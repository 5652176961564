// @ts-nocheck
import CreateUserForm from "#/components/Form/complex/User/index.js";
import { MantineController } from "#/components/Form/v3/index.js";
import { Form } from "#/components/index.js";
import { trpc } from "#/trpc.js";
import { reportUserError, reportUserSuccess } from "#/util/index.js";
import * as M from "@mantine/core";
import dayjs from "dayjs";
import { Link, useNavigate } from "react-router-dom";

export const CreateUser = () => {
  const { data: roles } = trpc.role.getAll.useQuery(undefined, {
    onError: (err) => {
      reportUserError({
        title: "Failed to load roles",
        message: err.message,
      });
    },
  });

  const { data: filials } = trpc.filial.getAllWithOnlyRead.useQuery(undefined, {
    onError: (err) => {
      reportUserError({
        title: "Failed to load filials",
        message: err.message,
      });
    },
  });

  const rolesData = useMemo(() => {
    return (
      roles?.map((r) => ({
        label: r.name,
        value: r.id as unknown as string,
      })) ?? []
    );
  }, [roles]);

  const filialOptions = useMemo(
    () =>
      filials?.map((filial) => ({
        label: filial.name,
        value: filial.id.toString(),
      })) ?? [],
    [filials]
  );

  const [isAddressRequired, setIsAddressRequired] = useState(false);
  const navigate = useNavigate();

  const { mutate, isLoading } = trpc.user.signupPos.useMutation({
    onSuccess() {
      reportUserSuccess({
        title: "User created successfully",
        message: <span>User created successfully</span>,
      });
      navigate("/settings/admin/users");
    },
    onError(err) {
      reportUserError({
        title: "Failed to create user",
        message: err.message,
      });
    },
  });

  const handleSubmit = useCallback(
    (f) => {
      const auth: any = [
        {
          providerId: "email-password",
          email: f.email,
          password: f.password,
        },
      ];

      if (f.pin) {
        auth.push({
          providerId: "email-pin",
          email: f.email,
          pin: f.pin,
        });
      }

      mutate({
        firstName: f.firstName,
        lastName: f.lastName,
        email: f.email,
        phoneNumber: f.phoneNumber,
        areaCode: f.areaCode,
        countryCode: f.countryCode,
        roleId: f.role as unknown as number,
        birthDate:
          f.birthDate && dayjs(f.birthDate).isValid()
            ? dayjs(f.birthDate).toDate()
            : undefined,
        street: isAddressRequired ? f.address?.address : undefined,
        city: isAddressRequired ? f.address?.city : undefined,
        state: isAddressRequired ? f.address?.state : undefined,
        zip: isAddressRequired ? f.address?.zip : undefined,
        country: isAddressRequired ? f.address?.country : undefined,
        apt: isAddressRequired ? f.address?.apt : undefined,
        poBox: isAddressRequired ? f.address?.poBox : undefined,
        filialId: f.filial ? Number(f.filial) : undefined,
        verified: f.verified,
        taxable: f.taxable,
        note: f.note,
        company: f.company ?? null,
        nickName: f.nickName ?? null,
        avatar: f.avatar ?? null,
        taxDocument: f.taxDocument ?? null,
        ein: f.ein ?? null,
        bio: f.bio ?? null,
        auth: auth,
      });
    },
    [mutate, isAddressRequired]
  );

  return (
    <M.Container>
      <Form onSubmit={handleSubmit} w="100%">
        <M.Group position="apart" my={12}>
          <M.Title order={2}>Create user</M.Title>
          <M.Group>
            <MantineController
              as={M.Select}
              name="filial"
              label="Filial"
              size="sm"
              data={filialOptions}
              clearable
            />
            <MantineController
              as={M.Select}
              name="role"
              label="Role"
              required
              size="sm"
              data={rolesData}
            />
          </M.Group>
        </M.Group>
        <M.Box
          p={12}
          sx={(t) => ({ background: "white", borderRadius: t.radius.sm })}
        >
          <CreateUserForm
            onAddressRequiredChange={setIsAddressRequired}
            isSecuritySectionDisabled={false}
          />
          <M.Group spacing={15} align="end" sx={{ width: "auto" }}>
            <M.Button component={Link} to="/settings/users" color="gray.4">
              Cancel
            </M.Button>
            <M.Button
              mt="md"
              type="submit"
              sx={{ width: "15ch" }}
              loading={isLoading}
            >
              Save
            </M.Button>
          </M.Group>
        </M.Box>
      </Form>
    </M.Container>
  );
};
