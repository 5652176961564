import { CardContainer } from "#/scenes/Reports/common.js";
import { RouterOutputs, trpc } from "#/trpc.js";
import * as M from "@mantine/core";
import { epochToHumanDate } from "#/util/date.js";

export type Departments =
  RouterOutputs["v2_5"]["discounts"]["getDepartmentsByDiscount"];
export type Department = Departments[number];

interface DepartmentDetailsProps {
  discountId: number;
  opened: boolean;
  onClose: () => void;
}

const DepartmentDetails = ({
  discountId,
  opened,
  onClose,
}: DepartmentDetailsProps) => {
  const { data: departments } =
    trpc.v2_5.discounts.getDepartmentsByDiscount.useQuery(
      {
        discountId: discountId,
      },
      {
        enabled: opened,
        refetchOnWindowFocus: false,
        refetchInterval: 0,
      },
    );

  return (
    <M.Drawer
      opened={opened}
      onClose={onClose}
      position="right"
      size="xl"
      padding="xl"
      overlayProps={{
        opacity: 0.6,
      }}
    >
      <M.Title order={3}>Departments Details</M.Title>
      <M.Divider color="gray.2" my={20} />
      <M.Stack
        spacing="xl"
        sx={{
          overflowY: "auto",
          maxHeight: "calc(100vh - 200px)",
        }}
      >
        {departments?.map((department) => (
          <DepartmentCard key={department.id} department={department} />
        ))}
      </M.Stack>
    </M.Drawer>
  );
};

export default DepartmentDetails;

interface DepartmentCardProps {
  department: Department;
}

const DepartmentCard = ({ department }: DepartmentCardProps) => {
  return (
    <CardContainer>
      <M.Table striped>
        <thead>
          <tr>
            <th
              colSpan={2}
              style={{
                color: "#69b7f9",
                fontSize: "1rem",
              }}
            >
              Department
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ fontWeight: "bold" }}>Department Name</td>
            <td>{department?.name}</td>
          </tr>
          <tr>
            <td style={{ fontWeight: "bold" }}>description</td>
            <td>{department?.description}</td>
          </tr>
          <tr>
            <td style={{ fontWeight: "bold" }}>#Items associated</td>
            <td>0 (todo)</td>
          </tr>
          <tr>
            <td style={{ fontWeight: "bold" }}>Status</td>
            <td>{department?.active ? "Active" : "Inactive"}</td>
          </tr>
          <tr>
            <td style={{ fontWeight: "bold" }}>Last updated</td>
            <td>{epochToHumanDate(department?.updatedAt)}</td>
          </tr>
        </tbody>
      </M.Table>
    </CardContainer>
  );
};
