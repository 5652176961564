import { trpc } from "#/trpc";
import { reportUserSuccess } from "#/util";
import { CustomAttributesForm } from "./Form";
import { FormValues } from "./Form/types";
import { useNavigate } from "react-router";

export const CreateCustomAttribute = () => {
  const navigate = useNavigate();
  const ctx = trpc.useContext();
  const { mutate, isLoading } = trpc.v2_5.customAttributes.create.useMutation({
    onSuccess() {
      ctx.v2_5.customAttributes.invalidate();
      reportUserSuccess({
        title: "Custom attribute created",
      });
      navigate("/inventory/custom-attributes");
    },
  });

  const handleSubmit = (values: FormValues) => {
    mutate({
      name: values.name,
      type: values.type,
      description: values.description,
      textLimit: values.textLimit,
      options: values.type === "SELECT" ? values.options : [],
      multiSelectItems: values.multiSelectItems.map((value) => value.id),
    });
  };

  return <CustomAttributesForm isLoading={isLoading} onSubmit={handleSubmit} />;
};
