import { CSSObject, forwardRef } from "@chakra-ui/react";
import { useToggle } from "@mantine/hooks";
import {
  Select as OriginalSelect,
  Props,
  SelectComponent,
  GroupBase,
  SelectInstance,
  MenuProps,
} from "chakra-react-select";
import { Ref } from "react";

const Select = forwardRef(
  <Option, Group extends GroupBase<Option>, IsMulti extends boolean = false>(
    { chakraStyles, onChange, ...props }: Props<Option, IsMulti, Group>,
    ref: Ref<SelectInstance<Option, IsMulti, Group>> | undefined
  ) => {
    // Class to keep the label floating when something is selected.
    // See `modules/theme/styles.js` to see how this is used.
    const [className, toggle] = useToggle(["", "react-select-active"]);
    const {
      placeholder: placeholderStyles,
      menu: menuStyles,
      ...restStyles
    } = chakraStyles ?? {};

    return (
      <OriginalSelect
        size="sm"
        chakraStyles={{
          placeholder: (old, state) => ({
            ...old,
            display: className ? "none" : undefined,
            ...(placeholderStyles && placeholderStyles(old, state)),
          }),
          // @ts-ignore
          menu: (old, state) =>
            menuStyles
              ? menuStyles(myMenuStyles(old, state) as any, state)
              : myMenuStyles(old, state),
          ...restStyles,
        }}
        className={className}
        onChange={(v, a) => {
          if (v != null && !className) {
            toggle();
          } else if (v == null && className) {
            toggle();
          }
          if (onChange) {
            onChange(v, a);
          }
        }}
        {...props}
        // @ts-ignore
        ref={ref}
      />
    );
  }
) as SelectComponent;

const myMenuStyles = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>
>(
  old: CSSObject,
  _state: MenuProps<Option, IsMulti, Group>
) => ({
  ...old,
  w: "auto",
  zIndex: "dropdown",
});

export default Select;
