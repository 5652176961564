import { cn } from "#/lib/utils.js";
import { ProductFormProps } from "./Item/ProductInfo.js";
import { VariantsTable } from "./Item/VariantsTable.js";
import { ItemRelationshipsAndTagsSection } from "./Item/components/ItemJoinSection.js";
import { ItemSection } from "./Item/index.js";
import { Container } from "@mantine/core";

export function ProductForm(props: ProductFormProps) {
  return (
    <Container size="xl">
      <h1 className={cn("mb-6 hidden", props.showTitle && "block")}>
        Add product
      </h1>
      <ItemSection {...props} />
      {props.itemId && (
        <ItemRelationshipsAndTagsSection itemId={props?.itemId} />
      )}
      {props.itemId && <VariantsTable itemId={props.itemId} />}
    </Container>
  );
}

export * from "./Item/types.js";
