import { RouterOutputs, trpc } from "#/trpc";
import { useParams } from "react-router";

export function useOrder() {
  const params = useParams();
  const id = params.id ? Number.parseInt(params.id) : null;
  return trpc.order.pos.ecommerceOrders.ecommerceOrder.get.useQuery(
    {
      id: id!,
    },
    {
      enabled: id != null,
    }
  );
}

export type Order = NonNullable<
  RouterOutputs["order"]["pos"]["ecommerceOrders"]["ecommerceOrder"]["get"]
>;
