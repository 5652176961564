import React from "react";
import * as M from "@mantine/core";

const Image = (props) => {
  const [isOpened, setIsOpened] = React.useState(false);
  const imageRef = React.useRef(null);
  const [transformOrigin, setTransformOrigin] = React.useState({
    x: 0,
    y: 0,
  });
  const [isZoomed, setIsZoomed] = React.useState(false);

  return (
    <M.Box>
      <M.Modal
        opened={isOpened}
        onClose={() => setIsOpened(false)}
        isCentered
        withCloseButton={false}
        size="xl"
        styles={{
          modal: { padding: "0 !important", background: "transparent" },
        }}
      >
        <M.Box
          ref={imageRef}
          sx={(t) => ({
            borderWidth: 2,
            borderStyle: "solid",
            borderColor: "white",
            flex: "initial",
            width: "min(1100px, 100%)",
            height: "min(680px, 100%)",
            padding: 0,
            borderRadius: t.radius.sm,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: `rgba(0, 0, 0, 0.5)`,
            overflow: "hidden",
            cursor: isZoomed ? "zoom-out" : "zoom-in",
          })}
          onClick={() => setIsZoomed((old) => !old)}
          onMouseMove={(e) => {
            const { left: offsetLeft, top: offsetTop } =
              imageRef.current.getBoundingClientRect();

            const width = imageRef.current.clientWidth;
            const height = imageRef.current.clientHeight;
            const x = (Math.max(0, e.pageX - offsetLeft) / width) * 100;
            const y = (Math.max(0, e.pageY - offsetTop) / height) * 100;

            setTransformOrigin({
              x,
              y,
            });
          }}
        >
          <M.Image
            src={props.src}
            sx={() => ({
              objectFit: "contain",
              width: "100%",
              height: "100%",
              maxWidth: "100%",
              maxHeight: "100%",
            })}
            style={{
              transform: isZoomed ? "scale(2.0)" : "scale(1.0)",
              transformOrigin: isZoomed
                ? `${transformOrigin.x}% ${transformOrigin.y}%`
                : "50% 50%",
            }}
          />
        </M.Box>
      </M.Modal>
      <M.Image
        cursor="zoom-in"
        fit="contain"
        withPlaceholder
        {...props}
        onClick={() => {
          if (props.src) {
            setIsOpened(true);
          }
        }}
      />
    </M.Box>
  );
};
export default Image;
