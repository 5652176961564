import { SearchButton } from "#/components@v2_5/search-button";
import { css } from "#/css/css";
import { HStack } from "#/css/jsx";
import { RouterOutputs, trpc } from "#/trpc";
import { Button, Dropdown } from "@gt/ui";
import { DropdownMenu } from "@radix-ui/themes";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_SortingState,
  useMantineReactTable,
} from "mantine-react-table";
import { Link, useNavigate } from "react-router-dom";
import MenuIcon from "~icons/ion/ellipsis-horizontal-outline";
import EditIcon from "~icons/ion/create-outline";
import dayjs from "dayjs";
import { reportUserSuccess } from "#/util";
import { openConfirmModal } from "@mantine/modals";

export const ChangelogVersionsSection = () => {
  const [search, setSearch] = useState<string | null>(null);

  return (
    <>
      <HStack justify="space-between" gap={3}>
        <DrowndownButton />
        <SearchButton setQuery={setSearch} />
      </HStack>
      <div
        className={css({
          marginTop: 5,
        })}
      >
        <ChangeLogVersionsTable search={search} />
      </div>
    </>
  );
};

const DrowndownButton = () => {
  const ctx = trpc.useContext();
  const navigate = useNavigate();

  const { mutate: generateNewVersion, isLoading } =
    trpc.v2_5.changelog.sysVersions.generateNewVersion.useMutation({
      onSuccess(data) {
        ctx.v2_5.changelog.invalidate();
        reportUserSuccess({
          title: `New Version Generated: v${data.version}`,
        });
        navigate(`/settings/admin/changelog/versions/${data.id}`);
      },
    });

  const handleConfirmGenerateNewVersion = () => {
    openConfirmModal({
      title: "Are you sure you want to generate a new version?",
      labels: { cancel: "Cancel", confirm: "Confirm" },
      confirmProps: { color: "red" },
      onConfirm: () => {
        generateNewVersion();
      },
    });
  };

  return (
    <Dropdown.Root>
      <Dropdown.Trigger>
        <Button
          className={css({
            gap: 4,
          })}
        >
          I want to
          <DropdownMenu.TriggerIcon />
        </Button>
      </Dropdown.Trigger>
      <Dropdown.Content>
        <Dropdown.Item
          disabled={isLoading}
          onClick={handleConfirmGenerateNewVersion}
        >
          Generate new version
        </Dropdown.Item>
      </Dropdown.Content>
    </Dropdown.Root>
  );
};

interface ChangeLogVersionsTableProps {
  search?: string | null;
}

const ChangeLogVersionsTable = (props: ChangeLogVersionsTableProps) => {
  // pagination
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 25,
  });

  // sorting
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const querySorting = useMemo(() => {
    if (sorting[0]) {
      return {
        desc: sorting[0].desc,
        key: sorting[0].id as any,
      };
    }
    return null;
  }, [sorting]);

  const { data, isLoading } =
    trpc.v2_5.changelog.sysVersions.getByPage.useQuery(
      {
        pageIndex: pagination.pageIndex,
        pageSize: pagination.pageSize,
        sorting: querySorting,
        search: props.search,
        filters: null,
      },
      {
        cacheTime: 0,
        keepPreviousData: true,
      },
    );

  const table = useMantineReactTable({
    data: data?.entries ?? [],
    columns,
    manualPagination: true,
    enableTopToolbar: false,
    rowCount: data?.totalEntries ?? 0,
    enableFilters: false,
    state: {
      pagination,
      sorting,
      isLoading,
    },
    enableStickyHeader: true,
    manualSorting: true,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
  });

  return <MantineReactTable table={table} />;
};

type Version =
  RouterOutputs["v2_5"]["changelog"]["sysVersions"]["getByPage"]["entries"][number];

const columns: MRT_ColumnDef<Version>[] = [
  {
    header: "Actions",
    id: "actions",
    enableSorting: false,
    size: 100,
    Cell: (table) => {
      const original = table.row.original;

      return (
        <>
          <Dropdown.Root>
            <Dropdown.Trigger>
              <Button variant="ghost" size="icon">
                <MenuIcon />
              </Button>
            </Dropdown.Trigger>
            <Dropdown.Content>
              <Dropdown.Item asChild>
                <Link to={`${original.id}`}>
                  <div
                    className={css({
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 2,
                    })}
                  >
                    <EditIcon />
                    Edit change sets
                  </div>
                </Link>
              </Dropdown.Item>
            </Dropdown.Content>
          </Dropdown.Root>
        </>
      );
    },
  },
  {
    header: "Version",
    id: "version",
    accessorKey: "version",
  },
  {
    header: "Date",
    id: "createdAt",
    accessorKey: "createdAt",
    enableSorting: false,
    Cell: (table) => {
      const createdAt = table.row.original.createdAt;
      if (!createdAt) return "";
      return dayjs(createdAt).format("MM/DD/YYYY").toString();
    },
  },
];
