import Controller from "./Controller.js";
import * as C from "@chakra-ui/react";
import { TextareaProps } from "@chakra-ui/react";
import { ControllerProps, FieldPath, FieldValues } from "react-hook-form";

export interface FormTextareaProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends ControllerProps<TFieldValues, TName>,
    Omit<TextareaProps, "name" | "defaultValue"> {
  nullIfEmpty?: boolean;
}

export function FormTextarea<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  defaultValue,
  nullIfEmpty = true,
  ...styleProps
}: FormTextareaProps<TFieldValues, TName>) {
  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      render={({ onChange, value }) => (
        <C.Textarea
          onChange={(e) => {
            if (nullIfEmpty && !e.target.value) {
              onChange(null as any);
            } else {
              onChange(e.target.value as any);
            }
          }}
          value={value}
          {...styleProps}
        />
      )}
    />
  );
}
