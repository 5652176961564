import { Link } from "react-router-dom";
import CalendarIcon from "~icons/ion/calendar-outline";
import { css } from "#/css/css";
import { HStack } from "#/css/jsx";
import { Button, Checkbox, Dropdown } from "@gt/ui";
import { DropdownMenu } from "@radix-ui/themes";
import { SearchButton } from "#/components@v2_5/search-button";
import { WaitingListProductsTable } from "./table";
import { Filter, FilterKey } from "./$common";
import { FilterOutlineIcon } from "#/components-ng";
import { DatePickerInput } from "@mantine/dates";

const WaitingListProductsScene = () => {
  const [query, setQuery] = useState<string | null>("");
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);
  const [filters, setFilters] = useState<Record<FilterKey, boolean>>(
    () =>
      Object.fromEntries(
        Object.keys(Filter).map((key) => [
          key,
          key === "WAITING" ? true : false,
        ]),
      ) as any,
  );

  function selectFilter(filter: FilterKey) {
    setFilters((old) => ({
      ...old,
      [filter]: !old[filter],
    }));
  }

  return (
    <>
      <HStack justify="space-between" gap={3}>
        <HStack width="100%" gap={3}>
          <DrowndownButton />
          <UserWaitingListStatusDropdown
            filters={filters}
            selectFilter={selectFilter}
          />
          <DatePickerInput
            type="range"
            value={dateRange}
            clearable
            popoverProps={{
              withinPortal: true,
              zIndex: 9999,
            }}
            numberOfColumns={1}
            onChange={(value) => setDateRange(value)}
            allowSingleDateInRange
            className={css({ width: "100%", maxWidth: "300px" })}
            icon={<CalendarIcon color="#424242" />}
          />
        </HStack>
        <SearchButton setQuery={setQuery} />
      </HStack>
      <div className={css({ marginTop: 15 })}>
        <WaitingListProductsTable
          dateRange={dateRange}
          search={query}
          filter={
            Object.entries(filters)
              .filter(([, active]) => active)
              .map(([filter]) => filter) as any
          }
        />
      </div>
    </>
  );
};

export default WaitingListProductsScene;

const DrowndownButton = () => {
  return (
    <Dropdown.Root>
      <Dropdown.Trigger>
        <Button
          className={css({
            gap: 4,
          })}
        >
          I want to
          <DropdownMenu.TriggerIcon />
        </Button>
      </Dropdown.Trigger>
      <Dropdown.Content>
        <Dropdown.Item>
          <Link to="create">Add to waiting list</Link>
        </Dropdown.Item>
      </Dropdown.Content>
    </Dropdown.Root>
  );
};

interface UserWaitingListStatusDropdownProps {
  filters: Record<FilterKey, boolean>;
  selectFilter: (filter: FilterKey) => void;
}

const UserWaitingListStatusDropdown = (
  props: UserWaitingListStatusDropdownProps,
) => {
  return (
    <Dropdown.Root>
      <Dropdown.Trigger>
        <Button variant="secondary">
          <FilterOutlineIcon
            className={css({
              marginRight: 2,
            })}
          />
          Filter
        </Button>
      </Dropdown.Trigger>
      <Dropdown.Content
        className={css({
          display: "grid",
          gridGap: "0.1rem",
        })}
        loop
      >
        {Object.entries(props.filters)
          .filter(([key]) => key !== "PROCESSING")
          .map(([filterKey, isFilterActive], i) => (
            <Dropdown.Item
              key={i}
              className={css({
                display: "flex",
                alignItems: "center",
                gap: 2,
              })}
              onSelect={(e) => {
                e.preventDefault();
                props.selectFilter(filterKey as FilterKey);
              }}
            >
              <Checkbox checked={isFilterActive} />
              <span>{Filter[filterKey]}</span>
            </Dropdown.Item>
          ))}
      </Dropdown.Content>
    </Dropdown.Root>
  );
};
