import { MenuIcon } from "#/components/index.js";
import { SearchButton } from "#/components@v2_5/search-button";
import { css } from "#/css/css";
import { Box, HStack } from "#/css/jsx";
import { RouterOutputs, trpc } from "#/trpc.js";
import { reportUserError, reportUserSuccess } from "#/util/ux/index.js";
import { Button, Dropdown } from "@gt/ui";
import * as M from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import { DropdownMenu, IconButton, Tooltip } from "@radix-ui/themes";
import dayjs from "dayjs";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_SortingState,
  useMantineReactTable,
} from "mantine-react-table";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import EditIcon from "~icons/ion/create-outline";
import DeleteIcon from "~icons/ion/trash-outline";
import placeholderImage from "#/placeholder-image.jpg";
import { MdOutlineDownload } from "react-icons/md";

export interface BlogColumns {
  id: number;
  image?: string;
  title: string;
  author: string;
  updatedAt: Date;
}

const BlogList = () => {
  const [search, setSearch] = useState<string | null>(null);

  // pagination
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 25,
  });

  // sorting
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const querySorting = useMemo(() => {
    if (sorting[0]) {
      return {
        desc: sorting[0].desc,
        key: sorting[0].id as any,
      };
    }
    return null;
  }, [sorting]);

  const { data, isLoading } = trpc.v2_5.blog.getAllPostByPage.useQuery(
    {
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize,
      sorting: querySorting,
      search: search,
      filters: null,
    },
    {
      keepPreviousData: true,
      onError(error) {
        reportUserError({
          title: "Failed to get posts",
          message: error.message,
        });
      },
    },
  );

  const { mutate: exportCsv, isLoading: isLoadingExport } =
    trpc.v2_5.blog.exportBlogTableToCsv.useMutation({
      onSuccess: () => {
        reportUserSuccess({
          title: "The csv file has been sent to email",
        });
      },
    });

  const handleExportCsv = () => {
    exportCsv({
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize,
      sorting: querySorting,
      search: search,
      filters: null,
    });
  };

  const table = useMantineReactTable({
    data: data?.entries ?? [],
    columns: columns(),
    manualPagination: true,
    enableTopToolbar: false,
    rowCount: data?.totalEntries ?? 0,
    enableFilters: false,
    state: {
      pagination,
      sorting,
      isLoading,
    },
    enableStickyHeader: true,
    manualSorting: true,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    renderBottomToolbarCustomActions: () => (
      <div
        className={css({
          display: "flex",
          alignItems: "center",
          gap: 2,
        })}
      >
        <Tooltip content="Export to CSV">
          <IconButton
            color="gray"
            variant="soft"
            loading={isLoadingExport}
            onClick={handleExportCsv}
          >
            <MdOutlineDownload />
          </IconButton>
        </Tooltip>
      </div>
    ),
  });

  return (
    <>
      <HStack mb={6} justify="space-between" gap={3}>
        <DrowndownButton />
        <SearchButton setQuery={setSearch} />
      </HStack>
      <Box>
        <MantineReactTable table={table} />
      </Box>
    </>
  );
};

type Blogs =
  RouterOutputs["v2_5"]["blog"]["getAllPostByPage"]["entries"][number];

const columns = (): MRT_ColumnDef<Blogs>[] => [
  {
    header: "Actions",
    id: "actions",
    Cell: ({ row: { original } }) => {
      const ctx = trpc.useContext();
      const { mutate: deletePost } = trpc.v2_5.blog.delete.useMutation({
        onSuccess() {
          reportUserSuccess({ title: "Post deleted successfully" });
          ctx.v2_5.blog.invalidate();
        },
        onError(error) {
          reportUserError({
            title: "Failed to delete post",
            message: error.message,
          });
        },
      });
      const openConfirmationModal = (id: number) =>
        openConfirmModal({
          title: "Are you sure you want to delete this post?",
          labels: { cancel: "Cancel", confirm: "Delete" },
          confirmProps: { color: "red" },
          onConfirm: () =>
            deletePost({
              id,
            }),
        });
      return (
        <M.Flex gap="0.5em">
          <M.Menu classNames={{ itemLabel: "flex flex-nowrap  gap-x-2" }}>
            <M.Menu.Target>
              <M.ActionIcon>
                <MenuIcon />
              </M.ActionIcon>
            </M.Menu.Target>
            <M.Menu.Dropdown>
              <M.Menu.Item
                component={Link}
                to={`edit/${encodeURI(original.id.toString())}`}
                icon={<EditIcon />}
              >
                Edit
              </M.Menu.Item>
              <M.Menu.Item onClick={() => openConfirmationModal(original.id)}>
                <DeleteIcon fontSize="md" />
                Delete
              </M.Menu.Item>
            </M.Menu.Dropdown>
          </M.Menu>
        </M.Flex>
      );
    },
  },
  {
    header: "Image",
    accessorKey: "image",
    enableSorting: false,
    Cell: ({ row: { original } }) => {
      return (
        <img
          src={original.image ?? placeholderImage}
          alt="image"
          height={80}
          width={80}
          onError={(e) => {
            (e.target as HTMLImageElement).src = placeholderImage;
          }}
        />
      );
    },
  },
  {
    header: "Title",
    accessorKey: "title",
  },
  {
    header: "Date",
    accessorKey: "updatedAt",
    Cell: ({ row: { original } }) => {
      return <p>{dayjs(original.updatedAt).format("DD/MM/YYYY")}</p>;
    },
  },
  {
    header: "Uploaded By",
    accessorKey: "author",
    Cell: ({
      row: {
        original: { author },
      },
    }) => {
      return (
        <Box>
          <p>
            {author?.firstName ?? "No author"} {author?.lastName ?? ""}
          </p>
        </Box>
      );
    },
  },
];

export default BlogList;

const DrowndownButton = () => {
  return (
    <Dropdown.Root>
      <Dropdown.Trigger>
        <Button
          className={css({
            gap: 4,
          })}
        >
          I want to
          <DropdownMenu.TriggerIcon />
        </Button>
      </Dropdown.Trigger>
      <Dropdown.Content>
        <Dropdown.Item>
          <Link to="create">Create</Link>
        </Dropdown.Item>
      </Dropdown.Content>
    </Dropdown.Root>
  );
};
