import { Parser } from "@json2csv/plainjs";

interface GenerateCsvProps {
  headers: {
    label: string;
    value: string;
  }[];
  data: any[];
}

export const generateCsv = async ({ headers, data }: GenerateCsvProps) => {
  const json2csv = new Parser({ fields: headers });
  const csv = json2csv.parse(data);
  return csv;
};
