import { trpc } from "#/trpc.js";
import { reportUserError } from "#/util/index.js";
import { dashboardBaseDataAtom } from "../state/index.js";
import * as M from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { useAtom } from "jotai";
import React, { forwardRef } from "react";

export const DashboardForm = () => {
  const [baseData, setBaseData] = useAtom(dashboardBaseDataAtom);

  const { data: filials } = trpc.filial.getAll.useQuery(undefined, {
    onError: (err) => {
      reportUserError({
        title: "Failed to load filials",
        message: err.message,
      });
    },
  });

  const { data: users } = trpc.user.getAll.useQuery(
    {
      filialId: null,
    },
    {
      onError: (err) => {
        reportUserError({
          title: "Failed to load users",
          message: err.message,
        });
      },
      enabled: !!baseData.filialId,
    }
  );

  const filialOptions = React.useMemo(
    () =>
      filials?.map((filial) => ({
        label: filial.name,
        value: filial.id.toString(),
      })) ?? [],
    [filials]
  );

  const userOptions = React.useMemo(
    () =>
      users
        ?.filter((u) => u.filialId === baseData.filialId)
        .map((user) => ({
          label: `${user.firstName} ${user.lastName ?? ""}`,
          value: user.id.toString(),
          image: user.avatar ?? "",
          description: user.role?.name ?? "",
          user: user,
        })) ?? [],
    [baseData.filialId, users]
  );

  return (
    <>
      <M.Title order={2}>Dashboard</M.Title>
      <M.Divider mt="md" mb={20} />
      <M.Group position="left">
        <M.Stack
          spacing="lg"
          sx={{
            width: "100%",
            maxWidth: "350px",
          }}
          px={10}
          py={10}
          mt="xl"
        >
          <M.Title order={3} ml={2} mt="sm">
            Generate states
          </M.Title>
          <M.Text ml={2} size={14}>
            Please select a filial, user and date range to continue
          </M.Text>
          <M.Select
            label="Filial"
            required
            value={baseData?.filialId?.toString() ?? ""}
            onChange={(e) =>
              setBaseData({
                ...baseData,
                filialId: parseInt(e as any),
                userId: null,
              })
            }
            data={filialOptions}
          />
          <M.Select
            label="User"
            required
            value={baseData?.userId?.toString() ?? ""}
            onChange={(e) => {
              const user = userOptions.find((u) => u.value === e);
              setBaseData({
                ...baseData,
                userId: parseInt(e as any),
                user: {
                  firstName: user?.user?.firstName ?? "",
                  lastName: user?.user?.lastName ?? "",
                  avatar: user?.user?.avatar ?? "",
                  roleName: user?.user?.role?.name ?? "",
                },
              });
            }}
            data={userOptions}
            nothingFound="No users found"
            maxDropdownHeight={280}
            searchable
            itemComponent={SelectItem}
            disabled={!baseData.filialId}
            clearable
          />

          <DatePickerInput
            label="Date"
            type="range"
            value={[baseData?.fromDate, baseData?.toDate]}
            clearable={false}
            popoverProps={{
              withinPortal: false,
              zIndex: 9999,
            }}
            onChange={(value) =>
              setBaseData({
                ...baseData,
                fromDate: value[0],
                toDate: value[1],
              })
            }
            allowSingleDateInRange
            required
          />
          <M.Button
            mt="lg"
            fullWidth
            onClick={() => {
              setBaseData({ ...baseData, send: true });
            }}
          >
            Generate stats
          </M.Button>
        </M.Stack>
      </M.Group>
    </>
  );
};

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  image: string;
  label: string;
  description: string;
}

// eslint-disable-next-line react/display-name
const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ image, label, description, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <M.Group noWrap>
        <M.Avatar src={image} radius={"xl"} />
        <div>
          <M.Text size="sm">{label}</M.Text>
          <M.Text size="xs" opacity={0.65}>
            {description}
          </M.Text>
        </div>
      </M.Group>
    </div>
  )
);
