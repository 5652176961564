import { cn } from "#/lib/utils.js";
import { Label } from "./label.js";
import * as React from "react";

type FieldDivProps = Omit<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  "children"
>;
type FieldOwnProps = {
  children: (props: { id: string }) => React.ReactNode;
  label?: string;
  name?: string;
  cnLabel?: string;
  orientation?: "horizontal" | "vertical";
};
export type FieldProps = FieldOwnProps & FieldDivProps;
export function Field({
  children,
  orientation,
  className,
  cnLabel,
  ...props
}: FieldProps) {
  const id = React.useId();
  return (
    <div
      className={cn(
        "flex",
        orientation === "horizontal"
          ? "flex-row items-center gap-x-4"
          : "flex-col gap-y-2",
        className
      )}
      {...props}
    >
      {props.label && (
        <Label htmlFor={id} className={cnLabel}>
          {props.label}
        </Label>
      )}
      {/* @ts-ignore */}
      {children({ id })}
    </div>
  );
}
Field.displayName = "Field";
