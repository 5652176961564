import { trpc } from "#/trpc";
import { reportUserError, reportUserSuccess } from "#/util";
import { useNavigate, useParams } from "react-router";
import { FormValues } from "./Form/types";
import { SysChangeSetForm } from "./Form";

export const CreateChangeSet = () => {
  const params = useParams() as any;
  const sysVersionId = params?.sysVersionId
    ? Number(params.sysVersionId)
    : null;

  const ctx = trpc.useContext();
  const navigate = useNavigate();

  const { mutate, isLoading } =
    trpc.v2_5.changelog.sysChangeSet.create.useMutation({
      onSuccess(data) {
        ctx.v2_5.changelog.invalidate();
        reportUserSuccess({
          title: "Create Change Set Successfully",
        });
        navigate(`/settings/admin/changelog/versions/${data.sysVersionId}`);
      },
    });

  const handleSubmit = (values: FormValues) => {
    if (!values.sysSectionId) {
      reportUserError({
        title: "SysSection is required",
      });
      return;
    }

    if (!sysVersionId) {
      reportUserError({
        title: "Something is wrong, please try again",
      });
      return;
    }

    mutate({
      reason: values.reason,
      sysSectionId: Number(values.sysSectionId.id),
      requiredReload: values.requiredReload,
      sysVersionId: sysVersionId,
      title: values.title,
      image: values.image,
      video: values.video,
      type: values.type,
    });
  };

  return <SysChangeSetForm onSubmit={handleSubmit} isLoading={isLoading} />;
};
