import GTLoader from "#/components-ng/loader.js";
import { DisplayTable } from "#/components/index.js";
import { printTicketFromRefundV2 } from "#/modules/ticket/print.js";
import { RouterOutputs, trpc } from "#/trpc.js";
import { epochToMMDDYYY } from "#/util/date.js";
import { reportUserError } from "#/util/index.js";
import { ChevronLeftIcon, ViewIcon } from "@chakra-ui/icons";
import * as C from "@chakra-ui/react";
import * as M from "@mantine/core";
import Decimal from "decimal.js";
import { atom, useAtom, useSetAtom } from "jotai";
import React from "react";
import { Flipper, Flipped } from "react-flip-toolkit";

const selectedRefundOrderAtom = atom<NonNullable<
  RouterOutputs["refundOrderItemSku"]["getByReference"]
> | null>(null);

export function RefundHistoryModal({
  customerId,
  ...disclosure
}: { customerId: number } & C.UseDisclosureReturn) {
  const [selectedRefund, setSelectedRefund] = useAtom(selectedRefundOrderAtom);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => setSelectedRefund(null), []);

  const { data, isLoading } = trpc.refundOrderItemSku.getByCustomerId.useQuery(
    {
      customerId: customerId,
    },
    {
      cacheTime: 0,
      enabled: !!customerId,
      onError(error) {
        reportUserError({
          title: "Failed to get return history",
          message: error.message,
        });
      },
    }
  );

  return (
    <C.Modal {...disclosure}>
      <Flipper flipKey={!!selectedRefund}>
        <C.ModalOverlay />
        <Flipped flipId="return-history-modal">
          <C.ModalContent
            maxW="initial"
            w="auto"
            overflowX="hidden"
            overflowY="auto"
            maxH="min(800px, 95vh)"
          >
            {selectedRefund ? (
              <RefundModalContent />
            ) : (
              <RefundsModalContent refunds={data} isLoading={isLoading} />
            )}
          </C.ModalContent>
        </Flipped>
      </Flipper>
    </C.Modal>
  );
}

interface OrdersModalContentProps {
  refunds:
    | NonNullable<RouterOutputs["refundOrderItemSku"]["getByCustomerId"]>
    | undefined;
  isLoading: boolean;
}

function RefundsModalContent({ refunds, isLoading }: OrdersModalContentProps) {
  return (
    <Flipped inverseFlipId="return-history-modal">
      <C.Box>
        <C.ModalHeader as={C.HStack} spacing={4}>
          <C.IconButton
            aria-label="go back"
            icon={<ChevronLeftIcon w={8} h={8} />}
            variant="ghost"
            colorScheme="gray"
            size="sm"
            visibility="hidden"
            opacity="0"
          />
          <C.Text>Return history</C.Text>
        </C.ModalHeader>
        <C.Box>
          <C.ModalCloseButton />
        </C.Box>
        <C.ModalBody>
          <DisplayTable
            data={refunds ?? []}
            columns={orderListColumns}
            pagination={!!refunds?.length}
          />

          {!refunds?.length && (
            <C.Center>
              <C.Text mt={4} color="gray.500">
                No return history yet
              </C.Text>
            </C.Center>
          )}
        </C.ModalBody>
        {isLoading && (
          <C.Center p={4}>
            <GTLoader width={100} height={100} />
          </C.Center>
        )}
      </C.Box>
    </Flipped>
  );
}

function RefundModalContent() {
  const [selectedRefund, setSelectedRefund] = useAtom(selectedRefundOrderAtom);

  const itemSkuList = selectedRefund?.map((entry) => ({
    ...entry,
    price: entry.orderItemSku?.price,
    sku: entry.orderItemSku?.itemSku?.sku,
    title: entry.orderItemSku?.itemSku?.title,
  }));

  const handleReprint = async () => {
    await printTicketFromRefundV2({
      refunds: selectedRefund!,
      withLocation: false,
      filialId: selectedRefund![0].filialId!,
    });
  };

  const total = selectedRefund?.reduce(
    (acc, curr) => acc.plus(curr.total ?? 0),
    new Decimal(0)
  );

  return (
    <Flipped inverseFlipId="return-history-modal">
      <C.Box>
        <C.ModalHeader as={C.HStack} spacing={4}>
          <C.IconButton
            aria-label="go back"
            icon={<ChevronLeftIcon w={8} h={8} />}
            variant="ghost"
            colorScheme="gray"
            size="sm"
            onClick={() => setSelectedRefund(null)}
          />

          <C.Text>Refund R{selectedRefund![0].reference}</C.Text>
        </C.ModalHeader>
        <C.Box>
          <C.ModalCloseButton />
        </C.Box>
        <C.ModalBody>
          <C.HStack w="100%" justify="space-between">
            <C.VStack>
              <C.Text fontWeight="bold">
                {selectedRefund![0]?.customer?.firstName ?? ""}{" "}
                {selectedRefund![0].customer?.lastName ?? ""}
              </C.Text>
              <C.Text color="gray.500">
                {selectedRefund![0].customer?.areaCode}{" "}
                {selectedRefund![0].customer?.phoneNumber}
              </C.Text>
            </C.VStack>
            <C.VStack>
              <C.Text color="gray.500">
                Date: {epochToMMDDYYY(selectedRefund![0]?.createdAt)}
              </C.Text>
            </C.VStack>
          </C.HStack>
          <C.Divider my={3} />
          <DisplayTable data={itemSkuList} columns={itemSkuListColumns} />
          <C.Divider my={6} />
          <C.HStack w="100%" spacing={8} mt={6} align="start">
            <C.VStack flex={1} align="start" spacing={6}>
              <C.Button variant="secondary" w="12ch" onClick={handleReprint}>
                Reprint
              </C.Button>
            </C.VStack>
            <M.Box
              w={250}
              sx={(theme) => ({
                backgroundColor: theme.colors.green[7],
                color: "white",
                borderRadius: theme.radius.md,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "8px 48px",
              })}
            >
              <M.Group position="apart" align="center" sx={{ width: "100%" }}>
                <M.Text weight="600">Total</M.Text>
                <M.Text weight="600">
                  -${total?.toNumber()?.toFixed(2) ?? 0}
                </M.Text>
              </M.Group>
            </M.Box>
          </C.HStack>
        </C.ModalBody>
      </C.Box>
    </Flipped>
  );
}

const orderListColumns = [
  {
    id: "actions",
    Header: "Actions",
    accessor: "reference",
    Cell: ({ row: { original } }) => {
      const setSelectedRefund = useSetAtom(selectedRefundOrderAtom);

      return (
        <C.IconButton
          aria-label="open"
          icon={<ViewIcon />}
          colorScheme="gray"
          onClick={() => setSelectedRefund(original.refundOrders)}
        />
      );
    },
  },
  {
    Header: "Date",
    accessor: "createdAt",
    isEpochDate: true,
    Cell: "dateFromEpoch",
  },
  {
    Header: "Customer",
    accessor: "customer",
  },
  {
    Header: "Reference",
    accessor: "reference",
    Cell: ({ value }) => <p>R{value}</p>,
  },
  {
    Header: "Items",
    accessor: "items",
  },
  {
    Header: "Total",
    accessor: "total",
    isNumeric: true,
    Cell: "money",
  },
];

const itemSkuListColumns = [
  {
    Header: "SKU",
    accessor: "sku",
    isNumeric: true,
  },
  {
    Header: "Product",
    accessor: "title",
  },
  {
    Header: "Size (WxHxL)",
    Cell: ({ row: { original } }) => (
      <C.Text>
        {`${original?.orderItemSku?.itemSku?.width ?? "-"}x${
          original?.orderItemSku?.itemSku?.height ?? "-"
        }x${original?.orderItemSku?.itemSku?.length ?? "-"}`}
      </C.Text>
    ),
  },
  {
    Header: "Qty",
    accessor: "quantity",
    isNumeric: true,
    Cell: ({ value }) => (
      <C.VStack>
        <C.Text>-{value}</C.Text>
      </C.VStack>
    ),
  },
  {
    Header: "Price",
    accessor: "price",
    isNumeric: true,
    PrintCell: "money",
    Cell: ({ value }) => <C.Text>-${value?.toNumber().toFixed(2)}</C.Text>,
  },
  {
    Header: "Total",
    accessor: "total",
    isNumeric: true,
    PrintCell: "money",
    Cell: ({ value }) => `-$${value?.toNumber()?.toFixed(2)}`,
  },
];
