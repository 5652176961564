import { DefaultValues, FormProvider, SubmitHandler } from "react-hook-form";
import {
  CCreateRouletteItem,
  createRouletteItemSchema,
  FormRouletteItemValues,
  rouletteItemTypes,
} from "./types";
import { zodResolver } from "@hookform/resolvers/zod";
import { Drawer, Select } from "@mantine/core";
import { Button } from "@gt/ui";
import { trpc } from "#/trpc";
import { reportUserError, reportUserSuccess } from "#/util";
import { css } from "#/css/css";
import { styled } from "#/css/jsx";
import { SpinnerIcon } from "#/components-ng";
import placeholderImage from "#/placeholder-image.jpg";

interface RouletteItemProps {
  rouletteId: number;
  rouletteItemId?: number;
  opened: boolean;
  onClose: () => void;
  defaultValues?: DefaultValues<FormRouletteItemValues>;
}

export const CreateRouletteItem = (props: RouletteItemProps) => {
  const ctx = trpc.useContext();
  const { mutate, isLoading } =
    trpc.v2_5.roulette.createRouletteItem.useMutation({
      onSuccess: () => {
        props.onClose();
        ctx.v2_5.roulette.invalidate();
        reportUserSuccess({
          title: "Roulette item created",
          message: "Roulette item has been created successfully",
        });
      },
    });

  const handleSubmit = (values: FormRouletteItemValues) => {
    const validation = validateFields(values);
    if (validation) {
      reportUserError(validation);
      return;
    }

    mutate({
      rouletteId: props.rouletteId,
      title: values.title,
      description: values.description,
      active: values.active,
      type: values.type,
      link: values.link,
      itemSkuId: values?.itemSku?.id ?? null,
      couponId: values?.couponId ? Number(values.couponId) : null,
      discountId: values?.discountId ? Number(values.discountId) : null,
    });
  };

  return (
    <RouletteItemFromDrawer
      opened={props.opened}
      onClose={props.onClose}
      title="Create Roulette Item"
      onSubmit={handleSubmit}
      isLoading={isLoading}
    />
  );
};

export const UpdateRouletteItem = (props: RouletteItemProps) => {
  const ctx = trpc.useContext();
  const { mutate, isLoading } =
    trpc.v2_5.roulette.updateRouletteItem.useMutation({
      onSuccess: () => {
        props.onClose();
        ctx.v2_5.roulette.invalidate();
        reportUserSuccess({
          title: "Roulette item updated",
          message: "Roulette item has been updated successfully",
        });
      },
    });

  const handleSubmit = (values: FormRouletteItemValues) => {
    if (!props.rouletteItemId) {
      return;
    }

    const validation = validateFields(values);
    if (validation) {
      reportUserError(validation);
      return;
    }

    mutate({
      id: props.rouletteItemId,
      title: values.title,
      description: values.description,
      active: values.active,
      type: values.type,
      link: values.link,
      itemSkuId: values?.itemSku?.id ?? null,
      couponId: values?.couponId ? Number(values.couponId) : null,
      discountId: values?.discountId ? Number(values.discountId) : null,
    });
  };

  return (
    <RouletteItemFromDrawer
      opened={props.opened}
      onClose={props.onClose}
      title="Update Roulette Item"
      onSubmit={handleSubmit}
      isLoading={isLoading}
      defaultValues={props.defaultValues}
    />
  );
};

interface RouletteItemFromDrawerProps {
  opened: boolean;
  onClose: () => void;
  title: string;
  onSubmit: SubmitHandler<FormRouletteItemValues>;
  isLoading: boolean;
  defaultValues?: DefaultValues<FormRouletteItemValues>;
}

const RouletteItemFromDrawer = (props: RouletteItemFromDrawerProps) => {
  const form = CCreateRouletteItem.useForm({
    resolver: zodResolver(createRouletteItemSchema),
    shouldUnregister: false,
    defaultValues: {
      title: "",
      description: "",
      active: true,
      type: "TEXT",
      link: null,
      itemSku: null,
      ...props.defaultValues,
    },
    resetOptions: {
      keepDirtyValues: true,
    },
    shouldFocusError: false,
  });

  const type = form.watch("type");

  return (
    <Drawer
      opened={props.opened}
      onClose={props.onClose}
      size="md"
      padding="xl"
      position="right"
      zIndex={50}
      title={props.title}
      classNames={{
        title: css({
          fontSize: 20,
          fontWeight: 500,
        }),
      }}
    >
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit((values) => {
            form.reset();
            props.onSubmit(values);
          })}
        >
          <styled.div
            width="100%"
            className={css({
              display: "flex",
              flexDirection: "column",
              gap: 18,
            })}
          >
            <CCreateRouletteItem.InputField
              name="title"
              label="Title"
              required
            />
            <CCreateRouletteItem.InputField
              name="description"
              label="Description"
              required
            />
            <CCreateRouletteItem.SelectField
              name="type"
              label="Type"
              data={rouletteItemTypes.map((type) => ({
                id: type,
                label: type,
              }))}
            />
            {type === "TEXT" && (
              <CCreateRouletteItem.InputField name="link" label="Link" />
            )}
            {type === "FREE_ITEM" && (
              <ItemSkusSearchField itemSku={form.watch("itemSku")} />
            )}
            {type === "COUPON" && (
              <CouponSearchField
                enabled={type === "COUPON" ? true : false}
                type="ORDER_SUBTOTAL_PERCENTAGE"
              />
            )}
            {type === "FREE_SHIPPING_CODE" && (
              <CouponSearchField
                enabled={type === "FREE_SHIPPING_CODE" ? true : false}
                type="SHIPPING_PERCENTAGE"
              />
            )}
            {type === "PERCENTAGE_DISCOUNT" && (
              <DiscountSearchField
                enabled={type === "PERCENTAGE_DISCOUNT" ? true : false}
                type="PERCENTAGE"
              />
            )}
            {type === "FIXED_DISCOUNT" && (
              <DiscountSearchField
                enabled={type === "FIXED_DISCOUNT" ? true : false}
                type="AMOUNT"
              />
            )}
            <Button type="submit" isLoading={props.isLoading}>
              Save
            </Button>
          </styled.div>
        </form>
      </FormProvider>
    </Drawer>
  );
};

interface ItemSkusSearchFieldProps {
  itemSku?: {
    id: number;
    sku: number;
    title: string;
    defaultImage?: string | null;
  } | null;
}

const ItemSkusSearchField = ({ itemSku }: ItemSkusSearchFieldProps) => {
  const form = CCreateRouletteItem.useFormContext();
  const [query, setQuery] = useState("");
  const [selectedItemSku, setSelectedItemSku] = useState<
    ItemSkusSearchFieldProps["itemSku"] | null
  >(itemSku ?? null);

  const { data, isLoading } = trpc.itemSku.pos.search.useQuery(
    {
      query: `\\"${query}\\"`,
      limit: 10,
    },
    {
      onError(error) {
        reportUserError({
          title: "Failed to get item skus",
          message: error.message,
        });
      },
    },
  );

  const selectData = useMemo(
    () =>
      data?.map((e) => ({
        label: `${e.sku} - ${e.title}`,
        value: e.id.toString(),
        itemSku: e,
      })) ?? [],
    [data],
  );

  const handleChange = (itemSkuId: string | null) => {
    if (itemSkuId) {
      const itemSkuFound = selectData?.find(
        (e) => e.value === itemSkuId,
      )?.itemSku;

      if (itemSkuFound) {
        form.setValue("itemSku", itemSkuFound);
        setSelectedItemSku(itemSkuFound);
      }
    }
  };

  return (
    <>
      <Select
        data={selectData}
        searchable
        placeholder="Search by item sku..."
        rightSection={isLoading && <SpinnerIcon />}
        onSearchChange={setQuery}
        filter={() => true}
        label="Select ItemSku"
        value={null}
        onChange={handleChange}
      />
      {selectedItemSku && (
        <styled.div
          className={css({
            display: "flex",
            gap: 1,
            justifyContent: "center",
          })}
        >
          <styled.img
            src={selectedItemSku?.defaultImage ?? placeholderImage}
            alt={selectedItemSku?.title ?? ""}
            className={css({
              width: 100,
              height: 100,
              objectFit: "cover",
              borderRadius: 4,
            })}
            onError={(e) => {
              (e.target as HTMLImageElement).src = placeholderImage;
            }}
          />
          <styled.div
            className={css({
              marginLeft: 10,
              display: "flex",
              flexDirection: "column",
              gap: 0,
              justifyContent: "start",
            })}
          >
            <styled.p>{selectedItemSku?.title}</styled.p>
            <styled.p
              className={css({
                color: "#838383",
                fontSize: 12,
              })}
            >
              SKU: {selectedItemSku?.sku}
            </styled.p>
          </styled.div>
        </styled.div>
      )}
    </>
  );
};

interface CouponSearchFieldProps {
  enabled: boolean;
  type: "ORDER_SUBTOTAL_PERCENTAGE" | "SHIPPING_PERCENTAGE";
}

const CouponSearchField = (props: CouponSearchFieldProps) => {
  const { data } = trpc.v2_5.coupons.getByType.useQuery(
    {
      type: props.type,
    },
    {
      enabled: props.enabled,
    },
  );

  const selectData = useMemo(
    () =>
      data?.map((e) => ({
        label: `${e.code} - ${e.discount.reference} ${e.discount.amount}%`,
        id: e.id.toString(),
      })) ?? [],
    [data],
  );

  return (
    <CCreateRouletteItem.SelectField
      name="couponId"
      label="Select Coupon"
      data={selectData}
    />
  );
};

interface DiscountSearchFieldProps {
  enabled: boolean;
  type: "PERCENTAGE" | "AMOUNT";
}

const DiscountSearchField = (props: DiscountSearchFieldProps) => {
  const { data } = trpc.v2_5.discounts.getLoyaltyDiscountsByType.useQuery(
    {
      type: props.type,
    },
    {
      enabled: props.enabled,
    },
  );

  const selectData = useMemo(
    () =>
      data?.map((e) => ({
        label: `${e.reference} ${e.amount}%`,
        id: e.id.toString(),
      })) ?? [],
    [data],
  );

  return (
    <CCreateRouletteItem.SelectField
      name="discountId"
      label="Select Discount"
      data={selectData}
    />
  );
};

const validateFields = (values: FormRouletteItemValues) => {
  if (values.type === "TEXT" && !values.link) {
    return {
      title: "Link is required",
      message: "Link is required for text type",
    };
  }

  if (values.type === "FREE_ITEM" && !values.itemSku) {
    return {
      title: "Item SKU is required",
      message: "Item SKU is required for free item type",
    };
  }

  if (
    (values.type === "COUPON" || values.type === "FREE_SHIPPING_CODE") &&
    !values.couponId
  ) {
    return {
      title: "Coupon is required",
      message: "Coupon is required for coupon type or free shipping code type",
    };
  }

  if (
    (values.type === "FIXED_DISCOUNT" ||
      values.type === "PERCENTAGE_DISCOUNT") &&
    !values.discountId
  ) {
    return {
      title: "Discount is required",
      message:
        "Discount is required for fixed discount type or percentage discount type",
    };
  }

  return null;
};
