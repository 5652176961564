import { DocumentTextOutlineIcon } from "#/components-ng";
import Card from "#/components/Card/index.js";
import SceneSectionLayout from "#/components/SceneSectionLayout/index.jsx";
import {
	UsersIcon,
	MilestonesIcon,
	EntitiyIcon,
	FilialIcon,
} from "#/components/icons/index.jsx";
import { useAuth } from "#/context/AuthContext";
import * as M from "@mantine/core";
import { FlagIcon } from "lucide-react";

export const Settings = () => {
	const [{ auth }] = useAuth();

	return (
		<SceneSectionLayout title="Admin">
			<M.Group spacing={50}>
				<Card
					icon={<UsersIcon size="big" />}
					text="Users"
					path="admin/users"
					module="settings.users"
				/>
				<Card
					icon={<MilestonesIcon size="big" />}
					text="Milestones"
					path="admin/milestones"
					module="settings.milestones"
				/>
				<Card
					icon={<EntitiyIcon size="big" />}
					text="Entity"
					path="admin/entity"
					module="settings.entity"
				/>
				<Card
					icon={<FilialIcon size="big" />}
					text="Filial"
					path="admin/filial"
					module="settings.filial"
				/>
				<Card
					icon={<FlagIcon strokeWidth={1.5} size="32" />}
					text="Feature flags"
					path="admin/feature-flags"
					module="settings.featureFlags"
				/>
				{auth?.role?.name === "DEV" && (
					<Card
						icon={<DocumentTextOutlineIcon fontSize={30} />}
						text="Changelog"
						path="admin/changelog"
					/>
				)}
			</M.Group>
		</SceneSectionLayout>
	);
};
