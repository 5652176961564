import { cn } from "#/lib/utils";
import * as M from "@mantine/core";
import React from "react";
import { MdSearch } from "react-icons/md/index.js";

export const WidgetContainer = React.forwardRef(
  (
    { children, sx, ...props }: M.BoxProps,
    ref: React.ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <M.Box
        p="30px"
        sx={[
          (t) => ({
            background: "white",
            borderRadius: t.radius.md,
            position: "relative",
          }),
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
        ref={ref}
        {...props}
      >
        {children}
      </M.Box>
    );
  }
);

export const SearchableInput = React.forwardRef(
  (props: M.TextInputProps, ref: React.ForwardedRef<HTMLInputElement>) => {
    return (
      <M.TextInput
        icon={<MdSearch />}
        variant="filled"
        className="w-full max-w-sm"
        styles={(t) => ({
          input: {
            borderRadius: t.radius.lg,
            background: t.colors.gray[0],
          },
        })}
        ref={ref}
        {...props}
      />
    );
  }
);

interface DetailSalesBoxProps {
  title: string;
  total: number;
  color: string;
  icon: React.ReactNode;
}

export const DetailSalesBox = ({
  title,
  total,
  color,
  icon,
}: DetailSalesBoxProps) => {
  return (
    <M.Box className="w-full rounded-md border border-gray-200 p-5">
      <M.Stack spacing="sm">
        <M.Box
          className={cn(
            "flex items-center justify-center",
            "w-12 rounded-full p-2.5",
            color
          )}
        >
          {icon}
        </M.Box>
        <M.Text weight={500} size="lg">
          ${total.toFixed(2)}
        </M.Text>
        <M.Text color="dimmed" size="sm">
          {title}
        </M.Text>
      </M.Stack>
    </M.Box>
  );
};

interface BadgeDetailsProps {
  title: string;
  total: number;
  isMoney: boolean;
}

export const BadgeDetails = ({ title, total, isMoney }: BadgeDetailsProps) => {
  return (
    <M.Badge size="xl" bg="#F0F0F0">
      <M.Text size="xs" className="text-gray-500" weight={400}>
        {title}:{" "}
        <span className="font-medium">
          {isMoney ? `$${total.toFixed(2)}` : total}
        </span>
      </M.Text>
    </M.Badge>
  );
};
