import { trpc } from "#/trpc.js";
import { reportUserError } from "#/util/index.js";
import { customerAtom } from "./state/index.js";
import * as C from "@chakra-ui/react";
import * as M from "@mantine/core";
import { EditCustomer } from "#/components/Form/complex/EditCustomer/EditCustomer.js";
import { useAtom } from "jotai";

export function EditCustomerModal(disclosure: C.UseDisclosureReturn) {
  const [customerSelected, setCustomer] = useAtom(customerAtom);
  const customerId = Number(customerSelected?.id);
  const { data } = trpc.user.getOneById.useQuery(
    // We need to pass a valid number so we use `0` if we don't have a customer selected.
    // This is not a problem since `enabled` will be false.
    { id: customerId },
    {
      cacheTime: 0,
      enabled: Number.isFinite(customerId),
      onError(error) {
        reportUserError({
          title: "Failed to get customer",
          message: error.message,
        });
      },
    }
  );

  if (!data) {
    return <></>;
  }

  return (
    <M.Modal
      size={1100}
      opened={disclosure.isOpen}
      onClose={disclosure.onClose}
      withCloseButton={false}
      zIndex={1200}
    >
      <EditCustomer
        key={data.id}
        customer={data as any}
        customerId={data.id}
        onCancel={disclosure.onClose}
        onSuccess={(newUser) => {
          setCustomer(newUser);
          disclosure.onClose();
        }}
      />
    </M.Modal>
  );
}
