import { CloseOutline } from "#/components-ng/index.js";
import { cn } from "#/lib/utils.js";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  ImageUpload,
  UseS3UploadResult,
} from "#/components-ng/ui/index.js";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  ActionIcon,
  Button,
  Drawer,
  LoadingOverlay,
  TextInput,
  Textarea,
  Title,
} from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import { useForm } from "react-hook-form";
import { z } from "zod";

const tagSchema = z.object({
  name: z
    .string()
    .nonempty({ message: "Tag name is required" })
    .min(3, { message: "Tag name must be at least 3 characters long" }),
  description: z.string().optional().nullable(),
  defaultImage: z
    .object({
      uid: z.string(),
      url: z.string(),
      isLoading: z.boolean(),
    })
    .optional()
    .nullish(),
});

export type TagSchema = z.infer<typeof tagSchema>;
export type TagFormDrawerProps = {
  opened: boolean;
  onClose: () => void;
  onSubmit: (values: TagSchema) => void;
  defaultValues?: TagSchema;
  loading: boolean;
};
type Image = { uid: string; url: string; isLoading: boolean };

export const TagFormDrawer = (props: TagFormDrawerProps) => {
  const form = useForm<TagSchema>({
    resolver: zodResolver(tagSchema),
    defaultValues: props.defaultValues
      ? {
          name: props.defaultValues.name,
          description: props.defaultValues?.description ?? null,
          defaultImage: props.defaultValues?.defaultImage,
        }
      : {
          name: "",
          description: null,
          defaultImage: null,
        },
  });

  const handleUploadImage = (image: Image) => {
    form.setValue("defaultImage", image);
  };

  const handleDeleteImage = () => {
    openConfirmModal({
      title: "Are you sure you want to delete this image?",
      labels: {
        confirm: "Delete",
        cancel: "Cancel",
      },
      confirmProps: { color: "red" },
      onConfirm: () => {
        form.trigger("defaultImage");
        form.setValue("defaultImage", null);
      },
    });
  };

  const handleUploadImageFinish = (image: UseS3UploadResult) => {
    form.trigger("defaultImage");
    form.setValue("defaultImage", {
      uid: image.uuid,
      url: image.url!,
      isLoading: false,
    });
  };

  const defaultImage = form.watch("defaultImage");

  return (
    <Drawer
      opened={props.opened}
      onClose={() => {
        form.reset();
        props.onClose();
      }}
      padding={"xl"}
      closeOnEscape={false}
      closeOnClickOutside={false}
      position="right"
      size="md"
    >
      <Title order={3} mb={"md"}>
        {props.defaultValues ? "Edit tag" : "Create new tag"}
      </Title>

      <form
        onSubmit={form.handleSubmit((values) => {
          form.reset();
          props.onSubmit(values);
        })}
      >
        <Form {...form}>
          <FormField
            control={form.control}
            name="defaultImage"
            render={() => (
              <FormItem>
                <ImageUpload
                  directory="tags"
                  onS3UploadingStart={(s3Object) => {
                    handleUploadImage({
                      uid: s3Object.uuid,
                      url: s3Object.blobUrl!,
                      isLoading: true,
                    });
                  }}
                  onS3UploadSuccess={handleUploadImageFinish}
                  supports={{ image: true }}
                />
                <FormMessage />
              </FormItem>
            )}
          />
          <div
            className={cn(
              "grid grid-cols-5 items-start gap-x-3",
              defaultImage && "mt-6"
            )}
          >
            {defaultImage && (
              <div key={defaultImage.url} className={"relative"}>
                <LoadingOverlay
                  visible={defaultImage.isLoading}
                  overlayBlur={2}
                />
                <img src={defaultImage.url} className={"rounded-md"} />
                <div
                  className={cn(
                    "absolute -right-2 -top-2",
                    defaultImage.isLoading && "hidden"
                  )}
                >
                  <ActionIcon
                    color="red"
                    size="sm"
                    variant="filled"
                    className="rounded-full"
                    onClick={() => handleDeleteImage()}
                  >
                    <CloseOutline />
                  </ActionIcon>
                </div>
              </div>
            )}
          </div>

          <div className={"mt-8 grid gap-y-4"}>
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <TextInput label="Name" {...field} withAsterisk />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Textarea
                      label="Description"
                      autosize
                      minRows={3}
                      maxRows={12}
                      {...field}
                      value={field.value ?? undefined}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <Button
            type="submit"
            className={"mt-8 px-8"}
            loading={!!form.watch("defaultImage")?.isLoading || props.loading}
          >
            {props.defaultValues ? "Update tag" : "Create tag"}
          </Button>
        </Form>
      </form>
    </Drawer>
  );
};
