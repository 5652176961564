import { RouterOutputs } from "#/trpc";
import {
  MantineReactTable,
  MRT_ColumnDef,
  useMantineReactTable,
} from "mantine-react-table";
import { styled } from "#/css/jsx";
import { css } from "#/css/css";
import placeholderImage from "#/placeholder-image.jpg";
import { IconButton, Tooltip } from "@radix-ui/themes";
import { CircleWavyWarningIcon, CreateOutline } from "#/components-ng";
import { useDisclosure } from "@mantine/hooks";
import { ReceivedItemModal } from "./received-item-modal";

type PurchaseOrderItemSku =
  RouterOutputs["v2_5"]["purchaseOrder"]["getById"]["purchaseOrderItemSku"][number];

interface PurchaseOrderItemSkuTableProps {
  purchaseOrderItemSkus?: PurchaseOrderItemSku[];
}

export const PurchaseOrderItemSkuTable = (
  props: PurchaseOrderItemSkuTableProps,
) => {
  const table = useMantineReactTable({
    columns,
    data: props.purchaseOrderItemSkus ?? [],
  });

  return <MantineReactTable table={table} />;
};

const columns: MRT_ColumnDef<PurchaseOrderItemSku>[] = [
  {
    id: "image",
    header: "Image",
    size: 100,
    Cell: (table) => {
      const imageUrl =
        table.row.original.itemSku.defaultImage ?? placeholderImage;
      return (
        <styled.img
          src={imageUrl}
          onError={(e) =>
            ((e.target as HTMLImageElement).src = placeholderImage)
          }
          width={50}
          height={50}
        />
      );
    },
  },

  {
    id: "itemSku.sku",
    header: "SKU",
    accessorKey: "itemSku.sku",
    size: 100,
    Cell: (table) => {
      const sku = table.row.original.itemSku.sku;
      const recentPriceUpdate = table.row.original.recentPriceUpdate;
      return (
        <styled.div display="flex" alignItems="center" gap="2">
          <styled.p>{sku}</styled.p>
          {recentPriceUpdate && (
            <Tooltip content="Price update">
              <IconButton variant="ghost">
                <CircleWavyWarningIcon />
              </IconButton>
            </Tooltip>
          )}
        </styled.div>
      );
    },
  },
  {
    id: "itemSku.price",
    header: "Price",
    size: 100,
    accessorKey: "itemSku.price",
    Cell: (table) => {
      return `$${table.row.original.itemSku.price}`;
    },
  },
  {
    id: "itemSku.cost",
    header: "Cost",
    accessorKey: "itemSku.cost",
    Cell: (table) => {
      return `$${table.row.original.itemSku.cost?.toFixed(2)}`;
    },
    size: 100,
  },
  {
    id: "itemSku.title",
    header: "Product",
    accessorKey: "itemSku.title",
  },
  {
    header: "PKG",
    size: 100,
    Cell: (table) => {
      const itemSku = table.row.original.itemSku;
      return `${itemSku?.presentationValue ?? "-"} ${itemSku?.presentationType ?? "-"}`;
    },
  },
  {
    id: "expectedQuantity",
    header: "Expected Qty",
    accessorKey: "expectedQuantity",
    size: 100,
  },
  {
    id: "receivedTotalQty",
    header: "Received Qty",
    size: 100,
    Cell: (table) => {
      const receivedQty =
        table.row.original.receivedPurchaseOrderItemSku?.reduce(
          (acc, received) => acc + (received?.receivedQuantity ?? 0),
          0,
        );

      return receivedQty;
    },
  },
  {
    id: "shortfallQty",
    header: "Shortfall qty",
    size: 100,
    Cell: (table) => {
      const expectedQty = table.row.original.expectedQuantity;
      const receivedQty =
        table.row.original.receivedPurchaseOrderItemSku?.reduce(
          (acc, received) => acc + (received?.receivedQuantity ?? 0),
          0,
        );

      return expectedQty - receivedQty;
    },
  },
  {
    id: "actions",
    header: "Receive items",
    size: 100,
    Cell(table) {
      const [opened, { open, close }] = useDisclosure(false);

      const expectedQty = table.row.original.expectedQuantity;
      const receivedQty =
        table.row.original.receivedPurchaseOrderItemSku?.reduce(
          (acc, received) => acc + (received?.receivedQuantity ?? 0),
          0,
        );

      const shortfallQty = expectedQty - receivedQty;

      return (
        <>
          {opened && (
            <ReceivedItemModal
              opened={opened}
              onClose={close}
              purchaseOrderItemSku={table.row.original}
            />
          )}
          <styled.div
            width="100%"
            display="flex"
            alignItems="center"
            alignContent="center"
          >
            <IconButton
              color="gray"
              variant="soft"
              disabled={shortfallQty <= 0}
              onClick={open}
              className={css({ cursor: "pointer!" })}
            >
              <CreateOutline />
            </IconButton>
          </styled.div>
        </>
      );
    },
  },
];
