import React from "react";
import * as C from "@chakra-ui/react";
import { Controller } from "react-hook-form";

/**
 *
 * @param {{ name?: string, control?: any, children?: any} & import("@chakra-ui/react").NumberInputProps} props
 * @returns
 */
export default function NumberInput({ name, control, children, ...props }) {
  if (control) {
    return (
      <Controller
        control={control}
        name={name}
        defaultValue={props.defaultValue}
        render={({ field: { value, onChange, ...field } }) => (
          <InnerNumberInput
            onChange={(_, v) => {
              onChange(v);
            }}
            {...field}
            {...props}
            value={value}
          />
        )}
      />
    );
  } else {
    return <InnerNumberInput name={name} {...props} />;
  }
}

const InnerNumberInput = React.forwardRef(({ id, ...props }, ref) => (
  <C.NumberInput size="sm" {...props}>
    <C.NumberInputField
      ref={ref}
      id={id}
      name={props.name}
      bg="white"
      borderRadius="md"
      _hover={{ borderColor: "brand.100" }}
      _focus={{ bg: "brand.50", border: "solid 2px", borderColor: "brand.200" }}
    />
    <C.NumberInputStepper>
      <C.NumberIncrementStepper />
      <C.NumberDecrementStepper />
    </C.NumberInputStepper>
  </C.NumberInput>
));
