import styled from "styled-components";

const StyledSceneSectionLayout = styled.div`
  width: 100%;
`;

const StyledTitle = styled.div`
  margin-bottom: 1.2em;
`;

export default StyledSceneSectionLayout;
export { StyledTitle };
