/* eslint-disable @typescript-eslint/ban-types */
import { Checkbox, NumberInput, Switch } from "@mantine/core";
import React from "react";
import {
  Control,
  FieldPath,
  FieldValues,
  useController,
  useFormContext,
} from "react-hook-form";

export interface MantineControllerProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  TContext extends object = any,
  Props extends object = {}
> {
  wrap?: React.ComponentType<Props>;
  as?: React.ComponentType<Props>;
  name: TName;
  defaultValue?: any;
  value?: any;
  control?: Control<TFieldValues, TContext>;
  ctx?: TFieldValues;
  noNullish?: boolean;
}

export const MantineController = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  TContext extends object = any,
  Props extends object = {}
>(
  props: MantineControllerProps<TFieldValues, TName, TContext, Props> &
    Omit<Props, "name">
) => {
  // We provide `as` as an alternative because `wrap` is a prop in some elements (e.g. textarea)
  const Child = props.wrap ?? props.as!;
  const context = useFormContext<TFieldValues>();
  if (!context) {
    throw new Error("component must be used under a FormProvider");
  }

  const {
    field,
    fieldState: { error },
  } = useController({
    control: props.control ?? context.control,
    name: props.name,
    defaultValue: props.defaultValue,
    rules: {
      required: (props["isRequired"] || props["required"]) && "Field required",
    },
  });

  const { defaultValue, ctx, name, control, as, noNullish, ...childProps } =
    props;

  let onChange: any = field.onChange;
  let valueProps: any = { value: props.value ?? field.value };
  if (Child === Switch || Child === Checkbox) {
    onChange = (e) => field.onChange((e as any).currentTarget.checked);
    valueProps = { checked: props.value ?? field.value };
  } else if (Child === NumberInput) {
    onChange = (v?: number) => {
      if (v == null && noNullish) {
        field.onChange(0 as any);
      } else {
        field.onChange(v as any);
      }
    };
  }

  return (
    // @ts-ignore
    <Child
      {...field}
      {...childProps}
      error={error?.message}
      onChange={(...params) => {
        onChange(...params);
        // @ts-ignore
        if (props.onChange) {
          // @ts-ignore
          props.onChange(...params);
        }
      }}
      {...valueProps}
    />
  );
};

/**
 *
 * @example
 * const Mc = makeMantineController({ ctx: {} as InputProduct });
 * // error: `descriatiption` is not a name in `InputProduct`
 * <Mc as={M.TextInput} name="descriatiption" />
 */
export const makeMantineController =
  <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
    TContext extends object = any
  >({
    ctx,
  }: {
    ctx: TFieldValues;
  }) =>
  // eslint-disable-next-line react/display-name
  <Props extends object = {}>(
    props: MantineControllerProps<TFieldValues, TName, TContext, Props> &
      Omit<Props, "name">
  ) =>
    <MantineController {...props} ctx={ctx} />;
