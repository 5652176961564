import { THEME } from "./enums.js";
import { themes } from "./styles.js";
import React, { createContext, useState } from "react";
import { ThemeProvider } from "styled-components";

export const ChangeThemeContext = createContext();

const ThemeContext = ({ children }) => {
  const [themeName, changeThemeName] = useState(THEME.Light);
  const theme = themes[themeName];

  return (
    <ChangeThemeContext.Provider value={changeThemeName}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ChangeThemeContext.Provider>
  );
};

export default ThemeContext;
