/* eslint-disable @typescript-eslint/no-explicit-any */
import { DisplayTable } from "#/components/index.js";
import React from "react";

export interface PrintTableProps {
  columns: {
    [x: string]: any;
  };
  data: any[];
}

export default function PrintTable() {
  const [tableJson] = React.useState(() =>
    window.localStorage.getItem("tableToPrint")
  );
  if (!tableJson) {
    return <h1>No table to print</h1>;
  }
  const table = React.useMemo<PrintTableProps>(() => JSON.parse(tableJson), []);

  React.useEffect(() => {
    window.addEventListener("load", () => {
      window.print();
    });
  }, []);

  return <DisplayTable {...table} style={{ fontSize: "xs" }} />;
}
