import {
  ChevronBackOutline,
  CircleWavyWarningIcon,
  Eye,
} from "#/components-ng/index.js";
import { DisplayTable } from "#/components/index.js";
import { cn } from "#/lib/utils.js";
import { trpc } from "#/trpc.js";
import * as M from "@mantine/core";
import { atom, useAtom, useAtomValue, useSetAtom } from "jotai";
import { Fragment, useMemo, useState } from "react";
import type { OrderItemSku, RefundOrderItemSkuReason } from "server";

export interface ReturnedOrderItemSku {
  orderItemSku: OrderItemSku;
  quantity: number;
  reason: RefundOrderItemSkuReason;
}
interface MaybeReturnedOrderItemSku {
  orderItemSku: OrderItemSku;
  quantity: number;
  reason: RefundOrderItemSkuReason;
  isReturned: boolean;
}

const shownMaybeReturnedOrderItemSkusAtom = atom<MaybeReturnedOrderItemSku[]>(
  []
);
const baseOrderIdAtom = atom<number | null>(null);
const orderIdAtom = atom(
  (get) => get(baseOrderIdAtom),
  (_get, set, payload: number | null) => {
    set(shownMaybeReturnedOrderItemSkusAtom, []);
    set(baseOrderIdAtom, payload);
  }
);
export const returnedOrderItemSkusAtom = atom<ReturnedOrderItemSku[]>([]);

export type OrdersModalProps = {
  onSubmit: (orderItemSkus: ReturnedOrderItemSku[]) => void;
  disabled: boolean;
  customerId?: number | null;
  returnedOrderItemSkus: ReturnedOrderItemSku[];
};
export function OrdersModal(props: OrdersModalProps) {
  const setReturnedItemSkus = useSetAtom(returnedOrderItemSkusAtom);
  const [isOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useState("");
  const ordersQuery = trpc.order.searchWithCustomer.useQuery(
    {
      customerId: props.customerId ?? -1,
      query,
      limit: 25,
    },
    {
      cacheTime: 0,
      enabled: !!props.customerId,
    }
  );
  const tableData = useMemo(
    () =>
      ordersQuery.data?.map((order) => ({
        ...order,
        $$customerFullName: `${order.customer?.firstName ?? ""} ${
          order.customer?.lastName ?? ""
        }`,
      })) ?? [],
    [ordersQuery.data]
  );
  const [orderId, setOrderId] = useAtom(orderIdAtom);

  function handleAddToReturns(v: ReturnedOrderItemSku[]) {
    props.onSubmit(v);
    setIsOpen(false);
    setOrderId(null);
  }

  useEffect(() => {
    setReturnedItemSkus(props.returnedOrderItemSkus);
  }, [props.returnedOrderItemSkus, setReturnedItemSkus]);

  useEffect(
    () => setOrderId(null),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Fragment>
      <M.Button
        color="darkGray.7"
        variant="light"
        className="px-6"
        onClick={() => setIsOpen(true)}
        disabled={props.disabled}
      >
        Orders
      </M.Button>
      <M.Modal
        title={
          <div className="flex gap-x-2">
            <M.ActionIcon
              className={cn("hidden", orderId && "block")}
              onClick={() => {
                setOrderId(null);
              }}
            >
              <ChevronBackOutline />
            </M.ActionIcon>
            <h3>{orderId ? `Order ${orderId}` : "Orders"}</h3>
          </div>
        }
        opened={isOpen}
        onClose={() => {
          setIsOpen(false);
          setOrderId(null);
        }}
        classNames={{
          content: "max-h-[90vh] overflow-auto",
        }}
        size="auto"
      >
        {orderId != null ? (
          <OrderContent orderId={orderId} onAddToReturns={handleAddToReturns} />
        ) : (
          <div>
            <M.TextInput
              label="Search order"
              onChange={(e) => setQuery(e.currentTarget.value)}
              className="mb-4"
            />
            <DisplayTable
              columns={orderColumns}
              data={tableData}
              isLoading={ordersQuery.isLoading}
            />
          </div>
        )}
      </M.Modal>
    </Fragment>
  );
}

type OrderContentProps = {
  orderId: number;
  onAddToReturns: (orderItemSkus: ReturnedOrderItemSku[]) => void;
};
function OrderContent(props: OrderContentProps) {
  const [shownMaybeReturnedOrderItemSkus, setShownMaybeReturnedOrderItemSkus] =
    useAtom(shownMaybeReturnedOrderItemSkusAtom);
  trpc.order.getById.useQuery(
    {
      id: props.orderId,
    },
    {
      refetchInterval: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      onSuccess(data) {
        setShownMaybeReturnedOrderItemSkus(
          data?.orderItemSku.map((orderItemSku) => ({
            orderItemSku,
            quantity: 1,
            reason: "DAMAGED",
            isReturned: false,
          })) ?? []
        );
      },
    }
  );

  function handleAddToReturns() {
    const orderItemSkus = shownMaybeReturnedOrderItemSkus.filter(
      (item) => item.isReturned
    );
    props.onAddToReturns(orderItemSkus);
  }

  return (
    <div className="grid gap-y-2">
      <div className="max-h-[min(600px,90vh)] overflow-auto">
        <DisplayTable
          columns={orderItemSkuColumns}
          data={shownMaybeReturnedOrderItemSkus}
        />
      </div>
      <M.Button className="justify-self-end" onClick={handleAddToReturns}>
        Add to returns
      </M.Button>
    </div>
  );
}

const orderColumns = [
  {
    Header: "Actions",
    id: "actions",
    Cell: ({ row: { original } }) => {
      const setOrderId = useSetAtom(orderIdAtom);
      return (
        <M.ActionIcon onClick={() => setOrderId(original.id)}>
          <Eye />
        </M.ActionIcon>
      );
    },
  },
  {
    Header: "Order ID",
    accessor: "receiptNumber",
  },
  {
    Header: "Date",
    accessor: "createdAt",
    Cell: "date",
  },
  {
    Header: "Customer",
    accessor: "$$customerFullName",
  },
];

const orderItemSkuColumns = [
  {
    Header: "SKU",
    accessor: "orderItemSku.itemSku.sku",
  },
  {
    Header: "Product",
    accessor: "orderItemSku.itemSku.title",
    Cell: ({ row: { original } }) => {
      const hasPreviousReturns =
        original.orderItemSku.refundOrderItemSku.length;

      return (
        <M.Group>
          <M.Text>{original.orderItemSku.itemSku.title}</M.Text>
          {hasPreviousReturns > 0 && (
            <M.Tooltip label="This item has already a return/exchange">
              <M.ActionIcon>
                <CircleWavyWarningIcon />
              </M.ActionIcon>
            </M.Tooltip>
          )}
        </M.Group>
      );
    },
  },
  {
    Header: "Order Qty",
    accessor: "orderItemSku.quantity",
    Cell: ({ value }) => {
      return <M.Text className="text-center">{value}</M.Text>;
    },
  },
  {
    Header: "Available Return Qty",
    Cell: ({ row: { original } }) => {
      const maxQtyToReturn = useGetMaxQtyToReturn(original);
      return <M.Text className="text-center">{maxQtyToReturn}</M.Text>;
    },
  },
  {
    Header: "Allow return",
    accessor: "isReturned",
    Cell: ({ row: { original, index } }) => {
      const setMaybeReturnedOrderItemSkus = useSetAtom(
        shownMaybeReturnedOrderItemSkusAtom
      );
      const maxQtyToReturn = useGetMaxQtyToReturn(original);

      return (
        <M.Checkbox
          disabled={maxQtyToReturn === 0}
          checked={original.isReturned}
          onChange={(e) => {
            setMaybeReturnedOrderItemSkus((prev) =>
              prev.map((item, i) => {
                if (index !== i) {
                  return item;
                }
                return {
                  ...item,
                  isReturned: e.currentTarget.checked,
                };
              })
            );
          }}
          className="flex justify-center"
        />
      );
    },
  },
  {
    Header: "Return qty",
    accessor: "quantity",
    Cell: ({ value, row: { original, index } }) => {
      const setMaybeReturnedOrderItemSkus = useSetAtom(
        shownMaybeReturnedOrderItemSkusAtom
      );
      const maxQtyToReturn = useGetMaxQtyToReturn(original);

      return (
        <M.NumberInput
          max={maxQtyToReturn}
          value={original.isReturned ? value : 0}
          disabled={!original.isReturned || maxQtyToReturn === 0}
          className="max-w-[12ch]"
          onChange={(v) => {
            if (typeof v === "number") {
              setMaybeReturnedOrderItemSkus((prev) =>
                prev.map((item, i) => {
                  if (index != i) return item;
                  return {
                    ...item,
                    quantity: v,
                  };
                })
              );
            }
          }}
        />
      );
    },
  },
  {
    Header: "Reason",
    accessor: "reason",
    Cell: ({ value, row: { index, original } }) => {
      const setMaybeReturnedOrderItemSkus = useSetAtom(
        shownMaybeReturnedOrderItemSkusAtom
      );
      return (
        <M.Select
          data={[
            {
              value: "DAMAGED",
              label: "Damaged",
            },
            {
              value: "RETURN",
              label: "Return",
            },
            {
              value: "EXCHANGE",
              label: "Exchange",
            },
          ]}
          value={value}
          disabled={!original.isReturned}
          onChange={(v) => {
            if (v != null) {
              setMaybeReturnedOrderItemSkus((prev) =>
                prev.map((item, i) => {
                  if (index != i) return item;
                  return {
                    ...item,
                    reason: v as any,
                  };
                })
              );
            }
          }}
        />
      );
    },
  },
];

function useGetMaxQtyToReturn(original: any) {
  const returnedItemSkus = useAtomValue(returnedOrderItemSkusAtom);

  const maxQtyToReturn =
    original.orderItemSku.quantity -
    original.orderItemSku.refundOrderItemSku
      ?.filter((refund) => !refund.isReversed)
      ?.reduce((acc, cur) => acc + cur.quantity, 0) -
    returnedItemSkus
      .filter(
        (item) =>
          item.orderItemSku.itemSkuId === original.orderItemSku.itemSkuId
      )
      ?.reduce((acc, cur) => acc + cur.quantity, 0);

  return maxQtyToReturn;
}
