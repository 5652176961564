import { trpc } from "#/trpc";
import { reportUserError, reportUserSuccess } from "#/util";
import { useNavigate, useParams } from "react-router";
import { FormValues } from "./Form/types";
import { RouletteForm } from "./Form";
import { LoadingOverlay } from "@mantine/core";
import GTLoader from "#/components-ng/loader";

export const UpdateRouletteForm = () => {
  const params = useParams() as any;
  const id = params?.id ? Number(params.id) : null;
  const navigate = useNavigate();
  const ctx = trpc.useContext();

  const { data, isLoading, isFetchedAfterMount } =
    trpc.v2_5.roulette.getById.useQuery(
      {
        id: id!,
      },
      {
        enabled: !!id,
        cacheTime: 0,
      },
    );

  const { mutate, isLoading: updateIsLoading } =
    trpc.v2_5.roulette.update.useMutation({
      onSuccess(data) {
        ctx.v2_5.roulette.invalidate();
        reportUserSuccess({
          title: "Roulette updated",
        });
        navigate(`/ecommerce-crm/roulette/edit/${data.id}`);
      },
    });

  const handleSubmit = (values: FormValues) => {
    if (!values.startDate || !values.startDate) {
      reportUserError({
        title: "Please enter a start date and end date.",
      });
      return;
    }

    mutate({
      id: id!,
      title: values.title,
      description: values.description,
      startDate: values.startDate!,
      endDate: values.endDate!,
      active: values.active,
    });
  };

  return isLoading || !isFetchedAfterMount ? (
    <LoadingOverlay
      loader={<GTLoader />}
      visible={isFetchedAfterMount || isLoading}
    />
  ) : (
    <RouletteForm
      isLoading={updateIsLoading}
      onSubmit={handleSubmit}
      defaultValues={{
        title: data?.title,
        description: data?.description,
        startDate: data?.startDate,
        endDate: data?.endDate,
        active: data?.active,
      }}
      rouletteId={id}
      rouletteItems={data?.rouletteItems ?? []}
    />
  );
};
