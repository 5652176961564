import { useNavigate, useParams } from "react-router";
import { useState, useMemo } from "react";
import { RouterOutputs, trpc } from "#/trpc";
import { LoadingOverlay } from "@mantine/core";
import GTLoader from "#/components-ng/loader";
import { css } from "#/css/css";
import { styled } from "#/css/jsx";
import { PurchaseOrderItemSkuTable } from "./table";
import { Badge, Flex, TextArea } from "@radix-ui/themes";
import { match } from "ts-pattern";
import { Button, Popover } from "@gt/ui";
import { reportUserError, reportUserSuccess } from "#/util";
import * as M from "@mantine/core";
import AlertIcon from "~icons/ion/alert-circle-outline";

type PurchaseOrder = RouterOutputs["v2_5"]["purchaseOrder"]["getById"];

export function ReceiveItems() {
  const params = useParams() as any;
  const poId = Number(params.id);

  const { data, isLoading, isFetchedAfterMount } =
    trpc.v2_5.purchaseOrder.getById.useQuery(
      {
        id: poId,
      },
      {
        enabled: !!poId,
        refetchInterval: 0,
        refetchOnWindowFocus: false,
      },
    );

  const itemSkus = useMemo(
    () => data?.purchaseOrderItemSku ?? [],
    [data?.purchaseOrderItemSku],
  );

  return isLoading || !isFetchedAfterMount ? (
    <LoadingOverlay
      visible={isLoading || isFetchedAfterMount}
      loader={<GTLoader width={100} height={100} />}
    />
  ) : (
    <>
      <PurchaseOrderHeader purchaseOrder={data} />
      <PurchaseOrderItemSkuTable purchaseOrderItemSkus={itemSkus} />
    </>
  );
}

interface PurchaseOrderHeaderProps {
  purchaseOrder?: PurchaseOrder | null;
}

const PurchaseOrderHeader = (props: PurchaseOrderHeaderProps) => {
  const badgeColor = match(props.purchaseOrder?.status)
    .with("UPCOMING", () => "red")
    .with("PARTIALLY_RECEIVED", () => "orange")
    .with("RECEIVED", () => "green")
    .otherwise(() => "gray");

  return (
    <styled.div
      className={css({
        width: "100%",
        backgroundColor: "white",
        padding: "40px",
        borderRadius: "lg",
        marginBottom: "10px",
      })}
    >
      <Flex align="center" justify="between">
        <styled.div
          className={css({
            display: "flex",
            gap: "1rem",
          })}
        >
          <p
            className={css({
              color: "black",
              fontSize: "16px",
              fontWeight: "bold",
            })}
          >
            Purchase order: #{props.purchaseOrder?.id}
          </p>
          <p
            className={css({
              color: "black",
              fontSize: "16px",
              fontWeight: "bold",
            })}
          >
            |
          </p>
          <p
            className={css({
              color: "black",
              fontSize: "16px",
              fontWeight: "medium",
            })}
          >
            Status:{" "}
            <Badge size="2" radius="full" color={badgeColor as any}>
              {props.purchaseOrder?.status}
            </Badge>
          </p>
        </styled.div>
        <styled.div
          className={css({
            display: "flex",
            gap: "1rem",
          })}
        >
          <NoteButton
            purchaseOrderId={props.purchaseOrder?.id ?? 0}
            note={props.purchaseOrder?.note}
          />

          <VerifyCustomerWaitingList
            purchaseOrderId={props.purchaseOrder?.id}
          />
        </styled.div>
      </Flex>
    </styled.div>
  );
};

interface NoteButtonProps {
  purchaseOrderId: number;
  note?: string | null;
}

const NoteButton = (props: NoteButtonProps) => {
  const ctx = trpc.useContext();
  const [note, setNote] = useState<string | null>(props.note ?? null);

  const { mutate, isLoading } = trpc.v2_5.purchaseOrder.updateNote.useMutation({
    onSuccess() {
      ctx.v2_5.purchaseOrder.invalidate();
      reportUserSuccess({
        title: "Note updated",
        message: "Note updated successfully",
      });
    },
  });

  return (
    <Popover.Root>
      <Popover.Trigger>
        <Button>Update note</Button>
      </Popover.Trigger>
      <Popover.Content>
        <div
          className={css({
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            padding: "1rem",
          })}
        >
          <TextArea
            value={note ?? ""}
            onChange={(e) => setNote(e.target.value)}
            size="3"
          />
          <div
            className={css({
              display: "flex",
              justifyContent: "flex-end",
            })}
          >
            <Button
              onClick={() => mutate({ id: props.purchaseOrderId, note })}
              className={css({
                width: "fit",
              })}
              isLoading={isLoading}
            >
              Save
            </Button>
          </div>
        </div>
      </Popover.Content>
    </Popover.Root>
  );
};

interface VerifyCustomerWaitingListProps {
  purchaseOrderId?: number | null;
}

const VerifyCustomerWaitingList = (props: VerifyCustomerWaitingListProps) => {
  const [openedModalWaitingList, setOpenedModalWaitingList] =
    useState<boolean>(false);

  const { mutate, isLoading } =
    trpc.userWaitingList.verifyWaitingCustomers.useMutation({
      onError(err) {
        reportUserError({
          title: "Failed to get waiting customers",
          message: err.message,
        });
      },
      onSuccess(data) {
        if (data.length > 0) {
          setOpenedModalWaitingList(true);
        }
      },
    });

  return (
    <>
      {openedModalWaitingList && (
        <VerifyCustomerWaitingListModal
          onClose={() => setOpenedModalWaitingList(false)}
          opened={openedModalWaitingList}
          purchaseOrderId={props.purchaseOrderId ?? 0}
          setOpenedModal={setOpenedModalWaitingList}
        />
      )}
      <Button
        variant="secondary"
        className={css({ width: "fit" })}
        isLoading={isLoading}
        onClick={() => {
          if (!props.purchaseOrderId) {
            return;
          }

          mutate({ purchaseOrderId: props.purchaseOrderId });
        }}
      >
        Verify customer waiting list
      </Button>
    </>
  );
};

interface VerifyCustomerWaitingListModalProps {
  purchaseOrderId: number;
  opened: boolean;
  onClose: () => void;
  setOpenedModal: (value: boolean) => void;
}

const VerifyCustomerWaitingListModal = (
  props: VerifyCustomerWaitingListModalProps,
) => {
  const navigate = useNavigate();
  return (
    <M.Modal
      opened={props.opened}
      onClose={() => props.onClose()}
      closeOnClickOutside={false}
      closeOnEscape={false}
      centered
      withCloseButton={false}
      sx={{
        maxWidth: "500",
      }}
    >
      <M.Stack spacing={"md"}>
        <M.Flex align="start">
          <M.Stack spacing="xs" mr={10}>
            <AlertIcon fontSize={20} color="#FFC711" />
          </M.Stack>
          <M.Stack spacing="xs">
            <M.Text color="#424242" weight="500" size="lg">
              Waiting list
            </M.Text>
            <M.Text color="#757575" size="sm">
              Customers are eagerly awaiting the arrival of new products. Are
              you ready to notify them?
            </M.Text>
          </M.Stack>
        </M.Flex>
        <M.Group position="right" mt="sm">
          <M.Button
            color="brand"
            onClick={() => {
              props.setOpenedModal(false);
              navigate(
                `/inventory/upcoming-items/receive/waiting-list/${props.purchaseOrderId}`,
              );
            }}
            className={css({
              fontWeight: 500,
            })}
          >
            Yes, notify
          </M.Button>
          <M.Button
            bg="#EDF8FF"
            c="#4DBAFF"
            onClick={() => {
              props.setOpenedModal(false);
            }}
            className={css({
              fontWeight: 500,
            })}
          >
            Not yet
          </M.Button>
        </M.Group>
      </M.Stack>
    </M.Modal>
  );
};
