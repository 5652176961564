import {
	ImageUpload,
	UseS3UploadObject,
	UseS3UploadResult,
} from "#/components-ng/ui"
import { makeMantineController } from "#/components/Form/v3/index.js"
import { trpc } from "#/trpc.js"
import { getDiscountLabel } from "#/util/discounts"
import { reportUserError } from "#/util/index.js"
import { zodResolver } from "@hookform/resolvers/zod"
import * as M from "@mantine/core"
import { DatePickerInput } from "@mantine/dates"
import React from "react"
import {
	Controller,
	FormProvider,
	useController,
	useForm,
	useFormContext,
} from "react-hook-form"
import { v4 as uuidv4 } from "uuid"
import { z } from "zod"
import CloseIcon from "~icons/ion/close-circle-outline"

const DEFAULT_ACCENT_COLOR = "#46636e"

interface ImageUploadProps {
	url: string
	uuid: string
	isLoading: boolean
}

export const FormValues = z.object({
	name: z.string().nonempty("Required"),
	description: z.string().nonempty("Required"),
	activeRange: z
		.tuple([z.coerce.date(), z.coerce.date()])
		.nullish()
		.refine((v) => v != null, "Required"),
	// ^ We need to add these `refine`s because while `null` is allowed while
	// EDITING the form, it is not allowed when SUBMITTING the form
	discount: z
		.number()
		.nullish()
		.refine((v) => v != null, "Required"),
	active: z.boolean().nullish(),
	defaultImage: z.string().nullish(),
	accentHexColor: z.string().nullish(),
	withTimer: z.boolean().default(false),
})

export type FormValues = z.TypeOf<typeof FormValues>

const Mc = makeMantineController({ ctx: {} as FormValues })

interface PromotionFormProps {
	onSubmit: (values: FormValues) => void
	defaultValues?: Partial<FormValues>
	loading: boolean
}

export const PromotionForm = ({
	onSubmit,
	defaultValues,
	loading,
}: PromotionFormProps) => {
	const form = useForm<FormValues>({
		values: {
			name: defaultValues?.name ?? "",
			description: defaultValues?.description ?? "",
			discount: defaultValues?.discount ?? null,
			activeRange: defaultValues?.activeRange,
			defaultImage: defaultValues?.defaultImage ?? null,
			accentHexColor: defaultValues?.accentHexColor ?? null,
			withTimer: defaultValues?.withTimer ?? false,
		},
		resetOptions: {
			keepDirtyValues: true,
		},
		resolver: zodResolver(FormValues),
	})

	const [defaultImage, setDefaultImage] = useState<ImageUploadProps | null>(
		() =>
			form.getValues(`defaultImage`)
				? {
						url: form.getValues(`defaultImage`)!,
						uuid: uuidv4(),
						isLoading: false,
					}
				: null,
	)

	const handleS3UploadImageStart = (upload: UseS3UploadObject) => {
		setDefaultImage({
			url: upload.url!,
			uuid: upload.uuid,
			isLoading: true,
		})
	}

	const handleS3UploadImageSuccess = (result: UseS3UploadResult) => {
		setDefaultImage({
			url: result.url,
			uuid: result.uuid,
			isLoading: false,
		})
		form.setValue(`defaultImage`, result.url)
	}

	const handleRemoveImage = () => {
		setDefaultImage(null)
		form.setValue(`defaultImage`, null)
	}

	return (
		<M.Container fluid>
			<FormProvider {...form}>
				<form onSubmit={(e) => e.preventDefault()}>
					<M.Group position="center" noWrap>
						<M.Box p="xl" pb={48} sx={{ background: "white" }}>
							<M.Title order={2}>Promotions</M.Title>
							<M.Divider color="gray.2" mt="lg" mx="-xl" />
							<M.Group spacing={"lg"} align="start" position="center">
								<M.Stack pt={48} spacing="xl">
									<Mc
										as={M.TextInput}
										name="name"
										label="Name"
										required
										styles={inputStyles}
									/>
									<Mc
										as={M.Textarea}
										autosize
										minRows={2}
										maxRows={8}
										name="description"
										label="Description"
										required
										styles={inputStyles}
									/>
									<Mc
										as={DatePickerInput}
										type="range"
										name="activeRange"
										label="Active from - to"
										required
										numberOfColumns={2}
										styles={inputStyles}
									/>
									<DiscountInput />
									<Mc as={M.Checkbox} name="withTimer" label="With Timer?" />
									<Controller
										control={form.control}
										name="accentHexColor"
										render={(c) => {
											return (
												<>
													<M.Checkbox
														label="Custom accent color"
														checked={c.field.value != null}
														onChange={(e) => {
															if (!e.currentTarget.checked) {
																c.field.onChange(null)
															} else {
																c.field.onChange(DEFAULT_ACCENT_COLOR)
															}
														}}
													/>
													<M.ColorInput
														label="Accent color"
														onChange={(e) => c.field.onChange(e)}
														value={c.field.value ?? DEFAULT_ACCENT_COLOR}
														error={c.fieldState.error?.message}
														disabled={c.field.value == null}
													/>
												</>
											)
										}}
									/>
								</M.Stack>

								<M.Divider
									color="gray.2"
									mt="lg"
									mx="xl"
									orientation="vertical"
								/>

								<M.Stack pt={48} spacing="xl">
									<M.Text size="sm" color="#495057" weight={500}>
										Default Image
									</M.Text>
									{defaultImage ? (
										<M.Box style={{ position: "relative", width: "100%" }}>
											<M.Box style={{ width: "100%" }}>
												<M.Image
													src={defaultImage.url}
													height={300}
													width={380}
												/>
												<M.ActionIcon
													sx={{
														position: "absolute",
														top: 0,
														right: 0,
													}}
													onClick={handleRemoveImage}
												>
													<CloseIcon fontSize={20} color="red" />
												</M.ActionIcon>
											</M.Box>
										</M.Box>
									) : (
										<ImageUpload
											directory="promotions/optimized"
											supports={{
												image: true,
											}}
											onS3UploadSuccess={handleS3UploadImageSuccess}
											onS3UploadingStart={handleS3UploadImageStart}
											hiddenVisually={true}
										/>
									)}
								</M.Stack>
							</M.Group>
						</M.Box>
					</M.Group>
					<M.Group position="right" mt="lg">
						<M.Button
							type="submit"
							onClick={() => {
								form.handleSubmit(onSubmit)()
							}}
							loading={loading || defaultImage?.isLoading}
						>
							Submit
						</M.Button>
					</M.Group>
				</form>
			</FormProvider>
		</M.Container>
	)
}

const DiscountInput = () => {
	const formCtx = useFormContext<FormValues>()
	const ctrl = useController({
		control: formCtx.control,
		name: "discount",
	})

	const { data, isLoading } = trpc.discount.getByDiscountMode.useQuery(
		{
			discountMode: "PROMOTION",
		},
		{
			cacheTime: 0,
			onError: (err) => {
				reportUserError({
					title: "Failed to get discounts",
					message: err.message,
				})
			},
		},
	)

	const selectData = React.useMemo(
		() =>
			data?.map((e) => ({
				label: `${getDiscountLabel(e)} - ${e?.reference}` ?? "",
				value: e?.id.toString() ?? "",
			})) ?? [],
		[data],
	)
	return (
		<M.Select
			name="discount"
			label="Discount"
			data={selectData}
			searchable
			required
			rightSection={isLoading ? <M.Loader size="xs" /> : undefined}
			styles={inputStyles}
			value={ctrl.field.value?.toString() ?? undefined}
			error={ctrl.fieldState.error ? ctrl.fieldState.error.message : undefined}
			onChange={(v) => {
				if (v) {
					ctrl.field.onChange(Number.parseInt(v))
				}
			}}
		/>
	)
}

const inputStyles = {
	root: { display: "grid", gridTemplateColumns: "repeat(2, auto)" },
	label: { width: "18ch" },
	input: { width: "26ch" },
	error: { gridColumn: "2" },
}
