import { trpc } from "#/trpc";
import { reportUserError } from "#/util";
import { useNavigate } from "react-router";
import { CatalogForm, FormValues } from "./Form/index.js";

export const CreateCatalog = () => {
  const navigate = useNavigate();
  const ctx = trpc.useContext();

  const { mutate, isLoading } = trpc.v2_5.catalog.create.useMutation({
    onError(err) {
      reportUserError({
        title: "Failed to create catalog",
        message: err.message,
      });
    },
    onSuccess(data) {
      ctx.v2_5.catalog.invalidate();
      navigate(`/inventory/catalog/edit/${data.id}`);
    },
  });

  const handleSubmit = (values: FormValues) => {
    mutate({
      title: values.title,
      description: values.description,
      type: values.type,
      defaultImage: values.defaultImage,
    });
  };

  return (
    <CatalogForm
      onSubmit={handleSubmit}
      isLoading={isLoading}
      title="Create catalog item"
    />
  );
};
