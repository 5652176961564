import { useAuth } from "#/context/AuthContext.js";
import { trpc } from "#/trpc.js";
import { reportUserError, reportUserSuccess } from "#/util/index.js";
import { CreatePoRestockForm, CreatePoRestockFormSchema } from "./Form.js";
import Decimal from "decimal.js";
import { useNavigate } from "react-router";

export const CreatePoRestock = () => {
  const ctx = trpc.useContext();
  const [{ auth }] = useAuth();
  const navigate = useNavigate();
  const createPoRestockMutation = trpc.purchaseOrder.create.useMutation({
    onSuccess() {
      ctx.purchaseOrder.invalidate();
      ctx.v2_5.purchaseOrder.invalidate();
      reportUserSuccess({
        title: "Purchase order created",
      });
      navigate("/inventory/upcoming-items");
    },
    onError(error) {
      reportUserError({
        title: "Purchase order not created",
        message: error.message,
      });
    },
  });

  function handleSubmit(status: "DRAFT" | "UPCOMING") {
    return function (values: CreatePoRestockFormSchema) {
      if (status === "UPCOMING") {
        const verify = values.itemSkus.find(
          (itemSku) => itemSku.shippingQuantity === 0,
        );
        if (verify) {
          reportUserError({
            title: "Purchase order not created",
            message: "Shipping quantity must be greater than 0",
          });
          return;
        }
      }

      createPoRestockMutation.mutate({
        createdBy: {
          connect: {
            id: auth!.user.id,
          },
        },
        dutyCost: values.dutyCost,
        entity: {
          connect: {
            // FIXME(v2): entity
            id: 1,
          },
        },
        status,
        type: "RE_STOCK",
        purchaseOrderShipping: {
          createMany: {
            data: values.shippingInformation.entries.map((entry) => ({
              shippingCompany: entry.company,
              shippingCost: new Decimal(entry.cost),
              shippingNumber: entry.trackingNumber,
            })),
          },
        },
        purchaseOrderItemSku: {
          createMany: {
            data: values.itemSkus.map((itemSku) => ({
              itemSkuId: itemSku.id,
              expectedQuantity: itemSku.shippingQuantity,
              cost: itemSku.cost,
              unitCost: itemSku.cost,
            })),
          },
        },
        vendor: values.vendorId
          ? {
              connect: {
                id: Number(values.vendorId),
              },
            }
          : undefined,
      });
    };
  }

  return <CreatePoRestockForm onSubmit={handleSubmit} />;
};
