import { makeController } from "#/components-ng/index.js";
import { RouterInputs } from "#/trpc.js";
import { z } from "zod";

export const presentationTypes = [
  "PC",
  "PCS",
  "GR",
  "FT",
  "YD",
  "MTR",
  "PAIR",
  "SET",
  "STRAND",
] as const;

export const createItemSkuSchema = z.object({
  sku: z.number().optional(),
  vendor: z.object({
    id: z.number().nullable(),
    company: z.string().nullish(),
  }),
  vendorAlu: z.string().nullish(),
  vendorCost: z.coerce.number().nullish(),
  vendorCostType: z.enum(["BY_GRAM", "BY_UNIT"]).nullish(),
  vendorImage: z.string().nullish(),
  rateCostId: z.number().or(z.string()).nullish(),
  presentationType: z.enum(presentationTypes, {
    required_error: "presentationType is required",
  }),
  presentationValue: z.coerce.number({
    required_error: "presentationValue is required",
  }),
  title: z
    .string({
      required_error: "Title is required",
    })
    .min(3, {
      message: "Title must be at least 3 characters",
    })
    .max(255, {
      message: "Title must be at most 255 characters",
    }),
  defaultVideo: z.string().nullish(),
  defaultImage: z.string().nullish(),
  images: z.array(z.string()).nullish(),
  note: z.string().nullish(),
  available: z.boolean().nullish().default(true),
  price: z.number({
    required_error: "price is required",
  }),
  unitPrice: z.number({
    required_error: "unitPrice is required",
  }),
  cost: z.number({
    required_error: "cost is required",
  }),
  weight: z.coerce.number().gt(0),
  width: z.coerce.number().nullish(),
  height: z.coerce.number().nullish(),
  length: z.coerce.number().nullish(),
  size: z.string().min(1),
  minPurchase: z.coerce.number().nullish(),
});

export type FormValues = z.infer<typeof createItemSkuSchema>;
export const C = makeController<FormValues>();

export type Variant = FormValues;
export type PresentationType =
  RouterInputs["itemSku"]["pos"]["create"]["presentationType"];
