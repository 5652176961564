import { useAuth } from "#/context/AuthContext.js";
import { trpc } from "#/trpc.js";
import { reportUserError, reportUserSuccess } from "#/util/index.js";
import { MilestoneForm, MilestoneFormValues } from "./Form.js";
import { useNavigate } from "react-router-dom";

export const CreateMilestone = () => {
  const [{ auth }] = useAuth();
  const navigate = useNavigate();
  const ctx = trpc.useContext();
  const { mutate: createMilestone, isLoading } =
    trpc.milestone.create.useMutation({
      onError: (err) => {
        reportUserError({
          title: "Failed to create milestone",
          message: err.message,
        });
      },
      onSuccess: (data) => {
        reportUserSuccess({
          title: "Milestone created successfully",
          message: (
            <>
              Milestone <span className="font-medium">{data.title}</span> has
              been created
            </>
          ),
        });
        ctx.milestone.invalidate();
        navigate("/settings/admin/milestones");
      },
    });

  const handleSubmit = (values: MilestoneFormValues) => {
    createMilestone({
      title: values.title,
      description: values.description,
      milestoneType: values.milestoneType,
      activeFrom: values.dateRange[0],
      activeTo: values.dateRange[1],
      milestoneValue: values.milestoneValue,
      filialId: values.filialId
        ? values.filialId
        : auth?.user?.filialId ?? null,
      // Todo: implement badgeId
      // badgeId: values.badgeId ?? null,
      entityId: values.entityId ?? null,
      users:
        values?.userMilestones?.map((userMilestone) => ({
          id: userMilestone,
        })) ?? undefined,
    });
  };
  return <MilestoneForm onSubmit={handleSubmit} loading={isLoading} />;
};
