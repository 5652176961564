import * as M from "@mantine/core";
import React from "react";

interface NoteModalProps {
  defaultValue: string;
  opened: boolean;
  onClose: () => void;
  onSave: (value: string) => void;
}

export const NoteModal = ({
  defaultValue,
  opened,
  onClose,
  onSave,
}: NoteModalProps) => {
  const [note, setNote] = React.useState(defaultValue);
  React.useEffect(() => {
    if (opened) {
      setNote(defaultValue);
    }
  }, [defaultValue, opened]);

  return (
    <M.Modal
      opened={opened}
      onClose={onClose}
      withCloseButton={false}
      styles={(t) => ({
        content: { borderTop: `solid 6px ${t.colors.brand[4]}` },
      })}
    >
      <M.Textarea
        label="Note"
        mt={16}
        value={note}
        onChange={(e) => setNote(e.target.value)}
        styles={(t) => ({
          label: {
            color: t.colors.brand[5],
            fontSize: t.fontSizes.md,
            fontWeight: "bold",
          },
        })}
      />

      <M.Group position="right">
        <M.Button color="brand" mt={16} onClick={() => onSave(note)}>
          Save
        </M.Button>
      </M.Group>
    </M.Modal>
  );
};
