import {
  ChevronDownOutline,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "#/components-ng";
import GTLoader from "#/components-ng/loader.js";
import { useAuth } from "#/context/AuthContext.js";
import { trpc } from "#/trpc.js";
import FilialCard from "./FilialCard.js";
import { UpdateFilial } from "./Update.js";
import * as M from "@mantine/core";
import React from "react";
import { useNavigate } from "react-router";

export default function ChoseFilial() {
  const navigate = useNavigate();

  const [selectedFilialId, setSelectedFilialId] = React.useState<number | null>(
    null
  );

  const [{ auth }] = useAuth();

  const { data, isLoading } = trpc.filial.getAllWithOnlyRead.useQuery();

  const filialId = auth?.user?.filialId;

  if (selectedFilialId || filialId) {
    return <UpdateFilial id={selectedFilialId ?? filialId} />;
  }

  return isLoading ? (
    <M.LoadingOverlay
      visible={isLoading}
      loader={<GTLoader width={100} height={100} />}
    />
  ) : (
    <M.Stack spacing="xl" p="20px 30px" bg="#fff">
      <M.Title order={1}>Filial</M.Title>
      <M.Divider my={10} />
      {auth?.role?.name === "ROOT" && (
        <div className="mb-8 flex justify-between">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <M.Button rightIcon={<ChevronDownOutline />}>I want to</M.Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuItem onClick={() => navigate("create")}>
                Create Filial
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      )}

      <M.Box>
        <M.Box mb="3rem">
          <M.Text fz="1.2rem" fw="500">
            Filial Information
          </M.Text>
          <M.Text c="rgba(85, 85, 85, 0.6)">
            Please select an option to continue
          </M.Text>
        </M.Box>

        <M.Flex fz="sm" c="rgba(85, 85, 85, 1)" wrap="wrap" gap="40px">
          {data?.map((filial) => {
            return (
              <M.Box
                key={filial.id}
                onClick={() => setSelectedFilialId(filial.id)}
              >
                <FilialCard filial={filial} />
              </M.Box>
            );
          })}
        </M.Flex>
      </M.Box>
    </M.Stack>
  );
}
