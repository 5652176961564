import SceneSectionLayout from "../SceneSectionLayout/index.jsx";
import { StyledCards } from "./Styles.js";

const CategoryGridLayout = ({ category, children }) => {
  return (
    <SceneSectionLayout title={category}>
      <StyledCards>{children}</StyledCards>
    </SceneSectionLayout>
  );
};

export default CategoryGridLayout;
