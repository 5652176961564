import { RouterOutputs, trpc } from "#/trpc.js";
import { reportUserError, reportUserSuccess } from "#/util/index.js";
import Download from "~icons/ion/download-outline";
import { Link } from "react-router-dom";
import EditIcon from "~icons/ion/create-outline";
import MenuIcon from "~icons/ion/ellipsis-horizontal-outline";
import { HStack } from "#/css/jsx";
import { SearchButton } from "#/components@v2_5/search-button";
import { Button, Dropdown } from "@gt/ui";
import { DropdownMenu, IconButton, Tooltip } from "@radix-ui/themes";
import { css } from "#/css/css";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_PaginationState,
  MRT_SortingState,
  useMantineReactTable,
} from "mantine-react-table";
import dayjs from "dayjs";
import { useDisclosure } from "@mantine/hooks";
import { ItemAssociatedModal } from "#/components/ItemsAssociated";
import { MdOutlineDownload } from "react-icons/md";

const SeasonScene = () => {
  // Pagination
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });

  // Sorting
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const querySorting = useMemo(() => {
    if (sorting[0]) {
      return {
        desc: sorting[0].desc,
        key: sorting[0].id as any,
      };
    }
    return null;
  }, [sorting]);

  const [search, setSearch] = useState<string | null>(null);

  const { data, isLoading } = trpc.v2_5.season.getAllSeasonsByPage.useQuery(
    {
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize,
      sorting: querySorting,
      search: search,
    },
    {
      keepPreviousData: true,
      onError(error) {
        reportUserError({
          title: "Failed to get departments",
          message: error.message,
        });
      },
    },
  );

  const { mutate: exportCsv, isLoading: isLoadingExport } =
    trpc.v2_5.season.exportSeasonsTableToCsv.useMutation({
      onSuccess: () => {
        reportUserSuccess({
          title: "The csv file has been sent to email",
        });
      },
    });

  const handleExportCsv = () => {
    exportCsv({
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize,
      sorting: querySorting,
      search: search,
    });
  };

  const table = useMantineReactTable({
    data: data?.entries ?? [],
    columns: columns(),
    manualPagination: true,
    enableTopToolbar: false,
    rowCount: data?.totalEntries ?? 0,
    enableFilters: false,
    state: {
      pagination,
      sorting,
      isLoading,
    },
    enableStickyHeader: true,
    manualSorting: true,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    renderBottomToolbarCustomActions: () => (
      <div
        className={css({
          display: "flex",
          alignItems: "center",
          gap: 2,
        })}
      >
        <Tooltip content="Export to CSV">
          <IconButton
            color="gray"
            variant="soft"
            loading={isLoadingExport}
            onClick={handleExportCsv}
          >
            <MdOutlineDownload />
          </IconButton>
        </Tooltip>
      </div>
    ),
  });

  return (
    <>
      <HStack justify="space-between" gap={3}>
        <DrowndownButton />
        <SearchButton setQuery={setSearch} />
      </HStack>
      <div
        className={css({
          marginTop: 5,
        })}
      >
        <MantineReactTable table={table} />
      </div>
    </>
  );
};
export default SeasonScene;

type season =
  RouterOutputs["v2_5"]["season"]["getAllSeasonsByPage"]["entries"][number];

const columns = (): MRT_ColumnDef<season>[] => [
  {
    header: "Actions",
    id: "actions",
    size: 100,
    Cell: (table) => {
      const [opened, { open, close }] = useDisclosure(false);

      return (
        <>
          <Dropdown.Root>
            <Dropdown.Trigger>
              <Button variant="ghost" size="icon">
                <MenuIcon />
              </Button>
            </Dropdown.Trigger>
            <Dropdown.Content>
              <Dropdown.Item asChild>
                <Link to={`edit/${table.row.original.id}`}>
                  <div
                    className={css({
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 2,
                    })}
                  >
                    <EditIcon />
                    Edit
                  </div>
                </Link>
              </Dropdown.Item>
              <Dropdown.Item onClick={open}>
                <div
                  className={css({
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 2,
                  })}
                >
                  <Download />
                  Download Items associated
                </div>
              </Dropdown.Item>
            </Dropdown.Content>
          </Dropdown.Root>
          {opened && (
            <ItemAssociatedModal
              opened={opened}
              onClose={close}
              id={table.row.original.id}
              type={"seasons"}
            />
          )}
        </>
      );
    },
  },
  {
    header: "Season",
    accessorKey: "name",
  },
  {
    header: "Description",
    accessorKey: "description",
  },
  {
    header: "Initial date of the season",
    accessorKey: "initialDate",
    Cell: ({ row: { original } }) => {
      return dayjs(original.initialDate).format("MM/DD/YYYY");
    },
  },
  {
    header: "End date of the season",
    accessorKey: "finalDate",
    Cell: ({ row: { original } }) => {
      return dayjs(original.finalDate).format("MM/DD/YYYY");
    },
  },
];

const DrowndownButton = () => {
  return (
    <Dropdown.Root>
      <Dropdown.Trigger>
        <Button
          className={css({
            gap: 4,
          })}
        >
          I want to
          <DropdownMenu.TriggerIcon />
        </Button>
      </Dropdown.Trigger>
      <Dropdown.Content>
        <Dropdown.Item>
          <Link to="create">Create season</Link>
        </Dropdown.Item>
      </Dropdown.Content>
    </Dropdown.Root>
  );
};
