import { styled } from "#/css/jsx"
import { RouterInputs, RouterOutputs, trpc } from "#/trpc"
import { Switch } from "@mantine/core"
import { useQueryClient } from "@tanstack/react-query"

export function FeatureFlagsPage() {
	const { data: featureFlagsJoin } =
		trpc.v2_5.featureFlags.getAllByUser.useQuery()

	const tctx = trpc.useContext()
	const { mutate: toggleFeatureFlag, isLoading: isToggling } =
		trpc.v2_5.featureFlags.toggle.useMutation({
			onSuccess() {
				return tctx.v2_5.featureFlags.invalidate()
			},
		})

	const activeFeatureFlags =
		featureFlagsJoin?.featureFlagsOnUsers.map((d) => d.featureId) ?? []

	function handleToggle(flagId: number) {
		const isCurrentlyActive = activeFeatureFlags.includes(flagId)
		toggleFeatureFlag({
			id: flagId,
			status: isCurrentlyActive ? "inactive" : "active",
		})
	}

	return (
		<styled.div display="grid" gridTemplateColumns="repeat(2, 1fr)">
			{featureFlagsJoin?.featureFlags.map((flag) => {
				return (
					<styled.div
						key={flag.id}
						display="flex"
						justifyContent="space-between"
						alignItems="center"
						bg="white"
						border="solid 1px token(colors.slate.200)"
						p="4"
						rounded="md"
					>
						<div>
							<div>{flag.name}</div>
							<styled.div c="slate.500" fs="sm">
								{flag.description}
							</styled.div>
						</div>
						<div>
							<Switch
								disabled={isToggling}
								checked={activeFeatureFlags.includes(flag.id)}
								onClick={() => handleToggle(flag.id)}
							/>
						</div>
					</styled.div>
				)
			})}
		</styled.div>
	)
}
