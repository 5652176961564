import { formatRelative, format } from "date-fns";

export function epochToHumanDate(epoch) {
  if (typeof epoch === "string") {
    epoch = Number.parseInt(epoch);
  }

  return formatRelative(epoch, new Date());
}

export function epochToMMDDYYY(epoch) {
  if (typeof epoch === "string") {
    epoch = Number.parseInt(epoch);
  }

  return format(new Date(epoch), "MM/dd/yyyy");
}

export function epochToDate(epoch) {
  if (typeof epoch === "string") {
    epoch = Number.parseInt(epoch);
  }

  return new Date(epoch);
}

export function dateToEpoch(date) {
  return date && date.valueOf();
}

export function dateToEpochString(date) {
  return date && date.valueOf().toString();
}
