import { useState } from "react";
import { styled } from "#/css/jsx";
import { css } from "#/css/css";
import { Button, Dropdown } from "@gt/ui";
import { Link } from "react-router-dom";
import { DropdownMenu } from "@radix-ui/themes";
import { SearchButton } from "#/components@v2_5/search-button.js";
import { RouletteTable } from "./table";

export const Roulette = () => {
  const [query, setQuery] = useState<string | null>(null);

  return (
    <>
      <styled.div bg="white" p={4}>
        <styled.div mb={6} display="flex" justifyContent="space-between">
          <DrowndownButton />
          <SearchButton setQuery={setQuery} />
        </styled.div>
        <RouletteTable search={query} />
      </styled.div>
    </>
  );
};

const DrowndownButton = () => {
  return (
    <Dropdown.Root>
      <Dropdown.Trigger>
        <Button
          className={css({
            gap: 4,
          })}
        >
          I want to
          <DropdownMenu.TriggerIcon />
        </Button>
      </Dropdown.Trigger>
      <Dropdown.Content>
        <Dropdown.Item>
          <Link to="create">Create roulette</Link>
        </Dropdown.Item>
      </Dropdown.Content>
    </Dropdown.Root>
  );
};
