import GTLoader from "#/components-ng/loader.js";
import { trpc } from "#/trpc.js";
import { reportUserError, reportUserSuccess } from "#/util/index.js";
import { MilestoneForm, MilestoneFormValues } from "./Form.js";
import * as M from "@mantine/core";
import dayjs from "dayjs";
import { useNavigate, useParams } from "react-router-dom";

export const EditMilestone = () => {
  const { id } = useParams() as { id: string };
  const navigate = useNavigate();
  const ctx = trpc.useContext();

  const { data, isLoading, isFetchedAfterMount } =
    trpc.milestone.getById.useQuery(
      {
        id: Number(id),
      },
      {
        cacheTime: 0,
        onError: (err) => {
          reportUserError({
            title: "Failed to load milestone",
            message: err.message,
          });
        },
      }
    );

  const { mutate: updateMilestone, isLoading: isLoadingUpdate } =
    trpc.milestone.update.useMutation({
      onError: (err) => {
        reportUserError({
          title: "Failed to update milestone",
          message: err.message,
        });
      },
      onSuccess: (data) => {
        reportUserSuccess({
          title: "Milestone updated successfully",
          message: (
            <>
              Milestone <span className="font-medium">{data.title}</span> has
              been updated
            </>
          ),
        });
        ctx.milestone.invalidate();
        navigate("/settings/admin/milestones");
      },
    });

  const handleSubmit = (values: MilestoneFormValues) => {
    updateMilestone({
      id: Number(id),
      input: {
        title: values.title,
        description: values.description,
        milestoneType: values.milestoneType,
        activeFrom: values.dateRange[0],
        activeTo: values.dateRange[1],
        milestoneValue: values.milestoneValue,
        filialId: values.filialId ?? null,
        // Todo: implement badgeId
        // badgeId: values.badgeId ?? null,
        entityId: values.entityId ?? null,
        users:
          values?.userMilestones?.map((userMilestone) => ({
            id: userMilestone,
          })) ?? undefined,
      },
    });
  };

  return !data || isLoading || !isFetchedAfterMount ? (
    <M.LoadingOverlay
      visible={isLoading}
      loader={<GTLoader width={100} height={100} />}
    />
  ) : (
    <MilestoneForm
      onSubmit={handleSubmit}
      defaultValues={{
        title: data.title,
        description: data.description ?? "",
        milestoneType:
          data.milestoneType as MilestoneFormValues["milestoneType"],
        milestoneValue: data.milestoneValue,
        filialId: data.filialId ?? undefined,
        entityId: data.entityId ?? undefined,
        // badgeId: data.badgeId ?? undefined,
        dateRange: [
          dayjs(data.activeFrom).toDate(),
          dayjs(data.activeTo).toDate(),
        ],
        userMilestones: data.UserMilestone.map(
          (userMilestone) => userMilestone.userId
        ),
      }}
      loading={isLoadingUpdate}
    />
  );
};
