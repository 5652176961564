import Decimal from "decimal.js";

export interface InventoryLabelInfo {
  sku: number;
  ubication: string;
  price: Decimal;
}

export function getPrintInventoryTemplate(
  inventoryLabelInfo: InventoryLabelInfo
): string {
  return `^XA
  ^FX Ubication
  ^CF0,25
  ^FB200,,,C^FO0,70^FD${inventoryLabelInfo.ubication}\\&^FS

  ^FX Sku
  ^CFA,25
  ^FB205,,,C^FO0,120^FDREF: ${inventoryLabelInfo.sku}\\&^FS
  ^CFL,20 
  
  ^FX Product price
  ^CFA,15
  ^FB203,,,C^FO0,160^FD$${inventoryLabelInfo.price.toNumber().toFixed(2)}\\&^FS
  ^CFL,20
  ^XZ`;
}


export function getPrintInventoryTemplate2(
  inventoryLabelInfo1: InventoryLabelInfo,
  inventoryLabelInfo2: InventoryLabelInfo,
): string {
  return `^XA
  ^FX Ubication
  ^CF0,25
  ^FB200,,,C^FO0,70^FD${inventoryLabelInfo1.ubication}\\&^FS

  ^FX Sku
  ^CFA,25
  ^FB205,,,C^FO0,120^FDREF: ${inventoryLabelInfo1.sku}\\&^FS
  ^CFL,20 
  
  ^FX Product price
  ^CFA,15
  ^FB203,,,C^FO0,160^FD$${inventoryLabelInfo1.price.toNumber().toFixed(2)}\\&^FS
  ^CFL,20

  ^FX Ubication
  ^CF0,25
  ^FB433,,,C^FO0,220^FD${inventoryLabelInfo2.ubication}\\&^FS

  ^FX Sku
  ^CFA,25
  ^FB438,,,C^FO0,270^FDREF: ${inventoryLabelInfo2.sku}\\&^FS
  ^CFL,20

  ^FX Product price
  ^CFA,15
  ^FB436,,,C^FO0,310^FD$${inventoryLabelInfo2.price.toNumber().toFixed(2)}\\&^FS
  ^CFL,20

  ^XZ`;
}
