import {
  EditIcon,
  MenuOutlineIcon,
  TagPromotionCustomIcon,
} from "#/components-ng";
import GTLoader from "#/components-ng/loader";
import { useAuth } from "#/context/AuthContext";
import { css } from "#/css/css";
import placeholderImage from "#/placeholder-image.jpg";
import { RouterInputs, RouterOutputs, trpc } from "#/trpc";
import { reportUserError, reportUserSuccess } from "#/util";
import {
  openDetailsDrawerAtom,
  openModalAtom,
  selectedItemSkuIdAtom,
  selectedItemSkuToPrintAtom,
} from "./ItemList";
import { Carousel } from "@mantine/carousel";
import * as M from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import { IconButton, Tooltip } from "@radix-ui/themes";
import { useAtom, useSetAtom } from "jotai";
import {
  MantineReactTable,
  useMantineReactTable,
  MRT_ColumnDef,
  MRT_PaginationState,
  MRT_SortingState,
  MRT_ColumnFiltersState,
} from "mantine-react-table";
import { useMemo, useState } from "react";
import { MdOutlineDownload, MdVisibility } from "react-icons/md";
import { Link } from "react-router-dom";
import { match } from "ts-pattern";
import Print from "~icons/ion/print-outline";

type ColumnFilters = RouterInputs["itemSku"]["root"]["pagination"]["filters"];
type ColumnVisibilityType =
  | "category"
  | "department"
  | "season"
  | "sku"
  | "storeLocation"
  | "warehouseLocation"
  | "price"
  | "stock"
  | "vendorAlu"
  | "actions"
  | "image"
  | "name";

interface ItemListTableProps {
  dateRange?: {
    startDate: Date | null;
    endDate: Date | null;
  } | null;
  onlyOutOfStock?: boolean;
  filialId?: number | null;
}

export function ItemListTable(props: ItemListTableProps) {
  const [columnsVisibility, setColumnsVisibility] = useState({
    actions: true,
    image: true,
    sku: true,
    name: true,
    department: true,
    price: true,
    stock: true,
    category: true,
    storeLocation: true,
    warehouseLocation: true,
    season: true,
    vendorAlu: true,
  });
  const [loadedColumnsVisibility, setLoadedColumnsVisibility] =
    useState<boolean>(false);

  const [{ auth }] = useAuth();
  const [filialToStoreLocation, setFilialToStoreLocation] = useState<
    number | null
  >(() => (props?.onlyOutOfStock && props?.filialId ? props.filialId : null));

  // Pagination
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });

  // Sorting
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const querySorting = useMemo(() => {
    if (sorting[0]) {
      return {
        desc: sorting[0].desc,
        key: sorting[0].id as any,
      };
    }
    return null;
  }, [sorting]);

  // Filtering
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    [],
  );
  const [globalFilter, setGlobalFilter] = useState<string | null>(null);

  const queryFilters = useMemo(() => {
    const filters: ColumnFilters = {};
    if (globalFilter?.length) {
      filters.$global = globalFilter;
    }
    for (const columnFilter of columnFilters) {
      if (columnFilter.id === "price") {
        if (!Array.isArray(columnFilter.value)) {
          throw new Error(
            "Expected array of min and max values for `price` column",
          );
        }
        let min: number | undefined = Number.parseInt(columnFilter.value[0]);
        if (Number.isNaN(min)) {
          min = undefined;
        }
        let max: number | undefined = Number.parseInt(columnFilter.value[1]);
        if (Number.isNaN(max)) {
          max = undefined;
        }
        filters.price = {
          min,
          max,
        };
      } else if (columnFilter.id === "stock") {
        if (!Array.isArray(columnFilter.value)) {
          throw new Error(
            "Expected array of min and max values for `stock` column",
          );
        }
        let min: number | undefined = Number.parseInt(columnFilter.value[0]);
        if (Number.isNaN(min)) {
          min = undefined;
        }
        let max: number | undefined = Number.parseInt(columnFilter.value[1]);
        if (Number.isNaN(max)) {
          max = undefined;
        }
        filters.stock = {
          min,
          max,
        };
      } else if (columnFilter.id === "sku") {
        filters.sku = `"${columnFilter.value}"`;
      } else {
        filters[columnFilter.id] = columnFilter.value;
      }
    }
    return filters;
  }, [columnFilters, globalFilter]);

  const [debouncedSearchTerm] = useDebouncedValue(queryFilters, 400);

  let enabled = false;

  if (props.dateRange) {
    enabled =
      props.dateRange.startDate !== null && props.dateRange.endDate !== null
        ? true
        : false;
  } else if (props.onlyOutOfStock) {
    enabled = props.filialId ? true : false;
  } else {
    enabled = true;
  }

  const { data } = trpc.itemSku.root.pagination.useQuery(
    {
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize,
      filters: debouncedSearchTerm,
      sorting: querySorting,
      filialId: props.onlyOutOfStock
        ? props.filialId
        : !auth?.user?.filialId
          ? filialToStoreLocation
          : null,
      dateRange: props.dateRange ? props.dateRange : null,
      onlyOutOfStock: props.onlyOutOfStock,
    },
    {
      keepPreviousData: true,
      enabled: enabled,
    },
  );

  const { data: userColumns, isLoading: isLoadingColumns } =
    trpc.v2_5.user.getItemListColumns.useQuery(undefined, {
      onSuccess(data) {
        setColumnsVisibility({
          actions: data.actions,
          image: data.image,
          sku: data.sku,
          name: data.name,
          department: data.department,
          price: data.price,
          stock: data.stock,
          category: data.category,
          storeLocation: data.storeLocation,
          warehouseLocation: data.warehouseLocation,
          season: data.season,
          vendorAlu: data.vendorAlu,
        });
        setLoadedColumnsVisibility(true);
      },
    });

  const ctx = trpc.useContext();
  const { mutate: updateUserColumns } =
    trpc.v2_5.user.updateItemListColumns.useMutation({
      onSuccess: () => {
        ctx.v2_5.user.getItemListColumns.invalidate();
      },
    });

  const { mutate: exportCsv, isLoading: isLoadingExport } =
    trpc.v2_5.itemSku.exportItemListTableToCsv.useMutation({
      onSuccess: () => {
        reportUserSuccess({
          title: "The csv file has been sent to email",
        });
      },
    });

  const handleExportCsv = () => {
    exportCsv({
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize,
      filters: debouncedSearchTerm,
      sorting: querySorting,
      filialId: props.onlyOutOfStock
        ? props.filialId
        : !auth?.user?.filialId
          ? filialToStoreLocation
          : null,
      dateRange: props.dateRange ? props.dateRange : null,
      onlyOutOfStock: props.onlyOutOfStock,
    });
  };

  useEffect(() => {
    if (!userColumns) return;

    if (!loadedColumnsVisibility) return;

    const column_ = Object.keys(columnsVisibility).find(
      (key) => columnsVisibility[key] !== userColumns[key],
    );

    const column = match(column_)
      .with("defaultImage", () => "image")
      .with("title", () => "name")
      .otherwise(() => column_);

    if (column && column_) {
      updateUserColumns({
        column: {
          by: column as ColumnVisibilityType,
          value: columnsVisibility[column_],
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnsVisibility]);

  const table = useMantineReactTable({
    data: data?.entries ?? [],
    columns: columns(filialToStoreLocation, setFilialToStoreLocation),
    enableFilterMatchHighlighting: false,
    enableStickyHeader: true,
    enableColumnResizing: true,
    // Pagination
    manualPagination: true,
    onPaginationChange: setPagination,
    rowCount: data?.totalEntries ?? 0,
    // Filtering
    manualFiltering: true,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    // Sorting
    manualSorting: true,
    onSortingChange: setSorting,
    initialState: {
      showColumnFilters: true,
      showGlobalFilter: true,
    },
    state: {
      pagination,
      sorting,
      columnFilters,
      columnVisibility: {
        category: isLoadingColumns ? false : userColumns!.category,
        storeLocation: isLoadingColumns ? false : userColumns!.storeLocation,
        warehouseLocation: isLoadingColumns
          ? false
          : userColumns!.warehouseLocation,
        season: isLoadingColumns ? false : userColumns!.season,
        vendorAlu: isLoadingColumns ? false : userColumns!.vendorAlu,
        actions: isLoadingColumns ? true : userColumns!.actions,
        defaultImage: isLoadingColumns ? true : userColumns!.image,
        sku: isLoadingColumns ? true : userColumns!.sku,
        title: isLoadingColumns ? true : userColumns!.name,
        department: isLoadingColumns ? true : userColumns!.department,
        price: isLoadingColumns ? true : userColumns!.price,
        stock: isLoadingColumns ? true : userColumns!.stock,
      },
    },
    onColumnVisibilityChange: setColumnsVisibility,
    enableHiding: true,
    mantineFilterTextInputProps() {
      return {
        classNames: {
          root: "border-b-0 mt-1",
          input: "px-2 font-normal text-sm",
        },
      };
    },
    mantineTopToolbarProps() {
      return {
        sx: {
          "& svg": {
            width: "1.2rem",
            height: "1.2rem",
          },
        },
      };
    },
    mantineTableBodyRowProps({ row }) {
      if (row.original.available === false) {
        return {
          sx: {
            "& td": {
              backgroundColor: "rgba(0, 0, 0, 0.1)",
            },
          },
        };
      }
      return {};
    },
    enableColumnFilterModes: !auth?.user?.filialId ? true : false,
    renderBottomToolbarCustomActions: () => (
      <div
        className={css({
          display: "flex",
          alignItems: "center",
          gap: 2,
        })}
      >
        <Tooltip content="Export to CSV">
          <IconButton
            color="gray"
            variant="soft"
            loading={isLoadingExport}
            onClick={handleExportCsv}
          >
            <MdOutlineDownload />
          </IconButton>
        </Tooltip>
      </div>
    ),
  });

  return <MantineReactTable table={table} />;
}

type ItemSku =
  RouterOutputs["itemSku"]["root"]["pagination"]["entries"][number];

const columns = (
  filialToStoreLocation: number | null,
  setFilialToStoreLocation: (value: number | null) => void,
): MRT_ColumnDef<ItemSku>[] => [
  {
    id: "actions",
    enableSorting: false,
    enableColumnFilter: false,
    header: "Actions",
    size: 100,
    Cell(props) {
      const original = props.row.original;
      const itemSkuId = props.row.original.id;
      const [selectedItemSkuId, setSelectedItemSkuId] = useAtom(
        selectedItemSkuIdAtom,
      );
      const setOpenDetailsDrawer = useSetAtom(openDetailsDrawerAtom);
      const setSelectedItemSkuToPrint = useSetAtom(selectedItemSkuToPrintAtom);
      const setOpenModal = useSetAtom(openModalAtom);

      return (
        <M.Group spacing={8} noWrap>
          <M.Menu withinPortal>
            <M.Menu.Target>
              <M.ActionIcon>
                <MenuOutlineIcon />
              </M.ActionIcon>
            </M.Menu.Target>
            <M.Menu.Dropdown>
              <M.Menu.Item
                component={Link}
                to={`/inventory/item-list/preview/${itemSkuId}`}
                icon={<MdVisibility fontSize="0.8rem" />}
              >
                Preview
              </M.Menu.Item>
              <M.Menu.Item
                component={Link}
                to={`/inventory/item-list/edit/${original.itemId}`}
                icon={<EditIcon fontSize="0.8rem" />}
              >
                Edit
              </M.Menu.Item>
              <M.Menu.Item
                onClick={() => {
                  setSelectedItemSkuToPrint(original as any);
                  setOpenModal(true);
                }}
                icon={<Print />}
              >
                Print label
              </M.Menu.Item>
            </M.Menu.Dropdown>
          </M.Menu>
          <M.ActionIcon
            variant="filled"
            onClick={() => {
              selectedItemSkuId?.id === original.id
                ? setSelectedItemSkuId(null)
                : setSelectedItemSkuId({
                    id: original.id,
                    sku: original.sku,
                    title: original.title,
                  });
              setOpenDetailsDrawer(true);
            }}
            className="bg-slate-200 text-slate-700 hover:bg-slate-300"
          >
            {<MdVisibility />}
          </M.ActionIcon>
          {original?.promotionItemSku &&
          original.promotionItemSku.length > 0 ? (
            <M.Tooltip label="Promotion" inline>
              <M.ActionIcon className="cursor-default">
                <TagPromotionCustomIcon />
              </M.ActionIcon>
            </M.Tooltip>
          ) : null}
        </M.Group>
      );
    },
  },
  {
    accessorKey: "defaultImage",
    header: "Image",
    enableSorting: false,
    enableColumnFilter: false,
    Cell(props) {
      const original = props.row.original;
      const image = props.row.original?.defaultImage
        ? props.row.original?.defaultImage
        : original.images?.length > 0
          ? original.images[0]
          : null;
      const [modalOpen, setModalOpen] = useState(false);

      return (
        <>
          <img
            src={image ?? placeholderImage}
            className="w-11 cursor-pointer"
            onClick={() => setModalOpen(true)}
          />
          <M.Modal
            opened={modalOpen}
            onClose={() => setModalOpen(false)}
            withCloseButton={false}
            classNames={{
              content: "bg-transparent shadow-none",
            }}
          >
            <ItemSkuImagesCarousel itemSkuId={props.row.original.id} />
          </M.Modal>
        </>
      );
    },
  },
  {
    accessorKey: "sku",
    header: "Sku",
    enableColumnFilterModes: false,
    Cell(props) {
      const [{ auth }] = useAuth();
      const { data, isLoading, mutate } =
        trpc.itemSkuStock.getByItemSkuId.useMutation({
          onError: (error) => {
            reportUserError({
              title: "Error while fetching item sku stock",
              message: error.message,
            });
          },
        });

      const roles = ["ROOT", "ENTITY MANAGER"];

      return roles.includes(auth?.role?.name) ? (
        <M.HoverCard
          width={320}
          shadow="md"
          withArrow
          openDelay={100}
          closeDelay={100}
          onOpen={() => {
            mutate({
              itemSkuId: props.row.original.id,
            });
          }}
          withinPortal
        >
          <M.HoverCard.Target>
            <M.Text>{props.renderedCellValue}</M.Text>
          </M.HoverCard.Target>
          <M.HoverCard.Dropdown>
            <M.Group>
              {isLoading ? (
                <M.Loader />
              ) : (
                <M.Stack>
                  {data?.map((stock) => {
                    return (
                      <M.Stack spacing={5} key={stock.id}>
                        <M.Text size="md" weight={700} mb={2}>
                          {stock.filial?.brevis ?? stock.filial?.name}
                        </M.Text>
                        <M.Group spacing={5}>
                          <M.Text size="sm" weight={500}>
                            Store location:
                          </M.Text>
                          <M.Text size="sm">{stock.storeLocation}</M.Text>
                        </M.Group>
                        <M.Group spacing={5}>
                          <M.Text size="sm" weight={500}>
                            Warehouse location:
                          </M.Text>
                          <M.Text size="sm">{stock.warehouseLocation}</M.Text>
                        </M.Group>
                      </M.Stack>
                    );
                  })}
                </M.Stack>
              )}
            </M.Group>
          </M.HoverCard.Dropdown>
        </M.HoverCard>
      ) : (
        <M.Text>{props.renderedCellValue}</M.Text>
      );
    },
  },
  {
    accessorKey: "title",
    header: "Name",
    enableColumnFilterModes: false,
  },
  {
    accessorKey: "storeLocation",
    header: "Store location",
    enableSorting: false,
    enableColumnFilterModes: true,
    renderColumnFilterModeMenuItems: () => {
      const { data } = trpc.filial.getAll.useQuery(undefined);

      const options =
        data?.map((filial) => ({
          label: filial.name,
          value: filial.id.toString(),
        })) ?? [];

      return (
        <M.Menu>
          <M.Menu.Item>
            <M.Select
              value={filialToStoreLocation?.toString() ?? ""}
              data={options}
              label="Select filial"
              onChange={(value) => {
                setFilialToStoreLocation(Number(value) ?? null);
              }}
            />
          </M.Menu.Item>
        </M.Menu>
      );
    },
  },
  {
    accessorKey: "vendorAlu",
    header: "Vendor ALU",
    enableSorting: false,
    enableColumnFilterModes: false,
  },
  {
    accessorKey: "warehouseLocation",
    header: "Warehouse location",
    enableSorting: false,
  },
  {
    id: "department",
    accessorKey: "item.department.name",
    header: "Department",
    filterVariant: "autocomplete",
    enableColumnFilterModes: false,
    mantineFilterAutocompleteProps() {
      const { data } = trpc.department.getAll.useQuery(undefined);

      const options =
        data?.map((cat) => ({
          label: cat.name,
          value: cat.name,
        })) ?? [];

      return {
        data: options,
        className: "border-b-0 mt-1 font-normal",
      };
    },
  },
  {
    id: "category",
    accessorKey: "item.category.name",
    header: "Category",
    filterVariant: "autocomplete",
    enableColumnFilterModes: false,
    mantineFilterAutocompleteProps() {
      const { data } = trpc.category.getAll.useQuery(undefined);

      const options =
        data?.map((cat) => ({
          label: cat.name,
          value: cat.name,
        })) ?? [];

      return {
        data: options,
        className: "border-b-0 mt-1 font-normal",
      };
    },
  },
  {
    id: "season",
    accessorKey: "item.season.name",
    header: "Season",
    enableColumnFilterModes: false,
  },
  {
    id: "price",
    filterVariant: "range",
    accessorFn: (row) => "$" + row.price?.toNumber().toFixed(2),
    header: "Price",
    enableColumnFilterModes: false,
  },
  {
    id: "stock",
    accessorFn: (row) => row.stock,
    filterVariant: "range",
    header: "Stock",
    enableColumnFilterModes: false,
  },
];

type ItemSkuImagesCarouselProps = {
  itemSkuId: number;
};

function ItemSkuImagesCarousel(props: ItemSkuImagesCarouselProps) {
  const { data, isInitialLoading } = trpc.itemSku.getById.useQuery(
    {
      id: props.itemSkuId,
    },
    {
      cacheTime: 0,
    },
  );
  const images = useMemo(() => {
    if (!data) {
      return [];
    }
    const images: Array<string> = [];
    // Put the default image first
    if (data?.defaultImage) {
      images.push(data.defaultImage);
    }
    // Make sure we don't duplicate the default image
    images.push(...data.images.filter((image) => image !== data.defaultImage));
    return images;
  }, [data]);

  if (isInitialLoading) {
    return (
      <div className="w-full max-w-[300px]">
        <GTLoader width={250} />
      </div>
    );
  }

  if (images.length > 0) {
    return (
      <Carousel>
        {images.map((image, i) => (
          <Carousel.Slide key={i}>
            <img src={image} />
          </Carousel.Slide>
        ))}
      </Carousel>
    );
  }
  return (
    <div>
      <img src={placeholderImage} />
      <p className="mt-3 text-center text-3xl font-semibold text-white">
        No images
      </p>
    </div>
  );
}
