// @ts-nocheck

import { PersonOutlineIcon } from "#/components-ng/index.js";
import { CustomersIcon } from "#/components/icons/index.jsx";
import * as C from "@chakra-ui/react";
import Card from "#/components/Card/index.js";
import CategoryGridLayout from "#/components/CategoryGridLayout/index.js";
import SceneLayout from "#/components/SceneLayout/index.js";

const CustomersScene = () => {
  return (
    <C.Box>
      <SceneLayout icon={<PersonOutlineIcon />}>
        <C.Stack spacing={6} divider={<C.StackDivider />}>
          <CategoryGridLayout category="Customers">
            <Card
              icon={<CustomersIcon size="big" />}
              text="Customers"
              path="/customers/list"
              module="customers.customerList"
            ></Card>
          </CategoryGridLayout>
          {/* <div className="recently-viewed-customers">
            <div className="title">
              <Text
                text="Recently viewed customers"
                fontSize="22px"
                fontWeight="bold"
              />
            </div>
            <C.Box mt={2}>
              <Text text="Customers" fontWeight="bold" />
              <C.UnorderedList styleType="none" spacing={2} mt={1}>
                <C.ListItem>
                  <Text text="Andres Mejias" color="primary" />
                </C.ListItem>
              </C.UnorderedList>
            </C.Box>
          </div> */}
        </C.Stack>
      </SceneLayout>
    </C.Box>
  );
};

export default CustomersScene;
