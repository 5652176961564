import { ItemAssociatedModal } from "#/components/ItemsAssociated/index.js";
import { RouterOutputs, trpc } from "#/trpc.js";
import { reportUserError, reportUserSuccess } from "#/util/ux/index.js";
import placeholderImage from "#/placeholder-image.jpg";
import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import EditIcon from "~icons/ion/create-outline";
import Download from "~icons/ion/download-outline";
import Change from "~icons/ion/refresh-circle-outline";
import MenuIcon from "~icons/ion/ellipsis-horizontal-outline";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_PaginationState,
  MRT_SortingState,
  useMantineReactTable,
} from "mantine-react-table";
import { Box, HStack } from "#/css/jsx";
import { SearchButton } from "#/components@v2_5/search-button";
import { Button, Dropdown } from "@gt/ui";
import { Badge, DropdownMenu, IconButton, Tooltip } from "@radix-ui/themes";
import { css } from "#/css/css";
import dayjs from "dayjs";
import { useDisclosure } from "@mantine/hooks";
import { MdOutlineDownload } from "react-icons/md";

export interface DepartmentColumns {
  id: number;
  image?: string;
  name: string;
  description: string;
  numItemsAssociated: number;
  status: string;
  lastUpdate: string | Date;
}

const DepartmentScene = () => {
  // Pagination
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });

  // Sorting
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const querySorting = useMemo(() => {
    if (sorting[0]) {
      return {
        desc: sorting[0].desc,
        key: sorting[0].id as any,
      };
    }
    return null;
  }, [sorting]);

  const [search, setSearch] = useState<string | null>(null);

  const { data, isLoading } = trpc.v2_5.departments.getAllByPage.useQuery(
    {
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize,
      sorting: querySorting,
      search: search,
    },
    {
      keepPreviousData: true,
      onError(error) {
        reportUserError({
          title: "Failed to get departments",
          message: error.message,
        });
      },
    },
  );

  const { mutate: exportCsv, isLoading: isLoadingExport } =
    trpc.v2_5.departments.exportDepartmentsTableToCsv.useMutation({
      onSuccess: () => {
        reportUserSuccess({
          title: "The csv file has been sent to email",
        });
      },
    });

  const handleExportCsv = () => {
    exportCsv({
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize,
      sorting: querySorting,
      search: search,
    });
  };

  const table = useMantineReactTable({
    data: data?.entries ?? [],
    columns: columns,
    manualPagination: true,
    enableTopToolbar: false,
    rowCount: data?.totalEntries ?? 0,
    enableFilters: false,
    state: {
      pagination,
      sorting,
      isLoading,
    },
    enableStickyHeader: true,
    manualSorting: true,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    renderBottomToolbarCustomActions: () => (
      <div
        className={css({
          display: "flex",
          alignItems: "center",
          gap: 2,
        })}
      >
        <Tooltip content="Export to CSV">
          <IconButton
            color="gray"
            variant="soft"
            loading={isLoadingExport}
            onClick={handleExportCsv}
          >
            <MdOutlineDownload />
          </IconButton>
        </Tooltip>
      </div>
    ),
  });

  return (
    <>
      <HStack justify="space-between" gap={3}>
        <DrowndownButton />
        <SearchButton setQuery={setSearch} />
      </HStack>
      <div
        className={css({
          marginTop: 5,
        })}
      >
        <MantineReactTable table={table} />
      </div>
    </>
  );
};

export default DepartmentScene;

export type Department =
  RouterOutputs["v2_5"]["departments"]["getAllByPage"]["entries"][number];

const columns: MRT_ColumnDef<Department>[] = [
  {
    id: "actions",
    header: "Actions",
    size: 100,
    Cell: (table) => {
      const [opened, { open, close }] = useDisclosure(false);
      const ctx = trpc.useContext();

      const { mutate: changeStatus } =
        trpc.v2_5.departments.updateStatus.useMutation({
          onSuccess() {
            ctx.v2_5.departments.invalidate();
            reportUserSuccess({
              title: "Department status changed successfully",
            });
          },
        });

      return (
        <>
          <Dropdown.Root>
            <Dropdown.Trigger>
              <Button variant="ghost" size="icon">
                <MenuIcon />
              </Button>
            </Dropdown.Trigger>
            <Dropdown.Content>
              <Dropdown.Item asChild>
                <Link to={`edit/${table.row.original.id}`}>
                  <div
                    className={css({
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 2,
                    })}
                  >
                    <EditIcon />
                    Edit
                  </div>
                </Link>
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  changeStatus({
                    id: table.row.original.id,
                    active: !table.row.original.active,
                  });
                }}
              >
                <div
                  className={css({
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 2,
                  })}
                >
                  <Change />
                  Change Status
                </div>
              </Dropdown.Item>
              <Dropdown.Item onClick={open}>
                <div
                  className={css({
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 2,
                  })}
                >
                  <Download />
                  Download Items associated
                </div>
              </Dropdown.Item>
            </Dropdown.Content>
          </Dropdown.Root>
          {opened && (
            <ItemAssociatedModal
              opened={opened}
              onClose={close}
              id={table.row.original.id}
              type={"departments"}
            />
          )}
        </>
      );
    },
  },
  {
    header: "Image",
    accessorKey: "image",
    enableSorting: false,
    size: 100,
    Cell: ({ row: { original } }) => {
      return (
        <Box>
          <img
            src={original.defaultImage ?? placeholderImage}
            className={css({
              objectFit: "cover",
              width: 20,
              height: 20,
            })}
          />
        </Box>
      );
    },
  },
  {
    header: "Department",
    accessorKey: "name",
  },
  {
    header: "Description",
    accessorKey: "description",
  },
  {
    header: "Status",
    accessorKey: "status",
    Cell: ({
      row: {
        original: { active },
      },
    }) => {
      const value = active ? "Active" : "Inactive";

      return (
        <Badge color={active ? "green" : "orange"} radius="full" size="3">
          {value}
        </Badge>
      );
    },
  },
  {
    header: "Last update",
    accessorKey: "lastUpdate",
    Cell: ({
      row: {
        original: { updatedAt },
      },
    }) => {
      return (
        <Box>
          <p>{dayjs(updatedAt).format("MM/DD/YYYY")} </p>
        </Box>
      );
    },
  },
];

const DrowndownButton = () => {
  return (
    <Dropdown.Root>
      <Dropdown.Trigger>
        <Button
          className={css({
            gap: 4,
          })}
        >
          I want to
          <DropdownMenu.TriggerIcon />
        </Button>
      </Dropdown.Trigger>
      <Dropdown.Content>
        <Dropdown.Item>
          <Link to="create">Create department</Link>
        </Dropdown.Item>
      </Dropdown.Content>
    </Dropdown.Root>
  );
};
