import { RouterInputs } from "#/trpc";

export type OrderStatus = NonNullable<
  RouterInputs["order"]["pos"]["ecommerceOrders"]["table"]["filters"]
>["status"][number];

export const Filter: Record<OrderStatus, string> = {
  PENDING: "Pending",
  PROCESSING: "Processing",
  SHIPPED: "Shipped",
  COMPLETE: "Complete",
  READY_TO_PICKUP: "Ready to pickup",
  REVERSED: "Reversed",
  CANCELLED: "Cancelled",
  FAILED: "Failed",
} as const;

export type FilterKey = keyof typeof Filter;
export type Filter = (typeof Filter)[FilterKey];
