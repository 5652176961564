import SceneLayout from "#/components/SceneLayout/index.jsx";
import { MdHome } from "react-icons/md/index.js";
import { Outlet } from "react-router";

export const DashboardLayout = () => {
  return (
    <SceneLayout icon={<MdHome />}>
      <Outlet />
    </SceneLayout>
  );
};
