import _ from "lodash";
import React from "react";
import {
  FieldValues,
  Path,
  UseFormReturn,
  UseFormWatch,
} from "react-hook-form";

export interface RequiredGroupProps<TFormValues extends FieldValues> {
  formMethods: UseFormReturn<TFormValues>;
  groupName: Path<TFormValues>;
}

export default function useRequiredGroup<TFormValues extends FieldValues>({
  formMethods,
  groupName,
}: RequiredGroupProps<TFormValues>): boolean {
  const [isRequired, setIsRequired] = React.useState(false);
  React.useEffect(() => {
    const sub = formMethods.watch((data, { name, type }) => {
      if (!name) return;
      if (name.startsWith(groupName)) {
        const fieldValue = _.get(data, name);
        // If the user inserted something, make the rest of the fields required.
        if (fieldValue && !isRequired) {
          setIsRequired(true);
        } else {
          let newRequired = false;
          // If at least one input is set, then set the rest of the fields as required.
          // Otherwise, set it as non-required.
          for (const field in data.address) {
            if (data.address[field]) {
              newRequired = true;
            }
          }
          if (isRequired !== newRequired) {
            setIsRequired(newRequired);
          }
        }
      }
    });
    return () => sub.unsubscribe();
  }, [formMethods, isRequired]);

  React.useEffect(() => {
    if (!isRequired) {
      formMethods.trigger(groupName);
    }
  }, [isRequired, formMethods]);

  return isRequired;
}
