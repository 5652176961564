import { makeController } from "#/components-ng/index.js";
import { z } from "zod";

export const sysChangeSetTypes = [
  "FEATURE",
  "FIX",
] as const;

export const createSysChangeSetSchema = z.object({
  title: z.string(),
  reason: z.string(),
  sysSectionId: z
    .object({
      id: z.number(),
      name: z.string(),
    })
    .nullish(),
  requiredReload: z.boolean(),
  image: z.string().nullish(),
  video: z.string().nullish(),
  type: z.enum(sysChangeSetTypes)
});

export type FormValues = z.infer<typeof createSysChangeSetSchema>;
export const C = makeController<FormValues>();
