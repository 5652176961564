import GTLoader from "#/components-ng/loader";
import { css } from "#/css/css";
import { trpc } from "#/trpc";
import { LoadingOverlay } from "@mantine/core";
import dayjs from "dayjs";

export function ChangelogArticle() {
  const { data, isLoading, isFetchedAfterMount } =
    trpc.v2_5.changelog.sysVersions.getLastChangelog.useQuery();

  return isLoading || !isFetchedAfterMount ? (
    <LoadingOverlay
      visible={isLoading || isFetchedAfterMount}
      loader={<GTLoader width="100px" height="100px" />}
    />
  ) : (
    <>
      <h2>
        Changelog {dayjs(data?.createdAt).format("MM/DD/YYYY")} | Version{" "}
        {data?.version}
      </h2>
      {data?.sysChangeSets.map((changeSet) => {
        const content = changeSet.reason.replace(/<br\s*\/?>/gi, " ");

        return (
          <div
            key={changeSet.id}
            className={css({
              marginBottom: "1rem",
            })}
          >
            <div dangerouslySetInnerHTML={{ __html: content ?? "" }} />

            {changeSet.video && (
              <video
                controls={false}
                loop
                width="100%"
                src={changeSet.video}
                autoPlay
              />
            )}

            {changeSet.image && <img src={changeSet.image} alt="changeSet" />}
          </div>
        );
      })}
    </>
  );
}
