import { trpc } from "#/trpc";
import { reportUserError, reportUserSuccess } from "#/util";
import { useNavigate, useParams } from "react-router";
import { FormValues } from "./Form/types";
import { SysChangeSetForm } from "./Form";
import { LoadingOverlay } from "@mantine/core";
import GTLoader from "#/components-ng/loader";

export const UpdateChangeSet = () => {
  const params = useParams() as any;
  const sysVersionId = params?.sysVersionId
    ? Number(params.sysVersionId)
    : null;

  const sysChangeSetId = params?.sysChangeSetId
    ? Number(params.sysChangeSetId)
    : null;

  const ctx = trpc.useContext();
  const navigate = useNavigate();

  const { data, isLoading, isFetchedAfterMount } =
    trpc.v2_5.changelog.sysChangeSet.getById.useQuery(
      {
        sysChangeSetId: sysChangeSetId!,
      },
      {
        enabled: !!sysChangeSetId,
        cacheTime: 0,
      },
    );

  const { mutate, isLoading: isLoadingUpdate } =
    trpc.v2_5.changelog.sysChangeSet.update.useMutation({
      onSuccess(data) {
        ctx.v2_5.changelog.invalidate();
        reportUserSuccess({
          title: "Update Change Set Successfully",
        });
        navigate(`/settings/admin/changelog/versions/${data.sysVersionId}`);
      },
    });

  const handleSubmit = (values: FormValues) => {
    if (!values.sysSectionId) {
      reportUserError({
        title: "SysSection is required",
      });
      return;
    }

    if (!sysVersionId) {
      reportUserError({
        title: "Something is wrong, please try again",
      });
      return;
    }

    if (!sysChangeSetId) {
      reportUserError({
        title: "Something is wrong, please try again",
      });
      return;
    }

    mutate({
      id: sysChangeSetId,
      reason: values.reason,
      sysSectionId: Number(values.sysSectionId.id),
      requiredReload: values.requiredReload,
      sysVersionId: sysVersionId,
      title: values.title,
      image: values.image,
      video: values.video,
      type: values.type,
    });
  };

  return isLoading || !isFetchedAfterMount ? (
    <LoadingOverlay
      visible={isLoading || isFetchedAfterMount}
      loader={<GTLoader width="100px" height="100px" />}
    />
  ) : (
    <SysChangeSetForm
      onSubmit={handleSubmit}
      isLoading={isLoadingUpdate}
      defaultValues={{
        title: data?.title ?? "",
        reason: data?.reason ?? "",
        sysSectionId: data?.sysSection ?? null,
        image: data?.image ?? null,
        video: data?.video ?? null,
        requiredReload: data?.requiredReload ?? true,
        type: data?.type,
      }}
    />
  );
};
