import Logo from "#/images/colored-logo-invoice.png";
import { trpc } from "#/trpc.js";
import { reportUserError } from "#/util/index.js";
import { EndOfDayBoxDetails, TableDetails } from "./components/pdf";
import * as M from "@mantine/core";
import dayjs from "dayjs";
import Decimal from "decimal.js";
import React from "react";
import { useParams } from "react-router";
import { PaymentType } from "server";

const paymentCards: PaymentType[] = [
  "MASTERCARD",
  "VISA",
  "DISCOVER",
  "AMERICAN_EXPRESS",
];

export const PrintEndOfDayPdf = ({
  preview = false,
}: {
  preview?: boolean;
}) => {
  const [triggeredPrint, setTriggeredPrint] = React.useState(false);
  const { id } = useParams();

  const { data: endOfDay, isLoading } =
    trpc.report.getLatestEndOfDayCache.useQuery(undefined, {
      onError(error) {
        reportUserError({
          title: "Error getting latest end of day report",
          message: error.message,
        });
      },
    });

  React.useEffect(() => {
    if (endOfDay && !triggeredPrint && !preview) {
      window.setTimeout(() => {
        window.print();
      }, 700);
      setTriggeredPrint(true);
    }
  }, [endOfDay, preview, triggeredPrint]);

  if (isLoading) {
    return (
      <M.Center p={64}>
        <M.Loader />
      </M.Center>
    );
  }

  const data = endOfDay?.find((item) => item.id === Number(id));

  if (!endOfDay || !data) {
    return (
      <M.Center p={64}>
        <M.Text>Error. You should not see this</M.Text>
      </M.Center>
    );
  }

  return (
    <M.Flex
      sx={{
        "@media only screen": {
          justifyContent: "center",
        },
      }}
    >
      <M.Stack
        sx={{
          "@media only screen": {
            width: 900,
          },
        }}
        className="w-full"
      >
        <M.Group position="apart">
          <M.Stack spacing={5}>
            <M.Title order={5}>End of Day</M.Title>
            <M.Title order={6}>{data.name}</M.Title>
            <M.Text size="xs">
              Created at:{" "}
              {`${dayjs(data.from).format("MM/DD/YYYY")} - ${dayjs(
                data.to
              ).format("MM/DD/YYYY")}`}
            </M.Text>
            <M.Text size="xs">Created by: {data.createdBy}</M.Text>
          </M.Stack>
          <M.Image src={Logo} width={120} />
        </M.Group>
        <M.Divider />

        <M.Text weight="bold">Sales</M.Text>
        <M.Stack mt={10} spacing="md" mb="xl">
          <EndOfDayBoxDetails
            header="Sales summary"
            data={[
              {
                title: "Completed orders",
                total: data?.ordersHistory.orderCount,
                isMoney: false,
              },
              {
                title: "Subtotal sales",
                total: data?.totalOrderAmount,
                isMoney: true,
              },
              {
                title: "Total returns",
                total: data?.totalReturnedAmount,
                isMoney: true,
              },
              { title: "Total sales", total: data?.totalSale, isMoney: true },
              {
                title: "Total shipping",
                total: data?.totalShipping,
                isMoney: true,
              },
              {
                title: "Total tax",
                total: data?.totalTaxAmount,
                isMoney: true,
              },
              {
                title: "Total activity",
                total: data?.totalActivityAmount,
                isMoney: true,
              },
            ]}
          />
          <M.Group position="apart" grow>
            <EndOfDayBoxDetails
              header="Sales Non Taxable"
              data={[
                {
                  title: "Subtotal sales",
                  total: data?.subTotalNonTaxable,
                  isMoney: true,
                },
                {
                  title: "Returns",
                  total: data?.totalReturnNonTaxable,
                  isMoney: true,
                },
                {
                  title: "Net",
                  total: data?.netSalesNonTaxable,
                  isMoney: true,
                },
              ]}
            />
            <EndOfDayBoxDetails
              header="Sales Taxable"
              data={[
                {
                  title: "Subtotal sales",
                  total: data?.subTotalTaxable,
                  isMoney: true,
                },
                {
                  title: "Returns",
                  total: data?.totalReturnTaxable,
                  isMoney: true,
                },
                {
                  title: "Net",
                  total: data?.netSalesTaxable,
                  isMoney: true,
                },
              ]}
            />
            <EndOfDayBoxDetails
              header="Sales Tax"
              data={[
                {
                  title: "Subtotal sales",
                  total: data?.totalTaxAmount,
                  isMoney: true,
                },
                {
                  title: "Returns",
                  total: data?.totalReturnTax,
                  isMoney: true,
                },
                {
                  title: "Net",
                  total: data?.netSalesTax,
                  isMoney: true,
                },
              ]}
            />
          </M.Group>
        </M.Stack>

        <M.Text weight="bold">Sales summary by order type</M.Text>
        <M.Stack mt={10} spacing="md" mb="xl">
          <EndOfDayBoxDetails
            header="Online"
            data={[
              {
                title: "Total paid in",
                total:
                  data?.summaryByOrderType?.find(
                    (o) => o.orderType === "ONLINE"
                  )?.totalPaidIn ?? new Decimal(0),
                isMoney: true,
              },
              {
                title: "Total paid out",
                total:
                  data?.summaryByOrderType?.find(
                    (o) => o.orderType === "ONLINE"
                  )?.totalPaidOut ?? new Decimal(0),
                isMoney: true,
              },
              {
                title: "Total amount",
                total:
                  data?.summaryByOrderType?.find(
                    (o) => o.orderType === "ONLINE"
                  )?.totalAmount ?? new Decimal(0),
                isMoney: true,
              },
            ]}
          />
          <EndOfDayBoxDetails
            header="In store"
            data={[
              {
                title: "Total paid in",
                total:
                  data?.summaryByOrderType?.find(
                    (o) => o.orderType === "IN_STORE"
                  )?.totalPaidIn ?? new Decimal(0),
                isMoney: true,
              },
              {
                title: "Total paid out",
                total:
                  data?.summaryByOrderType?.find(
                    (o) => o.orderType === "IN_STORE"
                  )?.totalPaidOut ?? new Decimal(0),
                isMoney: true,
              },
              {
                title: "Total amount",
                total:
                  data?.summaryByOrderType?.find(
                    (o) => o.orderType === "IN_STORE"
                  )?.totalAmount ?? new Decimal(0),
                isMoney: true,
              },
            ]}
          />
          <EndOfDayBoxDetails
            header="Pickup"
            data={[
              {
                title: "Total paid in",
                total:
                  data?.summaryByOrderType?.find(
                    (o) => o.orderType === "PICKUP"
                  )?.totalPaidIn ?? new Decimal(0),
                isMoney: true,
              },
              {
                title: "Total paid out",
                total:
                  data?.summaryByOrderType?.find(
                    (o) => o.orderType === "PICKUP"
                  )?.totalPaidOut ?? new Decimal(0),
                isMoney: true,
              },
              {
                title: "Total amount",
                total:
                  data?.summaryByOrderType?.find(
                    (o) => o.orderType === "PICKUP"
                  )?.totalAmount ?? new Decimal(0),
                isMoney: true,
              },
            ]}
          />
        </M.Stack>

        <M.Text weight="bold">Payments</M.Text>
        <M.Stack mt={10} spacing="md" mb="xl">
          <EndOfDayBoxDetails
            header="Payments"
            data={[
              {
                title: "Total paid in",
                total: data?.payments.totalPaidIn,
                isMoney: true,
              },
              {
                title: "Total paid out",
                total: data?.payments.totalPaidOut,
                isMoney: true,
              },
              {
                title: "Total amount",
                total: data?.payments.totalAmount,
                isMoney: true,
              },
            ]}
          />
          <EndOfDayBoxDetails
            header="Cash"
            data={[
              {
                title: "Total paid in",
                total:
                  data?.paymentsMethodsSummary?.paymentMethods?.find(
                    (o) => o.method === "CASH"
                  )?.totalPaidIn ?? 0,
                isMoney: true,
              },
              {
                title: "Total paid out",
                total:
                  data?.paymentsMethodsSummary?.paymentMethods?.find(
                    (o) => o.method === "CASH"
                  )?.totalPaidOut ?? 0,
                isMoney: true,
              },
              {
                title: "Total amount",
                total:
                  data?.paymentsMethodsSummary?.paymentMethods?.find(
                    (o) => o.method === "CASH"
                  )?.totalAmount ?? 0,
                isMoney: true,
              },
              {
                title: "Total return",
                total:
                  data?.paymentsMethodsSummary?.paymentMethods?.find(
                    (o) => o.method === "CASH"
                  )?.totalReturned ?? 0,
                isMoney: true,
              },
              {
                title: "Total activity",
                total:
                  data?.paymentsMethodsSummary?.paymentMethods?.find(
                    (o) => o.method === "CASH"
                  )?.totalActivity ?? 0,
                isMoney: true,
              },
            ]}
          />
          {data?.payments.orders.find((o) => o.method === "USER_CREDITS") && (
            <EndOfDayBoxDetails
              header="User credits"
              data={[
                {
                  title: "Total paid in",
                  total:
                    data?.paymentsMethodsSummary?.paymentMethods?.find(
                      (o) => o.method === "USER_CREDITS"
                    )?.totalPaidIn ?? 0,
                  isMoney: true,
                },
                {
                  title: "Total paid out",
                  total:
                    data?.paymentsMethodsSummary?.paymentMethods?.find(
                      (o) => o.method === "USER_CREDITS"
                    )?.totalPaidOut ?? 0,
                  isMoney: true,
                },
                {
                  title: "Total amount",
                  total:
                    data?.paymentsMethodsSummary?.paymentMethods?.find(
                      (o) => o.method === "USER_CREDITS"
                    )?.totalAmount ?? 0,
                  isMoney: true,
                },
                {
                  title: "Total return",
                  total:
                    data?.paymentsMethodsSummary?.paymentMethods?.find(
                      (o) => o.method === "USER_CREDITS"
                    )?.totalReturned ?? 0,
                  isMoney: true,
                },
                {
                  title: "Total activity",
                  total:
                    data?.paymentsMethodsSummary?.paymentMethods?.find(
                      (o) => o.method === "USER_CREDITS"
                    )?.totalActivity ?? 0,
                  isMoney: true,
                },
              ]}
            />
          )}
          <EndOfDayBoxDetails
            header="All Credit/Debit cards"
            data={[
              {
                title: "Total paid in",
                total: data?.paymentsMethodsSummary?.paymentMethods
                  .filter(
                    // (o) => o.method !== "CASH" && o.method !== "USER_CREDITS"
                    (o) => paymentCards.includes(o.method)
                  )
                  .map((p) => p.totalPaidIn)
                  .reduce((a, b) => a.plus(b), new Decimal(0)),
                isMoney: true,
              },
              {
                title: "Total paid out",
                total: data?.paymentsMethodsSummary?.paymentMethods
                  .filter((o) => paymentCards.includes(o.method))
                  .map((p) => p.totalPaidOut)
                  .reduce((a, b) => a.plus(b), new Decimal(0)),
                isMoney: true,
              },
              {
                title: "Total amount",
                total: data?.paymentsMethodsSummary?.paymentMethods
                  .filter((o) => paymentCards.includes(o.method))
                  .map((p) => p.totalAmount)
                  .reduce((a, b) => a.plus(b), new Decimal(0)),
                isMoney: true,
              },
              {
                title: "Total return",
                total: data?.paymentsMethodsSummary?.paymentMethods
                  .filter((o) => paymentCards.includes(o.method))
                  .map((p) => p.totalReturned)
                  .reduce((a, b) => a.plus(b), new Decimal(0)),
                isMoney: true,
              },
              {
                title: "Total activity",
                total: data?.paymentsMethodsSummary?.paymentMethods
                  .filter(
                    (o) => o.method !== "CASH" && o.method !== "USER_CREDITS"
                  )
                  .map((p) => p.totalActivity)
                  .reduce((a, b) => a.plus(b), new Decimal(0)),
                isMoney: true,
              },
            ]}
          />
          {data?.paymentsMethodsSummary?.paymentMethods
            .filter((o) => o.method !== "CASH" && o.method !== "USER_CREDITS")
            .map((p) => p.method)
            .filter((v, i, a) => a.indexOf(v) === i)
            .map((method, i) => (
              <EndOfDayBoxDetails
                key={`${method}-${i}`}
                header={method}
                data={[
                  {
                    title: "Total paid in",
                    total:
                      data?.paymentsMethodsSummary?.paymentMethods?.find(
                        (o) => o.method === method
                      )?.totalPaidIn ?? 0,

                    isMoney: true,
                  },
                  {
                    title: "Total paid out",
                    total:
                      data?.paymentsMethodsSummary?.paymentMethods?.find(
                        (o) => o.method === method
                      )?.totalPaidOut ?? 0,
                    isMoney: true,
                  },
                  {
                    title: "Total amount",
                    total:
                      data?.paymentsMethodsSummary?.paymentMethods?.find(
                        (o) => o.method === method
                      )?.totalAmount ?? 0,
                    isMoney: true,
                  },
                  {
                    title: "Total return",
                    total:
                      data?.paymentsMethodsSummary?.paymentMethods?.find(
                        (o) => o.method === method
                      )?.totalReturned ?? 0,
                    isMoney: true,
                  },
                  {
                    title: "Total activity",
                    total:
                      data?.paymentsMethodsSummary?.paymentMethods?.find(
                        (o) => o.method === method
                      )?.totalActivity ?? 0,
                    isMoney: true,
                  },
                ]}
              />
            ))}
        </M.Stack>

        <M.Text weight="bold">Payments methods</M.Text>
        <M.Stack mt={10} spacing="md" mb="xl">
          <TableDetails data={data?.payments.orders} />
        </M.Stack>

        <M.Text weight="bold">Refund</M.Text>
        <M.Stack mt={10} spacing="md" mb="xl">
          <TableDetails
            data={data?.payments.orders.filter((o) =>
              new Decimal(o.totalAmount).lessThan(0.001)
            )}
          />
        </M.Stack>

        <M.Text weight="bold">Inventory movement</M.Text>
        <M.Stack mt={10} spacing="md">
          <EndOfDayBoxDetails
            header="Inventory movement"
            data={[
              {
                title: "Count",
                total: data?.inventoryMovement.count,
                isMoney: false,
              },
              {
                title: "Sold Items",
                total:
                  data?.inventoryMovement.noReturned
                    .totalCountProductsTransacted,
                isMoney: false,
              },
              {
                title: "Returned Items",
                total:
                  data?.inventoryMovement.returned.totalCountProductsReturned,
                isMoney: false,
              },
            ]}
          />
        </M.Stack>
      </M.Stack>
    </M.Flex>
  );
};
