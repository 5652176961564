import { trpc } from "#/trpc";
import { reportUserError } from "#/util";
import { C } from "../types";
import React from "react";

export function SuggestedPriceSection() {
  const form = C.useFormContext();
  const currentCost = C.useWatch({
    name: `cost`,
    control: form.control,
  });
  const rateCostId = C.useWatch({
    name: `rateCostId`,
    control: C.useFormContext().control,
  });
  const presentationValue = C.useWatch({
    name: `presentationValue`,
    control: C.useFormContext().control,
  });
  const weight = C.useWatch({
    name: `weight`,
    control: C.useFormContext().control,
  });
  const vendorCost = C.useWatch({
    name: `vendorCost`,
    control: C.useFormContext().control,
  });
  const currentPrice = C.useWatch({
    name: `price`,
    control: form.control,
  });

  const { mutate, data, isLoading } = trpc.itemSku.suggestedPrice.useMutation({
    onError: (error) => {
      reportUserError({
        title: "Failed to get suggested price",
        message: error.message,
      });
    },
    onSuccess: (data) => {
      form.setValue(`cost`, data.cost);
    },
  });

  React.useEffect(() => {
    if (rateCostId && presentationValue && weight && vendorCost) {
      mutate({
        rateCostId: Number(rateCostId),
        presentationValue: Number(presentationValue),
        weight: Number(weight),
        vendorCost: Number(vendorCost),
      });
    }
  }, [mutate, presentationValue, rateCostId, vendorCost, weight]);

  React.useEffect(() => {
    if (currentPrice && presentationValue) {
      form.setValue(
        `unitPrice`,
        Number(currentPrice) / Number(presentationValue)
      );
    }
  }, [currentPrice, form, presentationValue]);

  return (
    <section className="mt-10">
      <h3 className="mb-4">Suggested price</h3>
      <div className="grid grid-cols-12 rounded-md border border-blue-100 bg-blue-50 text-sm font-medium">
        <div className="col-span-2 grid gap-1 border-r border-blue-100 px-4 py-2">
          <p className="text-blue-400">Cost</p>
          <p>{data?.cost?.toFixed(2) ?? `${currentCost?.toFixed(2)}`}</p>
        </div>
        <div className="col-span-2 grid gap-1 border-r border-blue-100 px-4 py-2">
          <p className="text-blue-400">Cost by package</p>
          <p>
            {data?.costByPresentation?.toFixed(2) ??
              `${currentCost?.toFixed(2)}`}
          </p>
        </div>
        <div className="col-span-2 grid gap-1 border-r border-blue-100 px-4 py-2">
          <p className="text-blue-400">Min. price</p>
          <p>{isLoading ? "Loading..." : data?.min.toFixed(2) ?? "0.00"}</p>
        </div>
        <div className="col-span-2 grid gap-1 border-r border-blue-100 px-4 py-2">
          <p className="text-blue-400">Max. price</p>
          <p>{isLoading ? "Loading..." : data?.max.toFixed(2) ?? "0.00"}</p>
        </div>
        <div className="col-span-2 grid gap-1 border-r border-blue-100 px-4 py-2">
          <p className="text-blue-400">Price *</p>
          <C.NumberInputField
            type="number"
            step="0.01"
            className="h-auto py-1"
            placeholder="0.00"
            required
            name="price"
          />
        </div>
        <div className="col-span-2 grid gap-1 px-4 py-2">
          <p className="text-blue-400">Unit price</p>
          <p>{(currentPrice / presentationValue)?.toFixed(2) || 0.0}</p>
        </div>
      </div>
    </section>
  );
}
