import { DefaultValues, FormProvider, SubmitHandler } from "react-hook-form";
import { C, createQuizSchema, FormValues } from "./types";
import { zodResolver } from "@hookform/resolvers/zod";
import { v4 as uuidv4 } from "uuid";
import {
  ImageUpload,
  UseS3UploadObject,
  UseS3UploadResult,
} from "#/components-ng/ui/media-upload.js";
import { IconButton } from "@radix-ui/themes";
import CloseIcon from "~icons/ion/close-circle-outline";
import * as M from "@mantine/core";
import { css } from "#/css/css";
import { styled } from "#/css/jsx";
import { DatePickerInput } from "@mantine/dates";

export interface QuizFormProps {
  title: string;
  onSubmit: SubmitHandler<FormValues>;
  isLoading: boolean;
  defaultValues?: DefaultValues<FormValues>;
  quizId?: number | null;
}

interface ImageUploadProps {
  url: string;
  uuid: string;
  isLoading: boolean;
}

export const QuizInfo = (props: QuizFormProps) => {
  const form = C.useForm({
    resolver: zodResolver(createQuizSchema),
    shouldUnregister: false,
    defaultValues: {
      title: "",
      description: "",
      needAuth: false,
      defaultImage: null,
      titleEsp: null,
      descriptionEsp: null,
      ...props.defaultValues,
    },
  });

  const [defaultImage, setDefaultImage] = useState<ImageUploadProps | null>(
    () =>
      form.getValues("defaultImage")
        ? {
            url: form.getValues("defaultImage")!,
            uuid: uuidv4(),
            isLoading: false,
          }
        : null,
  );

  const handleS3UploadDefaultImageStart = (upload: UseS3UploadObject) => {
    setDefaultImage({
      url: upload.url!,
      uuid: uuidv4(),
      isLoading: true,
    });
  };

  const handleS3UploadDefaultImageSuccess = (upload: UseS3UploadResult) => {
    setDefaultImage({
      url: upload.url!,
      uuid: uuidv4(),
      isLoading: false,
    });
    form.setValue("defaultImage", upload.url);
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={(e) => form.handleSubmit(props.onSubmit)(e)}>
        <M.Stack spacing={48}>
          <M.Stack spacing={4} align="start" bg="white">
            <M.Title
              bg={"white"}
              fw={600}
              px={40}
              py={24}
              w={"100%"}
              fz="1.6rem"
            >
              {props.title} Quiz
            </M.Title>

            <M.Tabs defaultValue="en" w="100%">
              <M.Tabs.List
                className={css({
                  display: "flex",
                  gap: 10,
                  paddingX: 10,
                  width: "100%",
                })}
              >
                <M.Tabs.Tab value="en">English</M.Tabs.Tab>
                <M.Tabs.Tab value="es">Spanish</M.Tabs.Tab>
              </M.Tabs.List>
              <M.Tabs.Panel value="en">
                <div
                  className={css({
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gridTemplateRows: "1",
                    gap: 10,
                    paddingX: 10,
                    paddingTop: 10,
                    width: "100%",
                  })}
                >
                  <C.InputField
                    label="Title"
                    name="title"
                    placeholder="Title"
                  />
                  <C.TextareaField
                    label="Description"
                    placeholder="Description"
                    name="description"
                  />
                </div>
              </M.Tabs.Panel>
              <M.Tabs.Panel value="es">
                <div
                  className={css({
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gridTemplateRows: "1",
                    gap: 10,
                    paddingX: 10,
                    paddingTop: 10,
                    width: "100%",
                  })}
                >
                  <C.InputField
                    label="Título"
                    name="titleEsp"
                    placeholder="Título"
                  />
                  <C.TextareaField
                    label="Descripción"
                    placeholder="Descripción"
                    name="descriptionEsp"
                  />
                </div>
              </M.Tabs.Panel>
            </M.Tabs>

            <div
              className={css({
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gridTemplateRows: "1",
                gap: 10,
                paddingX: 10,
                paddingTop: 10,
                width: "100%",
              })}
            >
              <C.M
                as={DatePickerInput}
                label="Published At"
                name="publishedAt"
                classNames={{
                  label: css({ color: "#64748B" }),
                }}
              />
              <C.M
                as={DatePickerInput}
                label="Finished At"
                name="finishedAt"
                classNames={{
                  label: css({ color: "#64748B" }),
                }}
              />
              <C.SwitchField label="Need auth?" name="needAuth" />
              {defaultImage?.url ? (
                <styled.div
                  position="relative"
                  width="100%"
                  maxHeight="400px"
                  height="200px"
                  lg={{ height: "400px" }}
                >
                  <styled.img
                    src={defaultImage.url}
                    alt="Catalog image"
                    width="full"
                    height="full"
                    objectFit="contain"
                  />
                  <styled.div
                    position="absolute"
                    top={0}
                    right={0}
                    p={2}
                    rounded="full"
                  >
                    <IconButton
                      variant="ghost"
                      onClick={() => {
                        form.setValue("defaultImage", null);
                        setDefaultImage(null);
                      }}
                    >
                      <CloseIcon
                        fontSize={20}
                        color="red"
                        className={css({ cursor: "pointer" })}
                      />
                    </IconButton>
                  </styled.div>
                </styled.div>
              ) : (
                <ImageUpload
                  directory="quiz"
                  supports={{
                    image: true,
                  }}
                  onS3UploadSuccess={handleS3UploadDefaultImageSuccess}
                  onS3UploadingStart={handleS3UploadDefaultImageStart}
                  hiddenVisually={true}
                />
              )}
              <M.Button
                type="submit"
                className={css({ width: "fit" })}
                loading={props.isLoading || defaultImage?.isLoading}
              >
                Save
              </M.Button>
            </div>
          </M.Stack>
        </M.Stack>
      </form>
    </FormProvider>
  );
};
