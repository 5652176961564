import { Payment } from "./types.js";
import Decimal from "decimal.js";
import { atom } from "jotai";

// Cash
export const cashPaymentMethodAtom = atom(new Decimal(0));

// Cards
// TODO: this is used for all payment methods, so we should rename it
export const cardPaymentMethodsBaseAtom = atom<Payment[]>([]);

// User credits
export const userCreditsPaymentMethodAtom = atom(new Decimal(0));

export const cardPaymentMethodsAtom = atom<Payment[]>((get) =>
  get(cardPaymentMethodsBaseAtom)
);

export const cardPaymentMethodAddAtom = atom(
  null,
  (get, set, paymentMethod: Required<Payment>) => {
    set(cardPaymentMethodsBaseAtom, [
      ...get(cardPaymentMethodsBaseAtom),
      paymentMethod,
    ]);
  }
);

export const cardPaymentMethodRemoveAtom = atom(
  null,
  (get, set, index: number) => {
    set(
      cardPaymentMethodsBaseAtom,
      get(cardPaymentMethodsBaseAtom).filter((_, i) => i !== index)
    );
  }
);

export const giftPaymentMethodAtom = atom({
  type: "Fixed" as "Fixed" | "Percentage",
  amount: new Decimal(0),
});
