import GTLoader from "#/components-ng/loader.js";
import { useAuth } from "#/context/AuthContext.js";
import { trpc } from "#/trpc.js";
import { CardLayout, CardTable } from "../components/index.js";
import { Text } from "@mantine/core";
import dayjs from "dayjs";
import React from "react";

interface PricingHistoryCardProps {
  itemSkuId: number;
}

export const PricingHistoryCard = ({ itemSkuId }: PricingHistoryCardProps) => {
  const [{ auth }] = useAuth();

  const { data, isLoading } = trpc.itemLog.getByItemSkuByPricing.useQuery(
    {
      itemSkuId: itemSkuId,
      filialId: auth?.user?.filialId ?? null,
      limit: 8,
    },
    {
      enabled: !!itemSkuId,
      cacheTime: 0,
    }
  );

  const itemLogs = React.useMemo(() => {
    if (!data) return [];
    return data.map((itemLog) => {
      const filial = `${itemLog.filial?.brevis ?? itemLog.filial.name}`;
      const user = `${itemLog.user?.firstName ?? ""} ${
        itemLog.user?.lastName ?? ""
      }`;
      const date = dayjs(itemLog.createdAt).format("MM/DD/YYYY");
      const oldCost = itemLog.oldCost?.toNumber().toFixed(2) ?? "";
      const newCost = itemLog.newCost?.toNumber().toFixed(2) ?? "";
      const costVar = `%${(
        ((itemLog.newCost!.toNumber() - itemLog.oldCost!.toNumber()) /
          (itemLog.oldCost?.toNumber() === 0
            ? 1
            : itemLog.oldCost!.toNumber())) *
        100
      ).toFixed(2)}`;
      const oldPrice = itemLog.oldPrice?.toNumber().toFixed(2) ?? "";
      const newPrice = itemLog.newPrice?.toNumber().toFixed(2) ?? "";
      const priceVar = `%${(
        ((itemLog.newPrice!.toNumber() - itemLog.oldPrice!.toNumber()) /
          (itemLog.oldPrice!.toNumber() === 0
            ? 1
            : itemLog.oldPrice!.toNumber())) *
        100
      ).toFixed(2)}`;

      return [
        filial,
        user,
        date,
        oldCost,
        newCost,
        costVar,
        oldPrice,
        newPrice,
        priceVar,
      ];
    });
  }, [data]);

  return (
    <CardLayout title="Pricing history" id={itemSkuId} route="pricing-history">
      {isLoading ? (
        <GTLoader width={100} height={100} key="loader" />
      ) : itemLogs.length > 0 ? (
        <CardTable
          headers={[
            "Filial",
            "User",
            "Date",
            "Old cost",
            "New cost",
            "Cost var",
            "Old price",
            "New price",
            "Price var",
          ]}
          rows={itemLogs}
        />
      ) : (
        <Text key="no-data" size="sm">
          No pricing history found
        </Text>
      )}
    </CardLayout>
  );
};
