import * as M from "@mantine/core";
import ReactRouterPromptRaw from "react-router-prompt";

const ReactRouterPrompt = (ReactRouterPromptRaw as any).default
  ? (ReactRouterPromptRaw as any).default
  : ReactRouterPromptRaw;

export const RouterPrompt = ({
  when,
  extraButtons,
  onCancel: routerOnCancel,
  onConfirm: routerOnConfirm,
}: {
  when: boolean;
  extraButtons?: (callbacks: {
    onConfirm: any;
    onCancel: any;
  }) => React.ReactNode;
  onCancel?: (() => void) | null;
  onConfirm?: (() => void) | null;
}) => {
  return (
    <ReactRouterPrompt when={when}>
      {({ isActive, onConfirm, onCancel }) => (
        <M.Modal
          opened={isActive}
          onClose={() => onCancel(null)}
          title="Are you sure you want to leave?"
        >
          <M.Group position="right">
            {extraButtons?.({ onConfirm, onCancel })}
            <M.Button
              color="darkGray.0"
              sx={(t) => ({ color: t.colors.gray[8] })}
              onClick={onCancel}
            >
              Stay
            </M.Button>
            <M.Button
              color="red"
              onClick={() => {
                if (routerOnCancel) routerOnCancel();
                if (routerOnConfirm) routerOnConfirm();
                onConfirm();
              }}
            >
              Leave
            </M.Button>
          </M.Group>
        </M.Modal>
      )}
    </ReactRouterPrompt>
  );
};
