import GTLoader from "#/components-ng/loader.js";
import { trpc } from "#/trpc.js";
import { reportUserError, reportUserSuccess } from "#/util/index.js";
import { TagFormDrawer, TagSchema } from "./form.js";
import { LoadingOverlay } from "@mantine/core";
import { useMemo } from "react";
import { v4 as uuidV4 } from "uuid";

type TagFormUpdateContentProps = {
  opened: boolean;
  onClose: () => void;
  id: number;
};

export const TagFormUpdateContent = ({
  opened,
  onClose,
  id,
}: TagFormUpdateContentProps) => {
  const ctx = trpc.useContext();
  const { data, isLoading } = trpc.tag.getById.useQuery(
    {
      id: id,
    },
    {
      cacheTime: 0,
      onError(error) {
        onClose();
        reportUserError({
          title: "Failed to get tag",
          message: error.message,
        });
      },
    },
  );

  const { mutate, isLoading: isLoadingUpdate } = trpc.tag.update.useMutation({
    onError(error) {
      reportUserError({
        title: "Failed to update tag",
        message: error.message,
      });
    },
    onSuccess() {
      ctx.tag.invalidate();
      ctx.v2_5.tags.invalidate();
      onClose();
      reportUserSuccess({
        title: "Tag updated successfully",
      });
    },
  });

  const handleSubmit = (values: TagSchema) => {
    mutate({
      id: id,
      input: {
        name: values.name,
        description: values?.description ?? null,
        defaultImage: values?.defaultImage?.url ?? null,
      },
    });
  };

  const defaultValues = useMemo(() => {
    if (data) {
      return {
        name: data.name,
        description: data?.description ?? null,
        defaultImage: data?.defaultImage
          ? {
              isLoading: false,
              url: data.defaultImage,
              uid: uuidV4(),
            }
          : null,
      };
    }
    return null;
  }, [data]);

  return id && isLoading ? (
    <LoadingOverlay
      visible={isLoading}
      loader={<GTLoader width={100} height={100} />}
    />
  ) : (
    <TagFormDrawer
      opened={opened}
      onClose={onClose}
      onSubmit={handleSubmit}
      defaultValues={defaultValues ?? undefined}
      loading={isLoadingUpdate}
    />
  );
};
