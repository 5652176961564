import React from "react";
import PropTypes from "prop-types";
import { Select as CSelect } from "chakra-react-select";
import { useFormContext, Controller } from "react-hook-form";

export default function Select({
  name,
  rules,
  onChange: userOnChange,
  ...props
}) {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value } }) => (
        <CSelect
          chakraStyles={{
            dropdownIndicator: () => ({ display: "none" }),
            menu: (provided) => ({
              ...provided,
              width: "auto",
            }),
          }}
          {...props}
          onChange={(v) => {
            onChange(v);
            if (userOnChange) {
              userOnChange(v);
            }
          }}
          value={value}
        />
      )}
    />
  );
}

Select.defaultProps = {
  name: "",
  onChange: () => {},
  rules: {},
};

Select.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  rules: PropTypes.object,
};
