import {
	DollarFilledIcon,
	FolderOpenOutlineIcon,
	HomeFilledIcon,
	HomeOutlineIcon,
	LineChartFilledIcon,
	MenuOutlineIcon,
} from "#/components-ng"
import { MenuNavbar } from "#/components/Menu"
import { LOGO_URL } from "#/constants/envt"
import { useAuth } from "#/context/AuthContext"
import { css } from "#/css/css"
import { styled } from "#/css/jsx"
import { Drawer } from "@mantine/core"
import { ReactNode } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"


export const BottomBarMenu = () => {
	const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
	const location = useLocation()

	let activeItemKey: BottomBarMenuItemLabel | null = null
	const urlPath = location.pathname
	if (urlPath === "/") {
		activeItemKey = "Home"
	} else if (urlPath.startsWith("/sales")) {
		activeItemKey = "Sales"
	} else if (urlPath.startsWith("/inventory")) {
		activeItemKey = "Inventory"
	} else if (urlPath.startsWith("/reports")) {
		activeItemKey = "Reports"
	} else {
		activeItemKey = "More"
	}

	const itemCss = (active: boolean) =>
		css({
			flex: "1",
			w: "20%",
			py: "3",
			px: "2",
			color: active ? "brand.500" : "slate.600",
			borderTop: active
				? "3px solid token(colors.brown.400)"
				: "1px solid token(colors.slate.100)",
			display: "flex",
			flexDir: "column",
			justifyContent: "space-between",
			alignItems: "center",
			rowGap: "2",
		})

	const ItemLinkContainer = (props: {
		children: ReactNode
		active: boolean
		href: string
	}) => (
		<Link
			to={props.href}
			className={itemCss(props.active)}
			aria-label={`Go to page`}
		>
			{props.children}
		</Link>
	)

	const ItemButtonContainer = (props: {
		children: ReactNode
		active: boolean
		onClick: () => void
	}) => (
		<button
			className={itemCss(props.active)}
			onClick={props.onClick}
			aria-label="Open more menu"
			aria-labelledby="Open more menu"
			title="Open more menu"
		>
			{props.children}
		</button>
	)

	return (
		<styled.div
			position="fixed"
			zIndex="101"
			bottom="0 !important"
			left="0"
			bg="white"
			display={{
				base: "flex",
				lg: "none",
			}}
			width="full"
		>
			{Object.entries(bottomBarMenuItems).map(([_key, item]) => {
				const key = _key as BottomBarMenuItemLabel

				const itemInner = (
					<div key={key}>
						<styled.div h="5" display="grid" placeItems="center">
							{key === activeItemKey ? item.filledIcon : item.outlinedIcon}
						</styled.div>
						<styled.label fontSize="xs">{item.label}</styled.label>
					</div>
				)

				if (key !== "More") {
					return (
						<ItemLinkContainer
							key={key}
							href={item.href as string}
							active={key === activeItemKey}
						>
							{itemInner}
						</ItemLinkContainer>
					)
				}

				if (key === "More") {
					return (
						<>
							<MobileMenuDrawer
								isOpen={isMenuOpen}
								setIsOpen={setIsMenuOpen}
								location={location}
							/>
							<ItemButtonContainer
								key={key}
								active={key === activeItemKey}
								onClick={() => setIsMenuOpen(!isMenuOpen)}
							>
								{itemInner}
							</ItemButtonContainer>
						</>
					)
				}
			})}
		</styled.div>
	)
}

type BottomBarMenuItem = {
	outlinedIcon: ReactNode
	filledIcon: ReactNode
	label: string
	href?: string
	active: boolean
}

type BottomBarMenuItemLabel =
	| "Home"
	| "Sales"
	| "Inventory"
	| "Reports"
	| "More"
type BottomBarMenuItems = Record<BottomBarMenuItemLabel, BottomBarMenuItem>

const bottomBarMenuItems: BottomBarMenuItems = {
	Home: {
		outlinedIcon: <HomeOutlineIcon />,
		filledIcon: <HomeFilledIcon />,
		label: "Home",
		href: "/",
		active: false,
	},
	Sales: {
		outlinedIcon: <DollarFilledIcon />,
		filledIcon: <DollarFilledIcon />,
		label: "Sales",
		href: "/sales",
		active: false,
	},
	Inventory: {
		outlinedIcon: <FolderOpenOutlineIcon />,
		filledIcon: <FolderOpenOutlineIcon />,
		label: "Inventory",
		href: "/inventory",
		active: false,
	},
	Reports: {
		outlinedIcon: <LineChartFilledIcon />,
		filledIcon: <LineChartFilledIcon />,
		label: "Reports",
		href: "/reports",
		active: false,
	},
	More: {
		outlinedIcon: <MenuOutlineIcon />,
		filledIcon: <MenuOutlineIcon />,
		label: "More",
		active: false,
	},
}

interface MobileMenuDrawerProps {
	isOpen: boolean
	setIsOpen: (isOpen: boolean) => void
	location: any
}

const MobileMenuDrawer = (props: MobileMenuDrawerProps) => {
	const [{ auth }, { logout }] = useAuth()
	const navigate = useNavigate()

	const handleLogout = (e) => {
		props.setIsOpen(false)
		logout()
		navigate("/login")
		e.preventDefault()
	}

	return (
		<Drawer
			opened={props.isOpen}
			onClose={() => props.setIsOpen(false)}
			position="left"
			size="xs"
			withCloseButton
			classNames={{
				header: css({
					backgroundColor: "#F8FAFC",
				}),
			}}
			title={
				<styled.img
					className="logo"
					src={LOGO_URL}
					alt="gold tree logo"
					maxHeight="50px"
					maxWidth="80px"
					height="100%"
					width="100%"
				/>
			}
		>
			<styled.div
				display="flex"
				flexDirection="column"
				justifyContent="space-between"
				height="calc(100vh - 130px)"
				width="100% !important"
				marginTop="2rem"
			>
				<MenuNavbar
					location={props.location}
					handleLogout={handleLogout}
					onClick={() => props.setIsOpen(false)}
				/>
			</styled.div>
		</Drawer>
	)
}
