import { trpc } from "#/trpc.js";
import { reportUserError } from "#/util/index.js";
import { reportBaseDataAtom } from "../state/index.js";
import * as M from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { useAtom } from "jotai";
import React, { forwardRef } from "react";

export const SalesReportForm = () => {
  const [baseData, setBaseData] = useAtom(reportBaseDataAtom);

  const { data: entities } = trpc.entity.getAll.useQuery(undefined, {
    onError: (err) => {
      reportUserError({
        title: "Failed to load entities",
        message: err.message,
      });
    },
  });

  const { data: filials } = trpc.filial.getAll.useQuery(undefined, {
    onError: (err) => {
      reportUserError({
        title: "Failed to load filials",
        message: err.message,
      });
    },
  });

  const entityOptions = React.useMemo(
    () =>
      entities?.map((entity) => ({
        label: entity.name,
        value: entity.id.toString(),
        image: entity.avatar ?? "",
        description: entity.email ?? "",
      })) ?? [],
    [entities]
  );

  const filialOptions = React.useMemo(
    () =>
      filials?.map((filial) => ({
        label: filial.name,
        value: filial.id.toString(),
        image: filial.avatar ?? "",
        description: "",
      })) ?? [],
    [filials]
  );

  return (
    <>
      <M.Title order={2}>Reports</M.Title>
      <M.Divider mt="md" mb={20} />
      <M.Group position="left">
        <M.Stack
          spacing="lg"
          sx={{
            width: "100%",
            maxWidth: "350px",
          }}
          px={10}
          py={10}
          mt="xl"
        >
          <M.Title order={3} ml={2} mt="sm">
            Generate report
          </M.Title>
          <M.Text ml={2} size={14}>
            Please select a type of report entity or filial and date range
          </M.Text>
          <M.Select
            label="Report type"
            required
            value={baseData?.type}
            onChange={(e) =>
              setBaseData({
                ...baseData,
                type: e as any,
                filialId: null,
                entityId: null,
              })
            }
            data={[
              {
                label: "Filial report",
                value: "filial",
              },
              {
                label: "Entity report",
                value: "entity",
              },
            ]}
          />
          {baseData.type === "entity" && (
            <M.Select
              label="Entity"
              required
              value={baseData?.entityId?.toString() ?? ""}
              onChange={(e) =>
                setBaseData({
                  ...baseData,
                  entityId: parseInt(e as any),
                  filialId: null,
                })
              }
              data={entityOptions}
              nothingFound="No entities found"
              maxDropdownHeight={280}
              searchable
              itemComponent={SelectItem}
              clearable
            />
          )}
          {baseData.type === "filial" && (
            <M.Select
              label="Filial"
              required
              value={baseData?.filialId?.toString() ?? ""}
              onChange={(e) =>
                setBaseData({
                  ...baseData,
                  filialId: parseInt(e as any),
                  entityId: null,
                })
              }
              data={filialOptions}
              nothingFound="No filials found"
              maxDropdownHeight={280}
              searchable
              itemComponent={SelectItem}
              clearable
            />
          )}

          <DatePickerInput
            label="Date"
            type="range"
            value={[baseData?.fromDate, baseData?.toDate]}
            clearable={false}
            popoverProps={{
              withinPortal: false,
              zIndex: 9999,
            }}
            numberOfColumns={1}
            onChange={(value) =>
              setBaseData({
                ...baseData,
                fromDate: value[0],
                toDate: value[1],
              })
            }
            allowSingleDateInRange
            required
          />
          <M.Button
            mt="lg"
            fullWidth
            onClick={() => {
              setBaseData({ ...baseData, send: true });
            }}
          >
            Generate report
          </M.Button>
        </M.Stack>
      </M.Group>
    </>
  );
};

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  image: string;
  label: string;
  description: string;
}

// eslint-disable-next-line react/display-name
const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ image, label, description, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <M.Group noWrap>
        <M.Avatar src={image} radius={"xl"} />
        <div>
          <M.Text size="sm">{label}</M.Text>
          <M.Text size="xs" opacity={0.65}>
            {description}
          </M.Text>
        </div>
      </M.Group>
    </div>
  )
);
