import { VariantInformation } from "./components";
import { LocationSection } from "./components/LocationSection";
import { FormValues } from "./types";
import * as M from "@mantine/core";
import { DefaultValues, SubmitHandler } from "react-hook-form";

export type VariantFormProps = {
  itemSkuId?: number | null;
  onSubmit: SubmitHandler<FormValues>;
  defaultValues?: DefaultValues<FormValues>;
  isLoading: boolean;
};

export const VariantForm = (props: VariantFormProps) => {
  return (
    <M.Container size="xl">
      <div className="rounded-md border border-[#E0E0E0] bg-white p-10">
        <M.Tabs defaultValue="information">
          <M.Tabs.List grow position="center">
            <M.Tabs.Tab value="information">Variant Information</M.Tabs.Tab>
            <M.Tabs.Tab value="locations">Locations</M.Tabs.Tab>
          </M.Tabs.List>
          <M.Tabs.Panel value="information" className="mt-5" h="auto">
            <VariantInformation {...props} />
          </M.Tabs.Panel>
          <M.Tabs.Panel value="locations" className="mt-5">
            <LocationSection itemSkuId={props?.itemSkuId} />
          </M.Tabs.Panel>
        </M.Tabs>
      </div>
    </M.Container>
  );
};
