import GTLoader from "#/components-ng/loader.js";
import { trpc } from "#/trpc.js";
import { reportUserError, reportUserSuccess } from "#/util/index.js";
import SeasonForm from "./Form.js";
import * as M from "@mantine/core";
import dayjs from "dayjs";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

export default function UpdateSeason() {
  const ctx = trpc.useContext();
  const params = useParams() as any;
  const id = Number.parseInt(params.id);
  const navigate = useNavigate();
  const { data, isLoading } = trpc.season.getById.useQuery(
    { id: Number(id) },
    {
      cacheTime: 0,
      refetchInterval: false,
      refetchOnWindowFocus: false,
      onError(error) {
        reportUserError({
          title: "Failed to get season",
          message: error.message,
        });
      },
    },
  );
  const { mutate, isLoading: isLoadingUpdate } = trpc.season.update.useMutation(
    {
      onSuccess(data) {
        reportUserSuccess({
          title: "Season updated successfully",
          message: (
            <span>
              Category <span className="font-semibold">{data.name}</span>{" "}
              created successfully
            </span>
          ),
        });
        navigate("/inventory/seasons");
      },
      onError(error) {
        ctx.season.invalidate();
        ctx.v2_5.season.invalidate();
        reportUserError({
          title: "Failed to update season",
          message: error.message,
        });
      },
    },
  );

  const handleSubmit = React.useCallback(
    (s) =>
      mutate({
        id,
        // TODO: We need to handle the field `active`, the form field `current` and multiple banners
        input: {
          name: s.season,
          description: s.description,
          defaultBannerImage:
            s.banner.find((banner: any) => banner.default)?.url ??
            s.banner[0]?.url,
          initialDate: s.initialDate,
          finalDate: s.endDate,
          metaDescription: s?.metaDescription ?? null,
          active: s.current ?? true,
          images: s.banner.map((b) => b.url),
        },
      }),
    // eslint-disable-next-line
    [mutate],
  );

  return data ? (
    <SeasonForm
      defaultValues={{
        initialDate: dayjs(data.initialDate).toDate(),
        endDate: dayjs(data.finalDate).toDate(),
        season: data.name,
        description: data.description,
        banner: data.images?.map((b) => ({
          url: b,
          default:
            data?.defaultBannerImage && data.defaultBannerImage === b
              ? true
              : false,
        })),
        metaDescription: data?.metaDescription ?? "",
        current: data?.active ?? true,
      }}
      onSubmit={handleSubmit}
      isLoading={isLoadingUpdate}
    />
  ) : (
    <M.LoadingOverlay
      visible={isLoading}
      loader={<GTLoader width={100} height={100} />}
    />
  );
}
