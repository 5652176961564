import { useParams } from "react-router"
import { CustomerPanel } from "../../components/customer-panel"
import { RouterOutputs, trpc } from "#/trpc"
import { styled, VStack } from "#/css/jsx"
import {
	Button,
	ComboBox,
	Input,
	Key,
	Label,
	ListBox,
	ListBoxItem,
	Popover,
} from "react-aria-components"
import { ChevronDownIcon, LoaderIcon } from "lucide-react"
import { css } from "#/css/css"
import { MantineReactTable, MRT_ColumnDef } from "mantine-react-table"
import { DisplayTable } from "#/components"
import React from "react"
import placeholderImage from "#/placeholder-image.jpg"
import { create } from "zustand"
import { NumberInput } from "@mantine/core"
import { reportUserError, reportUserSuccess } from "#/util"
import Decimal from "decimal.js"
import { applyDiscountV2 } from "../../util"

type CostBreakdown = RouterOutputs["v2_5"]["cart"]["costBreakdown"]
type ItemBreakdowns = NonNullable<CostBreakdown["itemsBreakdown"]>
type Cart = NonNullable<RouterOutputs["v2_5"]["cart"]["getOne"]>
type CartItem = Cart["cartItemSku"][0]
type ItemSku = CartItem["itemSku"]

type CartItemInput = {
	itemSku: ItemSku
	quantity: number
}

interface CartState {
	cartUuid: string | null
	setCartUuid: (cartUuid: string | null) => void
	items: Array<CartItemInput>
	itemBreakdowns: ItemBreakdowns
	upsertItem: (item: CartItemInput) => void
	setItems: (items: Array<CartItemInput>) => void
	setItemBreakdowns: (itemBreakdowns: ItemBreakdowns) => void
	setItemQty: (itemSku: number, qty: number) => void
}

const useCartStore = create<CartState>()((set) => ({
	cartUuid: null,
	items: [],
	itemBreakdowns: {},
	setCartUuid: (cartUuid) => set({ cartUuid }),
	upsertItem: (item: CartItemInput) =>
		set((state) => {
			let isExistingItem = false

			const newItems = state.items.map((i) => {
				if (i.itemSku.id === item.itemSku.id) {
					isExistingItem = true
					i.quantity += item.quantity
				}
				return i
			})

			if (!isExistingItem) {
				newItems.push(item)
			}

			return { items: newItems }
		}),
	setItems: (items: Array<CartItemInput>) => set({ items }),
	setItemBreakdowns: (itemBreakdowns: ItemBreakdowns) =>
		set({ itemBreakdowns }),
	setItemQty: (itemSku: number, qty: number) =>
		set((state) => {
			const item = state.items.find((i) => i.itemSku.id === itemSku)
			if (item == null) return state
			item.quantity = qty
			return state
		}),
}))

export function EditEcomCartPage() {
	const { data: cart } = useCart()

	const setCartItems = useCartStore((state) => state.setItems)
	const setCartUuid = useCartStore((state) => state.setCartUuid)
	useEffect(() => {
		if (cart == null) return

		setCartItems(cart.cartItemSku)
		setCartUuid(cart.uuid)
	}, [cart])

	const customerId = cart?.customerId

	return (
		<VStack alignItems="stretch" gap="10">
			<CustomerPanel customerId={customerId} />
			<VStack gap="4" alignItems="start">
				<ItemSearchField />
				<CartItemsTable />
				<CostBreakdown customerId={customerId} />
			</VStack>
		</VStack>
	)
}

const itemSearchFieldStyles = {
	label: css({
		fs: "sm",
		c: "slate.500",
	}),
	input: css({
		border: "solid 1px token(colors.slate.200)",
		rounded: "sm",
		py: "1",
		pl: "2.5",
		pr: "8",
		outlineColor: "brand.500",
		bg: "white",
	}),

	popover: css({
		bg: "white",
		shadow: "sm",
		w: "var(--trigger-width)",
	}),

	listBoxItem: css({
		px: "3",
		py: "2",
		"&[data-selected]": {
			bg: "brand.500",
			c: "white",
			fontWeight: "medium",
		},
		"&[data-focused],&[data-pressed]": {
			bg: "slate.50",
		},
	}),
}

type SearchItem = RouterOutputs["itemSku"]["search"][0]

function ItemSearchField() {
	const [query, setQuery] = React.useState("")

	const { data, isLoading } = trpc.itemSku.search.useQuery(
		{
			query: `\\"${query}\\"`,
			onlyAvailable: false,
		},
		{
			keepPreviousData: true,
		},
	)
	const comboBoxItems = data ?? []

	const invalidateUseCartQuery = useInvalidateUseCartQuery()
	const { mutate } = trpc.v2_5.cart.patch.useMutation({
		onSuccess(data) {
			invalidateUseCartQuery()
			if (data.isOk()) {
				reportUserSuccess({
					title: `Item added to cart`,
				})
			} else {
				reportUserError({
					title: "Failed to add item to cart",
				})
			}
		},
	})

	const cartUuid = useCartStore((state) => state.cartUuid)
	function handleSelectionChange(itemKey: Key | null) {
		if (itemKey == null) return

		const item = comboBoxItems.find((i) => i.id === itemKey)

		if (item == null) return
		if (cartUuid == null) return

		mutate({
			cartUuid,
			addedItems: [
				{
					itemSku: item.sku,
					quantity: 1,
				},
			],
		})
	}

	return (
		<ComboBox
			items={comboBoxItems}
			inputValue={query}
			onInputChange={(v) => setQuery(v)}
			onSelectionChange={handleSelectionChange}
			allowsCustomValue
			menuTrigger="focus"
		>
			<Label className={itemSearchFieldStyles.label}>Search items</Label>
			<styled.div display="flex" alignItems="center">
				<Input className={itemSearchFieldStyles.input} />
				<Button
					className={css({
						ml: "-8",
					})}
				>
					{isLoading ? (
						<LoaderIcon className={css({ c: "slate.400" })} />
					) : (
						<ChevronDownIcon className={css({ c: "slate.400" })} />
					)}
				</Button>
			</styled.div>
			<Popover className={itemSearchFieldStyles.popover}>
				<ListBox>
					{(item: SearchItem) => <ItemSearchFieldListBoxItem item={item} />}
				</ListBox>
			</Popover>
		</ComboBox>
	)
}

interface ItemSearchFieldListBoxItemProps {
	item: SearchItem
}

function ItemSearchFieldListBoxItem({ item }: ItemSearchFieldListBoxItemProps) {
	return (
		<ListBoxItem
			id={item.id}
			textValue={`${item.sku}`}
			className={itemSearchFieldStyles.listBoxItem}
		>
			<styled.div display="flex" alignItems="center" columnGap="2" fs="sm">
				<styled.img
					src={item.defaultImage || placeholderImage}
					w="20px"
					h="20px"
				/>
				<span>
					{item.sku} - {item.title}
				</span>
			</styled.div>
		</ListBoxItem>
	)
}

const columns = [
	{
		Header: "Image",
		Cell: ({ row: { original } }) => {
			return (
				<styled.img
					w="80px"
					h="80px"
					src={original.itemSku.defaultImage ?? placeholderImage}
				/>
			)
		},
	},
	{
		Header: "SKU",
		accessor: "itemSku.sku",
	},
	{
		Header: "Title",
		accessor: "itemSku.title",
	},
	{
		Header: "Location",
		accessor: "itemSku.itemSkuStock.0.storeLocation",
	},
	{
		Header: "PKG",
		accessor: "itemSku.presentationValue",
		Cell: ({ value, row: { original } }) => {
			return (
				<p>
					{value} {original.itemSku.presentationType}
				</p>
			)
		},
	},
	{
		Header: "Qty",
		id: "qty",
		Cell: ({ row: { original } }) => {
			const item = original.itemSku
			const qty = original.quantity

			const cartUuid = useCartStore((state) => state.cartUuid)
			const invalidateUseCartQuery = useInvalidateUseCartQuery()
			const { mutate } = trpc.v2_5.cart.patch.useMutation({
				onSuccess(data) {
					if (data.isErr()) {
						reportUserError({
							title: "Failed to add item to cart",
						})
					}
					invalidateUseCartQuery()
				},
			})

			return (
				<NumberInput
					value={qty}
					onChange={(v) => {
						if (v !== "") {
							mutate({
								cartUuid: cartUuid!,
								addedItems: [
									{
										itemSku: item.sku,
										quantity: v,
									},
								],
							})
						}
					}}
					classNames={{
						root: css({
							w: "10ch",
						}),
					}}
				/>
			)
		},
	},
	{
		Header: "Discount",
		id: "discount",
		Cell: ({ row: { original } }) => {
			const itemBreakdowns = useCartStore((state) => state.itemBreakdowns)
			const itemSkuId = original.itemSku.id
			const itemBreakdown = itemBreakdowns[itemSkuId]

			if (itemBreakdown == null) return null

			const discount = itemBreakdown.discount

			if (discount == null) return null

			return (
				<p>
					{discount.type === "AMOUNT"
						? `$${discount.amount.toFixed(2)}`
						: `${discount.amount.toFixed(2)}%`}{" "}
					{discount.reference}
				</p>
			)
		},
	},
	{
		Header: "Subtotal",
		id: "subtotal",
		Cell: ({ row: { original } }) => {
			const itemBreakdowns = useCartStore((state) => state.itemBreakdowns)
			const itemSkuId = original.itemSku.id
			const itemBreakdown = itemBreakdowns[itemSkuId]
			if (itemBreakdown == null) return null

			return <p>{`$${itemBreakdown.subtotal.toFixed(2)}`}</p>
		},
	},
	{
		Header: "Total",
		id: "total",
		Cell: ({ row: { original } }) => {
			const itemBreakdowns = useCartStore((state) => state.itemBreakdowns)
			const itemSkuId = original.itemSku.id
			const itemBreakdown = itemBreakdowns[itemSkuId]
			if (itemBreakdown == null) return null

			return <p>{`$${itemBreakdown.total.toFixed(2)}`}</p>
		},
	},
]

function CartItemsTable() {
	const cartItems = useCartStore((state) => state.items)

	return (
		<styled.div bg="white" w="full" maxH="500px" overflow="auto">
			<DisplayTable columns={columns} data={cartItems} pagination={false} />
		</styled.div>
	)
}

function useCart() {
	const params = useParams()
	const cartUuid = params.cartUuid

	return trpc.v2_5.cart.getOne.useQuery(
		{
			cartUuid: cartUuid as string,
			ecom: true,
		},
		{
			enabled: cartUuid != null,
			refetchInterval: 5000,
		},
	)
}

function useInvalidateUseCartQuery() {
	const tctx = trpc.useContext()
	const params = useParams()
	const cartUuid = params.cartUuid

	return () => {
		tctx.v2_5.cart.getOne.invalidate({
			cartUuid,
		})
		tctx.v2_5.cart.costBreakdown.invalidate({
			cartUuid,
		})
	}
}

type CostBreakdownProps = {
	customerId?: number | null
}

function CostBreakdown({ customerId }: CostBreakdownProps) {
	const cartUuid = useCartStore((state) => state.cartUuid)

	const { data: cb } = trpc.v2_5.cart.costBreakdown.useQuery(
		{
			cartUuid: cartUuid!,
			couponCode: null,
			deliveryMode: "SHIPPING",
			shippingCountry: "US",
			itemsBreakdown: true,
			onBehalfOfUserId: customerId,
		},
		{
			enabled: cartUuid != null,
		},
	)

	const setItemBreakdowns = useCartStore((state) => state.setItemBreakdowns)
	useEffect(() => {
		if (cb == null) return

		setItemBreakdowns(cb.itemsBreakdown!)
	}, [cb])

	return (
		<styled.div w="full" display="flex" justifyContent="end">
			<styled.div w="400px" display="flex" flexDirection="column" gap="2">
				<CostBreakdownRow
					main
					title="Total"
					value={`$${cb?.total.toFixed(2)}`}
				/>
				<CostBreakdownRow
					title="Subtotal"
					value={`$${cb?.subtotal.toFixed(2)}`}
				/>
				<CostBreakdownRow
					title="Discount"
					value={`$${cb?.discount.toFixed(2)}`}
				/>
				<CostBreakdownRow title="Tax" value={`$${cb?.tax.toFixed(2)}`} />
				<CostBreakdownRow
					title="Shipping"
					value={`$${cb?.shippingCost.toFixed(2)}`}
				/>
			</styled.div>
		</styled.div>
	)
}

function CostBreakdownRow(props: {
	title: string
	value: string
	main?: boolean
}) {
	return (
		<styled.div
			display="flex"
			justifyContent="space-between"
			alignItems="center"
			bg={props.main ? "green.600" : undefined}
			c={props.main ? "white" : undefined}
			px="3"
			py="1"
			rounded="md"
		>
			<p>{props.title}</p>
			<p>{props.value}</p>
		</styled.div>
	)
}
