import {
  Combobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  SpinnerIcon,
} from "#/components-ng";
import {
  ImageUpload,
  UseS3UploadObject,
  UseS3UploadResult,
} from "#/components-ng/ui";
import { RouterOutputs, trpc } from "#/trpc";
import { reportUserError } from "#/util";
import { C } from "../types";
import { SuggestedPriceSection } from "./SuggestedPriceSection";
import { ActionIcon, Avatar, Tooltip, Text } from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import React from "react";
import { useController } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import DefaultImage from "~icons/ion/image-outline";
import TrashIcon from "~icons/ion/trash-outline";

export function VendorSection() {
  const form = C.useFormContext();

  const [imageVendor, setImageVendor] = React.useState<{
    url: string;
    uuid: string;
    isLoading: boolean;
  } | null>(() =>
    form.getValues(`vendorImage`)
      ? {
          url: form.getValues(`vendorImage`)!,
          uuid: uuidv4(),
          isLoading: false,
        }
      : null
  );

  const handleS3UploadSuccess = (result: UseS3UploadResult) => {
    setImageVendor({
      url: result.url,
      uuid: result.uuid,
      isLoading: false,
    });
    form.setValue(`vendorImage`, result.url);
  };

  const handleS3UploadingStart = (upload: UseS3UploadObject) => {
    setImageVendor({
      url: upload.url!,
      uuid: upload.uuid,
      isLoading: true,
    });
  };

  const handleRemoveVendorImage = () => {
    openConfirmModal({
      title: "Are you sure you want to remove vendor image?",
      labels: {
        cancel: "Cancel",
        confirm: "Delete",
      },
      confirmProps: { color: "red" },
      onConfirm: () => {
        form.setValue(`vendorImage`, null);
        setImageVendor(null);
      },
    });
  };

  return (
    <div>
      <p className="mb-4 text-xl font-semibold">Vendor</p>
      <div className="mb-8 grid grid-cols-2 gap-8">
        <VendorField />
        <C.InputField label="Vendor ALU" name="vendorAlu" />
        <RateCostField />
        <C.SelectField
          data={[
            {
              label: "By gram",
              id: "BY_GRAM",
            },
            {
              label: "By unit",
              id: "BY_UNIT",
            },
          ]}
          name="vendorCostType"
          label="Cost type"
        />
        <C.InputField
          type="number"
          step="0.01"
          label="Cost"
          name="vendorCost"
        />
      </div>
      <div className="mt-10 flex items-center gap-x-10">
        <Text className="text-sm font-medium text-slate-500">Vendor Image</Text>
      </div>
      <div className="my-10 flex items-center gap-x-10">
        {imageVendor?.isLoading ? (
          <SpinnerIcon className="animate-spin" />
        ) : (
          <div className="relative shrink-0">
            <Avatar size="xl" src={imageVendor?.url}>
              <DefaultImage />
            </Avatar>
            {imageVendor?.url && (
              <Tooltip label="Delete">
                <ActionIcon
                  className="absolute right-0 top-0 mr-2 mt-2 bg-transparent p-1 hover:bg-red-200"
                  onClick={handleRemoveVendorImage}
                  size={"sm"}
                >
                  <TrashIcon className="text-red-500" />
                </ActionIcon>
              </Tooltip>
            )}
          </div>
        )}
        <ImageUpload
          directory="item-skus"
          supports={{ image: true }}
          onS3UploadSuccess={handleS3UploadSuccess}
          onS3UploadingStart={handleS3UploadingStart}
          hiddenVisually={true}
        />
      </div>
      <SuggestedPriceSection />
    </div>
  );
}

type Vendor = NonNullable<RouterOutputs["vendor"]["getById"]>;
function VendorField() {
  const [query, setQuery] = React.useState("");
  const { data: unfilteredData } = trpc.vendor.getAll.useQuery();
  const controller = useController({
    name: "vendor",
  });
  const data =
    unfilteredData?.filter((v) =>
      v.company?.toLowerCase().includes(query.toLowerCase())
    ) ?? [];

  return (
    <C.Field label="Vendor" name="vendor">
      {({ id }) => (
        <Combobox
          value={controller.field.value}
          by={(a: Vendor, b: Vendor) => a.id === b.id}
          onChange={(v: Vendor) => {
            controller.field.onChange({
              id: v.id,
              company: v.company,
            });
          }}
          className="w-full"
        >
          <ComboboxInput
            displayValue={(v?: Vendor) => v?.company ?? ""}
            onChange={(e) => setQuery(e.currentTarget.value)}
            id={id}
          />
          <ComboboxOptions>
            {data?.map((vendor) => (
              <ComboboxOption key={vendor.id} value={vendor}>
                {vendor.company}
              </ComboboxOption>
            ))}
          </ComboboxOptions>
        </Combobox>
      )}
    </C.Field>
  );
}

function RateCostField() {
  const vendor = C.useWatch({
    name: "vendor",
    control: C.useFormContext().control,
  });

  const { data } = trpc.vendorRateCost.getAllByVendorId.useQuery(
    {
      vendorId: vendor.id ?? 0,
    },
    {
      cacheTime: 0,
      enabled: !!vendor.id,
      onError: (err) => {
        reportUserError({
          title: "Failed to fetch rate costs",
          message: err.message,
        });
      },
    }
  );

  const dataOptions = React.useMemo(() => {
    if (vendor != null) {
      return (
        data?.map((rc) => ({
          label: rc.rateCost.name,
          id: rc.rateCostId.toString(),
        })) ?? []
      );
    }
    return [];
  }, [data, vendor]);

  return (
    <C.SelectField
      data={dataOptions ?? []}
      name="rateCostId"
      label="Rate cost"
      disabled={!vendor.id}
    />
  );
}
