import { SearchOutline } from "#/components-ng";
import { css } from "#/css/css";
import { TextField } from "@radix-ui/themes";

interface SearchButtonProps {
  setQuery: (value: string) => void;
  placeholder?: string;
}

export function SearchButton(props: SearchButtonProps) {
  return (
    <TextField.Root
      placeholder={props.placeholder ?? "Search..."}
      onChange={(e) => props.setQuery(e.currentTarget.value)}
      radius="full"
    >
      <TextField.Slot>
        <SearchOutline
          className={css({
            color: "gray.400",
          })}
        />
      </TextField.Slot>
    </TextField.Root>
  );
}
