// @ts-nocheck

import { HomeFilledIcon } from "#/components-ng/index.js";
import Card from "../../components/Card/index.js";
import CategoryGridLayout from "../../components/CategoryGridLayout/index.jsx";
import SceneLayout from "../../components/SceneLayout/index.js";
import {
  ChatBalloonIcon,
  ClipboardTimeIcon,
  MoonStarsIcon,
  NewArrivalsIcon,
  OrderBowlIcon,
  OutOfStockIcon,
  PendingCartsIcon,
  ItemListIcon,
  CustomersIcon,
} from "../../components/icons/index.jsx";
import { Stack, StackDivider } from "@chakra-ui/react";

const HomeScene = () => {
  return (
    <SceneLayout icon={<HomeFilledIcon />}>
      <Stack spacing={6} divider={<StackDivider />}>
        <CategoryGridLayout category="Sales">
          <Card
            icon={<OrderBowlIcon size="big" />}
            text="Make a sale"
            path="/sales/make-a-sale"
            module="sales.makeASale"
          />
          <Card
            icon={<PendingCartsIcon size="big" />}
            text="Pending carts"
            path="/sales/pending-carts"
            module="sales.pendingCarts"
          />
          <Card
            icon={<ClipboardTimeIcon size="big" />}
            text="Sale history"
            path="/sales/sales-history"
            module="sales.salesHistory"
          />
          <Card
            icon={<MoonStarsIcon size="big" />}
            text="End of day"
            path="/sales/end-of-day"
            module="sales.endOfDay"
          />
          <Card
            icon={<CustomersIcon size="big" />}
            text="Customers"
            path="/customers/list"
            module="customers.customerList"
          />
        </CategoryGridLayout>
        <CategoryGridLayout category="Inventory">
          <Card
            icon={<ItemListIcon size="big" />}
            text="Item list"
            path="/inventory/item-list"
            width="120px"
            height="120px"
            module="inventory.itemList"
          />
          <Card
            icon={<NewArrivalsIcon size="giant" />}
            text="New arrivals"
            path="/inventory/new-arrivals"
            width="120px"
            height="120px"
            module="inventory.newArrivals"
          />
          <Card
            icon={<ChatBalloonIcon size="big" />}
            text="Suggested items"
            path="/inventory/suggested-items"
            width="120px"
            height="120px"
            module="inventory.suggestedItems"
          />
          <Card
            icon={<OutOfStockIcon size="big" />}
            text="Out of stock"
            path="/inventory/out-of-stock"
            width="120px"
            height="120px"
            module="inventory.outOfStock"
          />
        </CategoryGridLayout>
      </Stack>
    </SceneLayout>
  );
};

export default HomeScene;
