import logoUrl from "#/images/colored-logo-invoice.png";
import { RouterOutputs } from "#/trpc.js";
import { fetchImageAsBase64 } from "#/util/index.js";
import { applyDiscountV2 } from "../util/index.js";
import dayjs from "dayjs";
import Decimal from "decimal.js";
import ExcelJS, { Borders } from "exceljs";
import { saveAs } from "file-saver";

type RefundsByReference = NonNullable<
  RouterOutputs["refundOrderItemSku"]["getByReference"]
>;

export const exportReturnToXLSX = async (refunds: RefundsByReference) => {
  const wb = new ExcelJS.Workbook();
  const sheet = wb.addWorksheet("Sheet 1", {
    pageSetup: { paperSize: 9 },
  });

  sheet.getCell(1, 1).value = `Printed: ${dayjs().format("MM/DD/YYYY")}`;
  sheet.getCell(2, 1).value = `Store: 1`;
  sheet.getCell(3, 1).value = `Workstation: 1`;

  sheet.mergeCells("I1:J1");
  sheet.mergeCells("I2:J2");
  sheet.mergeCells("I3:J3");
  sheet.mergeCells("I4:J4");
  sheet.getCell("I1").value = `Refund receipt R${refunds[0].reference}`;
  sheet.getCell("I2").value = `Refund Date: ${dayjs(
    refunds[0].createdAt
  ).format("MM/DD/YYYY")}`;
  sheet.getCell("I3").value = `Cashier: ${refunds[0].customer?.firstName} ${
    refunds[0].customer?.lastName ?? ""
  }`;
  sheet.getCell("I4").value = "Page: 1";

  sheet.mergeCells("C5:H5");
  sheet.getCell("C5").font = {
    bold: true,
    size: 22,
  };
  sheet.getCell("C5").value = "REFUND";
  sheet.getCell("C5").alignment = {
    horizontal: "center",
  };
  sheet.getRow(5).height = 28;

  const imgData = await fetchImageAsBase64(logoUrl);
  const logo = wb.addImage({
    base64: imgData.data,
    extension: imgData.ext,
  });
  sheet.addImage(logo, {
    tl: {
      col: 3,
      row: 5,
    },
    ext: { width: 180, height: 120 },
  });

  sheet.mergeCells("C12:H12");
  sheet.mergeCells("C13:H13");
  sheet.mergeCells("C14:H14");
  sheet.mergeCells("C15:H15");
  sheet.getCell("C12").value = "GOLD TREE";
  sheet.getCell("C13").value = "755 NW 72n AVE PLAZA 33";
  sheet.getCell("C14").value = "MIAMI, FL, 33126";
  sheet.getCell("C15").value = "PHONE: +1 (786) 7177131";

  sheet.getCell("A17").value = "Bill to:";
  sheet.getCell("B17").value =
    refunds[0].customer?.company ??
    `${refunds[0].customer?.firstName} ${refunds[0].customer?.lastName ?? ""}`;

  const borderStyle = {
    top: { style: "thin" },
    bottom: { style: "thin" },
    left: { style: "thin" },
    right: { style: "thin" },
  } as Partial<Borders>;

  sheet.getCell("A24").value = "SKU";
  sheet.getCell("A24").border = borderStyle;
  sheet.getCell("B24").value = "Qty";
  sheet.getCell("B24").border = borderStyle;
  sheet.mergeCells("C24:G24 ");
  sheet.getCell("C24").value = "Product Name";
  sheet.getCell("C24").border = borderStyle;
  sheet.getCell("H24").value = "Price";
  sheet.getCell("H24").border = borderStyle;
  sheet.getCell("I24").value = "Discount";
  sheet.getCell("I24").border = borderStyle;
  sheet.getCell("J24").value = "Ext price";
  sheet.getCell("J24").border = borderStyle;

  refunds?.forEach((p, i) => {
    sheet.getCell(`A${i + 25}`).value = p?.orderItemSku.itemSku.sku;
    sheet.getCell(`A${i + 25}`).border = borderStyle;
    sheet.getCell(`B${i + 25}`).value = -p?.quantity;
    sheet.getCell(`B${i + 25}`).border = {
      top: { style: "thin" },
      bottom: { style: "thin" },
      left: { style: "thin" },
      right: { style: "thin" },
    };
    sheet.mergeCells(`C${i + 25}:G${i + 25}`);
    sheet.getCell(`C${i + 25}`).value = p?.orderItemSku.itemSku.title;
    sheet.getCell(`C${i + 25}`).border = borderStyle;
    sheet.getCell(`H${i + 25}`).value = -p?.orderItemSku.price.toNumber();
    sheet.getCell(`H${i + 25}`).numFmt = "$0.00";
    sheet.getCell(`H${i + 25}`).border = borderStyle;
    sheet.getCell(`I${i + 25}`).value = `$${
      p?.orderItemSku.discountAmount?.toNumber().toFixed(2) ?? 0
    }`;
    sheet.getCell(`I${i + 25}`).border = borderStyle;
    sheet.getCell(`J${i + 25}`).value = -(p?.total?.toNumber().toFixed(2) ?? 0);
    sheet.getCell(`J${i + 25}`).numFmt = "$0.00";
    sheet.getCell(`J${i + 25}`).border = borderStyle;
  });
  const afterLastRow = 25 + (refunds?.length ?? 0);

  sheet.getCell(`I${afterLastRow}`).value = "Subtotal";
  sheet.getCell(`J${afterLastRow}`).value = 0;
  sheet.getCell(`J${afterLastRow}`).numFmt = "$0.00";
  sheet.getCell(`I${afterLastRow}`).border = borderStyle;
  sheet.getCell(`J${afterLastRow}`).border = borderStyle;

  sheet.getCell(`I${afterLastRow + 1}`).value = "Discount";
  sheet.getCell(`J${afterLastRow + 1}`).value = -refunds?.reduce(
    (old, e) =>
      old +
      (e.orderItemSku.price.toNumber() * e.quantity -
        (applyDiscountV2(
          e.orderItemSku.price.times(e.quantity),
          e.orderItemSku?.discountAmount && e.orderItemSku?.discountType
            ? {
                amount: e.orderItemSku?.discountAmount,
                type: e.orderItemSku?.discountType,
              }
            : null
        )?.toNumber() ?? 0)),
    0
  );
  sheet.getCell(`J${afterLastRow + 1}`).numFmt = "$0.00";
  sheet.getCell(`J${afterLastRow + 1}`).alignment = {
    horizontal: "right",
  };
  sheet.getCell(`I${afterLastRow + 1}`).border = borderStyle;
  sheet.getCell(`J${afterLastRow + 1}`).border = borderStyle;

  const total = refunds?.reduce((acc, entry) => {
    return new Decimal(entry?.total ?? 0)?.plus(acc)?.toNumber();
  }, 0);

  sheet.getCell(`I${afterLastRow + 2}`).value = "Total";
  sheet.getCell(`J${afterLastRow + 2}`).value = -Number(total);
  sheet.getCell(`J${afterLastRow + 2}`).numFmt = "$0.00";
  sheet.getCell(`I${afterLastRow + 2}`).border = borderStyle;
  sheet.getCell(`J${afterLastRow + 2}`).border = borderStyle;

  const xlsx = await wb.xlsx.writeBuffer({
    // @ts-ignore
    base64: true,
  });
  saveAs(
    new Blob([xlsx], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    }),
    `invoice_${dayjs().format("MM/DD/YYYY")}`
  );
};
