import { getSession } from "./context/AuthContext.js";
import "@radix-ui/themes/styles.css";
import "./index.css";
import GlobalStyle from "./modules/theme/globalStyles.js";
import ThemeContext from "./modules/theme/index.jsx";
import { chakraTheme, mantineTheme } from "./modules/theme/styles.js";
import AppScenes from "./scenes/index.jsx";
import { gTrpc, setGTrpc, trpc } from "./trpc.js";
import { reportUserError } from "./util/index.js";
import { ChakraProvider } from "@chakra-ui/react";
import { MantineProvider, createEmotionCache } from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import { Notifications } from "@mantine/notifications";
import { Theme } from "@radix-ui/themes";
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { httpBatchLink } from "@trpc/client";
import React from "react";
import superjson from "superjson";
import "virtual:uno.css";
import { z } from "zod";

const wrapInLayer: (layerName: string) => any = (layerName) => (node) => {
  if (node?.value.includes("*")) {
    // console.log(node);
  }
  
  // we want to wrap only rules in a layer
  if (node?.type !== "rule" || node?.root?.type === "@layer") return;

  // wrap ruleset in a layer
  const child = { ...node, parent: node, root: node };

  Object.assign(node, {
    children: [child],
    length: 6,
    parent: null,
    props: [layerName],
    return: "",
    root: null,
    type: "@layer",
    value: `@layer ${layerName}`,
  });
};

const myCache = createEmotionCache({
  key: "mantine",
  // prepend: true,
  stylisPlugins: [wrapInLayer("emotion")],
});

function App() {
  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        mutationCache: new MutationCache({
          onError(errUnknown) {
            console.error(errUnknown);
            const err = z
              .object({
                message: z.string(),
              })
              .safeParse(errUnknown);

            const msg = err.success ? err.data.message : "Unknown error";
            reportUserError({
              title: "Something went wrong...",
              message: msg,
            });
          },
        }),
        queryCache: new QueryCache({
          onError(errUnknown, query) {
            const err = z
              .object({
                message: z.string(),
              })
              .safeParse(errUnknown);

            const msg = err.success ? err.data.message : "Unknown error";
            if (query.meta?.reportUserError !== false) {
              reportUserError({
                title: "Something went wrong...",
                message: msg,
              });
            }
          },
        }),
      }),
  );
  const [trpcClient] = React.useState(() =>
    trpc.createClient({
      transformer: superjson,
      links: [
        httpBatchLink({
          url:
            // @ts-ignore
            import.meta.env.SECRET_ENTITY_POS_API_URL ||
            "http://localhost:4000/api/trpc",
          headers() {
            return {
              Authorization: getSession() ?? "",
            };
          },
        }),
      ],
    }),
  );
  if (gTrpc == null) {
    // @ts-ignore
    setGTrpc(trpcClient);
  }
  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <ThemeContext>
          {/* @ts-ignore */}
          <MantineProvider theme={mantineTheme} emotionCache={myCache}>
            <ChakraProvider
              theme={chakraTheme}
              resetCSS={false}
              disableGlobalStyle
            >
              <Theme>
                <Notifications zIndex={1300} />
                <ModalsProvider>
                  <GlobalStyle />
                  <AppScenes />
                </ModalsProvider>
              </Theme>
            </ChakraProvider>
          </MantineProvider>
        </ThemeContext>
      </QueryClientProvider>
    </trpc.Provider>
  );
}

export default App;
