import { RouterOutputs, trpc } from "#/trpc";
import { reportUserSuccess } from "#/util";
import { ActionIcon, Menu } from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_FilterOption,
  MRT_PaginationState,
  MRT_SortingState,
  useMantineReactTable,
} from "mantine-react-table";
import { useState, useMemo } from "react";
import { MdMoreHoriz, MdOutlineDownload } from "react-icons/md";
import { Link } from "react-router-dom";
import EditIcon from "~icons/ion/create-outline";
import DeleteIcon from "~icons/ion/trash-outline";
import { DropdownMenu, IconButton, Tooltip } from "@radix-ui/themes";
import { HStack } from "#/css/jsx";
import { SearchButton } from "#/components@v2_5/search-button.js";
import { Button, Dropdown } from "@gt/ui";
import { css } from "#/css/css";

export const CustomAttributes = () => {
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });

  // Sorting
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const querySorting = useMemo(() => {
    if (sorting[0]) {
      return {
        desc: sorting[0].desc,
        key: sorting[0].id as any,
      };
    }
    return null;
  }, [sorting]);

  const [globalFilter, setGlobalFilter] = useState<
    MRT_FilterOption | undefined
  >();

  const { data, isLoading } =
    trpc.v2_5.customAttributes.getAllCustomAttributesByPage.useQuery(
      {
        pageIndex: pagination.pageIndex,
        pageSize: pagination.pageSize,
        sorting: querySorting,
        search: globalFilter,
        filters: null,
      },
      {
        keepPreviousData: true,
      },
    );

  const { mutate: exportCsv, isLoading: isLoadingExport } =
    trpc.v2_5.customAttributes.exportTableToCsv.useMutation({
      onSuccess: () => {
        reportUserSuccess({
          title: "The csv file has been sent to email",
        });
      },
    });

  const handleExportCsv = () => {
    exportCsv({
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize,
      sorting: querySorting,
      search: globalFilter,
      filters: null,
    });
  };

  const table = useMantineReactTable({
    data: data?.entries ?? [],
    columns: columns(),
    manualPagination: true,
    enableTopToolbar: false,
    rowCount: data?.totalEntries ?? 0,
    enableFilters: false,
    state: {
      pagination,
      sorting,
      isLoading,
    },
    enableStickyHeader: true,
    manualSorting: true,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    renderBottomToolbarCustomActions: () => (
      <div
        className={css({
          display: "flex",
          alignItems: "center",
          gap: 2,
        })}
      >
        <Tooltip content="Export to CSV">
          <IconButton
            color="gray"
            variant="soft"
            loading={isLoadingExport}
            onClick={handleExportCsv}
          >
            <MdOutlineDownload />
          </IconButton>
        </Tooltip>
      </div>
    ),
  });

  return (
    <div>
      <HStack mb={6} justify="space-between">
        <DrowndownButton />
        <SearchButton setQuery={setGlobalFilter} />
      </HStack>
      <MantineReactTable table={table} />
    </div>
  );
};

type CustomAttribute =
  RouterOutputs["v2_5"]["customAttributes"]["getAllCustomAttributesByPage"]["entries"][number];

const columns = (): MRT_ColumnDef<CustomAttribute>[] => [
  {
    header: "Actions",
    id: "actions",
    Cell: ({ row: { original } }) => {
      const ctx = trpc.useContext();

      const { mutate: deleteCustomAttribute } =
        trpc.customAttribute.private.delete.useMutation({
          onSuccess() {
            ctx.v2_5.customAttributes.invalidate();
            reportUserSuccess({
              title: "Custom attribute deleted",
            });
          },
        });

      return (
        <Menu>
          <Menu.Target>
            <ActionIcon>
              <MdMoreHoriz size={20} />
            </ActionIcon>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item
              component={Link}
              to={`edit/${original.id}`}
              icon={<EditIcon />}
            >
              Edit
            </Menu.Item>
            <Menu.Item
              onClick={() =>
                openConfirmModal({
                  title:
                    "Are you sure you want to delete this custom attribute",
                  labels: {
                    confirm: "Delete",
                    cancel: "Cancel",
                  },
                  confirmProps: { color: "red" },
                  onConfirm: () =>
                    deleteCustomAttribute({
                      id: original.id as number,
                    }),
                })
              }
              icon={<DeleteIcon />}
            >
              Delete
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      );
    },
  },
  {
    header: "Attribute Name",
    accessorKey: "name",
  },
  {
    header: "Description",
    accessorKey: "description",
  },
  {
    header: "Type",
    accessorKey: "type",
  },
];

const DrowndownButton = () => {
  return (
    <Dropdown.Root>
      <Dropdown.Trigger>
        <Button
          className={css({
            gap: 4,
          })}
        >
          I want to
          <DropdownMenu.TriggerIcon />
        </Button>
      </Dropdown.Trigger>
      <Dropdown.Content>
        <Dropdown.Item>
          <Link to="create">Create custom attribute</Link>
        </Dropdown.Item>
      </Dropdown.Content>
    </Dropdown.Root>
  );
};
