import React from "react";
import PropTypes from "prop-types";
import { Textarea as ChakraTextarea } from "@chakra-ui/react";

const Textarea = React.forwardRef(
  (
    /**
     * @type {import("@chakra-ui/react").TextareaProps} props
     */
    { name, ...props },
    ref
  ) => {
    return (
      <ChakraTextarea
        ref={ref}
        name={name}
        bg="white"
        _hover={{ borderColor: "brand.100" }}
        _focus={{
          bg: "brand.50",
          border: "solid 2px",
          borderColor: "brand.200",
        }}
        {...props}
      />
    );
  }
);

Textarea.defaultProps = {
  name: "",
  type: "text",
};

Textarea.propTypes = {
  rules: PropTypes.objectOf(PropTypes.any),
  type: PropTypes.string,
};

Textarea.id = "Textarea";
Textarea.displayName = "Textarea";

export default Textarea;
