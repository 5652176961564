// @ts-nocheck
import { ProductField } from "#/scenes/Inventory/components/ProductField.js";
import { footerLeft, footerRight } from "../print-template.js";
import * as C from "@chakra-ui/react";
import { Field, FormControl, NumberInput, Select } from "#/components/Form/v2/index.js";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import ZebraBrowserPrintWrapper from "zebra-browser-print-wrapper";

export default function PrintLabel() {
  const formMethods = useForm();
  const { watch: watchForm, setValue: setFormValue } = formMethods;
  const [browserPrint] = React.useState(() => new ZebraBrowserPrintWrapper());
  const [availablePrinters, setAvailablePrinters] = React.useState([]);
  const [isLoadingPrinters, setIsLoadingPrinters] = React.useState(false);

  React.useEffect(() => {
    async function setDefaultPrinter() {
      setIsLoadingPrinters(true);
      const availablePrinters = await browserPrint.getAvailablePrinters();
      setFormValue("printer", availablePrinters[0]);
      setAvailablePrinters(availablePrinters);

      setIsLoadingPrinters(false);
    }

    setDefaultPrinter();
  }, [browserPrint, setFormValue]);

  React.useEffect(() => {
    const sub = watchForm(
      (formValues, { name }) =>
        name === "printer" && browserPrint.setPrinter(formValues.printer)
    );

    return () => sub.unsubscribe();
  }, [browserPrint, watchForm]);

  const handleSubmit = React.useCallback(
    (f) => {
      const template = `^XA
      ^FX Price and size
      ^CFA,15
      ^FO15,10^FD$${f.product.value.price.toFixed(2)}^FS
      ^FO218,10,1^FD${f.product.value.presentation}^FS
      
      ^FX SKU
      ^CF0,20
      ^FB233,,,C^FO0,30^FD${f.product.value.sku}\\&^FS
      
      ^FX Barcode
      ^BY2,2,50
      ^FX ---- Change BC,130,N to BC,130,Y to print the code number ----
      ^FO25,55^BC,,N^FD${f.product.value.sku}^FS
      
      ^FX Product name
      ^CFA,14
      ^FB233,,,C^FO0,115^FDMSRP XDR${f.product.value.price.toFixed(2)}\\&^FS
      ^CFL,22
      ${footerLeft(f.product.value.title, f.product.value.productLocationStore)}

      ^FX Price and size
      ^CFA,15
      ^FO248,10^FD$${f.product.value.price.toFixed(2)}^FS
      ^FO451,10,1^FD${f.product.value.presentation}^FS
      
      ^FX SKU
      ^CF0,20
      ^FB700,,,C^FO0,30^FD${f.product.value.sku}\\&^FS
      
      ^FX Barcode
      ^BY2,2,50
      ^FX ---- Change BC,130,N to BC,130,Y to print the code number ----
      ^FO258,55^BC,,N^FD${f.product.value.sku}^FS
      
      ^FX Product name
      ^CFA,14
      ^FB700,,,C^FO0,115^FDMSRP XDR${f.product.value.price.toFixed(2)}\\&^FS
      ^CFL,22
      ${footerRight(
        f.product.value.title,
        f.product.value.productLocationStore
      )}
      
      ^XZ`;

      async function print() {
        for (let i = 0; i < f.qty / 2; i++) {
          await browserPrint.print(template);
        }
      }
      print();
    },
    [browserPrint]
  );

  return (
    <C.Container maxW="container.lg">
      <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
        <FormProvider {...formMethods}>
          <C.VStack spacing={4}>
            <ProductField />
            <FormControl name="qty" label="QTY" required>
              <Field>
                {({ control, name }) => (
                  <NumberInput
                    control={control}
                    name={name}
                    defaultValue={2}
                    step={2}
                  />
                )}
              </Field>
            </FormControl>
            <FormControl name="printer" label="Printer" required>
              {({ id, ...props }) => {
                return (
                  <Select
                    options={availablePrinters}
                    getOptionLabel={(o) => o.name}
                    getOptionValue={(o) => o.uid}
                    isLoading={isLoadingPrinters}
                    inputId={id}
                    {...props}
                  />
                );
              }}
            </FormControl>
            <C.Button type="submit">Print</C.Button>
          </C.VStack>
        </FormProvider>
      </form>
    </C.Container>
  );
}
