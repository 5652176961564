import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Button,
  EllipsisHorizontal,
  SearchOutline,
  Separator,
  makeController,
} from "#/components-ng/index.js";
import { DisplayTable, RouterPrompt } from "#/components/index.js";
import { useAuth } from "#/context/AuthContext.js";
import { cn } from "#/lib/utils.js";
import {
  UpdateVariant,
  VariantOuput,
} from "#/scenes/Inventory/ItemList/Form/Variant/Update.js";
import { trpc } from "#/trpc.js";
import {
  ShippingInformationDialogContent,
  ShippingInformationFormSchema,
  shippingInformationFormSchema,
} from "./ShippingInformation.js";
import { zodResolver } from "@hookform/resolvers/zod";
import * as M from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import Decimal from "decimal.js";
import { Fragment, useState } from "react";
import { DefaultValues, FormProvider, SubmitHandler } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import { z } from "zod";
import { css } from "#/css/css";

const createPoRestockFormSchema = z.object({
  dutyCost: z.number().catch(0).default(0),
  vendorId: z.string().nullish(),
  itemSkus: z.array(
    z.object({
      id: z.number(),
      itemId: z.number(),
      defaultImage: z.string().nullish(),
      title: z.string(),
      sku: z.number(),
      presentation: z.string(),
      storeLocation: z.string().optional().nullable(),
      warehouseLocation: z.string().optional().nullable(),
      shippingQuantity: z.coerce.number(),
      cost: z.coerce.number(),
    }),
  ),
  shippingInformation: shippingInformationFormSchema,
});
export type CreatePoRestockFormSchema = z.TypeOf<
  typeof createPoRestockFormSchema
>;

const C = makeController<CreatePoRestockFormSchema>();

const SUBMIT_DRAFT_ID = "submit-draft";
const SUBMIT_CREATE_ID = "submit-create";

export type CreatePoRestockFormProps = {
  onSubmit: (
    status: "UPCOMING" | "DRAFT",
  ) => SubmitHandler<CreatePoRestockFormSchema>;
  defaultValues?: DefaultValues<CreatePoRestockFormSchema>;
};
export function CreatePoRestockForm(props: CreatePoRestockFormProps) {
  const [submitted, setSubmitted] = useState<boolean>(false);
  const form = C.useForm({
    resolver: zodResolver(createPoRestockFormSchema),
    defaultValues: {
      dutyCost: 0,
      vendorId: null,
      itemSkus: [],
      shippingInformation: {
        entries: [],
      },
      ...props.defaultValues,
    },
  });

  const handleSubmit = (e: any, summiterId: any) => {
    e.preventDefault();
    if (summiterId === SUBMIT_CREATE_ID) {
      setSubmitted(true);
      return props.onSubmit("UPCOMING");
    } else if (summiterId === SUBMIT_DRAFT_ID) {
      setSubmitted(true);
      return props.onSubmit("DRAFT");
    } else {
      throw new Error("Unknown submitter");
    }
  };

  return (
    <FormProvider {...form}>
      <form
        onSubmit={(e) => {
          // get react form submitter
          const submitterId = (e.nativeEvent as any).submitter.id;
          // call the submit handler
          handleSubmit(e, submitterId)(form.getValues());
        }}
      >
        <RouterPrompt when={!submitted} />
        <div className="grid gap-y-8">
          <Header />
          <Main />
        </div>
      </form>
    </FormProvider>
  );
}

function Header(props: { className?: string }) {
  const { poId } = useParams();
  const { data, isLoading } = trpc.purchaseOrder.getNextId.useQuery(undefined, {
    enabled: !!poId,
  });

  const { data: vendors } = trpc.vendor.getAll.useQuery();

  return (
    <div
      className={cn(
        "flex w-full items-center justify-between rounded bg-white px-5 py-3",
        props.className,
      )}
    >
      <div
        className={css({
          display: "flex",
          flex: 1,
          alignItems: "center",
          gap: "0.5rem",
        })}
      >
        <p>Purchase order: {poId ? poId : isLoading ? "" : `${data}`}</p>
        <Separator orientation="vertical" className="mx-4 h-12" />
        <C.M
          as={M.NumberInput}
          name="dutyCost"
          type="number"
          min={0}
          precision={2}
          label="Duty cost"
          placeholder="0.00"
          hideControls
          noNullish
          classNames={{
            root: "flex w-[18ch] flex-row items-center",
            label: "flex-1",
            wrapper: "flex-1",
          }}
        />
        <C.M
          as={M.Select}
          name="vendorId"
          label="Vendor"
          data={
            vendors?.map((vendor) => ({
              value: vendor.id.toString(),
              label: vendor.company ?? "",
            })) ?? []
          }
          clearable
          searchable
          classNames={{
            root: css({
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }),
            label: css({
              flex: 1,
              marginRight: "0.5rem",
            }),
            wrapper: css({
              flex: 1,
            }),
          }}
        />
      </div>
      <div>
        <ShippingInformationDialogButton />
      </div>
    </div>
  );
}

function ShippingInformationDialogButton() {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Fragment>
      <Button variant="primary" onClick={() => setIsOpen(true)}>
        See shipping information
      </Button>
      <M.Modal
        opened={isOpen}
        onClose={() => setIsOpen(false)}
        title={<M.Title order={4}>Shipping information</M.Title>}
        classNames={{
          header: "border-b border-slate-100 pb-1",
          content: "w-auto max-w-[550px]",
        }}
      >
        <ShippingInformationDialogWrapper close={() => setIsOpen(false)} />
      </M.Modal>
    </Fragment>
  );
}

function ShippingInformationDialogWrapper(props: { close: () => void }) {
  const form = C.useFormContext();
  function handleSubmit(values: ShippingInformationFormSchema) {
    form.setValue("shippingInformation", values);
    props.close();
  }
  return <ShippingInformationDialogContent onSubmit={handleSubmit} />;
}

function Main() {
  const navigate = useNavigate();
  const { totalCost } = useCostBreakdown();

  return (
    <Fragment>
      <div className="rounded bg-white px-5 py-3">
        <div className="flex justify-end">
          <ItemSkuSearchField />
        </div>
        <div className="mt-4">
          <MainTable />
        </div>
        <div className="mt-4 flex justify-end gap-x-3">
          <Button onClick={() => navigate("/inventory/upcoming-items")}>
            Cancel
          </Button>
          <Button type="submit" id={SUBMIT_DRAFT_ID}>
            Draft
          </Button>
          <Button
            type="submit"
            id={SUBMIT_CREATE_ID}
            variant="primary"
            className="px-10"
          >
            Create
          </Button>
          <div>
            <Accordion type="multiple">
              <AccordionItem value="total" className="border-none">
                <AccordionTrigger className="min-w-[25ch] rounded-md bg-green-400 px-4 py-2 text-base font-normal text-white hover:no-underline">
                  <div className="mr-2 grid flex-1 grid-cols-2">
                    <p className="text-left">Total</p>
                    <p className="text-right">${totalCost.toFixed(2)}</p>
                  </div>
                </AccordionTrigger>
                <AccordionContent className="px-4">
                  <CostBreakdownTable />
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

function ItemSkuSearchField() {
  const [{ auth }] = useAuth();
  const form = C.useFormContext();
  const itemSkusFieldArray = C.useFieldArray({
    name: "itemSkus",
    control: form.control,
    keyName: "$key",
  });
  const [query, setQuery] = useState("");
  const itemSkuSearchMutation = trpc.itemSku.search.useQuery({
    query: `\\"${query}\\"`,
  });
  const itemSkusAutocompleteData =
    itemSkuSearchMutation.data?.map((itemSku) => ({
      label: `${itemSku.sku} ${itemSku.title}`,
      value: itemSku.id.toString(),
      itemSku: itemSku,
    })) ?? [];

  function handleChange(id: string) {
    const itemSku = itemSkusAutocompleteData.find(
      (itemSku) => itemSku.value.toString() === id,
    )!.itemSku!;

    itemSkusFieldArray.append({
      id: itemSku.id,
      itemId: itemSku.itemId,
      defaultImage: itemSku.defaultImage,
      title: itemSku.title,
      sku: itemSku.sku,
      presentation: `${itemSku.presentationValue} ${itemSku.presentationType}`,
      storeLocation: `${
        itemSku?.itemSkuStock?.find(
          (is) => is.filialId === auth?.user?.filialId,
        )?.storeLocation ?? ""
      }`,
      warehouseLocation: `${
        itemSku?.itemSkuStock?.find(
          (is) => is.filialId === auth?.user?.filialId,
        )?.warehouseLocation ?? ""
      }`,
      shippingQuantity: 0,
      cost: itemSku.cost?.toNumber() ?? 0,
    });
  }
  return (
    <M.Select
      data={itemSkusAutocompleteData}
      icon={<SearchOutline />}
      placeholder="Search..."
      onChange={handleChange}
      onInput={(e) => setQuery(e.currentTarget.value)}
      filter={() => true}
      searchable
      clearable
    />
  );
}

function MainTable() {
  const form = C.useFormContext();
  const itemSkus = C.useWatch({
    control: form.control,
    name: "itemSkus",
  });
  return <DisplayTable columns={columns} data={itemSkus} />;
}

function CostBreakdownTable() {
  const { totalCost, dutyCost, shippingTotalCost } = useCostBreakdown();
  return (
    <div className="grid grid-cols-2 [&>*]:my-1">
      <p>No. items received</p>
      <p className="text-right">0</p>
      <p>Total qty. received</p>
      <p className="text-right">0</p>
      <Separator className="col-span-2" />
      <p>Shipping cost</p>
      <p className="text-right">${shippingTotalCost.toFixed(2)}</p>
      <p>Duty cost</p>
      <p className="text-right">${dutyCost.toFixed(2)}</p>
      <Separator className="col-span-2" />
      <p>Total cost</p>
      <p className="text-right">${totalCost.toFixed(2)}</p>
    </div>
  );
}

function useCostBreakdown() {
  const form = C.useFormContext();
  const shippingInformation = C.useWatch({
    control: form.control,
    name: "shippingInformation",
  });
  const itemSkus = C.useWatch({
    control: form.control,
    name: "itemSkus",
  });
  const dutyCost = C.useWatch({
    control: form.control,
    name: "dutyCost",
  });

  const shippingTotalCost = shippingInformation.entries.reduce(
    (acc, cur) => acc.add(cur.cost),
    new Decimal(0),
  );
  const itemSkusTotalCost = itemSkus.reduce(
    (acc, cur) => acc.add(cur.cost),
    new Decimal(0),
  );

  const totalCost = shippingTotalCost.add(itemSkusTotalCost).add(dutyCost);
  return {
    totalCost,
    dutyCost,
    shippingTotalCost,
    itemSkusTotalCost,
  };
}

const columns = [
  {
    Header: "Actions",
    id: "actions",
    Cell: ({ row: { original, index } }) => {
      const [{ auth }] = useAuth();
      const form = C.useFormContext();
      const [opened, actions] = useDisclosure();

      const handleUpdate = (values: VariantOuput) => {
        actions.close();
        const itemSku = values;
        const currentItemSku = form.getValues().itemSkus[index];
        form.setValue(`itemSkus.${index}`, {
          id: itemSku.id,
          itemId: itemSku.itemId,
          defaultImage: itemSku.defaultImage,
          title: itemSku.title,
          sku: itemSku.sku,
          presentation: `${itemSku.presentationValue} ${itemSku.presentationType}`,
          storeLocation: `${
            itemSku.itemSkuStock.find(
              (is) => is.filialId === auth?.user?.filialId,
            )?.storeLocation ?? ""
          }`,
          warehouseLocation: `${
            itemSku.itemSkuStock.find(
              (is) => is.filialId === auth?.user?.filialId,
            )?.warehouseLocation ?? ""
          }`,
          shippingQuantity: currentItemSku.shippingQuantity,
          cost: itemSku.cost?.toNumber() ?? 0,
        });
      };

      return (
        <>
          <M.Drawer
            position="right"
            opened={opened}
            title={<M.Title order={3}>Update item</M.Title>}
            size="auto"
            zIndex={40}
            classNames={{
              content: "w-[800px]",
            }}
            onClose={actions.close}
            closeOnClickOutside={false}
            closeOnEscape={false}
          >
            <UpdateVariant
              itemSkuId={Number(original.id)}
              redirectOnSuccess={false}
              onSuccess={handleUpdate}
            />
          </M.Drawer>
          <M.Menu>
            <M.Menu.Target>
              <M.ActionIcon>
                <EllipsisHorizontal />
              </M.ActionIcon>
            </M.Menu.Target>
            <M.Menu.Dropdown>
              <M.Menu.Item onClick={actions.open}>Edit</M.Menu.Item>
            </M.Menu.Dropdown>
          </M.Menu>
        </>
      );
    },
  },
  {
    Header: "Image",
    accessor: "defaultImage",
  },
  {
    Header: "Sku",
    accessor: "sku",
  },
  {
    Header: "Title",
    accessor: "title",
  },
  {
    Header: "PKG",
    accessor: "presentation",
  },
  {
    Header: "Store Location",
    accessor: "storeLocation",
  },
  {
    Header: "Warehouse Location",
    accessor: "warehouseLocation",
  },
  {
    Header: "Shipping qty",
    accessor: "shippingQuantity",
    Cell: ({ row: { index } }) => {
      const name = `itemSkus.${index}.shippingQuantity` as const;
      return <C.M as={M.NumberInput} name={name} min={0} />;
    },
  },
  {
    Header: "Cost",
    accessor: "cost",
    isNumeric: true,
    Cell: "money",
  },
  {
    Header: "",
    id: "delete",
    Cell: ({ row: { index } }) => {
      const form = C.useFormContext();

      return (
        <M.CloseButton
          onClick={() => {
            form.setValue(
              "itemSkus",
              form.getValues("itemSkus").filter((_, i) => i !== index),
            );
          }}
        />
      );
    },
  },
];
