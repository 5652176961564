import { Tabs, TabsContent, TabsList, TabsTrigger } from "#/components-ng";
import { ProductGallery } from "./ProductGallery";
import { ProductFormProps, ProductInfo } from "./ProductInfo";

export const ItemSection = (props: ProductFormProps) => {
  return (
    <div className="bg-white p-10 rounded-md border-[#E0E0E0] border mb-10">
      <Tabs defaultValue={TabValues.productInfo}>
        <TabsList>
          <TabsTrigger value={TabValues.productInfo}>Information</TabsTrigger>
          <TabsTrigger
            value={TabValues.productGallery}
            disabled={!props.hasVariants}
          >
            Product gallery
          </TabsTrigger>
        </TabsList>
        <TabsContent
          value={TabValues.productInfo}
          className="mt-6 border-none p-0"
        >
          <ProductInfo {...props} />
        </TabsContent>
        <TabsContent
          value={TabValues.productGallery}
          className="mt-6 border-none p-0"
        >
          <ProductGallery />
        </TabsContent>
      </Tabs>
    </div>
  );
};

const TabValues = {
  productInfo: "productInfo",
  productGallery: "gallery",
};
type TabValues = (typeof TabValues)[keyof typeof TabValues];
