import dayjs from "dayjs";
import { atom } from "jotai";

type ReportBaseData = {
  type: "filial" | "entity";
  filialId?: number | null;
  entityId?: number | null;
  fromDate: Date | null;
  toDate: Date | null;
  send: boolean;
};

export const reportBaseDataAtom = atom<ReportBaseData>({
  type: "entity",
  filialId: null,
  entityId: null,
  fromDate: dayjs().startOf("month").toDate(),
  toDate: dayjs().endOf("month").toDate(),
  send: false,
});
