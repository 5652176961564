import DisplayTable from "#/components/Table/DisplayTable/index.jsx";
import { useAuth } from "#/context/AuthContext.js";
import { trpc, RouterOutputs } from "#/trpc.js";
import { reportUserError, reportUserSuccess } from "#/util/index.js";
import * as M from "@mantine/core";
import { useQueryClient } from "@tanstack/react-query";
import { getQueryKey } from "@trpc/react-query";
import { atom, useAtomValue, useSetAtom } from "jotai";
import React from "react";
import { useParams } from "react-router";
import DownIcon from "~icons/ion/chevron-down-outline";
import UpIcon from "~icons/ion/chevron-up-outline";
import MenuIcon from "~icons/ion/ellipsis-horizontal-outline";
import DeleteIcon from "~icons/ion/trash-outline";

const discountsAtom = atom<
  RouterOutputs["discount"]["getDiscountsGroupedByReference"]
>([]);

export const Discounts = ({ id: providedId }: { id?: number }) => {
  const setDiscounts = useSetAtom(discountsAtom);
  const params = useParams();
  const id = providedId ?? params.id!;
  const [{ auth }] = useAuth()!;
  const { data: discountsData } =
    trpc.discount.getDiscountsGroupedByReference.useQuery(undefined, {
      onError(error) {
        reportUserError({
          title: "Failed to get discounts",
          message: error.message,
        });
      },
      onSuccess(data) {
        setDiscounts(data);
      },
      staleTime: 0,
    });

  const selectDiscountsData = React.useMemo(
    () =>
      discountsData?.map((e) => ({
        label: e.reference,
        value: e.reference,
        discounts: e.discounts,
      })) ?? [],
    [discountsData]
  );

  const { data: userDiscountsData, refetch } =
    trpc.userDiscount.getManyByUserId.useQuery(
      {
        userId: Number(id),
      },
      {
        onError(error) {
          reportUserError({
            title: "Failed to get user discounts",
            message: error.message,
          });
        },
      }
    );

  const { mutate: createUserDiscount } = trpc.userDiscount.create.useMutation({
    onSuccess() {
      reportUserSuccess({
        title: "Discount added successfully",
      });
      refetch();
    },
    onError(error) {
      reportUserError({
        title: "Failed to add discount",
        message: error.message,
      });
    },
  });

  const tableData = React.useMemo(
    () =>
      userDiscountsData?.map((e) => ({
        ...e,
        updatedByFullName: `${e.updatedBy?.firstName} ${
          e.updatedBy?.lastName ?? ""
        }`,
      })) ?? [],
    [userDiscountsData]
  );

  return (
    <M.Stack>
      <M.Title order={3}>Discounts</M.Title>
      <M.Select
        data={selectDiscountsData}
        placeholder="Search discount..."
        searchable
        clearable
        zIndex={1500}
        w={400}
        sx={(t) => ({
          border: `solid 1px ${t.colors.cyan[3]}`,
          borderRadius: 10,
          overflow: "hidden",
        })}
        styles={(t) => ({
          input: {
            background: `${t.colors.cyan[0]}77`,
          },
        })}
        onChange={(v) => {
          const discount = selectDiscountsData.find((n) => n.value === v)!
            .discounts[0];
          createUserDiscount({
            user: Number(id),
            discount: discount.id,
            updatedBy: auth?.user.id as number,
          });
        }}
      />
      <DisplayTable columns={columns} data={tableData} />{" "}
    </M.Stack>
  );
};

const columns = [
  {
    Header: "Actions",
    id: "actions",
    Cell: ({ row: { original } }) => {
      const queryClient = useQueryClient();
      const getAllQueryKey = getQueryKey(
        trpc.userDiscount.getManyByUserId,
        undefined,
        "query"
      );

      const { mutate: deleteUserDiscount } =
        trpc.userDiscount.delete.useMutation({
          async onSuccess() {
            reportUserSuccess({
              title: "Discount removed successfully",
            });
            await queryClient.invalidateQueries({
              queryKey: getAllQueryKey,
            });
          },
          onError(error) {
            reportUserError({
              title: "Failed to remove discount",
              message: error.message,
            });
          },
        });

      return (
        <M.Menu>
          <M.Menu.Target>
            <M.ActionIcon>
              <MenuIcon />
            </M.ActionIcon>
          </M.Menu.Target>
          <M.Menu.Dropdown>
            <M.Menu.Item
              icon={<DeleteIcon />}
              onClick={() =>
                deleteUserDiscount({
                  id: Number(original.id),
                })
              }
            >
              Delete
            </M.Menu.Item>
          </M.Menu.Dropdown>
        </M.Menu>
      );
    },
  },
  {
    Header: "Ref",
    accessor: "discount.reference",
    Cell: ({ value, row: { original } }) => {
      const [opened, setOpened] = React.useState(false);

      return (
        <M.Stack>
          <M.Group noWrap>
            <M.Text>{value}</M.Text>
            <M.ActionIcon onClick={() => setOpened((prev) => !prev)}>
              {opened ? <UpIcon /> : <DownIcon />}
            </M.ActionIcon>
          </M.Group>
          <M.Text
            size="sm"
            color="gray.7"
            maw={400}
            sx={{ display: opened ? "block" : "none" }}
          >
            {original.discount.departmentDiscounts
              .map((d: any) => d.department.name)
              .join(", ")}
          </M.Text>
        </M.Stack>
      );
    },
  },
  {
    Header: "Type",
    accessor: "discount.type",
    Cell: ({ value }) => {
      return value === "PERCENTAGE" ? "%" : value === "AMOUNT" ? "$" : value;
    },
  },
  {
    Header: "Amount",
    accessor: "discount.amount",
    isNumeric: true,
    Cell: ({ row: { original } }) => {
      // We store the id of the current discount so that when we change the discount,
      // we can show immediately even if the backend has not responded yet!
      const [localId, setLocalId] = React.useState(
        original.discount.id.toString()
      );
      React.useEffect(() => {
        // Sync with the data
        setLocalId(original.discount.id.toString());
      }, [original.discount.id]);

      const [{ auth }] = useAuth()!;

      const { mutate: updateUserDiscount } =
        trpc.userDiscount.update.useMutation({
          onSuccess() {
            reportUserSuccess({
              title: "Discount updated successfully",
            });
          },
          onError(error) {
            reportUserError({
              title: "Failed to update discount",
              message: error.message,
            });
          },
        });

      const discounts = useAtomValue(discountsAtom);

      const selectData = React.useMemo(
        () =>
          discounts
            .find((d) => d.reference === original.discount.reference)
            ?.discounts?.map((d) => ({
              label: d.type === "PERCENTAGE" ? `${d.amount}%` : `$${d.amount}`,
              value: d.id.toString(),
              discount: d,
            })) ?? [],
        [discounts, original.discount.reference]
      );

      return (
        <M.Select
          data={selectData}
          value={localId}
          onChange={(newId) => {
            // Optimistically update the local discount id
            setLocalId(newId);

            const newDiscount = selectData.find(
              (sd) => sd.value === newId
            )!.discount;

            updateUserDiscount({
              id: original.id,
              input: {
                discount: {
                  connect: {
                    id: Number(newDiscount.id),
                  },
                },
                user: {
                  connect: {
                    id: Number(original.user.id),
                  },
                },
                updatedBy: {
                  connect: {
                    id: auth?.user.id as number,
                  },
                },
              },
            });
          }}
        />
      );
    },
  },
  {
    Header: "Updated by",
    accessor: "updatedByFullName",
  },
];
