import Card from "./components/card";
import * as M from "@mantine/core";

const WaitingListChooseScene = () => {
  return (
    <M.Stack spacing={4}>
      <M.Title bg={"white"} fw={600} px={40} py={24} w={"100%"} fz="1.6rem">
        Waiting List
      </M.Title>
      <M.Stack spacing={52} bg={"#FFFFFF"} p={40}>
        <M.Text>Please select an option to continue</M.Text>
        <M.Flex gap={48}>
          <Card bg="bg-blue-200/20" title="Products" link="products" />
          <Card bg="bg-red-200/20" title="GT Academy" link="academy" />
        </M.Flex>
      </M.Stack>
    </M.Stack>
  );
};

export default WaitingListChooseScene;
