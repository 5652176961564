import * as M from "@mantine/core";
import { useUncontrolled } from "@mantine/hooks";
import { uniqBy } from "lodash";

export interface SelectProps<Value>
  extends Omit<M.SelectProps, "data" | "onChange" | "defaultValue" | "value"> {
  data: Array<NonNullable<Value>>;
  onChange?: (value: Value) => void;
  defaultValue?: Value;
  value?: Value;
  entryId: (value: NonNullable<Value>) => string;
  entryLabel: (value: NonNullable<Value>) => string;
}

export function Select<Value>({
  data,
  defaultValue,
  onChange: callerOnChange,
  value: callerValue,
  entryId,
  entryLabel,
  ...props
}: SelectProps<Value>) {
  const [value, onChange] = useUncontrolled({
    defaultValue: defaultValue,
    onChange: callerOnChange,
    finalValue: null,
    value: callerValue,
  });

  const processedValue = useMemo(() => {
    return value ? entryId(value) : null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const processedData = useMemo(() => {
    let transformedData = data.map((e) => ({
      label: entryLabel(e),
      value: entryId(e),
      original: e,
    }));
    

    if (value != null) {
      transformedData = transformedData.concat({
        label: entryLabel(value),
        value: entryId(value),
        original: value,
      });
    }

    transformedData = uniqBy(transformedData, (e) => e.value);
    

    return transformedData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, value]);

  const handleChange = (newSelectedId: string | null) => {
    if (newSelectedId == null) {
      onChange(null);
    } else {
      const newSelectedValue =
        data.find((e) => entryId(e) === newSelectedId) ?? null;
      onChange(newSelectedValue);
    }
  };

  return (
    <M.Select
      data={processedData}
      value={processedValue}
      onChange={handleChange}
      {...props}
    />
  );
}
