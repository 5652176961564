import { ErrorTooltip } from "./error-tooltip.js";
import { InputWrapper } from "./input-wrapper.js";

export type InputStateWrapperProps = {
  className?: string;
  children?: React.ReactNode;
  error?: string;
};

export function InputStateWrapper(props: InputStateWrapperProps) {
  return (
    <InputWrapper className={props.className}>
      {props.children}
      {props.error ? <ErrorTooltip error={props.error} /> : null}
    </InputWrapper>
  );
}
