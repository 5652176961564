import { trpc } from "#/trpc.js";
import React from "react";

export const useProductSearch = () => {
  const [query, setQuery] = React.useState<null | string>(null);
  const { data } = trpc.itemSku.search.useQuery(
    {
      // we only execute the query when `query` is not null due to the `enabled` option
      query: `\\"${query}\\"`!,
    },
    {
      enabled: !!query,
    }
  );
  const options = React.useMemo(() => {
    const options =
      data?.map((itemSku) => ({
        label: `${itemSku.sku} | ${itemSku.title}`,
        value: itemSku.id.toString(),
        itemSku,
      })) ?? [];
    return options;
  }, [data]);

  const searchEasy = React.useCallback(async (query: string) => {
    setQuery(query);
  }, []);

  const returnData = React.useMemo(
    () =>
      [searchEasy, { options }] as [
        typeof searchEasy,
        { options: typeof options }
      ],
    [options, searchEasy]
  );

  return returnData;
};
