import { EyeIcon } from "#/components";
import { css } from "#/css/css";
import { RouterOutputs, trpc } from "#/trpc";
import { Button } from "@gt/ui";
import { useDisclosure } from "@mantine/hooks";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_SortingState,
  useMantineReactTable,
} from "mantine-react-table";
import placeholderImage from "#/placeholder-image.jpg";
import { FilterKey } from "./$common";
import { UsersWaitingListModal } from "./user-waiting-list-modal";
import { reportUserSuccess } from "#/util";
import { IconButton, Tooltip } from "@radix-ui/themes";
import { MdOutlineDownload } from "react-icons/md";

export interface WaitingListProductsTableProps {
  filter: Array<FilterKey>;
  search?: string | null;
  dateRange: [Date | null, Date | null];
}

export const WaitingListProductsTable = (
  props: WaitingListProductsTableProps,
) => {
  // Pagination
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 25,
  });
  // Sorting
  const [sorting, setSorting] = useState<MRT_SortingState>([]);

  const querySorting = useMemo(() => {
    if (sorting[0]) {
      return {
        desc: sorting[0].desc,
        key: sorting[0].id as any,
      };
    }
    return null;
  }, [sorting]);

  const { data, isLoading } = trpc.v2_5.waitingProducts.getByPage.useQuery(
    {
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize,
      sorting: querySorting,
      filters: {
        dateRange:
          props.dateRange[0] != null && props.dateRange[1] != null
            ? {
                start: props.dateRange[0],
                end: props.dateRange[1],
              }
            : null,
        status: props.filter,
      },
      search: props.search,
    },
    {
      keepPreviousData: true,
    },
  );

  const { mutate: exportCsv, isLoading: isLoadingExport } =
    trpc.v2_5.waitingProducts.exportTableToCsv.useMutation({
      onSuccess: () => {
        reportUserSuccess({
          title: "The csv file has been sent to email",
        });
      },
    });

  const handleExportCsv = () => {
    exportCsv({
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize,
      sorting: querySorting,
      filters: {
        dateRange:
          props.dateRange[0] != null && props.dateRange[1] != null
            ? {
                start: props.dateRange[0],
                end: props.dateRange[1],
              }
            : null,
        status: props.filter,
      },
      search: props.search,
    });
  };

  const table = useMantineReactTable({
    data: data?.entries ?? [],
    columns: columns({
      status: props.filter,
    }),
    manualPagination: true,
    enableTopToolbar: false,
    rowCount: data?.totalEntries ?? 0,
    enableFilters: false,
    state: {
      pagination,
      sorting,
      isLoading,
    },
    enableStickyHeader: true,
    manualSorting: true,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    renderBottomToolbarCustomActions: () => (
      <div
        className={css({
          display: "flex",
          alignItems: "center",
          gap: 2,
        })}
      >
        <Tooltip content="Export to CSV">
          <IconButton
            color="gray"
            variant="soft"
            loading={isLoadingExport}
            onClick={handleExportCsv}
          >
            <MdOutlineDownload />
          </IconButton>
        </Tooltip>
      </div>
    ),
  });

  return <MantineReactTable table={table} />;
};

type UserWaitingList =
  RouterOutputs["v2_5"]["waitingProducts"]["getByPage"]["entries"][number];

type Columns = {
  status: Array<FilterKey>;
};

const columns = (filter: Columns): MRT_ColumnDef<UserWaitingList>[] => [
  {
    id: "actions",
    size: 100,
    enableSorting: false,
    header: "Actions",
    Cell: (table) => {
      const [opened, { open, close }] = useDisclosure(false);

      return (
        <>
          <Button variant="ghost" size="icon" onClick={open}>
            <EyeIcon />
          </Button>
          {opened && (
            <>
              <UsersWaitingListModal
                opened={opened}
                close={close}
                itemSkuId={table.row.original.itemSkuId}
                status={filter.status}
              />
            </>
          )}
        </>
      );
    },
  },
  {
    id: "defaultImage",
    header: "Image",
    enableSorting: false,
    size: 100,
    Cell: (table) => {
      const original = table.row.original;
      return (
        <img
          src={original.defaultImage ?? placeholderImage}
          className={css({
            objectFit: "cover",
            width: 20,
            height: 20,
          })}
          onError={(e) => {
            (e.target as HTMLImageElement).src = placeholderImage;
          }}
        />
      );
    },
  },
  {
    id: "sku",
    header: "SKU",
    accessorKey: "sku",
    size: 100,
  },
  {
    id: "title",
    header: "Product name",
    accessorKey: "title",
  },
  {
    id: "price",
    header: "Price",
    Cell: (table) => {
      return `$${table.row.original.price.toFixed(2)}`;
    },
  },
  {
    id: "expectedQty",
    header: "Expected QTY",
    accessorKey: "expectedQty",
    enableSorting: false,
  },
];
