import { createTRPCReact, inferReactQueryProcedureOptions } from "@trpc/react-query";
import { inferRouterInputs, inferRouterOutputs } from "@trpc/server";
import Decimal from "decimal.js";
import { Err, Ok, err, ok } from "neverthrow";
import type { AppRouter } from "server/src/app/router.js";
import superjson from "superjson";

superjson.registerCustom<Decimal, string>(
  {
    isApplicable: (v): v is Decimal => Decimal.isDecimal(v),
    serialize: (v) => v.toJSON(),
    deserialize: (v) => new Decimal(v),
  },
  "decimal.js"
);

superjson.registerCustom<Ok<any, any>, string>(
  {
    isApplicable: (v): v is Ok<any, any> => v instanceof Ok,
    serialize: (v) => superjson.stringify(v.value),
    deserialize: (v) => ok(superjson.parse(v)),
  },
  "neverthrow_ok"
);

superjson.registerCustom<Err<any, any>, string>(
  {
    isApplicable: (v): v is Err<any, any> => v instanceof Err,
    serialize: (v) => superjson.stringify(v.error),
    deserialize: (v) => err(superjson.parse(v)),
  },
  "neverthrow_err"
);

export const trpc = createTRPCReact<AppRouter>();
export type ReactQueryOptions = inferReactQueryProcedureOptions<AppRouter>;
export type RouterOutputs = inferRouterOutputs<AppRouter>;
export type RouterInputs = inferRouterInputs<AppRouter>;
type TrpcClient = ReturnType<typeof trpc.useContext>["client"];
export let gTrpc: TrpcClient = null as any;
export function setGTrpc(client: TrpcClient) {
  gTrpc = client;
}
