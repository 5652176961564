import { MantineController as Mc } from "#/components/Form/v3/index.js";
import { diffCostAtom, diffQtyAtom, newQtyAtom, oldQtyAtom } from "../state.js";
import * as M from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import { useAtomValue } from "jotai";
import { useNavigate } from "react-router";

export const Footer = ({ onSave, onHold, loading }) => {
  const oldQty = useAtomValue(oldQtyAtom);
  const newQty = useAtomValue(newQtyAtom);
  const diffQty = useAtomValue(diffQtyAtom);
  const diffCost = useAtomValue(diffCostAtom);

  return (
    <>
      <M.Group position="apart" mt="lg" className="items-center">
        <Mc
          as={M.Textarea}
          name="note"
          label="Note"
          className="w-1/3"
          placeholder="Enter note"
        />
        <M.Stack>
          <M.Group position="right">
            <M.SimpleGrid cols={2}>
              <M.Box className="inline-flex items-center justify-center gap-8 rounded-md bg-[#FAFAFA] p-5 text-sm font-medium text-[#3D3D3D]">
                <M.Text inherit>Old quantity</M.Text>
                <M.Text
                  inherit
                  className="rounded-md bg-white p-0 pl-10 pr-1 text-center"
                >
                  {oldQty}
                </M.Text>
              </M.Box>
              <M.Box className="inline-flex items-center justify-center gap-8 rounded-md bg-[#FAFAFA] p-5 text-sm font-medium text-[#3D3D3D]">
                <M.Text inherit>Diff. quantity</M.Text>
                <M.Text
                  inherit
                  className="rounded-md bg-white p-0 pl-10 pr-1 text-center"
                >
                  {diffQty}
                </M.Text>
              </M.Box>
              <M.Box className="inline-flex items-center justify-center gap-8 rounded-md bg-[#FAFAFA] p-5 text-sm font-medium text-[#3D3D3D]">
                <M.Text inherit>New quantity</M.Text>
                <M.Text
                  inherit
                  className="rounded-md bg-white p-0 pl-10 pr-1 text-center"
                >
                  {newQty}
                </M.Text>
              </M.Box>
              <M.Box className="inline-flex items-center justify-center gap-8 rounded-md bg-[#FAFAFA] p-5 text-sm font-medium text-[#3D3D3D]">
                <M.Text inherit>Diff. cost</M.Text>
                <M.Text
                  inherit
                  className="rounded-md bg-white p-0 pl-10 pr-1 text-center"
                >
                  {diffCost.toFixed(2)}
                </M.Text>
              </M.Box>
            </M.SimpleGrid>
          </M.Group>
        </M.Stack>
      </M.Group>
      <M.Group position="right">
        <M.Button
          color="darkGray"
          variant="light"
          onClick={onSave}
          loading={loading}
        >
          Save and print
        </M.Button>
        <M.Button onClick={onHold} loading={loading}>
          On hold
        </M.Button>
        <CloseButton />
      </M.Group>
    </>
  );
};

const CloseButton = () => {
  const navigate = useNavigate();

  const openConfirmCloseModal = () =>
    openConfirmModal({
      title: "Are you sure you want to leave?",
      children: <M.Text>Nothing will be saved.</M.Text>,
      labels: { confirm: "Leave", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onConfirm: () => navigate("/inventory/adjust"),
    });

  return (
    <M.Button color="darkGray" variant="light" onClick={openConfirmCloseModal}>
      Close
    </M.Button>
  );
};

const colorForNumber = (theme: M.MantineTheme, n: number): string => {
  if (n === 0) {
    return theme.colors.gray[5];
  } else if (n < 0) {
    return theme.colors.red[7];
  } else {
    return theme.colors.green[7];
  }
};
