import { MultiSelect } from "#/components-ng";
import { ImageUpload, UseS3UploadResult } from "#/components-ng/ui";
import { RouterPrompt } from "#/components/index.js";
import placeholderImage from "#/placeholder-image.jpg";
import { trpc } from "#/trpc";
import { C, FormValues, createBlogSchema } from "./types";
import { zodResolver } from "@hookform/resolvers/zod";
import * as M from "@mantine/core";
import { RichTextEditor, Link } from "@mantine/tiptap";
import TextAlign from "@tiptap/extension-text-align";
import Underline from "@tiptap/extension-underline";
import { useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import {
  FormProvider,
  useFormContext,
  useWatch,
  SubmitHandler,
  DefaultValues,
  UseFormReturn,
} from "react-hook-form";

export interface BlogFormProps {
  defaultValues?: DefaultValues<FormValues>;
  onSubmit: SubmitHandler<FormValues>;
  isLoading: boolean;
  titleSubmit: string;
  title: string;
}

export default function BlogForm({
  defaultValues,
  onSubmit,
  isLoading,
  titleSubmit,
  title,
}: BlogFormProps) {
  const [submitted, setSubmitted] = useState(false);

  const [translateState, setTranslateState] = useState<"ENGLISH" | "SPANISH">(
    "ENGLISH"
  );

  const form = C.useForm({
    resolver: zodResolver(createBlogSchema),
    shouldUnregister: false,
    defaultValues: {
      title: "",
      titleEsp: "",
      image: "",
      text: "",
      textEsp: "",
      blogExternalUrl: "",
      seasons: [],
      tags: [],
      catalogs: [],
      ...defaultValues,
    },
  });

  return (
    <form
      onSubmit={(e) => (form.handleSubmit(onSubmit)(e), setSubmitted(true))}
    >
      <RouterPrompt when={!submitted} />
      <M.Stack spacing={4} align="start">
        <M.Title bg={"white"} fw={600} px={40} py={24} w={"100%"} fz="1.6rem">
          {title}
        </M.Title>
        <FormProvider {...form}>
          <M.Box w="100%" bg={"white"} px={40} pt={43}>
            <M.Stack spacing={40} w="40%" fz={"14px"}>
              <M.Stack fz={16} fw={400} c="#3D3D3D" spacing={12}>
                <M.Text>Upload image</M.Text>
                <DefaultImageUpload />
              </M.Stack>
              <M.Tabs
                defaultValue={translateState}
                onTabChange={(e: "ENGLISH" | "SPANISH") => {
                  setTranslateState(e);
                }}
              >
                <M.Tabs.List className="mb-8">
                  <M.Tabs.Tab value="ENGLISH">ENGLISH</M.Tabs.Tab>
                  <M.Tabs.Tab value="SPANISH">SPANISH</M.Tabs.Tab>
                </M.Tabs.List>
                <M.Tabs.Panel value="ENGLISH">
                  <C.InputField
                    label="Title *"
                    name="title"
                    placeholder="Enter Title"
                    required
                  />
                </M.Tabs.Panel>
                <M.Tabs.Panel value="SPANISH">
                  <C.InputField
                    label="Title Spanish"
                    name="titleEsp"
                    placeholder="Enter Title spanish"
                  />
                </M.Tabs.Panel>
              </M.Tabs>
              {translateState === "ENGLISH" ? (
                <TextRichInput form={form} />
              ) : (
                <TextRichInputEsp form={form} />
              )}
              <C.InputField
                label="Link"
                name="blogExternalUrl"
                placeholder="Enter link video"
              />
              <SeasonMultiselect />
              <TagMultiselect />
              <CatalogMultiselect />
              <M.Button mb={38} type="submit" w="30%" loading={isLoading}>
                {titleSubmit}
              </M.Button>
            </M.Stack>
          </M.Box>
        </FormProvider>
      </M.Stack>
    </form>
  );
}

function DefaultImageUpload() {
  const form = useFormContext();
  const [isLoading, setIsLoading] = useState(false);

  function handleUpload(data: UseS3UploadResult) {
    form.setValue("image", data.url);
    setIsLoading(false);
  }

  function handleUploadStart() {
    setIsLoading(true);
  }

  const image = useWatch({
    control: form.control,
    name: "image",
  });

  return (
    <M.Stack spacing={24}>
      <div className="relative">
        <img
          src={image === "" ? placeholderImage : image}
          className="max-h-[340px] w-[340px] rounded-lg object-fill"
        />
        <M.LoadingOverlay visible={isLoading} />
      </div>
      <ImageUpload
        directory="blog"
        onS3UploadSuccess={handleUpload}
        onS3UploadingStart={handleUploadStart}
      />
      <M.Text c="dimmed" fz={12}>
        Suggested size: 680x680 px
      </M.Text>
    </M.Stack>
  );
}

function TextRichInput({ form }: { form: UseFormReturn<FormValues> }) {
  const { register, setValue } = useFormContext();
  const content = form.watch("text");

  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      TextAlign.configure({ types: ["heading", "paragraph"] }),
    ],
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      setValue("text", html);
    },
    content,
  });
  return (
    <div>
      <M.Input.Wrapper
        label="Content"
        required
        description="Enter content"
        {...register("text")}
      >
        <RichTextEditor editor={editor}>
          <RichTextEditor.Toolbar sticky stickyOffset={60}>
            <RichTextEditor.ControlsGroup>
              <RichTextEditor.Bold />
              <RichTextEditor.Italic />
              <RichTextEditor.Underline />
              <RichTextEditor.Strikethrough />
              <RichTextEditor.ClearFormatting />
              <RichTextEditor.Code />
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
              <RichTextEditor.H1 />
              <RichTextEditor.H2 />
              <RichTextEditor.H3 />
              <RichTextEditor.H4 />
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
              <RichTextEditor.Blockquote />
              <RichTextEditor.Hr />
              <RichTextEditor.BulletList />
              <RichTextEditor.OrderedList />
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
              <RichTextEditor.Link />
              <RichTextEditor.Unlink />
            </RichTextEditor.ControlsGroup>
          </RichTextEditor.Toolbar>

          <RichTextEditor.Content />
        </RichTextEditor>
      </M.Input.Wrapper>
    </div>
  );
}

function TextRichInputEsp({ form }: { form: UseFormReturn<FormValues> }) {
  const { register, setValue } = useFormContext();
  const content = form.watch("textEsp");

  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      TextAlign.configure({ types: ["heading", "paragraph"] }),
    ],
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      setValue("textEsp", html);
    },
    content,
  });
  return (
    <div>
      <M.Input.Wrapper
        label="Content (Spanish)"
        description="Enter content (Spanish)"
        {...register("textEsp")}
      >
        <RichTextEditor editor={editor}>
          <RichTextEditor.Toolbar sticky stickyOffset={60}>
            <RichTextEditor.ControlsGroup>
              <RichTextEditor.Bold />
              <RichTextEditor.Italic />
              <RichTextEditor.Underline />
              <RichTextEditor.Strikethrough />
              <RichTextEditor.ClearFormatting />
              <RichTextEditor.Code />
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
              <RichTextEditor.H1 />
              <RichTextEditor.H2 />
              <RichTextEditor.H3 />
              <RichTextEditor.H4 />
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
              <RichTextEditor.Blockquote />
              <RichTextEditor.Hr />
              <RichTextEditor.BulletList />
              <RichTextEditor.OrderedList />
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
              <RichTextEditor.Link />
              <RichTextEditor.Unlink />
            </RichTextEditor.ControlsGroup>
          </RichTextEditor.Toolbar>

          <RichTextEditor.Content />
        </RichTextEditor>
      </M.Input.Wrapper>
    </div>
  );
}

type Season = {
  id: number;
  name: string;
};

function SeasonMultiselect() {
  const form = useFormContext();
  const seasons = form.watch("seasons");
  const [selectedSeasons, setSelectedSeasons] = useState<Season[]>(
    () => seasons ?? []
  );

  const { data: allSeasons } = trpc.season.getAll.useQuery(undefined, {
    cacheTime: 0,
  });

  const fieldData = useMemo(
    () =>
      allSeasons?.map((season) => ({
        id: season.id,
        name: season.name,
      })) ?? [],
    [allSeasons]
  );

  const handleSeasonChange = (seasons: Season[]) => {
    form.setValue("seasons", seasons);
    setSelectedSeasons(seasons);
  };

  return (
    <MultiSelect
      label="Seasons"
      data={fieldData}
      entryId={(e) => e.id.toString()}
      entryLabel={(e) => e.name}
      onChange={handleSeasonChange}
      placeholder="Select seasons"
      value={selectedSeasons}
      maxSelectedValues={1}
    />
  );
}

type Tag = {
  id: number;
  name: string;
};

function TagMultiselect() {
  const form = useFormContext();
  const tags = form.watch("tags");
  const [selectedTags, setSelectedTags] = useState<Tag[]>(() => tags ?? []);

  const { data: allTags } = trpc.tag.getTags.useQuery(undefined, {
    cacheTime: 0,
  });

  const fieldData = useMemo(
    () =>
      allTags?.map((tag) => ({
        id: tag.id,
        name: tag.name,
      })) ?? [],
    [allTags]
  );

  const handleTagChange = (tags: Tag[]) => {
    form.setValue("tags", tags);
    setSelectedTags(tags);
  };

  return (
    <MultiSelect
      label="Tags"
      data={fieldData}
      entryId={(e) => e.id.toString()}
      entryLabel={(e) => e.name}
      onChange={handleTagChange}
      placeholder="Select tags"
      value={selectedTags}
    />
  );
}

type Catalog = {
  id: number;
  name: string;
};

function CatalogMultiselect() {
  const form = useFormContext();
  const catalogs = form.watch("catalogs");
  const [selectedCatalogs, setSelectedCatalogs] = useState<Catalog[]>(
    () => catalogs ?? []
  );

  const { data: allCatalogs } = trpc.catalogItem.public.all.useQuery(
    undefined,
    {
      cacheTime: 0,
    }
  );

  const fieldData = useMemo(
    () =>
      allCatalogs?.map((catalog) => ({
        id: catalog.id,
        name: catalog.title,
      })) ?? [],
    [allCatalogs]
  );

  const handleCatalogChange = (catalogs: Catalog[]) => {
    form.setValue("catalogs", catalogs);
    setSelectedCatalogs(catalogs);
  };

  return (
    <MultiSelect
      label="Catalogs"
      data={fieldData}
      entryId={(e) => e.id.toString()}
      entryLabel={(e) => e.name}
      onChange={handleCatalogChange}
      placeholder="Select catalogs"
      value={selectedCatalogs}
    />
  );
}
