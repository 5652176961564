import Text from "../Text/index.jsx";
import StyledSceneSectionLayout, { StyledTitle } from "./Styles.js";

const SceneSectionLayout = ({ title, children }) => {
  return (
    <StyledSceneSectionLayout>
      <StyledTitle>
        <Text text={title} fontSize="22px" fontWeight="bold" />
      </StyledTitle>
      <div>{children}</div>
    </StyledSceneSectionLayout>
  );
};

export default SceneSectionLayout;
