import React from "react";
import ZebraBrowserPrintWrapper from "zebra-browser-print-wrapper";

export function useZebraPrinter() {
  const [loading, setLoading] = React.useState(true);
  const [browserPrint] = React.useState(() => new ZebraBrowserPrintWrapper());

  React.useEffect(() => {
    async function setDefaultPrinter() {
      const availablePrinters = await browserPrint.getAvailablePrinters();
      browserPrint.setPrinter(availablePrinters[0]);
      setLoading(false);
    }

    setDefaultPrinter();
  }, [browserPrint]);

  return React.useMemo(
    () => ({ printer: browserPrint, loading }),
    [browserPrint, loading]
  );
}
