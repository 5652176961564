import { SearchButton } from "#/components@v2_5/search-button"
import { css } from "#/css/css"
import { Box, HStack } from "#/css/jsx"
import { RouterOutputs, trpc } from "#/trpc.js"
import { getDiscountLabel } from "#/util/discounts"
import { reportUserError, reportUserSuccess } from "#/util/index.js"
import { Button, Dropdown } from "@gt/ui"
import * as M from "@mantine/core"
import { openConfirmModal } from "@mantine/modals"
import { DropdownMenu, IconButton, Tooltip } from "@radix-ui/themes"
import dayjs from "dayjs"
import {
	MantineReactTable,
	MRT_ColumnDef,
	MRT_SortingState,
	useMantineReactTable,
} from "mantine-react-table"
import { useState } from "react"
import { MdOutlineDownload } from "react-icons/md"
import { Link } from "react-router-dom"
import CheckIcon from "~icons/ic/baseline-check"
import CloseIcon from "~icons/ic/baseline-close"
import DisableIcon from "~icons/ion/ban-outline"
import EditIcon from "~icons/ion/create-outline"
import DownloadIcon from "~icons/ion/download-outline"
import MenuIcon from "~icons/ion/ellipsis-horizontal-outline"

export type Promotion =
	RouterOutputs["v2_5"]["promotion"]["getByPagePos"]["entries"][number]

export const Promotions = () => {
	const [search, setSearch] = useState<string | null>(null)

	// pagination
	const [pagination, setPagination] = useState({
		pageIndex: 0,
		pageSize: 25,
	})

	// sorting
	const [sorting, setSorting] = useState<MRT_SortingState>([])
	const querySorting = useMemo(() => {
		if (sorting[0]) {
			return {
				desc: sorting[0].desc,
				key: sorting[0].id as any,
			}
		}
		return null
	}, [sorting])

	const { data, isLoading } = trpc.v2_5.promotion.getByPagePos.useQuery(
		{
			pageIndex: pagination.pageIndex,
			pageSize: pagination.pageSize,
			sorting: querySorting,
			search: search,
			filters: null,
		},
		{
			onError: (err) => {
				reportUserError({
					title: "Failed to load promotions",
					message: err.message,
				})
			},
		},
	)

	const { mutate: exportCsv, isLoading: isLoadingExport } =
		trpc.v2_5.promotion.exportPromotionsTableToCsv.useMutation({
			onSuccess: () => {
				reportUserSuccess({
					title: "The csv file has been sent to email",
				})
			},
		})

	const handleExportCsv = () => {
		exportCsv({
			pageIndex: pagination.pageIndex,
			pageSize: pagination.pageSize,
			sorting: querySorting,
			search: search,
			filters: null,
		})
	}

	const table = useMantineReactTable({
		data: data?.entries ?? [],
		columns,
		manualPagination: true,
		enableTopToolbar: false,
		rowCount: data?.totalEntries ?? 0,
		enableFilters: false,
		state: {
			pagination,
			sorting,
			isLoading,
		},
		enableStickyHeader: true,
		manualSorting: true,
		onSortingChange: setSorting,
		onPaginationChange: setPagination,
		renderBottomToolbarCustomActions: () => (
			<div
				className={css({
					display: "flex",
					alignItems: "center",
					gap: 2,
				})}
			>
				<Tooltip content="Export to CSV">
					<IconButton
						color="gray"
						variant="soft"
						loading={isLoadingExport}
						onClick={handleExportCsv}
					>
						<MdOutlineDownload />
					</IconButton>
				</Tooltip>
			</div>
		),
	})

	return (
		<Box>
			<HStack justify="space-between" gap={3}>
				<DrowndownButton />
				<SearchButton setQuery={setSearch} />
			</HStack>
			<Box mt="4">
				<MantineReactTable table={table} />
			</Box>
		</Box>
	)
}

const columns: MRT_ColumnDef<Promotion>[] = [
	{
		header: "Actions",
		id: "actions",
		size: 100,
		Cell: ({ row: { original } }) => {
			const ctx = trpc.useContext()

			const { mutate } = trpc.v2_5.promotion.updateStatus.useMutation({
				onError: (err) => {
					reportUserError({
						title: "Failed to change status",
						message: err.message,
					})
				},
				onSuccess: async () => {
					reportUserSuccess({
						title: "Successfully changed status",
						message: "The promotion status has been changed",
					})
					ctx.promotion.invalidate()
				},
			})

			const { mutate: download } = trpc.promotion.root.createPdf.useMutation({
				onSuccess: async () => {
					reportUserSuccess({
						title: "You pdf is being generated",
						message:
							"Please wait a few minutes, the link will be sent to your email",
					})
				},
				onError: (err) => {
					reportUserError({
						title: "Failed to generate pdf",
						message: err.message,
					})
				},
			})

			const openConfirmationModal = (status: boolean) => {
				openConfirmModal({
					title: `Are you sure you want to ${
						status ? "enable" : "disable"
					} this promotion?`,
					labels: { cancel: "Cancel", confirm: "Disable" },
					confirmProps: { color: "red" },
					onConfirm: () =>
						mutate({
							id: Number(original.id),
							status,
						}),
				})
			}

			return (
				<M.Group noWrap>
					<M.Menu withinPortal>
						<M.Menu.Target>
							<M.ActionIcon>
								<MenuIcon />
							</M.ActionIcon>
						</M.Menu.Target>
						<M.Menu.Dropdown>
							<M.Menu.Item
								component={Link}
								to={`/inventory/promotions/edit/${original.id}`}
								icon={<EditIcon />}
							>
								Edit
							</M.Menu.Item>
							<M.Menu.Item
								onClick={() => openConfirmationModal(!original.active)}
								icon={<DisableIcon />}
							>
								{original.active ? "Disable" : "Enable"}
							</M.Menu.Item>
							<M.Menu.Label>Download catalog PDF</M.Menu.Label>
							<M.Menu.Item
								onClick={() => {
									download({
										id: Number(original.id),
										language: "en",
									})
								}}
								icon={<DownloadIcon />}
							>
								English
							</M.Menu.Item>
							<M.Menu.Item
								onClick={() => {
									download({
										id: Number(original.id),
										language: "es",
									})
								}}
								icon={<DownloadIcon />}
							>
								Spanish
							</M.Menu.Item>
						</M.Menu.Dropdown>
					</M.Menu>
				</M.Group>
			)
		},
	},
	{
		id: "activeAt",
		header: "Active from",
		accessorKey: "activeAt",
		size: 100,
		Cell: (table) => {
			const activeAt = table.row.original.activeAt
			if (!activeAt) return ""
			return dayjs(activeAt).format("MM/DD/YYYY").toString()
		},
	},
	{
		id: "inactiveAt",
		header: "Active to",
		accessorKey: "inactiveAt",
		size: 100,
		Cell: (table) => {
			const inactiveAt = table.row.original.inactiveAt
			if (!inactiveAt) return ""
			return dayjs(inactiveAt).format("MM/DD/YYYY").toString()
		},
	},
	{
		id: "name",
		accessorKey: "name",
		header: "Name",
	},
	{
		id: "active",
		header: "Active",
		accessorKey: "active",
		size: 100,
		Cell: (table) => {
			const value = table.row.original.active
			return (
				<Box color={value ? "green" : "red"}>
					{value ? <CheckIcon /> : <CloseIcon />}
				</Box>
			)
		},
	},
	{
		id: "discountId",
		header: "Discount",
		accessorKey: "discount.reference",
		enableSorting: false,
		Cell: (table) => {
			const discount = table.row.original.discount
			return getDiscountLabel(discount)
		},
	},
]

const DrowndownButton = () => {
	return (
		<Dropdown.Root>
			<Dropdown.Trigger>
				<Button
					className={css({
						gap: 4,
					})}
				>
					I want to
					<DropdownMenu.TriggerIcon />
				</Button>
			</Dropdown.Trigger>
			<Dropdown.Content>
				<Dropdown.Item>
					<Link to="create">Create promotion</Link>
				</Dropdown.Item>
			</Dropdown.Content>
		</Dropdown.Root>
	)
}
