import ConfirmDialog from "#/components/ConfirmDialog/ConfirmDialog.js";
import { userDiscountsAtom } from "#/components/Form/complex/User/DiscountsNewCustomers.js";
import CreateUserForm from "#/components/Form/complex/User/User.js";
import { Form } from "#/components/Form/v3/index.js";
import { useAuth } from "#/context/AuthContext.js";
import { RouterOutputs, trpc } from "#/trpc.js";
import { reportUserError, reportUserSuccess } from "#/util/index.js";
import { customerAtom } from "./state/index.js";
import * as C from "@chakra-ui/react";
import * as M from "@mantine/core";
import dayjs from "dayjs";
import { useAtom, useSetAtom } from "jotai";
import React from "react";

type User = RouterOutputs["user"]["register"];

export function CreateCustomerModal(disclosure) {
  const [auth] = useAuth();
  const ctx = trpc.useContext();
  const setCustomer = useSetAtom(customerAtom);
  const [userDiscounts, setUserDiscounts] = useAtom(userDiscountsAtom);
  const [isAddressRequired, setIsAddressRequired] = React.useState(false);
  const [createdCustomer, setCreatedCustomer] = React.useState<User | null>(
    null
  );

  const { data: rolesCustomer } = trpc.role.getByType.useQuery(
    {
      type: "CUSTOMER",
    },
    {
      onError: (err) => {
        reportUserError({
          title: "Failed to load roles",
          message: err.message,
        });
      },
    }
  );

  const rolesData = React.useMemo(() => {
    return (
      rolesCustomer?.map((r) => ({
        label: r.name,
        value: r.id as unknown as string,
      })) ?? []
    );
  }, [rolesCustomer]);

  const { mutate } = trpc.user.signupPos.useMutation({
    onSuccess(data) {
      reportUserSuccess({
        title: "Customer created successfully",
        message: (
          <span>
            Customer{" "}
            <span className="font-semibold">{data.user.firstName}</span> created
            successfully
          </span>
        ),
      });
      setCustomer(data.data);
      setUserDiscounts([]);
      ctx.user.invalidate();
      disclosure.onClose();
    },
    onError(err) {
      reportUserError({
        title: "Failed to create customer",
        message: err.message,
      });
    },
  });

  const handleSubmit = React.useCallback(
    async (f) => {
      mutate({
        firstName: f.firstName,
        lastName: f.lastName,
        email: f.email,
        phoneNumber: f.phoneNumber,
        areaCode: f.areaCode,
        countryCode: f.countryCode,
        roleId: rolesData[0].value as unknown as number,
        birthDate:
          f.birthDate && dayjs(f.birthDate).isValid()
            ? dayjs(f.birthDate).toDate()
            : undefined,
        street: isAddressRequired ? f.address?.address : undefined,
        city: isAddressRequired ? f.address?.city : undefined,
        state: isAddressRequired ? f.address?.state : undefined,
        zip: isAddressRequired ? f.address?.zip : undefined,
        country: isAddressRequired ? f.address?.country : undefined,
        apt: isAddressRequired ? f.address?.apt : undefined,
        poBox: isAddressRequired ? f.address?.poBox : undefined,
        filialId: auth.auth?.user?.filialId ?? null,
        verified: f.verified,
        taxable: f.taxable,
        note: f.note,
        company: f.company ?? null,
        nickName: f.nickName ?? null,
        avatar: f.avatar ?? null,
        taxDocument: f.taxDocument ?? null,
        ein: f.ein ?? null,
        bio: f.bio ?? null,
        userDiscounts: userDiscounts.map((d) => ({
          discountId: d.discountId,
          updatedById: auth.auth?.user?.id ?? 1,
        })),
        preferredSalesAssociatedId: f.preferredSalesAssociatedId,
      });
    },
    [
      mutate,
      rolesData,
      isAddressRequired,
      auth.auth?.user?.filialId,
      auth.auth?.user?.id,
      userDiscounts,
    ]
  );

  const confirmDisclosure = C.useDisclosure();

  return (
    <>
      <ConfirmDialog
        title="Are you sure want to change the customer?"
        description={
          <>
            <C.Text>
              Are you sure? This might have unexpected effects in the sale:
            </C.Text>
            <C.UnorderedList>
              <C.ListItem>
                Resets the waiting list (current waiting will be saved).
              </C.ListItem>
              <C.ListItem>
                Updates the products&apos; discounts. This will change the cost
                breakdown.
              </C.ListItem>
            </C.UnorderedList>
          </>
        }
        mostDestructiveAction="Change"
        onMostDestructiveAction={() => setCustomer(createdCustomer!)}
        {...confirmDisclosure}
      />
      <M.Modal
        size={1100}
        opened={disclosure.isOpen}
        onClose={disclosure.onClose}
        withCloseButton={false}
        zIndex={1200}
      >
        <Form onSubmit={handleSubmit}>
          <CreateUserForm
            onAddressRequiredChange={setIsAddressRequired}
            isDiscountsTabDisabledNewCustomers={false}
            discountsButtons={undefined}
            customerButtons={undefined}
            isCustomer={true}
          />
          <C.HStack spacing={6} w="100%" justify="end">
            <C.Button
              colorScheme="gray"
              color="gray.500"
              onClick={disclosure.onClose}
            >
              Cancel
            </C.Button>
            <C.Button type="submit" w="min(15ch, 70%)">
              Save
            </C.Button>
          </C.HStack>
        </Form>
      </M.Modal>
    </>
  );
}
