import { ActionsMenuIcon } from "#/components-ng";
import { RouterOutputs, trpc } from "#/trpc";
import { DeleteIcon } from "@chakra-ui/icons";
import * as M from "@mantine/core";
import dayjs from "dayjs";
import {
  MantineReactTable,
  useMantineReactTable,
  MRT_ColumnDef,
  MRT_PaginationState,
  MRT_SortingState,
  MRT_FilterOption,
} from "mantine-react-table";
import { openConfirmModal } from "@mantine/modals";
import { SearchButton } from "#/components@v2_5/search-button";
import { css } from "#/css/css";
import { Flex } from "#/css/jsx";
import { reportUserSuccess } from "#/util";
import { IconButton, Tooltip } from "@radix-ui/themes";
import { MdOutlineDownload } from "react-icons/md";

export default function AcademyWaitingList() {
  // Pagination
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });

  // Sorting
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const querySorting = useMemo(() => {
    if (sorting[0]) {
      return {
        desc: sorting[0].desc,
        key: sorting[0].id as any,
      };
    }
    return null;
  }, [sorting]);

  const [globalFilter, setGlobalFilter] = useState<
    MRT_FilterOption | undefined
  >();

  const { data, isLoading } = trpc.v2_5.academy.getAll.useQuery(
    {
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize,
      sorting: querySorting,
      search: globalFilter,
    },
    {
      keepPreviousData: true,
    },
  );

  const { mutate: exportCsv, isLoading: isLoadingExport } =
    trpc.v2_5.academy.exportTable.useMutation({
      onSuccess: () => {
        reportUserSuccess({
          title: "The csv file has been sent to email",
        });
      },
    });

  const handleExportCsv = () => {
    exportCsv({
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize,
      sorting: querySorting,
      search: globalFilter,
    });
  };

  const table = useMantineReactTable({
    columns: columns(),
    data: data?.entries ?? [],
    rowCount: data?.totalEntries ?? 0,
    manualPagination: true,
    manualSorting: true,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    onGlobalFilterChange: setGlobalFilter,
    state: {
      pagination,
      sorting,
      isLoading,
    },
    enableStickyHeader: true,
    enableTopToolbar: false,
    renderBottomToolbarCustomActions: () => (
      <div
        className={css({
          display: "flex",
          alignItems: "center",
          gap: 2,
        })}
      >
        <Tooltip content="Export to CSV">
          <IconButton
            color="gray"
            variant="soft"
            loading={isLoadingExport}
            onClick={handleExportCsv}
          >
            <MdOutlineDownload />
          </IconButton>
        </Tooltip>
      </div>
    ),
  });

  return (
    <>
      <Flex justifyContent="end">
        <SearchButton setQuery={setGlobalFilter} />
      </Flex>

      <div
        className={css({
          marginTop: 5,
        })}
      >
        <MantineReactTable table={table} />
      </div>
    </>
  );
}

type AcademyUsers =
  RouterOutputs["v2_5"]["academy"]["getAll"]["entries"][number];

const columns = (): MRT_ColumnDef<AcademyUsers>[] => [
  {
    id: "id",
    enableSorting: true,
    enableColumnFilter: false,
    header: "Actions",
    size: 100,

    Cell: (props) => {
      const ctx = trpc.useContext();

      const { mutate: deleteAcademyUserById } =
        trpc.v2_5.academy.deleteById.useMutation({
          onSuccess: () => {
            ctx.v2_5.academy.invalidate();
          },
        });

      const openConfirmDeleteModal = () =>
        openConfirmModal({
          title: (
            <M.Stack spacing={2}>
              <M.Text> Are you sure you want to remove this user</M.Text>
              <M.Text>from the academy waiting list?</M.Text>
            </M.Stack>
          ),
          labels: { cancel: "Cancel", confirm: "Delete" },
          confirmProps: { color: "red" },
          onConfirm: () => deleteAcademyUserById({ id: props.row.original.id }),
        });

      return (
        <M.Box ml={16}>
          <M.Menu>
            <M.Menu.Target>
              <M.ActionIcon variant="subtle">
                <ActionsMenuIcon />
              </M.ActionIcon>
            </M.Menu.Target>
            <M.Menu.Dropdown p={2}>
              <M.Menu.Item
                icon={<DeleteIcon />}
                onClick={() => openConfirmDeleteModal()}
              >
                <M.Text>Delete</M.Text>
              </M.Menu.Item>
            </M.Menu.Dropdown>
          </M.Menu>
        </M.Box>
      );
    },
  },

  {
    enableColumnFilterModes: false,
    header: "Phone number",
    accessorKey: "phoneNumber",
    enableSorting: true,

    Cell: (props) => {
      return (
        <M.Box>
          <M.Text>
            +{props.row.original.user.areaCode}{" "}
            {props.row.original.user.phoneNumber}
          </M.Text>
        </M.Box>
      );
    },
  },

  {
    header: "Customer name",
    accessorKey: "firstName",
    enableSorting: true,
    Cell: ({ row: { original } }) => {
      return (
        <M.Box>
          <M.Text>
            {`${original.user.firstName}  ${original.user?.lastName ?? ""}`}
          </M.Text>
        </M.Box>
      );
    },
  },
  {
    header: "Email",
    accessorKey: "email",
    enableSorting: true,
    Cell: ({ row: { original } }) => {
      return (
        <M.Box>
          <M.Text>{original.user.email}</M.Text>
        </M.Box>
      );
    },
  },
  {
    header: "Created at",
    enableSorting: true,
    accessorKey: "createdAt",
    enableColumnFilter: false,
    Cell: ({ row: { original } }) => {
      return (
        <M.Box>
          <M.Text>{dayjs(original.createdAt).format("MM/DD/YYYY")}</M.Text>
        </M.Box>
      );
    },
  },
];
