import { trpc } from "#/trpc";
import { reportUserError, reportUserSuccess } from "#/util";
import { CouponsForm, FormSchema, openConflictingCouponsModal } from "./form";
import { getDiscountLabel } from "#/util/discounts";
import * as M from "@mantine/core";
import { useNavigate, useParams } from "react-router";

export const CouponsUpdateForm: React.FC = () => {
  const ctx = trpc.useContext();
  const params = useParams();
  const navigate = useNavigate();
  const referenceParam = params.reference;
  const { data, isLoading, error } = trpc.v2_5.coupons.getMany.useQuery(
    {
      filter: {
        by: "reference",
        reference: referenceParam!,
      },
    },
    {
      enabled: referenceParam != null,
    },
  );

  const { mutate } = trpc.v2_5.coupons.update.useMutation({
    onSuccess(data) {
      if (data.isOk()) {
        ctx.v2_5.coupons.invalidate();
        reportUserSuccess({
          title: "Coupon(s) updated successfully!",
        });
        navigate("/inventory/coupons");
      } else {
        openConflictingCouponsModal({
          conflictingCoupons: data.error,
        });
      }
    },
  });

  useEffect(() => {
    if (error != null) {
      reportUserError({
        title: "There was an unexpected error",
        message: error.message,
      });
      navigate("/inventory/coupons");
    }
  }, [error, navigate]);

  useEffect(() => {
    if (data != null && data.length === 0) {
      reportUserError({
        title: "Coupon(s) not found",
      });
      navigate("/inventory/coupons");
    }
  }, [data, navigate]);

  if (isLoading) {
    return (
      <div className="my-8 flex justify-center">
        <M.Loader />
      </div>
    );
  }

  const handleSubmit = (formData: FormSchema) => {
    mutate({
      reference: data![0].reference,
      type: formData.couponType,
      activeDateRange: formData.activeDateRange,
      rates: formData.rates.map((rate) => ({
        discountId: rate.discountId,
        couponId: rate.couponId!,
        couponCode: rate.couponCode,
      })),
    });
  };

  if (data != null) {
    return (
      <CouponsForm
        mode="update"
        defaultValues={{
          discountReference: data?.[0].discount?.reference ?? null,
          couponType: data?.[0].type ?? "GLOBAL",
          activeDateRange: [
            data?.[0].activeAt ?? null,
            data?.[0].inactiveAt ?? null,
          ],
          rates: data.map((entry) => ({
            couponId: entry.id,
            discountId: entry.discountId,
            label: getDiscountLabel(entry.discount),
            couponCode: entry.code,
          })),
        }}
        onSubmit={handleSubmit}
      />
    );
  }
};

function ConflictingCouponsModal() {
  return (
    <div>
      <p>These coupons are conflicting:</p>
    </div>
  );
}
