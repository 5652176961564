import GTLoader from "#/components-ng/loader.js";
import { trpc } from "#/trpc.js";
import { Box, Table } from "@mantine/core";
import { nanoid } from "nanoid";
import React from "react";
import CheckIcon from "~icons/ic/baseline-check";
import CloseIcon from "~icons/ic/baseline-close";

interface LocationProps {
  itemSkuId: number;
}

export const LocationSection = ({ itemSkuId }: LocationProps) => {
  const {
    data: allItemSkuStocks,
    isLoading: isLoadingItemSkuStocks,
    isFetchedAfterMount: isFetchedStocks,
  } = trpc.itemSkuStock.getByFilialAndItemSku.useQuery(
    {
      itemSkuId: itemSkuId,
    },
    {
      cacheTime: 0,
      enabled: !!itemSkuId,
    }
  );
  const summary = React.useMemo(() => {
    if (!allItemSkuStocks) return [];

    return allItemSkuStocks.map((stock) => {
      const onHoldFilial = stock.holdStock;

      return {
        filial: stock.filial.name,
        total: stock.quantity,
        onHold: onHoldFilial,
        storeLocation: stock?.storeLocation ?? "",
        warehouseLocation: stock?.warehouseLocation ?? "",
        reorder: stock?.reorder ?? "",
        isLine: stock?.isLine,
      };
    });
  }, [allItemSkuStocks]);

  return (
    <div className="overflow-x-auto">
      {isLoadingItemSkuStocks || !isFetchedStocks ? (
        <GTLoader width={100} height={100} />
      ) : (
        <Table align="center" verticalSpacing="lg" className="mt-3">
          <thead>
            <tr>
              <th>Filial</th>
              <th>Total stock</th>
              <th>Hold Stock</th>
              <th>Store location</th>
              <th>Warehouse location</th>
              <th>Reorder</th>
              <th>Is line</th>
            </tr>
          </thead>
          <tbody>
            {summary.map((row, index) => (
              <tr key={`row-${index}-${nanoid()}`}>
                <td key={`cell-${row.filial}-${nanoid()}`}>{row.filial}</td>
                <td key={`cell-${row.total}-${nanoid()}`}>{row.total}</td>
                <td key={`cell-${row.onHold}-${nanoid()}`}>{row.onHold}</td>
                <td key={`cell-${row.storeLocation}-${nanoid()}`}>
                  {row.storeLocation}
                </td>
                <td key={`cell-${row.warehouseLocation}-${nanoid()}`}>
                  {row.warehouseLocation}
                </td>
                <td key={`cell-${row.reorder}-${nanoid()}`}>{row.reorder}</td>
                <td key={`cell-${row.isLine}-${nanoid()}`}>
                  <Box c={row.isLine ? "lime.7" : "red.4"}>
                    {row.isLine ? <CheckIcon /> : <CloseIcon />}
                  </Box>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
};
