import { TextComponent, TextContainer } from "./Styles.js";
import React from "react";

const Text = ({
  text = "I love croissants",
  fontSize = "14px",
  align = "auto",
  transform = "none",
  color = "black",
  fontWeight = "500",
  styles = {},
}) => {
  return (
    <TextContainer style={styles}>
      <TextComponent
        fontSize={fontSize}
        color={color}
        align={align}
        fontWeight={fontWeight}
        textTransform={transform}
      >
        {text}
      </TextComponent>
    </TextContainer>
  );
};

export default Text;
