import {
  Button,
  Combobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from "#/components-ng/index.js";
import { cn } from "#/lib/utils.js";
import { RouterOutputs, trpc } from "#/trpc.js";
import { C, createItemSchema, FormValues } from "./types.js";
import { zodResolver } from "@hookform/resolvers/zod";
import React from "react";
import {
  DefaultValues,
  FormProvider,
  SubmitHandler,
  useController,
} from "react-hook-form";

export type ProductFormProps = {
  itemId?: number | null;
  onSubmit: SubmitHandler<FormValues>;
  defaultValues?: DefaultValues<FormValues>;
  showTitle?: boolean;
  hasVariants?: boolean;
};

export const ProductInfo = (props: ProductFormProps) => {
  const form = C.useForm({
    resolver: zodResolver(createItemSchema),
    shouldUnregister: false,
    defaultValues: {
      title: "",
      description: "",
      active: true,
      department: {},
      category: {},
      season: {},
      ...props.defaultValues,
    },
  });

  return (
    <FormProvider {...form}>
      <form
        onSubmit={(e) => {
          e.stopPropagation();
          form.handleSubmit(props.onSubmit)(e);
        }}
      >
        <div className="grid grid-cols-2 gap-8">
          <C.InputField label="Title *" name="title" required />
          <C.TextareaField
            label="Description"
            name="description"
            classNames={{
              input: "h-10 min-h-[2.5rem] border-slate-200 bg-white",
            }}
          />
          <CategoryField />
          <DepartmentField />
          <SeasonField />
          <C.InputField name="material" label="Material" />
        </div>
        <Button variant="primary" type="submit" className="mt-8 px-12">
          Save product
        </Button>
      </form>
    </FormProvider>
  );
};

type Category = RouterOutputs["category"]["search"][number];
function CategoryField() {
  const [query, setQuery] = React.useState("");
  const { data } = trpc.category.search.useQuery({ query });
  const controller = useController({
    name: "category",
  });

  return (
    <C.Field name="category" label="Category *">
      {({ id }) => (
        <Combobox
          value={controller.field.value}
          onChange={controller.field.onChange}
          className="w-full"
        >
          <ComboboxInput
            displayValue={(v?: Category) => v?.name ?? ""}
            onChange={(e) => setQuery(e.currentTarget.value)}
            id={id}
            required
            className={cn(
              controller.field.value &&
                !controller.field.value?.active &&
                "text-red-500"
            )}
          />
          <ComboboxOptions>
            {data?.map((category) => (
              <ComboboxOption
                key={category.id}
                value={category}
                className={cn(!category.active && "text-red-500")}
              >
                {category.name}
              </ComboboxOption>
            ))}
          </ComboboxOptions>
        </Combobox>
      )}
    </C.Field>
  );
}

type Department = RouterOutputs["department"]["search"][number];
function DepartmentField() {
  const [query, setQuery] = React.useState("");
  const { data } = trpc.department.search.useQuery({ query });
  const controller = useController({
    name: "department",
  });

  return (
    <C.Field name="department" label="Department *">
      {({ id }) => (
        <Combobox
          value={controller.field.value}
          onChange={controller.field.onChange}
          className="w-full"
        >
          <ComboboxInput
            displayValue={(v?: Department) => v?.name ?? ""}
            onChange={(e) => setQuery(e.currentTarget.value)}
            id={id}
            required
            className={cn(
              controller.field.value &&
                !controller.field.value?.active &&
                "text-red-500"
            )}
          />
          <ComboboxOptions>
            {data?.map((department) => (
              <ComboboxOption
                key={department.id}
                value={department}
                className={cn(!department.active && "text-red-500")}
              >
                {department.name}
              </ComboboxOption>
            ))}
          </ComboboxOptions>
        </Combobox>
      )}
    </C.Field>
  );
}

type Season = RouterOutputs["season"]["search"][number];
function SeasonField() {
  const [query, setQuery] = React.useState("");
  const { data } = trpc.season.search.useQuery({ query });
  const controller = useController({
    name: "season",
  });

  return (
    <C.Field name="season" label="Season">
      {({ id }) => (
        <Combobox
          value={controller.field.value}
          onChange={(e) => {
            controller.field.onChange({
              id: e?.id ?? undefined,
              name: e?.name ?? undefined,
              active: e?.active ?? undefined,
            });
          }}
          className="w-full"
          nullable
        >
          <ComboboxInput
            displayValue={(v?: Season) => v?.name ?? ""}
            onChange={(e) => setQuery(e.currentTarget.value)}
            id={id}
            className={cn(
              controller.field.value &&
                !controller.field.value?.active &&
                "text-red-500"
            )}
          />
          <ComboboxOptions>
            {data?.map((season) => (
              <ComboboxOption
                key={season.id}
                value={season}
                className={cn(!season.active && "text-red-500")}
              >
                {season.name}
              </ComboboxOption>
            ))}
          </ComboboxOptions>
        </Combobox>
      )}
    </C.Field>
  );
}
