import React from "react";
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";

/**
 *
 * @param {{
 *     as?: any,
 *     name?: string,
 *   }
 *   & import("./FormControl").Rules
 *   & import("@chakra-ui/react").BoxProps
 *   & {[x: string]: any}
 * } props
 */
export default function Field({
  as,
  children,
  name,
  required,
  maxLength,
  minLength,
  max,
  min,
  pattern,
  validate,
  valueAsNumber,
  valueAsDate,
  value,
  ...props
}) {
  const { register, control } = useFormContext();
  const registerProps = register(name, {
    required,
    maxLength,
    minLength,
    max,
    min,
    pattern,
    validate,
    valueAsNumber: valueAsNumber || props.type === "number" || false,
    valueAsDate,
    value,
  });
  if (typeof children === "function") {
    return children({
      control,
      name,
      field: registerProps,
      id: props.id,
    });
  } else {
    return React.createElement(as, {
      control,
      children,
      ...props,
      ...registerProps,
    });
  }
}

Field.defaultProps = {};

Field.propTypes = {
  as: PropTypes.elementType.isRequired,
  name: PropTypes.string.isRequired,
  rules: PropTypes.object,
  required: PropTypes.bool,
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  max: PropTypes.number,
  min: PropTypes.number,
  pattern: PropTypes.object,
  validate: PropTypes.func,
  valueAsNumber: PropTypes.bool,
  valueAsDate: PropTypes.bool,
};
