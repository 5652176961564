import { trpc } from "#/trpc.js"
import { reportUserError, reportUserSuccess } from "#/util/index.js"
import DiscountForm, { DiscountFormValues } from "./Form.js"
import Decimal from "decimal.js"
import React, { useMemo } from "react"
import { useNavigate, useParams } from "react-router"

export default function UpdateDiscount() {
	const params = useParams()
	const discountReference = params.reference
	// ^ Discount ID from the discount we're going to update.
	const { data: defaultDiscount } = trpc.discount.pos.getByReference.useQuery(
		{
			reference: discountReference!,
		},
		{
			enabled: typeof discountReference === "string",
		},
	)
	const defaultValues = useMemo((): Partial<DiscountFormValues> => {
		if (!defaultDiscount) return {}
		return {
			ref: defaultDiscount[0].reference,
			discountMode: defaultDiscount[0].discountMode,
			active: defaultDiscount[0].active,
			rates: defaultDiscount.map((d) => ({
				id: d.id,
				minPurchase: d.minPurchase?.toNumber(),
				minPurchaseForEligibility: d.minPurchaseForEligibility?.toNumber(),
				amount: d.amount.toNumber(),
				redeemable: d.redeemable,
				type: d.type,
			})),
			itemSkus: defaultDiscount[0].itemSkuDiscounts.map(
				(iskuDiscount) => iskuDiscount.itemSku,
			),
			departments: defaultDiscount[0].departmentDiscounts.map(
				(dd) => dd.department,
			),
			filterMode: defaultDiscount[0].filterMode!,
			activeDateRange: defaultDiscount[0].dateStart
				? [defaultDiscount[0].dateStart, defaultDiscount[0].dateEnd]
				: undefined,
			validOn: defaultDiscount[0].validOn ?? undefined,
		}
	}, [defaultDiscount])

	const navigate = useNavigate()

	const ctx = trpc.useContext()
	const { mutate, isLoading } =
		trpc.discount.pos.updateManyByReference.useMutation({
			onSuccess() {
				reportUserSuccess({
					title: "Discounts updated successfully",
				})
				ctx.discount.invalidate()
				ctx.v2_5.discounts.invalidate()
				navigate("/inventory/discounts")
			},
			onError(error) {
				reportUserError({
					title: "Failed to update discounts",
					message: error.message,
				})
			},
		})

	const handleSubmit = React.useCallback(
		(discount: DiscountFormValues) => {
			mutate({
				reference: discount.ref,
				rates: discount.rates.map((rate) => {
					console.log(
						rate.minPurchaseForEligibility,
						rate.minPurchaseForEligibility == null,
						rate.minPurchaseForEligibility != null
							? new Decimal(rate.minPurchaseForEligibility)
							: null,
					)
					if (rate.id == null) {
						throw new Error("rate id must be present when updating a discount")
					}
					return {
						id: rate.id,
						minPurchase:
							rate.minPurchase != null ? new Decimal(rate.minPurchase) : null,
						minPurchaseForEligibility:
							rate.minPurchaseForEligibility != null
								? new Decimal(rate.minPurchaseForEligibility)
								: null,
					}
				}),
				itemSkus: discount.itemSkus.map((isku) => isku.id),
				filterMode: discount.filterMode,
				departments: discount.departments.map((d) => d.id),
			})
		},
		[mutate],
	)
	return (
		<DiscountForm
			onSubmit={handleSubmit}
			isLoading={isLoading}
			defaultValues={defaultValues}
			mode="update"
		/>
	)
}
