import { cn } from "#/lib/utils";
import { RouterOutputs } from "#/trpc";
import * as M from "@mantine/core";
import Decimal from "decimal.js";

interface EndOfDayBoxDetailsProps {
  header: string;
  data: {
    title: string;
    total: Decimal | number;
    isMoney: boolean;
  }[];
}

export const EndOfDayBoxDetails = ({
  data,
  header,
}: EndOfDayBoxDetailsProps) => {
  return (
    <div
      className={cn(
        "flex flex-col items-start gap-2.5 self-stretch",
        "rounded-sm border border-[#EEE]"
      )}
    >
      <div className="flex w-full items-start justify-between self-stretch p-2.5 odd:bg-[#F9F9F9]">
        <M.Text className="text-sm" weight={600}>
          {header}
        </M.Text>
      </div>
      {data.map((item, index) => (
        <div
          key={index}
          className="flex w-full items-start justify-between self-stretch p-2.5 odd:bg-[#F9F9F9]"
        >
          <M.Text className="text-sm" weight={400}>
            {item.title}
          </M.Text>
          <M.Text className="text-sm" weight={400} align="right">
            {item.isMoney ? `$${item.total?.toFixed(2)}` : `${item.total}`}
          </M.Text>
        </div>
      ))}
    </div>
  );
};

type PaymentsEndOfDay =
  RouterOutputs["report"]["getEndOfDay"]["payments"]["orders"];
interface TableDetailsProps {
  data: PaymentsEndOfDay;
}
export const TableDetails = ({ data }: TableDetailsProps) => {
  return (
    <M.Table
      className="overflow-x-auto border border-[#EEE]"
      verticalSpacing="sm"
    >
      <thead>
        <tr>
          <th>Order</th>
          <th>Customer</th>
          <th>Method</th>
          <th>Total amount</th>
        </tr>
      </thead>
      <tbody>
        {data?.map((p, index) => (
          <tr key={index} className="odd:bg-[#F9F9F9]">
            <td>#{p.orderId}</td>
            <td>{p.customerFullName}</td>
            <td>{p.method}</td>
            <td>${p.totalAmount.toFixed(3)}</td>
          </tr>
        ))}
      </tbody>
    </M.Table>
  );
};
