import * as M from "@mantine/core";
import { useUncontrolled } from "@mantine/hooks";
import { uniqBy } from "lodash";

export interface MultiSelectProps<Value>
  extends Omit<
    M.MultiSelectProps,
    "data" | "onChange" | "defaultValue" | "value"
  > {
  data: Array<Value>;
  onChange?: (values: Array<Value>) => void;
  defaultValue?: Array<Value>;
  value?: Array<Value>;
  entryId: (value: Value) => string;
  entryLabel: (value: Value) => string;
}

export function MultiSelect<Value>({
  data,
  defaultValue,
  onChange: callerOnChange,
  value: callerValue,
  entryId,
  entryLabel,
  ...props
}: MultiSelectProps<Value>) {
  const [value, onChange] = useUncontrolled({
    defaultValue: defaultValue,
    onChange: callerOnChange,
    finalValue: [],
    value: callerValue,
  });

  const processedValue = useMemo(() => {
    return value.map((v) => entryId(v));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const processedData = useMemo(() => {
    let transformedData = data.map((e) => ({
      label: entryLabel(e),
      value: entryId(e),
      original: e,
    }));

    transformedData = transformedData.concat(
      value.map((v) => ({
        label: entryLabel(v),
        value: entryId(v),
        original: v,
      }))
    );

    transformedData = uniqBy(transformedData, (e) => e.value);

    return transformedData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, value]);

  const handleChange = (newSelectedIds: string[]) => {
    const newSelectedValues = newSelectedIds.map((id) => {
      return (
        data.find((e) => entryId(e) === id) ??
        value.find((v) => entryId(v) === id)!
      );
    });
    onChange(newSelectedValues);
  };

  return (
    <M.MultiSelect
      data={processedData}
      value={processedValue}
      onChange={handleChange}
      {...props}
    />
  );
}
