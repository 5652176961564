import { css } from "#/css/css";
import { styled } from "#/css/jsx";
import { Wheel } from "react-custom-roulette";
import { RouletteInfoForm } from "./roulette-info";
import { RouterOutputs } from "#/trpc";
import { FormValues } from "./types";
import { RouletteItemsSection } from "./roulette-items";
import { DefaultValues, SubmitHandler } from "react-hook-form";

type RouletteItem = NonNullable<
  RouterOutputs["v2_5"]["roulette"]["getById"]
>["rouletteItems"][number];

export interface RouletteFormProps {
  onSubmit: SubmitHandler<FormValues>;
  isLoading: boolean;
  defaultValues?: DefaultValues<FormValues>;
  rouletteId?: number | null;
  rouletteItems: RouletteItem[];
}

export const RouletteForm = (props: RouletteFormProps) => {
  const options = useMemo(
    () =>
      props?.rouletteItems
        ?.filter((item) => item.active)
        ?.map((item) => ({
          option: item.title ?? "",
        })) ?? [],
    [props.rouletteItems],
  );

  return (
    <styled.div
      className={css({
        backgroundColor: "white",
        padding: "40px",
        borderRadius: "lg",
      })}
    >
      <h2
        className={css({
          color: "black",
          fontSize: "26px",
          fontWeight: "bold",
          marginBottom: "18px",
        })}
      >
        Roulette
      </h2>
      <styled.div
        className={css({
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          gap: 20,
        })}
      >
        <styled.div flex={1} w="1/2">
          <RouletteInfoForm
            onSubmit={props.onSubmit}
            isLoading={props.isLoading}
            rouletteId={props?.rouletteId}
            defaultValues={props.defaultValues}
          />
        </styled.div>
        <styled.div flex={1} w="1/2">
          {props.rouletteId && props.rouletteItems && options.length > 0 ? (
            <Wheel
              mustStartSpinning={false}
              prizeNumber={0}
              data={options}
              backgroundColors={["#FFC435", "#E664A4"]}
              textColors={["#ffffff"]}
              fontFamily="Montserrat"
              fontSize={16}
              outerBorderWidth={0}
              radiusLineWidth={1}
            />
          ) : (
            <p
              className={css({
                color: "black",
                fontSize: "16px",
              })}
            >
              {!props.rouletteId
                ? "There are no existing roulette, please create one."
                : props.rouletteItems && props.rouletteItems.length === 0
                  ? "There are no active options for this roulette. Please add some."
                  : ""}
            </p>
          )}
        </styled.div>
      </styled.div>

      <styled.div
        className={css({
          width: "100%",
          height: 0.3,
          backgroundColor: "#E0E0E0",
          marginY: "48px",
        })}
      />
      {props.rouletteId && (
        <RouletteItemsSection
          rouletteItems={props.rouletteItems}
          rouletteId={props.rouletteId}
        />
      )}
    </styled.div>
  );
};
