import dayjs from "dayjs";
import { atom } from "jotai";

type DashboardBaseData = {
  userId: number | null;
  filialId: number | null;
  fromDate: Date | null;
  toDate: Date | null;
  send: boolean;
  user: {
    firstName: string;
    lastName: string | null;
    avatar: string | null;
    roleName: string;
  } | null;
};

export const dashboardBaseDataAtom = atom<DashboardBaseData>({
  userId: null,
  filialId: null,
  fromDate: dayjs().startOf("month").toDate(),
  toDate: dayjs().endOf("month").toDate(),
  send: false,
  user: null,
});
