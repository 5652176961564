import { useAuth } from "#/context/AuthContext.js";
import { css } from "#/css/css";
import { RouterOutputs, trpc } from "#/trpc.js";
import { reportUserSuccess } from "#/util/index.js";
import { useZebraPrinter } from "#/util/label.js";
import { InventoryAdjustmentModal } from "./components/InventoryAdjustmentModal.js";
import { createLabelTemplate } from "./helper.js";
import * as M from "@mantine/core";
import { useMantineTheme } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { IconButton, Tooltip } from "@radix-ui/themes";
import dayjs from "dayjs";
import Decimal from "decimal.js";
import {
  MRT_ColumnDef,
  MRT_SortingState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import {
  MdMoreHoriz,
  MdOutlineDownload,
  MdVisibility,
} from "react-icons/md/index.js";
import { Link } from "react-router-dom";
import ZebraBrowserPrintWrapper from "zebra-browser-print-wrapper";
import EditIcon from "~icons/ion/create-outline";
import PrintIcon from "~icons/ion/print-outline";

type Adjustment =
  RouterOutputs["v2_5"]["adjustments"]["getByPage"]["entries"][number];

type AdjustmentForTable = Adjustment & {
  newQtyTotal: number;
  oldQtyTotal: number;
  diffQtyTotal: number;
  diffCostTotal: Decimal;
};

export const AdjustInventory = () => {
  const [{ auth }] = useAuth();
  const { printer, loading: loadingPrinter } = useZebraPrinter();

  // pagination
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 25,
  });

  // sorting
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const querySorting = useMemo(() => {
    if (sorting[0]) {
      return {
        desc: sorting[0].desc,
        key: sorting[0].id as any,
      };
    }
    return null;
  }, [sorting]);

  const { data, isLoading } = trpc.v2_5.adjustments.getByPage.useQuery(
    {
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize,
      sorting: querySorting,
      filters: {
        filialId: auth?.user?.filialId ?? null,
      },
    },
    {
      keepPreviousData: true,
    },
  );

  const { mutate: exportCsv, isLoading: isLoadingExport } =
    trpc.v2_5.adjustments.exportAdjustmentsTableToCsv.useMutation({
      onSuccess: () => {
        reportUserSuccess({
          title: "The csv file has been sent to email",
        });
      },
    });

  const handleExportCsv = () => {
    exportCsv({
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize,
      sorting: querySorting,
      filters: {
        filialId: auth?.user?.filialId ?? null,
      },
    });
  };

  const tableData = useMemo(
    () =>
      data?.entries?.map((adj) => {
        const newQtyTotal = adj.adjustmentItemSku.reduce(
          (acc, item) => acc + (item.newStock ?? 0),
          0,
        );
        const oldQtyTotal = adj.adjustmentItemSku.reduce(
          (acc, item) => acc + (item.oldStock ?? 0),
          0,
        );
        const diffQtyTotal = newQtyTotal - oldQtyTotal;
        const diffCostTotal = adj.adjustmentItemSku.reduce((acc, item) => {
          const newCostTotal = new Decimal(item.newStock ?? 0).mul(
            item.newPrice ?? 0,
          );
          const oldCostTotal = new Decimal(item.oldStock ?? 0).mul(
            item.oldPrice ?? 0,
          );
          const diffCostTotal = newCostTotal.sub(oldCostTotal);
          return acc.plus(diffCostTotal);
        }, new Decimal(0));
        return {
          ...adj,
          newQtyTotal,
          oldQtyTotal,
          diffQtyTotal,
          diffCostTotal,
        };
      }) ?? [],
    [data?.entries],
  );

  const table = useMantineReactTable({
    data: tableData ?? [],
    columns: columns({
      printer,
      loadingPrinter,
    }),
    manualPagination: true,
    enableTopToolbar: false,
    rowCount: data?.totalEntries ?? 0,
    enableFilters: false,
    state: {
      pagination,
      sorting,
      isLoading,
    },
    enableStickyHeader: true,
    manualSorting: true,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    renderBottomToolbarCustomActions: () => (
      <div
        className={css({
          display: "flex",
          alignItems: "center",
          gap: 2,
        })}
      >
        <Tooltip content="Export to CSV">
          <IconButton
            color="gray"
            variant="soft"
            loading={isLoadingExport}
            onClick={handleExportCsv}
          >
            <MdOutlineDownload />
          </IconButton>
        </Tooltip>
      </div>
    ),
  });

  return (
    <M.Box>
      <M.Menu>
        <M.Menu.Target>
          <M.Button>I want to...</M.Button>
        </M.Menu.Target>
        <M.Menu.Dropdown>
          <M.Menu.Item component={Link} to="/inventory/adjust/new">
            Create inventory adjustment
          </M.Menu.Item>
        </M.Menu.Dropdown>
      </M.Menu>
      <M.Box mt={16} sx={{ background: "white" }}>
        <MantineReactTable table={table} />
      </M.Box>
    </M.Box>
  );
};

const columns = ({
  printer,
  loadingPrinter,
}: {
  printer: ZebraBrowserPrintWrapper;
  loadingPrinter: boolean;
}): Array<MRT_ColumnDef<AdjustmentForTable>> => [
  {
    id: "actions",
    header: "Actions",
    enableSorting: false,
    enableColumnFilter: false,
    maxSize: 120,
    Cell: (table) => {
      const [opened, setOpened] = useState<boolean>(false);

      const handlePrintLabel = () => {
        if (printer) {
          printer.print(
            createLabelTemplate({
              tag: table.row.original.tagName,
              guide: table.row.original.id,
              date: dayjs(table.row.original.createdAt).format("MM/DD/YYYY"),
              reason: table.row.original.adjustmentType,
            }),
          );
        } else {
          showNotification({
            title: "Zebra printer not connected.",
            message: "Make sure Zebra Browser Print is running and try again.",
            color: "red",
          });
        }
      };

      return (
        <M.Group noWrap position="center">
          {table.row.original.adjustmentStatus === "DRAFT" ? (
            <M.Menu>
              <M.Menu.Target>
                <M.ActionIcon>
                  <MdMoreHoriz />
                </M.ActionIcon>
              </M.Menu.Target>
              <M.Menu.Dropdown>
                <M.Menu.Item
                  component={Link}
                  to={`/inventory/adjust/edit/${table.row.original.id}`}
                  icon={<EditIcon />}
                >
                  Edit
                </M.Menu.Item>
                <M.Menu.Item
                  disabled={loadingPrinter}
                  onClick={handlePrintLabel}
                  icon={<PrintIcon />}
                >
                  <M.Group>
                    <M.Text>Print label</M.Text>
                    {loadingPrinter && <M.Loader size="xs" />}
                  </M.Group>
                </M.Menu.Item>
              </M.Menu.Dropdown>
            </M.Menu>
          ) : (
            <></>
          )}
          <InventoryAdjustmentModal
            opened={opened}
            onClose={() => setOpened(false)}
            adjustment={table.row.original}
          />
          <M.ActionIcon onClick={() => setOpened(true)}>
            <MdVisibility />
          </M.ActionIcon>
        </M.Group>
      );
    },
  },
  {
    id: "createdAt",
    header: "Date",
    maxSize: 120,
    accessorKey: "createdAt",
    Cell: (table) => {
      const createdAt = table.row.original.createdAt;
      if (!createdAt) return "";
      return dayjs(createdAt).format("MM/DD/YYYY").toString();
    },
  },
  {
    id: "id",
    header: "Guide",
    maxSize: 100,
    accessorKey: "id",
    Cell: (table) => {
      return table.row.original.adjustmentStatus === "DRAFT" ? (
        <M.Text>DRAFT</M.Text>
      ) : (
        <M.Text>{table.row.original.id}</M.Text>
      );
    },
  },
  {
    id: "productsQty",
    header: "Products qty",
    maxSize: 100,
    enableSorting: false,
    enableColumnFilter: false,
    Cell: (table) => {
      return table.row.original.adjustmentItemSku.length;
    },
  },
  {
    id: "newQtyTotal",
    header: "New total qty",
    accessorKey: "newQtyTotal",
    maxSize: 100,
    enableSorting: false,
    enableColumnFilter: false,
  },
  {
    id: "oldQtyTotal",
    header: "Old total qty",
    accessorKey: "oldQtyTotal",
    maxSize: 100,
    enableSorting: false,
    enableColumnFilter: false,
  },
  {
    id: "diffQtyTotal",
    header: "Diff total qty",
    accessorKey: "diffQtyTotal",
    maxSize: 100,
    enableSorting: false,
    enableColumnFilter: false,
    Cell: (table) => {
      const value = table.row.original.diffQtyTotal;
      return <M.Text color={value < 0 ? "red.5" : "green.5"}>{value}</M.Text>;
    },
  },
  {
    id: "diffCostTotal",
    header: "Diff total cost",
    accessorKey: "diffCostTotal",
    maxSize: 100,
    enableSorting: false,
    enableColumnFilter: false,
    Cell: (table) => {
      const value = table.row.original.diffCostTotal;
      return (
        <M.Text color={value.lt(0) ? "red.5" : "green.5"}>
          ${value?.toNumber()?.toFixed(2) ?? 0}
        </M.Text>
      );
    },
  },
  {
    id: "adjustmentStatus",
    header: "Status",
    accessorKey: "adjustmentStatus",
    maxSize: 120,
    Cell: (table) => {
      const theme = useMantineTheme();
      const color =
        table.row.original.adjustmentStatus === "DRAFT"
          ? theme.colors.orange
          : theme.colors.green;
      return (
        <M.Text
          sx={{
            color: color[6],
            background: color[0],
            borderRadius: "1rem",
            padding: "0.15rem 0.75rem",
            textAlign: "center",
          }}
        >
          {table.row.original.adjustmentStatus}
        </M.Text>
      );
    },
  },
  {
    id: "adjustmentType",
    header: "Type",
    accessorKey: "adjustmentType",
    maxSize: 100,
  },
  {
    id: "user",
    header: "Perfomed By",
    maxSize: 100,
    enableSorting: false,
    enableColumnFilter: false,
    Cell: (table) => {
      return (
        <M.Text>
          {table.row.original?.user?.firstName}{" "}
          {table.row.original?.user?.lastName ?? ""}
        </M.Text>
      );
    },
  },
  {
    id: "note",
    header: "Note",
    accessorKey: "note",
    maxSize: 100,
    enableSorting: false,
    enableColumnFilter: false,
  },
];
