import { trpc } from "#/trpc.js";
import { reportUserError } from "#/util/index.js";
import * as M from "@mantine/core";
import dayjs from "dayjs";
import React from "react";
import { useLocation } from "react-router";
import type { SuggestedItemStatus } from "server";

export const SuggestedItemsPdf = () => {
  const location = useLocation();
  const state: { statuses: SuggestedItemStatus[] } = location.state as any;

  const { data, isLoading } = trpc.suggestedItem.getByStatuses.useQuery(
    {
      statuses: state.statuses,
    },
    {
      onError: (err) => {
        reportUserError({
          title: "Error while fetching suggested items",
          message: err.message,
        });
      },
    }
  );

  React.useEffect(() => {
    if (data) {
      window.print();
    }
  }, [data]);

  if (isLoading) return <M.Loader />;

  return (
    <M.Table>
      <thead>
        <tr>
          <th>Image</th>
          <th>Date</th>
          <th>Customer&apos;s name</th>
          <th>Customer&apos;s phone</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {data?.map((si, i) => (
          <tr key={i}>
            <td>
              <img src={si?.images[0] ?? undefined} width={50} />
            </td>
            <td>{dayjs(si.createdAt).format("DD/MM/YYYY")}</td>
            <td>
              {si.user?.firstName} {si.user?.lastName}
            </td>
            <td>{si.user?.phoneNumber}</td>
            <td>{si.status}</td>
          </tr>
        ))}
      </tbody>
    </M.Table>
  );
};
