import { ActionsMenuIcon, EditIcon } from "#/components-ng";
import { RouterOutputs, trpc } from "#/trpc";
import * as M from "@mantine/core";
import dayjs from "dayjs";
import {
  MantineReactTable,
  useMantineReactTable,
  MRT_ColumnDef,
  MRT_PaginationState,
  MRT_SortingState,
  MRT_FilterOption,
} from "mantine-react-table";
import { openConfirmModal } from "@mantine/modals";
import { Link } from "react-router-dom";
import DeleteIcon from "~icons/ion/trash-outline";
import { HStack, styled } from "#/css/jsx";
import { DropdownMenu, IconButton, Tooltip } from "@radix-ui/themes";
import { Button, Dropdown } from "@gt/ui";
import { css } from "#/css/css";
import { SearchButton } from "#/components@v2_5/search-button.js";
import { reportUserSuccess } from "#/util";
import { MdOutlineDownload } from "react-icons/md";

export default function SuppliersTable() {
  // Pagination
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });

  // Sorting
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const querySorting = useMemo(() => {
    if (sorting[0]) {
      return {
        desc: sorting[0].desc,
        key: sorting[0].id as any,
      };
    }
    return null;
  }, [sorting]);

  const [globalFilter, setGlobalFilter] = useState<
    MRT_FilterOption | undefined
  >();

  const { data } = trpc.v2_5.supplier.getAllSuppliersByPage.useQuery(
    {
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize,
      sorting: querySorting,
      search: globalFilter,
    },
    {
      keepPreviousData: true,
    },
  );

  const { mutate: exportCsv, isLoading: isLoadingExport } =
    trpc.v2_5.supplier.exportSuppliersTableToCsv.useMutation({
      onSuccess: () => {
        reportUserSuccess({
          title: "The csv file has been sent to email",
        });
      },
    });

  const handleExportCsv = () => {
    exportCsv({
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize,
      sorting: querySorting,
      search: globalFilter,
    });
  };

  const table = useMantineReactTable({
    columns: columns(),
    data: data?.entries ?? [],
    rowCount: data?.totalEntries ?? 0,
    manualPagination: true,
    manualSorting: true,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    onGlobalFilterChange: setGlobalFilter,
    mantineSearchTextInputProps: {
      placeholder: "Search by name",
    },
    state: {
      pagination,
      sorting,
    },
    enableTopToolbar: false,
    enableStickyHeader: true,
    renderBottomToolbarCustomActions: () => (
      <div
        className={css({
          display: "flex",
          alignItems: "center",
          gap: 2,
        })}
      >
        <Tooltip content="Export to CSV">
          <IconButton
            color="gray"
            variant="soft"
            loading={isLoadingExport}
            onClick={handleExportCsv}
          >
            <MdOutlineDownload />
          </IconButton>
        </Tooltip>
      </div>
    ),
  });

  return (
    <>
      <HStack justify="space-between" gap={3}>
        <DrowndownButton />
        <SearchButton setQuery={setGlobalFilter} />
      </HStack>
      <div
        className={css({
          marginTop: 5,
        })}
      >
        <MantineReactTable table={table} />
      </div>
    </>
  );
}

type Supplier =
  RouterOutputs["v2_5"]["supplier"]["getAllSuppliersByPage"]["entries"][number];

const columns = (): MRT_ColumnDef<Supplier>[] => [
  {
    id: "id",
    enableSorting: false,
    enableColumnFilter: false,
    header: "Actions",
    enableColumnActions: false,
    size: 100,

    Cell: (props) => {
      const ctx = trpc.useContext();

      const { mutate: deleteSupplierById } =
        trpc.v2_5.supplier.deleteById.useMutation({
          onSuccess: () => {
            ctx.v2_5.supplier.invalidate();
          },
        });

      const openConfirmDeleteModal = () =>
        openConfirmModal({
          title: (
            <M.Stack spacing={2}>
              <M.Text> Are you sure you want to remove this supplier?</M.Text>
            </M.Stack>
          ),
          labels: { cancel: "Cancel", confirm: "Delete" },
          confirmProps: { color: "red" },
          onConfirm: () => deleteSupplierById({ id: props.row.original.id }),
        });

      return (
        <M.Box ml={16}>
          <M.Menu withinPortal>
            <M.Menu.Target>
              <M.ActionIcon variant="subtle">
                <ActionsMenuIcon />
              </M.ActionIcon>
            </M.Menu.Target>
            <M.Menu.Dropdown p={2}>
              <M.Menu.Item
                icon={<DeleteIcon />}
                onClick={() => openConfirmDeleteModal()}
              >
                <M.Text>Delete</M.Text>
              </M.Menu.Item>
              <M.Menu.Item
                icon={<EditIcon />}
                component={Link}
                to={`edit/${props.row.original.id}`}
              >
                <M.Text>Edit</M.Text>
              </M.Menu.Item>
            </M.Menu.Dropdown>
          </M.Menu>
        </M.Box>
      );
    },
  },

  {
    enableColumnFilterModes: false,
    header: "Name",
    accessorKey: "name",
    enableSorting: true,

    Cell: (props) => {
      return (
        <M.Box>
          <M.Text>{props.row.original.name}</M.Text>
        </M.Box>
      );
    },
  },

  {
    header: "Description",
    accessorKey: "description",
    enableSorting: true,
    enableColumnFilter: false,
    Cell: ({ row: { original } }) => {
      return (
        <M.Box>
          <styled.p lineClamp={2} overflow={"hidden"}>
            {original.description}
          </styled.p>
        </M.Box>
      );
    },
  },
  {
    header: "Phone number",
    accessorKey: "phone number",
    enableSorting: false,
    enableColumnFilter: false,
    Cell: ({ row: { original } }) => {
      return (
        <M.Box>
          <M.Text>{original.phoneNumber}</M.Text>
        </M.Box>
      );
    },
  },
  {
    header: "Address",
    accessorKey: "address",
    enableSorting: false,
    enableColumnFilter: false,
    Cell: ({ row: { original } }) => {
      return (
        <M.Box>
          <styled.p lineClamp={2} overflow={"hidden"}>
            {original.address}
          </styled.p>
        </M.Box>
      );
    },
  },
  {
    header: "Created at",
    enableSorting: true,
    accessorKey: "createdAt",
    enableColumnFilter: false,
    Cell: ({ row: { original } }) => {
      return (
        <M.Box>
          <M.Text>{dayjs(original.createdAt).format("MM/DD/YYYY")}</M.Text>
        </M.Box>
      );
    },
  },
];

const DrowndownButton = () => {
  return (
    <Dropdown.Root>
      <Dropdown.Trigger>
        <Button
          className={css({
            gap: 4,
          })}
        >
          I want to
          <DropdownMenu.TriggerIcon />
        </Button>
      </Dropdown.Trigger>
      <Dropdown.Content>
        <Dropdown.Item>
          <Link to="create">Create supplier</Link>
        </Dropdown.Item>
      </Dropdown.Content>
    </Dropdown.Root>
  );
};
