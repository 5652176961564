import { openChangelogDialog } from "#/changelog/dialog.state.js"
import type { Module } from "#/changelog/modules.js"
import {
	AppsFilledIcon,
	CartIcon,
	DocumentTextOutlineIcon,
	DollarFilledIcon,
	FolderOpenOutlineIcon,
	HomeOutlineIcon,
	LineChartFilledIcon,
	LogoutFilledIcon,
	SettingsFilledIcon,
} from "#/components-ng/index.js"
import { ChangelogNewBadge } from "#/components@v2_5/changelog-new-badge.js"
import { Flex, styled } from "#/css/jsx"
import { cn } from "#/lib/utils/index.js"
import { trpc } from "#/trpc.js"
import { useAuth } from "../../context/AuthContext.js"
import { CustomersIcon } from "../icons/index.jsx"
import { motion } from "framer-motion"
import type { ReactNode } from "react"
import { useNavigate, Link } from "react-router-dom"

const Menu = ({
	location,
	open = true,
	isDesktop,
}: {
	location: any
	open?: boolean
	isDesktop?: boolean
}) => {
	const [{ auth }, { logout }] = useAuth()
	const navigate = useNavigate()

	const { data: dataEntity } = trpc.entity.getById.useQuery(
		{
			id: 1,
		},
		{
			cacheTime: 0,
			enabled: !auth?.user?.filialId,
		},
	)

	const { data: dataFilial } = trpc.filial.getById.useQuery(
		{
			id: auth?.user?.filialId ?? 0,
		},
		{
			cacheTime: 0,
			enabled: !!auth?.user?.filialId,
		},
	)

	const handleLogout = (e) => {
		logout()
		navigate("/login")
		e.preventDefault()
	}

	return (
		<motion.div
			animate={{ marginLeft: open ? 0 : -245 }}
			transition={{
				type: "tween",
				duration: !isDesktop ? 0 : 0.2,
				ease: "easeInOut",
			}}
			style={{
				width: "240px",
			}}
		>
			<div
				className={cn(
					"mr-1 flex h-[calc(100vh-60px)] flex-col overflow-auto border-r border-slate-200",
					"py-6 pl-8 pr-3",
				)}
			>
				<div className="mb-8">
					<h3>{dataFilial?.name || dataEntity?.name || "Gold Tree"}</h3>
				</div>
				<MenuNavbar location={location} handleLogout={handleLogout} />
			</div>
		</motion.div>
	)
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const MenuItem = ({
	icon,
	label,
	path,
	location,
	module,
	onClick = () => {},
}: {
	icon: ReactNode
	label: string
	path: string
	location: any
	module?: Module
	onClick?: () => void
}) => {
	const active = location.pathname === path ? "active" : ""

	return (
		<styled.li display="flex" justifyContent="space-between">
			<Link
				to={path}
				className={cn(
					"flex items-center gap-x-2 px-1 text-slate-600",
					active && "font-medium text-brand-500",
				)}
				onClick={onClick}
			>
				<span className={"leading-none"}>{icon}</span>
				<span className={"leading-none"}>{label}</span>
			</Link>

			{module != null && <ChangelogNewBadge module={module} />}
		</styled.li>
	)
}

export default Menu

export const MenuNavbar = ({
	location,
	handleLogout,
	onClick,
}: {
	location: any
	handleLogout: (e: any) => void
	onClick?: () => void
}) => {
	return (
		<nav className={"flex flex-1 flex-col justify-between gap-y-4"}>
			<ul className={"space-y-7"}>
				<MenuItem
					icon={<HomeOutlineIcon />}
					label="Home"
					path="/"
					location={location}
					onClick={onClick}
				/>
				<MenuItem
					icon={<DollarFilledIcon />}
					label="Sales"
					path="/sales"
					location={location}
					module="sales"
					onClick={onClick}
				/>
				<MenuItem
					icon={<FolderOpenOutlineIcon />}
					label="Inventory"
					path="/inventory"
					location={location}
					module="inventory"
					onClick={onClick}
				/>
				<MenuItem
					icon={<AppsFilledIcon />}
					label="Dashboard"
					path="/dashboard"
					location={location}
					onClick={onClick}
				/>
				<MenuItem
					icon={<CustomersIcon />}
					label="Customers"
					path="/customers"
					location={location}
					module="customers.customerList"
					onClick={onClick}
				/>
				<MenuItem
					icon={<CartIcon className="size-5" />}
					label="E-commerce CRM"
					path="/ecommerce-crm"
					location={location}
					module="crm"
					onClick={onClick}
				/>
				<MenuItem
					icon={<LineChartFilledIcon />}
					label="Reports"
					path="/reports"
					location={location}
					module="reports"
					onClick={onClick}
				/>
				<MenuItem
					icon={<SettingsFilledIcon />}
					label="Settings"
					path="/settings"
					location={location}
					module="settings"
					onClick={onClick}
				/>
			</ul>

			<styled.ul display="grid" rowGap={6}>
				<Flex justify="space-between">
					<MenuItem
						icon={<DocumentTextOutlineIcon />}
						label="Changelog"
						path="#"
						// @ts-ignore
						onClick={() => {
							openChangelogDialog()
							if(onClick){
                onClick()
              }
						}}
						location={location}
					/>
					<ChangelogNewBadge module="changelog" />
				</Flex>
				<MenuItem
					icon={<LogoutFilledIcon />}
					label="Logout"
					path="#"
					// @ts-ignore
					onClick={handleLogout}
					location={location}
				/>
			</styled.ul>
		</nav>
	)
}
