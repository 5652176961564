import { MantineController as Mc } from "#/components/Form/v3/index.js";
import { RouterPrompt } from "#/components/index.js";
import { trpc, RouterInputs } from "#/trpc.js";
import { useS3PresignedUrl } from "#/util/index.js";
import { reportUserError } from "#/util/ux/index.js";
import * as M from "@mantine/core";
import { IMAGE_MIME_TYPE } from "@mantine/dropzone";
import { useRequiredGroup } from "#/components/Form/v2/index.js";
import getUnicodeFlagIcon from "country-flag-icons/unicode";
import countryTelData from "country-telephone-data";
import { uniqBy } from "lodash";
import React from "react";
import {
  FormProvider,
  useForm,
  Controller,
  DefaultValues,
  useFormContext,
} from "react-hook-form";
import CameraIcon from "~icons/ion/camera-outline";

export type InputVendor = RouterInputs["vendor"]["create"];

export interface VendorFormValues {
  email: string;
  seoName: string;
  contactPersonName: string;
  description?: string;
  phoneNumber?: string;
  areaCode?: string;
  countryCode?: string;
  company?: string;
  defaultImage?: string;
  note?: string;
  address?: {
    street?: string;
    city?: string;
    state?: string;
    country?: string;
    zip?: string;
  };
  rateCosts?: {
    id: number;
    name: string;
  }[];
}

export interface VendorsFormProps {
  defaultValues?: DefaultValues<VendorFormValues>;
  onSubmit: (formValues: VendorFormValues) => void;
  loading: boolean;
}

export default function VendorsForm({
  onSubmit,
  defaultValues,
  loading,
}: VendorsFormProps) {
  const formMethods = useForm<VendorFormValues>({
    defaultValues,
  });
  const isAddressRequired = useRequiredGroup({
    formMethods,
    groupName: "address",
  });

  const [submitted, setSubmitted] = React.useState(false);

  const [uploadPhoto, { isLoading: isPhotoLoading, fileUrl: avatar }] =
    useS3PresignedUrl({
      onSuccess: ({ fileUrl }) => {
        formMethods.setValue("defaultImage", fileUrl);
      },
    });

  const joinedAreaCode = `${formMethods.watch(
    "countryCode"
  )}/${formMethods.watch("areaCode")}`;

  const areaCodes = uniqBy(
    countryTelData.allCountries.map((c) => ({
      label: `${getUnicodeFlagIcon(c.iso2)} +${
        c.dialCode
      } (${c.iso2.toUpperCase()})`,
      value: `${c.iso2.toUpperCase()}/+${c.dialCode}`,
    })),
    (c) => c.value
  );

  const countries = uniqBy(
    countryTelData.allCountries.map((c) => ({
      label: `${getUnicodeFlagIcon(c.iso2)} ${c.name}`,
      value: c.iso2.toUpperCase(),
      iso2: c.iso2.toUpperCase(),
    })),
    (c) => c.value
  );

  return (
    <M.Container size="lg" px={18}>
      <form
        onSubmit={formMethods.handleSubmit((formValues: VendorFormValues) => {
          setSubmitted(true);
          onSubmit({
            ...formValues,
            address: isAddressRequired ? formValues.address : undefined,
          });
        })}
      >
        <RouterPrompt when={!submitted} />
        <M.Title order={3}>Vendor</M.Title>
        <FormProvider {...formMethods}>
          <M.Stack spacing="xl" mt={4}>
            <M.Group w="100%" spacing="xl" noWrap grow>
              <M.Center>
                <M.Box>
                  <M.Box w={84} pos="relative">
                    <M.Avatar
                      radius="sm"
                      size="xl"
                      color="gray"
                      src={formMethods.watch("defaultImage")}
                      sx={{
                        borderWidth: "2px",
                        borderStyle: "solid",
                        borderColor: "brand.100",
                      }}
                    >
                      GT
                    </M.Avatar>
                    <M.FileButton
                      accept={IMAGE_MIME_TYPE.join(",")}
                      onChange={(file) => {
                        if (file) {
                          uploadPhoto(file, "vendors");
                        }
                      }}
                    >
                      {(props) => {
                        return (
                          <M.ActionIcon
                            color="brand"
                            variant="filled"
                            radius="xl"
                            size="sm"
                            pos="absolute"
                            bottom={0}
                            right={0}
                            {...props}
                            loading={isPhotoLoading}
                          >
                            <CameraIcon fontSize={12} />
                          </M.ActionIcon>
                        );
                      }}
                    </M.FileButton>
                  </M.Box>
                </M.Box>
              </M.Center>

              <Mc
                as={M.TextInput}
                name="contactPersonName"
                label="Contact person name"
                required
              />
              <Mc wrap={M.TextInput} name="seoName" label="SEO Name" required />
              <Mc
                as={M.TextInput}
                name="company"
                label="Company name"
                required
              />
            </M.Group>
            <M.Group w="100%" spacing="xl" noWrap grow align="start">
              <Mc
                as={M.Textarea}
                autosize
                maxRows={8}
                name="description"
                label="Description"
              />
              <Mc
                as={M.Select}
                name="areaCode"
                label="Area code"
                searchable
                nothingFound="No area code found"
                clearable
                data={areaCodes}
                value={
                  areaCodes.find((c) => c.value === joinedAreaCode)?.value ||
                  undefined
                }
                onChange={(value) => {
                  const parts = value?.split("/");
                  if (parts.length) {
                    formMethods.setValue("countryCode", parts[0]);
                    formMethods.setValue("areaCode", parts[1]);
                  }
                }}
              />
              <Mc
                as={M.TextInput}
                name="phoneNumber"
                label="Phone number"
                onInput={(e) => {
                  const value = e.target.value;
                  const onlyNumbers = value.replace(/[^0-9]/g, "");
                  e.target.value = onlyNumbers;
                }}
              />
              <Mc
                wrap={M.TextInput}
                name="email"
                label="Email"
                type="email"
                required
              />
            </M.Group>
            <M.Group w="100%" spacing="xl" grow noWrap>
              <Mc
                as={M.Select}
                name="address.country"
                label="Country"
                searchable
                nothingFound="No country found"
                clearable
                data={countries}
                value={
                  countries.find(
                    (c) => c.value === formMethods.watch("address.country")
                  )?.value || undefined
                }
                onChange={(value) => {
                  formMethods.setValue("address.country", value);
                }}
                required={isAddressRequired}
              />
              <Mc
                as={M.TextInput}
                name="address.city"
                label="City"
                required={isAddressRequired}
              />
              <RateCostField />
            </M.Group>
            <M.Group w="100%" spacing="xl" grow noWrap align="stretch">
              <M.Stack spacing={4}>
                <Mc
                  as={M.TextInput}
                  name="address.street"
                  label="Address"
                  required={isAddressRequired}
                />
                <M.Group w="100%" spacing={6} grow noWrap>
                  <Mc
                    as={M.TextInput}
                    name="address.zip"
                    label="Zip code"
                    required={isAddressRequired}
                  />
                  <Mc
                    as={M.TextInput}
                    name="address.state"
                    label="State"
                    required={isAddressRequired}
                  />
                </M.Group>
              </M.Stack>
              <Mc
                as={M.Textarea}
                name="note"
                label="Note"
                autosize
                minRows={4}
              />
            </M.Group>
            <M.Group position="right">
              <M.Button
                type="submit"
                sx={{ minWidth: "12ch" }}
                loading={loading}
              >
                Submit
              </M.Button>
            </M.Group>
          </M.Stack>
        </FormProvider>
      </form>
    </M.Container>
  );
}

const RateCostField = () => {
  const form = useFormContext<VendorFormValues>();
  const { data, isLoading } = trpc.rateCost.getAll.useQuery(undefined, {
    onError: (err) => {
      reportUserError({
        title: "Failed to load rate costs",
        message: err.message,
      });
    },
  });

  const selectData = React.useMemo(
    () =>
      data?.map((e) => ({
        label: e.name,
        value: e.id.toString(),
      })) ?? [],
    [data]
  );

  return (
    <Controller
      name="rateCosts"
      control={form.control}
      render={({ field, fieldState }) => (
        <M.MultiSelect
          label="Rate costs"
          searchable
          nothingFound="No rate cost found"
          clearable
          data={selectData}
          value={field?.value?.map((r: any) => r.id.toString())}
          error={fieldState.error?.message}
          rightSection={isLoading ? <M.Loader size="xs" /> : undefined}
          onChange={(selectedIds) => {
            const ratecosts = selectData.filter((r) =>
              selectedIds.includes(r.value)
            );
            field.onChange(
              ratecosts.map((r) => ({
                id: Number(r.value),
                name: r.label,
              }))
            );
          }}
        />
      )}
    />
  );
};
