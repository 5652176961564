import GTLoader from "#/components-ng/loader.js";
import { trpc } from "#/trpc.js";
import { CardLayout, CardTable } from "../components/index.js";
import React from "react";

interface FilialStockCardProps {
  itemSkuId: number;
}

export const FilialStockCard = ({ itemSkuId }: FilialStockCardProps) => {
  const { data: allItemSkuStocks, isLoading: isLoadingItemSkuStocks } =
    trpc.itemSkuStock.getByFilialAndItemSku.useQuery(
      {
        itemSkuId: itemSkuId,
      },
      {
        cacheTime: 0,
        enabled: !!itemSkuId,
      }
    );
  const summary = React.useMemo(() => {
    if (!allItemSkuStocks) return [];

    return allItemSkuStocks.map((stock) => {
      const onHoldFilial = stock.holdStock;
      const filialName = stock.filial.name;
      const total = `${stock.quantity}`;
      const storeLocation = stock?.storeLocation ?? "";
      const warehouseLocation = stock?.warehouseLocation ?? "";
      const reorder = `${stock?.reorder}` ?? "";
      const isLine = stock?.isLine ? "✔" : "X";

      return [
        filialName,
        total,
        `${onHoldFilial}`,
        storeLocation,
        warehouseLocation,
        reorder,
        isLine,
      ];
    });
  }, [allItemSkuStocks]);

  return (
    <CardLayout title="Filial Stock">
      {isLoadingItemSkuStocks ? (
        <GTLoader width={100} height={100} />
      ) : (
        <CardTable
          headers={[
            "Filial",
            "Total",
            "On Hold",
            "Store Location",
            "Warehouse Location",
            "Reorder",
            "Is line",
          ]}
          rows={summary}
        />
      )}
    </CardLayout>
  );
};
