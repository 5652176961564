import logoUrl from "#/images/colored-logo-invoice.png";
import { fetchImageAsBase64 } from "#/util/index.js";
import { applyDiscountV2 } from "../util/index.js";
import dayjs from "dayjs";
import Decimal from "decimal.js";
import ExcelJS, { Borders } from "exceljs";
import { saveAs } from "file-saver";
import type { CartAllFields as Cart } from "server";

export const exportInvoicePendingCartsToXLSX = async (cart: Cart) => {
  const wb = new ExcelJS.Workbook();
  const sheet = wb.addWorksheet("Sheet 1", {
    pageSetup: { paperSize: 9 },
  });

  const subtotal = cart.cartItemSku!.reduce((acc, entry) => {
    return new Decimal(
      applyDiscountV2(
        entry!.itemSku.price.times(entry.quantity),
        entry!.discount
      )
    )
      .plus(acc)
      .toNumber();
  }, 0);

  const tax = subtotal * 0.07;
  const total = cart?.customer?.taxable ? subtotal + tax : subtotal;

  sheet.getCell(1, 1).value = `Printed: ${dayjs().format("MM/DD/YYYY")}`;
  sheet.getCell(2, 1).value = `Store: 1`;
  sheet.getCell(3, 1).value = `Workstation: 1`;

  sheet.mergeCells("I1:J1");
  sheet.mergeCells("I2:J2");
  sheet.mergeCells("I3:J3");
  sheet.mergeCells("I4:J4");
  sheet.getCell("I1").value = `Last updated: ${dayjs(cart.updatedAt).format(
    "MM/DD/YYYY"
  )}`;
  sheet.getCell("I2").value = `Associated: ${cart.associated?.firstName} ${
    cart.associated?.lastName ?? ""
  }`;
  sheet.getCell("I3").value = "Page: 1";

  sheet.mergeCells("C5:H5");
  sheet.getCell("C5").font = {
    bold: true,
    size: 22,
  };

  sheet.getCell("C5").value = "PENDING CART";
  sheet.getCell("C5").alignment = {
    horizontal: "center",
  };
  sheet.getRow(5).height = 28;

  const imgData = await fetchImageAsBase64(logoUrl);
  const logo = wb.addImage({
    base64: imgData.data,
    extension: imgData.ext,
  });

  sheet.addImage(logo, {
    tl: {
      col: 3,
      row: 5,
    },
    ext: { width: 180, height: 120 },
  });

  sheet.mergeCells("C12:H12");
  sheet.mergeCells("C13:H13");
  sheet.mergeCells("C14:H14");
  sheet.mergeCells("C15:H15");
  sheet.getCell("C12").value = `GOLD TREE`;
  sheet.getCell("C13").value = "755 NW 72n AVE PLAZA 33";
  sheet.getCell("C14").value = "MIAMI, FL, 33126";
  sheet.getCell("C15").value = "PHONE: +1 (786) 7177131";

  sheet.getCell("A17").value = "Customer:";
  sheet.getCell("B17").value = cart.customer?.company
    ? cart.customer?.company
    : `${cart.customer?.firstName} ${cart.customer?.lastName ?? ""}`;

  const borderStyle = {
    top: { style: "thin" },
    bottom: { style: "thin" },
    left: { style: "thin" },
    right: { style: "thin" },
  } as Partial<Borders>;

  sheet.getCell("A24").value = "SKU";
  sheet.getCell("A24").border = borderStyle;
  sheet.getCell("B24").value = "Qty";
  sheet.getCell("B24").border = borderStyle;
  sheet.mergeCells("C24:G24 ");
  sheet.getCell("C24").value = "Product Name";
  sheet.getCell("C24").border = borderStyle;
  sheet.getCell("H24").value = "Price";
  sheet.getCell("H24").border = borderStyle;
  sheet.getCell("I24").value = "Discount";
  sheet.getCell("I24").border = borderStyle;
  sheet.getCell("J24").value = "Ext price";
  sheet.getCell("J24").border = borderStyle;

  cart?.cartItemSku?.forEach((p, i) => {
    sheet.getCell(`A${i + 25}`).value = p?.itemSku?.sku;
    sheet.getCell(`A${i + 25}`).border = borderStyle;
    sheet.getCell(`B${i + 25}`).value = p?.quantity;
    sheet.getCell(`B${i + 25}`).border = {
      top: { style: "thin" },
      bottom: { style: "thin" },
      left: { style: "thin" },
      right: { style: "thin" },
    };
    sheet.mergeCells(`C${i + 25}:G${i + 25}`);
    sheet.getCell(`C${i + 25}`).value = p?.itemSku?.title;
    sheet.getCell(`C${i + 25}`).border = borderStyle;
    sheet.getCell(`H${i + 25}`).value = p?.itemSku?.price.toNumber();
    sheet.getCell(`H${i + 25}`).numFmt = "$0.00";
    sheet.getCell(`H${i + 25}`).border = borderStyle;
    sheet.getCell(`I${i + 25}`).value = `$${
      (p?.itemSku?.price ?? 0)
        .times(p?.quantity ?? 0)
        .minus(
          applyDiscountV2(
            p?.itemSku?.price.times(p?.quantity ?? 0),
            p?.discount
          ) ?? 0
        )
        .toFixed(2) ?? 0
    }`;
    sheet.getCell(`I${i + 25}`).border = borderStyle;
    sheet.getCell(`J${i + 25}`).value =
      applyDiscountV2(
        p!.itemSku!.price.times(p!.quantity),
        p?.discount
      )?.toNumber() ?? 0;
    sheet.getCell(`J${i + 25}`).numFmt = "$0.00";
    sheet.getCell(`J${i + 25}`).border = borderStyle;
  });
  const lastRow = 25 + (cart?.cartItemSku?.length ?? 0);

  sheet.getCell(`I${lastRow}`).value = "Subtotal";
  sheet.getCell(`J${lastRow}`).value = subtotal;
  sheet.getCell(`J${lastRow}`).numFmt = "$0.00";
  sheet.getCell(`I${lastRow}`).border = borderStyle;
  sheet.getCell(`J${lastRow}`).border = borderStyle;

  const taxLabel = cart?.customer?.taxable
    ? `${cart?.filial?.taxRate?.times(100) ?? 0}% Tax`
    : "0% Tax";
  sheet.getCell(`I${lastRow + 1}`).value = taxLabel;
  sheet.getCell(`J${lastRow + 1}`).value = tax;
  sheet.getCell(`J${lastRow + 1}`).numFmt = "$0.00";
  sheet.getCell(`I${lastRow + 1}`).border = borderStyle;
  sheet.getCell(`J${lastRow + 1}`).border = borderStyle;

  sheet.getCell(`I${lastRow + 2}`).value = "Total";
  sheet.getCell(`J${lastRow + 2}`).value = total;
  sheet.getCell(`J${lastRow + 2}`).numFmt = "$0.00";
  sheet.getCell(`I${lastRow + 2}`).border = borderStyle;
  sheet.getCell(`J${lastRow + 2}`).border = borderStyle;

  if (cart?.note) {
    sheet.getCell(`A${lastRow + 4}`).value = "Note:";
    sheet.getCell(`B${lastRow + 4}`).value = cart?.note;
  }

  const xlsx = await wb.xlsx.writeBuffer({
    // @ts-ignore
    base64: true,
  });
  saveAs(
    new Blob([xlsx], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    }),
    `pending_cart_${dayjs().format("YYYY-MM-DD")}`
  );
};
