import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  InputWrapper,
  SearchOutline,
  Separator,
  makeController,
  Combobox,
  ComboboxInput,
  ComboboxOptions,
  ComboboxOption,
  ChevronBackOutline,
  EllipsisHorizontal,
} from "#/components-ng/index.js";
import { DisplayTable, RouterPrompt } from "#/components/index.js";
import { useAuth } from "#/context/AuthContext.js";
import { cn } from "#/lib/utils.js";
import CreateItemForm from "#/scenes/Inventory/ItemList/Create.js";
import {
  CreateVariant,
  VariantOuput,
} from "#/scenes/Inventory/ItemList/Form/Variant/Create.js";
import { UpdateVariant } from "#/scenes/Inventory/ItemList/Form/Variant/Update.js";
import UpdateItemForm, {
  UpdateItemFormOutput,
} from "#/scenes/Inventory/ItemList/Update.js";
import { RouterOutputs, trpc } from "#/trpc.js";
import {
  ShippingInformationDialogContent,
  ShippingInformationFormSchema,
  shippingInformationFormSchema,
} from "./ShippingInformation.js";
import { zodResolver } from "@hookform/resolvers/zod";
import * as M from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import Decimal from "decimal.js";
import { atom, useAtom, useSetAtom } from "jotai";
import { Fragment, useState } from "react";
import { DefaultValues, FormProvider, SubmitHandler } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import { z } from "zod";
import { css } from "#/css/css";

export const showVariantFormPOAtom = atom<boolean>(false);

const createPoNewItemsFormSchema = z.object({
  dutyCost: z.number().catch(0).default(0),
  vendorId: z.string().nullish(),
  itemSkus: z.array(
    z.object({
      id: z.number(),
      defaultImage: z.string().nullish(),
      sku: z.number(),
      title: z.string(),
      presentation: z.string(),
      storeLocation: z.string().optional().nullable(),
      warehouseLocation: z.string().optional().nullable(),
      shippingQuantity: z.coerce.number(),
      cost: z.coerce.number(),
    }),
  ),
  shippingInformation: shippingInformationFormSchema,
});
export type CreatePoNewItemsFormSchema = z.TypeOf<
  typeof createPoNewItemsFormSchema
>;

const C = makeController<CreatePoNewItemsFormSchema>();

const SUBMIT_DRAFT_ID = "submit-draft";
const SUBMIT_CREATE_ID = "submit-create";

export type CreatePoNewItemsFormProps = {
  onSubmit: (
    status: "UPCOMING" | "DRAFT",
  ) => SubmitHandler<CreatePoNewItemsFormSchema>;
  defaultValues?: DefaultValues<CreatePoNewItemsFormSchema>;
};
export function CreatePoNewItemsForm(props: CreatePoNewItemsFormProps) {
  const [submitted, setSubmitted] = useState<boolean>(false);
  const form = C.useForm({
    resolver: zodResolver(createPoNewItemsFormSchema),
    defaultValues: {
      dutyCost: 0,
      vendorId: null,
      itemSkus: [],
      shippingInformation: {
        entries: [],
      },
      ...props.defaultValues,
    },
  });

  return (
    <FormProvider {...form}>
      <form
        onSubmit={(e) => {
          // get react form submitter
          const submitterId = (e.nativeEvent as any).submitter.id;
          if (submitterId === SUBMIT_CREATE_ID) {
            setSubmitted(true);
            form.handleSubmit(props.onSubmit("UPCOMING"))(e);
          } else if (submitterId === SUBMIT_DRAFT_ID) {
            setSubmitted(true);
            form.handleSubmit(props.onSubmit("DRAFT"))(e);
          } else {
            throw new Error("Unknown submitter");
          }
        }}
      >
        <RouterPrompt when={!submitted} />
        <div className="grid gap-y-8">
          <Header />
          <Main />
        </div>
      </form>
    </FormProvider>
  );
}

function Header(props: { className?: string }) {
  const { poId } = useParams();
  const { data, isLoading } = trpc.purchaseOrder.getNextId.useQuery(undefined, {
    enabled: !!poId,
  });
  const { data: vendors } = trpc.vendor.getAll.useQuery(undefined, {
    enabled: !!poId,
  });

  return (
    <div
      className={cn(
        "flex w-full items-center justify-between rounded bg-white px-5 py-3",
        props.className,
      )}
    >
      <div
        className={css({
          display: "flex",
          flex: 1,
          alignItems: "center",
          gap: "0.5rem",
        })}
      >
        <p>Purchase order: {poId ? poId : isLoading ? "" : `${data}`}</p>
        <Separator orientation="vertical" className="mx-4 h-12" />
        <C.M
          as={M.NumberInput}
          name="dutyCost"
          type="number"
          min={0}
          precision={2}
          label="Duty cost"
          placeholder="0.00"
          hideControls
          noNullish
          classNames={{
            root: "flex w-[18ch] flex-row items-center",
            label: "flex-1",
            wrapper: "flex-1",
          }}
        />
        <C.M
          as={M.Select}
          name="vendorId"
          label="Vendor"
          data={
            vendors?.map((vendor) => ({
              value: vendor.id.toString(),
              label: vendor.company ?? "",
            })) ?? []
          }
          clearable
          searchable
          classNames={{
            root: css({
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }),
            label: css({
              flex: 1,
              marginRight: "0.5rem",
            }),
            wrapper: css({
              flex: 1,
            }),
          }}
        />
      </div>
      <div>
        <ShippingInformationDialogButton />
      </div>
    </div>
  );
}

function ShippingInformationDialogButton() {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Fragment>
      <Button variant="primary" onClick={() => setIsOpen(true)}>
        See shipping information
      </Button>
      <M.Modal
        opened={isOpen}
        onClose={() => setIsOpen(false)}
        title={<M.Title order={4}>Shipping information</M.Title>}
        classNames={{
          header: "border-b border-slate-100 pb-1",
          content: "w-auto max-w-[550px]",
        }}
      >
        <ShippingInformationDialogWrapper close={() => setIsOpen(false)} />
      </M.Modal>
    </Fragment>
  );
}

function ShippingInformationDialogWrapper(props: { close: () => void }) {
  const form = C.useFormContext();
  function handleSubmit(values: ShippingInformationFormSchema) {
    form.setValue("shippingInformation", values);
    props.close();
  }
  return <ShippingInformationDialogContent onSubmit={handleSubmit} />;
}

function Main() {
  const [{ auth }] = useAuth();
  const navigate = useNavigate();
  const form = C.useFormContext();
  const itemSkusFieldArray = C.useFieldArray({
    name: "itemSkus",
    control: form.control,
    keyName: "$key",
  });
  const { totalCost } = useCostBreakdown();
  const [isCreateItemFormDialogOpen, setIsCreateItemFormDialogOpen] =
    useState(false);
  const [isUpdateItemFormDialogOpen, setIsUpdateItemFormDialogOpen] =
    useState(false);
  const [newItemId, setNewItemId] = useState<number | null>(null);
  const setShowVariantForm = useSetAtom(showVariantFormPOAtom);

  function onItemCreate(item: RouterOutputs["item"]["private"]["create"]) {
    setIsCreateItemFormDialogOpen(false);
    setNewItemId(item.id);
    setIsUpdateItemFormDialogOpen(true);
  }

  function onItemUpdate() {
    setIsUpdateItemFormDialogOpen(false);
  }

  function onVariantCreate(output: VariantOuput) {
    setIsCreateItemFormDialogOpen(false);
    setIsUpdateItemFormDialogOpen(false);
    setShowVariantForm(false);
    setNewItemId(null);

    const itemSku = output;
    itemSkusFieldArray.append({
      id: itemSku.id,
      defaultImage: itemSku.defaultImage,
      title: itemSku.title,
      sku: itemSku.sku,
      presentation: `${itemSku.presentationValue} ${itemSku.presentationType}`,
      storeLocation: `${
        itemSku?.itemSkuStock?.find(
          (is) => is.filialId === auth?.user?.filialId,
        )?.storeLocation ?? ""
      }`,
      warehouseLocation: `${
        itemSku?.itemSkuStock?.find(
          (is) => is.filialId === auth?.user?.filialId,
        )?.warehouseLocation ?? ""
      }`,
      shippingQuantity: 0,
      cost: itemSku.cost?.toNumber() ?? 0,
    });
  }

  return (
    <Fragment>
      <CreateItemFormDialog
        isOpen={isCreateItemFormDialogOpen}
        onOpenChange={setIsCreateItemFormDialogOpen}
        onItemCreate={onItemCreate}
      />
      <UpdateItemFormDialog
        isOpen={isUpdateItemFormDialogOpen}
        onOpenChange={setIsUpdateItemFormDialogOpen}
        onItemUpdate={onItemUpdate}
        itemId={newItemId}
        setItemId={setNewItemId}
        onVariantCreate={onVariantCreate}
      />
      <div className="rounded bg-white px-5 py-3">
        <div className="flex justify-between">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="primary">I want to</Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuItem
                onClick={() => setIsCreateItemFormDialogOpen(true)}
              >
                Create new item
              </DropdownMenuItem>
              <DropdownMenuItem
                onClick={() => setIsUpdateItemFormDialogOpen(true)}
              >
                Create new SKU on existing item
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
        <div className="mt-4">
          <MainTable />
        </div>
        <div className="mt-4 flex justify-end gap-x-3">
          <Button onClick={() => navigate("/inventory/upcoming-items")}>
            Cancel
          </Button>
          <Button type="submit" id={SUBMIT_DRAFT_ID}>
            Draft
          </Button>
          <Button
            type="submit"
            id={SUBMIT_CREATE_ID}
            variant="primary"
            className="px-10"
          >
            Create
          </Button>
          <div>
            <Accordion type="multiple">
              <AccordionItem value="total" className="border-none">
                <AccordionTrigger className="min-w-[25ch] rounded-md bg-green-400 px-4 py-2 text-base font-normal text-white hover:no-underline">
                  <div className="mr-2 grid flex-1 grid-cols-2">
                    <p className="text-left">Total</p>
                    <p className="text-right">${totalCost.toFixed(2)}</p>
                  </div>
                </AccordionTrigger>
                <AccordionContent className="px-4">
                  <CostBreakdownTable />
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

function MainTable() {
  const form = C.useFormContext();
  const itemSkus = C.useWatch({
    control: form.control,
    name: "itemSkus",
  });
  return <DisplayTable columns={columns} data={itemSkus} />;
}

function CostBreakdownTable() {
  const { totalCost, dutyCost, shippingTotalCost } = useCostBreakdown();
  return (
    <div className="grid grid-cols-2 [&>*]:my-1">
      <p>No. items received</p>
      <p className="text-right">0</p>
      <p>Total qty. received</p>
      <p className="text-right">0</p>
      <Separator className="col-span-2" />
      <p>Shipping cost</p>
      <p className="text-right">${shippingTotalCost.toFixed(2)}</p>
      <p>Duty cost</p>
      <p className="text-right">${dutyCost.toFixed(2)}</p>
      <Separator className="col-span-2" />
      <p>Total cost</p>
      <p className="text-right">${totalCost.toFixed(2)}</p>
    </div>
  );
}

type CreateItemFormDialogProps = {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  onItemCreate: (item: RouterOutputs["item"]["private"]["create"]) => void;
};
function CreateItemFormDialog(props: CreateItemFormDialogProps) {
  return (
    <M.Drawer
      opened={props.isOpen}
      onClose={() => props.onOpenChange(false)}
      title={<M.Title order={3}>Add new item</M.Title>}
      size="900px"
      classNames={{
        header: "border-b border-slate-100 pb-1",
      }}
      zIndex={40}
      closeOnClickOutside={false}
      closeOnEscape={false}
      position="right"
    >
      <M.Box py="1em">
        <CreateItemForm
          showTitle={false}
          redirectOnSuccess={false}
          onSuccess={props.onItemCreate}
        />
      </M.Box>
    </M.Drawer>
  );
}

type UpdateItemFormDialogProps = {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  onItemUpdate: (item: UpdateItemFormOutput) => void;
  onVariantCreate: (item: VariantOuput) => void;
  itemId?: number | null;
  setItemId?: (itemId: number) => void;
};

function UpdateItemFormDialog(props: UpdateItemFormDialogProps) {
  const [itemId, setItemId] = useState<number | null>(null);
  const [showVariantForm, setShowVariantForm] = useAtom(showVariantFormPOAtom);

  useEffect(() => {
    setItemId(props?.itemId ?? null);
  }, [props.itemId]);

  const [query, setQuery] = useState("");
  const itemSkuSearchQuery = trpc.itemSku.search.useQuery({
    query: `\\"${query}\\"`,
  });

  return showVariantForm ? (
    <M.Drawer
      position="right"
      opened={showVariantForm}
      title={<M.Title order={3}>Update item</M.Title>}
      size="auto"
      zIndex={40}
      classNames={{
        content: "w-[800px]",
      }}
      onClose={() => {
        setShowVariantForm(false);
        props.onOpenChange(false);
        setItemId(null);
      }}
      closeOnClickOutside={false}
      closeOnEscape={false}
    >
      <CreateVariant
        redirectOnSuccess={false}
        onSuccess={props.onVariantCreate}
        itemId={itemId}
      />
    </M.Drawer>
  ) : (
    <M.Drawer
      opened={props.isOpen}
      onClose={() => {
        props.onOpenChange(false);
        setItemId(null);
      }}
      size="900px"
      title={
        <div className="flex gap-x-4">
          <M.ActionIcon
            className={cn("hidden", itemId && "block")}
            onClick={() => {
              setItemId(null);
            }}
          >
            <ChevronBackOutline />
          </M.ActionIcon>
          <M.Title order={3}>
            {itemId ? "Update item" : "Search an item"}
          </M.Title>
        </div>
      }
      classNames={{
        header: "border-b border-slate-100 pb-1",
        body: cn(
          "max-h-[calc(100vh-2rem)] overflow-visible",
          itemId && "overflow-auto",
        ),
      }}
      zIndex={40}
      closeOnClickOutside={false}
      closeOnEscape={false}
      position="right"
    >
      <M.Box py="1em" w="100%">
        {itemId ? (
          <UpdateItemForm
            showTitle={false}
            redirectOnSuccess={false}
            onSuccess={props.onItemUpdate}
            itemId={itemId}
          />
        ) : (
          <div>
            <p className="mb-3">Write the name of the item to search</p>
            <Combobox
              onChange={(itemSku: RouterOutputs["itemSku"]["search"][0]) =>
                setItemId(itemSku.item.id)
              }
            >
              <InputWrapper leftIcon={<SearchOutline />}>
                <ComboboxInput
                  placeholder="Search..."
                  onChange={(e) => setQuery(e.currentTarget.value)}
                />
              </InputWrapper>
              <ComboboxOptions className="z-10">
                {itemSkuSearchQuery.data?.map((itemSku) => (
                  <ComboboxOption key={itemSku.id} value={itemSku}>
                    {itemSku.sku} {itemSku.title}
                  </ComboboxOption>
                ))}
              </ComboboxOptions>
            </Combobox>
          </div>
        )}
      </M.Box>
    </M.Drawer>
  );
}

function useCostBreakdown() {
  const form = C.useFormContext();
  const shippingInformation = C.useWatch({
    control: form.control,
    name: "shippingInformation",
  });
  const itemSkus = C.useWatch({
    control: form.control,
    name: "itemSkus",
  });
  const dutyCost = C.useWatch({
    control: form.control,
    name: "dutyCost",
  });

  const shippingTotalCost = shippingInformation.entries.reduce(
    (acc, cur) => acc.add(cur.cost),
    new Decimal(0),
  );
  const itemSkusTotalCost = itemSkus.reduce(
    (acc, cur) => acc.add(cur.cost),
    new Decimal(0),
  );

  const totalCost = shippingTotalCost.add(itemSkusTotalCost).add(dutyCost);
  return {
    totalCost,
    dutyCost,
    shippingTotalCost,
    itemSkusTotalCost,
  };
}

const columns = [
  {
    Header: "Actions",
    id: "actions",
    Cell: ({ row: { original, index } }) => {
      const [{ auth }] = useAuth();
      const form = C.useFormContext();
      const [opened, actions] = useDisclosure();

      const handleUpdate = (values: VariantOuput) => {
        actions.close();
        const itemSku = values;
        const currentItemSku = form.getValues("itemSkus")[index];
        form.setValue(`itemSkus.${index}`, {
          id: itemSku.id,
          defaultImage: itemSku.defaultImage,
          title: itemSku.title,
          sku: itemSku.sku,
          presentation: `${itemSku.presentationValue} ${itemSku.presentationType}`,
          storeLocation: `${
            itemSku?.itemSkuStock?.find(
              (is) => is.filialId === auth?.user?.filialId,
            )?.storeLocation ?? ""
          }`,
          warehouseLocation: `${
            itemSku?.itemSkuStock?.find(
              (is) => is.filialId === auth?.user?.filialId,
            )?.warehouseLocation ?? ""
          }`,
          shippingQuantity: currentItemSku.shippingQuantity,
          cost: itemSku.cost?.toNumber() ?? 0,
        });
      };

      return (
        <>
          <M.Drawer
            position="right"
            opened={opened}
            title={<M.Title order={3}>Update item</M.Title>}
            size="auto"
            zIndex={40}
            classNames={{
              content: "w-[800px]",
            }}
            onClose={actions.close}
            closeOnClickOutside={false}
            closeOnEscape={false}
          >
            <UpdateVariant
              itemSkuId={Number(original.id)}
              redirectOnSuccess={false}
              onSuccess={handleUpdate}
            />
          </M.Drawer>
          <M.Menu>
            <M.Menu.Target>
              <M.ActionIcon>
                <EllipsisHorizontal />
              </M.ActionIcon>
            </M.Menu.Target>
            <M.Menu.Dropdown>
              <M.Menu.Item onClick={actions.open}>Edit</M.Menu.Item>
            </M.Menu.Dropdown>
          </M.Menu>
        </>
      );
    },
  },
  {
    Header: "Image",
    accessor: "defaultImage",
  },
  {
    Header: "Sku",
    accessor: "sku",
  },
  {
    Header: "Title",
    accessor: "title",
  },
  {
    Header: "PKG",
    accessor: "presentation",
  },
  {
    Header: "Store Location",
    accessor: "storeLocation",
  },
  {
    Header: "Warehouse Location",
    accessor: "warehouseLocation",
  },
  {
    Header: "Shipping qty",
    accessor: "shippingQuantity",
    Cell: ({ row: { index } }) => {
      const name = `itemSkus.${index}.shippingQuantity` as const;
      return <C.M as={M.NumberInput} name={name} min={0} />;
    },
  },
  {
    Header: "Cost",
    accessor: "cost",
    isNumeric: true,
    Cell: "money",
  },
  {
    Header: "",
    id: "delete",
    Cell: ({ row: { index } }) => {
      const form = C.useFormContext();

      return (
        <M.CloseButton
          onClick={() => {
            form.setValue(
              "itemSkus",
              form.getValues("itemSkus").filter((_, i) => i !== index),
            );
          }}
        />
      );
    },
  },
];
