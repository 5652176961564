import { EcommerceOrdersCard } from "#/components-ng/icons.js";
import Card from "../../components/Card/index.js";
import CategoryGridLayout from "../../components/CategoryGridLayout/index.js";
import {
  ClipboardTimeIcon,
  MoonStarsIcon,
  OrderBowlIcon,
  PendingCartsIcon,
  CustomersIcon,
  ReturnIcon, // CampaignIcon,
} from "../../components/icons/index.jsx";
import { Stack, StackDivider } from "@chakra-ui/react";

const SalesScene = () => {
  return (
    <Stack spacing={6} divider={<StackDivider />}>
      <CategoryGridLayout category="Sales">
        <Card
          icon={<OrderBowlIcon size="big" />}
          text="Make a sale"
          path="/sales/make-a-sale"
          module="sales.makeASale"
        />
        <Card
          icon={<ReturnIcon size="big" />}
          text="Return"
          path="/sales/return"
          module="sales.return"
        />
        <Card
          icon={<PendingCartsIcon size="big" />}
          text="Pending carts"
          path="/sales/pending-carts"
          module="sales.pendingCarts"
        />
        <Card
          icon={<MoonStarsIcon size="big" />}
          text="End of day"
          path="/sales/end-of-day"
          module="sales.endOfDay"
        />
        <Card
          icon={<ClipboardTimeIcon size="big" />}
          text="Sale history"
          path="/sales/sales-history"
          module="sales.salesHistory"
        />
        <Card
          icon={<ReturnIcon size="big" />}
          text="Return History"
          path="/sales/return-history"
          module="sales.returnHistory"
        />
      </CategoryGridLayout>
      <CategoryGridLayout category="Modules">
        <Card
          icon={<CustomersIcon size="big" />}
          text="Customers"
          path="/customers/list"
          module="customers.customerList"
        />
        {/* <Card
          icon={<CampaignIcon size="big" />}
          text="Campaigns"
          path="/sales/campaigns/list"
        /> */}
        <Card
          icon={<EcommerceOrdersCard />}
          text="E-commerce orders"
          path="/sales/ecommerce-orders"
          module="sales.ecommerceOrders"
        />
      </CategoryGridLayout>
    </Stack>
  );
};

export default SalesScene;
