// @ts-nocheck
import { DetailsDrawer } from "./DetailsDrawer/index.js";
import { ItemListTable } from "./ItemListTable.js";
import { PrintInventoryLabel } from "./PrintInventoryLabel/index.js";
import { PrintLabelsModal } from "./PrintLabelsModal.js";
import { openExportProductsModal } from "./export-products.js";
import { Flex } from "@chakra-ui/react";
import * as M from "@mantine/core";
import { Provider, atom, useAtom } from "jotai";
import React from "react";
import { Link } from "react-router-dom";
import { ItemSku } from "server";
import ZebraBrowserPrintWrapper from "zebra-browser-print-wrapper";

export const selectedItemSkuIdAtom = atom<{
  id: number;
  sku: number;
  title: string;
} | null>(null);
export const selectedItemSkuToPrintAtom = atom<ItemSku | null>(null);
export const openModalAtom = atom(false);
export const openDetailsDrawerAtom = atom(false);

export default function ItemList() {
  const [browserPrint] = React.useState(() => new ZebraBrowserPrintWrapper());
  const [isLoadingPrinters, setIsLoadingPrinters] = React.useState(false);

  const [selectedItemSkuId, setSelectedItemSkuId] = useAtom(
    selectedItemSkuIdAtom
  );
  const [selectedItemSkuToPrint, setSelectedItemSkuToPrint] = useAtom(
    selectedItemSkuToPrintAtom
  );
  const [openModal, setOpenModal] = useAtom(openModalAtom);
  const [openModalInventoryLabel, setOpenModalInventoryLabel] =
    React.useState(false);
  const [openDetailsDrawer, setOpenDetailsDrawer] = useAtom(
    openDetailsDrawerAtom
  );

  React.useEffect(() => {
    async function setDefaultPrinter() {
      setIsLoadingPrinters(true);
      const availablePrinters = await browserPrint.getAvailablePrinters();
      browserPrint.setPrinter(availablePrinters[0]);
      setIsLoadingPrinters(false);
    }

    setDefaultPrinter();
  }, [browserPrint]);

  return (
    <>
      <Provider>
        <PrintLabelsModal
          printer={browserPrint}
          isLoadingPrinters={isLoadingPrinters}
          setSelectedItemSkuToPrint={setSelectedItemSkuToPrint}
          opened={openModal}
          setOpened={setOpenModal}
        />
      </Provider>
      <Provider key={selectedItemSkuToPrint?.id}>
        <PrintLabelsModal
          printer={browserPrint}
          isLoadingPrinters={isLoadingPrinters}
          defaultProduct={selectedItemSkuToPrint}
          setSelectedItemSkuToPrint={setSelectedItemSkuToPrint}
          opened={openModal}
          setOpened={setOpenModal}
        />
      </Provider>
      <PrintInventoryLabel
        printer={browserPrint}
        isLoadingPrinters={isLoadingPrinters}
        opened={openModalInventoryLabel}
        setOpened={setOpenModalInventoryLabel}
      />
      <Flex justify="space-between" gap={3}>
        <M.Group>
          <M.Menu>
            <M.Menu.Target>
              <M.Button>I want to:</M.Button>
            </M.Menu.Target>
            <M.Menu.Dropdown>
              <M.Menu.Item component={Link} to="create">
                Add product
              </M.Menu.Item>
              <M.Menu.Item onClick={() => setOpenModal(true)}>
                Print label
              </M.Menu.Item>
              <M.Menu.Item onClick={() => setOpenModalInventoryLabel(true)}>
                Print Inventory Label
              </M.Menu.Item>
              <M.Menu.Item component={Link} to="/inventory/adjust">
                Inventory Adjustment
              </M.Menu.Item>
              <M.Menu.Item onClick={openExportProductsModal}>
                Export products
              </M.Menu.Item>
              <M.Menu.Item /* component={Link} to="import" */ disabled>
                Import products
              </M.Menu.Item>
            </M.Menu.Dropdown>
          </M.Menu>
        </M.Group>
      </Flex>
      <div className="mt-6">
        <ItemListTable />
      </div>
      <DetailsDrawer
        opened={openDetailsDrawer}
        onClose={() => {
          setOpenDetailsDrawer(false);
          setSelectedItemSkuId(null);
        }}
        itemSkuId={selectedItemSkuId?.id}
        sku={selectedItemSkuId?.sku}
        title={selectedItemSkuId?.title}
      />
    </>
  );
}
