import { useAtomValue } from "jotai/react";
import { getDefaultStore } from "jotai/vanilla";
import { atom } from "jotai";
import { Changelog } from "./changelog";

export const changelogDialogAtom = atom<{
  open: boolean;
  changelog: Changelog | null;
}>({
  open: false,
  changelog: null,
});

export function useChangelogDialog() {
  return [
    useAtomValue(changelogDialogAtom, {
      store: getDefaultStore(),
    }),
    (opened: boolean) => {
      if (opened) {
        openChangelogDialog();
      } else {
        closeChangelogDialog();
      }
    },
  ] as const;
}

export function openChangelogDialog() {
  getDefaultStore().set(changelogDialogAtom, (prev) => ({
    ...prev,
    open: true,
  }));
}

export function closeChangelogDialog() {
  getDefaultStore().set(changelogDialogAtom, (prev) => ({
    ...prev,
    open: false,
  }));
}
