import { makeController } from "#/components-ng/index.js";
import { z } from "zod";

export const customAttributeTypes = [
  "TEXT",
  "FILE",
  "SELECT",
  "MULTISELECT_ITEM",
] as const;

export const createCustomAttributeSchema = z.object({
  name: z.string({
    required_error: "Name is required",
  }),
  type: z.enum(customAttributeTypes, {
    required_error: "Type is required",
  }),
  description: z.string().optional().nullish(),
  options: z.array(z.string()),
  textLimit: z.number().default(9),
  multiSelectItems: z.array(
    z.object({
      id: z.number(),
      sku: z.number(),
      title: z.string(),
      defaultImage: z.string().nullish(),
    }),
  ),
});

export type FormValues = z.infer<typeof createCustomAttributeSchema>;
export const C = makeController<FormValues>();
