import { RouterOutputs, trpc } from "#/trpc";
import { css } from "#/css/css";
import { styled } from "#/css/jsx";
import { Box, IconButton, Tabs } from "@radix-ui/themes";
import { Button, Dropdown } from "@gt/ui";
import {
  MantineReactTable,
  MRT_ColumnDef,
  useMantineReactTable,
} from "mantine-react-table";
import { ActionsMenuIcon } from "#/components-ng";
import { useDisclosure } from "@mantine/hooks";
import {
  CreateRouletteItem,
  UpdateRouletteItem,
} from "./roulette-item-form-drawer";
import { reportUserSuccess } from "#/util";
import { openConfirmModal } from "@mantine/modals";
import { openWinnersByRouletteItemIdModal } from "./winners-modal";

type RouletteItems = NonNullable<
  RouterOutputs["v2_5"]["roulette"]["getById"]
>["rouletteItems"];

interface RouletteItemsTableProps {
  rouletteId: number;
  rouletteItems: RouletteItems;
}

export const RouletteItemsSection = (props: RouletteItemsTableProps) => {
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
      {opened && (
        <CreateRouletteItem
          rouletteId={props.rouletteId}
          opened={opened}
          onClose={close}
        />
      )}
      <styled.div
        className={css({
          display: "flex",
          flexDirection: "column",
          width: "100%",
          gap: "20px",
        })}
      >
        <h3>Options</h3>
        <Tabs.Root defaultValue="active">
          <Tabs.List>
            <Tabs.Trigger value="active">Active</Tabs.Trigger>
            <Tabs.Trigger value="inactive">Inactive</Tabs.Trigger>
          </Tabs.List>
          <Box pt="3">
            <Tabs.Content
              value="active"
              className={css({
                marginTop: "20px",
              })}
            >
              <RouletteItemsActiveTable {...props} />
            </Tabs.Content>

            <Tabs.Content
              value="inactive"
              className={css({
                marginTop: "20px",
              })}
            >
              <RouletteItemsInactiveTable {...props} />
            </Tabs.Content>
          </Box>
        </Tabs.Root>
        <Button className={css({ width: "fit-content" })} onClick={open}>
          Add option
        </Button>
      </styled.div>
    </>
  );
};

const RouletteItemsActiveTable = (props: RouletteItemsTableProps) => {
  const data = useMemo(
    () => props.rouletteItems.filter((item) => item.active) ?? [],
    [props.rouletteItems],
  );

  const table = useMantineReactTable({
    columns,
    data: data ?? [],
  });

  return <MantineReactTable table={table} />;
};

const RouletteItemsInactiveTable = (props: RouletteItemsTableProps) => {
  const data = useMemo(
    () => props.rouletteItems.filter((item) => !item.active) ?? [],
    [props.rouletteItems],
  );

  const table = useMantineReactTable({
    columns,
    data: data ?? [],
  });

  return <MantineReactTable table={table} />;
};

type RouletteItem = RouletteItems[number];

const columns: MRT_ColumnDef<RouletteItem>[] = [
  {
    header: "Actions",
    id: "actions",
    enableSorting: false,
    enableColumnFilter: false,
    Cell: (table) => {
      const [opened, { open, close }] = useDisclosure(false);
      const original = table.row.original;
      const ctx = trpc.useContext();

      const { mutate: updateStatus } =
        trpc.v2_5.roulette.updateRoutetteItemStatus.useMutation({
          onSuccess() {
            ctx.v2_5.roulette.invalidate();
            reportUserSuccess({
              title: "Item status updated",
              message: "Item status has been updated successfully",
            });
          },
        });

      const openConfirmInactivateModal = () =>
        openConfirmModal({
          title: `Are you sure you want to ${
            original.active ? "inactivate" : "activate"
          } this item?`,
          labels: {
            cancel: "Cancel",
            confirm: original.active ? "Inactivate" : "Activate",
          },
          confirmProps: { color: "red" },
          onConfirm: () =>
            updateStatus({ id: original.id, active: !original.active }),
        });

      return (
        <>
          <Dropdown.Root>
            <Dropdown.Trigger>
              <styled.div
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <IconButton variant="ghost" color="gray" size="1">
                  <ActionsMenuIcon />
                </IconButton>
              </styled.div>
            </Dropdown.Trigger>
            <Dropdown.Content>
              <Dropdown.Item onClick={open}>Edit</Dropdown.Item>
              <Dropdown.Item onClick={openConfirmInactivateModal}>
                {original.active ? "Inactivate" : "Activate"}
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => openWinnersByRouletteItemIdModal(original.id)}
              >
                View winners
              </Dropdown.Item>
            </Dropdown.Content>
          </Dropdown.Root>
          {opened && (
            <UpdateRouletteItem
              opened={opened}
              onClose={close}
              rouletteId={original.rouletteId}
              rouletteItemId={original.id}
              defaultValues={{
                title: original.title ?? "",
                description: original.description,
                active: original.active,
                type: original.type,
                link: original.link,
                couponId: original?.couponId ? `${original.couponId}` : null,
                discountId: original.discountId
                  ? `${original.discountId}`
                  : null,
                itemSku: original?.itemSku
                  ? {
                      id: original.itemSku.id,
                      title: original.itemSku.title,
                      sku: original.itemSku.sku,
                      defaultImage: original.itemSku.defaultImage,
                    }
                  : null,
              }}
            />
          )}
        </>
      );
    },
  },
  {
    id: "title",
    header: "Title",
    accessorKey: "title",
  },
  {
    id: "description",
    header: "Description",
    accessorKey: "description",
  },
  {
    id: "type",
    header: "Type",
    accessorKey: "type",
  },
];
