import * as React from "react";
import { List, ListItem, Input, Flex } from "@chakra-ui/react";
import { useCombobox } from "downshift";
import PropTypes from "prop-types";

export default function Combobox({
  items,
  onInputChange,
  onChange,
  itemToString,
  manualFilter,
  inputProps,
  selectedItem,
}) {
  const [shownItems, setShownItems] = React.useState(items);

  React.useEffect(() => {
    setShownItems(items);
  }, [items]);

  const {
    isOpen,
    getComboboxProps,
    getInputProps,
    getMenuProps,
    getItemProps,
    highlightedIndex,
  } = useCombobox({
    itemToString,
    selectedItem,
    stateReducer: stateReducer,
    onSelectedItemChange: ({ selectedItem }) => onChange(selectedItem),
    onInputValueChange: ({ inputValue }) => {
      if (!manualFilter) {
        setShownItems(
          items.filter((item) =>
            itemToString(item)
              .toLowerCase()
              .startsWith(inputValue.toLowerCase())
          )
        );
      }
      onInputChange(inputValue);
    },
    items: shownItems,
  });
  return (
    <Flex flexDir="column" width="100%" position="relative">
      <Flex {...getComboboxProps()} flex="1" gap={2}>
        <Input {...getInputProps()} {...inputProps} />
      </Flex>
      <List
        w="100%"
        maxH="300px"
        overflowY="auto"
        position="absolute"
        bottom="0"
        transform="translateY(100%)"
        zIndex="popover"
        boxShadow="base"
        borderRadius="sm"
        {...getMenuProps()}
      >
        {isOpen &&
          shownItems.map((item, index) => (
            <ListItem
              padding={2}
              borderRadius="sm"
              fontWeight={selectedItem === item ? "bold" : ""}
              backgroundColor={
                highlightedIndex === index ? "blue.500" : "white"
              }
              key={index}
              {...getItemProps({ item, index })}
            >
              {itemToString(item)}
            </ListItem>
          ))}
      </List>
    </Flex>
  );
}

Combobox.defaultProps = {
  items: [],
  onChange: () => {},
  onInputChange: () => {},
  itemToString: (item) => (item ? String(item) : ""),
  manualFilter: false,
  inputProps: {},
};

Combobox.propTypes = {
  items: PropTypes.array,
  onChange: PropTypes.func,
  onInputChange: PropTypes.func,
  itemToString: PropTypes.func,
  manualFilter: PropTypes.bool,
  inputProps: PropTypes.object,
  selectedItem: PropTypes.any,
};

// We use this to reset the input value to the selected item on blur.
function stateReducer(state, actionAndChanges) {
  const { type, props, changes } = actionAndChanges;
  if (
    type === useCombobox.stateChangeTypes.InputBlur &&
    changes.selectedItem !== undefined
  ) {
    return {
      ...changes,
      inputValue: props.itemToString(changes.selectedItem),
    };
  }
  return changes;
}
