import GTLoader from "#/components-ng/loader"
import { css } from "#/css/css"
import { Flex } from "#/css/jsx"
import { Button } from "@gt/ui"
import { PackageDetails } from "./components"
import type { Order } from "./use-order"
import * as M from "@mantine/core"
import { getCountryCallingCode } from "libphonenumber-js"
import ClipboardIcon from "~icons/ion/clipboard-outline"
import CheckIcon from "~icons/ic/baseline-check"

interface ShippingInfoDrawerProps {
	open: boolean
	setOpen: (value: boolean) => void
	data?: {
		receiptNumber?: number | null
		customer?: Order["userBasicInfo"] | null
		orderShipping?: Order["orderShipping"] | null
		packages?: Order["packages"] | null
		orderItemSku?: Order["orderItemSku"] | null
		withInsurance?: boolean | null
		userBasicInfo?: Order["userBasicInfo"] | null
	} | null
	isLoading?: boolean
}

export const ShippingInfoDrawer = ({
	open,
	setOpen,
	data,
	isLoading,
}: ShippingInfoDrawerProps) => {
	const customer = data?.orderShipping?.firstName
		? {
				firstName: data.orderShipping.firstName,
				lastName: data.orderShipping.lastName,
			}
		: data?.customer
			? {
					firstName: data.customer.firstName,
					lastName: data.customer?.lastName,
				}
			: {
					firstName: data?.userBasicInfo?.firstName,
					lastName: data?.userBasicInfo?.lastName,
				}

	const stamps = `${customer?.firstName ?? ""} ${customer?.lastName ?? ""}
${data?.orderShipping?.addressLine1}
${data?.orderShipping?.apt && `Apt ${data?.orderShipping?.apt}\n`}${data?.orderShipping?.city}, ${data?.orderShipping?.state} ${data?.orderShipping?.zipCode}`

	return (
		<M.Drawer
			opened={open}
			onClose={() => setOpen(false)}
			size="lg"
			position="right"
			padding="md"
			zIndex={44}
		>
			{isLoading ? (
				<M.LoadingOverlay
					visible={isLoading}
					loader={<GTLoader width={100} height={100} />}
				/>
			) : (
				<>
					<M.Title order={3} mb="0.75rem" weight={600} color="#424242">
						Shipping information
					</M.Title>
					<M.Title order={5} mb="0.75rem" weight={600} color="#424242">
						Order #{data?.receiptNumber}
					</M.Title>
					<div
						className={css({
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							marginBottom: "0.75rem",
						})}
					>
						<M.Title order={5} weight={600} color="#424242">
							Address information
						</M.Title>
						<M.CopyButton value={stamps} timeout={2000}>
							{({ copied, copy }) => (
								<M.Tooltip
									label={copied ? "Copied" : "Copy"}
									withArrow
									position="right"
									withinPortal
								>
									<Button onClick={copy} variant="secondary">
										<div
											className={css({
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
												gap: 2,
											})}
										>
											Copy to Stamps
											{copied ? <CheckIcon /> : <ClipboardIcon />}
										</div>
									</Button>
								</M.Tooltip>
							)}
						</M.CopyButton>
					</div>
					<M.Stack mt="xl">
						<div className="flex flex-col items-start gap-2.5 self-stretch overflow-x-auto rounded-sm border border-[#E0E0E0] p-5">
							<InformationCardItem
								title="First name"
								value={data?.orderShipping?.firstName ?? ""}
								withDivider={false}
							/>
							<InformationCardItem
								title="Last name"
								value={data?.orderShipping?.lastName ?? ""}
								withDivider={true}
							/>
							<InformationCardItem
								title="Address"
								value={data?.orderShipping?.addressLine1 ?? ""}
								withDivider={true}
							/>
							<InformationCardItem
								title="City"
								value={data?.orderShipping?.city ?? ""}
								withDivider={true}
							/>
							<InformationCardItem
								title="State"
								value={data?.orderShipping?.state ?? ""}
								withDivider={true}
							/>
							<InformationCardItem
								title="Country"
								value={data?.orderShipping?.country ?? ""}
								withDivider={true}
							/>
							<InformationCardItem
								title="Zip code"
								value={data?.orderShipping?.zipCode ?? ""}
								withDivider={true}
							/>
							<InformationCardItem
								title="Apt"
								value={data?.orderShipping?.apt ?? ""}
								withDivider={true}
							/>
							<InformationCardItem
								title="PO Box"
								value={data?.orderShipping?.poBox ?? ""}
								withDivider={true}
							/>
							<PhoneNumberInformationCardItem
								phoneNumberCountryCode={
									data?.orderShipping?.phoneNumberCountryCode
								}
								phoneNumber={data?.orderShipping?.phoneNumber}
							/>
							<InformationCardItem
								title="Insured"
								value={data?.withInsurance ? "Yes" : "No"}
								withDivider={true}
							/>
							{(data?.orderShipping?.shippingCompany ||
								data?.orderShipping?.shippingNumber) && (
								<>
									<InformationCardItem
										title="Tracking Number"
										value={data?.orderShipping?.shippingNumber ?? ""}
										withDivider={true}
									/>
									<InformationCardItem
										title="Shipping company"
										value={data?.orderShipping?.shippingCompany ?? ""}
										withDivider={true}
									/>
								</>
							)}
						</div>
						<M.Title
							order={5}
							mt="0.75rem"
							mb="0.1rem"
							weight={600}
							color="#424242"
						>
							Packages
						</M.Title>
						{data?.packages?.length ? (
							<M.Accordion variant="separated">
								{data?.packages?.map((packageShipping, index) => (
									<PackageDetails
										package_={packageShipping}
										key={index}
										setOpened={setOpen}
										orderItemSkus={data?.orderItemSku ?? []}
									/>
								))}
							</M.Accordion>
						) : (
							<M.Text>No packages found</M.Text>
						)}
					</M.Stack>
				</>
			)}
		</M.Drawer>
	)
}

interface PhoneNumberInformationCardItemProps {
	phoneNumberCountryCode?: string | null
	phoneNumber?: string | null
}

function PhoneNumberInformationCardItem(
	props: PhoneNumberInformationCardItemProps,
) {
	let callingCode = ""
	const phoneNumber = props.phoneNumber ?? ""

	if (props.phoneNumberCountryCode != null) {
		try {
			callingCode =
				"+" + (getCountryCallingCode(props.phoneNumberCountryCode as any) ?? "")
			// eslint-disable-next-line no-empty
		} catch (e) {}
	}

	return (
		<InformationCardItem
			title="Phone number"
			value={`${callingCode} ${phoneNumber}`}
			withDivider={true}
		/>
	)
}

interface InformationCardItemProps {
	title: string
	value: string | number
	withDivider: boolean
}
const InformationCardItem = ({
	title,
	value,
	withDivider,
}: InformationCardItemProps) => {
	return (
		<>
			{withDivider && <div className="w-full border-t border-[#E0E0E0]" />}

			<div className="flex items-start justify-between self-stretch">
				<Flex gap={4}>
					<M.Text className="text-sm">{title}</M.Text>
				</Flex>
				<M.Text className="text-sm">{value}</M.Text>
			</div>
		</>
	)
}
