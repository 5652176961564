import GTLoader from "#/components-ng/loader.js";
import { trpc } from "#/trpc.js";
import { CardLayout } from "../components/index.js";
import { Badge } from "@chakra-ui/react";
import { ActionIcon, Group } from "@mantine/core";
import { useMemo } from "react";

interface VariantsCardProps {
  itemId: number;
  itemSkuId: number;
}

export const VariantsCard = ({ itemId, itemSkuId }: VariantsCardProps) => {
  const { data, isLoading } = trpc.item.getItemSkus.useQuery(
    {
      id: itemId!,
    },
    {
      cacheTime: 0,
      enabled: !!itemId,
    }
  );

  const variants = useMemo(() => {
    if (!data) return [];
    return data.filter((itemSku) => itemSku.id !== itemSkuId);
  }, [data, itemSkuId]);

  return (
    <CardLayout title="Variants">
      {isLoading ? (
        <GTLoader width={100} height={100} />
      ) : (
        <Group maw="100%">
          {variants.map((variant) => (
            <ActionIcon
              key={`${variant.id} - ${variant.sku}`}
              onClick={() =>
                window.open(`/inventory/item-list/edit/${variant.id}`, "_blank")
              }
            >
              <Badge
                variant="solid"
                fontSize="12px"
                fontWeight={400}
                textColor="#525252"
                borderRadius="xl"
                p="1.5"
                bg="#F0F0F0"
              >
                {variant.sku}
              </Badge>
            </ActionIcon>
          ))}
        </Group>
      )}
    </CardLayout>
  );
};
