import { cartEntriesAtom, cartEntriesReturnedAtom } from "./cart-entries.js";
import {
  customerAtom,
  helperAtom,
  salesAssociateAtom,
  associatedAtom,
} from "./customer.js";
import { filialAtom } from "./filial.js";
import { noteAtom } from "./note.js";
import { trpcAtom } from "./trpc.js";
import { CartEntry } from "./types.js";
import { atom } from "jotai";
import { nanoid } from "nanoid";
import { CartStatus, CartType } from "server";

type Cart = {
  customerId: number | undefined;
  associatedId: number | undefined;
  cartStatus: CartStatus;
  cartType: CartType;
  products: Array<CartEntry>;
  productsReturned: Array<CartEntry>;
  productsDamaged: Array<CartEntry>;
  note?: string;
};

export const cartAtom = atom<Cart>((get) => {
  const customer = get(customerAtom);
  const cartEntries = get(cartEntriesAtom);
  const cartEntriesReturned = get(cartEntriesReturnedAtom);
  const note = get(noteAtom);
  const filial = get(filialAtom);

  const cart: Cart = {
    customerId: customer?.id,
    associatedId: get(associatedAtom)?.id,
    products: cartEntries,
    productsReturned: cartEntriesReturned.filter(
      (entry) => entry.reason === "returned",
    ),
    productsDamaged: cartEntriesReturned.filter(
      (entry) => entry.reason === "damaged",
    ),
    cartStatus: "OPEN",
    cartType: filial?.type === "WAREHOUSE" ? "WAREHOUSE" : "IN_STORE",
    note: note,
  };

  return cart;
});

export const cartClosedAtom = atom<boolean>(false);
export const cartIdAtom = atom<number | null>(null);
export const saveCartAtom = atom(
  null,
  async (get, set, status?: CartStatus) => {
    const input = get(cartAtom);
    const cartId = get(cartIdAtom);
    const cartStatus = status ?? input.cartStatus;
    const trpc = get(trpcAtom);
    const associated = get(associatedAtom);
    const salesAssociate = get(salesAssociateAtom);
    const helper = get(helperAtom);
    const note = get(noteAtom);

    if (!input.customerId) return;
    if (cartId) {
      if (get(cartClosedAtom)) {
        return;
      }

      trpc.client.cart.update.mutate({
        id: cartId,
        input: {
          cartStatus: cartStatus,
          cartType: input.cartType,
          associated: salesAssociate?.id,
          helper: helper?.id,
          customer: input.customerId,
          filial: associated!.filialId,
          note: note ?? "",
        },
        cartItemsSkus: input.products.map((product) => ({
          quantity: product.quantity,
          itemSkuId: product.itemWithVariant.itemSku.id,
          discountId: product.discount?.id,
        })),
      });

      set(cartClosedAtom, status === "CLOSED");
    } else {
      const data = await trpc.client.cart.create.mutate({
        uuid: nanoid(128),
        filial: associated!.filialId,
        cartStatus: cartStatus,
        cartType: input.cartType,
        associated: input.associatedId,
        customer: input.customerId,
        cartItemSku: input.products.map((product) => ({
          quantity: product.quantity,
          itemSkuId: product.itemWithVariant.itemSku.id,
          discountId: product.discount?.id,
        })),
        note: note ?? "",
      });
      if (data) {
        set(cartIdAtom, data.id);
        set(cartClosedAtom, status === "CLOSED");
      }
    }
  },
);
