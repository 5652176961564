import { SpinnerIcon } from "#/components-ng";
import { cn } from "#/lib/utils";
import { ActionIcon, Tooltip } from "@mantine/core";
import TrashIcon from "~icons/ion/trash-outline";

type VideoProps = {
  src: string;
  isLoading?: boolean;
  alt?: string;
  deleteVideo: () => void;
};
export function VideoCard(props: VideoProps) {
  return (
    <div className="relative shrink-0">
      <video src={props.src} className="w-full object-cover" controls={true} />
      <div
        className={cn(
          "absolute inset-0 hidden h-full w-full place-items-center bg-black opacity-40",
          props.isLoading && "grid",
        )}
      >
        <SpinnerIcon className="animate-spin text-white" />
      </div>
      <Tooltip label="Delete">
        <ActionIcon
          className="absolute right-0 top-0 mr-2 mt-2 bg-transparent p-1 hover:bg-red-200"
          onClick={() => props.deleteVideo()}
          size={"lg"}
        >
          <TrashIcon className="text-red-500" />
        </ActionIcon>
      </Tooltip>
    </div>
  );
}
