// @ts-nocheck
import GTLoader from '#/components-ng/loader.js'
import CreateUserForm from '#/components/Form/complex/User/index.js'
import { MantineController } from '#/components/Form/v3/index.js'
import { trpc } from '#/trpc.js'
import { reportUserError, reportUserSuccess } from '#/util/index.js'
import * as M from '@mantine/core'
import dayjs from 'dayjs'
import { FormProvider, useForm } from 'react-hook-form'
import { Link, useParams, useNavigate } from 'react-router-dom'

const Form = ({ defaultValues }) => {
  const ctx = trpc.useContext()
  const { data: roles } = trpc.role.getAll.useQuery(undefined, {
    onError: (err) => {
      reportUserError({
        title: 'Failed to load roles',
        message: err.message,
      })
    },
  })

  const { data: filials } = trpc.filial.getAllWithOnlyRead.useQuery(undefined, {
    onError: (err) => {
      reportUserError({
        title: 'Failed to load filials',
        message: err.message,
      })
    },
  })

  const rolesData = useMemo(() => {
    return (
      roles?.map((r) => ({
        label: r.name,
        value: r.id as unknown as string,
      })) ?? []
    )
  }, [roles])

  const filialOptions = useMemo(
    () =>
      filials?.map((filial) => ({
        label: filial.name,
        value: filial.id.toString(),
      })) ?? [],
    [filials],
  )

  const [isAddressRequired, setIsAddressRequired] = useState(false)
  const navigate = useNavigate()

  const { mutate: updateUser, isLoading } = trpc.user.update.useMutation({
    onSuccess(data) {
      reportUserSuccess({
        title: 'User created successfully',
        message: (
          <span>
            User <span className="font-semibold">{data.firstName}</span> Updated
          </span>
        ),
      })
      ctx.user.invalidate()
      navigate('/settings/admin/users')
    },
    onError(err) {
      reportUserError({
        title: 'Failed to create user',
        message: err.message,
      })
    },
  })

  const form = useForm({
    defaultValues,
  })
  const handleSubmit = useCallback(
    (f) => {
      console.log(f)
      updateUser({
        id: defaultValues.id,
        input: {
          firstName: f.firstName,
          lastName: f.lastName,
          email: f.email,
          phoneNumber: f.phoneNumber,
          areaCode: f.areaCode,
          countryCode: f.countryCode,
          role: {
            connect: {
              id: f.role as unknown as number,
            },
          },
          birthDate:
            f.birthDate && dayjs(f.birthDate).isValid()
              ? dayjs(f.birthDate).toDate()
              : undefined,
          street: isAddressRequired ? f.address?.address : undefined,
          city: isAddressRequired ? f.address?.city : undefined,
          state: isAddressRequired ? f.address?.state : undefined,
          zip: isAddressRequired ? f.address?.zip : undefined,
          country: isAddressRequired ? f.address?.country : undefined,
          apt: isAddressRequired ? f.address?.apt : undefined,
          poBox: isAddressRequired ? f.address?.poBox : undefined,
          filial: f.filial
            ? {
                connect: {
                  id: Number(f.filial),
                },
              }
            : {
                disconnect: true,
              },
          verified: f.verified,
          taxable: f.taxable,
          note: f.note,
          company: f.company ?? null,
          nickName: f.nickName ?? null,
          avatar: f.avatar ?? null,
          taxDocument: f.taxDocument ?? null,
          ein: f.ein ?? null,
          bio: f.bio ?? null,
          suspended: f.active === false,
        },
      })
    },
    [defaultValues.id, isAddressRequired, updateUser],
  )

  return (
    <M.Container>
      <M.Box
        component="form"
        onSubmit={form.handleSubmit(handleSubmit)}
        sx={{ width: '100%' }}
      >
        <FormProvider {...form}>
          <M.Group position="apart" my={12}>
            <M.Title order={2}>Edit user</M.Title>
            <M.Group>
              <MantineController
                as={M.Select}
                name="filial"
                label="Filial"
                size="sm"
                data={filialOptions}
                clearable
              />
              <MantineController
                as={M.Select}
                name="role"
                label="Role"
                required
                size="sm"
                data={rolesData}
              />
            </M.Group>
          </M.Group>
          <M.Box
            p={12}
            sx={(t) => ({ background: 'white', borderRadius: t.radius.sm })}
          >
            <CreateUserForm
              onAddressRequiredChange={setIsAddressRequired}
              isSecuritySectionDisabled={false}
            />
            <M.Group spacing={15} align="end" sx={{ width: 'auto' }}>
              <M.Button component={Link} to="/settings/users" color="gray.4">
                Cancel
              </M.Button>
              <M.Button
                mt="md"
                type="submit"
                sx={{ width: '15ch' }}
                loading={isLoading}
              >
                Save
              </M.Button>
            </M.Group>
          </M.Box>
        </FormProvider>
      </M.Box>
    </M.Container>
  )
}

export const EditUser = () => {
  const { id } = useParams()
  const {
    data: userData,
    isLoading,
    isFetchedAfterMount,
  } = trpc.user.getOneById.useQuery(
    {
      id: Number(id),
    },
    {
      onError: (err) => {
        reportUserError({
          title: 'Failed to load user',
          message: err.message,
        })
      },
    },
  )

  const defaultValues = useMemo(() => {
    if (!isLoading && userData) {
      return {
        ...userData,
        address: {
          address: userData?.street,
          city: userData?.city,
          country: userData?.country,
          poBox: userData?.poBox,
          zip: userData?.zip,
          apt: userData?.apt,
          state: userData?.state,
        },
        role: userData?.roleId,
        birthDate:
          userData?.birthDate && dayjs(userData.birthDate).isValid()
            ? dayjs(userData.birthDate).toISOString().slice(0, 10)
            : undefined,
        filial: userData?.filialId ? userData?.filialId.toString() : undefined,
        active: userData?.suspended === false,
      }
    }
  }, [isLoading, userData])

  return isLoading || !isFetchedAfterMount ? (
    <M.LoadingOverlay
      visible={isLoading}
      loader={<GTLoader width={100} height={100} />}
    />
  ) : (
    <Form defaultValues={defaultValues} />
  )
}
