import { DisplayTable } from "#/components/index.js";
import { useAuth } from "#/context/AuthContext.js";
import { trpc } from "#/trpc.js";
import { reportUserError } from "#/util/index.js";
import { printEndOfDayEpson } from "./PrintEpson.js";
import { exportEndOfDayToXLSX } from "./exportXlsx.js";
import * as M from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { openConfirmModal } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import dayjs from "dayjs";
import React from "react";
import { MdMoreHoriz } from "react-icons/md/index.js";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import EyeIcon from "~icons/ion/eye-outline";

export const EndOfDay = () => {
  const [{ auth }] = useAuth();
  const [type, setType] = React.useState<"Today" | "Yesterday" | "Custom date">(
    "Today"
  );
  const [dateRange, setDateRange] = React.useState<[Date | null, Date | null]>([
    dayjs().subtract(1, "month").toDate(),
    new Date(),
  ]);
  const [filialId, setFilialId] = React.useState<number | null>(null);

  const openConfirmModal_ = useGenerateEndOfDayConfirmation(
    dateRange,
    type,
    auth?.user?.filialId ?? filialId!
  );

  const { data } = trpc.filial.getAll.useQuery();
  const filials = React.useMemo(
    () =>
      data?.map((filial) => ({
        label: filial.name,
        value: filial.id.toString(),
      })) ?? [],
    [data]
  );

  return (
    <>
      <M.Title order={3}>Generate End of day</M.Title>
      <M.Divider mt="md" mb={50} />
      <M.Group position="center">
        <M.Stack
          spacing="lg"
          sx={{
            width: "100%",
            maxWidth: "300px",
            backgroundColor: "white",
            borderRadius: 4,
            border: "1px solid #eaeaea",
          }}
          px={10}
          py={10}
          mt="xl"
        >
          <M.Title order={5} ml={2} mt="sm">
            End of day from
          </M.Title>
          <M.Divider mt="sm" mb="md" />
          {!auth?.user?.filialId && (
            <M.Select
              label="Filial"
              data={filials}
              onChange={(e) => e && setFilialId(Number(e))}
              required={!auth?.user?.filialId}
            />
          )}
          <M.Select
            label="Date"
            required
            value={type}
            onChange={(e) => setType(e as any)}
            data={[
              { label: "Today", value: "Today" },
              { label: "Yesterday", value: "Yesterday" },
              { label: "Custom date", value: "Custom date" },
            ]}
          />
          {type === "Custom date" && (
            <DatePickerInput
              value={dateRange}
              type="range"
              clearable={false}
              numberOfColumns={1}
              popoverProps={{
                withinPortal: false,
                zIndex: 9999,
              }}
              onChange={(value) => setDateRange(value)}
              allowSingleDateInRange
              required={type === "Custom date"}
              sx={{
                marginTop: 10,
              }}
            />
          )}
          <M.Button
            mt="lg"
            fullWidth
            onClick={() => {
              if (!auth?.user?.filialId && !filialId) {
                return reportUserError({
                  title: "Failed to generate end of day",
                  message: "Filial is required",
                });
              }

              openConfirmModal_();
            }}
          >
            Generate end of day
          </M.Button>
        </M.Stack>

        <M.Stack
          spacing="lg"
          sx={{
            width: "800px",
            maxWidth: "100%",
            backgroundColor: "white",
            borderRadius: 4,
            border: "1px solid #eaeaea",
          }}
          px={10}
          py={10}
          mx={10}
        >
          <Table />
        </M.Stack>
      </M.Group>
    </>
  );
};

const Table = () => {
  const { data, isLoading } = trpc.report.getLatestEndOfDayCache.useQuery(
    undefined,
    {
      onError(error) {
        reportUserError({
          title: "Failed to get ends of days",
          message: error.message,
        });
      },
    }
  );

  const tableData = React.useMemo(() => data ?? [], [data]);

  return (
    <>
      <M.Title order={5} ml={2} mt="sm">
        Last generated end of days
      </M.Title>
      <M.Divider mt="sm" />
      {tableData.length === 0 ? (
        <M.Box mt="sm" p={5} mb={2}>
          <M.Text size={"sm"}>
            After generating end of days, you will be able to to view the
            previous end of days
          </M.Text>
        </M.Box>
      ) : (
        <M.Box className="max-h-96 overflow-x-auto">
          <DisplayTable
            columns={columns}
            data={tableData}
            isLoading={isLoading}
          />
        </M.Box>
      )}
    </>
  );
};

const useGenerateEndOfDayConfirmation = (
  dateRange: [Date | null, Date | null],
  type: "Today" | "Custom date" | "Yesterday",
  filialId: number
) => {
  const navigate = useNavigate();

  const { mutate: generate } = trpc.report.getEndOfDay.useMutation({
    onError(error) {
      reportUserError({
        title: "Failed to generate end of day",
        message: error.message,
      });
    },
    onSuccess(data) {
      navigate(`generate/${data.id}`);
    },
  });

  return () =>
    openConfirmModal({
      title: "Are you sure you want to generate end of day?",
      labels: { confirm: "Confirm", cancel: "Cancel" },
      onConfirm: () =>
        generate({
          start:
            type === "Today"
              ? new Date()
              : type === "Yesterday"
              ? dayjs().subtract(1, "day").toDate()
              : (dateRange[0] as Date),
          end:
            type === "Today"
              ? new Date()
              : type === "Yesterday"
              ? dayjs().subtract(1, "day").toDate()
              : (dateRange[1] as Date),
          filialId: filialId,
        }),
    });
};

const columns = [
  {
    Header: "Actions",
    id: "actions",
    Cell: ({ row: { original } }) => {
      const handlePrintEpsonTicket = async () => {
        try {
          await printEndOfDayEpson(original);
        } catch (e) {
          showNotification({
            title: "Failed to print end of day",
            message: e.message,
            color: "red",
          });
        }
      };

      const handleExportXlsx = async () => {
        try {
          await exportEndOfDayToXLSX(original);
        } catch (e) {
          showNotification({
            title: "Failed to export end of day",
            message: e.message,
            color: "red",
          });
        }
      };

      return (
        <M.Group spacing="xs">
          <M.Menu>
            <M.Menu.Target>
              <M.ActionIcon>
                <MdMoreHoriz />
              </M.ActionIcon>
            </M.Menu.Target>
            <M.Menu.Dropdown>
              <M.Menu.Item
                component={Link}
                to={`/sales/end-of-day/print/${original.id}`}
                target="_blank"
              >
                Print PDF
              </M.Menu.Item>
              <M.Menu.Item
                component={Link}
                to={`/sales/end-of-day/print/${original.id}/preview`}
                target="_blank"
              >
                Preview PDF
              </M.Menu.Item>
              <M.Menu.Item onClick={handlePrintEpsonTicket}>
                Print epson ticket
              </M.Menu.Item>
              <M.Menu.Item onClick={handleExportXlsx}>
                Export to XLSX
              </M.Menu.Item>
            </M.Menu.Dropdown>
          </M.Menu>
          <M.ActionIcon component={Link} to={`generate/${original.id}`}>
            <EyeIcon />
          </M.ActionIcon>
        </M.Group>
      );
    },
  },
  {
    Header: "From",
    accessor: "from",
    isEpochDate: true,
    Cell: "dateFromEpoch",
  },
  {
    Header: "To",
    accessor: "to",
    isEpochDate: true,
    Cell: "dateFromEpoch",
  },
  {
    Header: "Inv. movement",
    accessor: "inventoryMovement.count",
  },
  {
    Header: "Orders (count)",
    accessor: "ordersHistory.orderCount",
  },
];
