import { Button } from "#/components-ng/button.js";
import { SpinnerIcon } from "#/components-ng/icons.js";
import GTLoader from "#/components-ng/loader.js";
import { Separator } from "#/components-ng/separator.js";
import { trpc } from "#/trpc.js";
import { showRoomTypes } from "../../state.js";
import { VariantFormProps } from "../index.js";
import { C, createItemSkuSchema, presentationTypes } from "../types.js";
import { ItemSkuCustomAttributeSection } from "./ItemSkuCustomAttributeSection.js";
import { VendorSection } from "./VendorSection.js";
import { zodResolver } from "@hookform/resolvers/zod";
import { Group, LoadingOverlay, Select } from "@mantine/core";
import { FormProvider } from "react-hook-form";
import { useNavigate, useParams } from "react-router";

export const VariantInformation = (props: VariantFormProps) => {
  const [showRoomType, setShowRoomType] = useAtom(showRoomTypes);
  const navigate = useNavigate();
  const params = useParams();

  const form = C.useForm({
    resolver: zodResolver(createItemSkuSchema),
    shouldUnregister: false,
    defaultValues: {
      title: "",
      sku: 0,
      weight: 0,
      presentationValue: 0,
      presentationType: "PC",
      width: 0,
      height: 0,
      length: 0,
      size: "",
      price: 0,
      unitPrice: 0,
      cost: 0,
      images: [],
      available: true,
      note: "",
      vendor: {
        id: null,
        company: null,
      },
      vendorAlu: "",
      vendorCostType: "BY_GRAM",
      vendorCost: 0,
      vendorImage: null,
      minPurchase: 1,
      ...props.defaultValues,
    },
  });

  const nextSkuRes = trpc.itemSku.getNextSKU.useQuery(undefined, {
    cacheTime: 0,
    onSuccess(data) {
      if (!params?.itemSkuId && !props.itemSkuId) {
        form.setValue("sku", data);
      }
    },
  });

  if (nextSkuRes.isLoading) {
    return (
      <LoadingOverlay
        visible={nextSkuRes.isLoading}
        loader={<GTLoader width={100} height={100} />}
      />
    );
  }

  return (
    <div>
      <Separator className="mb-8" />
      <FormProvider {...form}>
        <form
          onSubmit={(e) => {
            e.stopPropagation();
            form.handleSubmit(props.onSubmit)(e);
          }}
        >
          <div className="mb-8 grid grid-cols-2 gap-8">
            <C.InputField
              label="SKU"
              name="sku"
              readOnly
              className="cursor-default bg-slate-100"
            />
            <C.InputField
              label="Title *"
              name="title"
              required
              placeholder="Enter title"
            />
            <C.InputField label="Package *" name="presentationValue" required />
            <C.SelectField
              label="Unit *"
              placeholder="Select unit"
              name="presentationType"
              data={presentationTypes.map((pt) => ({
                id: pt,
                label: pt,
              }))}
              required
            />
            <C.InputField name="width" label="Width (mm)" type="number" />
            <C.InputField name="height" label="Height (mm)" type="number" />
            <C.InputField name="length" label="Length (mm)" type="number" />
            <C.InputField name="size" label="Size *" required/>
            <C.InputField name="weight" label="Weight (g) *" type="number" required/>
            <Select
              value={showRoomType}
              data={[
                { value: "PUBLIC", label: "Public" },
                { value: "EXCLUSIVE", label: "Exclusive" },
                { value: "CUSTOM", label: "Custom" },
              ]}
              classNames={{
                input:
                  "w-full rounded-md border border-slate-200 bg-white px-3 text-sm placeholder:text-slate-400",
                wrapper: "w-full",
                rightSection: "hidden",
                label: "text-sm font-medium text-slate-500",
              }}
              label="Variant type"
              onChange={(e) => {
                e && setShowRoomType(e as any);
              }}
            />
            {showRoomType === "EXCLUSIVE" ? (
              <C.InputField
                name="minPurchase"
                label="Min. Purchase *"
                type="number"
                required={showRoomType === "EXCLUSIVE"}
              />
            ) : undefined}
            <C.SwitchField name="available" label="Active" />
          </div>
          <C.TextareaField label="Note" name="note" placeholder="Enter note" />
          {showRoomType === "CUSTOM" && (
            <ItemSkuCustomAttributeSection itemSkuId={props?.itemSkuId} />
          )}
          <Separator className="my-12" />
          <VendorSection />
          <Group spacing={8} noWrap mt={5}>
            <Button
              variant="primary"
              type="submit"
              className="mt-8 px-12"
              disabled={props.isLoading}
            >
              Save variant {props.isLoading && <SpinnerIcon />}
            </Button>
            <Button
              className="mt-8 px-12"
              onClick={() => {
                navigate(`/inventory/item-list/edit/${params.id}`);
              }}
            >
              Cancel
            </Button>
          </Group>
        </form>
      </FormProvider>
    </div>
  );
};
