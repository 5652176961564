import { makeController } from "#/components-ng/index.js";
import { z } from "zod";

export const textTemplates = [
  "template1",
  "template2",
  "template3",
  "template4",
] as const;
export const pages = ["HOME"] as const;
export const buttonVariants = ["FILLED", "OUTLINE"] as const;
export const positions = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
] as const;

export const createBannerSchema = z.object({
  title: z.string({
    required_error: "Title is required",
  }),
  template: z.enum(textTemplates, {
    required_error: "Template is required",
  }),
  position: z.enum(positions, {
    required_error: "Position is required",
  }),
  page: z.enum(pages),
  dateRange: z.array(z.date()),
  withButton: z.boolean().default(false),
  buttonHexColor: z.string().nullish().optional(),
  buttonVariant: z.enum(buttonVariants).nullish().optional(),
  buttonText: z.string().nullish().optional(),
  imageDesktop: z
    .string({
      required_error: "Desktop image is required",
    })
    .nonempty(),
  imageMobile: z
    .string({
      required_error: "Mobile image is required",
    })
    .nonempty(),
  redirectTo: z
    .string({
      required_error: "Redirect to is required",
    })
    .nonempty(),
  content: z.array(
    z.object({
      content: z.string({
        required_error: "Content is required",
      }),
      colorHex: z.string({
        required_error: "Color is required",
      }),
      position: z.enum(positions),
    })
  ),
});

export type FormValues = z.infer<typeof createBannerSchema>;
export const C = makeController<FormValues>();
