import { makeController } from "#/components-ng";
import { useAuth } from "#/context/AuthContext";
import { HStack, VStack, styled } from "#/css/jsx";
import { printTicketFromRefundV2 } from "#/modules/ticket/print";
import { RouterOutputs, trpc } from "#/trpc";
import { reportUserError } from "#/util";
import { CustomerPanel } from "./_customer-panel";
import {
  calculateMaxCreditsOrderToRefund,
  calculateMaxStripeOrderToRefund as calculateMaxPaymentsProviderOrderToRefund,
  calculateMaxTotalItemsToRefund,
} from "./refund-helper";
import { useOrder } from "./use-order";
import { Table } from "@gt/ui";
import { zodResolver } from "@hookform/resolvers/zod";
import * as M from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import Decimal from "decimal.js";
import { nanoid } from "nanoid";
import { Controller, FormProvider, useWatch } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { z } from "zod";

const RefundMethods = {
  SQUARE: "Square",
  STRIPE: "Stripe",
  CREDITS_AND_STRIPE: "Credits and stripe",
  PAYPAL: "PayPal",
  CREDITS_AND_PAYPAL: "Credits and PayPal",
  CREDITS: "Credits",
} as const;

const RefundMethodsSelectData = Object.entries(RefundMethods).map(
  ([key, val]) => ({
    label: val,
    value: key,
    disabled: false,
  }),
);

const RefundReasons = {
  DAMAGED: "Damaged",
  RETURN: "Return",
  EXCHANGE: "Exchange",
  WRONG_ITEM: "Wrong Item",
} as const;

type RefundReason = keyof typeof RefundReasons;

const RefundReasonsSelectData = Object.entries(RefundReasons).map(
  ([key, val]) => ({
    label: val,
    value: key,
  }),
);

const PrintSelectData = [
  { label: "Don't print", value: "NO" },
  { label: "Print with location", value: "WITH_LOCATION" },
  { label: "Print without location", value: "WITHOUT_LOCATION" },
];

const RefundFormSchema = z.object({
  // `CREDITS_AND_STRIPE` is used when you can refund to multiple payment methods, e.g. credits
  // and stripe
  method: z.enum([
    "SQUARE",
    "STRIPE",
    "CREDITS_AND_STRIPE",
    "PAYPAL",
    "CREDITS_AND_PAYPAL",
    "CREDITS",
  ]),
  print: z.enum(["NO", "WITH_LOCATION", "WITHOUT_LOCATION"]),
  items: z.array(
    z.object({
      orderItemSkuId: z.number(),
      quantity: z.number(),
      reason: z.enum(["DAMAGED", "RETURN", "EXCHANGE", "WRONG_ITEM"]),
    }),
  ),
  mixedMethod: z
    .object({
      credits: z.number().optional(),
      stripe: z.number().optional(),
      paypal: z.number().optional(),
    })
    .nullish(),
});

type RefundFormSchema = z.input<typeof RefundFormSchema>;
type RefundFormOutput = z.output<typeof RefundFormSchema>;

const { useForm, useFormContext } = makeController<RefundFormSchema>();

export function EcommerceOrderRefund() {
  const [credits, setCredits] = useState<number>(0);
  const [stripe, setStripe] = useState<number>(0);
  const [paypal, setPaypal] = useState<number>(0);

  const { data: order } = useOrder();
  const location = useLocation();
  const routeState = location.state.items as Record<number, any> | undefined;
  // ^ The array of orderItemSkus gets transformed to an object like so:
  // `{ 1: {...}, 2: {...}}`

  const itemsToRefundIds = useMemo(
    () => (routeState ? Object.values(routeState).map((o) => o.id) : []),
    [routeState],
  );

  const itemsToRefund = useMemo(
    () =>
      order?.orderItemSku
        .filter((oiSku) => itemsToRefundIds.includes(oiSku.id))
        .map(
          (o) =>
            ({
              orderItemSkuId: o.id,
              quantity: 0,
              reason: "RETURN",
              previousRefund: o.refundOrderItemSku,
            }) as const,
        ) ?? [],
    [itemsToRefundIds, order?.orderItemSku],
  );

  const form = useForm({
    resolver: zodResolver(RefundFormSchema),
    values: {
      method:
        !order?.customer && order?.squareOrderId
          ? "SQUARE"
          : !order?.customer && order?.stripeSessionId
            ? "STRIPE"
            : !order?.customer && order?.paypalOrderId
              ? "PAYPAL"
              : (order?.orderPayment.length ?? 0) > 1 && order?.paypalOrderId
                ? "CREDITS_AND_PAYPAL"
                : (order?.orderPayment.length ?? 0) > 1 &&
                    order?.stripeSessionId
                  ? "CREDITS_AND_STRIPE"
                  : "CREDITS",
      items: itemsToRefund,
      print: "WITH_LOCATION",
    },
    resetOptions: {
      keepDirtyValues: true,
    },
  });

  const navigate = useNavigate();
  const params = useParams();

  const orderPreviewUrl = `/sales/ecommerce-orders/${params.id}`;
  function goBackToOrderPreview() {
    navigate(orderPreviewUrl);
  }

  const [{ auth }] = useAuth();
  const { mutateAsync } = trpc.v2_5.orderReturn.return.useMutation();
  const [idempotencyKey] = useState(() => nanoid());
  function handleSubmit(values: RefundFormOutput) {
    if (!order) {
      reportUserError({
        title: "Order did not load",
        message: "Try reloading the page or try again in a few seconds.",
      });
      return;
    }
    openConfirmModal({
      title: "Are you sure you want to refund these items?",
      labels: {
        confirm: "Refund",
        cancel: "Cancel",
      },
      confirmProps: {
        color: "red",
      },
      onConfirm: async () => {
        const mixedRefund =
          values.method === "CREDITS_AND_STRIPE" ||
          values.method === "CREDITS_AND_PAYPAL"
            ? {
                credits: credits,
                stripe: stripe,
                paypal: paypal,
              }
            : undefined;

        const refundedOrderItemSkus = await mutateAsync({
          id: order.id,
          idempotencyKey,
          method: values.method,
          orderItemSkuIds: values.items.map((i) => ({
            id: i.orderItemSkuId,
            quantity: i.quantity,
            reason: i.reason,
          })),
          mixedRefund: mixedRefund,
        });
        if (values.print !== "NO") {
          printTicketFromRefundV2({
            refunds: refundedOrderItemSkus,
            filialId: auth.user.filialId!,
            withLocation: values.print === "WITH_LOCATION",
          });
        }
        goBackToOrderPreview();
      },
    });
  }

  // We can only refund mixed if the user paid with credits and stripe
  const refundMethod = form.watch("method");
  const canOnlyRefundMixed = (order?.orderPayment.length ?? 0) > 1;
  const isRefundMixedMethod =
    refundMethod === "CREDITS_AND_STRIPE" ||
    refundMethod === "CREDITS_AND_PAYPAL";
  const currentCreditsUsage = form.watch("mixedMethod.credits") ?? 0;
  const currentStripeUsage = form.watch("mixedMethod.stripe") ?? 0;
  const currentPaypalUsage = form.watch("mixedMethod.paypal") ?? 0;

  const itemsForm = form.watch("items");

  const maxTotalItemsToRefund = calculateMaxTotalItemsToRefund({
    itemsForm,
    orderItemSkus: order?.orderItemSku ?? [],
  });

  const maxPaymentsProvider = useMemo(() => {
    return calculateMaxPaymentsProviderOrderToRefund({
      currentMethodUsage: currentCreditsUsage,
      itemToRefunds: itemsToRefund,
      orderPayments: order?.orderPayment ?? [],
    });
  }, [currentCreditsUsage, itemsToRefund, order?.orderPayment]);

  const maxOrderCredits = useMemo(() => {
    return calculateMaxCreditsOrderToRefund({
      currentMethodUsage:
        refundMethod === "CREDITS_AND_STRIPE"
          ? currentStripeUsage
          : refundMethod === "CREDITS_AND_PAYPAL"
            ? currentPaypalUsage
            : 0,
      itemToRefunds: itemsToRefund,
      orderPayments: order?.orderPayment ?? [],
    });
  }, [
    currentPaypalUsage,
    currentStripeUsage,
    itemsToRefund,
    order?.orderPayment,
    refundMethod,
  ]);

  const creditsInputMax = Decimal.min(maxOrderCredits, maxTotalItemsToRefund);
  const paymentsProviderInputMax = Decimal.min(
    maxPaymentsProvider,
    maxTotalItemsToRefund,
  );

  if (!routeState) {
    goBackToOrderPreview();
    return null;
  }

  return (
    <div className="grid gap-y-6">
      <div className="rounded-md bg-white px-6 py-4">
        <CustomerPanel order={order} />
      </div>
      <form
        className="bg-white px-6 py-4"
        onSubmit={form.handleSubmit(handleSubmit)}
      >
        <FormProvider {...form}>
          <div className="my-4 flex gap-x-5">
            <Controller
              control={form.control}
              name="method"
              render={(c) => (
                <M.Select
                  data={RefundMethodsSelectData.map((method) => ({
                    ...method,
                    disabled:
                      (!order?.customer && method.value === "CREDITS") ||
                      (order?.stripeSessionId == null &&
                        (method.value === "STRIPE" ||
                          method.value === "CREDITS_AND_STRIPE")) ||
                      (order?.squareOrderId == null &&
                        method.value === "SQUARE") ||
                      (order?.paypalOrderId == null &&
                        (method.value === "PAYPAL" ||
                          method.value === "CREDITS_AND_PAYPAL")) ||
                      (canOnlyRefundMixed &&
                        method.value !== "CREDITS" &&
                        method.value !== "CREDITS_AND_PAYPAL" &&
                        method.value !== "CREDITS_AND_STRIPE"),
                  }))}
                  value={c.field.value}
                  placeholder="Refund method"
                  classNames={{
                    root: "max-w-[300px]",
                  }}
                  onChange={c.field.onChange}
                  error={c.fieldState.error?.message}
                />
              )}
            />
            <Controller
              control={form.control}
              name="print"
              render={(c) => (
                <M.Select
                  data={PrintSelectData}
                  value={c.field.value}
                  placeholder="Printing method"
                  classNames={{
                    root: "max-w-[300px]",
                  }}
                  onChange={c.field.onChange}
                  error={c.fieldState.error?.message}
                />
              )}
            />
          </div>
          <div>
            <ItemsTable itemsToRefund={itemsToRefund} />
          </div>
          {isRefundMixedMethod && (
            <VStack my={6} alignItems="start">
              <styled.h4 fontWeight="normal">Allocate refunds</styled.h4>
              <HStack>
                <Controller
                  control={form.control}
                  name="mixedMethod.credits"
                  defaultValue={0}
                  render={(c) => (
                    <M.NumberInput
                      label="Credits"
                      max={creditsInputMax?.toNumber()}
                      precision={2}
                      description={`Max: $${creditsInputMax?.toFixed(2)}`}
                      value={credits}
                      onChange={(e) => {
                        if (!e) {
                          c.field.onChange(0);
                          setStripe(0);
                          return;
                        }
                        setCredits(e);
                        const maxPaymentsProviderRefund =
                          maxTotalItemsToRefund.toNumber() - (e as number);
                        if (refundMethod === "CREDITS_AND_STRIPE") {
                          setStripe(maxPaymentsProviderRefund);
                        } else if (refundMethod === "CREDITS_AND_PAYPAL") {
                          setPaypal(maxPaymentsProviderRefund);
                        }
                      }}
                      defaultValue={0}
                      min={0}
                    />
                  )}
                />

                {refundMethod === "CREDITS_AND_STRIPE" && (
                  <Controller
                    control={form.control}
                    name="mixedMethod.stripe"
                    defaultValue={0}
                    render={(c) => (
                      <M.NumberInput
                        label="Stripe"
                        max={paymentsProviderInputMax?.toNumber()}
                        precision={2}
                        description={`Max: $${paymentsProviderInputMax?.toFixed(2)}`}
                        value={stripe}
                        onChange={(e) => {
                          if (!e) {
                            c.field.onChange(0);
                            setCredits(0);
                            return;
                          }
                          setStripe(e);
                          setCredits(
                            maxTotalItemsToRefund.toNumber() - (e as number),
                          );
                        }}
                        defaultValue={0}
                        min={0}
                      />
                    )}
                  />
                )}

                {refundMethod === "CREDITS_AND_PAYPAL" && (
                  <Controller
                    control={form.control}
                    name="mixedMethod.paypal"
                    defaultValue={0}
                    render={(c) => (
                      <M.NumberInput
                        label="PayPal"
                        max={paymentsProviderInputMax?.toNumber()}
                        precision={2}
                        description={`Max: $${paymentsProviderInputMax?.toFixed(2)}`}
                        value={paypal}
                        onChange={(e) => {
                          if (!e) {
                            c.field.onChange(0);
                            setCredits(0);
                            return;
                          }
                          setPaypal(e);
                          setCredits(
                            maxTotalItemsToRefund.toNumber() - (e as number),
                          );
                        }}
                        defaultValue={0}
                        min={0}
                      />
                    )}
                  />
                )}
              </HStack>
            </VStack>
          )}
          <div className="mt-8 flex gap-x-6">
            <M.Button
              component={Link}
              to={orderPreviewUrl}
              color="gray.1"
              c="dark"
            >
              Cancel
            </M.Button>
            <M.Button
              component={Link}
              to="/sales/ecommerce-orders"
              color="gray.1"
              c="dark"
            >
              Orders
            </M.Button>
            <M.Button
              type="submit"
              disabled={
                isRefundMixedMethod &&
                credits === 0 &&
                stripe === 0 &&
                paypal === 0
              }
            >
              Refund
            </M.Button>
          </div>
        </FormProvider>
      </form>
    </div>
  );
}

type OrderItemSku = NonNullable<
  RouterOutputs["order"]["pos"]["ecommerceOrders"]["ecommerceOrder"]["get"]
>["orderItemSku"][number];

const column = createColumnHelper<OrderItemSku>();

const columns = [
  column.accessor("itemSku.defaultImage", {
    header: "Image",
    cell: (table) => {
      return (
        <img
          src={table.getValue() ?? "/placeholder.png"}
          className="h-[80px] w-[80px]"
        />
      );
    },
  }),
  column.accessor("itemSku.sku", {
    header: "SKU",
  }),
  column.accessor("itemSku.title", {
    header: "Title",
  }),
  column.display({
    id: "pkg",
    header: "PKG",
    cell: ({ row }) => {
      const pkgValue = row.original.itemSku.presentationValue;
      const pkgType = row.original.itemSku.presentationType;
      return `${pkgValue} ${pkgType}`;
    },
  }),
  column.accessor("quantity", {
    header: "Qty",
  }),
  column.display({
    id: "returnQty",
    header: "Return QTY",
    cell: function Cell({ row }) {
      const form = useFormContext();
      const items = useWatch({
        control: form.control,
        name: `items`,
      });

      const thisItemIndex = items.findIndex(
        (item) => item.orderItemSkuId === row.original.id,
      );
      const thisItem = items[thisItemIndex];

      let alreadyReturnedQuantity = 0;
      for (const refund of row.original.refundOrderItemSku) {
        alreadyReturnedQuantity += refund.quantity;
      }
      const maxAvailableReturnQuantity =
        row.original.quantity - alreadyReturnedQuantity;

      function updateQuantity(newQuantity: number) {
        if (newQuantity < 0) {
          return;
        }
        if (newQuantity > maxAvailableReturnQuantity) {
          reportUserError({
            id: `max-available-refund-quantity-${row.original.id}`,
            title: "Cannot refund more quantity",
            message: `Already refunded quantity: ${alreadyReturnedQuantity}`,
          });
          return;
        }
        form.setValue(`items.${thisItemIndex}.quantity`, newQuantity);
      }

      return (
        <div className="min-w-max rounded-md border border-current">
          <button
            type="button"
            className="h-8 w-10 bg-transparent"
            onClick={() => updateQuantity(thisItem.quantity - 1)}
          >
            -
          </button>
          <span className="h-8 w-10">{thisItem?.quantity ?? 0}</span>
          <button
            type="button"
            className="h-8 w-10 bg-transparent"
            onClick={() => updateQuantity(thisItem.quantity + 1)}
          >
            +
          </button>
        </div>
      );
    },
  }),
  column.display({
    id: "reason",
    header: "Reason",
    cell: function Cell({ row }) {
      const form = useFormContext();
      const items = useWatch({
        control: form.control,
        name: `items`,
      });
      const thisItemIndex = items.findIndex(
        (item) => item.orderItemSkuId === row.original.id,
      );
      const thisItem = items[thisItemIndex] as
        | (typeof items)[number]
        | undefined;

      function updateRefundReason(reason: RefundReason) {
        form.setValue(`items.${thisItemIndex}.reason`, reason);
      }

      return (
        <M.Select
          data={RefundReasonsSelectData}
          value={thisItem?.reason ?? "RETURN"}
          classNames={{
            input: "border-current text-current",
          }}
          onChange={(v) => {
            if (!v) return;
            updateRefundReason(v as any);
          }}
        />
      );
    },
  }),
  column.display({
    id: "discount",
    header: "Discount",
    cell: (table) => {
      const discountAmount = table.row.original.discountAmount;
      const discountType = table.row.original.discountType;
      if (discountAmount) {
        if (discountType === "PERCENTAGE") {
          return `${discountAmount.toFixed(2)}%`;
        } else if (discountType === "AMOUNT") {
          return `$${discountAmount.toFixed(2)}`;
        }
      }
      return "";
    },
  }),
  column.display({
    id: "subtotal",
    header: "Subtotal",
    cell: (table) => {
      const qty = table.row.original.quantity;
      const price = table.row.original.price;
      const subtotal = price.mul(qty);
      return `$${subtotal.toFixed(2)}`;
    },
  }),
  column.accessor("total", {
    header: "Total",
    cell: (table) => {
      return `$${table.getValue().toFixed(2)}`;
    },
  }),
];

interface ItemsTableProps {
  itemsToRefund: RefundFormSchema["items"];
}

function ItemsTable(props: ItemsTableProps) {
  const { data: order } = useOrder();

  const tableData = useMemo(
    () =>
      order?.orderItemSku.filter((oiSku) =>
        props.itemsToRefund.map((i) => i.orderItemSkuId).includes(oiSku.id),
      ) ?? [],
    [props.itemsToRefund, order],
  );

  const table = useReactTable({
    columns,
    data: tableData,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="text-red-500">
      <Table.Root>
        <Table.Header>
          {table.getHeaderGroups().map((headerGroup) => (
            <Table.Row key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Table.Head key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                </Table.Head>
              ))}
            </Table.Row>
          ))}
        </Table.Header>
        <Table.Body>
          {table.getRowModel().rows.map((row) => (
            <Table.Row key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <Table.Cell key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Table.Cell>
              ))}
            </Table.Row>
          ))}
        </Table.Body>
      </Table.Root>
    </div>
  );
}
