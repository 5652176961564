import { useFormSchemaContext } from "./Form.js";
import * as C from "@chakra-ui/react";
import { ErrorMessage } from "@hookform/error-message";
import _ from "lodash";
import React from "react";
import { FieldValues, Path, useFormContext } from "react-hook-form";
import { ZodObject } from "zod";

export interface FieldProps<TFieldValues extends FieldValues>
  extends C.FormControlProps {
  label: string;
  name: Path<TFieldValues>;
  children?: React.ReactNode;
  render?: (props: { name: string }) => React.ReactNode;
}

export function Field<TFieldValues extends FieldValues>({
  label,
  name,
  children,
  render,
  ...props
}: FieldProps<TFieldValues>) {
  const {
    formState: { errors },
  } = useFormContext();

  const schema = useFormSchemaContext() as ZodObject<TFieldValues>;

  const fieldShape = _.get(schema.shape, name);
  const isRequired = !fieldShape.isOptional() && !fieldShape.isNullable();
  const isInvalid = _.get(errors, name);

  return (
    <C.FormControl
      isRequired={isRequired}
      variant="floating"
      isInvalid={!!isInvalid}
      {...props}
    >
      {render ? render({ name }) : children}
      <C.FormLabel>{label}</C.FormLabel>
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ messages, message }) => {
          if (message) {
            return <C.FormErrorMessage>{message}</C.FormErrorMessage>;
          } else if (messages) {
            return Object.entries(messages).map(([type, message]) => (
              <C.FormErrorMessage key={type}>{message}</C.FormErrorMessage>
            ));
          }
        }}
      />
    </C.FormControl>
  );
}
