import { ReceiptCustomIcon } from "#/components-ng/index.js";
import { DisplayTable } from "#/components/index.js";
import { NoteModal } from "#/components/ng/index.js";
import { RefundHistoryModal } from "#/scenes/Customers/CustomersList/ReturnHistory.js";
import { RouterOutputs, trpc } from "#/trpc.js";
import { getDiscountLabel } from "#/util/discounts.js";
import { EditCustomerModal } from "./EditCustomerModal.js";
import { OrderHistoryModal } from "./OrderHistoryModal.js";
import { customerAtom, receiptNameAtom } from "./state/index.js";
import { noteAtom } from "./state/note.js";
import { EditIcon } from "@chakra-ui/icons";
import * as C from "@chakra-ui/react";
import { UseDisclosureReturn } from "@chakra-ui/react";
import { useAtom } from "jotai";
import React, { useState } from "react";
import {
  MdOutlineEmail,
  MdExpandMore,
  MdOutlineLocalOffer,
  MdOutlinePhone,
} from "react-icons/md/index.js";

type AnonCustomer = RouterOutputs["user"]["search"][number];

export function CustomerPanel() {
  const [receiptName, setReceiptName] = useAtom(receiptNameAtom);
  const [anonSelected, setAnonSelected] = useState(false);
  const [anonymousCustomer, setAnonymousCustomer] =
    useState<AnonCustomer | null>();
  const [customer, setCustomer] = useAtom(customerAtom);

  function selectAnonymousCustomer(anon?: AnonCustomer) {
    if (anon) {
      setCustomer(anon);
    }
  }
  const anonUserSearch = trpc.user.search.useQuery(
    {
      query: "anonymous",
    },
    {
      onSuccess(data) {
        setAnonymousCustomer(data?.[0]);
        if (anonSelected) {
          selectAnonymousCustomer(data?.[0]);
        }
      },
    },
  );

  const userDiscountsQuery = trpc.userDiscount.getManyByUserId.useQuery(
    {
      userId: Number(customer?.id ?? 0),
    },
    {
      enabled: !!anonymousCustomer || !!customer,
    },
  );

  function handleSetAnonymousCustomer() {
    setAnonSelected(true);
    selectAnonymousCustomer(anonUserSearch.data?.[0]);
  }

  const discountsDisclosure = C.useDisclosure();
  const editCustomerDisclosure = C.useDisclosure();
  const orderHistoryDisclosure = C.useDisclosure();
  const returnHistoryDisclosure = C.useDisclosure();

  return (
    <C.Container bg="white" px={5} py={4} maxW="container.xl" borderRadius="md">
      <CustomerDiscountsModal
        entries={userDiscountsQuery.data}
        {...discountsDisclosure}
      />
      <EditCustomerModal {...editCustomerDisclosure} />
      <OrderHistoryModal {...orderHistoryDisclosure} />
      {customer ? (
        <>
          <RefundHistoryModal
            {...returnHistoryDisclosure}
            customerId={customer.id}
          />
          <C.HStack spacing={4} w="100%">
            <C.Avatar
              name={`${customer.firstName} ${customer.lastName}`}
              src={customer.avatar ?? undefined}
            />
            <C.VStack w="100%" spacing={0}>
              <C.HStack>
                <C.Text fontWeight="bold">{`${customer.firstName} ${customer.lastName}`}</C.Text>
                <C.IconButton
                  icon={<EditIcon />}
                  aria-label="edit"
                  size="xs"
                  variant="ghost"
                  colorScheme="gray"
                  onClick={editCustomerDisclosure.onOpen}
                />
                <C.Popover placement="right-start">
                  <C.PopoverTrigger>
                    <C.IconButton
                      icon={<ReceiptCustomIcon />}
                      aria-label="receipt name"
                      size="xs"
                      variant="ghost"
                      colorScheme="gray"
                    />
                  </C.PopoverTrigger>
                  <C.PopoverContent>
                    <C.PopoverArrow />
                    <C.PopoverCloseButton py={"16px"} />
                    <C.PopoverHeader
                      border="0"
                      fontWeight={500}
                      pl={"20px"}
                      pt={"16px"}
                      pr={"16px"}
                      pb={"16px"}
                    >
                      Invoice recipient
                    </C.PopoverHeader>
                    <C.PopoverBody>
                      <C.RadioGroup
                        value={receiptName}
                        onChange={(e) => setReceiptName(e as any)}
                      >
                        <C.Stack>
                          <C.Radio value="customerName">Customer Name</C.Radio>
                          <C.Radio value="companyName">Company Name</C.Radio>
                        </C.Stack>
                      </C.RadioGroup>
                    </C.PopoverBody>
                  </C.PopoverContent>
                </C.Popover>
              </C.HStack>
              <C.HStack
                spacing={5}
                w="100%"
                justify="space-between"
                align="end"
              >
                <C.Flex columnGap={3} rowGap={1} wrap="wrap">
                  <C.HStack color="gray.500" fontSize="md">
                    <MdOutlineEmail fontSize="small" />
                    <C.Text fontSize="sm">{customer.email}</C.Text>
                  </C.HStack>
                  <C.HStack color="gray.500" fontSize="md">
                    <MdOutlinePhone fontSize="small" />
                    <C.Text fontSize="sm">{`${customer.areaCode} ${customer.phoneNumber}`}</C.Text>
                  </C.HStack>
                  <C.HStack color="gray.500" fontSize="md">
                    <MdOutlineLocalOffer fontSize="small" />
                    <C.Text fontSize="sm">{"Discounts"}</C.Text>
                    <C.IconButton
                      icon={<MdExpandMore />}
                      aria-label="expand discounts"
                      variant="ghost"
                      size="xs"
                      colorScheme="gray"
                      onClick={discountsDisclosure.onOpen}
                    />
                  </C.HStack>
                </C.Flex>
                <C.HStack>
                  <C.Button
                    colorScheme="gray"
                    variant="secondary"
                    size="sm"
                    onClick={orderHistoryDisclosure.onOpen}
                  >
                    Order history
                  </C.Button>
                  <C.Button
                    colorScheme="gray"
                    variant="secondary"
                    size="sm"
                    onClick={returnHistoryDisclosure.onOpen}
                  >
                    Return History
                  </C.Button>
                  <AddNoteButton />
                </C.HStack>
              </C.HStack>
            </C.VStack>
          </C.HStack>
        </>
      ) : (
        <C.VStack>
          <C.Text size="lg" fontWeight="bold">
            You must select a customer
          </C.Text>
          <C.Checkbox
            colorScheme="gray"
            size="sm"
            onChange={handleSetAnonymousCustomer}
          >
            <C.Text size="sm" color="gray.500">
              Customer does not provide their information
            </C.Text>
          </C.Checkbox>
        </C.VStack>
      )}
    </C.Container>
  );
}

interface CustomerDiscountsModalProps extends UseDisclosureReturn {
  entries?: RouterOutputs["userDiscount"]["getManyByUserId"] | null;
}

function CustomerDiscountsModal({
  entries,
  ...disclosure
}: CustomerDiscountsModalProps) {
  return (
    <C.Modal {...disclosure}>
      <C.ModalOverlay />
      <C.ModalContent maxW="min(800px, 95%)">
        <C.ModalHeader>Customer discounts</C.ModalHeader>
        <C.ModalCloseButton />
        <C.ModalBody>
          <DisplayTable columns={discountColumns} data={entries || []} />
        </C.ModalBody>
      </C.ModalContent>
    </C.Modal>
  );
}

const AddNoteButton = () => {
  const [opened, setOpened] = React.useState(false);
  const [note, setNote] = useAtom(noteAtom);

  const handleSave = async (note: string) => {
    setNote(note);
    setOpened(false);
  };

  return (
    <>
      <C.Button size="sm" onClick={() => setOpened(true)}>
        {note ? "See note" : "Add note to cart"}
      </C.Button>
      <NoteModal
        defaultValue={note}
        opened={opened}
        onClose={() => setOpened(false)}
        onSave={handleSave}
      />
    </>
  );
};

const discountColumns = [
  {
    Header: "Name",
    accessor: "discount.reference",
  },
  {
    Header: "Redeem code",
    accessor: "redeemCode",
    Cell: ({ value, row: {original} }) => {
      console.log(original)
      return value ?? "N/A";
    },
  },
  {
    Header: "Department",
    accessor: "department.name",
  },
  {
    Header: "Amount",
    accessor: "discount.amount",
    isNumeric: true,
    Cell: ({ row: { original } }) => (
      <C.Text>{getDiscountLabel(original.discount)}</C.Text>
    ),
  },
];
