import GTLoader from "#/components-ng/loader.js";
import { trpc } from "#/trpc.js";
import { reportUserError, reportUserSuccess } from "#/util/index.js";
import { RateCostForm, RateCostFormValues } from "../Form/index.js";
import * as M from "@mantine/core";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

export const EditRateCost = () => {
  const ctx = trpc.useContext();
  const { id } = useParams();
  const { data, isLoading } = trpc.rateCost.getById.useQuery(
    {
      id: Number(id!),
    },
    {
      cacheTime: 0,
      onError: (error) => {
        reportUserError({
          title: "Failed to get rate cost",
          message: error.message,
        });
      },
    },
  );

  const defaultValues = React.useMemo(() => {
    if (data == null) return null;

    return {
      id: data?.id,
      isWithWeight: data?.isWithWeight ?? false,
      name: data?.name ?? "",
      extendedByCost: {
        id_: data?.extendedByCost?.id,
        exchangeRate: data?.extendedByCost?.exchangeRate ?? 1,
        fees:
          data!.extendedByCost?.fees.map((fee) => ({
            id_: fee?.id,
            description: fee?.description ?? "",
            amount: fee?.amount ?? 0,
            type: fee?.type ?? "PERCENTAGE",
            category: fee?.category ?? "FREIGHT",
          })) ?? [],
      },
      utilityRangeMin: data?.utilityRangeMin ?? 0,
      utilityRangeMax: data?.utilityRangeMax ?? 0,
      extendedByWeight: data?.extendedByWeight
        ? {
            id_: data?.extendedByWeight?.id,
            taxCostPercentage: data?.extendedByWeight.taxCostPercentage,
            fees:
              data?.extendedByWeight?.fees.map((fee) => ({
                id_: fee?.id,
                description: fee?.description ?? "",
                amount: fee?.amount ?? 0,
                type: fee?.type ?? "FIXED",
                category: fee?.category ?? "FREIGHT",
              })) ?? [],
          }
        : undefined,
    };
  }, [data]);

  const navigate = useNavigate();
  const {
    mutate: updateRateCost,
    error,
    isLoading: isLoadingUpdate,
  } = trpc.rateCost.update.useMutation({
    onSuccess: () => {
      reportUserSuccess({
        title: "Successfully updated rate cost",
      });
      ctx.v2_5.rateCost.invalidate();
      navigate("/inventory/rate-cost");
    },
    onError: (error) => {
      reportUserError({
        title: "Failed to update rate cost",
        message: error.message,
      });
    },
  });

  const { mutate: updateFee } = trpc.rateCost.updateFee.useMutation({
    onSuccess: () => {
      ctx.v2_5.rateCost.invalidate();
    },
    onError: (error) => {
      reportUserError({
        title: "Failed to update fee",
        message: error.message,
      });
    },
  });

  const { mutate: createFee } = trpc.rateCost.createFee.useMutation({
    onSuccess: () => {
      ctx.v2_5.rateCost.invalidate();
    },
    onError: (error) => {
      reportUserError({
        title: "Failed to create fee",
        message: error.message,
      });
    },
  });

  const handleSubmit = React.useCallback(
    (formValues: RateCostFormValues) => {
      updateRateCost({
        id: Number(id!),
        input: {
          name: formValues.name,
          isWithWeight: formValues.isWithWeight,
          utilityRangeMax: formValues.utilityRangeMax,
          utilityRangeMin: formValues.utilityRangeMin,
          extendedByCost: {
            update: {
              id: formValues.extendedByCost!.id_,
              exchangeRate: formValues.extendedByCost.exchangeRate,
            },
          },
          extendedByWeight: formValues.isWithWeight
            ? {
                upsert: {
                  update: {
                    id: formValues.extendedByWeight?.id_,
                    taxCostPercentage:
                      formValues.extendedByWeight?.taxCostPercentage,
                  },
                  create: {
                    taxCostPercentage:
                      formValues.extendedByWeight?.taxCostPercentage ?? 0,
                  },
                },
              }
            : undefined,
        },
      });

      if (!error) {
        if (formValues.extendedByCost?.fees.length > 0) {
          for (const f of formValues.extendedByCost.fees) {
            if (f.id_) {
              updateFee({
                id: f.id_,
                input: {
                  description: f.description,
                  amount: f.amount,
                  type: f.type,
                  category: f.category,
                  ExtendedByCost: {
                    connect: {
                      id: formValues.extendedByCost?.id_,
                    },
                  },
                },
              });
            } else {
              createFee({
                description: f.description,
                amount: f.amount,
                type: f.type,
                category: f.category,
                ExtendedByCost: {
                  connect: {
                    id: formValues.extendedByCost?.id_,
                  },
                },
              });
            }
          }
        }
        if (
          formValues.isWithWeight &&
          formValues?.extendedByWeight?.fees &&
          formValues?.extendedByWeight?.fees.length > 0
        ) {
          // eslint-disable-next-line no-unsafe-optional-chaining
          for (const f of formValues?.extendedByWeight!.fees) {
            if (f.id_) {
              updateFee({
                id: f.id_,
                input: {
                  description: f.description,
                  amount: f.amount,
                  type: f.type,
                  category: f.category,
                  ExtendedByWeight: {
                    connect: {
                      id: formValues.extendedByWeight?.id_,
                    },
                  },
                },
              });
            } else {
              createFee({
                description: f.description,
                amount: f.amount,
                type: f.type,
                category: f.category,
                ExtendedByWeight: {
                  connect: {
                    id: formValues.extendedByWeight?.id_,
                  },
                },
              });
            }
          }
        }
      }
    },
    [updateRateCost, error, updateFee, createFee, id],
  );

  return isLoading ? (
    <M.Center>
      <M.LoadingOverlay
        visible={isLoading}
        loader={<GTLoader width={100} height={100} />}
      />
    </M.Center>
  ) : (
    <RateCostForm
      onSubmit={handleSubmit}
      defaultValues={defaultValues as RateCostFormValues}
      loading={isLoadingUpdate}
    />
  );
};
