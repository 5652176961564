import React from "react";
import PropTypes from "prop-types";
import UncontrolledCombobox from "#/components/Combobox/index.jsx";
import { useFormContext, Controller } from "react-hook-form";

// If you don't use this in a form, use the `Combobox`
// component in `components/`
function Combobox({ name, rules, onChange: userOnChange, ...props }) {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value } }) => (
        <UncontrolledCombobox
          {...props}
          onChange={(v) => {
            onChange(v);
            if (userOnChange) {
              userOnChange(v);
            }
          }}
          selectedItem={value || null}
        />
      )}
    />
  );
}

export default Combobox;

Combobox.defaultProps = {
  name: "",
  onChange: () => {},
  rules: {},
};

Combobox.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  rules: PropTypes.object,
};
