import * as M from "@mantine/core";
import {
  MantineReactTable,
  MRT_ColumnDef,
  useMantineReactTable,
} from "mantine-react-table";
import { ActionsMenuIcon, PencilCustomIcon } from "#/components-ng";
import DeleteIcon from "~icons/ion/trash-outline";
import { RouterOutputs, trpc } from "#/trpc";
import { useDisclosure } from "@mantine/hooks";
import { FormQuestionCreate, FormAnswerCreate } from "./types";
import { DefaultValues } from "react-hook-form";
import { css } from "#/css/css";
import { QuestionFormDrawer } from "./quiz-question-form-drawer";
import { reportUserError, reportUserSuccess } from "#/util";
import { openConfirmModal } from "@mantine/modals";
import DownIcon from "~icons/ion/chevron-down-outline";
import UpIcon from "~icons/ion/chevron-up-outline";
import { AnswerFormDrawer } from "./answer-form-drawer";

type Questions = RouterOutputs["v2_5"]["quiz"]["getQuestionsByQuizId"];
type Question = Questions[number];

export default function QuizQuestionsSection({ quizId }: { quizId: number }) {
  const [opened, { open, close }] = useDisclosure(false);

  const { data: questions } = trpc.v2_5.quiz.getQuestionsByQuizId.useQuery({
    quizId: quizId,
  });

  return (
    <M.Stack spacing={10} bg={"white"} my={32}>
      <M.Title bg={"white"} fw={600} px={40} py={24} w={"100%"} fz="1.2rem">
        Create questions
      </M.Title>
      <M.Divider />
      <M.Box w="100%" px={40} py={24}>
        <QuestionsTable questions={questions ?? []} quizId={quizId} />
        <CreateQuestionDrawer open={opened} close={close} quizId={quizId} />
        <M.Button onClick={open} mt={40} className={css({ width: "fit" })}>
          Add new question
        </M.Button>
      </M.Box>
    </M.Stack>
  );
}

function CreateQuestionDrawer({
  open,
  close,
  quizId,
}: {
  open: boolean;
  close: () => void;
  quizId: number;
}) {
  const ctx = trpc.useContext();

  const { mutate, isLoading } = trpc.v2_5.quiz.createQuestion.useMutation({
    onSuccess: () => {
      ctx.v2_5.quiz.invalidate();
      close();
      reportUserSuccess({
        title: "Question created",
        message: "Question created successfully",
      });
    },
  });

  const handleSubmit = (values: FormQuestionCreate) => {
    mutate({
      quizId: quizId,
      question: values.question,
      questionEsp: values.questionEsp,
    });
    close();
  };

  return (
    <QuestionFormDrawer
      open={open}
      close={close}
      isLoading={isLoading}
      onSubmit={handleSubmit}
      title="Create question"
    />
  );
}

function UpdateQuestionDrawer({
  open,
  close,
  defaultValues,
  questionId,
}: {
  open: boolean;
  close: () => void;
  defaultValues?: DefaultValues<FormQuestionCreate>;
  questionId: number;
}) {
  const ctx = trpc.useContext();

  const { mutate, isLoading } = trpc.v2_5.quiz.updateQuestion.useMutation({
    onSuccess: () => {
      ctx.v2_5.quiz.invalidate();
      reportUserSuccess({
        title: "Question updated",
        message: "Question updated successfully",
      });
      close();
    },
  });

  const handleSubmit = (values: FormQuestionCreate) => {
    mutate({
      id: questionId,
      question: values.question,
      questionEsp: values.questionEsp,
    });
    close();
  };

  return (
    <QuestionFormDrawer
      open={open}
      close={close}
      defaultValues={defaultValues}
      isLoading={isLoading}
      onSubmit={handleSubmit}
      title="Update question"
    />
  );
}

function CreateAnswerDrawer({
  open,
  close,
  questionId,
  quizId,
}: {
  open: boolean;
  close: () => void;
  questionId: number;
  quizId: number;
}) {
  const ctx = trpc.useContext();

  const { mutate, isLoading } = trpc.v2_5.quiz.createAnswer.useMutation({
    onSuccess: () => {
      reportUserSuccess({
        title: "Answer created",
        message: "Answer created successfully",
      });
      ctx.v2_5.quiz.invalidate();
      close();
    },
  });

  const handleSubmit = (values: FormAnswerCreate) => {
    if (!values.valueId) {
      reportUserError({
        title: "Value required",
        message: "Please select a value",
      });
      return;
    }

    mutate({
      answer: values.answer,
      score: Number(values.score),
      valueId: Number(values.valueId),
      questionId: questionId,
      answerEsp: values.answerEsp,
    });
    close();
  };

  return (
    <AnswerFormDrawer
      open={open}
      close={close}
      isLoading={isLoading}
      onSubmit={handleSubmit}
      title="Create answer"
      quizId={quizId}
    />
  );
}

function UpdateAnswerDrawer({
  open,
  close,
  defaultValues,
  quizId,
  answerId,
}: {
  open: boolean;
  close: () => void;
  defaultValues?: DefaultValues<FormAnswerCreate>;
  quizId: number;
  answerId: number;
}) {
  const ctx = trpc.useContext();

  const { mutate, isLoading } = trpc.v2_5.quiz.updateAnswer.useMutation({
    onSuccess: () => {
      reportUserSuccess({
        title: "Answer updated",
        message: "Answer updated successfully",
      });
      ctx.v2_5.quiz.invalidate();
      close();
    },
  });

  const handleSubmit = (values: FormAnswerCreate) => {
    if (!values.valueId) {
      reportUserError({
        title: "Value required",
        message: "Please select a value",
      });
      return;
    }

    mutate({
      id: answerId,
      answer: values.answer,
      score: Number(values.score),
      valueId: Number(values.valueId),
      answerEsp: values.answerEsp,
    });
    close();
  };

  return (
    <AnswerFormDrawer
      open={open}
      close={close}
      defaultValues={defaultValues}
      isLoading={isLoading}
      onSubmit={handleSubmit}
      title="Update answer"
      quizId={quizId}
    />
  );
}

interface QuestionsTableProps {
  questions: Questions;
  quizId: number;
}

const QuestionsTable = (props: QuestionsTableProps) => {
  const table = useMantineReactTable({
    columns,
    data: props.questions ?? [],
    enableColumnActions: false,
    enableColumnFilters: false,
    enablePagination: false,
    enableDensityToggle: false,
    enableGlobalFilter: false,
    enableFullScreenToggle: false,
    enableHiding: false,
    enableSorting: false,
    enableTopToolbar: false,
    enableFilters: false,
    positionExpandColumn: "last",
    mantineTableHeadCellProps: {
      sx: {
        flex: "0 0 auto",
      },
    },
    displayColumnDefOptions: {
      "mrt-row-expand": {
        mantineTableHeadCellProps: {
          hidden: true,
        },
        mantineTableBodyCellProps: {
          align: "center",
          width: "100%",
        },
      },
    },
    icons: {
      IconChevronDown: (props) => {
        return (
          <M.Button
            variant="default"
            rightIcon={
              props.style.transform === "rotate(-180deg)" ? (
                <UpIcon />
              ) : (
                <DownIcon />
              )
            }
            className={css({
              width: "500px",
              color: "#000",
              backgroundColor: "#F4F4F4",
              border: 0,
            })}
            size="sm"
          >
            Answers
          </M.Button>
        );
      },
    },
    renderDetailPanel: ({ row }) => (
      <M.Stack spacing={18} pl={"1rem"} w="100%">
        {row.original.answers.map((element) => (
          <AnswersPanelDetails
            answer={element}
            key={element.id}
            quizId={props.quizId}
          />
        ))}
      </M.Stack>
    ),
  });

  return <MantineReactTable table={table} />;
};

const columns: Array<MRT_ColumnDef<Question>> = [
  {
    header: "Actions",
    size: 100,
    Cell(table) {
      const ctx = trpc.useContext();
      // state to open the question drawer
      const [
        openedQuestionDrawer,
        { open: openQuestionDrawer, close: closeQuestionDrawer },
      ] = useDisclosure(false);

      // state to open the answer drawer
      const [
        openedAnswerDrawer,
        { open: openAnswerDrawer, close: closeAnswerDrawer },
      ] = useDisclosure(false);

      const original = table.row.original;

      const { mutate: deleteQuestion } =
        trpc.v2_5.quiz.deleteQuestion.useMutation({
          onSuccess: () => {
            reportUserSuccess({
              title: "Question deleted",
              message: "Question deleted successfully",
            });
            ctx.v2_5.quiz.invalidate();
          },
        });

      const openConfirmDeleteModal = () =>
        openConfirmModal({
          title: "Are you sure you want to delete this question?",
          labels: { cancel: "Cancel", confirm: "Delete" },
          confirmProps: { color: "red" },
          onConfirm: () => deleteQuestion({ id: original.id }),
        });

      return (
        <div
          className={css({
            width: "100px",
          })}
        >
          <M.Menu withinPortal>
            <M.Menu.Target>
              <M.ActionIcon>
                <ActionsMenuIcon />
              </M.ActionIcon>
            </M.Menu.Target>
            <M.Menu.Dropdown>
              <M.Menu.Item onClick={openAnswerDrawer}>Add answer</M.Menu.Item>
              <M.Menu.Item onClick={openQuestionDrawer}>Edit</M.Menu.Item>
              <M.Menu.Item onClick={openConfirmDeleteModal}>Delete</M.Menu.Item>
            </M.Menu.Dropdown>
          </M.Menu>
          {openedAnswerDrawer && (
            <CreateAnswerDrawer
              quizId={original.quizId}
              questionId={original.id}
              open={openedAnswerDrawer}
              close={closeAnswerDrawer}
            />
          )}
          {openedQuestionDrawer && (
            <UpdateQuestionDrawer
              open={openedQuestionDrawer}
              close={closeQuestionDrawer}
              defaultValues={{
                question: original?.question ?? "",
                questionEsp: original?.questionEsp ?? "",
              }}
              questionId={original.id ?? 0}
            />
          )}
        </div>
      );
    },
  },
  {
    accessorKey: "question",
    header: "Question",
  },
];

interface AnswersPanelDetailsProps {
  answer: Questions[number]["answers"][number];
  quizId: number;
}

const AnswersPanelDetails = ({ answer, quizId }: AnswersPanelDetailsProps) => {
  const [opened, { open, close }] = useDisclosure(false);

  const ctx = trpc.useContext();
  const { mutate: deleteAnswer } = trpc.v2_5.quiz.deleteAnswer.useMutation({
    onSuccess: () => {
      reportUserSuccess({
        title: "Answer deleted",
        message: "Answer deleted successfully",
      });
      ctx.v2_5.quiz.invalidate();
    },
  });

  const openConfirmDeleteModal = () =>
    openConfirmModal({
      title: "Are you sure you want to delete this answer?",
      labels: { cancel: "Cancel", confirm: "Delete" },
      confirmProps: { color: "red" },
      onConfirm: () => deleteAnswer({ id: answer.id }),
    });

  return (
    <>
      <M.Group position="apart" w="100%">
        <M.Text>{answer.answer}</M.Text>
        <M.Group mr={"2%"} spacing={2}>
          <M.ActionIcon>
            <PencilCustomIcon className="size-5" onClick={open} />
          </M.ActionIcon>
          <M.ActionIcon onClick={openConfirmDeleteModal}>
            <DeleteIcon className="size-5 text-black" />
          </M.ActionIcon>
        </M.Group>
      </M.Group>
      {opened && (
        <UpdateAnswerDrawer
          open={opened}
          close={close}
          defaultValues={{
            answer: answer?.answer ?? "",
            score: answer?.score.toString() ?? "",
            valueId: answer?.valueId.toString() ?? "",
            answerEsp: answer?.answerEsp ?? "",
          }}
          quizId={quizId}
          answerId={answer.id}
        />
      )}
    </>
  );
};
