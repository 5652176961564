import { CheckCircleIcon, XCircleIcon } from "#/components-ng";
import { RouterOutputs } from "#/trpc.js";

type Product = NonNullable<RouterOutputs["itemSku"]["getById"]>;

interface otherProps {
  product?: Product | null;
}

export const OtherSection = ({ product }: otherProps) => {
  return (
    <div className="mt-5 overflow-x-auto">
      <div className="grid grid-cols-1 gap-y-2 lg:grid-cols-2 lg:gap-x-20">
        <Details title="Department" value={product?.item?.department.name} />
        <Details title="Availability" value={product?.available} />
        <Details title="Category" value={product?.item?.category.name} />
        <Details title="Active" value={product?.item?.active} />
        <Details title="Season" value={product?.item?.season?.name} />
        <Details title="Note" value={product?.note} />
      </div>
    </div>
  );
};

interface Details {
  title: string;
  value?: string | number | boolean | null;
}
const Details = (props: Details) => {
  return (
    <div className="flex justify-between">
      <div className="flex-1">
        <p className="text-sm font-normal text-[#666]">{props.title}</p>
      </div>
      <div className="flex-1">
        {typeof props.value === "boolean" ? (
          props.value ? (
            <CheckCircleIcon />
          ) : (
            <XCircleIcon />
          )
        ) : (
          <p className="text-sm font-normal text-[#212121]">
            {props.value ?? "-"}
          </p>
        )}
      </div>
    </div>
  );
};
