import { DefaultValues, FormProvider, SubmitHandler } from "react-hook-form";
import { FormValues, C, createRouletteSchema } from "./types";
import { zodResolver } from "@hookform/resolvers/zod";
import { styled } from "#/css/jsx";
import { css } from "#/css/css";
import { DatePickerInput } from "@mantine/dates";
import { Button } from "@gt/ui";

export interface RouletteFormProps {
  onSubmit: SubmitHandler<FormValues>;
  isLoading: boolean;
  defaultValues?: DefaultValues<FormValues>;
  rouletteId?: number | null;
}

export const RouletteInfoForm = (props: RouletteFormProps) => {
  const form = C.useForm({
    resolver: zodResolver(createRouletteSchema),
    shouldUnregister: false,
    defaultValues: {
      title: "",
      description: "",
      active: true,
      startDate: null,
      endDate: null,
      ...props.defaultValues,
    },
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={(e) => form.handleSubmit(props.onSubmit)(e)}>
        <styled.div
          width="100%"
          className={css({
            display: "flex",
            flexDirection: "column",
            gap: 35,
          })}
        >
          <C.InputField name="title" label="Title *" required />
          <C.TextareaField name="description" label="Description" />
          <C.SwitchField name="active" label="Active" />
          <styled.div
            className={css({
              display: "flex",
              gap: 4,
              width: "100%",
            })}
          >
            <C.M
              as={DatePickerInput}
              name="startDate"
              label="Start Date"
              required
              className={css({ flex: 1 })}
            />
            <C.M
              as={DatePickerInput}
              name="endDate"
              label="End Date"
              required
              className={css({ flex: 1 })}
            />
          </styled.div>
          <Button type="submit">Save</Button>
        </styled.div>
      </form>
    </FormProvider>
  );
};
