import { S3_URL } from "#/constants/envt.js";
import { trpc } from "#/trpc.js";
import React from "react";
import { v4 as uuidv4 } from "uuid";

type TrpcClient = ReturnType<typeof trpc.useContext>["client"];

export const useS3PresignedUrl = (params?: {
  onSuccess?: ({ fileUrl }: { fileUrl: string }) => void;
}) => {
  const trpcClient = trpc.useContext().client;
  const [isLoading, setIsLoading] = React.useState(false);
  const [fileUrl, setFileUrl] = React.useState<string | null>(null);
  const upload = React.useCallback(
    async (file: File, folder: string) => {
      setIsLoading(true);
      try {
        const res = await uploadFile(file, folder, trpcClient);
        setFileUrl(res.url);
        params?.onSuccess?.({ fileUrl: res.url });
        return res;
      } finally {
        setIsLoading(false);
      }
    },
    [params]
  );

  return [upload, { isLoading, fileUrl }] as [
    typeof upload,
    { isLoading: boolean; fileUrl: string }
  ];
};

const uploadFile = async (
  file: File,
  folder: string,
  trpcClient: TrpcClient
) => {
  const uuid = uuidv4();
  let extension = file.name.split(".").pop();
  extension = extension ? `.${extension}` : "";

  const uploadFileName = `${uuid}${extension}`;

  const presignedUrlRes = await trpcClient.s3.getPresignedUrl.query({
    key: `${folder}/${uploadFileName}`,
  });
  const presignedUrl = presignedUrlRes.presignedUrl;
  await fetch(presignedUrl, {
    method: "PUT",
    body: file,
  });

  return { url: `${S3_URL}/${folder}/${uploadFileName}` };
};
