import { ChevronDownOutline } from "#/components-ng/index.js";
import GTLoader from "#/components-ng/loader.js";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "#/components-ng/ui/index.js";
import { DisplayTable, RouterPrompt } from "#/components/index.js";
import { useAuth } from "#/context/AuthContext.js";
import { trpc } from "#/trpc.js";
import { reportUserError } from "#/util/index.js";
import {
  addItemSkuAtom,
  itemSkusAtom,
  removeItemSkuAtom,
  resetItemsAtom,
  setItemSkuNewQtyAtom,
  fromDiffTotalStockAtom,
  toDiffTotalStockAtom,
  fromFilialBrevisAtom,
  toFilialBrevisAtom,
} from "./state.js";
import * as M from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import { useAtomValue, useSetAtom } from "jotai";
import React from "react";
import { useForm, useFormContext } from "react-hook-form";
import { useNavigate } from "react-router";
import { InventoryTransferStatus } from "server";

export type valuesDefaultInventoryTransfer = {
  filialFromId: string;
  filialToId: string;
  reason?: string | null;
  note: string;
  itemSkus: {
    id: number;
    title: string;
    sku: number;
    quantity: number;
    fromStock: number;
    toStock: number;
    diffFromStock: number;
    diffToStock: number;
  }[];
  status: InventoryTransferStatus;
};

export interface InventoryTransferFormProps {
  defaultValues?: valuesDefaultInventoryTransfer;
  onSubmit: (values: valuesDefaultInventoryTransfer) => void;
  isLoading: boolean;
}

export const InventoryTransferForm = ({
  defaultValues,
  onSubmit,
  isLoading,
}: InventoryTransferFormProps) => {
  const [submitted, setSubmitted] = React.useState(false);
  const itemSkus = useAtomValue(itemSkusAtom);
  const resetItems = useSetAtom(resetItemsAtom);
  const setFromFilialBrevis = useSetAtom(fromFilialBrevisAtom);
  const [{ auth }] = useAuth();

  const form = useForm<valuesDefaultInventoryTransfer>({
    defaultValues: {
      ...defaultValues,
      filialFromId:
        auth?.user?.filialId?.toString() ?? defaultValues?.filialFromId,
    },
  });

  const { data: filial } = trpc.filial.getById.useQuery(
    {
      id: auth?.user?.filialId ?? 0,
    },
    {
      enabled: !!auth?.user?.filialId,
      cacheTime: 0,
      select: (data) => ({ brevis: data?.brevis }),
    }
  );

  React.useEffect(() => {
    if (filial) {
      setFromFilialBrevis(filial?.brevis ?? null);
    }
  }, [filial, setFromFilialBrevis]);

  if (isLoading) {
    return (
      <M.LoadingOverlay
        visible={isLoading}
        loader={<GTLoader width={100} height={100} />}
      />
    );
  }

  const handlePreSubmit = (status: InventoryTransferStatus) => async (f) => {
    setSubmitted(true);
    onSubmit({
      ...f,
      status: status,
      itemSkus: itemSkus.map((item) => ({
        id: item.id,
        diffFromStock: item.diffStockFrom,
        diffToStock: item.diffStockTo,
        fromStock: item.stockFrom,
        toStock: item.stockTo,
        quantity: item.quantity,
        sku: item.sku,
        title: item.title,
      })),
    });
  };

  return (
    <M.Container size="xl">
      <form>
        <RouterPrompt
          when={!submitted}
          extraButtons={({ onConfirm, onCancel }) => (
            <M.Button
              color="darkGray.0"
              sx={(t) => ({ color: t.colors.gray[8] })}
              onClick={() => {
                form.handleSubmit(handlePreSubmit("DRAFT"))();
                setTimeout(() => {
                  if (submitted) {
                    resetItems();
                    onConfirm();
                  } else {
                    onCancel();
                  }
                }, 10);
              }}
            >
              Put on hold
            </M.Button>
          )}
          onCancel={() => setSubmitted(false)}
          onConfirm={() => {
            setSubmitted(false);
            resetItems();
          }}
        />
        <Form {...form}>
          <M.Stack className="bg-white" p={10}>
            <GeneralInformation />
            <M.Divider className="my-1 grayscale-0" color="gray.2" />
            <M.Stack p={16}>
              <Table />
              <Footer
                onSave={form.handleSubmit(handlePreSubmit("COMPLETE"))}
                onHold={form.handleSubmit(handlePreSubmit("DRAFT"))}
              />
            </M.Stack>
          </M.Stack>
        </Form>
      </form>
    </M.Container>
  );
};

const Table = () => {
  const items = useAtomValue(itemSkusAtom);
  const data = React.useMemo(() => {
    return items ?? [];
  }, [items]);

  return (
    <M.Stack>
      <M.Box className="max-w-full overflow-auto bg-white">
        <DisplayTable columns={columns} data={data} pagination={false} />
      </M.Box>
    </M.Stack>
  );
};

const columns = [
  {
    Header: "SKU",
    accessor: "sku",
  },
  {
    Header: "Product name",
    accessor: "title",
  },
  {
    Header: "Move quantity",
    accessor: "quantity",
    isNumeric: true,
    Cell: ({ value, row: { index, original } }) => {
      const setNewQty = useSetAtom(setItemSkuNewQtyAtom);

      return (
        <M.Box w={100} className="flex items-center justify-center">
          <M.NumberInput
            min={0}
            max={original.stockFrom}
            value={value}
            onChange={(v) => {
              if (typeof v === "number" && v > original.stockFrom) {
                reportUserError({
                  title: "Failed to add item",
                  message: "You can't move more than you have",
                });
                setNewQty({
                  index,
                  qty: original.stockFrom,
                });
              }

              setNewQty({
                index,
                qty: typeof v === "number" ? v : 0,
              });
            }}
          />
        </M.Box>
      );
    },
  },
  {
    Header: "From diff stock",
    accessor: "diffStockFrom",
    isNumeric: true,
    Cell: ({ value }) => <M.Text className="text-[#196799]">{value}</M.Text>,
  },
  {
    Header: "To diff stock",
    accessor: "diffStockTo",
    isNumeric: true,
    Cell: ({ value }) => <M.Text className="text-[#3F936F]">{value}</M.Text>,
  },
  {
    Header: "",
    accessor: "delete",
    Cell: ({ row: { index } }) => {
      const removeItemSku = useSetAtom(removeItemSkuAtom);
      return <M.CloseButton onClick={() => removeItemSku(index)} color="red" />;
    },
  },
];

const GeneralInformation = () => {
  const form = useFormContext<valuesDefaultInventoryTransfer>();
  const addItem = useSetAtom(addItemSkuAtom);
  const resetItems = useSetAtom(resetItemsAtom);
  const setFromFilialBrevis = useSetAtom(fromFilialBrevisAtom);
  const setToFilialBrevis = useSetAtom(toFilialBrevisAtom);
  const [{ auth }] = useAuth();

  const [query, setQuery] = React.useState("");
  const { data } = trpc.itemSku.search.useQuery(
    { query: `\\"${query}\\"` },
    {
      enabled: query.length > 0,
      onError: (err) => {
        reportUserError({
          title: "Failed to get products",
          message: err.message,
        });
      },
    }
  );

  const { data: filials } = trpc.filial.getAll.useQuery(undefined, {
    cacheTime: 0,
    onError: (err) => {
      reportUserError({
        title: "Error loading filials",
        message: err.message,
      });
    },
    select: (data) => {
      return data.map((data) => ({
        value: data.id.toString(),
        label: data.name,
        filial: data,
      }));
    },
  });

  const productOptions = React.useMemo(
    () =>
      data?.map((e) => ({
        label: `${e?.sku} - ${e?.title}` ?? "",
        value: e?.id.toString() ?? "",
        itemSku: e,
      })) ?? [],
    [data]
  );

  return (
    <M.Stack className="mb-0.5 p-10">
      <M.Group noWrap grow align="end">
        <FormField
          control={form.control}
          name="reason"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <M.Select
                  label="Reason"
                  {...field}
                  data={[
                    {
                      label: "Low Inventory",
                      value: "Low Inventory",
                    },
                    {
                      label: "Sales Needed",
                      value: "Sales Needed",
                    },
                  ]}
                  withAsterisk
                  onChange={(e) => {
                    form.setValue("reason", e ?? null);
                  }}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="filialFromId"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <M.Select
                  label="From filial"
                  {...field}
                  withAsterisk
                  data={filials ?? []}
                  classNames={{
                    input: "border-2 border-[#4DBAFF] text-[#196799]",
                    label: "text-[#196799]",
                    rightSection: "text-[#4DBAFF]",
                  }}
                  rightSection={<ChevronDownOutline />}
                  nothingFound="No filials found"
                  searchable
                  onChange={(e) => {
                    if (e) {
                      const brevis = filials?.find((f) => f.value === e)?.filial
                        ?.brevis;

                      const checkFrom = form.watch("filialFromId");
                      const checkTo = form.watch("filialToId");

                      if (checkTo && e === checkTo) {
                        form.resetField("filialFromId");
                        return reportUserError({
                          title: "Error",
                          message: "You can't select the same filial",
                        });
                      }

                      if (checkFrom) {
                        openConfirmModal({
                          title:
                            "Are you sure you want to change from filial, this delete all items in the list?",
                          labels: {
                            confirm: "Change",
                            cancel: "Cancel",
                          },
                          confirmProps: { color: "red" },
                          onConfirm: () => {
                            form.setValue("filialFromId", e);
                            setFromFilialBrevis(brevis ?? null);
                            resetItems();
                          },
                        });
                        return;
                      } else {
                        form.setValue("filialFromId", e);
                        setFromFilialBrevis(brevis ?? null);
                        return;
                      }
                    }
                  }}
                  required
                  disabled={auth?.user?.filialId !== null}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="filialToId"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <M.Select
                  label="To filial"
                  {...field}
                  withAsterisk
                  data={filials ?? []}
                  classNames={{
                    input: "border-2 border-[#60C69B] text-[#3F936F]",
                    label: "text-[#3F936F]",
                    rightSection: "text-[#60C69B]",
                  }}
                  rightSection={<ChevronDownOutline />}
                  nothingFound="No filials found"
                  searchable
                  onChange={(e) => {
                    if (e) {
                      const brevis = filials?.find((f) => f.value === e)?.filial
                        ?.brevis;
                      const checkTo = form.watch("filialToId");
                      const checkFrom = form.watch("filialFromId");

                      if (checkFrom && e === checkFrom) {
                        form.resetField("filialToId");
                        return reportUserError({
                          title: "Error",
                          message: "You can't select the same filial",
                        });
                      }

                      if (checkTo) {
                        openConfirmModal({
                          title:
                            "Are you sure you want to change to filial, this delete all items in the list?",
                          labels: {
                            confirm: "Change",
                            cancel: "Cancel",
                          },
                          confirmProps: { color: "red" },
                          onConfirm: () => {
                            form.setValue("filialToId", e);
                            setToFilialBrevis(brevis ?? null);
                            resetItems();
                          },
                        });
                        return;
                      } else {
                        form.setValue("filialToId", e);
                        setToFilialBrevis(brevis ?? null);
                        return;
                      }
                    }
                  }}
                  required
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <M.Select
          data={productOptions}
          label="Enter items"
          searchable
          zIndex={9998}
          value={null}
          onInput={(e) => setQuery(e.currentTarget.value)}
          onChange={(item) => {
            if (!item) return;
            const itemfound = productOptions.find((e) => e.value === item);
            if (!itemfound) return;

            const filialFrom = form.watch("filialFromId");
            const filialTo = form.watch("filialToId");

            if (!filialFrom || !filialTo) {
              return reportUserError({
                title: "Failed to add item",
                message: "Please select filials",
              });
            }

            if (
              itemfound.itemSku.itemSkuStock.find(
                (is) => is.filialId === Number(filialFrom) && is.quantity === 0
              )
            ) {
              return reportUserError({
                title: "Failed to add item",
                message: "This item is out of stock",
              });
            }

            const itemSkuStockFilialFrom = itemfound.itemSku.itemSkuStock.find(
              (is) => is.filialId === Number(filialFrom)
            );

            const itemSkuStockFilialTo = itemfound.itemSku.itemSkuStock.find(
              (is) => is.filialId === Number(filialTo)
            );

            addItem({
              sku: itemfound.itemSku.sku,
              title: itemfound.itemSku.title,
              quantity: 0,
              id: itemfound.itemSku.id,
              stockFrom: itemSkuStockFilialFrom?.quantity ?? 0,
              stockTo: itemSkuStockFilialTo?.quantity ?? 0,
              diffStockFrom: itemSkuStockFilialFrom?.quantity ?? 0,
              diffStockTo: itemSkuStockFilialTo?.quantity ?? 0,
            });
          }}
        />
      </M.Group>
    </M.Stack>
  );
};

export const Footer = ({
  onSave,
  onHold,
}: {
  onSave: () => void;
  onHold: () => void;
}) => {
  const form = useFormContext<valuesDefaultInventoryTransfer>();
  const fromDiffTotalStock = useAtomValue(fromDiffTotalStockAtom);
  const toDiffTotalStock = useAtomValue(toDiffTotalStockAtom);
  const fromBrevis = useAtomValue(fromFilialBrevisAtom);
  const toBrevis = useAtomValue(toFilialBrevisAtom);
  const navigate = useNavigate();

  return (
    <>
      <M.Group position="apart" className="mt-10 items-center p-10">
        <FormField
          control={form.control}
          name="note"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <M.Textarea
                  label="Note"
                  {...field}
                  placeholder="Enter note"
                  className="w-full max-w-lg"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <M.Group position="apart" className="">
          <M.SimpleGrid cols={2} className="gap-10">
            <M.Box className="inline-flex items-center justify-center gap-8 rounded-md bg-[#EDF8FF] p-5 text-sm text-[#4DBAFF]">
              <M.Text inherit>{fromBrevis ?? "From diff"} total stock</M.Text>
              <M.Text
                inherit
                className="rounded-md bg-white p-0 pl-10 pr-1 text-center"
              >
                {fromDiffTotalStock}
              </M.Text>
            </M.Box>
            <M.Box className="inline-flex items-center justify-center gap-8 rounded-md bg-[#F3FFFA] p-5 text-sm text-[#60C69B]">
              <M.Text inherit>{toBrevis ?? "To diff"} total stock</M.Text>
              <M.Text
                inherit
                className="rounded-md bg-white p-0 pl-10 pr-1 text-center"
              >
                {toDiffTotalStock}
              </M.Text>
            </M.Box>
          </M.SimpleGrid>
        </M.Group>
      </M.Group>
      <M.Group position="right" className="pr-10">
        <M.Button onClick={onSave}>Save</M.Button>
        <M.Button onClick={onHold} color="darkGray" variant="light">
          On hold
        </M.Button>
        <M.Button
          color="darkGray"
          variant="light"
          onClick={() => {
            openConfirmModal({
              title: "Are you sure you want to leave?",
              children: <M.Text>Nothing will be saved.</M.Text>,
              labels: { confirm: "Leave", cancel: "Cancel" },
              confirmProps: { color: "red" },
              onConfirm: () => navigate("/inventory/inventory-transfer"),
            });
          }}
        >
          Close
        </M.Button>
      </M.Group>
    </>
  );
};
