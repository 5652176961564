import { RouterPrompt } from "#/components/index.js";
import { trpc } from "#/trpc.js";
import {
  cartEntriesAtom,
  cartEntriesReturnedAtom,
  customerAtom,
  submittedAtom,
} from "./state/index.js";
import { saveCartAtom } from "./state/cart.js";
import { reportAtom } from "./state/reporting.js";
import * as M from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { useAtomValue, useSetAtom } from "jotai";
import React from "react";
import { useNavigate } from "react-router";

const RouterPromptConfirmation = ({ extraButtons }) => {
  const customer = useAtomValue(customerAtom);
  const cartEntries = useAtomValue(cartEntriesAtom);
  const cartEntriesReturned = useAtomValue(cartEntriesReturnedAtom);
  const submitted = useAtomValue(submittedAtom);
  const when =
    (!!customer || cartEntries.length > 0 || cartEntriesReturned.length > 0) &&
    !submitted;

  return <RouterPrompt when={when} extraButtons={extraButtons} />;
};

export function PutOnHoldButton() {
  const navigate = useNavigate();
  const saveCart = useSetAtom(saveCartAtom);
  const report = useAtomValue(reportAtom);
  const customer = useAtomValue(customerAtom);
  const setSubmitted = useSetAtom(submittedAtom);
  const [loading, setLoading] = React.useState(false);
  const ctx = trpc.useContext();

  const putOnHold = async () => {
    setSubmitted(true);
    setLoading(true);
    try {
      if (!customer) {
        report.error({
          id: "make-a-sale__put-on-hold__no-customer",
          title: "Failed to put cart on hold",
          description: "You must add a customer",
        });
        return;
      }
      await saveCart("HOLD");
      ctx.cart.invalidate();
      ctx.cartItemSku.invalidate();
      navigate("/sales");
    } catch (e) {
      showNotification({
        title: "Failed to put cart on hold",
        message: e?.message,
        color: "red",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <RouterPromptConfirmation
        extraButtons={({ onConfirm }) => (
          <M.Button
            variant="light"
            color="darkGray"
            sx={(t) => ({ color: t.colors.gray[8] })}
            onClick={async () => {
              await putOnHold();
              onConfirm();
            }}
          >
            Put on hold
          </M.Button>
        )}
      />
      <M.Button
        variant="light"
        color="darkGray"
        onClick={putOnHold}
        loading={loading}
        sx={{ width: "100%" }}
      >
        Put on hold
      </M.Button>
    </div>
  );
}
