import GTLoader from "#/components-ng/loader.js"
import DisplayTableContainer from "#/components/DisplayTableContainer/index.jsx"
import { makeMantineController } from "#/components/Form/v3/index.js"
import { DisplayTable } from "#/components/index.js"
import { useAuth } from "#/context/AuthContext.js"
import { RouterOutputs, trpc } from "#/trpc.js"
import { reportUserError, reportUserSuccess } from "#/util/index.js"
import * as M from "@mantine/core"
import { atom, useAtom, useAtomValue, useSetAtom } from "jotai"
import React from "react"
import { FormProvider, useForm } from "react-hook-form"
import { MdMoreHoriz } from "react-icons/md/index.js"
import { useNavigate, useParams } from "react-router"
import { ItemSku } from "server"

const defaultMessage =
	"The products you were waiting for at Gold Tree are now available."

type CustomerWaitingList = NonNullable<
	RouterOutputs["userWaitingList"]["getWaitingCustomers"]
>

type NotifyFormValues = {
	itemSku?: ItemSku | null
	smsMessage: string
	emailMessage: string
	messageType: "sms" | "email"
	customers: {
		userId: number
		firstName: string
		lastName?: string | null
		email: string
		phoneNumber: string
		avatar?: string | null
	}[]
}

const Mc = makeMantineController({ ctx: {} as NotifyFormValues })

export const customerWaitingListAtom = atom<CustomerWaitingList>([])
export const itemSkuSelectedAtom = atom<number | null>(null)
export const itemsToNotifyAtom = atom<{
	itemSku: ItemSku | null
	customers: {
		userId: number
		firstName: string
		lastName?: string | null
		email: string
		phoneNumber: string
		avatar?: string | null
	}[]
}>({
	itemSku: null,
	customers: [],
})

export const ReceiveItemsWaitingList = () => {
	const [showDrawer, setShowDrawer] = React.useState<boolean>(false)
	const { id } = useParams()
	const navigate = useNavigate()
	const [customerWaitingList, setCustomerWaitingList] = useAtom(
		customerWaitingListAtom,
	)
	const [itemSkuSelected, setItemSkuSelected] = useAtom(itemSkuSelectedAtom)
	const setItemsToNotify = useSetAtom(itemsToNotifyAtom)

	const { isLoading, isFetchedAfterMount } =
		trpc.userWaitingList.getWaitingCustomers.useQuery(
			{
				purchaseOrderId: Number(id),
			},
			{
				enabled: !!id,
				cacheTime: 0,
				onError(err) {
					reportUserError({
						title: "Failed to get waiting customers",
						message: err.message,
					})
				},
				onSuccess(data) {
					setCustomerWaitingList(data)
				},
			},
		)

	React.useEffect(() => {
		setItemSkuSelected(null)
		setItemsToNotify({
			itemSku: null,
			customers: [],
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const customers = React.useMemo(() => {
		if (itemSkuSelected) {
			return (
				customerWaitingList.find((w) => {
					return w.itemSkuId === itemSkuSelected
				})?.users ?? []
			)
		}
		return null
	}, [customerWaitingList, itemSkuSelected])

	return (
		<M.Container fluid>
			<ReceiveItemsNotifyDrawer
				openDrawer={showDrawer}
				setOpenDrawer={setShowDrawer}
			/>
			<M.Title order={2}>Waiting List</M.Title>
			<M.Text size="sm" color="gray" my={"md"}>
				Choose an item and keep customers informed about your latest additions
			</M.Text>
			{customerWaitingList.length === 0 || isLoading || !isFetchedAfterMount ? (
				<M.LoadingOverlay
					visible={customerWaitingList.length === 0}
					loader={<GTLoader width={100} height={100} />}
				/>
			) : (
				<DisplayTableContainer>
					{itemSkuSelected ? (
						<DisplayTable
							data={customers}
							columns={columnsCustomerWaitingList}
							pagination={false}
						/>
					) : (
						<DisplayTable
							data={customerWaitingList}
							columns={columnsItemSkusWaitingList}
							pagination={false}
						/>
					)}
				</DisplayTableContainer>
			)}
			<M.Group position="right" my={"xl"}>
				<M.Button
					variant="outline"
					color="gray"
					c="gray.7"
					onClick={() => {
						navigate("/inventory/upcoming-items")
					}}
				>
					Cancel
				</M.Button>
				{itemSkuSelected && (
					<>
						<M.Button
							variant="outline"
							color="gray"
							c="gray.7"
							onClick={() => {
								setItemSkuSelected(null)
								setItemsToNotify({
									itemSku: null,
									customers: [],
								})
							}}
						>
							Back To List
						</M.Button>
						<M.Button className="px-8" onClick={() => setShowDrawer(true)}>
							Notify
						</M.Button>
					</>
				)}
			</M.Group>
		</M.Container>
	)
}

export const ReceiveItemsNotifyDrawer = ({
	openDrawer,
	setOpenDrawer,
}: {
	openDrawer: boolean
	setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>
}) => {
	const [auth] = useAuth()
	const itemSkuSelected = useAtomValue(itemSkuSelectedAtom)
	const [itemsToNotify, setItemsToNotify] = useAtom(itemsToNotifyAtom)
	const setCustomerWaitingList = useSetAtom(customerWaitingListAtom)
	const [filialId, setFilialId] = React.useState<number | null>(() => {
		return auth.auth?.user?.filialId ?? null
	})

	const { data: allFilials } = trpc.filial.getAll.useQuery(undefined, {
		cacheTime: 0,
		enabled: !auth.auth?.user?.filialId,
	})

	const filials = useMemo(() => {
		return (
			allFilials?.map((f) => ({
				label: f.name,
				value: f.id.toString(),
			})) ?? []
		)
	}, [allFilials])

	const handleCleanCustomerNotified = () => {
		setCustomerWaitingList((prev) => {
			const newCustomerWaitingList = prev.map((w) => {
				if (w.itemSkuId === itemSkuSelected) {
					return {
						...w,
						users: w.users.filter(
							(u) => !itemsToNotify.customers.some((c) => c.userId === u.id),
						),
					}
				}
				return w
			})
			return newCustomerWaitingList
		})
		setItemsToNotify({
			...itemsToNotify,
			customers: [],
		})
	}

	const { mutate: sendEmail } = trpc.userWaitingList.email.useMutation({
		onSuccess() {
			handleCleanCustomerNotified()
			setOpenDrawer(false)
			reportUserSuccess({
				title: "Email sent",
			})
		},
		onError(err) {
			reportUserError({
				title: "Failed to send email",
				message: err.message,
			})
		},
	})

	const { mutate: sendSms } = trpc.userWaitingList.sms.useMutation({
		onSuccess() {
			handleCleanCustomerNotified()
			setOpenDrawer(false)
			reportUserSuccess({
				title: "SMS sent",
			})
		},
		onError(err) {
			reportUserError({
				title: "Failed to send SMS",
				message: err.message,
			})
		},
	})

	const form = useForm<NotifyFormValues>({
		defaultValues: {
			smsMessage: defaultMessage,
			emailMessage: defaultMessage,
			customers: [],
			messageType: "email",
		},
	})

	React.useEffect(() => {
		form.setValue("customers", itemsToNotify.customers)
	}, [form, itemsToNotify.customers])

	const handleSend = async (values: NotifyFormValues) => {
		if (!auth.auth?.user.filialId && !filialId) {
			setOpenDrawer(false)
			reportUserError({
				title: "Failed to send notification",
				message: "You need select a filial",
			})
		}

		if (values.messageType === "email" && filialId) {
			sendEmail({
				filialId: filialId,
				message: values.emailMessage,
				users: values.customers.map((c) => c.userId),
				itemSkus: [itemSkuSelected!],
			})
		}

		if (values.messageType === "sms" && filialId) {
			sendSms({
				filialId: filialId,
				message: values.smsMessage,
				users: values.customers.map((c) => c.userId),
				itemSkus: [itemSkuSelected!],
			})
		}
	}

	const type = form.watch("messageType")

	return (
		<M.Drawer
			opened={openDrawer && itemSkuSelected !== null}
			onClose={() => {
				setOpenDrawer(false)
			}}
			position="right"
			size="xl"
			padding="xl"
			overlayProps={{
				opacity: 0.6,
			}}
		>
			<M.Title order={3} mb="md">
				Send notification
			</M.Title>
			<FormProvider {...form}>
				<form onSubmit={form.handleSubmit(handleSend)}>
					<M.Tabs
						value={type}
						onTabChange={(tab) => {
							const newType = tab
							if (newType !== "sms" && newType !== "email") {
								throw new Error("Only SMS and Email messages allowed")
							}
							form.setValue("messageType", newType)
							if (newType === "sms") {
								form.setValue("emailMessage", defaultMessage)
							}
							if (newType === "email") {
								form.setValue("smsMessage", defaultMessage)
							}
						}}
						styles={(t) => ({
							tabsList: { borderBottom: `solid 1px ${t.colors.gray[2]}` },
						})}
					>
						<M.Tabs.List>
							<M.Tabs.Tab value="sms">SMS</M.Tabs.Tab>
							<M.Tabs.Tab value="email">Email</M.Tabs.Tab>
						</M.Tabs.List>
					</M.Tabs>
					<M.Stack spacing={"md"}>
						<M.Title order={4} mt="md">
							Customers
						</M.Title>
						<M.Stack>
							{itemsToNotify.customers.map((customer) => (
								<M.Group noWrap key={customer.userId}>
									<M.Avatar src={customer.avatar} radius={"xl"} />
									<div>
										<M.Text size="sm">
											{customer.firstName} {customer?.lastName ?? ""}
										</M.Text>
										<M.Text size="xs" opacity={0.65}>
											{type === "email" ? customer.email : customer.phoneNumber}
										</M.Text>
									</div>
								</M.Group>
							))}
						</M.Stack>
						<M.Divider color="gray.2" mt="sm" />
						<M.Title order={4} mt="md">
							Product
						</M.Title>
						<M.Stack>
							<M.Group noWrap position="apart">
								<M.Group noWrap>
									<M.Image
										src={itemsToNotify.itemSku?.defaultImage}
										radius={"sm"}
										width={"51px"}
										height={"51px"}
									/>
									<div>
										<M.Text size="sm">{itemsToNotify.itemSku?.title}</M.Text>
										<M.Text size="xs" opacity={0.65}>
											SKU: {itemsToNotify.itemSku?.sku}
										</M.Text>
									</div>
								</M.Group>
								<M.Text size="sm" weight="600" color="#60C69B" bg="#F3FFFA">
									${itemsToNotify.itemSku?.price?.toNumber().toFixed(2) ?? 0}
								</M.Text>
							</M.Group>
						</M.Stack>
						<M.Divider color="gray.2" mt="sm" />
						<M.Title order={4} mt="md">
							Message
						</M.Title>
						{type === "email" ? (
							<Mc
								as={M.Textarea}
								name="emailMessage"
								placeholder="Your message here"
								required={type === "email"}
								autosize
								minRows={4}
								maxRows={7}
							/>
						) : (
							<Mc
								as={M.Textarea}
								name="smsMessage"
								placeholder="Your message here"
								description="Max. 160 characters"
								inputWrapperOrder={["label", "input", "description"]}
								required={type === "sms"}
								maxLength={160}
								autosize
								minRows={4}
								maxRows={7}
							/>
						)}
						{!auth.auth?.user.filialId && (
							<M.Select
								label="Select a filial"
								value={filialId?.toString() ?? undefined}
								onChange={(e) => setFilialId(e ? Number(e) : null)}
								data={filials}
							/>
						)}
						<M.Group position="right">
							<M.Button type="submit">Send</M.Button>
							<M.Button variant="light" onClick={() => setOpenDrawer(false)}>
								Cancel
							</M.Button>
						</M.Group>
					</M.Stack>
				</form>
			</FormProvider>
		</M.Drawer>
	)
}

const columnsItemSkusWaitingList = [
	{
		Header: "Actions",
		id: "actions",
		Cell: ({ row: { original } }) => {
			const setItemSkuSelected = useSetAtom(itemSkuSelectedAtom)
			const setItemsToNotify = useSetAtom(itemsToNotifyAtom)

			return (
				<M.Group noWrap>
					<M.Menu>
						<M.Menu.Target>
							<M.ActionIcon>
								<MdMoreHoriz />
							</M.ActionIcon>
						</M.Menu.Target>
						<M.Menu.Dropdown>
							<M.Menu.Item
								onClick={() => {
									setItemSkuSelected(Number(original.itemSkuId))
									setItemsToNotify((prev) => ({
										...prev,
										itemSku: original.itemSku,
									}))
								}}
							>
								Notify Customer
							</M.Menu.Item>
						</M.Menu.Dropdown>
					</M.Menu>
				</M.Group>
			)
		},
	},
	{
		Header: "Image",
		accesor: "itemSku.defaultImage",
		type: "image",
	},
	{
		Header: "Sku",
		accessor: "itemSku.sku",
	},
	{
		Header: "Product",
		accessor: "itemSku.title",
	},
	{
		Header: "PKG",
		accessor: "itemSku.presentationValue",
		Cell: ({ value, row: { original } }) => (
			<M.Text>
				{value} {original.itemSku.presentationType}
			</M.Text>
		),
	},
	{
		Header: "Size",
		accesor: "itemSku.width",
		Cell: ({ row: { original } }) => (
			<M.Text>
				{`${original.itemSku.width ?? "-"}x${original.itemSku.height ?? "-"}x${
					original.itemSku.length ?? "-"
				}`}
			</M.Text>
		),
	},
]

const columnsCustomerWaitingList = [
	{
		Header: "Notify",
		accesor: "notify",
		Cell: ({ row: { original } }) => {
			const [itemsToNotify, setItemsToNotify] = useAtom(itemsToNotifyAtom)
			const setCustomerWaitingList = useSetAtom(customerWaitingListAtom)

			return (
				<M.Checkbox
					checked={original?.notify}
					onChange={(e) => {
						setCustomerWaitingList((prev) => {
							if (e.currentTarget.checked) {
								return prev.map((w) => {
									if (
										itemsToNotify?.itemSku &&
										w.itemSkuId === itemsToNotify.itemSku.id
									) {
										return {
											...w,
											users: w.users.map((u) => {
												if (u.id === original.id) {
													return {
														...u,
														notify: true,
													}
												}
												return u
											}),
										}
									}
									return w
								})
							} else {
								return prev.map((w) => {
									if (
										itemsToNotify?.itemSku &&
										w.itemSkuId === itemsToNotify.itemSku.id
									) {
										return {
											...w,
											users: w.users.map((u) => {
												if (u.id === original.id) {
													return {
														...u,
														notify: false,
													}
												}
												return u
											}),
										}
									}
									return w
								})
							}
						})

						setItemsToNotify(() => {
							if (e.currentTarget.checked) {
								return {
									...itemsToNotify,
									customers: [
										...itemsToNotify.customers,
										{
											userId: original.id,
											firstName: original.firstName,
											lastName: original.lastName,
											email: original.email,
											phoneNumber: original.phoneNumber,
										},
									],
								}
							} else {
								return {
									...itemsToNotify,
									customers: itemsToNotify.customers.filter(
										(c) => c.userId !== original.id,
									),
								}
							}
						})
					}}
				/>
			)
		},
	},
	{
		Header: "Customer",
		accessor: "firstName",
		Cell: ({ value, row: { original } }) => (
			<M.Text>
				{value} {original?.lastName ?? ""}
			</M.Text>
		),
	},
	{
		Header: "Email",
		accessor: "email",
	},
	{
		Header: "Phone Number",
		accessor: "phoneNumber",
	},
	{
		Header: "Date",
		accessor: "createdAt",
		isEpochDate: true,
		Cell: "epochDate",
	},
]
