import { trpc } from "#/trpc";
import { modals } from "@mantine/modals";
import dayjs from "dayjs";
import { VStack } from "#/css/jsx";
import { css } from "#/css/css";
import { DatePickerInput } from "@mantine/dates";
import CalendarIcon from "~icons/ion/calendar-clear-outline";
import {
  TransferList,
  TransferListData,
  TransferListItem,
} from "@mantine/core";
import { reportUserError, reportUserSuccess } from "#/util";
import { Button } from "@gt/ui";

export function openSendOrdersPdfsModal(customerId: number) {
  modals.open({
    size: "lg",
    children: <SendOrdersPdfsModalChildren customerId={customerId} />,
    modalId: "SEND-ORDERS-PDFS-MODAL",
  });
}

interface SendOrdersPdfsModalChildrenProps {
  customerId: number;
}

const SendOrdersPdfsModalChildren = (
  input: SendOrdersPdfsModalChildrenProps,
) => {
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    dayjs().subtract(1, "M").toDate(),
    new Date(),
  ]);

  const { data } = trpc.v2_5.order.getByRangeDateAndCustomer.useQuery(
    {
      customerId: input.customerId,
      startDate: dateRange[0]!,
      endDate: dateRange[1]!,
    },
    {
      enabled: dateRange[0] && dateRange[1] ? true : false,
      cacheTime: 0,
    },
  );

  const orders = useMemo(
    () =>
      data?.map((order) => ({
        label: `#${order.receiptNumber} - ${dayjs(order.createdAt).format(
          "MM/DD/YYYY",
        )}`,
        value: order.id.toString(),
      })) ?? [],
    [data],
  );

  return (
    <VStack gap="5">
      <p
        className={css({
          fontSize: "20px",
          fontWeight: "medium",
        })}
      >
        Send Invoices PDFs
      </p>
      <DatePickerInput
        type="range"
        value={dateRange}
        clearable={false}
        popoverProps={{
          withinPortal: true,
          zIndex: 9999,
        }}
        numberOfColumns={2}
        onChange={(value) => setDateRange(value)}
        allowSingleDateInRange
        icon={<CalendarIcon color="#424242" />}
      />
      <TransferListSection data={orders} />
    </VStack>
  );
};

interface TransferListSectionProps {
  data: TransferListItem[];
}

const TransferListSection = (props: TransferListSectionProps) => {
  const [transferList, setTransferList] = useState<TransferListData>([[], []]);

  useEffect(() => {
    setTransferList([props.data, transferList[1]]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data]);

  return (
    <>
      <TransferList
        value={transferList}
        onChange={setTransferList}
        searchPlaceholder="Search..."
        nothingFound="Nothing here"
        titles={["Orders", "Orders Selected"]}
      />
      <SendTransferListButton ordersSelected={transferList[1]} />
    </>
  );
};

interface SendTransferListButtonProps {
  ordersSelected: TransferListItem[];
}

const SendTransferListButton = (props: SendTransferListButtonProps) => {
  const { mutate, isLoading } = trpc.v2_5.order.sendInvoicesEmail.useMutation({
    onSuccess() {
      modals.close("SEND-ORDERS-PDFS-MODAL");
      reportUserSuccess({
        title: "Email sent",
        message: "Email sent successfully, please wait few seconds",
      });
    },
  });

  const handleSubmit = () => {
    if (props.ordersSelected.length === 0) {
      reportUserError({
        title: "Please select at least one order to send email",
      });
      return;
    }

    mutate({
      orderIds: props.ordersSelected.map((order) => Number(order.value)),
    });
  };

  return (
    <Button isLoading={isLoading} onClick={handleSubmit}>
      Send email
    </Button>
  );
};
