import { makeController } from "#/components-ng";
import { RouterOutputs, trpc } from "#/trpc";
import { reportUserError, reportUserSuccess } from "#/util";
import { useOrder } from "../use-order";
import { Button, Checkbox, Field, Form, Input, Select, Table } from "@gt/ui";
import { zodResolver } from "@hookform/resolvers/zod";
import * as M from "@mantine/core";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { z } from "zod";

const selectedOrderItemSkusAtom = atom<number[]>([]);

export const CreatePackageButton = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);

  return (
    <>
      <CreatePackageDrawer opened={openModal} setOpened={setOpenModal} />
      <M.Button onClick={() => setOpenModal(true)}>
        Create new shipping package
      </M.Button>
    </>
  );
};

interface CreatePackageModalProps {
  opened: boolean;
  setOpened: (opened: boolean) => void;
}

const ShippingStatuses = ["PROCESSING", "SHIPPING", "DELIVERED"] as const;

const PackageFormSchema = z.object({
  shippingNumber: z.string().nonempty(),
  shippingCompany: z.string().nonempty(),
  shippingStatus: z.enum(ShippingStatuses),
});

type PackageFormSchema = z.infer<typeof PackageFormSchema>;

const { useForm: usePackageForm } = makeController<PackageFormSchema>();

const CreatePackageDrawer = (props: CreatePackageModalProps) => {
  const { data: order } = useOrder();
  const [selectedItemSkus, setSelectedItemSkus] = useAtom(
    selectedOrderItemSkusAtom
  );
  const ctx = trpc.useContext();

  const form = usePackageForm({
    values: {
      shippingNumber: "",
      shippingCompany: "",
      shippingStatus: "PROCESSING",
    },
    resolver: zodResolver(PackageFormSchema),
    resetOptions: {
      keepDirtyValues: true,
    },
  });

  const { mutate, isLoading } = trpc.v2_5.package.createPackage.useMutation({
    onSuccess: () => {
      form.reset();
      setSelectedItemSkus([]);
      props.setOpened(false);
      ctx.order.pos.ecommerceOrders.invalidate();
      reportUserSuccess({
        title: "Package shipping created",
      });
    },
  });

  const handleSubmit = (values: PackageFormSchema) => {
    if (!order) {
      reportUserError({
        title: "There is no order to update",
      });
      return;
    }

    if (selectedItemSkus.length === 0) {
      reportUserError({
        title: "You must select at least one item to create a package",
      });
      return;
    }

    mutate({
      orderId: order.id,
      shippingCompany: values.shippingCompany,
      shippingNumber: values.shippingNumber,
      shippingStatus: values.shippingStatus,
      items: selectedItemSkus,
    });
  };

  return (
    <M.Drawer
      opened={props.opened}
      onClose={() => props.setOpened(false)}
      size="auto"
      padding="xl"
      zIndex={44}
      position="right"
    >
      <Form.Root {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          <M.Stack spacing="md" p={20}>
            <M.Title order={3} mb={10}>
              Create New Shipping
            </M.Title>
            <M.Group align="end" position="apart" spacing={10}>
              <M.Grid columns={3}>
                <M.Grid.Col span={1}>
                  <Form.Field
                    control={form.control}
                    name="shippingNumber"
                    render={({ field }) => (
                      <Field.Root>
                        <Field.Label>Tracking number</Field.Label>
                        <Field.Control>
                          <Input placeholder="ABC123" {...field} />
                        </Field.Control>
                        <Field.Message />
                      </Field.Root>
                    )}
                  />
                </M.Grid.Col>
                <M.Grid.Col span={1}>
                  <Form.Field
                    control={form.control}
                    name="shippingCompany"
                    render={({ field }) => (
                      <Field.Root>
                        <Field.Label>Shipping company</Field.Label>
                        <Field.Control>
                          <Input placeholder="Fedex" {...field} />
                        </Field.Control>
                        <Field.Message />
                      </Field.Root>
                    )}
                  />
                </M.Grid.Col>
                <M.Grid.Col span={1}>
                  <Form.Field
                    control={form.control}
                    name="shippingStatus"
                    render={({ field }) => (
                      <Field.Root>
                        <Field.Label>Status</Field.Label>
                        <Select.Root
                          onValueChange={field.onChange}
                          value={field.value}
                        >
                          <Field.Control>
                            <Select.Trigger>{field.value}</Select.Trigger>
                          </Field.Control>
                          <Select.Content>
                            {ShippingStatuses.map((status) => (
                              <Select.Item key={status} value={status}>
                                {status === "SHIPPING" ? "SHIPPED" : status}
                              </Select.Item>
                            ))}
                          </Select.Content>
                        </Select.Root>
                        <Field.Message />
                      </Field.Root>
                    )}
                  />
                </M.Grid.Col>
              </M.Grid>
              <Button type="submit" isLoading={isLoading}>
                Save changes
              </Button>
            </M.Group>
            <M.Divider />
            <M.Group position="apart">
              <M.Text size="sm" weight={500}>
                Select items to add to the package for shipping
              </M.Text>
              <M.Text>
                <M.Text size="sm" weight={500}>
                  Items
                </M.Text>
                <M.Text size="sm" color="gray">
                  {selectedItemSkus.length} items selected
                </M.Text>
              </M.Text>
            </M.Group>
            <OrderItemSkuTable />
          </M.Stack>
        </form>
      </Form.Root>
    </M.Drawer>
  );
};

const OrderItemSkuTable = () => {
  const { data: order } = useOrder();

  const orderItemSkus = useMemo(() => {
    return (
      order?.orderItemSku?.filter((item) => !item.packageItem.length) ?? []
    );
  }, [order]);

  const table = useReactTable({
    columns,
    data: orderItemSkus,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <M.Box pos="relative" h={450} className="overflow-auto">
      <Table.Root>
        <Table.Header>
          {table.getHeaderGroups().map((headerGroup) => (
            <Table.Row key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Table.Head key={header.id} className="sticky top-0 bg-white">
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </Table.Head>
              ))}
            </Table.Row>
          ))}
        </Table.Header>
        <Table.Body>
          {table.getRowModel().rows.map((row) => (
            <Table.Row key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <Table.Cell key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Table.Cell>
              ))}
            </Table.Row>
          ))}
        </Table.Body>
      </Table.Root>
    </M.Box>
  );
};

type OrderItemSku = NonNullable<
  RouterOutputs["order"]["pos"]["ecommerceOrders"]["ecommerceOrder"]["get"]
>["orderItemSku"][number];

const column = createColumnHelper<OrderItemSku>();

const columns = [
  column.display({
    id: "Actions",
    header: function Cell(table) {
      const [, setSelectedItemSkus] = useAtom(selectedOrderItemSkusAtom);

      function changeActiveState(active: boolean) {
        if (active) {
          setSelectedItemSkus(table.table.options.data.map((item) => item.id));
        } else {
          setSelectedItemSkus([]);
        }
      }

      return (
        <Checkbox
          onCheckedChange={(v) => {
            if (v === "indeterminate") return;
            changeActiveState(v);
          }}
        />
      );
    },
    cell: function Cell(table) {
      const [selectedItemSkus, setSelectedItemSkus] = useAtom(
        selectedOrderItemSkusAtom
      );

      const isActive = selectedItemSkus.includes(table.row.original.id);

      function changeActiveState(active: boolean) {
        if (active) {
          setSelectedItemSkus([...selectedItemSkus, table.row.original.id]);
        } else {
          setSelectedItemSkus(
            selectedItemSkus.filter((id) => id !== table.row.original.id)
          );
        }
      }

      return (
        <Checkbox
          checked={isActive}
          onCheckedChange={(v) => {
            if (v === "indeterminate") return;
            changeActiveState(v);
          }}
        />
      );
    },
  }),
  column.accessor("itemSku.sku", {
    header: "SKU",
  }),
  column.accessor("itemSku.title", {
    header: "Title",
  }),
  column.accessor("quantity", {
    header: "Qty",
  }),
];
