import {
  ImageUpload,
  UseS3UploadObject,
  UseS3UploadResult,
} from "#/components-ng/ui";
import { Drawer } from "@mantine/core";
import { styled } from "#/css/jsx";
import { css } from "#/css/css";
import { v4 as uuidv4 } from "uuid";
import { CatalogFormImage } from "./catalog-form-image";
import { CatalogImageFormValues, CI, createCatalogImageSchema } from "./types";
import { zodResolver } from "@hookform/resolvers/zod";
import { DefaultValues, FormProvider, SubmitHandler } from "react-hook-form";
import { Button } from "@gt/ui";

interface CatalogImageFormDrawerProps {
  opened: boolean;
  onClose: () => void;
  title: string;
  onSubmit: SubmitHandler<CatalogImageFormValues>;
  isLoading: boolean;
  defaultValues?: DefaultValues<CatalogImageFormValues>;
  catalogId?: number | null;
}
interface ImageUploadProps {
  url: string;
  uuid: string;
  isLoading: boolean;
}

export function CatalogImageFormDrawer(props: CatalogImageFormDrawerProps) {
  const form = CI.useForm({
    resolver: zodResolver(createCatalogImageSchema),
    shouldUnregister: false,
    defaultValues: {
      image: null,
      ...props.defaultValues,
    },
    resetOptions: {
      keepDirtyValues: true,
    },
    shouldFocusError: false,
  });

  const [image, setImage] = useState<ImageUploadProps | null>(() =>
    form.getValues("image")
      ? {
          url: form.getValues("image")!.url,
          uuid: uuidv4(),
          isLoading: false,
        }
      : null,
  );

  const handleS3UploadImageStart = (upload: UseS3UploadObject) => {
    setImage({
      url: upload.url!,
      uuid: uuidv4(),
      isLoading: true,
    });
  };

  const handleS3UploadImageSuccess = (upload: UseS3UploadResult) => {
    setImage({
      url: upload.url!,
      uuid: uuidv4(),
      isLoading: false,
    });
    form.setValue("image", {
      url: upload.url!,
      uid: uuidv4(),
      isLoading: false,
      tags: [],
    });
  };

  const removeImage = () => {
    setImage(null);
    form.setValue("image", null);
  };

  return (
    <Drawer
      opened={props.opened}
      onClose={props.onClose}
      size="md"
      padding="xl"
      position="right"
      zIndex={50}
      title={<h2>{props.title}</h2>}
    >
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit((values) => {
            form.reset();
            props.onSubmit(values);
            setImage(null);
          })}
        >
          <ImageUpload
            directory="catalog-images"
            onS3UploadingStart={handleS3UploadImageStart}
            onS3UploadSuccess={handleS3UploadImageSuccess}
            supports={{
              image: true,
            }}
          />
          <styled.div mt={image ? 6 : 0}>
            {image && (
              <CatalogFormImage
                key={image.uuid}
                index={0}
                img={image}
                removeImage={removeImage}
              />
            )}
          </styled.div>
          <Button
            type="submit"
            className={css({
              width: "fit",
              marginTop: "10px",
            })}
            isLoading={props.isLoading}
          >
            Save catalog image
          </Button>
        </form>
      </FormProvider>
    </Drawer>
  );
}
