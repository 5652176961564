import { Flex, Stack, Text } from "@mantine/core";
import React from "react";
import { Link } from "react-router-dom";

interface CardLayoutProps {
  title: string;
  children: React.ReactNode;
  id?: number;
  route?: string;
}

export const CardLayout = ({ children, title, id, route }: CardLayoutProps) => {
  return (
    <Stack mt="xl">
      <Flex justify="space-between">
        <Text className="text-sm font-semibold">{title}</Text>
        {id && (
          <Link
            to={`${route}/${id}`}
            className="text-sm font-semibold text-sky-500 underline"
          >
            View all
          </Link>
        )}
      </Flex>
      <div className="flex flex-col items-start gap-2.5 self-stretch overflow-x-auto rounded-sm border border-[#E0E0E0] p-5">
        {children}
      </div>
    </Stack>
  );
};

interface CardLayourMultipleProps {
  title: string;
  childrens: React.ReactNode[];
}

export const CardLayoutMultiple = ({
  childrens,
  title,
}: CardLayourMultipleProps) => {
  return (
    <Stack mt="xl">
      <Text className="text-sm font-semibold">{title}</Text>
      {childrens.map((children, index) => (
        <div
          key={index}
          className="flex flex-col items-start gap-2.5 self-stretch overflow-x-auto rounded-sm border border-[#E0E0E0] p-5"
        >
          {children}
        </div>
      ))}
    </Stack>
  );
};
