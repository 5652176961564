import { trpc } from "#/trpc.js";
import { reportUserError, reportUserSuccess } from "#/util/ux/index.js";
import VendorsForm, { VendorFormValues } from "./Form.js";
import { useNavigate } from "react-router-dom";

export default function CreateVendor() {
  const ctx = trpc.useContext();
  const navigate = useNavigate();

  const { mutate, isLoading } = trpc.vendor.create.useMutation({
    onSuccess() {
      ctx.v2_5.vendor.invalidate();
      reportUserSuccess({
        title: "Vendor created",
        message: "Vendor created successfully",
      });
      navigate("/inventory/vendors");
    },
    onError(error) {
      reportUserError({
        title: "Failed to create vendor",
        message: error.message,
      });
    },
  });

  const handleSubmit = (f: VendorFormValues) => {
    mutate({
      email: f.email,
      contactPersonName: f.contactPersonName,
      seoName: f.seoName,
      phoneNumber: f?.phoneNumber ?? "",
      description: f?.description ?? "",
      areaCode: f?.areaCode ?? undefined,
      countryCode: f?.countryCode ?? undefined,
      company: f?.company ?? "",
      defaultImage: f?.defaultImage ?? "",
      note: f?.note ?? "",
      street: f?.address?.street ?? "",
      city: f?.address?.city ?? "",
      state: f?.address?.state ?? "",
      country: f?.address?.country ?? "",
      zip: f?.address?.zip ?? "",
      vendorRateCost: {
        createMany: {
          data:
            f?.rateCosts?.map((rateCost) => ({
              rateCostId: Number(rateCost.id),
            })) ?? [],
        },
      },
    });
  };

  return <VendorsForm onSubmit={handleSubmit} loading={isLoading} />;
}
