import { FilterOutlineIcon } from "#/components-ng/index.js";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Filter, FilterKey } from "./$common";
import { Button, Checkbox, Dropdown } from "@gt/ui";
import { DropdownMenu } from "@radix-ui/themes";
import { css } from "#/css/css";
import { HStack } from "#/css/jsx";
import { SearchButton } from "#/components@v2_5/search-button";
import { UpcomingItemsTable } from "./table";

export const UpcomingItems = () => {
  const [search, setSearch] = useState<string | null>(null);
  const [filters, setFilters] = useState<Record<FilterKey, boolean>>(
    () =>
      Object.fromEntries(
        Object.keys(Filter).map((key) => [
          key,
          key === "UPCOMING" || key === "DRAFT" ? true : false,
        ]),
      ) as any,
  );

  function selectFilter(filter: FilterKey) {
    setFilters((old) => ({
      ...old,
      [filter]: !old[filter],
    }));
  }

  return (
    <>
      <HStack justify="space-between" gap={3}>
        <HStack width="100%" gap={3}>
          <DrowndownButton />
          <PurchaseOrderStatusDropdown
            filters={filters}
            selectFilter={selectFilter}
          />
        </HStack>
        <SearchButton setQuery={setSearch} />
      </HStack>
      <div className={css({ marginTop: 15 })}>
        <UpcomingItemsTable
          filter={
            Object.keys(filters).filter((key) => filters[key]) as FilterKey[]
          }
          search={search}
        />
      </div>
    </>
  );
};

const DrowndownButton = () => {
  return (
    <Dropdown.Root>
      <Dropdown.Trigger>
        <Button
          className={css({
            gap: 4,
          })}
        >
          I want to
          <DropdownMenu.TriggerIcon />
        </Button>
      </Dropdown.Trigger>
      <Dropdown.Content>
        <Dropdown.Item>
          <Link to="create-po/new-items">Create PO: New items</Link>
        </Dropdown.Item>
        <Dropdown.Item>
          <Link to="create-po/restock">Create PO: Restock</Link>
        </Dropdown.Item>
      </Dropdown.Content>
    </Dropdown.Root>
  );
};

interface PurchaseOrderStatusDropdownProps {
  filters: Record<FilterKey, boolean>;
  selectFilter: (filter: FilterKey) => void;
}

const PurchaseOrderStatusDropdown = (
  props: PurchaseOrderStatusDropdownProps,
) => {
  return (
    <Dropdown.Root>
      <Dropdown.Trigger>
        <Button variant="secondary">
          <FilterOutlineIcon
            className={css({
              marginRight: 2,
            })}
          />
          Filter
        </Button>
      </Dropdown.Trigger>
      <Dropdown.Content
        className={css({
          display: "grid",
          gridGap: "0.1rem",
        })}
        loop
      >
        {Object.entries(props.filters)
          .filter(([key]) => key !== "PROCESSING")
          .map(([filterKey, isFilterActive], i) => (
            <Dropdown.Item
              key={i}
              className={css({
                display: "flex",
                alignItems: "center",
                gap: 2,
              })}
              onSelect={(e) => {
                e.preventDefault();
                props.selectFilter(filterKey as FilterKey);
              }}
            >
              <Checkbox checked={isFilterActive} />
              <span>{Filter[filterKey]}</span>
            </Dropdown.Item>
          ))}
      </Dropdown.Content>
    </Dropdown.Root>
  );
};
