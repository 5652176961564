import {
  DefaultValues,
  FormProvider,
  SubmitHandler,
  useController,
  useFormContext,
  useWatch,
} from "react-hook-form";
import {
  C,
  createSysChangeSetSchema,
  FormValues,
  sysChangeSetTypes,
} from "./types";
import { css } from "#/css/css";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@gt/ui";
import { RouterOutputs, trpc } from "#/trpc";
import {
  Combobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from "#/components-ng";
import { useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import { Link, RichTextEditor } from "@mantine/tiptap";
import TextAlign from "@tiptap/extension-text-align";
import * as M from "@mantine/core";
import {
  ImageUpload,
  UseS3UploadObject,
  UseS3UploadResult,
} from "#/components-ng/ui";
import { v4 as uuidv4 } from "uuid";

export interface SysChangeSetFormProps {
  onSubmit: SubmitHandler<FormValues>;
  isLoading: boolean;
  defaultValues?: DefaultValues<FormValues>;
}

interface UploadProps {
  url: string;
  uuid: string;
  isLoading: boolean;
}

export const SysChangeSetForm = (props: SysChangeSetFormProps) => {
  const form = C.useForm({
    resolver: zodResolver(createSysChangeSetSchema),
    shouldUnregister: false,
    defaultValues: {
      title: "",
      reason: "",
      sysSectionId: null,
      image: null,
      video: null,
      requiredReload: true,
      type: "FEATURE",
      ...props.defaultValues,
    },
  });

  const [image, setImage] = useState<UploadProps | null>(() =>
    form.getValues(`image`)
      ? {
          url: form.getValues(`image`)!,
          uuid: uuidv4(),
          isLoading: false,
        }
      : null,
  );

  const [video, setVideo] = useState<UploadProps | null>(() =>
    form.getValues(`video`)
      ? {
          url: form.getValues(`video`)!,
          uuid: uuidv4(),
          isLoading: false,
        }
      : null,
  );

  const handleS3UploadImageStart = (upload: UseS3UploadObject) => {
    setImage({
      url: upload.url!,
      uuid: upload.uuid,
      isLoading: true,
    });
  };

  const handleS3UploadImageSuccess = (upload: UseS3UploadResult) => {
    setImage({
      url: upload.url!,
      uuid: upload.uuid,
      isLoading: false,
    });
    form.setValue(`image`, upload.url);
  };

  const handleS3UploadVideoStart = (upload: UseS3UploadObject) => {
    setVideo({
      url: upload.url!,
      uuid: upload.uuid,
      isLoading: true,
    });
  };

  const handleS3UploadVideoSuccess = (upload: UseS3UploadResult) => {
    setVideo({
      url: upload.url!,
      uuid: upload.uuid,
      isLoading: false,
    });
    form.setValue(`video`, upload.url);
  };

  return (
    <div
      className={css({
        backgroundColor: "white",
        padding: "40px",
        borderRadius: "lg",
      })}
    >
      <h2
        className={css({
          color: "black",
          fontSize: "26px",
          fontWeight: "bold",
          marginBottom: "30px",
        })}
      >
        Sys-ChangeSet
      </h2>
      <FormProvider {...form}>
        <form onSubmit={(e) => form.handleSubmit(props.onSubmit)(e)}>
          <div
            className={css({
              display: "flex",
              gap: 30,
              marginBottom: 30,
              justifyContent: "left",
              flexWrap: "nowrap",
              alignContent: "initial",
            })}
          >
            <div
              className={css({
                display: "flex",
                flexDirection: "column",
                gap: 35,
                maxWidth: "500px",
              })}
            >
              <C.InputField name="title" label="Title *" required />
              <TextRichInput />
              <SysSectionField />
              <C.SelectField
                name="type"
                label="Type *"
                required
                data={sysChangeSetTypes.map((type) => ({
                  id: type,
                  label: type,
                }))}
              />
              <C.SwitchField name="requiredReload" label="Required Reload" />
              <Button
                type="submit"
                isLoading={props.isLoading || image?.isLoading}
                className={css({
                  marginTop: "30px",
                })}
              >
                Save
              </Button>
            </div>

            <div
              className={css({
                display: "flex",
                flexDirection: "column",
                gap: 35,
                maxWidth: "500px",
              })}
            >
              <p
                className={css({
                  fontSize: "14px",
                  lineHeight: "20px",
                  fontWeight: 500,
                  color: "#64748B",
                })}
              >
                Image
              </p>
              <ImageUpload
                directory="changelog/changesets"
                supports={{
                  image: true,
                }}
                onS3UploadSuccess={handleS3UploadImageSuccess}
                onS3UploadingStart={handleS3UploadImageStart}
                hiddenVisually={true}
              />
              {image?.url && (
                <img
                  src={image.url}
                  alt="Image"
                  className={css({
                    width: "100%",
                    maxWidth: "500px",
                    height: "auto",
                    borderRadius: "lg",
                    border: "1px solid #E0E7FF",
                  })}
                />
              )}

              <p
                className={css({
                  fontSize: "14px",
                  lineHeight: "20px",
                  fontWeight: 500,
                  color: "#64748B",
                })}
              >
                Video
              </p>
              <ImageUpload
                directory="changelog/changesets"
                supports={{
                  video: true,
                }}
                onS3UploadSuccess={handleS3UploadVideoSuccess}
                onS3UploadingStart={handleS3UploadVideoStart}
                hiddenVisually={true}
              />
              {video?.url && (
                <video
                  src={video.url}
                  className={css({
                    width: "100%",
                    maxWidth: "500px",
                    height: "auto",
                    borderRadius: "lg",
                    border: "1px solid #E0E7FF",
                  })}
                  controls={true}
                />
              )}
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

type SysSection =
  RouterOutputs["v2_5"]["changelog"]["sysSections"]["search"][number];

const SysSectionField = () => {
  const [query, setQuery] = useState<string>("");
  const { data } = trpc.v2_5.changelog.sysSections.search.useQuery({
    query: query,
  });
  const form = useFormContext();
  const controller = useController({
    name: "sysSectionId",
    control: form.control,
  });

  return (
    <C.Field name="sysSectionId" label="SysSection *">
      {({ id }) => (
        <Combobox
          value={controller.field.value}
          onChange={controller.field.onChange}
          className="w-full"
        >
          <ComboboxInput
            displayValue={(v?: SysSection) => v?.name ?? ""}
            onChange={(e) => setQuery(e.currentTarget.value)}
            id={id}
            required
          />
          <ComboboxOptions>
            {data?.map((sysSection) => (
              <ComboboxOption key={sysSection.id} value={sysSection}>
                {sysSection.name}
              </ComboboxOption>
            ))}
          </ComboboxOptions>
        </Combobox>
      )}
    </C.Field>
  );
};

const TextRichInput = () => {
  const form = useFormContext();
  const reason = useWatch({
    name: "reason",
    control: form.control,
  });

  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      TextAlign.configure({ types: ["heading", "paragraph"] }),
    ],
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      form.setValue("reason", html);
    },
    content: reason,
  });

  return (
    <div>
      <M.Input.Wrapper
        label="Content"
        required
        description="Enter content"
        {...form.register("reason")}
      >
        <RichTextEditor editor={editor}>
          <RichTextEditor.Toolbar sticky stickyOffset={60}>
            <RichTextEditor.ControlsGroup>
              <RichTextEditor.Bold />
              <RichTextEditor.Italic />
              <RichTextEditor.Underline />
              <RichTextEditor.Strikethrough />
              <RichTextEditor.ClearFormatting />
              <RichTextEditor.Code />
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
              <RichTextEditor.H1 />
              <RichTextEditor.H2 />
              <RichTextEditor.H3 />
              <RichTextEditor.H4 />
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
              <RichTextEditor.Blockquote />
              <RichTextEditor.Hr />
              <RichTextEditor.BulletList />
              <RichTextEditor.OrderedList />
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
              <RichTextEditor.Link />
              <RichTextEditor.Unlink />
            </RichTextEditor.ControlsGroup>
          </RichTextEditor.Toolbar>

          <RichTextEditor.Content />
        </RichTextEditor>
      </M.Input.Wrapper>
    </div>
  );
};
