import placeholderImage from "#/images/placeholder.png";
import { cn } from "#/lib/utils";
import { RouterOutputs } from "#/trpc";
import { uniq } from "lodash";
import { useMemo, useState } from "react";

type Product = NonNullable<RouterOutputs["itemSku"]["getById"]>;

interface GalleryProps {
  product?: Product | null;
}
export function Gallery(props: GalleryProps) {
  const [selectedImage, setSelectedImage] = useState(0);

  const handleImageError = (event: any) => {
    event.target.src = placeholderImage;
  };

  const images = useMemo(() => {
    return uniq(
      props.product?.defaultImage && props.product?.defaultImage !== ""
        ? [props.product.defaultImage, ...props.product.images]
        : props.product?.images?.length
        ? props.product?.images
        : [placeholderImage]
    );
  }, [props.product?.defaultImage, props.product?.images]);

  return (
    <div className="flex flex-col gap-y-2 lg:items-center lg:justify-center">
      <img
        src={images[selectedImage]}
        alt=""
        className="aspect-square h-[350px] w-[458px] rounded-lg object-cover"
        onError={handleImageError}
        defaultValue={placeholderImage}
      />
      <div className="grid max-w-[458px] grid-cols-4  gap-4">
        {images.map((image, i) => {
          const defaultImage =
            !image || image === "" ? placeholderImage : image;

          return (
            <div
              key={i}
              className={cn("aspect-square rounded-lg object-cover")}
              onClick={() => setSelectedImage(i)}
            >
              <img
                src={defaultImage}
                alt=""
                className="aspect-square w-full rounded-lg object-cover"
                onError={handleImageError}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}
