import QuizQuestionsSection from "./quiz-questions-section";
import QuizValuesSection from "./quiz-values-section";
import { QuizFormProps, QuizInfo } from "./quiz-info";

export const QuizForm = (props: QuizFormProps) => {
  return (
    <>
      <QuizInfo {...props} />
      {props.quizId && (
        <>
          <QuizValuesSection quizId={props.quizId} />
          <QuizQuestionsSection quizId={props.quizId} />
        </>
      )}
    </>
  );
};
