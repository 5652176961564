import { PersonOutlineIcon } from "#/components-ng/index.js";
import GTLoader from "#/components-ng/loader.js";
import CreateUserForm from "#/components/Form/complex/User/index.js";
import SceneLayout from "#/components/SceneLayout/index.jsx";
import { Form, RouterPrompt } from "#/components/index.js";
import { trpc } from "#/trpc.js";
import { reportUserError, reportUserSuccess } from "#/util/index.js";
import * as C from "@chakra-ui/react";
import * as M from "@mantine/core";
import dayjs from "dayjs";
import React from "react";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";

export default function EditCustomer() {
  const [isAddressRequired, setIsAddressRequired] = React.useState(false);
  const params = useParams() as any;
  const id = Number.parseInt(params.id);
  const navigate = useNavigate();
  const ctx = trpc.useContext();
  const { data, isLoading, isFetchedAfterMount } =
    trpc.user.getOneById.useQuery(
      { id },
      {
        onError(error) {
          reportUserError({
            title: "Failed to get customer",
            message: error.message,
          });
        },
      }
    );
  const { mutate, isLoading: isLoadingUpdate } = trpc.user.update.useMutation({
    onSuccess(data) {
      navigate("/customers/list");
      ctx.user.invalidate();
      reportUserSuccess({
        title: "Customer updated successfully",
        message: (
          <>
            Customer{" "}
            <span className="font-medium">
              {data.firstName} {data.lastName}
            </span>{" "}
            updated successfully
          </>
        ),
      });
    },
    onError(error) {
      reportUserError({
        title: "Failed to get customer",
        message: error.message,
      });
    },
  });

  const defaultUser = React.useMemo(
    () =>
      data
        ? {
            ...data,
            address: data.country
              ? {
                  address: data.street,
                  city: data.city,
                  state: data.state,
                  country: data.country,
                  zip: data.zip,
                  poBox: data.poBox,
                  apt: data.apt,
                }
              : undefined,
            birthDate:
              data?.birthDate && dayjs(data.birthDate).isValid()
                ? dayjs(data.birthDate).toISOString().slice(0, 10)
                : undefined,
          }
        : {},
    [data]
  );

  const [submitted, setSubmitted] = React.useState(false);
  const handleSubmit = React.useCallback(
    (f) => {
      setSubmitted(true);
      const newUser = f as any;
      mutate({
        id,
        input: {
          role: {
            connect: {
              id: data!.roleId,
            },
          },
          firstName: newUser.firstName,
          lastName: newUser.lastName,
          email: newUser.email,
          phoneNumber: newUser.phoneNumber,
          areaCode: newUser.areaCode,
          countryCode: newUser.countryCode,
          birthDate:
            newUser.birthDate && dayjs(newUser.birthDate).isValid()
              ? dayjs(newUser.birthDate).toDate()
              : undefined,
          street: isAddressRequired ? newUser.address?.address : undefined,
          city: isAddressRequired ? newUser.address?.city : undefined,
          state: isAddressRequired ? newUser.address?.state : undefined,
          zip: isAddressRequired ? newUser.address?.zip : undefined,
          country: isAddressRequired ? newUser.address?.country : undefined,
          apt: isAddressRequired ? newUser.address?.apt : undefined,
          poBox: isAddressRequired ? newUser.address?.poBox : undefined,
          verified: newUser.verified,
          taxable: newUser.taxable,
          note: newUser.note,
          company: newUser.company ?? null,
          nickName: newUser.nickName ?? null,
          avatar: newUser.avatar ?? null,
          taxDocument: newUser.taxDocument ?? null,
          ein: newUser.ein ?? null,
          bio: f.bio ?? null,
          preferredSalesAssociated: newUser.preferredSalesAssociatedId
            ? {
                connect: {
                  id: newUser.preferredSalesAssociatedId,
                },
              }
            : undefined,
        },
      });
    },
    [mutate, id, data, isAddressRequired]
  );

  return (
    <SceneLayout icon={<PersonOutlineIcon />}>
      <RouterPrompt when={!submitted} />
      <C.Container maxW="container.xl">
        <C.Box bg="white" borderRadius="md" p={5}>
          {data && isFetchedAfterMount ? (
            <Form onSubmit={handleSubmit} defaultValues={defaultUser} w="100%">
              <CreateUserForm
                onAddressRequiredChange={setIsAddressRequired}
                isDiscountsTabDisabled={false}
                customerButtons={
                  <>
                    <M.Button
                      component={Link}
                      to="/customers/list"
                      color="darkGray.0"
                      sx={(t) => ({ color: t.colors.gray[8] })}
                    >
                      Cancel
                    </M.Button>
                    <M.Button
                      type="submit"
                      sx={{ width: "min(15ch, 70%)" }}
                      loading={isLoadingUpdate}
                    >
                      Update
                    </M.Button>
                  </>
                }
                discountsButtons={<></>}
                isCustomer={true}
              />
            </Form>
          ) : (
            <M.LoadingOverlay
              visible={isLoading}
              loader={<GTLoader width={100} height={100} />}
            />
          )}
        </C.Box>
      </C.Container>
    </SceneLayout>
  );
}
