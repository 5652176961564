import Resizer from "react-image-file-resizer";

export const fetchImageAsBase64 = async (url) => {
  const urlSplit = url.split(".");
  const ext = urlSplit[urlSplit.length - 1];
  const imgData = await fetch(url + "?refetch");

  const contentType = imgData.headers["Content-Type"];
  const imgArray = await imgData.arrayBuffer();
  const b64 = `data:${contentType};base64,${window.Buffer.from(
    imgArray
  ).toString("base64")}`;

  return {
    data: b64,
    ext,
  };
};

export const resizeFile = (
  file: File,
  width: number,
  height: number
): Promise<File> =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      width,
      height,
      "jpg",
      100,
      0,
      (uri) => {
        resolve(uri as File);
      },
      "file"
    );
  });
