import { SpinnerIcon } from "#/components-ng";
import { cn } from "#/lib/utils";
import {
  Tooltip,
  ActionIcon,
  Group,
  Menu,
  Image as ImageMantine,
} from "@mantine/core";
import { MdMoreVert, MdOutlineCheckCircle } from "react-icons/md";
import TrashIcon from "~icons/ion/trash-outline";

type ImageProps = {
  src: string;
  isLoading: boolean;
  alt?: string;
  deleteImage: () => void;
  setDefaultImage: () => void;
  isDefault: boolean;
};

export function ImageCard(props: ImageProps) {
  return (
    <div className="relative shrink-0">
      <ImageMantine
        src={props.src}
        className="aspect-[5/3] w-full object-cover"
        alt=""
        height="173px"
        caption={
          <ImageActions
            isDefault={props.isDefault}
            onSetDefault={props.setDefaultImage}
            isLoading={props.isLoading}
          />
        }
      />
      <div
        className={cn(
          "absolute inset-0 hidden h-full w-full place-items-center bg-black opacity-40",
          props.isLoading && "grid"
        )}
      >
        <SpinnerIcon className="animate-spin text-white" />
      </div>
      <Tooltip label="Delete">
        <ActionIcon
          className="absolute right-0 top-0 mr-2 mt-2 bg-transparent p-1 hover:bg-red-200"
          onClick={() => props.deleteImage()}
          size={"lg"}
        >
          <TrashIcon className="text-red-500" />
        </ActionIcon>
      </Tooltip>
    </div>
  );
}

type ImageActionsProps = {
  isDefault: boolean;
  isLoading: boolean;
  onSetDefault: () => void;
};
const ImageActions = ({
  isLoading,
  isDefault,
  onSetDefault,
}: ImageActionsProps) => {
  return (
    <Group
      spacing="xs"
      position="right"
      noWrap
      onClick={(e) => e.stopPropagation()}
    >
      {isDefault && <MdOutlineCheckCircle />}
      <Menu withinPortal zIndex={9999}>
        <Menu.Target>
          <ActionIcon>
            <MdMoreVert size={18} />
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item disabled={isLoading} onClick={onSetDefault}>
            Set as default
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </Group>
  );
};
