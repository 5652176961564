import { css } from "#/css/css";
import { modals } from "@mantine/modals";
import { Flex } from "@radix-ui/themes";
import * as M from "@mantine/core";
import CheckIcon from "~icons/ic/baseline-check";
import ClipboardIcon from "~icons/ion/clipboard-outline";

interface GenerateOTPModalProps {
  token?: string | null;
  otp?: string | null;
}

export const generateOTPModal = (props: GenerateOTPModalProps) => {
  modals.open({
    modalId: "INACTIVATE_USER_MODAL",
    children: <GenerateOTPModal {...props} />,
  });
};

const GenerateOTPModal = (props: GenerateOTPModalProps) => {
  // @ts-ignore
  const SHOP_BASE_URL = import.meta.env.SECRET_SHOP_BASE_URL;
  const url = `${SHOP_BASE_URL}/auth/verify?token=${props?.token ?? ""}`;

  return (
    <div
      className={css({
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 30,
      })}
    >
      <h4 className={css({ textAlign: "center" })}>
        Your OTP is {props?.otp ?? ""}
      </h4>
      <p className={css({ textAlign: "center" })}>
        Remember that code expire in 10 minutes
      </p>
      <p className={css({ textAlign: "center" })}>
        You can click to copy the link below or click on the button to go to the
        website to enter the OTP
      </p>
      <Flex align="center" width="100%">
        <M.CopyButton value={url} timeout={2000}>
          {({ copied, copy }) => (
            <M.Tooltip
              label={copied ? "Copied" : "Copy"}
              withArrow
              position="right"
              withinPortal
            >
              <M.Button
                color={copied ? "teal" : "gray"}
                onClick={copy}
                variant="outline"
                className="w-full"
                rightIcon={copied ? <CheckIcon /> : <ClipboardIcon />}
              >
                {url}
              </M.Button>
            </M.Tooltip>
          )}
        </M.CopyButton>
      </Flex>
      <M.Button
        component="a"
        href={url}
        target="_blank"
        className=" text-center"
      >
        Go to website
      </M.Button>
    </div>
  );
};
