import { trpc } from "#/trpc.js";
import { reportUserError, reportUserSuccess } from "#/util/ux/index.js";
import DepartmentForm from "./Form.js";
import React from "react";
import { useNavigate } from "react-router";

export default function CreateDepartment() {
  const ctx = trpc.useContext();
  const navigate = useNavigate();
  const { mutate, isLoading } = trpc.department.create.useMutation({
    onSuccess() {
      ctx.department.invalidate();
      ctx.v2_5.departments.invalidate();
      reportUserSuccess({ title: "Department created successfully" });
      navigate("/inventory/departments");
    },
    onError(error) {
      reportUserError({
        title: "Failed to create department",
        message: error.message,
      });
    },
  });

  const handleSubmit = React.useCallback(
    (department) => {
      mutate({
        name: department.name,
        description: department.description,
        defaultImage: department.defaultImage ?? null,
        images: [],
        active: department.active,
        top: department.top,
        metaDescription: department?.metaDescription ?? null,
      });
    },
    [mutate]
  );
  return <DepartmentForm onSubmit={handleSubmit} isLoading={isLoading} />;
}
