import { PersonOutlineIcon } from "#/components-ng"
import SceneLayout from "#/components/SceneLayout"
import { trpc } from "#/trpc"
import Preview from "./Preview"
import * as M from "@mantine/core"
import { useParams } from "react-router"
import MailIconOutline from "~icons/ion/ios-email-outline"
import PhoneIconOutline from "~icons/ion/ios-telephone-outline"
import {
	OrderHistorySection,
	ReturnHistorySection,
	CreditsHistorySection,
	DiscountsSection,
} from "./sections"

export default function PreviewCustomer() {
	const { id } = useParams()
	const userId = id ? Number.parseInt(id) : 0

	const { data, isLoading } = trpc.user.getOneById.useQuery({
		id: userId,
	})

	return (
		<SceneLayout icon={<PersonOutlineIcon />}>
			<M.Stack spacing="xs" className="font-sans">
				<div className="grid h-24 w-full grid-cols-11 grid-rows-5 bg-white py-2 ">
					<div className="col-span-1 row-span-5 grid  place-content-center">
						<M.Avatar src={data?.avatar ?? undefined} size={"lg"} radius="xl" />
					</div>
					<M.Text className="col-span-9 col-start-2 row-start-2 ml-3 flex items-stretch text-xl font-semibold">
						{data?.firstName} {data?.lastName}
					</M.Text>
					<div className="col-span-9 col-start-2 row-span-4 row-start-4 ml-3 flex w-full items-stretch gap-7 text-gray-500">
						<M.Text className="flex items-stretch gap-1">
							<span className="py-[3px]">
								<MailIconOutline />
							</span>
							{data?.email}
						</M.Text>
						<M.Text className="flex items-stretch gap-1">
							<span className="py-[3px]">
								<PhoneIconOutline />
							</span>
							{data?.areaCode} {data?.phoneNumber}
						</M.Text>
					</div>
				</div>

				<M.Flex w={"100%"} justify={"space-between"} bg={"white"}>
					<M.Tabs defaultValue="Profile" w={"100%"}>
						<M.Tabs.List pt={12} grow={true} mb="50px">
							<M.Tabs.Tab value="Profile">Profile</M.Tabs.Tab>
							<M.Tabs.Tab value="Order history">Order history</M.Tabs.Tab>
							<M.Tabs.Tab value="Return history">Return history</M.Tabs.Tab>
							<M.Tabs.Tab value="User credits history">
								User credits history
							</M.Tabs.Tab>
							<M.Tabs.Tab value="Discounts">Discounts</M.Tabs.Tab>
						</M.Tabs.List>

						<M.Tabs.Panel value="Profile">
							<Preview customer={data} isLoading={isLoading} />
						</M.Tabs.Panel>
						<M.Tabs.Panel value="Order history">
							<OrderHistorySection customerId={userId} />
						</M.Tabs.Panel>
						<M.Tabs.Panel value="Return history">
							<ReturnHistorySection customerId={userId} />
						</M.Tabs.Panel>
						<M.Tabs.Panel value="User credits history">
							<CreditsHistorySection customerId={userId} />
						</M.Tabs.Panel>
						<M.Tabs.Panel value="Discounts">
							<DiscountsSection customerId={userId} />
						</M.Tabs.Panel>
					</M.Tabs>
				</M.Flex>
			</M.Stack>
		</SceneLayout>
	)
}
