import { ChevronDownOutline } from "#/components-ng";
import { trpc } from "#/trpc";
import { reportUserError, reportUserSuccess } from "#/util";
import { generateCsv } from "#/util/csv";
import * as M from "@mantine/core";
import dayjs from "dayjs";
import FileSaver from "file-saver";

interface ExportEcommerceSalesButtonProps {
  startDate?: Date | null;
  endDate?: Date | null;
}

enum ExportType {
  ALL = "ALL",
  WITH_LAST_ASSOCIATED = "WITH_LAST_ASSOCIATED",
  WITHOUT_LAST_ASSOCIATED = "WITHOUT_LAST_ASSOCIATED",
}

export const ExportEcommerceSalesButton = (
  props: ExportEcommerceSalesButtonProps
) => {
  const [exportType, setExportType] = useState<ExportType>(ExportType.ALL);

  const { mutate: exportCsv, isLoading } =
    trpc.v2_5.report.exportOrdersEcom.useMutation({
      onError(err) {
        reportUserError({
          message: err.message,
        });
      },
      async onSuccess(data) {
        reportUserSuccess({
          title: "Csv exported successfully",
          message: "Your file will be downloaded a few minutes",
        });

        const headers = [
          { label: "orderId", value: "orderId" },
          { label: "customer", value: "customer" },
          { label: "totalOrder", value: "totalOrder" },
          { label: "lastAssociated", value: "lastAssociated" },
          { label: "orderCreatedAt", value: "orderCreatedAt" },
          { label: "registerFrom", value: "registerFrom" },
        ];

        const csv = await generateCsv({
          data: data,
          headers: headers,
        });

        const fileName = `${exportType?.toLowerCase()}_orders_ecom_${dayjs(
          props?.startDate
        ).format("MM-DD-YYYY")}_${dayjs(props?.endDate).format("MM-DD-YYYY")}`;

        FileSaver.saveAs(
          new Blob([csv], { type: "text/csv;charset=utf-8" }),
          `${fileName}.csv`
        );
      },
    });

  const handleExport = (type: ExportType) => {
    if (!props.startDate || !props.endDate) {
      reportUserError({
        message: "Please select a date range",
      });
      return;
    }

    setExportType(type);
    exportCsv({
      startDate: props.startDate,
      endDate: props.endDate,
      type: type,
    });
  };

  return (
    <M.Menu disabled={isLoading}>
      <M.Menu.Target>
        <M.Button
          rightIcon={<ChevronDownOutline />}
          loading={isLoading}
          loaderPosition="right"
        >
          Export CSV
        </M.Button>
      </M.Menu.Target>
      <M.Menu.Dropdown>
        <M.Menu.Item
          disabled={isLoading}
          onClick={() => handleExport(ExportType.ALL)}
        >
          Export all orders
        </M.Menu.Item>
        <M.Menu.Item
          disabled={isLoading}
          onClick={() => handleExport(ExportType.WITH_LAST_ASSOCIATED)}
        >
          Export orders with last associated
        </M.Menu.Item>
        <M.Menu.Item
          disabled={isLoading}
          onClick={() => handleExport(ExportType.WITHOUT_LAST_ASSOCIATED)}
        >
          Export orders without last associated
        </M.Menu.Item>
      </M.Menu.Dropdown>
    </M.Menu>
  );
};
