import { CloseOutline, makeController } from "#/components-ng/index.js";
import { DisplayTable, RouterPrompt } from "#/components/index.js";
import { useAuth } from "#/context/AuthContext.js";
import { printTicketFromRefundV2 } from "#/modules/ticket/print.js";
import { RouterOutputs, trpc } from "#/trpc.js";
import { reportUserError, reportUserSuccess } from "#/util/index.js";
import { CustomerPanel } from "../components/customer-panel.js";
import { applyDiscountV2 } from "../util/index.js";
import { OrdersModal, ReturnedOrderItemSku } from "./orders-modal.js";
import { refundMethodsMap } from "./refundPayments.js";
import { zodResolver } from "@hookform/resolvers/zod";
import * as M from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import Decimal from "decimal.js";
import { Fragment, forwardRef, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { PaymentType } from "server";
import { z } from "zod";

type Customer = NonNullable<RouterOutputs["user"]["getOneById"]>;

type ReturnFormValues = {
  customer: Customer | null;
  returnedOrderItemSkus: ReturnedOrderItemSku[];
  refundMethod: PaymentType | null;
};

const C = makeController<ReturnFormValues>();

const schema = z.object({
  customer: z
    .object(
      {},
      {
        errorMap: () => ({ message: "Customer is required" }),
      }
    )
    .passthrough(),
  returnedOrderItemSkus: z.array(z.any()),
  refundMethod: z.unknown({
    errorMap: () => ({ message: "Refund method is required" }),
  }),
});

export function ReturnForm() {
  const [submitted, setSubmitted] = useState(false);
  const form = C.useForm({
    defaultValues: {
      customer: null,
      returnedOrderItemSkus: [],
    },
    resolver: zodResolver(schema) as any,
  });
  const customer = C.useWatch({
    name: "customer",
    control: form.control,
  });
  const returnedOrderItemSkus = C.useWatch({
    name: "returnedOrderItemSkus",
    control: form.control,
  });

  const tax = useMemo(
    () => (customer?.taxable ? new Decimal("0.07") : new Decimal(0)),
    [customer?.taxable]
  );

  const tableData = useMemo(
    () =>
      returnedOrderItemSkus.map((o) => {
        const total = applyDiscountV2(
          o.orderItemSku.price.mul(o.quantity),
          o.orderItemSku.discountAmount
            ? {
                amount: o.orderItemSku.discountAmount!,
                type: o.orderItemSku.discountType!,
              }
            : null
        );

        return {
          ...o,
          $$subtotal: o.orderItemSku.price.mul(o.quantity),
          $$total: total.plus(total.times(tax)),
        };
      }) ?? [],
    [returnedOrderItemSkus, tax]
  );

  const [{ auth }] = useAuth();
  const navigate = useNavigate();

  const { data: nextReference, isLoading } =
    trpc.refundOrderItemSku.getNextReference.useQuery(undefined, {
      onError(error) {
        reportUserError({
          title: "Failed to get next reference",
          message: error.message,
        });
      },
      cacheTime: 0,
    });

  const returnMutation = trpc.orderItemSku.returnMany.useMutation({
    onSuccess(data) {
      reportUserSuccess({
        title: "Returned items successfully",
      });

      printTicketFromRefundV2({
        refunds: auth?.user?.filialId
          ? data.filter((r) => r.filialId === auth?.user?.filialId)
          : data,
        filialId: auth?.user?.filialId ?? 0,
        withLocation: false,
      });
      navigate("/sales");
    },
    onError() {
      reportUserError({
        title: "Failed to return items",
      });
    },
  });
  function handleSubmit(values: ReturnFormValues) {
    if (values.returnedOrderItemSkus.length === 0) {
      reportUserError({
        title: "Failed to return items",
        message: "You must add at least a item to refund",
      });
      return;
    }

    if (!values.refundMethod) {
      reportUserError({
        title: "Failed to return items",
        message: "You must add at refund method",
      });
      return;
    }

    if (!auth.user.filialId) {
      reportUserError({
        title: "Failed to return items",
        message: "you need to have a filial assigned to refund items",
      });
      return;
    }

    setSubmitted(true);
    returnMutation.mutate({
      returns: values.returnedOrderItemSkus.map((o) => {
        const total = applyDiscountV2(
          o.orderItemSku.price.mul(o.quantity),
          o.orderItemSku.discountAmount
            ? {
                amount: o.orderItemSku.discountAmount!,
                type: o.orderItemSku.discountType!,
              }
            : null
        );

        return {
          orderItemSkuId: o.orderItemSku.id,
          filialId: auth!.user.filialId!,
          customerId: values.customer!.id,
          quantity: o.quantity,
          reason: o.reason,
          total: total.plus(total.times(tax)),
          reference: nextReference!,
          refundMethod: values.refundMethod!,
          associatedId: auth?.user?.id ?? null,
        };
      }),
    });
  }

  const subTotal = useMemo(
    () =>
      returnedOrderItemSkus.reduce((acc, curr) => {
        return acc.plus(
          applyDiscountV2(
            curr.orderItemSku.price.mul(curr.quantity),
            curr.orderItemSku.discountAmount
              ? {
                  amount: curr.orderItemSku.discountAmount!,
                  type: curr.orderItemSku.discountType!,
                }
              : null
          )
        );
      }, new Decimal(0)),
    [returnedOrderItemSkus]
  );

  const taxTotal = useMemo(
    () =>
      returnedOrderItemSkus.reduce((acc, curr) => {
        const total = applyDiscountV2(
          curr.orderItemSku.price.mul(curr.quantity),
          curr.orderItemSku.discountAmount
            ? {
                amount: curr.orderItemSku.discountAmount!,
                type: curr.orderItemSku.discountType!,
              }
            : null
        );

        return acc.plus(total.times(tax));
      }, new Decimal(0)),
    [returnedOrderItemSkus, tax]
  );

  const total = useMemo(() => subTotal.plus(taxTotal), [subTotal, taxTotal]);

  return (
    <Fragment>
      <RouterPrompt when={!submitted} />
      <div>
        <C.Form form={form} onSubmit={handleSubmit} className="grid gap-y-2">
          <CustomerPanel customerId={customer?.id} />
          <div className="grid gap-y-4 rounded bg-white p-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-x-5 text-base">
                <CustomerField />
                <M.Select
                  data={refundMethodsMap}
                  placeholder={"Refund Method"}
                  itemComponent={SelectItem}
                  classNames={{
                    root: "max-w-[26ch]",
                  }}
                  clearable
                  searchable
                  onChange={(v) => {
                    form.setValue("refundMethod", (v as any) ?? null);
                  }}
                  disabled={!customer ? true : false}
                />
              </div>
              <div className="flex items-center gap-x-5 text-base">
                <M.Select
                  data={[
                    {
                      value: "DAMAGED",
                      label: "Damaged",
                    },
                    {
                      value: "RETURN",
                      label: "Return",
                    },
                    {
                      value: "EXCHANGE",
                      label: "Exchange",
                    },
                  ]}
                  placeholder={"Reason"}
                  onChange={(v) => {
                    if (v != null) {
                      form.setValue(
                        "returnedOrderItemSkus",
                        form.getValues("returnedOrderItemSkus").map((prev) => {
                          return {
                            ...prev,
                            reason: v as any,
                          };
                        })
                      );
                    }
                  }}
                  w={150}
                />
                <p className="text-sm font-medium">
                  Ref: {isLoading ? "" : `R${nextReference}`}
                </p>
              </div>
            </div>
            <DisplayTable columns={columns} data={tableData} />
            <div className="flex flex-wrap justify-between">
              <div className="flex gap-x-4">
                <M.Button
                  color="darkGray.7"
                  variant="light"
                  component={Link}
                  to="/sales"
                  className="px-6"
                >
                  Cancel
                </M.Button>
                <OrdersModal
                  onSubmit={(v) => {
                    form.setValue(
                      "returnedOrderItemSkus",
                      form.getValues("returnedOrderItemSkus").concat(v)
                    );
                  }}
                  disabled={!customer ? true : false}
                  customerId={customer?.id ?? null}
                  returnedOrderItemSkus={returnedOrderItemSkus}
                />
                <M.Button
                  className="px-12"
                  type="submit"
                  loading={returnMutation.isLoading}
                  disabled={!auth.user.filialId ? true : false}
                >
                  Return and print
                </M.Button>
              </div>
              <M.Accordion
                w={250}
                classNames={{
                  control:
                    "h-[36px] rounded-md bg-green-500 text-white hover:bg-green-500",
                }}
              >
                <M.Accordion.Item value="costBreakdown">
                  <M.Accordion.Control>
                    <M.Group
                      position="apart"
                      align="center"
                      sx={{ width: "100%" }}
                    >
                      <M.Text weight="600">Total</M.Text>
                      <M.Text weight="600">
                        ${total.toNumber().toFixed(2)}
                      </M.Text>
                    </M.Group>
                  </M.Accordion.Control>
                  <M.Accordion.Panel>
                    <M.SimpleGrid
                      cols={2}
                      verticalSpacing={2}
                      w={250}
                      px={3}
                      py={1}
                      className="text-gray-500"
                    >
                      <M.Text>Subtotal</M.Text>
                      <M.Text weight="bold">
                        ${subTotal.toNumber().toFixed(2)}
                      </M.Text>
                      <M.Text>Tax</M.Text>
                      <M.Text weight="bold">
                        {customer && customer.taxable
                          ? `$${taxTotal.toNumber().toFixed(2)}`
                          : `$0.00`}
                      </M.Text>
                    </M.SimpleGrid>
                  </M.Accordion.Panel>
                </M.Accordion.Item>
              </M.Accordion>
            </div>
          </div>
        </C.Form>
      </div>
    </Fragment>
  );
}

function CustomerField() {
  const form = C.useFormContext();
  const ctrl = C.useController({
    name: "customer",
    control: form.control,
  });
  const customer = C.useWatch({
    name: "customer",
    control: form.control,
  });
  const [query, setQuery] = useState("");
  const userSearchQuery = trpc.user.search.useQuery({
    query,
  });

  const selectData = useMemo(() => {
    const data =
      userSearchQuery.data?.map((user) => ({
        label: `${user.firstName} ${user.lastName ?? ""} ${user.email}`,
        value: user.id.toString(),
        user,
      })) ?? [];

    if (
      customer &&
      !data.find(({ value }) => value === customer?.id.toString())
    ) {
      data.push({
        label: `${customer?.firstName} ${customer?.lastName ?? ""} ${
          customer?.email
        }`,
        value: customer.id.toString(),
        user: customer,
      });
    }

    return data;
  }, [customer, userSearchQuery.data]);

  const openConfirmCloseModal = (user: any) =>
    openConfirmModal({
      title: "Are you sure you want to change customer?",
      labels: { confirm: "Confirm", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onConfirm: () => {
        ctrl.field.onChange(user);
        form.setValue("returnedOrderItemSkus", []);
      },
    });

  return (
    <M.Select
      name={ctrl.field.name}
      placeholder="Search customer..."
      data={selectData}
      onSearchChange={setQuery}
      searchable
      value={ctrl.field.value?.id.toString() ?? null}
      error={ctrl.fieldState.error?.message}
      onChange={(userValue) => {
        const user = selectData.find(({ value }) => value === userValue);
        if (customer && Number(userValue) !== customer.id) {
          openConfirmCloseModal(user?.user);
          return;
        }
        ctrl.field.onChange(user?.user ?? null);
      }}
      classNames={{
        root: "max-w-[26ch]",
      }}
      clearable
    />
  );
}

const columns = [
  {
    Header: "Image",
    accessor: "orderItemSku.itemSku.defaultImage",
    type: "image",
  },
  {
    Header: "Title",
    accessor: "orderItemSku.itemSku.title",
    Cell: ({ value }) => <p className="text-red-500">{value}</p>,
  },
  {
    Header: "SKU",
    accessor: "orderItemSku.itemSku.sku",
    isNumeric: true,
    Cell: ({ value }) => <p className="text-red-500">{value}</p>,
  },
  {
    Header: "PKG",
    accessor: "orderItemSku.itemSku.presentationValue",
    Cell: ({ value, row: { original } }) => (
      <p className="text-red-500">
        {value} {original.orderItemSku.itemSku.presentationType}
      </p>
    ),
  },
  {
    Header: "Returned qty",
    accessor: "quantity",
    isNumeric: true,
    Cell: ({ value }) => {
      return <p className="text-red-500">{value}</p>;
    },
  },
  {
    Header: "Reason",
    accessor: "reason",
    Cell: ({ value, row: { index, original } }) => {
      const form = C.useFormContext();
      return (
        <M.Select
          data={[
            {
              value: "DAMAGED",
              label: "Damaged",
            },
            {
              value: "RETURN",
              label: "Return",
            },
            {
              value: "EXCHANGE",
              label: "Exchange",
            },
          ]}
          value={value}
          disabled={!original.isReturned}
          onChange={(v) => {
            if (v != null) {
              form.setValue(
                "returnedOrderItemSkus",
                form.getValues("returnedOrderItemSkus").map((prev, i) => {
                  if (i != index) return prev;
                  return {
                    ...prev,
                    reason: v as any,
                  };
                })
              );
            }
          }}
          styles={(theme) => ({
            label: {
              color: theme.colors.red[9],
            },
            item: {
              "&[data-selected]": {
                "&, &:hover": {
                  backgroundColor: theme.colors.red[1],
                  color: "#EF4444",
                },
              },
            },
            input: {
              color: "#EF4444",
              borderColor: "#EF4444",
            },
          })}
        />
      );
    },
  },
  {
    Header: "Discount",
    accessor: "orderItemSku.discountReference",
    Cell: ({ value }) => {
      return <p className="text-red-500">{value}</p>;
    },
  },
  {
    Header: "Subtotal",
    accessor: "$$subtotal",
    styles: {
      width: "4ch",
    },
    Cell: ({ value }) => {
      return <p className="text-red-500">${value.toFixed(2)}</p>;
    },
    PrintCell: "money",
  },
  {
    Header: "Total",
    accessor: "$$total",
    styles: {
      width: "4ch",
    },
    Cell: ({ value }) => <p className="text-red-500">${value.toFixed(2)}</p>,
    PrintCell: "money",
  },
  {
    Header: "",
    id: "delete",
    skipPrint: true,
    Cell: ({ row: { index } }) => {
      const form = C.useFormContext();

      function handleClick() {
        form.setValue(
          "returnedOrderItemSkus",
          form.getValues("returnedOrderItemSkus").filter((_, i) => i !== index)
        );
      }

      return (
        <M.ActionIcon onClick={handleClick}>
          <CloseOutline />
        </M.ActionIcon>
      );
    },
  },
];

interface SelectCustomProps extends React.ComponentPropsWithoutRef<"div"> {
  icon: React.ReactNode;
  label: string;
}

// eslint-disable-next-line react/display-name
const SelectItem = forwardRef<HTMLDivElement, SelectCustomProps>(
  ({ icon, label, ...others }: SelectCustomProps, ref) => (
    <div ref={ref} {...others}>
      <M.Group noWrap>
        <M.ActionIcon>{icon}</M.ActionIcon>
        <div>
          <M.Text size="sm">{label}</M.Text>
        </div>
      </M.Group>
    </div>
  )
);
