import { RouterOutputs, trpc } from "#/trpc";
import { Button, Dropdown } from "@gt/ui";
import { FilterKey } from "./$common";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_SortingState,
  useMantineReactTable,
} from "mantine-react-table";
import placeholderImage from "#/placeholder-image.jpg";
import MenuIcon from "~icons/ion/ellipsis-horizontal-outline";
import { css } from "#/css/css";
import dayjs from "dayjs";
import { Badge, Flex, IconButton, Tooltip } from "@radix-ui/themes";
import { match } from "ts-pattern";
import { Modal } from "@mantine/core";
import {
  FolderOpenOutlineIcon,
  StarFilledIcon,
  TrashFilledIcon,
} from "#/components-ng";
import { reportUserSuccess } from "#/util";
import { MdOutlineDownload } from "react-icons/md";

export interface SuggestedItemsTableProps {
  filter: Array<FilterKey>;
  search?: string | null;
}

export const SuggestedItemsTable = (props: SuggestedItemsTableProps) => {
  // Pagination
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 25,
  });
  // Sorting
  const [sorting, setSorting] = useState<MRT_SortingState>([]);

  const querySorting = useMemo(() => {
    if (sorting[0]) {
      return {
        desc: sorting[0].desc,
        key: sorting[0].id as any,
      };
    }
    return null;
  }, [sorting]);

  const { data, isLoading } = trpc.v2_5.suggestedItems.getByPage.useQuery(
    {
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize,
      sorting: querySorting,
      filters: {
        status: props.filter,
      },
      search: props.search,
    },
    {
      keepPreviousData: true,
    },
  );

  const { mutate: exportCsv, isLoading: isLoadingExport } =
    trpc.v2_5.suggestedItems.exportTableToCsv.useMutation({
      onSuccess: () => {
        reportUserSuccess({
          title: "The csv file has been sent to email",
        });
      },
    });

  const handleExportCsv = () => {
    exportCsv({
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize,
      sorting: querySorting,
      filters: {
        status: props.filter,
      },
      search: props.search,
    });
  };

  const table = useMantineReactTable({
    data: data?.entries ?? [],
    columns,
    manualPagination: true,
    enableTopToolbar: false,
    rowCount: data?.totalEntries ?? 0,
    enableFilters: false,
    state: {
      pagination,
      sorting,
      isLoading,
    },
    enableStickyHeader: true,
    manualSorting: true,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    renderBottomToolbarCustomActions: () => (
      <div
        className={css({
          display: "flex",
          alignItems: "center",
          gap: 2,
        })}
      >
        <Tooltip content="Export to CSV">
          <IconButton
            color="gray"
            variant="soft"
            loading={isLoadingExport}
            onClick={handleExportCsv}
          >
            <MdOutlineDownload />
          </IconButton>
        </Tooltip>
      </div>
    ),
  });

  return <MantineReactTable table={table} />;
};

type SuggestedItem =
  RouterOutputs["v2_5"]["suggestedItems"]["getByPage"]["entries"][number];

const columns: MRT_ColumnDef<SuggestedItem>[] = [
  {
    id: "actions",
    header: "Actions",
    enableSorting: false,
    size: 100,
    Cell: (table) => {
      const ctx = trpc.useContext();
      const id = table.row.original.id;
      const status = table.row.original.status;

      const { mutate: updateStatus } =
        trpc.v2_5.suggestedItems.updateStatus.useMutation({
          onSuccess() {
            ctx.v2_5.suggestedItems.invalidate();
          },
        });

      const handleUpdateStatus = (value: SuggestedItem["status"]) => {
        updateStatus({ id, status: value });
      };

      return (
        <>
          <Dropdown.Root>
            <Dropdown.Trigger>
              <Button variant="ghost" size="icon">
                <MenuIcon />
              </Button>
            </Dropdown.Trigger>
            <Dropdown.Content>
              <Dropdown.Item
                asChild
                disabled={status === "FAVORITE" ? true : false}
                onClick={() => handleUpdateStatus("FAVORITE")}
              >
                <Flex gap="5">
                  <StarFilledIcon
                    className={css({
                      marginRight: 2,
                      backgroundColor: "yellow.50",
                      color: "yellow.700",
                    })}
                  />
                  <p>Change status to Favorite</p>
                </Flex>
              </Dropdown.Item>
              <Dropdown.Item
                asChild
                disabled={status === "ARCHIVED" ? true : false}
                onClick={() => handleUpdateStatus("ARCHIVED")}
              >
                <Flex gap="5">
                  <FolderOpenOutlineIcon
                    className={css({
                      marginRight: 2,
                      backgroundColor: "cyan.50",
                      color: "cyan.700",
                    })}
                  />
                  <p>Change status to Archived</p>
                </Flex>
              </Dropdown.Item>
              <Dropdown.Item
                asChild
                disabled={status === "DELETED" ? true : false}
                onClick={() => handleUpdateStatus("DELETED")}
              >
                <Flex gap="5">
                  <TrashFilledIcon
                    className={css({
                      marginRight: 2,
                      backgroundColor: "red.50",
                      color: "red.700",
                    })}
                  />
                  <p>Change status to Deleted</p>
                </Flex>
              </Dropdown.Item>
            </Dropdown.Content>
          </Dropdown.Root>
        </>
      );
    },
  },
  {
    id: "image",
    header: "Image",
    accessorKey: "images",
    enableSorting: false,
    size: 100,
    Cell: (table) => {
      const [modalOpen, setModalOpen] = useState<boolean>(false);
      const original = table.row.original;

      return (
        <>
          <img
            src={original.images[0] ?? placeholderImage}
            className={css({
              objectFit: "cover",
              width: 20,
              height: 20,
              cursor: "pointer",
            })}
            onError={(e) => {
              (e.target as HTMLImageElement).src = placeholderImage;
            }}
            onClick={() => setModalOpen(true)}
          />
          {modalOpen && (
            <Modal
              opened={modalOpen}
              onClose={() => setModalOpen(false)}
              withCloseButton={false}
              classNames={{
                content: "bg-transparent shadow-none",
              }}
            >
              <img
                src={original.images[0] ?? placeholderImage}
                className={css({
                  objectFit: "contain",
                  width: "100%",
                  height: "100%",
                })}
                onError={(e) => {
                  (e.target as HTMLImageElement).src = placeholderImage;
                }}
              />
            </Modal>
          )}
        </>
      );
    },
  },
  {
    id: "userId",
    accessorKey: "user",
    header: "Suggested by",
    enableSorting: false,
    Cell: (table) => {
      const fullName = `${table.row.original?.user?.firstName ?? ""} ${
        table.row.original?.user?.lastName ?? ""
      }`;

      return fullName;
    },
  },
  {
    id: "phoneNumber",
    accessorKey: "user.phoneNumber",
    header: "Phone number",
    Cell: (table) => {
      const phoneNumber = `+${table.row.original?.user?.areaCode} ${table.row.original?.user?.phoneNumber}`;
      return phoneNumber;
    },
  },
  {
    id: "note",
    accessorKey: "note",
    header: "Note",
    enableSorting: false,
  },
  {
    id: "status",
    accessorKey: "status",
    header: "Status",
    enableSorting: false,
    Cell: (table) => {
      const status = table.row.original.status;
      const value = `${status[0]}${status.substring(1).toLowerCase()}`;

      return (
        <Badge
          size="3"
          radius="full"
          color={
            match(status)
              .with("NEW", () => "green")
              .with("FAVORITE", () => "yellow")
              .with("DELETED", () => "red")
              .with("ARCHIVED", () => "cyan")
              .otherwise(() => "gray") as any
          }
        >
          {value}
        </Badge>
      );
    },
  },
  {
    id: "createdAt",
    accessorKey: "createdAt",
    header: "Date",
    Cell: (table) => {
      const createdAt = table.row.original.createdAt;
      if (!createdAt) return "";
      return dayjs(createdAt).format("MM/DD/YYYY").toString();
    },
  },
];
