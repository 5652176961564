import { useAuth } from "#/context/AuthContext"
import { trpc } from "#/trpc"
import { reportUserError, reportUserSuccess } from "#/util"
import { useDisclosure } from "@mantine/hooks"
import * as M from "@mantine/core"
import { useParams } from "react-router"

export const UserCreditsEdit = () => {
	const { id } = useParams()
	const userId = id ? parseInt(id) : 0

	const pin = useRef("pin")
	const [{ auth }] = useAuth()

	const [opened, { open, close }] = useDisclosure(false)
	const ctx = trpc.useContext()

	const { mutate: adjustCredits } = trpc.v2_5.user.updateCredits.useMutation({
		onError: () => {
			reportUserError({
				title: "Failed to adjust credits",
			})
			close()
		},
		onSuccess: () => {
			reportUserSuccess({
				title: "Credits adjust successfully",
			})
			close()
			ctx.user.invalidate()
			ctx.creditTransaccion.invalidate()
		},
	})

	const credits = useRef(0)

	const { data } = trpc.user.getOneById.useQuery(
		{
			id: userId,
		},
		{
			onSuccess(data) {
				credits.current = data?.credits?.toNumber() ?? 0
			},
			refetchOnWindowFocus: false,
		},
	)

	const handleAdjustCredits = async () => {
		if (!auth?.user?.filialId) {
			reportUserError({
				title: "Failed to adjust credits",
				message:
					"you need to have a filial assigned to be able to adjust credits",
			})
		}

		adjustCredits({
			id: userId,
			credits: credits.current,
			pin: pin.current,
		})

		close()
	}

	return (
		<M.Box p={24} w="90%" className="bg-slate-50 rounded-md">
			<M.Flex justify={"space-between"} align={"center"}>
				<M.Box>
					<M.Text className="text-slate-500">User credits available</M.Text>
					<M.Text fz={24} fw={600} color="green">
						${data?.credits.toNumber().toFixed(2)}
					</M.Text>
				</M.Box>
				<M.Button onClick={open}>Edit credits</M.Button>
			</M.Flex>
			<M.Modal withCloseButton={false} centered opened={opened} onClose={close}>
				<>
					<M.Title my={16} fw={300} order={3}>
						Edit credits user
					</M.Title>
					<M.Flex mb={18} gap={34} direction={"column"}>
						<M.NumberInput
							label="New credit amount"
							onChange={(e) => ((credits.current as any) = e)}
							defaultValue={credits.current}
							min={0}
							precision={2}
						/>
						{/* <M.Textarea label="Reason" /> */}
						<M.Divider />
						<M.PasswordInput
							label="Enter the administrator pin"
							onChange={(e) => (pin.current = e.currentTarget.value)}
						/>
					</M.Flex>
					<M.Flex gap={14} justify={"end"}>
						<M.Button onClick={handleAdjustCredits}>Confirm</M.Button>
						<M.Button onClick={close} variant="outline">
							Cancel
						</M.Button>
					</M.Flex>
				</>
			</M.Modal>
		</M.Box>
	)
}
