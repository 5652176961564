import {
	MantineReactTable,
	useMantineReactTable,
	type MRT_ColumnDef,
	type MRT_SortingState,
	type MRT_ColumnFiltersState,
} from "mantine-react-table";
import dayjs from "dayjs";
import { useState } from "react";
import type { FilterKey } from "./$common";
import { reportUserError, reportUserSuccess } from "#/util";
import { type RouterOutputs, trpc } from "#/trpc";
import { useAuth } from "#/context/AuthContext";
import { exportInvoiceToXLSX } from "./export-invoice";
import { printTicketFromOrderV2 } from "#/modules/ticket/print";
import FileSaver from "file-saver";
import { openConfirmModal } from "@mantine/modals";
import { Flex, IconButton, Tooltip } from "@radix-ui/themes";
import { Button, Dropdown } from "@gt/ui";
import MenuIcon from "~icons/ion/ellipsis-horizontal-outline";
import { Link } from "react-router-dom";
import { useDisclosure } from "@mantine/hooks";
import { SendOrderEmailFormDrawer } from "./send-order-email-drawer";
import { Modal } from "@mantine/core";
import { TicketPreview } from "./ticket-preview";
import { MdOutlineDownload } from "react-icons/md";
import { css } from "#/css/css";

type Order = RouterOutputs["v2_5"]["order"]["getByPage"]["entries"][number];

export interface SalesHistoryTableProps {
	filter: Array<FilterKey>;
	search?: string | null;
	dateRange: [Date | null, Date | null];
}

export const SalesHistoryTable = (props: SalesHistoryTableProps) => {
	const [{ auth }] = useAuth();

	// Pagination
	const [pagination, setPagination] = useState({
		pageIndex: 0,
		pageSize: 25,
	});
	// Sorting
	const [sorting, setSorting] = useState<MRT_SortingState>([]);
	// Filtering
	const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
		[],
	);

	const querySorting = useMemo(() => {
		if (sorting[0]) {
			return {
				desc: sorting[0].desc,
				key: sorting[0].id as any,
			};
		}
		return null;
	}, [sorting]);

	const queryFilters = useMemo(() => {
		const filters: {
			associated?: number | null;
			unassisted?: boolean | null;
		} = {};

		for (const columnFilter of columnFilters) {
			if (columnFilter.id === "associated") {
				if (columnFilter.value === "unassisted") {
					filters.associated = null;
					filters.unassisted = true;
				} else if (columnFilter.value) {
					filters.associated = Number(columnFilter.value);
					filters.unassisted = false;
				}
			}
		}

		return filters;
	}, [columnFilters]);

	const { data, isLoading } = trpc.v2_5.order.getByPage.useQuery(
		{
			pageIndex: pagination.pageIndex,
			pageSize: pagination.pageSize,
			sorting: querySorting,
			filters: {
				status: props.filter,
				startDate: props.dateRange[0]!,
				endDate: props.dateRange[1]!,
				filialId: auth?.user?.filialId ?? null,
				associated: queryFilters.associated,
				unassisted: queryFilters.unassisted,
			},
			search: props.search,
		},
		{
			keepPreviousData: true,
			enabled: props.dateRange[0] !== null && props.dateRange[1] !== null,
		},
	);

	const { mutate: exportCsv, isLoading: isLoadingExport } =
		trpc.v2_5.order.exportSalesHistoryTableCsv.useMutation({
			onSuccess: () => {
				reportUserSuccess({
					title: "The csv file has been sent to email",
				});
			},
		});

	const handleExportCsv = () => {
		exportCsv({
			pageIndex: pagination.pageIndex,
			pageSize: pagination.pageSize,
			sorting: querySorting,
			filters: {
				status: props.filter,
				startDate: props.dateRange[0]!,
				endDate: props.dateRange[1]!,
				filialId: auth?.user?.filialId ?? null,
				associated: queryFilters.associated,
				unassisted: queryFilters.unassisted,
			},
			search: props.search,
		});
	};

	const table = useMantineReactTable({
		data: data?.entries ?? [],
		columns,
		manualPagination: true,
		enableTopToolbar: false,
		rowCount: data?.totalEntries ?? 0,
		enableFilters: true,
		state: {
			pagination,
			sorting,
			isLoading,
			columnFilters,
		},
		enableStickyHeader: true,
		manualSorting: true,
		onSortingChange: setSorting,
		onPaginationChange: setPagination,
		manualFiltering: true,
		onColumnFiltersChange: setColumnFilters,
		initialState: {
			showColumnFilters: true,
		},
		renderBottomToolbarCustomActions: () => (
			<div
				className={css({
					display: "flex",
					alignItems: "center",
					gap: 2,
				})}
			>
				<Tooltip content="Export to CSV">
					<IconButton
						color="gray"
						variant="soft"
						loading={isLoadingExport}
						onClick={handleExportCsv}
					>
						<MdOutlineDownload />
					</IconButton>
				</Tooltip>
			</div>
		),
	});

	return <MantineReactTable table={table} />;
};

export const columns: MRT_ColumnDef<Order>[] = [
	{
		id: "actions",
		header: "Actions",
		size: 100,
		enableSorting: false,
		enableColumnFilter: false,
		Cell: (table) => {
			const ctx = trpc.useContext();

			const original = table.row.original;

			const [reprintEpson, setReprintEpson] = useState<{
				enabled: boolean;
				includeLocation?: boolean;
			}>({ enabled: false });
			const [exportToXlsx, setExportToXlsx] = useState<boolean>(false);
			const [previewOpened, setPreviewOpened] = useState<boolean>(false);
			const [enabledFecth, setEnabledFetch] = useState<boolean>(false);
			const [
				openedPreviewSendEmail,
				{ open: openPreviewSendEmail, close: closePreviewSendEmail },
			] = useDisclosure(false);

			const { data } = trpc.order.getById.useQuery(
				{
					id: original.id,
				},
				{
					enabled: enabledFecth,
					onSuccess(data) {
						if (exportToXlsx) {
							exportInvoiceToXLSX(data as any);
							setExportToXlsx(false);
							setEnabledFetch(false);
						}
						if (reprintEpson.enabled) {
							printTicketFromOrderV2({
								order: data!,
								withLocation: !!reprintEpson.includeLocation,
							});
							setReprintEpson({ enabled: false });
							setEnabledFetch(false);
						}
					},
					onError(err) {
						reportUserError({
							title: "Failed to get order",
							message: err.message,
						});
					},
				},
			);

			const { mutate: reverseOrder } = trpc.order.reverse.useMutation({
				onError(err) {
					reportUserError({
						title: "Failed to reverse order",
						message: err.message,
					});
				},
				onSuccess() {
					ctx.v2_5.order.invalidate();
					reportUserSuccess({
						title: "Order reversed",
						message: "Order reversed successfully",
					});
				},
			});

			const { mutate: exportLabel, isLoading: isLoadingExportLabel } =
				trpc.v2_5.order.exportLabel.useMutation({
					onError(err) {
						reportUserError({
							title: "Failed to export label pdf",
							message: err.message,
						});
					},
					async onSuccess(data) {
						FileSaver.saveAs(data.url, "order_label.pdf");

						reportUserSuccess({
							title: "Label pdf exported",
							message: "Label pdf exported successfully",
						});
					},
				});

			const OpenConfirmModal = () => {
				openConfirmModal({
					title: "Are you sure you want to reverse this order?",
					labels: { cancel: "Cancel", confirm: "Confirm" },
					confirmProps: { color: "red" },
					onConfirm: () =>
						reverseOrder({
							id: Number(original.id),
						}),
				});
			};

			return (
				<>
					<Flex>
						<Dropdown.Root>
							<Dropdown.Trigger>
								<Button variant="ghost" size="icon">
									<MenuIcon />
								</Button>
							</Dropdown.Trigger>
							<Dropdown.Content>
								<Dropdown.Item asChild>
									<Link
										target="_blank"
										to={`/sales/sales-history/preview/${original.id}`}
									>
										Preview
									</Link>
								</Dropdown.Item>
								<Dropdown.Item
									onClick={() => {
										setEnabledFetch(true);
										setExportToXlsx(true);
									}}
								>
									Re-print (XLSX)
								</Dropdown.Item>
								<Dropdown.Item asChild>
									<Link
										target="_blank"
										to={`/sales/sales-history/invoice/${original.id}`}
									>
										Re-print (PDF)
									</Link>
								</Dropdown.Item>
								<Dropdown.Item
									onClick={() => {
										setEnabledFetch(true);
										setReprintEpson({ enabled: true });
									}}
								>
									Re-print (Epson)
								</Dropdown.Item>
								<Dropdown.Item
									onClick={() => {
										setEnabledFetch(true);
										setReprintEpson({ enabled: true, includeLocation: true });
									}}
								>
									Re-print with location (Epson)
								</Dropdown.Item>
								<Dropdown.Item
									onClick={() => {
										setEnabledFetch(true);
										setPreviewOpened(true);
									}}
								>
									Preview receipt
								</Dropdown.Item>
								<Dropdown.Item asChild>
									<Link to={`/sales/make-a-sale/copy/${original.id}`}>
										Copy
									</Link>
								</Dropdown.Item>
								<Dropdown.Item onClick={openPreviewSendEmail}>
									Send as e-mail
								</Dropdown.Item>
								<Dropdown.Item
									onClick={OpenConfirmModal}
									disabled={original.orderStatus === "REVERSED"}
								>
									Reverse
								</Dropdown.Item>
								<Dropdown.Item
									onClick={() => {
										exportLabel({ id: original.id, includeQr: false });
									}}
									disabled={isLoadingExportLabel}
								>
									Print label
								</Dropdown.Item>
							</Dropdown.Content>
						</Dropdown.Root>
					</Flex>
					{openedPreviewSendEmail && (
						<SendOrderEmailFormDrawer
							opened={openedPreviewSendEmail}
							onClose={closePreviewSendEmail}
							orderId={original.id}
							receiptNumber={original.receiptNumber}
						/>
					)}
					{previewOpened && (
						<Modal
							opened={previewOpened}
							onClose={() => setPreviewOpened(false)}
							withCloseButton={false}
						>
							{data && <TicketPreview order={data} />}
						</Modal>
					)}
				</>
			);
		},
	},
	{
		id: "createdAt",
		header: "Date",
		accessorKey: "createdAt",
		enableColumnFilter: false,
		Cell: (table) => {
			const createdAt = table.row.original.createdAt;
			if (!createdAt) return "";
			return dayjs(createdAt).format("MM/DD/YYYY").toString();
		},
	},
	{
		header: "Associate",
		enableSorting: false,
		accessorKey: "associated",
		Cell: (table) => {
			return `${table.row.original.associated?.firstName ?? ""} ${table.row.original.associated?.lastName ?? ""}`;
		},
		filterVariant: "select",
		mantineFilterSelectProps() {
			const { data } = trpc.v2_5.user.getAssociateds.useQuery();

			const options =
				data
					?.map((user) => ({
						label: `${user.firstName} ${user?.lastName ?? ""}`,
						value: user.id.toString(),
					}))
					.concat({
						label: "Unassisted",
						value: "unassisted",
					}) ?? [];

			return {
				data: options,
				className: "border-b-0 mt-1 font-normal",
			};
		},
	},
	{
		id: "orderStatus",
		header: "Status",
		accessorKey: "orderStatus",
		enableColumnFilter: false,
	},
	{
		id: "receiptNumber",
		header: "Receipt #",
		accessorKey: "receiptNumber",
		size: 100,
		enableColumnFilter: false,
	},
	{
		header: "Cashier",
		enableSorting: false,
		enableColumnFilter: false,
		Cell: (table) => {
			return `${table.row.original.cashier?.firstName ?? ""} ${table.row.original.cashier?.lastName ?? ""}`;
		},
	},
	{
		header: "Full name",
		enableSorting: false,
		enableColumnFilter: false,
		Cell: (table) => {
			const isGuest = !table.row.original.customer ? true : false;

			const customerFullName = isGuest
				? `${table.row.original.userBasicInfo?.firstName ?? ""} ${table.row.original.userBasicInfo?.lastName ?? ""}`
				: `${table.row.original.customer?.firstName ?? ""} ${table.row.original.customer?.lastName ?? ""}`;

			return (
				<p>
					{customerFullName}
					{isGuest && <span className="text-red-300"> (Guest)</span>}
				</p>
			);
		},
	},
	{
		id: "filialId",
		header: "Filial name",
		enableSorting: false,
		accessorKey: "filial",
		enableColumnFilter: false,
		Cell: (table) => {
			return table.row.original.filial?.name ?? "";
		},
	},
	{
		id: "total",
		header: "Total",
		accessorKey: "total",
		enableColumnFilter: false,
		Cell: (table) => {
			return `$${table.row.original.total.toFixed(2)}`;
		},
	},
];
