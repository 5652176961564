import SceneLayout from "#/components/SceneLayout/index.jsx";
import { MdOutlineFolderOpen } from "react-icons/md/index.js";
import { Outlet } from "react-router";

export const InventoryLayout = () => {
  return (
    <SceneLayout icon={<MdOutlineFolderOpen />}>
      <Outlet />
    </SceneLayout>
  );
};
