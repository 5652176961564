import GTLoader from "#/components-ng/loader";
import { css } from "#/css/css";
import { trpc } from "#/trpc";
import { generateCsv } from "#/util/csv";
import { PieChartCard } from "../common";
import * as M from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import dayjs from "dayjs";
import FileSaver from "file-saver";
import Download from "~icons/ion/download-outline";
import { Button } from "@gt/ui";

type UsersBasicInfo = {
  email: string;
  firstName: string;
  lastName: string | null;
  phoneNumber: string;
};

export const NewCustomersReport = () => {
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    dayjs().startOf("month").toDate(),
    dayjs().endOf("month").toDate(),
  ]);

  const {
    data: report,
    isFetching,
    fetchStatus,
  } = trpc.v2_5.report.getNewCustomersSummary.useQuery(
    {
      startDate: dateRange[0]!,
      endDate: dateRange[1]!,
    },
    {
      enabled: dateRange[0] != null && dateRange[1] != null,
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );

  const data = useMemo(() => {
    if (report) {
      return report;
    }
    return null;
  }, [report]);

  const downloadCsv = async (
    customersInfo: UsersBasicInfo[],
    pathName: string,
  ) => {
    {
      const headers = [
        { label: "Name", value: "firstName" },
        { label: "Last Name", value: "lastName" },
        { label: "Email", value: "email" },
        { label: "Phone Number", value: "phoneNumber" },
      ];
      const csv = await generateCsv({
        headers,
        data: customersInfo ?? [],
      });
      FileSaver.saveAs(
        new Blob([csv], { type: "text/csv" }),
        `${pathName}${dayjs(dateRange[0]).format("MM-DD-YYYY")}__${dayjs(dateRange[1]).format("MM-DD-YYYY") ?? ""}.csv`,
      );
    }
  };

  return (
    <M.Container size="lg">
      <M.Group position="apart">
        <M.Title order={2}>New customers</M.Title>
        <DatePickerInput
          type="range"
          value={dateRange}
          onChange={setDateRange}
          numberOfColumns={2}
          popoverProps={{
            zIndex: 9000,
          }}
          w={400}
        />
      </M.Group>

      <M.Divider my="lg" />
      {isFetching && fetchStatus === "fetching" ? (
        <M.LoadingOverlay
          visible={isFetching}
          loader={<GTLoader width={100} height={100} />}
        />
      ) : (
        <>
          <M.Group align="stretch" mb="lg">
            <PieChartCard
              title="Total new customers"
              text={`${data?.totalNewCustomers}`}
              total={data?.totalNewCustomers ?? 0}
              data={[
                {
                  title: "Customers registered from POS",
                  value: data?.summary.store ?? 0,
                  color: "#B4E490",
                },
                {
                  title: "Customers registered from Ecommerce",
                  value: data?.summary.ecommerce ?? 0,
                  color: "#3E7C1D",
                },
              ]}
              isMoney={false}
            >
              <Button
                className={css({
                  gap: 4,
                  mb: 4,
                  width: "fit-content",
                })}
                onClick={() =>
                  downloadCsv(data?.summary.users ?? [], "new_customers_")
                }
              >
                <Download />
                <M.Text size="sm" color="white">
                  Download new customers
                </M.Text>
              </Button>
            </PieChartCard>
          </M.Group>
          <div
            className={css({
              marginTop: "18px",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              rowGap: "18px",
            })}
          >
            <div
              className={css({
                width: "100%",
                md: {
                  width: "45%",
                },
              })}
            >
              <PieChartCard
                title="New customers with minimum one order"
                text={`${data?.customersWithMinimumOneOrder.totalCustomers}`}
                total={data?.customersWithMinimumOneOrder.totalCustomers ?? 0}
                data={[
                  {
                    title: "Customers registered from POS",
                    value: data?.customersWithMinimumOneOrder.store ?? 0,
                    color: "#B4E490",
                  },
                  {
                    title: "Customers registered from Ecommerce",
                    value: data?.customersWithMinimumOneOrder.ecommerce ?? 0,
                    color: "#3E7C1D",
                  },
                ]}
                isMoney={false}
              >
                <Button
                  className={css({
                    gap: 4,
                    mb: 4,
                  })}
                  onClick={() =>
                    downloadCsv(
                      data?.customersWithMinimumOneOrder.users ?? [],
                      "customers_with_minimum_one_order_",
                    )
                  }
                >
                  <Download />
                  <M.Text size="sm" color="white">
                    Download new customers with minimum one order
                  </M.Text>
                </Button>
              </PieChartCard>
            </div>
            <div
              className={css({
                width: "100%",
                md: {
                  width: "45%",
                },
              })}
            >
              <PieChartCard
                title="New customers without orders"
                text={`${data?.customersWithoutOrders.totalCustomers}`}
                total={data?.customersWithoutOrders.totalCustomers ?? 0}
                data={[
                  {
                    title: "Customers registered from POS",
                    value: data?.customersWithoutOrders.store ?? 0,
                    color: "#B4E490",
                  },
                  {
                    title: "Customers registered from Ecommerce",
                    value: data?.customersWithoutOrders.ecommerce ?? 0,
                    color: "#3E7C1D",
                  },
                ]}
                isMoney={false}
              >
                <Button
                  className={css({
                    gap: 4,
                    mb: 4,
                  })}
                  onClick={() =>
                    downloadCsv(
                      data?.customersWithoutOrders.users ?? [],
                      "customers_without_orders_",
                    )
                  }
                >
                  <Download />
                  <M.Text size="sm" color="white">
                    Download new customers without orders
                  </M.Text>
                </Button>
              </PieChartCard>
            </div>
          </div>
        </>
      )}
    </M.Container>
  );
};

const generateRandomHexColor = () => {
  return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
};
