import { trpc } from "#/trpc.js";
import { reportUserError } from "#/util/ux/index.js";
import { PrintPdfInventory } from "#/components/PdfInventory/index.js";
import * as M from "@mantine/core";
import React from "react";
import { useParams } from "react-router";

export const PrintPdfCategory = () => {
  const [triggeredPrint, setTriggeredPrint] = React.useState(false);
  const { id } = useParams();

  const { data, isLoading, isError } = trpc.category.get.useQuery(
    {
      id: Number(id!),
    },
    {
      onError(error) {
        reportUserError({
          title: "Failed to get department",
          message: error.message,
        });
      },
    }
  );

  React.useEffect(() => {
    if (data && !triggeredPrint) {
      window.setTimeout(() => {
        window.print();
      }, 1000);
      setTriggeredPrint(true);
    }
  }, [data, triggeredPrint]);

  if (isLoading) {
    return (
      <M.Center p={64}>
        <M.Loader />
      </M.Center>
    );
  }

  if (!data || isError) {
    return (
      <M.Center p={64}>
        <M.Text>Error. You should not see this</M.Text>
      </M.Center>
    );
  }

  return (
    <PrintPdfInventory type="Category" name={data?.name} items={data.item} />
  );
};
