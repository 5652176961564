import { trpc } from "#/trpc";
import { VariantGallery } from "./components";
import { useParams } from "react-router";

export const ProductGallery = () => {
  const params = useParams() as any;
  const itemId = params?.id ? Number(params.id) : null;

  const { data } = trpc.item.getItemSkus.useQuery(
    {
      id: itemId!,
    },
    {
      cacheTime: 0,
      enabled: !!itemId,
    },
  );

  const variants = useMemo(() => {
    if (!data) return [];
    return data;
  }, [data]);

  return (
    <div>
      {variants.map((variant) => {
        return (
          <div key={variant.id}>
            <VariantGallery {...variant} />
          </div>
        );
      })}
    </div>
  );
};
