import { trpc } from "#/trpc.js";
import { useEffect } from "react";

type AuthUser = {
  role: {
    id: number;
    level: number;
    name: string;
  };
  user: {
    id: number;
    firstName: string;
    lastName: string | null;
    email: string;
    avatar: string | null;
    filialId: number | null;
  };
};

export function useAuth() {
  const ctx = trpc.useContext();
  const { data, error } = trpc.user.signedInUser.useQuery(undefined, {
    cacheTime: 0,
    meta: {
      reportUserError: false,
    },
  });
  useEffect(() => {
    if (error) {
      ctx.invalidate();
      removeSession();
    }
  }, [ctx, error]);
  return [
    {
      auth: (data as AuthUser) ?? null,
      hasSession: getSession() != null,
    },
    {
      login: (session: { id: string }) => {
        setSession(session.id);
      },
      logout: () => {
        removeSession();
      },
    },
  ] as const;
}

export function setSession(sessionId: string) {
  localStorage.setItem("auth_session", sessionId);
}

export function getSession() {
  return localStorage.getItem("auth_session");
}

export function removeSession() {
  localStorage.removeItem("auth_session");
}
