import { Select, Props, SelectComponent } from "chakra-react-select";
import { forwardRef } from "react";

const USelect = forwardRef((props: Props, ref) => {
  return (
    <Select
      size="sm"
      chakraStyles={{
        control: (old, { isFocused }) => ({
          ...old,
          bg: isFocused ? "brand.50" : "white",
          _focus: {
            boxShadow: "none",
            border: "solid 2px",
            borderColor: "brand.200",
          },
          _hover: {
            borderColor: "brand.100",
          },
        }),
        menu: (old) => ({
          ...old,
          w: "auto",
          zIndex: "dropdown",
        }),
      }}
      {...props}
      // @ts-ignore
      ref={ref}
    />
  );
}) as SelectComponent;

export default USelect;
