import { RouterOutputs, trpc } from "#/trpc.js";
import { reportUserError, reportUserSuccess } from "#/util/ux/index.js";
import * as M from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import placeholderImage from "#/placeholder-image.jpg";
import { Link } from "react-router-dom";
import EditIcon from "~icons/ion/create-outline";
import MenuIcon from "~icons/ion/ellipsis-horizontal-outline";
import DeleteIcon from "~icons/ion/trash-outline";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_FilterOption,
  MRT_PaginationState,
  MRT_SortingState,
  useMantineReactTable,
} from "mantine-react-table";
import dayjs from "dayjs";
import { css } from "#/css/css";
import { DropdownMenu, IconButton, Tooltip } from "@radix-ui/themes";
import { Button, Dropdown } from "@gt/ui";
import { Box, HStack, VStack } from "#/css/jsx";
import { SearchButton } from "#/components@v2_5/search-button";
import { MdOutlineDownload } from "react-icons/md";

const Vendors = () => {
  // Pagination
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });

  // Sorting
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const querySorting = useMemo(() => {
    if (sorting[0]) {
      return {
        desc: sorting[0].desc,
        key: sorting[0].id as any,
      };
    }
    return null;
  }, [sorting]);

  const [globalFilter, setGlobalFilter] = useState<
    MRT_FilterOption | undefined
  >();

  const { data, isLoading } = trpc.v2_5.vendor.getAllVendorsByPage.useQuery(
    {
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize,
      sorting: querySorting,
      search: globalFilter,
    },
    {
      keepPreviousData: true,
    },
  );

  const { mutate: exportCsv, isLoading: isLoadingExport } =
    trpc.v2_5.vendor.exportVendorsTableToCsv.useMutation({
      onSuccess: () => {
        reportUserSuccess({
          title: "The csv file has been sent to email",
        });
      },
    });

  const handleExportCsv = () => {
    exportCsv({
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize,
      sorting: querySorting,
      search: globalFilter,
    });
  };

  const table = useMantineReactTable({
    columns: columns(),
    data: data?.entries ?? [],
    enableTopToolbar: false,
    rowCount: data?.totalEntries ?? 0,
    manualPagination: true,
    enableStickyHeader: true,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    state: {
      pagination,
      sorting,
      isLoading,
    },
    renderBottomToolbarCustomActions: () => (
      <div
        className={css({
          display: "flex",
          alignItems: "center",
          gap: 2,
        })}
      >
        <Tooltip content="Export to CSV">
          <IconButton
            color="gray"
            variant="soft"
            loading={isLoadingExport}
            onClick={handleExportCsv}
          >
            <MdOutlineDownload />
          </IconButton>
        </Tooltip>
      </div>
    ),
  });

  return (
    <VStack gap={6}>
      <HStack w={"100%"} justify={"space-between"}>
        <DrowndownButton />
        <SearchButton setQuery={setGlobalFilter} />
      </HStack>
      <Box w={"100%"}>
        <MantineReactTable table={table} />
      </Box>
    </VStack>
  );
};

export default Vendors;

type Vendor =
  RouterOutputs["v2_5"]["vendor"]["getAllVendorsByPage"]["entries"][number];

const columns = (): MRT_ColumnDef<Vendor>[] => [
  {
    header: "Actions",
    id: "actions",
    Cell: ({ row: { original } }) => {
      const ctx = trpc.useContext();
      const { mutate } = trpc.vendor.delete.useMutation({
        async onSuccess() {
          reportUserSuccess({
            title: "Vendor deleted",
            message: "Vendor deleted successfully",
          });
          ctx.v2_5.vendor.invalidate();
        },
        async onError(error) {
          reportUserError({
            title: "Failed to delete vendor",
            message: error.message,
          });
        },
      });

      const handleDelete = () => {
        openConfirmModal({
          title: "Are you sure you want to disable this vendor?",
          labels: { cancel: "Cancel", confirm: "Delete" },
          confirmProps: { color: "red " },
          onConfirm: () => mutate({ id: original.id }),
        });
      };

      return (
        <M.Flex gap="0.5em">
          <M.Menu>
            <M.Menu.Target>
              <M.ActionIcon>
                <MenuIcon />
              </M.ActionIcon>
            </M.Menu.Target>
            <M.Menu.Dropdown>
              <M.Menu.Item
                component={Link}
                to={`edit/${original.id}`}
                icon={<EditIcon />}
              >
                Edit
              </M.Menu.Item>
              <M.Menu.Item onClick={handleDelete} icon={<DeleteIcon />}>
                Delete
              </M.Menu.Item>
            </M.Menu.Dropdown>
          </M.Menu>
        </M.Flex>
      );
    },
  },
  {
    header: "Image",
    accessorKey: "defaultImage",
    enableSorting: false,
    Cell: ({ row }) => (
      <img
        className={css({ width: "65px" })}
        src={row.original.defaultImage ?? placeholderImage}
      />
    ),
  },
  {
    header: "Contact person name",
    accessorKey: "contactPersonName",
  },
  {
    header: "Company",
    accessorKey: "companyName",
  },
  {
    header: "SEO Name",
    accessorKey: "seoName",
  },
  {
    header: "Phone number",
    accessorKey: "phoneNumber",

    enableSorting: false,
  },
  {
    header: "Email",
    accessorKey: "email",
  },
  {
    header: "Created at",
    accessorKey: "createdAt",

    Cell: ({ row }) => (
      <M.Text>{dayjs(row.original.createdAt).format("MM-DD-YYYY")}</M.Text>
    ),
  },
];

const DrowndownButton = () => {
  return (
    <Dropdown.Root>
      <Dropdown.Trigger>
        <Button
          className={css({
            gap: 4,
          })}
        >
          I want to
          <DropdownMenu.TriggerIcon />
        </Button>
      </Dropdown.Trigger>
      <Dropdown.Content>
        <Dropdown.Item>
          <Link to="create">Create vendor</Link>
        </Dropdown.Item>
      </Dropdown.Content>
    </Dropdown.Root>
  );
};
