import { RouterOutputs, trpc } from "#/trpc.js";
import { reportUserError } from "#/util/index.js";
import Select from "./Select.js";
import { Props, GroupBase } from "chakra-react-select";

type User = Exclude<RouterOutputs["user"]["getAll"], null | undefined>[number];

export function CustomerSelect<
  Group extends GroupBase<User>,
  IsMulti extends boolean = false
>({
  userId,
  ...props
}: Omit<Props<User, IsMulti, Group>, "value"> & { userId?: number }) {
  const { data, isLoading } = trpc.user.getAllUsers.useQuery(undefined, {
    onError: (err) => {
      reportUserError({
        title: "Failed to load users",
        message: err.message,
      });
    },
  });

  return (
    <Select
      options={data || []}
      getOptionLabel={(v) => `${v?.firstName} ${v?.lastName || ""}`}
      getOptionValue={(v) => v?.id.toString() || ""}
      isClearable
      value={data?.find((u) => u?.id === userId)}
      isLoading={isLoading}
      {...props}
    />
  );
}
