import { makeController } from "#/components-ng/index.js";
import { z } from "zod";

export const createBlogSchema = z.object({
  title: z.string({
    required_error: "Name is required",
  }),
  titleEsp: z.string().optional().nullish(),
  text: z.string({
    required_error: "Content is required",
  }),
  textEsp: z.string().optional().nullish(),
  image: z.string().optional().nullish(),
  blogExternalUrl: z.string().optional().nullish(),
  seasons: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
    })
  ),
  tags: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
    })
  ),
  catalogs: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
    })
  ),
});

export type FormValues = z.infer<typeof createBlogSchema>;
export const C = makeController<FormValues>();
