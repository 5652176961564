import { trpc } from "#/trpc";
import { reportUserSuccess } from "#/util";
import { CouponsForm, FormSchema, openConflictingCouponsModal } from "./form";
import { useNavigate } from "react-router";

export const CouponsCreateForm: React.FC = () => {
  const ctx = trpc.useContext();
  const navigate = useNavigate();

  const { mutate } = trpc.v2_5.coupons.create.useMutation({
    onSuccess(data) {
      if (data.isOk()) {
        ctx.v2_5.coupons.invalidate();
        navigate("/inventory/coupons");
        reportUserSuccess({
          title: "Coupon(s) created successfully!",
        });
      } else {
        openConflictingCouponsModal({
          conflictingCoupons: data.error,
        });
      }
    },
  });

  const handleSubmit = (data: FormSchema) => {
    mutate({
      activeDateRange: data.activeDateRange,
      type: data.couponType,
      rates: data.rates.map((rate) => ({
        discountId: rate.discountId,
        couponCode: rate.couponCode,
      })),
    });
  };

  return <CouponsForm mode="create" onSubmit={handleSubmit} />;
};
