import { RouterOutputs, trpc } from "#/trpc";
import { reportUserSuccess } from "#/util";
import { useNavigate } from "react-router";
import { Flex } from "#/css/jsx";
import { Link } from "react-router-dom";
import EditIcon from "~icons/ion/create-outline";
import DeleteIcon from "~icons/ion/trash-outline";
import { ChangelogNewBadge } from "#/components@v2_5/changelog-new-badge";
import { openConfirmModal } from "@mantine/modals";
import { FilterKey } from "./$common";
import { useState } from "react";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_SortingState,
  useMantineReactTable,
} from "mantine-react-table";
import { Button, Dropdown } from "@gt/ui";
import MenuIcon from "~icons/ion/ellipsis-horizontal-outline";
import { css } from "#/css/css";
import { EyeIcon } from "#/components";
import { useDisclosure } from "@mantine/hooks";
import { PurchaseOrderItemSkusModal } from "./purchase-order-item-skus-modal";
import dayjs from "dayjs";
import { Badge, IconButton, Tooltip } from "@radix-ui/themes";
import { match } from "ts-pattern";
import { MdOutlineDownload } from "react-icons/md";

export interface UpcomingItemsTableProps {
  filter: Array<FilterKey>;
  search?: string | null;
}

export const UpcomingItemsTable = (props: UpcomingItemsTableProps) => {
  // Pagination
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 25,
  });
  // Sorting
  const [sorting, setSorting] = useState<MRT_SortingState>([]);

  const querySorting = useMemo(() => {
    if (sorting[0]) {
      return {
        desc: sorting[0].desc,
        key: sorting[0].id as any,
      };
    }
    return null;
  }, [sorting]);

  const { data, isLoading } = trpc.v2_5.purchaseOrder.getByPage.useQuery(
    {
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize,
      sorting: querySorting,
      filters: {
        status: props.filter,
      },
      search: props.search,
    },
    {
      keepPreviousData: true,
    },
  );

  const { mutate: exportCsv, isLoading: isLoadingExport } =
    trpc.v2_5.purchaseOrder.exportUpcomingItemsTableToCsv.useMutation({
      onSuccess: () => {
        reportUserSuccess({
          title: "The csv file has been sent to email",
        });
      },
    });

  const handleExportCsv = () => {
    exportCsv({
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize,
      sorting: querySorting,
      filters: {
        status: props.filter,
      },
      search: props.search,
    });
  };

  const table = useMantineReactTable({
    data: data?.entries ?? [],
    columns,
    manualPagination: true,
    enableTopToolbar: false,
    rowCount: data?.totalEntries ?? 0,
    enableFilters: false,
    state: {
      pagination,
      sorting,
      isLoading,
    },
    enableStickyHeader: true,
    manualSorting: true,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    renderBottomToolbarCustomActions: () => (
      <div
        className={css({
          display: "flex",
          alignItems: "center",
          gap: 2,
        })}
      >
        <Tooltip content="Export to CSV">
          <IconButton
            color="gray"
            variant="soft"
            loading={isLoadingExport}
            onClick={handleExportCsv}
          >
            <MdOutlineDownload />
          </IconButton>
        </Tooltip>
      </div>
    ),
  });

  return <MantineReactTable table={table} />;
};

type PurchaseOrder =
  RouterOutputs["v2_5"]["purchaseOrder"]["getByPage"]["entries"][number];

const columns: MRT_ColumnDef<PurchaseOrder>[] = [
  {
    id: "actions",
    header: "Actions",
    enableSorting: false,
    size: 100,
    Cell: (table) => {
      const [opened, { open, close }] = useDisclosure(false);

      const original = table.row.original;
      const poId = original.id;
      const ctx = trpc.useContext();
      const navigate = useNavigate();

      const { mutate: deletePO } = trpc.purchaseOrder.delete.useMutation({
        onSuccess() {
          ctx.v2_5.purchaseOrder.invalidate();
          reportUserSuccess({
            title: "purchase order deleted",
          });
        },
      });

      const { mutate: getWaitingCustomers, isLoading: isLoadingGetWaiting } =
        trpc.userWaitingList.verifyWaitingCustomers.useMutation({
          onSuccess(data) {
            if (data.length > 0) {
              navigate(
                `/inventory/upcoming-items/receive/waiting-list/${poId}`,
              );
            } else {
              reportUserSuccess({
                title:
                  "There are no waiting customers for products in this purchase order",
              });
            }
          },
        });

      const openConfirmDeleteModal = () =>
        openConfirmModal({
          title: "Are you sure you want to delete this purchase order?",
          labels: {
            confirm: "Delete",
            cancel: "Cancel",
          },
          confirmProps: { color: "red" },
          onConfirm: () => deletePO({ id: poId }),
        });

      return (
        <>
          <Flex>
            <Dropdown.Root>
              <Dropdown.Trigger>
                <Button variant="ghost" size="icon">
                  <MenuIcon />
                </Button>
              </Dropdown.Trigger>
              <Dropdown.Content>
                {original.status === "DRAFT" && (
                  <>
                    <Dropdown.Item asChild>
                      <Link
                        to={
                          original.type === "NEW_ITEMS"
                            ? `/inventory/upcoming-items/create-po/new-items/${poId}`
                            : `/inventory/upcoming-items/create-po/restock/${poId}`
                        }
                      >
                        <div
                          className={css({
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: 2,
                          })}
                        >
                          <EditIcon />
                          Edit draft
                        </div>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={openConfirmDeleteModal}>
                      <div
                        className={css({
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: 2,
                        })}
                      >
                        <DeleteIcon />
                        Delete
                      </div>
                    </Dropdown.Item>
                  </>
                )}
                {(original.status === "UPCOMING" ||
                  original.status === "PARTIALLY_RECEIVED") && (
                  <Dropdown.Item asChild>
                    <Link
                      to={`/inventory/upcoming-items/receive/${poId}`}
                      className={css({
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 2,
                      })}
                    >
                      <span>Receive items</span>
                      <ChangelogNewBadge
                        module="inventory.upcomingItems"
                        openDialog={false}
                      />
                    </Link>
                  </Dropdown.Item>
                )}
                {original.status === "RECEIVED" && (
                  <Dropdown.Item
                    onClick={() => {
                      getWaitingCustomers({ purchaseOrderId: poId });
                    }}
                    disabled={isLoadingGetWaiting}
                  >
                    Notify waiting customers
                  </Dropdown.Item>
                )}
                {original.status !== "DRAFT" && (
                  <Dropdown.Item asChild>
                    <Link
                      className={css({
                        display: "flex",

                        alignItems: "center",
                        gap: 2,
                      })}
                      target="_blank"
                      to={`/inventory/upcoming-items/invoice/${poId}`}
                    >
                      Print (PDF)
                    </Link>
                  </Dropdown.Item>
                )}
              </Dropdown.Content>
            </Dropdown.Root>
            <Button variant="ghost" size="icon" onClick={open}>
              <EyeIcon />
            </Button>
          </Flex>
          {opened && (
            <PurchaseOrderItemSkusModal
              purchaseOrderId={poId}
              opened={opened}
              setOpened={close}
            />
          )}
        </>
      );
    },
  },
  {
    id: "id",
    accessorKey: "id",
    header: "PO #",
  },
  {
    id: "type",
    accessorKey: "type",
    header: "Type",
    enableSorting: false,
  },
  {
    id: "createdAt",
    accessorKey: "createdAt",
    header: "Order date",
    Cell: (table) => {
      const createdAt = table.row.original.createdAt;
      if (!createdAt) return "";
      return dayjs(createdAt).format("MM/DD/YYYY").toString();
    },
  },
  {
    id: "status",
    accessorKey: "status",
    header: "Status",
    enableSorting: false,
    Cell: (table) => {
      const status = table.row.original.status;
      const value = `${status[0]}${status.substring(1).toLowerCase()}`;

      return (
        <Badge
          size="3"
          radius="full"
          color={
            match(status)
              .with("UPCOMING", () => "red")
              .with("PARTIALLY_RECEIVED", () => "orange")
              .with("RECEIVED", () => "green")
              .otherwise(() => "gray") as any
          }
        >
          {value}
        </Badge>
      );
    },
  },
  {
    id: "createdById",
    accessorKey: "createdBy",
    header: "Created by",
    enableSorting: false,
    Cell: (table) => {
      const fullName = `${table.row.original?.createdBy?.firstName ?? ""} ${
        table.row.original?.createdBy?.lastName ?? ""
      }`;

      return fullName;
    },
  },
  {
    id: "note",
    accessorKey: "note",
    header: "Note",
    enableSorting: false,
  },
];
