import { useAuth } from "#/context/AuthContext";
import { trpc } from "#/trpc";
import { reportUserError, reportUserSuccess } from "#/util";
import { FilialForm, FilialFormValues, RolesAllowed } from ".";
import { useNavigate } from "react-router";

export const CreateFilial = () => {
  const [{ auth }] = useAuth();
  const navigate = useNavigate();

  const rolesAllowed: RolesAllowed[] = ["ROOT"];

  if (auth?.role?.name !== "ROOT") {
    reportUserError({
      title: "You are not allowed to create filial",
    });
    navigate("/settings");
  }

  const { mutate, isLoading } = trpc.filial.create.useMutation({
    onSuccess: () => {
      reportUserSuccess({
        title: "Filial created successfully",
        message: "",
      });
      navigate("/settings");
    },
    onError: (error) => {
      reportUserError({
        title: "Failed to create filial",
        message: error.message,
      });
    },
  });

  const handleSubmit = (values: FilialFormValues) => {
    if (rolesAllowed.includes(auth?.role.name as any)) {
      mutate({
        ...values,
        entityId: 1,
      });
      return;
    } else {
      reportUserError({
        title: "You are not allowed to create filial",
      });
      return;
    }
  };

  return (
    <FilialForm
      rolesAllowed={rolesAllowed}
      isLoading={isLoading}
      onSubmit={handleSubmit}
    />
  );
};
