import { RouterInputs } from "#/trpc";

export type UserWaitingListStatus = NonNullable<
  RouterInputs["v2_5"]["waitingProducts"]["getByPage"]["filters"]
>["status"][number];

export const Filter: Record<UserWaitingListStatus, string> = {
  WAITING: "Waiting",
  NOTIFIED: "Notified",
} as const;

export type FilterKey = keyof typeof Filter;
export type Filter = (typeof Filter)[FilterKey];
