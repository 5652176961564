import { makeController } from "#/components-ng/index.js";
import { z } from "zod";

export const customersRange = ["10", "25", "50", "100"] as const;

export const generateCustomersWithMostLVReportSchema = z.object({
  dateRange: z.array(z.date()),
  customersRange: z.enum(customersRange),
  filter: z
    .discriminatedUnion("by", [
      z.object({
        by: z.literal("entity"),
        id: z.string(),
      }),
      z.object({
        by: z.literal("filial"),
        id: z.string(),
      }),
    ])
    .default({ by: "entity", id: "1" }),
});

export type FormValues = z.infer<
  typeof generateCustomersWithMostLVReportSchema
>;
export const C = makeController<FormValues>();
