import { ActionsMenuIcon } from "#/components-ng"
import { RouterOutputs, trpc } from "#/trpc"
import dayjs from "dayjs"
import { Link } from "react-router-dom"
import { Box, styled } from "#/css/jsx"
import {
	MantineReactTable,
	MRT_ColumnDef,
	MRT_SortingState,
	useMantineReactTable,
} from "mantine-react-table"
import { Dropdown } from "@gt/ui"
import { IconButton, Tooltip } from "@radix-ui/themes"
import CheckIcon from "~icons/ic/baseline-check"
import CloseIcon from "~icons/ic/baseline-close"
import { reportUserError, reportUserSuccess } from "#/util"
import { openConfirmModal } from "@mantine/modals"
import { MdOutlineDownload } from "react-icons/md"
import { css } from "#/css/css"
import timezone from "dayjs/plugin/timezone.js"
import utc from "dayjs/plugin/utc.js"

dayjs.extend(utc)
dayjs.extend(timezone)

interface HomePageBannerTableProps {
	search?: string | null
}

export const HomerPageBannerTable = (props: HomePageBannerTableProps) => {
	// Pagination
	const [pagination, setPagination] = useState({
		pageIndex: 0,
		pageSize: 10,
	})
	// Sorting
	const [sorting, setSorting] = useState<MRT_SortingState>([])
	const querySorting = useMemo(() => {
		if (sorting[0]) {
			return {
				desc: sorting[0].desc,
				key: sorting[0].id as any,
			}
		}
		return null
	}, [sorting])

	const { data, isLoading } = trpc.v2_5.banner.getByPage.useQuery(
		{
			pageIndex: pagination.pageIndex,
			pageSize: pagination.pageSize,
			search: props.search,
			sorting: querySorting,
		},
		{
			keepPreviousData: true,
		},
	)

	const { mutate: exportCsv, isLoading: isLoadingExport } =
		trpc.v2_5.banner.exportBannersTableToCsv.useMutation({
			onSuccess: () => {
				reportUserSuccess({
					title: "The csv file has been sent to email",
				})
			},
		})

	const handleExportCsv = () => {
		exportCsv({
			pageIndex: pagination.pageIndex,
			pageSize: pagination.pageSize,
			search: props.search,
			sorting: querySorting,
		})
	}

	const table = useMantineReactTable({
		data: data?.entries ?? [],
		columns: columns,
		manualPagination: true,
		enableTopToolbar: false,
		rowCount: data?.totalEntries ?? 0,
		enableFilters: false,
		state: {
			pagination,
			sorting,
			isLoading,
		},
		enableStickyHeader: true,
		manualSorting: true,
		onSortingChange: setSorting,
		onPaginationChange: setPagination,
		renderBottomToolbarCustomActions: () => (
			<div
				className={css({
					display: "flex",
					alignItems: "center",
					gap: 2,
				})}
			>
				<Tooltip content="Export to CSV">
					<IconButton
						color="gray"
						variant="soft"
						loading={isLoadingExport}
						onClick={handleExportCsv}
					>
						<MdOutlineDownload />
					</IconButton>
				</Tooltip>
			</div>
		),
	})

	return <MantineReactTable table={table} />
}

type Banner = RouterOutputs["v2_5"]["banner"]["getByPage"]["entries"][number]

const columns: Array<MRT_ColumnDef<Banner>> = [
	{
		id: "actions",
		header: "Actions",
		size: 100,
		enableColumnFilter: false,
		Cell(props) {
			const ctx = trpc.useContext()

			const { mutate } = trpc.v2_5.banner.delete.useMutation({
				onError(error) {
					reportUserError({
						title: "Error deleting home page banner",
						message: error.message,
					})
				},
				onSuccess() {
					ctx.v2_5.banner.invalidate()
					reportUserSuccess({
						title: "Home page banner deleted",
					})
				},
			})

			const { mutate: duplicateBanner } =
				trpc.v2_5.banner.duplicateBanner.useMutation({
					onError(error) {
						reportUserError({
							title: "Error duplicating home page banner",
							message: error.message,
						})
					},
					onSuccess() {
						ctx.v2_5.banner.invalidate()
						reportUserSuccess({
							title: "Home page banner duplicated",
						})
					},
				})

			const openConfirmDeleteModal = () =>
				openConfirmModal({
					title: "Are you sure you want to delete this banner?",
					labels: { confirm: "Confirm", cancel: "Cancel" },
					confirmProps: { color: "red" },
					onConfirm: () =>
						mutate({
							id: props.row.original.id,
						}),
				})

			const openConfirmDuplicateModal = () =>
				openConfirmModal({
					title: "Are you sure you want to duplicate this banner?",
					labels: { confirm: "Confirm", cancel: "Cancel" },
					confirmProps: { color: "red" },
					onConfirm: () => duplicateBanner({ id: props.row.original.id }),
				})

			return (
				<Dropdown.Root>
					<Dropdown.Trigger>
						<styled.div
							display="flex"
							justifyContent="center"
							alignItems="center"
						>
							<IconButton variant="ghost" color="gray" size="1">
								<ActionsMenuIcon />
							</IconButton>
						</styled.div>
					</Dropdown.Trigger>
					<Dropdown.Content>
						<Dropdown.Item asChild>
							<Link to={`edit/${props.row.original.id}`}>Edit</Link>
						</Dropdown.Item>
						<Dropdown.Item onClick={openConfirmDeleteModal}>
							Delete
						</Dropdown.Item>
						<Dropdown.Item onClick={openConfirmDuplicateModal}>
							Duplicate
						</Dropdown.Item>
					</Dropdown.Content>
				</Dropdown.Root>
			)
		},
	},
	{
		id: "imageDesktop",
		header: "Image",
		Cell(props) {
			return (
				<img
					src={props.row.original.imageDesktop}
					className={"h-10 w-20 object-cover"}
				/>
			)
		},
	},
	{
		id: "title",
		header: "Title",
		accessorKey: "title",
	},
	{
		id: "activeFrom",
		header: "Active from",
		accessorKey: "activeFrom",
		Cell(props) {
			return <p>{dayjs(props.row.original.activeFrom).format("DD/MM/YYYY")}</p>
		},
	},
	{
		id: "activeTo",
		header: "Active to",
		accessorKey: "activeTo",
		Cell(props) {
			return <p>{dayjs(props.row.original.activeTo).format("DD/MM/YYYY")}</p>
		},
	},
	{
		id: "active",
		header: "Active",
		Cell(props) {
			const value = dayjs().isBetween(
				dayjs(props.row.original.activeFrom)
					.tz("America/New_York")
					.startOf("day"),
				dayjs(props.row.original.activeTo).tz("America/New_York").endOf("day"),
			)

			return (
				<Box color={value ? "green" : "red"}>
					{value ? <CheckIcon /> : <CloseIcon />}
				</Box>
			)
		},
	},
]
