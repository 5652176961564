import { ChevronDownOutline } from "#/components-ng/index.js";
import { useAuth } from "#/context/AuthContext.js";
import { trpc } from "#/trpc.js";
import { reportUserError } from "#/util/index.js";
import { CreateCustomerModal } from "./CreateCustomerModal.js";
import { SearchSelect } from "./SearchSelect.js";
import {
  filialTaxAtom,
  orderTotalAtom,
  shippingCostAtom,
  subtotalAtom,
  subtotalTaxAtom,
} from "./state/cost-breakdown.js";
import { filialAtom } from "./state/filial.js";
import {
  customerAtom,
  cartEntriesAtom,
  salesAssociateAtom,
  cashierAtom,
  helperAtom,
  receiptNameAtom,
} from "./state/index.js";
import {
  giftPaymentMethodAtom,
  userCreditsPaymentMethodAtom,
} from "./state/payment-methods.js";
import * as C from "@chakra-ui/react";
import * as M from "@mantine/core";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { debounce } from "lodash";
import React, { useMemo } from "react";
import { MdPersonAdd, MdOutlinePrint } from "react-icons/md/index.js";
import type { PublicUser } from "server/index.js";
import { noteAtom } from "./state/note.js";

export function SearchHeader() {
  const createCustomerDisclosure = C.useDisclosure();
  const shipping = useAtomValue(shippingCostAtom);
  const customer = useAtomValue(customerAtom);
  const cartEntries = useAtomValue(cartEntriesAtom);
  const globalDiscount = useAtomValue(giftPaymentMethodAtom);
  const taxTotal = useAtomValue(subtotalTaxAtom);
  const subTotal = useAtomValue(subtotalAtom);
  const total = useAtomValue(orderTotalAtom);
  const userCreditsPaymentMethod = useAtomValue(userCreditsPaymentMethodAtom);
  const filialTax = useAtomValue(filialTaxAtom);
  const note = useAtomValue(noteAtom);

  const [salesAssociate, setSalesAssociate] = useAtom(salesAssociateAtom);
  const [cashier, setCashier] = useAtom(cashierAtom);
  const [helper, setHelper] = useAtom(helperAtom);

  const HandlePrint = () => {
    if (!customer) {
      return reportUserError({
        title: "Failed to print",
        message: "You must select a customer",
      });
    }

    localStorage.setItem(
      "cart",
      JSON.stringify({
        globalDiscount: globalDiscount,
        tax: taxTotal,
        subtotal: subTotal,
        total: total,
        shipping: shipping,
        customer: {
          firstName: customer.firstName,
          lastName: customer?.lastName ?? "",
          taxable: customer.taxable,
          company: customer?.company ?? "",
          email: customer?.email ?? "",
          phoneNumber: customer?.phoneNumber ?? "",
        },
        cartItemSku: cartEntries.map((entry) => ({
          sku: entry.itemWithVariant.itemSku.sku,
          title: entry.itemWithVariant.itemSku.title,
          price: entry.itemWithVariant.itemSku.price,
          quantity: entry.quantity,
          defaultImage: entry.itemWithVariant.itemSku.defaultImage ?? "",
          discount: entry.discount as any,
        })),
        userCredits: userCreditsPaymentMethod ?? null,
        taxFilial: filialTax,
        note: note,
      })
    );

    window.open("/sales/make-a-sale/pdf", "_blank");
  };

  return (
    <C.Box>
      <C.HStack justify="space-between" w="100%">
        <C.Box w="22ch">
          <SearchProduct />
        </C.Box>
        <C.HStack spacing={2} flex={1} justify="end">
          <C.Box w="35ch">
            <SearchCustomer />
          </C.Box>
          <M.Popover>
            <M.Popover.Target>
              <M.Button
                color="darkGray.7"
                variant="light"
                rightIcon={<ChevronDownOutline />}
              >
                Associated
              </M.Button>
            </M.Popover.Target>
            <M.Popover.Dropdown>
              <div>
                <h3 className="mb-4">Order associated</h3>
                <div className="grid gap-y-3">
                  <UserSelect
                    label="Sales associate"
                    value={salesAssociate}
                    onChange={setSalesAssociate}
                  />
                  {/* <UserSelect
                    label="Cashier"
                    value={cashier}
                    onChange={setCashier}
                  /> */}
                  <UserSelect
                    label="Helper"
                    value={helper}
                    onChange={setHelper}
                  />
                </div>
              </div>
            </M.Popover.Dropdown>
          </M.Popover>
          <C.IconButton
            aria-label="Create customer"
            icon={<MdPersonAdd />}
            variant="ghost"
            onClick={createCustomerDisclosure.onOpen}
          />
          <C.IconButton
            aria-label="print"
            icon={<MdOutlinePrint />}
            color="gray.600"
            variant="ghost"
            onClick={HandlePrint}
          />
        </C.HStack>
      </C.HStack>
      <CreateCustomerModal {...createCustomerDisclosure} />
    </C.Box>
  );
}

function SearchProduct({ chakraStyles = undefined, ...props }) {
  const dispatch = useSetAtom(cartEntriesAtom);
  const filial = useAtomValue(filialAtom);
  const checkIsStoreFilial =
    filial && filial.name?.toLocaleLowerCase()?.includes("store");

  const [auth] = useAuth();

  const [query, setQuery] = React.useState("");
  const { data, isInitialLoading } = trpc.itemSku.search.useQuery(
    { query: `\\"${query}\\"`, onlyAvailable: true },

    {
      enabled: query.length > 0,
      onError(error) {
        console.warn(error);
      },
    }
  );

  const productOptions = React.useMemo(
    () =>
      data?.map((itemSku) => ({
        label: `${itemSku.sku} - ${itemSku.title}`,
        value: itemSku.id,
      })) ?? [],
    [data]
  );

  return (
    <SearchSelect
      placeholder="Scan or enter information."
      options={productOptions}
      onInputChange={debounce((productQuery) => setQuery(productQuery), 250)}
      onChange={(product) => {
        const selectedItemSku = data!.find(
          (itemSku) => itemSku.id === product!.value
        )!;

        const itemSkuStock =
          selectedItemSku.itemSkuStock.find(
            (skuStock) => skuStock?.filialId === auth.auth?.user?.filialId
          )?.quantity ?? 0;

        if (!checkIsStoreFilial && itemSkuStock <= 0) {
          reportUserError({
            title: `There's no stock for ${selectedItemSku.sku} ${selectedItemSku.title}`,
          });
        } else {
          if(filial?.type === "WAREHOUSE" && selectedItemSku.cost.lte(0)){
            reportUserError({
              title: `Cost is not set for ${selectedItemSku.sku} ${selectedItemSku.title}`,
            });
            return;
          }

          dispatch({
            type: "add",
            payload: {
              item: selectedItemSku.item,
              itemSku: selectedItemSku,
            },
          });
        }
      }}
      isLoading={isInitialLoading}
      chakraStyles={chakraStyles}
      getOptionLabel={(p) => p.label}
      getOptionValue={(p) => p.value.toString()}
      {...props}
    />
  );
}

function SearchCustomer() {
  const setCustomer = useSetAtom(customerAtom);
  const setReceiptName = useSetAtom(receiptNameAtom);

  const [query, setQuery] = React.useState("");
  const { data, isInitialLoading } = trpc.user.search.useQuery(
    {
      query,
    },
    {
      enabled: query.length > 0,
    }
  );

  const customerOptions = React.useMemo(
    () =>
      data?.map((c) => ({
        label: `${c.firstName} ${c.lastName ?? ""} (${c.phoneNumber ?? ""})`,
        value: c,
      })) ?? [],
    [data]
  );

  return (
    <SearchSelect
      placeholder="Enter customer or phone number (min. 2 chars.)"
      options={customerOptions}
      onInputChange={debounce((customerQuery) => setQuery(customerQuery), 250)}
      onChange={(customer) => {
        setCustomer(customer!.value);
        setReceiptName(
          customer!.value?.company ? "companyName" : "customerName"
        );
      }}
      isLoading={isInitialLoading}
      getOptionLabel={(c) => c.label}
      getOptionValue={(c) => c?.value.id.toString()}
    />
  );
}

type UserSelectProps = {
  label: string;
  value?: PublicUser | null;
  onChange?: (user: PublicUser) => void;
};
function UserSelect(props: UserSelectProps) {
  const [{ auth }] = useAuth();
  const userdata = trpc.user.getAll.useQuery({
    filialId: auth?.user?.filialId ?? null,
  });

  const selectData = useMemo(() => {
    const users =
      userdata.data?.map((user) => ({
        label: `${user.firstName} ${user.lastName ?? ""} (${
          user.phoneNumber ?? ""
        })`,
        value: user.id.toString(),
        user: user as PublicUser,
      })) ?? [];
    if (
      props.value != null &&
      users.find((u) => u.value === props.value?.id.toString()) == null
    ) {
      users.push({
        label: `${props.value.firstName} ${props.value.lastName ?? ""} (${
          props.value.phoneNumber ?? ""
        })`,
        value: props.value.id.toString(),
        user: props.value,
      });
    }
    return users;
  }, [props.value, userdata.data]);

  return (
    <M.Select
      label={props.label}
      data={selectData}
      searchable
      value={props.value?.id.toString() ?? null}
      onChange={(uId) => {
        const user = selectData.find((u) => u.value === uId);
        if (user == null) return;
        props.onChange?.(user.user);
      }}
    />
  );
}
