import GTLoader from "#/components-ng/loader.js";
import { LOGO_URL } from "#/constants/envt.js";
import { Box, Flex, Stack } from "#/css/jsx";
import { RouterOutputs, trpc } from "#/trpc.js";
import { reportUserError } from "#/util/index.js";
import * as M from "@mantine/core";
import dayjs from "dayjs";
import Decimal from "decimal.js";
import React from "react";
import { useParams } from "react-router";

export const PurchaseOrderInvoice = () => {
  const [imageLoaded, setImageLoaded] = React.useState(false);
  const { id } = useParams();
  const idFilter = Number(id);

  const { data: purchaseOrder, isLoading } =
    trpc.v2_5.purchaseOrder.getById.useQuery(
      {
        id: idFilter,
      },
      {
        onError(error) {
          reportUserError({
            title: "Failed to load order",
            message: error.message,
          });
        },
        refetchOnWindowFocus: false,
      },
    );

  const { data: filials } = trpc.filial.getAll.useQuery();

  React.useEffect(() => {
    if (purchaseOrder && imageLoaded) {
      window.print();
    }
  }, [purchaseOrder, imageLoaded]);

  if (isLoading)
    return <M.LoadingOverlay loader={<GTLoader />} visible={isLoading} />;

  const itemsCosts = purchaseOrder?.purchaseOrderItemSku.map((p) =>
    new Decimal(p?.cost ?? 0).times(p.expectedQuantity),
  );

  const shippingCost = purchaseOrder?.purchaseOrderShipping.map(
    (p) => new Decimal(p?.shippingCost ?? 0),
  );

  const sum = (array: Decimal[]) =>
    array?.reduce((acc, val) => acc.plus(val), new Decimal(0));

  const subTotal = sum(itemsCosts ?? []);
  const shippingCostTotal = sum(shippingCost ?? []);
  const total = new Decimal(purchaseOrder?.dutyCost ?? 0)
    .plus(subTotal)
    .plus(shippingCostTotal);

  return (
    <M.Box>
      <M.Group position="apart">
        <M.Group position="left" spacing="md" align="flex-start">
          <M.Image
            src={LOGO_URL}
            width={100}
            onLoad={() => setImageLoaded(true)}
          />
          <M.Stack spacing={0} align="flex-start">
            <M.Text weight={600} size={20} color="#836415">
              Gold Tree
            </M.Text>
            <M.Text weight={500} size={16} color="#1A1C21">
              www.goldtreemiami.com
            </M.Text>
            <M.Text size={16} color="#5E6470">
              +1 (786) 7177131
            </M.Text>
            <M.Text size={16} color="#5E6470">
              755 NW 72nd Ave Plaza 33
            </M.Text>
            <M.Text size={16} color="#5E6470">
              Miami, FL, 33126
            </M.Text>
          </M.Stack>
        </M.Group>
        <M.Stack spacing={0} align="flex-end">
          <M.Text size={16} color="#5E6470">
            Order number
          </M.Text>
          <M.Text weight={500} size={16} color="#1A1C21" mb={16}>
            #{purchaseOrder?.id}
          </M.Text>
          <M.Text size={16} color="#5E6470">
            Sale date: {dayjs(purchaseOrder?.createdAt).format("MM/DD/YYYY")}
          </M.Text>
          <M.Text size={16} color="#5E6470">
            Printed date: {dayjs().format("MM/DD/YYYY")}
          </M.Text>
        </M.Stack>
      </M.Group>
      <M.Divider my={40} color="#D7DAE0" />
      <Flex spaceX={20} justify={"space-evenly"}>
        <Stack>
          <text>From</text>
          <M.Text weight={500} size={16} color="#1A1C21">
            {purchaseOrder?.vendor?.seoName ?? "N/A"}
          </M.Text>
        </Stack>
        <Stack mb={30} align="flex-start">
          <M.Text size={16} color="#5E6470">
            Billed to
          </M.Text>
          <M.Text weight={500} size={16} color="#1A1C21">
            {purchaseOrder?.entity.name}
          </M.Text>
        </Stack>
        <Box justifySelf={"right"}>
          <Flex borderRadius={"50px"} bg="#F9F9F9 !important" px={3} py={1}>
            {purchaseOrder?.status
              .slice(0, 1)
              .concat(
                purchaseOrder?.status.slice(1).toLowerCase().replace("_", " "),
              )}
          </Flex>
        </Box>
      </Flex>

      <TableQuantity purchaseOrder={purchaseOrder} filials={filials} />

      <M.Box className="h-auto w-full rounded-md bg-[#F9F9F9]">
        <M.Group position="right" className="p-5">
          <M.Box className="w-1/4">
            <M.Text size={14} color="#5E6470">
              Subtotal
            </M.Text>
            <M.Text size={14} color="#5E6470">
              Shipping
            </M.Text>
            <M.Text size={14} color="#5E6470">
              Duty cost
            </M.Text>
            <M.Divider my={14} color="#D7DAE0" />
            <M.Text size={14} color="#5E6470" weight={600}>
              Total
            </M.Text>
          </M.Box>
          <M.Box className="text-right">
            <M.Text size={14} color="#5E6470">
              ${subTotal.toNumber().toFixed(2) ?? 0.0}
            </M.Text>
            <M.Text size={14} color="#5E6470">
              ${shippingCostTotal.toNumber().toFixed(2) ?? 0.0}
            </M.Text>
            <M.Text size={14} color="#5E6470">
              ${purchaseOrder?.dutyCost?.toFixed(2) ?? "0.0"}
            </M.Text>
            <M.Divider my={14} color="#D7DAE0" />
            <M.Text size={14} color="#5E6470" weight={600}>
              ${total.toNumber().toFixed(2) ?? 0.0}
            </M.Text>
          </M.Box>
        </M.Group>
      </M.Box>

      {purchaseOrder?.note && (
        <M.Stack className="mt-5" spacing="xs">
          <M.Text size={14} color="#1A1C21" weight={600}>
            Note:
          </M.Text>
          <M.Text size={14} color="#5E6470">
            {purchaseOrder?.note}
          </M.Text>
        </M.Stack>
      )}
    </M.Box>
  );
};

type PurchaseOrder = RouterOutputs["v2_5"]["purchaseOrder"]["getById"];

const TableQuantity = ({
  purchaseOrder,
  filials,
}: {
  purchaseOrder?: PurchaseOrder;
  filials?: { id: number; name: string }[];
}) => {
  if (
    purchaseOrder?.status === "PARTIALLY_RECEIVED" ||
    purchaseOrder?.status === "RECEIVED"
  ) {
    return (
      <M.Table mb={40}>
        <thead className="w-4 border !border-x-0 !border-y-[#D7DAE0] p-5">
          <tr>
            <th className="!p-4 !text-left !text-[11.2px] !font-semibold !text-[#5E6470]">
              SKU
            </th>
            <th className="!p-4 !text-left !text-[11.2px] !font-semibold !text-[#5E6470]">
              ITEM
            </th>
            <th className="!p-4 !text-left !text-[11.2px] !font-semibold !text-[#5E6470]">
              QTY
            </th>
            {filials?.map((filial, i) => (
              <th
                key={i}
                className="!p-4 !text-left !text-[11.2px] !font-semibold !text-[#5E6470]"
              >
                {filial.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="w-4 border !border-x-0 !border-y-[#D7DAE0]">
          {purchaseOrder?.purchaseOrderItemSku?.map((p, i) => (
            <tr key={i}>
              <td className="!border-none !p-4 text-left !text-xs !text-[#1A1C21]">
                {p?.itemSku.sku}
              </td>
              <td className="!border-none !p-4 text-left !text-xs !text-[#1A1C21]">
                {p?.itemSku.title}
              </td>
              <td className="!border-none !p-4 text-left !text-xs !text-[#1A1C21]">
                {p?.expectedQuantity}
              </td>
              {filials?.map((filial, i) => (
                <td
                  key={i}
                  className="!border-none !p-4 text-left !text-xs !text-[#1A1C21]"
                >
                  {p?.receivedPurchaseOrderItemSku?.find(
                    (q) => q.filialId === filial.id,
                  )?.receivedQuantity ?? 0}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </M.Table>
    );
  } else {
    return (
      <M.Table mb={40}>
        <thead className="w-4 border !border-x-0 !border-y-[#D7DAE0] p-5">
          <tr>
            <th className="!p-4 !text-left !text-[11.2px] !font-semibold !text-[#5E6470]">
              SKU
            </th>
            <th className="!p-4 !text-left !text-[11.2px] !font-semibold !text-[#5E6470]">
              ITEM DETAIL
            </th>
            <th className="!p-4 !text-left !text-[11.2px] !font-semibold !text-[#5E6470]">
              QTY
            </th>
          </tr>
        </thead>
        <tbody className="w-4 border !border-x-0 !border-y-[#D7DAE0]">
          {purchaseOrder?.purchaseOrderItemSku?.map((p, i) => (
            <tr key={i}>
              <td className="!border-none !p-4 text-left !text-xs !text-[#1A1C21]">
                {p?.itemSku.sku}
              </td>
              <td className="!border-none !p-4 text-left !text-xs !text-[#1A1C21]">
                {p?.itemSku.title}
              </td>
              <td className="!border-none !p-4 text-left !text-xs !text-[#1A1C21]">
                {p?.expectedQuantity}
              </td>
            </tr>
          ))}
        </tbody>
      </M.Table>
    );
  }
};
