import { trpc } from "#/trpc";
import { reportUserError, reportUserSuccess } from "#/util";
import { useNavigate, useParams } from "react-router";
import { FormValues } from "./Form/types";
import { LoadingOverlay } from "@mantine/core";
import GTLoader from "#/components-ng/loader";
import { SysSectionForm } from "./Form";

export const UpdateSysSection = () => {
  const params = useParams() as any;
  const id = params?.id ? Number(params.id) : null;
  const navigate = useNavigate();
  const ctx = trpc.useContext();

  const { data, isLoading, isFetchedAfterMount } =
    trpc.v2_5.changelog.sysSections.getById.useQuery(
      {
        id: id!,
      },
      {
        enabled: !!id,
        cacheTime: 0,
      },
    );

  const { mutate, isLoading: updateIsLoading } =
    trpc.v2_5.changelog.sysSections.update.useMutation({
      onSuccess() {
        ctx.v2_5.changelog.invalidate();
        reportUserSuccess({
          title: "Updated sys-section successfully",
        });
        navigate(`/settings/admin/changelog/sys-sections`);
      },
    });

  const handleSubmit = (values: FormValues) => {
    if (!values.sysBlockId) {
      reportUserError({
        title: "SysBlock is required",
      });
      return;
    }

    mutate({
      id: id!,
      name: values.name,
      sysBlockId: Number(values.sysBlockId),
    });
  };

  return isLoading || !isFetchedAfterMount ? (
    <LoadingOverlay
      loader={<GTLoader width="100px" height="100px"/>}
      visible={isFetchedAfterMount || isLoading}
    />
  ) : (
    <SysSectionForm
      onSubmit={handleSubmit}
      isLoading={updateIsLoading}
      defaultValues={{
        name: data?.name ?? "",
        sysBlockId: data?.sysblockId?.toString() ?? "",
      }}
    />
  );
};
