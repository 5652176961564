import { DisplayTable } from "#/components/index.js";
import { trpc } from "#/trpc.js";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  Button,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "#/components-ng/index.js";
import {
  MdOutlineEmail,
  MdExpandMore,
  MdOutlineLocalOffer,
  MdOutlinePhone,
} from "react-icons/md/index.js";

type CustomerPanelProps = {
  customerId?: number | null;
};

export function CustomerPanel(props: CustomerPanelProps) {
  const userQuery = trpc.user.getOneById.useQuery(
    {
      id: props.customerId ?? 0,
    },
    {
      enabled: props.customerId != null,
    }
  );
  const customer = userQuery.data;

  return (
    <div className="rounded bg-white px-5 py-4">
      {customer ? (
        <div className="flex w-full gap-x-4">
          <Avatar>
            <AvatarImage src={customer.avatar ?? undefined} />
            <AvatarFallback>
              {customer.firstName[0]} {customer.lastName?.[0] ?? ""}
            </AvatarFallback>
          </Avatar>
          <div className="grid w-full">
            <div className="flex">
              <p className="font-bold">{`${customer.firstName} ${
                customer.lastName ?? ""
              }`}</p>
            </div>
            <div className="flex w-full items-end justify-between gap-x-5">
              <div className="flex flex-wrap gap-x-4 gap-y-3">
                <div className="flex items-center gap-x-1 text-base text-slate-500">
                  <MdOutlineEmail fontSize="small" />
                  <p className="text-sm">{customer.email}</p>
                </div>
                <div className="flex items-center gap-x-1 text-base text-slate-500">
                  <MdOutlinePhone fontSize="small" />
                  <p className="text-sm">{`${customer.areaCode} ${customer.phoneNumber}`}</p>
                </div>
                <div className="flex items-center gap-x-1 text-base text-slate-500">
                  <MdOutlineLocalOffer fontSize="small" />
                  <p className="text-sm">{"Discounts"}</p>
                  <CustomerDiscountsModal customerId={props.customerId} />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="grid place-items-center">
          <p className="text-lg font-bold">You must select a customer</p>
        </div>
      )}
    </div>
  );
}

type CustomerDiscountsModalProps = {
  customerId?: number | null;
};

function CustomerDiscountsModal(props: CustomerDiscountsModalProps) {
  const userDiscountsQuery = trpc.userDiscount.getManyByUserId.useQuery(
    {
      userId: Number(props.customerId),
    },
    {
      enabled: props.customerId != null,
    }
  );
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="subtle" size="sm" className="h-auto w-auto p-1">
          <MdExpandMore />
        </Button>
      </DialogTrigger>
      <DialogContent className="max-w-[min(800px,95%)]">
        <DialogHeader>
          <DialogTitle>Customer discounts</DialogTitle>
        </DialogHeader>
        <DisplayTable
          columns={discountColumns}
          data={userDiscountsQuery?.data ?? []}
        />
      </DialogContent>
    </Dialog>
  );
}

const discountColumns = [
  {
    Header: "Name",
    accessor: "discount.reference",
  },
  {
    Header: "Department",
    accessor: "department.name",
  },
  {
    Header: "Amount",
    accessor: "discount.amount",
    isNumeric: true,
    Cell: ({ value, row: { original } }) => (
      <p>
        <span>{original.discount.type === "AMOUNT" && "$"}</span>
        <span>{value.toNumber()}</span>
        <span>{original.discount.type === "PERCENTAGE" && "%"}</span>
      </p>
    ),
  },
];
