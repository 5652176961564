import dayjs from "dayjs";
import { Change } from "./change";

export class Changelog {
  date: Date;
  changes: Array<Change>;

  constructor({ date, changes }: { date: Date; changes: Array<Change> }) {
    this.date = date;
    this.changes = changes;
  }

  /**
   * Returns new changes for the module or any of its children.
   */
  getNewChanges(
    module: string,
    opts?: { onlyDisplayable: boolean },
  ): Array<Change> {
    if (
      module === "changelog" &&
      // today is between the start of the day of the changelog and the day after
      dayjs().isBetween(
        dayjs(this.date).startOf("day"),
        dayjs(this.date).add(1, "day").endOf("day"),
      )
    ) {
      return [
        {
          display: true,
          type: "feature",
          module: "changelog",
        },
      ];
    }

    const newChanges: Array<Change> = [];

    const onlyDisplayable = opts?.onlyDisplayable ?? true;

    // Given a module like "sales.makeASale", we count the number of components
    // that make this module, in this case 2.
    const moduleComponentsLength = module.split(".").length;

    for (const change of this.changes) {
      if (onlyDisplayable && !change.display) {
        continue;
      }

      // We get the prefix of the change module that has the same number of
      // components as the module we are checking and convert it again to a
      // string.
      const changeModulePrefix = change.module
        .split(".")
        .slice(0, moduleComponentsLength)
        .join(".");

      if (module === changeModulePrefix) {
        newChanges.push(change);
      }
    }

    return newChanges;
  }
}
