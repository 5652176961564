import { useAuth } from "#/context/AuthContext.js";
import logoUrl from "#/images/colored-logo-invoice.png";
import * as M from "@mantine/core";
import { sortBy } from "lodash";
import React from "react";

export const PrintPdfInventory = ({ type, name, items }) => {
  const [auth] = useAuth();

  const sortedProducts = React.useMemo(
    () => sortBy(items, (p) => -(p.defaultImage?.length ?? 0)),
    [items]
  );

  return (
    <M.Stack p="xl">
      <M.Group position="apart" align="start">
        <M.Stack>
          <M.Stack spacing={0}>
            <M.Text weight="bold" size={24} sx={{ color: "#82631A" }}>
              Gold Tree
            </M.Text>
            <M.Text weight={500}>
              {type}: {name}
            </M.Text>
          </M.Stack>
          <M.Text color="gray.7" mt="xl">
            Items associated with this {type.toLowerCase()}
          </M.Text>
        </M.Stack>
        <M.Image src={logoUrl} width={140} />
      </M.Group>
      <M.Divider color="gray.2" />
      <M.Group position="apart" align="start">
        <M.Table
          sx={{
            "& > thead > tr > th": {
              color: "#82631A",
            },
            "& > tbody > tr:nth-child(even) > td": {
              backgroundColor: "#F8F8FC",
            },
            "& > thead > tr > th, & > tbody > tr > td": {
              border: "none",
              padding: "0.5em 1.5em",
              fontSize: 12,
            },
          }}
        >
          <thead>
            <tr>
              <th>Image</th>
              <th>Sku</th>
              <th>Product</th>
              <th>Price</th>
              <th>Stock</th>
            </tr>
          </thead>
          <tbody>
            {sortedProducts?.map((item) =>
              item.itemSku.map((itemSku) => (
                <tr key={itemSku.id}>
                  <td>
                    <M.Image
                      src={itemSku?.defaultImage}
                      width={80}
                      withPlaceholder
                    />
                  </td>
                  <td>{itemSku.sku}</td>
                  <td>{itemSku.title}</td>
                  <td>{`$${itemSku.price.toNumber()}`}</td>
                  <td>
                    {itemSku.itemSkuStock?.find(
                      (stock) => stock.filialId === auth?.auth?.user.filialId
                    )?.quantity ?? 0}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </M.Table>
      </M.Group>
    </M.Stack>
  );
};
