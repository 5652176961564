import { useAuth } from "#/context/AuthContext.js";
import { styled, HStack } from "#/css/jsx";
import { RouterOutputs, trpc } from "#/trpc.js";
import { reportUserError, reportUserSuccess } from "#/util/index.js";
import * as M from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { openConfirmModal } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import dayjs from "dayjs";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_FilterOption,
  MRT_PaginationState,
  MRT_SortingState,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useRef } from "react";
import { Link } from "react-router-dom";
import EditIcon from "~icons/ion/create-outline";
import MenuIcon from "~icons/ion/ellipsis-horizontal-outline";
import DeleteIcon from "~icons/ion/trash-outline";
import { DropdownMenu, IconButton, Tooltip } from "@radix-ui/themes";
import { Button, Dropdown } from "@gt/ui";
import { css } from "#/css/css";
import { SearchButton } from "#/components@v2_5/search-button";
import { MdOutlineDownload } from "react-icons/md";

export const Milestones = () => {
  const [{ auth }] = useAuth();
  const [dateRange, setDateRange] = React.useState<[Date | null, Date | null]>([
    dayjs().subtract(1, "M").toDate(),
    new Date(),
  ]);

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });

  // Sorting
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const querySorting = useMemo(() => {
    if (sorting[0]) {
      return {
        desc: sorting[0].desc,
        key: sorting[0].id as any,
      };
    }
    return null;
  }, [sorting]);

  const [globalFilter, setGlobalFilter] = useState<
    MRT_FilterOption | undefined
  >();

  const { data } = trpc.v2_5.milestone.getMilestonesByPage.useQuery(
    {
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize,
      sorting: querySorting,
      search: globalFilter,
      filters: {
        filialId: auth?.user?.filialId ?? null,
        startDate: dateRange[0]!,
        endDate: dateRange[1]!,
      },
    },
    {
      keepPreviousData: true,
      cacheTime: 0,
      enabled: !!dateRange[0] && !!dateRange[1],
      onError: (err) => {
        reportUserError({
          title: "Failed to load milestones",
          message: err.message,
        });
      },
    },
  );

  const { mutate: exportCsv, isLoading: isLoadingExport } =
    trpc.v2_5.milestone.exportMilestonesTableToCsv.useMutation({
      onSuccess: () => {
        reportUserSuccess({
          title: "The csv file has been sent to email",
        });
      },
    });

  const handleExportCsv = () => {
    exportCsv({
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize,
      sorting: querySorting,
      search: globalFilter,
      filters: {
        filialId: auth?.user?.filialId ?? null,
        startDate: dateRange[0]!,
        endDate: dateRange[1]!,
      },
    });
  };

  const table = useMantineReactTable({
    columns: columns(),
    data: data?.entries ?? [],
    rowCount: data?.totalEntries ?? 0,
    manualPagination: true,
    manualSorting: true,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    onGlobalFilterChange: setGlobalFilter,
    state: {
      pagination,
      sorting,
    },
    enableTopToolbar: false,
    enableStickyHeader: true,
    renderBottomToolbarCustomActions: () => (
      <div
        className={css({
          display: "flex",
          alignItems: "center",
          gap: 2,
        })}
      >
        <Tooltip content="Export to CSV">
          <IconButton
            color="gray"
            variant="soft"
            loading={isLoadingExport}
            onClick={handleExportCsv}
          >
            <MdOutlineDownload />
          </IconButton>
        </Tooltip>
      </div>
    ),
  });

  return (
    <>
      <HStack justify="space-between" gap={3}>
        <DropdownButton />
        <HStack gap={4}>
          <DatePickerInput
            type="range"
            popoverProps={{
              zIndex: 9997,
            }}
            onChange={setDateRange}
            value={dateRange}
          />
          <SearchButton setQuery={setGlobalFilter} />
        </HStack>
      </HStack>
      <div
        className={css({
          marginTop: 5,
        })}
      >
        <MantineReactTable table={table} />
      </div>
    </>
  );
};
type Milestone =
  RouterOutputs["v2_5"]["milestone"]["getMilestonesByPage"]["entries"][number];

const columns = (): MRT_ColumnDef<Milestone>[] => [
  {
    id: "id",
    enableSorting: false,
    enableColumnFilter: false,
    header: "Actions",
    enableColumnActions: false,
    size: 100,

    Cell: ({ row: { original } }) => {
      const [{ auth }] = useAuth();
      const ctx = trpc.useContext();
      const pin = useRef("adminPin");

      const { mutate: deleteMilestone } = trpc.milestone.delete.useMutation({
        onSuccess: () => {
          ctx.milestone.invalidate();
          showNotification({
            title: "Milestone deleted",
            message: "Milestone deleted successfully",
            color: "green",
          });
        },
      });

      const handleDelete = () => {
        openConfirmModal({
          title: "Are you sure you want to delete this milestone?",
          children: (
            <M.PasswordInput
              label="Pin"
              onChange={(e) => (pin.current = e.currentTarget.value)}
            />
          ),
          labels: {
            confirm: "Delete",
            cancel: "Cancel",
          },
          confirmProps: { color: "red" },
          onConfirm: () => {
            deleteMilestone({
              id: Number(original.id),
              adminPin: pin.current,
            });
          },
        });
      };

      return (
        <M.Menu withinPortal>
          <M.Menu.Target>
            <M.Box>
              <M.Tooltip
                label="You can only edit current milestones"
                zIndex={1500}
              >
                <M.ActionIcon>
                  <MenuIcon />
                </M.ActionIcon>
              </M.Tooltip>
            </M.Box>
          </M.Menu.Target>
          <M.Menu.Dropdown>
            <M.Menu.Item
              component={Link}
              to={`edit/${original.id}`}
              icon={<EditIcon />}
            >
              Edit
            </M.Menu.Item>
            {auth?.user?.filialId && (
              <M.Menu.Item icon={<DeleteIcon />} onClick={handleDelete}>
                Delete
              </M.Menu.Item>
            )}
          </M.Menu.Dropdown>
        </M.Menu>
      );
    },
  },
  {
    header: "From",
    accessorKey: "activeFrom",
    enableSorting: true,
    enableColumnFilter: false,
    Cell: ({ row: { original } }) => {
      return (
        <M.Box>
          <styled.p lineClamp={2} overflow={"hidden"}>
            {dayjs(original.activeFrom).format("MM/DD/YYYY")}
          </styled.p>
        </M.Box>
      );
    },
  },
  {
    header: "To",
    accessorKey: "activeTo",
    enableSorting: true,
    enableColumnFilter: false,
    Cell: ({ row: { original } }) => {
      return (
        <M.Box>
          <styled.p lineClamp={2} overflow={"hidden"}>
            {dayjs(original.activeTo).format("MM/DD/YYYY")}
          </styled.p>
        </M.Box>
      );
    },
  },

  {
    header: "Title",
    accessorKey: "title",
    enableSorting: true,
    enableColumnFilter: false,
    Cell: ({ row: { original } }) => {
      return (
        <M.Box>
          <styled.p lineClamp={2} overflow={"hidden"}>
            {original.title}
          </styled.p>
        </M.Box>
      );
    },
  },
  {
    header: "Description",
    accessorKey: "description",

    enableColumnFilter: false,
    Cell: ({ row: { original } }) => {
      return (
        <M.Box>
          <M.Text>{original.description}</M.Text>
        </M.Box>
      );
    },
  },
  {
    header: "Goal",
    accessorKey: "goal",
    enableSorting: true,
    enableColumnFilter: false,
    Cell: ({ row: { original } }) => {
      return (
        <M.Box>
          <styled.p lineClamp={2} overflow={"hidden"}>
            ${original.milestoneValue.toNumber().toFixed(2)}
          </styled.p>
        </M.Box>
      );
    },
  },
  {
    header: "Type",
    enableSorting: true,
    accessorKey: "milestoneType",
    enableColumnFilter: false,
    Cell: ({ row: { original } }) => {
      return (
        <M.Box>
          <M.Text>{original.milestoneType}</M.Text>
        </M.Box>
      );
    },
  },
];

const DropdownButton = () => {
  return (
    <Dropdown.Root>
      <Dropdown.Trigger>
        <Button
          className={css({
            gap: 4,
          })}
        >
          I want to
          <DropdownMenu.TriggerIcon />
        </Button>
      </Dropdown.Trigger>
      <Dropdown.Content>
        <Dropdown.Item>
          <Link to="create">Create</Link>
        </Dropdown.Item>
      </Dropdown.Content>
    </Dropdown.Root>
  );
};
