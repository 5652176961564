import GTLoader from '#/components-ng/loader.js'
import { trpc } from '#/trpc.js'
import { reportUserError, reportUserSuccess } from '#/util/index.js'
import CategoryForm from './Form.js'
import * as M from '@mantine/core'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'

export default function UpdateCategory() {
  const params = useParams() as any
  const id = Number.parseInt(params.id)
  const navigate = useNavigate()
  const ctx = trpc.useContext()

  const { data, isLoading } = trpc.category.get.useQuery(
    { id },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchInterval: false,
      onError(error) {
        reportUserError({
          title: 'Failed to get category',
          message: error.message,
        })
      },
    },
  )

  const { mutate } = trpc.category.update.useMutation({
    onSuccess() {
      ctx.category.invalidate()
      ctx.v2_5.categories.invalidate()
      reportUserSuccess({
        title: 'Category updated successfully',
      })
      navigate('/inventory/categories')
    },
    onError(error) {
      reportUserError({
        title: 'Failed to create category',
        message: error.message,
      })
    },
  })

  const handleSubmit = React.useCallback(
    (c) =>
      mutate({
        id,
        input: {
          name: c.name,
          description: c.description,
          defaultImage: c.image,
          active: c.active,
          top: c.top,
          sizeGuideImage: c.sizeGuideImage,
          metaDescription: c?.metaDescription ?? null,
          type: c.type,
        },
      }),
    [id, mutate],
  )

  console.log(data?.type)

  return !isLoading ? (
    <CategoryForm
      defaultValues={{
        name: data!.name,
        description: data?.description ?? '',
        image: data?.defaultImage,
        active: data!.active,
        top: data?.top ?? false,
        sizeGuideImage: data?.sizeGuideImage,
        metaDescription: data?.metaDescription ?? '',
        type: data?.type,
      }}
      onSubmit={handleSubmit}
      isLoading={isLoading}
    />
  ) : (
    <M.LoadingOverlay
      visible={isLoading}
      loader={<GTLoader width={100} height={100} />}
    />
  )
}
