import { BadgeDetails, SearchableInput, WidgetContainer } from "./common.js";
import { endOfDayAtom } from "./state.js";
import * as M from "@mantine/core";
import Decimal from "decimal.js";
import { useAtomValue } from "jotai";
import {
  MRT_ColumnDef,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import { MdFilterAlt } from "react-icons/md";
import { CacheEndOfDay, OrderType } from "server";

const orderTypes: OrderType[] = ["IN_STORE", "ONLINE", "PICKUP"];

interface FilterOrderTypes {
  IN_STORE: boolean;
  ONLINE: boolean;
  PICKUP: boolean;
}

interface FilterBadgeTotal {
  orderCount: number;
  totalOrderAmount: Decimal;
  totalTaxAmount: Decimal;
  totalReturnedAmount: Decimal;
  totalActivityAmount: Decimal;
  totalShipping: Decimal;
}

export const OrderHistory = () => {
  const endOfDay = useAtomValue(endOfDayAtom);
  const [query, setQuery] = useState("");

  const [filterOrderTypes, setFilterOrderTypes] = useState<FilterOrderTypes>({
    IN_STORE: true,
    ONLINE: true,
    PICKUP: true,
  });

  const [totalBadge, setTotalBadge] = useState<FilterBadgeTotal>(() =>
    getTotalBadges(filterOrderTypes, endOfDay.salesActivityByOrderType)
  );

  const orderData = useMemo(() => {
    return (
      endOfDay.ordersHistory.orders
        .filter(
          (o) =>
            filterOrderTypes[o.orderType as keyof FilterOrderTypes] === true
        )
        .filter((o) => {
          if (!query) return true;

          const customer = o.customerFullName?.toLowerCase();
          const associated = o.associatedFullName?.toLowerCase();
          const guide = o.receiptNumber?.toString();
          const queryLower = query.toLowerCase();

          return (
            customer?.includes(queryLower) ||
            associated?.includes(queryLower) ||
            guide?.includes(queryLower)
          );
        }) ?? []
    );
  }, [endOfDay.ordersHistory.orders, query, filterOrderTypes]);

  const table = useMantineReactTable({
    data: orderData,
    columns: columns,
    initialState: { density: "xs" },
    enableColumnFilters: false,
    enableTopToolbar: false,
    enableFilters: false,
  });

  useEffect(() => {
    setTotalBadge(
      getTotalBadges(filterOrderTypes, endOfDay.salesActivityByOrderType)
    );
  }, [endOfDay.salesActivityByOrderType, filterOrderTypes]);

  return (
    <WidgetContainer>
      <M.Stack>
        <M.Title order={3} mb={10} color="#3D3D3D">
          Order history
        </M.Title>
        <M.Group position="left" mb={20}>
          <BadgeDetails
            title="Count"
            total={totalBadge.orderCount}
            isMoney={false}
          />
          <BadgeDetails
            title="Total order"
            total={totalBadge.totalOrderAmount.toNumber()}
            isMoney={true}
          />
          <BadgeDetails
            title="Total tax"
            total={totalBadge.totalTaxAmount.toNumber()}
            isMoney={true}
          />
          <BadgeDetails
            title="Total returns"
            total={totalBadge.totalReturnedAmount.toNumber()}
            isMoney={true}
          />
          <BadgeDetails
            title="Total activity"
            total={totalBadge.totalActivityAmount.toNumber()}
            isMoney={true}
          />
          <BadgeDetails
            title="Total shipping"
            total={totalBadge.totalShipping.toNumber()}
            isMoney={true}
          />
        </M.Group>
        <M.Divider />
        <M.Group position="apart" mb={20}>
          <SearchableInput
            placeholder="Search by customer or guide"
            onInput={(e) => setQuery(e.currentTarget.value)}
          />
          <M.Popover zIndex={9000}>
            <M.Popover.Target>
              <M.Button
                variant="light"
                color="dark"
                bg="gray.2"
                leftIcon={<MdFilterAlt />}
              >
                Filter by order type
              </M.Button>
            </M.Popover.Target>
            <M.Popover.Dropdown>
              <M.Stack>
                {orderTypes.map((type) => (
                  <M.Checkbox
                    key={type}
                    label={type}
                    checked={filterOrderTypes[type]}
                    onChange={(event) =>
                      setFilterOrderTypes({
                        ...filterOrderTypes,
                        [type]: event.currentTarget.checked,
                      })
                    }
                  />
                ))}
              </M.Stack>
            </M.Popover.Dropdown>
          </M.Popover>
        </M.Group>
        <MantineReactTable table={table} />
      </M.Stack>
      <M.LoadingOverlay visible={endOfDay.loading} zIndex={9999} />
    </WidgetContainer>
  );
};

const columns: Array<
  MRT_ColumnDef<{
    id: number;
    customerFullName?: string | null;
    associatedFullName?: string | null;
    orderType: OrderType;
    receiptNumber?: number | null;
    total: Decimal;
  }>
> = [
  {
    accessorKey: "customerFullName",
    header: "Customer",
  },
  {
    accessorKey: "associatedFullName",
    header: "Associated",
  },
  {
    accessorKey: "orderType",
    header: "Order type",
  },
  {
    accessorKey: "receiptNumber",
    header: "Guide",
  },
  {
    id: "total",
    header: "Total",
    Cell: (table) => {
      const total = table.row.original.total;
      if (!total) return "$0.00";
      return `$${total.toNumber().toFixed(2)}`;
    },
  },
];

const getTotalBadges = (
  filterOrderTypes: FilterOrderTypes,
  salesActivityByOrderType: CacheEndOfDay["salesActivityByOrderType"]
) => {
  return salesActivityByOrderType
    .filter(
      (orderType) =>
        filterOrderTypes[orderType.orderType as keyof FilterOrderTypes] === true
    )
    .reduce(
      (acc, curr) => {
        acc.orderCount += curr.orderCount;
        acc.totalOrderAmount = acc.totalOrderAmount.plus(curr.totalOrderAmount);
        acc.totalTaxAmount = acc.totalTaxAmount.plus(curr.totalTaxAmount);
        acc.totalReturnedAmount = acc.totalReturnedAmount.plus(
          curr.totalReturnedAmount
        );
        acc.totalActivityAmount = acc.totalActivityAmount.plus(
          curr.totalActivityAmount
        );
        acc.totalShipping = acc.totalShipping.plus(curr.totalShipping);
        return acc;
      },
      {
        orderCount: 0,
        totalOrderAmount: new Decimal(0),
        totalTaxAmount: new Decimal(0),
        totalReturnedAmount: new Decimal(0),
        totalActivityAmount: new Decimal(0),
        totalShipping: new Decimal(0),
      }
    );
};
