// @ts-nocheck

import { routes } from "../../scenes/routes.js";
import Text from "../Text/index.jsx";
import StyledSceneLayout, {
  BreadcrumbsWrapper,
  LinkWrapper,
} from "./Styles.js";
import { Link } from "react-router-dom";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { styled } from "#/css/jsx"

const SceneLayout = ({ icon, children }) => {
  let breadcrumbs = useBreadcrumbs(routes).filter(
    // Remove breadcrumbs with invalid paths
    (b) => b.match.route.path !== "*"
  );

  let activeIcon = false;
  if (breadcrumbs.length <= 2) {
    activeIcon = true;
  }

  // Keep `Dashboard` out of the breadcrumbs. Only show it when it's by
  // itself.
  if (breadcrumbs.length >= 2) {
    breadcrumbs = breadcrumbs.slice(1);
  }

  return (
    <StyledSceneLayout activeIcon={activeIcon}>
      <styled.div 
        className="title" 
        display={{
          base: "none !important",
          lg: "flex !important",
        }}
        alignItems="center"
      >
        {icon}
        <BreadcrumbsWrapper>
          {breadcrumbs.map(({ breadcrumb, match }) => (
            <LinkWrapper key={match.pathname}>
              <Link to={match.pathname}>
                <Text text={breadcrumb} color="secondaryFont" />
              </Link>
            </LinkWrapper>
          ))}
        </BreadcrumbsWrapper>
      </styled.div>
      <div
        className={
          "flex-1 border-l border-t border-slate-200 bg-slate-50/50 lg:pb-5 lg:pl-[58px] lg:pr-[60px] lg:pt-6 px-[16px] pt-[8px] pb-[60px]"
        }
      >
        {children}
      </div>
    </StyledSceneLayout>
  );
};

export default SceneLayout;
