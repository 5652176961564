import { makeController } from "#/components-ng"
import { trpc } from "#/trpc"
import { reportUserError, reportUserSuccess } from "#/util"
import { Order } from "../use-order"
import { Button, Checkbox, Field, Form, Select, Table } from "@gt/ui"
import { zodResolver } from "@hookform/resolvers/zod"
import * as M from "@mantine/core"
import {
	createColumnHelper,
	flexRender,
	getCoreRowModel,
	useReactTable,
} from "@tanstack/react-table"
import { ShippingStatus } from "server"
import { z } from "zod"
import { styled } from "#/css/jsx"
import ClipboardIcon from "~icons/ion/clipboard-outline"
import CheckIcon from "~icons/ic/baseline-check"

const selectedOrderItemSkusAtom = atom<number[]>([])

interface PackageDetailsProps {
	package_?: Order["packages"][number] | null
	setOpened: (value: boolean) => void
	orderItemSkus?: Order["orderItemSku"] | null
}

const ShippingStatuses = ["PROCESSING", "SHIPPED", "DELIVERED"] as const

const PackageFormSchema = z.object({
	shippingStatus: z.enum(ShippingStatuses),
})
type PackageFormSchema = z.infer<typeof PackageFormSchema>
const { useForm: usePackageForm } = makeController<PackageFormSchema>()

const PackageItemsSchema = z.object({
	items: z.array(z.number()),
})
type PackageItemsSchema = z.infer<typeof PackageItemsSchema>
const { useForm: usePackageItemsForm } = makeController<PackageItemsSchema>()

export const PackageDetails = ({
	package_,
	setOpened,
	orderItemSkus,
}: PackageDetailsProps) => {
	const [openPackage, setOpenPackage] = useState<boolean>(false)
	const [selectedItemSkus, setSelectedItemSkus] = useAtom(
		selectedOrderItemSkusAtom,
	)

	const ctx = trpc.useContext()
	const { mutate: updatePackageStatus, isLoading } =
		trpc.v2_5.package.updateStatus.useMutation({
			onSuccess: () => {
				setOpened(false)
				ctx.order.pos.ecommerceOrders.invalidate()
				reportUserSuccess({
					title: "Status updated",
				})
			},
		})

	const form = usePackageForm({
		values: {
			shippingStatus: package_?.shippingStatus
				? package_?.shippingStatus === "SHIPPING"
					? "SHIPPED"
					: package_?.shippingStatus
				: "PROCESSING",
		},
		resolver: zodResolver(PackageFormSchema),
		resetOptions: {
			keepDirtyValues: true,
		},
	})

	const handleSubmit = (values: PackageFormSchema) => {
		if (!package_) {
			reportUserError({
				title: "There is no package to update",
			})
			return
		}

		updatePackageStatus({
			packageId: package_?.id ?? 0,
			shippingStatus:
				values.shippingStatus === "SHIPPED"
					? "SHIPPING"
					: values.shippingStatus,
		})
	}

	const { mutate: updatePackageItems, isLoading: isLoadingUpdatePackageItems } =
		trpc.v2_5.package.items.update.useMutation({
			onSuccess: () => {
				setOpened(false)
				ctx.order.pos.ecommerceOrders.invalidate()
				reportUserSuccess({
					title: "Items updated",
				})
			},
		})

	const itemsForm = usePackageItemsForm({
		values: {
			items: selectedItemSkus,
		},
		resolver: zodResolver(PackageItemsSchema),
		resetOptions: {
			keepDirtyValues: true,
		},
	})

	const handleItemsSubmit = () => {
		if (!package_) {
			reportUserError({
				title: "There is no package to update",
			})
			return
		}

		if (selectedItemSkus.length === 0) {
			reportUserError({
				title: "You must select at least one item",
			})
			return
		}

		updatePackageItems({
			packageId: package_?.id ?? 0,
			items: selectedItemSkus,
		})
	}

	const items = useMemo(() => {
		if (!package_) return []

		return package_?.shippingStatus === "PROCESSING"
			? package_?.items
					.map((item) => item.orderItemSku)
					?.concat(
						orderItemSkus?.filter((item) => item.packageItem?.length === 0) ??
							[],
					) ?? []
			: package_?.items.map((item) => item.orderItemSku)
	}, [package_])

	const table = useReactTable({
		columns: columns(package_?.shippingStatus ?? "PROCESSING"),
		data: items,
		getCoreRowModel: getCoreRowModel(),
	})

	return (
		<M.Accordion.Item value={package_?.shippingNumber ?? ""}>
			<M.Accordion.Control
				onClick={() => {
					if (openPackage) {
						setOpenPackage(false)
						setSelectedItemSkus([])
					} else {
						setOpenPackage(true)
						setSelectedItemSkus(
							package_?.items.map((item) => item.orderItemSku.id) ?? [],
						)
					}
				}}
			>
				<M.Group spacing="md" position="apart">
					<M.Text>
						<M.Text size="sm" weight={500}>
							{package_?.shippingNumber}
						</M.Text>
						<M.Text size="sm" color="gray">
							{package_?.shippingCompany}
						</M.Text>
					</M.Text>
					<M.Text size="sm" weight={500}>
						Status:{" "}
						{package_?.shippingStatus === "SHIPPING"
							? "SHIPPED"
							: package_?.shippingStatus}
					</M.Text>
				</M.Group>
			</M.Accordion.Control>
			<M.Accordion.Panel>
				<M.Stack spacing="md">
					{package_?.shippingStatus !== "DELIVERED" && (
						<Form.Root {...form}>
							<form onSubmit={form.handleSubmit(handleSubmit)}>
								<M.Group align="end" position="apart" spacing={10}>
									<styled.div
										display="flex"
										justifyContent="space-between"
										alignItems="flex-end"
										gap={2}
									>
										<Form.Field
											control={form.control}
											name="shippingStatus"
											render={({ field }) => (
												<Field.Root>
													<Field.Label>Status</Field.Label>
													<Select.Root
														onValueChange={field.onChange}
														value={field.value}
													>
														<Field.Control>
															<Select.Trigger>{field.value}</Select.Trigger>
														</Field.Control>
														<Select.Content>
															{ShippingStatuses.map((status) => (
																<Select.Item key={status} value={status}>
																	{status}
																</Select.Item>
															))}
														</Select.Content>
													</Select.Root>
													<Field.Message />
												</Field.Root>
											)}
										/>
										<Button type="submit" isLoading={isLoading}>
											Save Status
										</Button>
									</styled.div>
									<M.CopyButton
										value={package_?.shippingNumber ?? ""}
										timeout={2000}
									>
										{({ copied, copy }) => (
											<M.Tooltip
												label={copied ? "Copied" : "Copy"}
												withArrow
												position="right"
												withinPortal
											>
												<Button onClick={copy} variant="secondary">
													<styled.div
														display="flex"
														justifyContent="center"
														alignItems="center"
														gap={2}
													>
														Copy tracking number
														{copied ? <CheckIcon /> : <ClipboardIcon />}
													</styled.div>
												</Button>
											</M.Tooltip>
										)}
									</M.CopyButton>
								</M.Group>
							</form>
						</Form.Root>
					)}

					<M.Box pos="relative" className="overflow-auto">
						<Table.Root>
							<Table.Header>
								{table.getHeaderGroups().map((headerGroup) => (
									<Table.Row key={headerGroup.id}>
										{headerGroup.headers.map((header) => (
											<Table.Head
												key={header.id}
												className="sticky top-0 bg-white"
											>
												{header.isPlaceholder
													? null
													: flexRender(
															header.column.columnDef.header,
															header.getContext(),
														)}
											</Table.Head>
										))}
									</Table.Row>
								))}
							</Table.Header>
							<Table.Body>
								{table.getRowModel().rows.map((row) => (
									<Table.Row key={row.id}>
										{row.getVisibleCells().map((cell) => (
											<Table.Cell key={cell.id}>
												{flexRender(
													cell.column.columnDef.cell,
													cell.getContext(),
												)}
											</Table.Cell>
										))}
									</Table.Row>
								))}
							</Table.Body>
						</Table.Root>
					</M.Box>
					{package_?.shippingStatus === "PROCESSING" && (
						<Form.Root {...itemsForm}>
							<form onSubmit={form.handleSubmit(handleItemsSubmit)}>
								<M.Group spacing="md" position="right">
									<Button type="submit" isLoading={isLoadingUpdatePackageItems}>
										Save Items
									</Button>
								</M.Group>
							</form>
						</Form.Root>
					)}
				</M.Stack>
			</M.Accordion.Panel>
		</M.Accordion.Item>
	)
}

type OrderItemSku = Order["packages"][number]["items"][number]["orderItemSku"]

const column = createColumnHelper<OrderItemSku>()

const columns = (packageStatus: ShippingStatus) => [
	column.display({
		id: "Actions",
		cell: function Cell(table) {
			const [selectedItemSkus, setSelectedItemSkus] = useAtom(
				selectedOrderItemSkusAtom,
			)

			const isActive = selectedItemSkus.includes(table.row.original.id)

			function changeActiveState(active: boolean) {
				if (active) {
					setSelectedItemSkus([...selectedItemSkus, table.row.original.id])
				} else {
					setSelectedItemSkus(
						selectedItemSkus.filter((id) => id !== table.row.original.id),
					)
				}
			}

			return (
				<Checkbox
					checked={isActive}
					onCheckedChange={(v) => {
						if (v === "indeterminate") return
						changeActiveState(v)
					}}
					disabled={
						packageStatus === "DELIVERED" || packageStatus === "SHIPPING"
					}
				/>
			)
		},
	}),
	column.accessor("itemSku.sku", {
		header: "SKU",
	}),
	column.accessor("itemSku.title", {
		header: "Title",
	}),
	column.accessor("quantity", {
		header: "Qty",
	}),
]
