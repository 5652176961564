import { makeController } from "#/components-ng/index.js";
import { z } from "zod";

export const createQuizSchema = z.object({
  title: z
    .string({
      required_error: "Name is required",
    })
    .min(1),
  titleEsp: z.string().nullish(),
  description: z.string().min(1),
  descriptionEsp: z.string().nullish(),
  finishedAt: z.date(),
  needAuth: z.boolean().default(false),
  publishedAt: z.date(),
  defaultImage: z.string().nullish(),
});

export type FormValues = z.infer<typeof createQuizSchema>;
export const C = makeController<FormValues>();

export const quizValueTypes = [
  "BLOG",
  "CATALOG",
  "PROMOTION",
  "DEPARTMENT",
  "CATEGORY",
  "SEASON",
] as const;

export const createValueSchema = z.object({
  title: z.string().min(1),
  titleEsp: z.string().nullish(),
  description: z.string().min(1),
  descriptionEsp: z.string().nullish(),
  type: z.enum(quizValueTypes),
  blogId: z.string().nullish(),
  catalogId: z.string().nullish(),
  promotionId: z.string().nullish(),
  departmentId: z.string().nullish(),
  categoryId: z.string().nullish(),
  seasonId: z.string().nullish(),
});

export type FormValueCreate = z.infer<typeof createValueSchema>;
export const CCreateValue = makeController<FormValueCreate>();

export const createQuestionSchema = z.object({
  question: z.string().min(1),
  questionEsp: z.string().nullish(),
});

export type FormQuestionCreate = z.infer<typeof createQuestionSchema>;
export const CCreateQuestion = makeController<FormQuestionCreate>();

export const createAnswerSchema = z.object({
  answer: z.string().min(1),
  answerEsp: z.string().nullish(),
  valueId: z.string().nullish(),
  score: z.string(),
});

export type FormAnswerCreate = z.infer<typeof createAnswerSchema>;
export const CCreateAnswer = makeController<FormAnswerCreate>();
