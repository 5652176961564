import { trpc } from "#/trpc.js";
import { reportUserError, reportUserSuccess } from "#/util/index.js";
import { FormValues, PromotionForm } from "./PromotionForm.js";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

export const CreatePromotion = () => {
  const ctx = trpc.useContext();
  const navigate = useNavigate();

  const { mutate, isLoading } = trpc.v2_5.promotion.create.useMutation({
    onSuccess: () => {
      ctx.v2_5.promotion.invalidate();
      reportUserSuccess({
        title: "Promotion created",
        message: "Promotion created successfully",
      });
      navigate("/inventory/promotions");
    },
    onError: (error) => {
      reportUserError({
        title: "Error creating promotion",
        message: error.message,
      });
    },
  });

  const handleSubmit = (values: FormValues) => {
    mutate({
      activeRange: [
        dayjs(values.activeRange![0]).toDate(),
        dayjs(values.activeRange![1]).toDate(),
      ],
      discountId: Number(values.discount),
      name: values.name,
      description: values.description,
      defaultImage: values?.defaultImage ? values.defaultImage : null,
      accentHexColor: values.accentHexColor,
      withTimer: values.withTimer,
    });
  };

  return <PromotionForm onSubmit={handleSubmit} loading={isLoading} />;
};
