import GTLoader from "#/components-ng/loader.js";
import { LOGO_URL } from "#/constants/envt.js";
import { trpc } from "#/trpc.js";
import { reportUserError } from "#/util/index.js";
import { applyDiscountV2 } from "../util";
import * as M from "@mantine/core";
import dayjs from "dayjs";
import Decimal from "decimal.js";
import React from "react";
import { useParams } from "react-router";

export const Invoice = ({ id: idProp = 0, isDrawer = false }) => {
  const [imageLoaded, setImageLoaded] = React.useState(false);
  const { id } = useParams();
  const idFilter = idProp === 0 ? Number(id) : idProp;
  const { data, isLoading } = trpc.order.getById.useQuery(
    {
      id: idFilter,
    },
    {
      onError(error) {
        reportUserError({
          title: "Failed to load order",
          message: error.message,
        });
      },
    },
  );

  React.useEffect(() => {
    if (data && imageLoaded && !isDrawer) {
      window.print();
    }
  }, [data, imageLoaded, isDrawer]);
  if (isLoading)
    return <M.LoadingOverlay loader={<GTLoader />} visible={isLoading} />;
  const order = data;

  const couponToSubtotal =
    order?.coupon != null &&
    order.coupon.discount.type === "ORDER_SUBTOTAL_PERCENTAGE"
      ? {
          amount: `${order.coupon.discount.amount.toFixed(2)}%`,
          appliedAmount: order?.subTotal
            ?.sub(applyDiscountV2(order.subTotal, order.coupon.discount))
            ?.toNumber(),
        }
      : null;

  const couponToShipping =
    order?.coupon != null &&
    order.coupon.discount.type === "SHIPPING_PERCENTAGE" &&
    order?.orderShipping?.shippingCost != null
      ? {
          previousShipping: order.orderShipping.shippingCost
            ?.div(new Decimal(1).sub(order.coupon.discount.amount.div(100)))
            .toNumber(),
          discountShipping: order.orderShipping.shippingCost
            ?.sub(
              order.orderShipping.shippingCost?.div(
                new Decimal(1).sub(order.coupon.discount.amount.div(100)),
              ),
            )
            .toNumber(),
        }
      : null;

  return (
    <M.Box bg={isDrawer ? "#fff" : ""} p={isDrawer ? "1.6rem" : 0}>
      <M.Group position="apart">
        <M.Group position="left" spacing="md" align="flex-start">
          <M.Image
            src={LOGO_URL}
            width={100}
            onLoad={() => setImageLoaded(true)}
          />
          <M.Stack spacing={0} align="flex-start">
            <M.Text weight={600} size={20} color="#836415">
              Gold Tree
            </M.Text>
            <M.Text weight={500} size={16} color="#1A1C21">
              www.goldtreemiami.com
            </M.Text>
            <M.Text size={16} color="#5E6470">
              +1 (786) 7177131
            </M.Text>
            <M.Text size={16} color="#5E6470">
              755 NW 72nd Ave Plaza 33
            </M.Text>
            <M.Text size={16} color="#5E6470">
              Miami, FL, 33126
            </M.Text>
          </M.Stack>
        </M.Group>
        <M.Stack spacing={0} align="flex-end">
          <M.Text size={16} color="#5E6470">
            Order number
          </M.Text>
          <M.Text weight={500} size={16} color="#1A1C21" mb={16}>
            #{order?.receiptNumber}
          </M.Text>
          <M.Text size={16} color="#5E6470">
            Sale date: {dayjs(order?.createdAt).format("MM/DD/YYYY")}
          </M.Text>
          <M.Text size={16} color="#5E6470">
            Printed date: {dayjs().format("MM/DD/YYYY")}
          </M.Text>
        </M.Stack>
      </M.Group>

      <M.Divider my={40} color="#D7DAE0" />

      <M.Stack spacing={0} align="flex-start" mb={40}>
        <M.Text size={16} color="#5E6470">
          Billed to
        </M.Text>
        <M.Text weight={500} size={16} color="#1A1C21">
          {order?.customer
            ? order?.receiptName
              ? order?.receiptName
              : `${order?.customer?.firstName} ${order?.customer?.lastName ?? ""}`
            : `${order?.userBasicInfo?.firstName} ${
                order?.userBasicInfo?.lastName ?? ""
              }`}
        </M.Text>
        <M.Text size={16} color="#5E6470">
          {order?.customer
            ? order?.customer?.email
            : order?.userBasicInfo?.email}
        </M.Text>
        <M.Text size={16} color="#5E6470">
          {order?.customer
            ? order?.customer?.phoneNumber
            : order?.userBasicInfo?.phoneNumber}
        </M.Text>
        <M.Text size={16} color="#5E6470">
          {order?.orderShipping?.addressLine1 ?? ""}
        </M.Text>
      </M.Stack>

      <M.Table mb={40}>
        <thead className="border !border-x-0 !border-y-[#D7DAE0] p-5">
          <tr>
            <th className="!p-4 !text-center !text-[11.2px] !font-semibold !text-[#5E6470]">
              IMAGE
            </th>
            <th className="!p-4 !text-center !text-[11.2px] !font-semibold !text-[#5E6470]">
              SKU
            </th>
            <th className="!p-4 !text-center !text-[11.2px] !font-semibold !text-[#5E6470]">
              ITEM DETAIL
            </th>
            <th className="!p-4 !text-center !text-[11.2px] !font-semibold !text-[#5E6470]">
              QTY
            </th>
            <th className="!p-4 !text-center !text-[11.2px] !font-semibold !text-[#5E6470]">
              REGULAR PRICE
            </th>
            <th className="!p-4 !text-center !text-[11.2px] !font-semibold !text-[#5E6470]">
              DISCOUNT
            </th>
            <th className="!p-4 !text-center !text-[11.2px] !font-semibold !text-[#5E6470]">
              PRICE WITH DISCOUNT
            </th>
            <th className="!p-4 !text-center !text-[11.2px] !font-semibold !text-[#5E6470]">
              AMOUNT
            </th>
          </tr>
        </thead>
        <tbody className="border !border-x-0 !border-y-[#D7DAE0]">
          {order?.orderItemSku?.map((p, i) => (
            <tr key={i}>
              <td className="!border-none !p-4 text-center !text-xs !text-[#1A1C21]">
                <img
                  src={p?.itemSku?.images[0] ?? LOGO_URL}
                  width={50}
                  height={50}
                />
              </td>
              <td className="!border-none !p-4 text-center !text-xs !text-[#1A1C21]">
                {p?.itemSku.sku}
              </td>
              <td className="!border-none !p-4 text-center !text-xs !text-[#1A1C21]">
                {p?.itemSku.title}
              </td>
              <td className="!border-none !p-4 text-center !text-xs !text-[#1A1C21]">
                {p?.quantity}
              </td>
              <td className="!border-none !p-4 text-center !text-xs !text-[#1A1C21]">
                ${p?.price?.toNumber()?.toFixed(2)}
              </td>
              <td className="!border-none !p-4 text-center !text-xs !text-[#1A1C21]">
                {`${
                  p?.discountAmount
                    ? p?.discountType === "PERCENTAGE"
                      ? `${p?.discountAmount?.toNumber()?.toFixed(2)}%`
                      : `$${p?.discountAmount?.toNumber()?.toFixed(2)}`
                    : ""
                }`}
              </td>
              <td className="!border-none !p-4 text-center !text-xs !text-[#1A1C21]">
                {`$${applyDiscountV2(
                  p?.price,
                  p?.discountAmount && p?.discountType
                    ? {
                        amount: p?.discountAmount,
                        type: p?.discountType,
                      }
                    : null
                )
                  ?.toNumber()
                  ?.toFixed(2)}`}
              </td>
              <td className="!border-none !p-4 text-center !text-xs !text-[#1A1C21]">
                ${p?.total.toFixed(2)}
              </td>
            </tr>
          ))}
        </tbody>
      </M.Table>

      <M.Box className="h-auto w-full rounded-md bg-[#F9F9F9]">
        <M.Group position="right" className="p-5">
          <M.Box className="w-1/4">
            <M.Text size={14} color="#5E6470">
              Subtotal
            </M.Text>
            {couponToSubtotal != null && (
              <M.Text size={14} color="#5E6470">
                Applied Coupon {couponToSubtotal.amount}
              </M.Text>
            )}
            <M.Text size={14} color="#5E6470">
              Discount
            </M.Text>
            <M.Text size={14} color="#5E6470">
              Tax
            </M.Text>
            {couponToShipping != null && (
              <>
                <M.Text size={14} color="#5E6470">
                  Shipping
                </M.Text>
                <M.Text size={14} color="#5E6470">
                  Discount Shipping
                </M.Text>
              </>
            )}
            <M.Text size={14} color="#5E6470">
              Total Shipping
            </M.Text>
            <M.Divider my={14} color="#D7DAE0" />
            <M.Text size={14} color="#5E6470" weight={600}>
              Total
            </M.Text>
          </M.Box>
          <M.Box className="text-right">
            <M.Text size={14} color="#5E6470">
              ${order?.subTotal?.toNumber().toFixed(2) ?? 0}
            </M.Text>
            {couponToSubtotal != null && (
              <M.Text size={14} color="#5E6470">
                ${couponToSubtotal?.appliedAmount?.toFixed(2) ?? 0}
              </M.Text>
            )}
            <M.Text size={14} color="#5E6470">
              {order?.discountReference
                ? order?.discountType === "PERCENTAGE"
                  ? `${order?.discountAmount}%`
                  : `$${order?.discountAmount?.toFixed(2)}`
                : "$0.00"}
            </M.Text>
            <M.Text size={14} color="#5E6470">
              {order?.taxable
                ? `$${order?.taxTotal?.toNumber()?.toFixed(2)}`
                : "$0.00"}{" "}
            </M.Text>
            {couponToShipping != null && (
              <>
                <M.Text size={14} color="#5E6470">
                  ${couponToShipping.previousShipping.toFixed(2)}
                </M.Text>
                <M.Text size={14} color="#5E6470">
                  ${couponToShipping.discountShipping.toFixed(2)}
                </M.Text>
              </>
            )}
            <M.Text size={14} color="#5E6470">
              ${order?.orderShipping?.shippingCost.toFixed(2) ?? "0.00"}
            </M.Text>
            <M.Divider my={14} color="#D7DAE0" />
            <M.Text size={14} color="#5E6470" weight={600}>
              ${order?.total?.toNumber()?.toFixed(2)}
            </M.Text>
          </M.Box>
        </M.Group>
      </M.Box>

      {order?.note && (
        <M.Stack className="mt-5" spacing="xs">
          <M.Text size={14} color="#1A1C21" weight={600}>
            Note:
          </M.Text>
          <M.Text size={14} color="#5E6470">
            {order?.note}
          </M.Text>
        </M.Stack>
      )}
    </M.Box>
  );
};
