import { trpc } from "#/trpc";
import { reportUserError, reportUserSuccess } from "#/util";
import { HomePageBannerForm } from "./Form";
import { FormValues } from "./Form/types";
import { useNavigate } from "react-router-dom";

export const CreateHomePageBanner = () => {
  const navigate = useNavigate();
  const ctx = trpc.useContext();
  const { mutate, isLoading } = trpc.v2_5.banner.create.useMutation({
    onError(error) {
      reportUserError({
        title: "Error creating home page banner",
        message: error.message,
      });
    },
    onSuccess() {
      ctx.v2_5.banner.invalidate();
      reportUserSuccess({
        title: "Home page banner created",
      });
      navigate("/ecommerce-crm/banner");
    },
  });

  const handleSubmit = (values: FormValues) => {
    mutate({
      title: values.title,
      template: values.template,
      position: Number(values.position),
      page: values.page,
      redirectTo: values.redirectTo,
      imageDesktop: values.imageDesktop,
      imageMobile: values.imageMobile,
      activeFrom: values.dateRange[0],
      activeTo: values.dateRange[1],
      withButton: values.withButton,
      content: values.content.map((it) => ({
        content: it.content,
        colorHex: it.colorHex,
        position: Number(it.position),
      })),
      buttonHexColor: values.withButton ? values.buttonHexColor : null,
      buttonText: values.withButton ? values.buttonText : null,
      buttonVariant: values.withButton ? values.buttonVariant : null,
    });
  };

  return <HomePageBannerForm isLoading={isLoading} onSubmit={handleSubmit} />;
};
