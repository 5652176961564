// @ts-nocheck
import {
  Field,
  FormControl,
  Input,
  NumberInput,
} from "#/components/Form/v2/index.js";
import SceneLayout from "#/components/SceneLayout/index.jsx";
import { MoonIcon } from "@chakra-ui/icons";
import * as C from "@chakra-ui/react";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";

const epson = window.epson;
const DEFAULT_ADDRESS = "192.168.192.168";
const DEFAULT_PORT = 8008;

export default function PrintLab() {
  const formMethods = useForm();
  const [connectionProps, setConnectionProps] = React.useState({
    address: DEFAULT_ADDRESS,
    port: DEFAULT_PORT,
  });
  const [ePosDev] = React.useState(() => new epson.ePOSDevice());
  const [printer, setPrinter] = React.useState(null);

  const handleSubmit = React.useCallback((f) => setConnectionProps(f), []);

  const handlePrint = React.useCallback(
    (f) => {
      printer.addTextAlign(printer.ALIGN_LEFT);
      printer.addText("01/27/21                Order number: #12345678\n");
      printer.addText("Store: 1                        Workstation: 12\n");
      printer.addTextAlign(printer.ALIGN_CENTER);
      printer.addTextStyle(false, false, true, printer.COLOR_1);
      printer.addText("REPRINTED\n");
      printer.brightness = 1.0;
      printer.halftone = printer.HALFTONE_ERROR_DIFFUSION;
      //printer.addImage(context, 0, 0, 256, 60, printer.COLOR_1, printer.MODE_MONO);
      printer.addTextSize(2, 2);
      printer.addText("GOLD TREE\n");
      printer.addTextStyle(false, false, false, printer.COLOR_1);
      printer.addTextSize(1, 1);
      printer.addText("755 NW 72nd AVE PLAZA 33\n");
      printer.addText("MIAMI, FL, 33126\n");
      printer.addText("TELF +1 (786) 7177131\n");
      printer.addTextAlign(printer.ALIGN_LEFT);
      printer.addTextStyle(false, false, true, printer.COLOR_1);
      printer.addText("    Bill to:\n");
      printer.addTextStyle(false, false, false, printer.COLOR_1);
      printer.addText("            Kenia Escobar\n");
      printer.addText("Cashier: Reina\n\n");
      printer.addTextStyle(false, true, false, printer.COLOR_1);
      printer.addText("Item #               Qty    Price    Ext Price\n");
      printer.addTextStyle(false, false, false, printer.COLOR_1);
      printer.addText("14063                  8    $3.15       $25.20 T\n");
      printer.addText(" Eye Round Bizel B    D%    10%                 \n");
      printer.addTextAlign(printer.ALIGN_RIGHT);
      printer.addText("Subtotal:    $321.32\n");
      printer.addText("Local Sales Tax         7% Tax:      +$22.49\n");
      printer.addTextStyle(false, false, true, printer.COLOR_1);
      printer.addText("RECEIPT TOTAL: $343.81\n");
      printer.addTextStyle(false, false, false, printer.COLOR_1);
      printer.addTextAlign(printer.ALIGN_LEFT);
      printer.addText("   Credit Card: $343.81\n");
      printer.addText("   Visa\n");
      printer.addTextAlign(printer.ALIGN_RIGHT);
      printer.addText("Total Sales Discounts:    $260.52\n");
      printer.addTextAlign(printer.ALIGN_CENTER);
      printer.addText("Thanks for shopping with us!\n");
      printer.addText("All sales are final\n");
      printer.addText("No exchanges. No returns\n");
      printer.send();
    },
    [printer]
  );

  React.useEffect(() => {
    ePosDev.connect(connectionProps.address, connectionProps.port, (data) => {
      if (data === "OK" || data === "SSL_CONNECT_OK") {
        ePosDev.createDevice(
          "local_printer",
          ePosDev.DEVICE_TYPE_PRINTER,
          { crypto: false, buffer: false },
          (devobj, retcode) => {
            if (retcode === "OK") {
              setPrinter({
                ...devobj,
                timeout: 6000,
                onreceive: (res) => alert(res.success),
                oncoveropen: () => alert("Cover open"),
              });
            }
          }
        );
      }
    });
  }, [ePosDev, connectionProps]);

  return (
    <SceneLayout icon={<MoonIcon />}>
      <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
        <FormProvider {...formMethods}>
          <FormControl name="address" label="Address">
            <Field as={Input} defaultValue={DEFAULT_ADDRESS} />
          </FormControl>
          <FormControl name="port" label="PORT" required>
            <Field>
              {({ control, name }) => (
                <NumberInput
                  control={control}
                  name={name}
                  defaultValue={DEFAULT_PORT}
                />
              )}
            </Field>
          </FormControl>
          <C.Button type="submit">Connect</C.Button>
          <C.Button onClick={handlePrint}>Print</C.Button>
        </FormProvider>
      </form>
    </SceneLayout>
  );
}
