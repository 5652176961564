import Decimal from "decimal.js";

interface Discount {
  amount: Decimal;
  type:
    | "AMOUNT"
    | "PERCENTAGE"
    | "SHIPPING_PERCENTAGE"
    | "ORDER_SUBTOTAL_PERCENTAGE"
    | "ORDER_SUBTOTAL_AMOUNT";
}

export function applyDiscountV2(total: Decimal, discount: Discount | null) {
  if (discount?.type === "PERCENTAGE") {
    return total.times(new Decimal(1).sub(discount.amount.div(100)));
  } else if (discount?.type === "AMOUNT") {
    return total.sub(discount.amount);
  } else if(discount?.type === "ORDER_SUBTOTAL_PERCENTAGE") {
    return total.times(new Decimal(1).sub(discount.amount.div(100)));
  } else if (discount?.type === "ORDER_SUBTOTAL_AMOUNT") {
    return total.sub(discount.amount);
  } else {
    return total;
  }
}
