import { makeController } from "#/components-ng/index.js";
import { z } from "zod";

export const createSupplierSchema = z.object({
  name: z.string({
    required_error: "Name is required",
  }),
  description: z.string({
    required_error: "Description is required",
  }),
  phoneNumber: z.string({
    required_error: "Phone number is required",
  }),
  address: z.string({
    required_error: "Address is required",
  }),
});

export type FormValues = z.infer<typeof createSupplierSchema>;
export const C = makeController<FormValues>();
