import { useAuth } from "#/context/AuthContext.js";
import { trpc } from "#/trpc.js";
import { reportUserError, reportUserSuccess } from "#/util/ux/index.js";
import BlogForm from "./Form/Form.js";
import { FormValues } from "./Form/types.js";
import { useNavigate } from "react-router";

export default function CreatePost() {
  const ctx = trpc.useContext();
  const [{ auth }] = useAuth();
  const navigate = useNavigate();
  const { mutate, isLoading } = trpc.v2_5.blog.create.useMutation({
    onSuccess() {
      ctx.v2_5.blog.invalidate();
      reportUserSuccess({ title: "Post created successfully" });
      navigate("/ecommerce-crm/blog");
    },
    onError(error) {
      reportUserError({
        title: "Failed to create post",
        message: error.message,
      });
    },
  });

  const handleSubmit = (post: FormValues) => {
    mutate({
      title: post.title,
      titleEsp: post.titleEsp ?? null,
      image: post.image ?? null,
      author: auth.user.id,
      text: post.text,
      textEsp: post.textEsp ?? null,
      blogExternalUrl: post.blogExternalUrl ?? null,
      seasonsIds: post.seasons?.map((season) => season.id) ?? [],
      tagsIds: post.tags?.map((tag) => tag.id) ?? [],
      catalogIds: post.catalogs?.map((catalog) => catalog.id) ?? null,
    });
  };

  return (
    <BlogForm
      title="Create post"
      titleSubmit="Create post"
      onSubmit={handleSubmit}
      isLoading={isLoading}
    />
  );
}
