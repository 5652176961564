import { orderV2ToTicket } from "#/modules/ticket/print"
import * as M from "@mantine/core"
import logoSrc from "#/images/monochromatic-logo-ticket.png"
import dayjs from "dayjs"
import { Fragment } from "react"
import { match } from "ts-pattern"

export const TicketPreview = ({ order }) => {
	//TODO(v2): This function doesn't work at all
	const ticket = orderV2ToTicket(order)

	let changeString = `$${ticket.change.toFixed(2)}`
	let shippingString = ticket.shipping ? `$${ticket.shipping.toFixed(2)}` : ""
	let couponString =
		ticket.coupon != null ? `$${ticket.coupon.appliedAmount.toFixed(2)}` : ""
	let previousShippingString =
		ticket?.previousShipping != null
			? `$${ticket.previousShipping.toFixed(2)}`
			: ""
	let discountShippingString =
		ticket?.discountShipping != null
			? `$${ticket.discountShipping.toFixed(2)}`
			: ""

	let subtotalString = `${ticket.subtotal.toFixed(2)}`
	let taxString = `+$${ticket.taxAmount.toFixed(2)}`
	let totalString = `${ticket.total.toFixed(2)}`
	let insuranceString = ticket?.insurance
		? `$${ticket.insurance.toFixed(2)}`
		: null

	const maxLength = Math.max(
		subtotalString.length,
		taxString.length,
		totalString.length,
		changeString.length,
		shippingString.length,
		couponString.length,
		previousShippingString.length,
		discountShippingString.length,
		insuranceString?.length ?? 0,
	)
	changeString = changeString.padStart(maxLength, " ")
	shippingString = shippingString.padStart(maxLength, " ")
	subtotalString = subtotalString.padStart(maxLength, " ")
	taxString = taxString.padStart(maxLength, " ")
	totalString = totalString.padStart(maxLength, " ")
	couponString = couponString.padStart(maxLength, " ")
	previousShippingString = previousShippingString.padStart(maxLength, " ")
	discountShippingString = discountShippingString.padStart(maxLength, " ")
	insuranceString = insuranceString?.padStart(maxLength, " ") ?? ""

	const orderType = match(ticket.orderType)
		.with("IN_STORE", () => "Store")
		.with("ONLINE", () => "Delivery")
		.with("PICKUP", () => "Pickup")
		.otherwise(() => "Store")

	return (
		<M.Stack spacing={2} sx={{ "& *": { fontFamily: "monospace !important" } }}>
			<M.Group position="apart">
				<M.Text>Date: {dayjs(ticket.createdAt).format("MM/DD/YYYY")}</M.Text>
				<M.Text>Order: {ticket.order}</M.Text>
			</M.Group>
			<M.Group position="apart">
				<M.Text>Store: {ticket.store}</M.Text>
				<M.Text>Workstation: {ticket.workstation}</M.Text>
			</M.Group>
			<M.Stack align="center" spacing={2}>
				<M.Text>REPRINTED</M.Text>
				<M.Image src={logoSrc} width={240} />
				<M.Text>GOLD TREE</M.Text>
				<M.Text>755 NW 72nd AVE PLAZA 33</M.Text>
				<M.Text>MIAMI, FL, 33126</M.Text>
				<M.Text>TEL +1 (786) 7177131</M.Text>
			</M.Stack>
			{ticket.cashier && (
				<M.Text weight="bold" mt="md">
					Cashier: {ticket.cashier}
				</M.Text>
			)}
			<M.Text weight="bold" mt={!ticket.cashier ? "md" : undefined}>
				Bill to: {ticket.customer}
			</M.Text>
			{ticket.address && (
				<M.Text weight="bold">
					Shipping: <span className="font-normal">{ticket.address}</span>
				</M.Text>
			)}
			<M.Text weight="bold">Type: {orderType}</M.Text>
			<M.Text underline component="pre" mt="md">
				{"Item #".padEnd(16)}
				{"".padEnd(1)}
				{"Qty".padEnd(3)}
				{"".padEnd(3)}
				{"D%".padEnd(3)}
				{"".padEnd(3)}
				{"Price".padEnd(8)}
				{"".padEnd(3)}
				{"Total".padEnd(8)}
			</M.Text>
			{ticket.products.map((p, i) => {
				const itemCell = `${p.sku} ${p.title}`
				return (
					<M.Stack spacing={0} key={i}>
						<M.Text component="pre">
							{itemCell.substring(0, 17).padEnd(16)}
							{"".padEnd(1)}
							{p.qty.toString().padStart(3)}
							{"".padEnd(3)}
							{(p.discount ? p.discount + "%" : "").padEnd(3)}
							{"".padEnd(3)}
							{`$${p.price.toFixed(2)}`.padEnd(8)}
							{"".padEnd(3)}
							{`$${p.total.toFixed(2)}`.padEnd(8)}
						</M.Text>
						<M.Text component="pre">
							{itemCell.substring(17, 34).padEnd(16)}
							{"".padEnd(1)}
							{"".padStart(3)}
							{"".padEnd(3)}
							{"".padEnd(3)}
							{"".padEnd(3)}
							{(p.discount
								? `-$${(p.price * (p.discount / 100.0)).toFixed(2)}`
								: ""
							).padEnd(8)}
							{"".padEnd(3)}
							{"".padEnd(8)}
						</M.Text>
					</M.Stack>
				)
			})}
			{ticket.customizedMultipledProducts.length > 0 && (
				<>
					{ticket.customizedMultipledProducts.map((p, i) => {
						const itemCell = `${p[0].sku} ${p[0].title}`
						console.log(p)
						return (
							<Fragment key={itemCell}>
								<M.Text weight="bold" mt="md">
									Custom: Selected Items
								</M.Text>

								<M.Stack spacing={0} key={i}>
									<M.Text
										component="pre"
										weight={
											p[0].productCustomAttributePrincipal === true
												? "bold"
												: "normal"
										}
									>
										{itemCell.substring(0, 17).padEnd(16)}
										{"".padEnd(1)}
										{p[0].qty.toString().padStart(3)}
										{"".padEnd(3)}
										{(p[0].discount ? `${p[0].discount} %` : "").padEnd(3)}
										{"".padEnd(3)}
										{`$${p[0].price.toFixed(2)}`.padEnd(8)}
										{"".padEnd(3)}
										{`$${p[0].total.toFixed(2)}`.padEnd(8)}
									</M.Text>
									<M.Text
										component="pre"
										weight={
											p[0].productCustomAttributePrincipal === true
												? "bold"
												: "normal"
										}
									>
										{itemCell.substring(17, 34).padEnd(16)}
										{"".padEnd(1)}
										{"".padStart(3)}
										{"".padEnd(3)}
										{"".padEnd(3)}
										{"".padEnd(3)}
										{(p[0].discount
											? `-$${(p[0].price * (p[0].discount / 100.0)).toFixed(2)}`
											: ""
										).padEnd(8)}
										{"".padEnd(3)}
										{"".padEnd(8)}
									</M.Text>
								</M.Stack>

								{p[1].map((v) => {
									const itemCell_ = `${v.sku} ${v.title}`

									return (
										<M.Stack spacing={0} key={v.sku}>
											<M.Text
												component="pre"
												weight={
													v.productCustomAttributePrincipal === true
														? "bold"
														: "normal"
												}
											>
												{itemCell_.substring(0, 17).padEnd(16)}
												{"".padEnd(1)}
												{v.qty.toString().padStart(3)}
												{"".padEnd(3)}
												{(v.discount ? `${v.discount} %` : "").padEnd(3)}
												{"".padEnd(3)}
												{`$${v.price.toFixed(2)}`.padEnd(8)}
												{"".padEnd(3)}
												{`$${v.total.toFixed(2)}`.padEnd(8)}
											</M.Text>
											<M.Text
												component="pre"
												weight={
													v.productCustomAttributePrincipal === true
														? "bold"
														: "normal"
												}
											>
												{itemCell_.substring(17, 34).padEnd(16)}
												{"".padEnd(1)}
												{"".padStart(3)}
												{"".padEnd(3)}
												{"".padEnd(3)}
												{"".padEnd(3)}
												{(v.discount
													? `-$${(v.price * (v.discount / 100.0)).toFixed(2)}`
													: ""
												).padEnd(8)}
												{"".padEnd(3)}
												{"".padEnd(8)}
											</M.Text>
										</M.Stack>
									)
								})}
							</Fragment>
						)
					})}
				</>
			)}
			<M.Stack spacing={2} align="end" mt="md">
				{ticket.previousShipping != null && (
					<M.Text>Shipping: {previousShippingString}</M.Text>
				)}
				{ticket.discountShipping != null && (
					<M.Text>Discount Shipping: {discountShippingString}</M.Text>
				)}
				{!ticket.shipping?.eq(0) && <M.Text>Shipping: {shippingString}</M.Text>}
				<M.Text>Subtotal: ${subtotalString}</M.Text>
				{ticket.coupon != null && (
					<M.Text>
						Applied Coupon {ticket.coupon.amount}:{"  "}-{couponString}
					</M.Text>
				)}
				{ticket.saleDiscount && (
					<M.Text>
						Applied Discount:{"  "}
						{ticket.saleDiscount?.type === "AMOUNT" ? "$" : ""}
						{ticket.saleDiscount?.amount.toFixed(2)}
						{ticket.saleDiscount?.type === "PERCENTAGE" ? "%" : ""}
					</M.Text>
				)}
				<M.Text>
					Local sales tax ${ticket.taxPercentage.toString()}% Tax: ${taxString}
				</M.Text>

				{insuranceString && <M.Text>Insurance: {insuranceString}</M.Text>}

				<M.Text weight="bold">RECEIPT TOTAL: ${totalString}</M.Text>
			</M.Stack>
			{ticket.payments.map((payment, i) => (
				<M.Text key={i}>
					{"  "}
					{payment.method}: ${payment.amount}
				</M.Text>
			))}
			<M.Text>Change: {changeString}</M.Text>
			<M.Stack spacing={2} align="end">
				<M.Text>
					Total sales discounts: ${ticket.totalDiscount.toFixed(2)}
				</M.Text>
			</M.Stack>
			<M.Stack spacing={2} align="center" mt="md">
				<M.Text>Thanks for shopping with us!</M.Text>
				<M.Text>All sales are final</M.Text>
				<M.Text>No exchanges. No returns</M.Text>
			</M.Stack>
		</M.Stack>
	)
}
