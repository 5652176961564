import { Button, makeController } from "#/components-ng/index.js";
import { useAuth } from "#/context/AuthContext.js";
import Logo from "#/images/colored-logo-invoice.png";
import { trpc } from "#/trpc.js";
import { reportUserError } from "#/util/index.js";
import { FormProvider } from "react-hook-form";
import { useLocation, useNavigate } from "react-router";

type FormValues = {
  email: string;
  password: string;
};
const C = makeController<FormValues>();

export default function Auth() {
  const form = C.useForm();
  const [, { login }] = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const ctx = trpc.useContext();
  const loginWithPasswordMut = trpc.user.signin.useMutation({
    onError(err) {
      reportUserError({
        title: "Login failed",
        message: err.message,
      });
    },
    onSuccess(data) {
      ctx.invalidate();
      login(data.session);
      const locationState = location.state as unknown;
      if (
        locationState &&
        typeof locationState === "object" &&
        "from" in locationState &&
        typeof locationState.from === "string" &&
        locationState.from !== location.pathname
      ) {
        navigate(locationState.from);
      } else {
        navigate("/");
      }
    },
  });

  const handleSubmit = async (values: FormValues) => {
    await loginWithPasswordMut.mutateAsync({
      email: values.email,
      password: values.password,
    });
  };

  return (
    <div className="grid h-screen place-items-center bg-slate-100">
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <FormProvider {...form}>
          <div className="grid min-w-[32ch] justify-items-center gap-y-2 rounded-md bg-white p-4">
            <img src={Logo} className="w-52" />
            <C.InputField
              name="email"
              placeholder="Email"
              required
              classNames={{ field: "w-full" }}
            />
            <C.InputField
              name="password"
              placeholder="Password"
              type="password"
              required
              classNames={{ field: "w-full" }}
            />
            <Button
              variant="primary"
              className="w-full"
              type="submit"
              disabled={loginWithPasswordMut.isLoading}
            >
              Log in
            </Button>
          </div>
        </FormProvider>
      </form>
    </div>
  );
}
