import { RouterOutputs, trpc } from "#/trpc.js";
import { Table } from "@mantine/core";

type Product = NonNullable<RouterOutputs["itemSku"]["getById"]>;

interface vendorProps {
  product?: Product | null;
}

export const VendorSection = ({ product }: vendorProps) => {
  const { data } = trpc.vendor.getById.useQuery(
    {
      id: product?.vendorId as number,
    },
    {
      cacheTime: 0,
      enabled: !!product?.vendorId,
      select: (data) => {
        return { company: data!.company };
      },
    }
  );

  const { data: rateCost } = trpc.rateCost.getById.useQuery(
    { id: product?.rateCostId as number },
    {
      cacheTime: 0,
      enabled: !!product?.rateCostId,
      select: (data) => {
        return { name: data!.name };
      },
    }
  );

  return (
    <div className="overflow-x-auto">
      <Table align="center" verticalSpacing="lg" className="mt-3">
        <thead>
          <tr>
            <th>Vendor</th>
            <th>Rate cost</th>
            <th>Vendor ALU</th>
            <th>Cost type</th>
            <th>Cost</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{data?.company ?? ""}</td>
            <td>{rateCost?.name ?? ""}</td>
            <td>{product?.vendorAlu ?? ""}</td>
            <td>{product?.vendorCostType ?? ""}</td>
            <td>{`${product?.vendorCost?.toNumber()?.toFixed(2) ?? ""}`}</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};
