import { PersonOutlineIcon } from "#/components-ng/index.js";
import SceneLayout from "#/components/SceneLayout/index.jsx";
import { Button, Dropdown } from "@gt/ui";
import { Link } from "react-router-dom";
import { HStack } from "#/css/jsx";
import { DropdownMenu } from "@radix-ui/themes";
import { css } from "#/css/css";
import { SearchButton } from "#/components@v2_5/search-button.js";
import { CustomersTable } from "./table.js";

export default function CustomerList() {
  const [search, setSearch] = useState<string | null>(null);

  return (
    <SceneLayout icon={<PersonOutlineIcon />}>
      <HStack justify="space-between" gap={3}>
        <DrowndownButton />
        <SearchButton setQuery={setSearch} />
      </HStack>
      <div
        className={css({
          marginTop: 5,
        })}
      >
        <CustomersTable search={search} />
      </div>
    </SceneLayout>
  );
}

const DrowndownButton = () => {
  return (
    <Dropdown.Root>
      <Dropdown.Trigger>
        <Button
          className={css({
            gap: 4,
          })}
        >
          I want to
          <DropdownMenu.TriggerIcon />
        </Button>
      </Dropdown.Trigger>
      <Dropdown.Content>
        <Dropdown.Item>
          <Link to="create">Create customer</Link>
        </Dropdown.Item>
      </Dropdown.Content>
    </Dropdown.Root>
  );
};
