import GTLoader from "#/components-ng/loader.js";
import { trpc } from "#/trpc.js";
import { reportUserError, reportUserSuccess } from "#/util/ux/index.js";
import DepartmentForm from "./Form.js";
import * as M from "@mantine/core";
import React from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";

export default function UpdateDepartment() {
  const { id: _id } = useParams<{ id: string }>();
  const id = Number.parseInt(_id!);
  const navigate = useNavigate();
  const ctx = trpc.useContext();
  const { data, isLoading } = trpc.department.getById.useQuery(
    {
      id,
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchInterval: false,
      onError(error) {
        reportUserError({
          title: "Failed to load department",
          message: error.message,
        });
      },
    }
  );
  const { mutate } = trpc.department.update.useMutation({
    onSuccess() {
      ctx.department.invalidate();
      ctx.v2_5.departments.invalidate();
      reportUserSuccess({ title: "Department updated successfully" });
      navigate("/inventory/departments");
    },
    onError(error) {
      reportUserError({
        title: "Failed to update department",
        message: error.message,
      });
    },
  });

  const handleSubmit = React.useCallback(
    (f) =>
      mutate({
        id,
        input: {
          name: f.name,
          description: f.description,
          defaultImage: f.defaultImage ?? null,
          images: f.images ?? [],
          active: f.active,
          top: f.top,
          metaDescription: f.metaDescription ?? null
        },
      }),
    [id, mutate]
  );

  return !isLoading ? (
    <DepartmentForm
      defaultValues={{
        name: data!.name,
        description: data?.description ?? "",
        defaultImage: data?.defaultImage ?? undefined,
        active: data!.active,
        images: [],
        top: data!.top,
        metaDescription: data?.metaDescription ?? undefined,
      }}
      onSubmit={handleSubmit}
      isLoading={isLoading}
    />
  ) : (
    <M.LoadingOverlay
      visible={isLoading}
      loader={<GTLoader width={100} height={100} />}
    />
  );
}
