import { trpc } from "#/trpc";
import { useNavigate } from "react-router";
import { SysSectionForm } from "./Form";
import { reportUserError, reportUserSuccess } from "#/util";
import { FormValues } from "./Form/types";

export const CreateSysSection = () => {
  const ctx = trpc.useContext();
  const navigate = useNavigate();

  const { mutate, isLoading } =
    trpc.v2_5.changelog.sysSections.create.useMutation({
      onSuccess() {
        ctx.v2_5.changelog.invalidate();
        reportUserSuccess({
          title: "Created sys-section successfully",
        });
        navigate("/settings/admin/changelog/sys-sections");
      },
    });

  const handleSubmit = (values: FormValues) => {
    if (values.sysBlockId == null) {
      reportUserError({
        title: "SysBlock is required",
      });
      return;
    }

    mutate({
      name: values.name,
      sysBlockId: Number(values.sysBlockId),
    });
  };

  return <SysSectionForm isLoading={isLoading} onSubmit={handleSubmit} />;
};
