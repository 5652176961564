import { DefaultValues, FormProvider, SubmitHandler } from "react-hook-form";
import * as M from "@mantine/core";
import { C, createSupplierSchema, FormValues } from "./types";
import { css } from "#/css/css";
import { zodResolver } from "@hookform/resolvers/zod";

interface SupplierFormProps {
  onSubmit: SubmitHandler<FormValues>;
  defaultValues?: DefaultValues<FormValues>;
  isLoading: boolean;
}

export default function SupplierForm(props: SupplierFormProps) {
  const { defaultValues, onSubmit, isLoading } = props;

  const form = C.useForm({
    resolver: zodResolver(createSupplierSchema),
    shouldUnregister: false,
    defaultValues: {
      name: "",
      description: "",
      phoneNumber: "",
      address: "",
      ...defaultValues,
    },
  });

  return (
    <M.Box>
      <FormProvider {...form}>
        <form onSubmit={(e) => form.handleSubmit(onSubmit)(e)}>
          <M.Stack spacing={48}>
            <M.Stack p={40} spacing={4} align="start">
              <M.Title
                bg={"white"}
                fw={600}
                px={40}
                py={24}
                w={"100%"}
                fz="1.6rem"
                className={css({ borderRadius: 8 })}
              >
                Supplier
              </M.Title>
              <M.Box bg="white" w={"100%"} className={css({ borderRadius: 8 })}>
                <M.Stack spacing={24} px={40} w={"30%"} py={43}>
                  <C.InputField name="name" label="Name" placeholder="Name" />
                  <C.InputField
                    name="description"
                    label="Description"
                    placeholder="Description"
                  />
                  <C.InputField
                    name="phoneNumber"
                    label="Phone number"
                    placeholder="Phone number"
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const value: string = e.target.value;
                      const onlyNumbers: string = value
                        .replace(/[^0-9]/g, "")
                        .slice(0, 10);
                      e.target.value = onlyNumbers;
                    }}
                  />
                  <C.InputField
                    name="address"
                    label="Address"
                    placeholder="Address"
                  />
                  <M.Button
                    loading={isLoading}
                    type="submit"
                    className={css({
                      width: "fit",
                    })}
                  >
                    Save supplier
                  </M.Button>
                </M.Stack>
              </M.Box>
            </M.Stack>
          </M.Stack>
        </form>
      </FormProvider>
    </M.Box>
  );
}
