import { makeMantineController } from "#/components/Form/v3/index.js";
import { useAuth } from "#/context/AuthContext.js";
import { trpc } from "#/trpc.js";
import { reportUserError } from "#/util/index.js";
import * as M from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import dayjs from "dayjs";
import Decimal from "decimal.js";
import React from "react";
import {
  Controller,
  DefaultValues,
  FormProvider,
  SubmitHandler,
  useForm,
  useFormContext,
} from "react-hook-form";

enum MilestoneType {
  ENTITY = "ENTITY",
  FILIAL = "FILIAL",
  USERS = "USERS",
  // BADGE = "BADGE",
}

export interface MilestoneFormValues {
  title: string;
  description: string;
  dateRange: [Date, Date];
  milestoneType: MilestoneType;
  milestoneValue: Decimal;
  badgeId?: number;
  entityId?: number;
  filialId?: number;
  userMilestones?: number[];
}

const Mc = makeMantineController({ ctx: {} as MilestoneFormValues });

export const MilestoneForm = ({
  onSubmit,
  defaultValues,
  loading,
}: {
  onSubmit: SubmitHandler<MilestoneFormValues>;
  defaultValues?: DefaultValues<MilestoneFormValues>;
  loading: boolean;
}) => {
  const [type, setType] = React.useState<MilestoneType>(
    defaultValues?.milestoneType ?? MilestoneType.ENTITY
  );

  const form = useForm<MilestoneFormValues>({
    defaultValues: {
      dateRange: [new Date(), dayjs().add(1, "M").toDate()],
      milestoneType: MilestoneType.ENTITY,
      ...defaultValues,
    },
  });

  return (
    <M.Container>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <FormProvider {...form}>
          <M.Stack>
            <Mc as={M.TextInput} name="title" label="Title" required />
            <Mc
              as={M.Textarea}
              name="description"
              label="Description"
              autosize
              minRows={2}
              maxRows={8}
              required
            />
            <Mc
              as={DatePickerInput}
              type="range"
              name="dateRange"
              label="Date range"
              numberOfColumns={2}
              required
            />
            <Mc
              as={M.NumberInput}
              name="milestoneValue"
              min={0}
              precision={2}
              label="Milestone value"
              required
            />
            <Mc
              as={M.Select}
              name="milestoneType"
              data={Object.values(MilestoneType)}
              label="Milestone type"
              required
              onChange={(value) => setType(value as MilestoneType)}
            />
            {type === MilestoneType.ENTITY ? <EntityField /> : null}
            {type === MilestoneType.FILIAL ? <FilialField /> : null}
            {type === MilestoneType.USERS ? <UsersField /> : null}
          </M.Stack>
          <M.Group position="right">
            <M.Button mt="md" type="submit" loading={loading}>
              Submit
            </M.Button>
          </M.Group>
        </FormProvider>
      </form>
    </M.Container>
  );
};

const EntityField = () => {
  const form = useFormContext<MilestoneFormValues>();
  const { data } = trpc.entity.getAll.useQuery(undefined, {
    suspense:
      form.watch("milestoneType") === MilestoneType.FILIAL ||
      form.watch("milestoneType") === MilestoneType.USERS,
    onError(err) {
      reportUserError({
        title: "Failed to load entities",
        message: err.message,
      });
    },
  });

  const selectData = React.useMemo(() => {
    return (
      data?.map((entity) => ({
        label: entity.name,
        value: entity.id as unknown as string,
      })) ?? []
    );
  }, [data]);

  return (
    <Mc
      as={M.Select}
      name="entityId"
      label="Entity"
      data={selectData}
      searchable
      required={form.watch("milestoneType") === MilestoneType.ENTITY}
      clearable
      onChange={(value) => {
        form.setValue(
          "entityId",
          selectData.find((d) => d.value === value)?.value as unknown as number
        );
      }}
    />
  );
};

const FilialField = () => {
  const form = useFormContext<MilestoneFormValues>();

  const { data } = trpc.filial.getAll.useQuery(undefined, {
    suspense:
      form.watch("milestoneType") === MilestoneType.USERS ||
      form.watch("milestoneType") === MilestoneType.ENTITY,
    onError(err) {
      reportUserError({
        title: "Failed to load filials",
        message: err.message,
      });
    },
  });

  const selectData = React.useMemo(() => {
    return (
      data?.map((filial) => ({
        label: filial.name,
        value: filial.id as unknown as string,
      })) ?? []
    );
  }, [data]);

  return (
    <Mc
      as={M.Select}
      name="filialId"
      label="Filial"
      data={selectData}
      searchable
      required={form.watch("milestoneType") === MilestoneType.FILIAL}
      clearable
      onChange={(value) => {
        form.setValue(
          "filialId",
          selectData.find((d) => d.value === value)?.value as unknown as number
        );
      }}
    />
  );
};

const UsersField = () => {
  const [{ auth }] = useAuth();
  const form = useFormContext<MilestoneFormValues>();

  const { data } = trpc.user.getAll.useQuery(
    {
      filialId: auth?.user?.filialId ?? null,
    },
    {
      suspense:
        form.watch("milestoneType") === MilestoneType.FILIAL ||
        form.watch("milestoneType") === MilestoneType.ENTITY,
      onError: (err) => {
        reportUserError({
          title: "Failed to load users",
          message: err.message,
        });
      },
    }
  );

  const selectData = React.useMemo(() => {
    return (
      data?.map((user) => ({
        label: `${user.firstName} ${user.lastName ?? ""}`,
        value: user.id.toString(),
      })) ?? []
    );
  }, [data]);

  return (
    <Controller
      name="userMilestones"
      control={form.control}
      render={({ field }) => (
        <M.MultiSelect
          label="Users"
          data={selectData}
          searchable
          required={form.watch("milestoneType") === MilestoneType.USERS}
          clearable
          value={field.value?.map((v) => v.toString()) ?? []}
          onChange={(value) => {
            field.onChange(
              selectData
                .filter((d) => value.includes(d.value))
                .map((d) => parseInt(d.value))
            );
          }}
        />
      )}
    />
  );
};
