import * as M from "@mantine/core";
import _ from "lodash";
import React from "react";

export class ErrorBoundary extends React.Component {
  state = { hasError: false };

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  render() {
    return this.state.hasError ? (
      <M.Center sx={{ textAlign: "center", height: "calc(100vh - 300px)" }}>
        <M.Stack>
          <M.Title order={3}>
            Sorry, there was a problem loading this page
          </M.Title>
          <M.Title order={4}>
            {_.sample([
              "(╯°□°）╯︵ ┻━┻",
              "（　ﾟДﾟ）",
              "(╬ ಠ益ಠ)",
              "ლ(ಠ益ಠლ)",
              "ಥ_ಥ",
              "ಥ﹏ಥ",
              "(⊙.☉)7",
              "щ（ﾟДﾟщ）",
              "｡ﾟ( ﾟஇ‸இﾟ)ﾟ｡",
              "༼ ༎ຶ ෴ ༎ຶ༽",
              "(ᵟຶ︵ ᵟຶ)",
              "( ಠ ʖ̯ ಠ)",
              "(._.)",
            ])}
          </M.Title>
        </M.Stack>
      </M.Center>
    ) : (
      // @ts-ignore
      this.props.children
    );
  }
}
