import React from "react";
import PropTypes from "prop-types";
import { Select as ChakraSelect } from "@chakra-ui/react";

const NativeSelect = React.forwardRef(({ name, ...props }, ref) => {
  return (
    <ChakraSelect
      ref={ref}
      name={name}
      bg="white"
      _hover={{ borderColor: "brand.100" }}
      _focus={{ bg: "brand.50", border: "solid 2px", borderColor: "brand.200" }}
      {...props}
    />
  );
});

NativeSelect.defaultProps = {
  name: "",
  type: "text",
};

NativeSelect.propTypes = {
  rules: PropTypes.objectOf(PropTypes.any),
  type: PropTypes.string,
};

NativeSelect.id = "NativeSelect";
NativeSelect.displayName = "NativeSelect";

export default NativeSelect;
