import MenuIcon from "~icons/ion/ellipsis-horizontal-outline";
import { css } from "#/css/css";
import { HStack } from "#/css/jsx";
import { RouterOutputs, trpc } from "#/trpc";
import { getDiscountLabel } from "#/util/discounts";
import { Button, Dropdown } from "@gt/ui";
import { Badge, DropdownMenu, IconButton, Tooltip } from "@radix-ui/themes";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { useState } from "react";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_SortingState,
  useMantineReactTable,
} from "mantine-react-table";
import { reportUserSuccess } from "#/util";
import { MdOutlineDownload } from "react-icons/md";

export const Coupons = () => {
  return (
    <>
      <HStack justify="space-between" mb={4}>
        <DrowndownButton />
      </HStack>
      <CouponsTable />
    </>
  );
};

export const CouponsTable = () => {
  // pagination
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  // sorting
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const querySorting = useMemo(() => {
    if (sorting[0]) {
      return {
        desc: sorting[0].desc,
        key: sorting[0].id as any,
      };
    }
    return null;
  }, [sorting]);

  const { data, isLoading } = trpc.v2_5.coupons.getByPage.useQuery({
    pageIndex: pagination.pageIndex,
    pageSize: pagination.pageSize,
    sorting: querySorting,
    filters: null,
  });

  const { mutate: exportCsv, isLoading: isLoadingExport } =
    trpc.v2_5.coupons.exportCouponsTableToCsv.useMutation({
      onSuccess: () => {
        reportUserSuccess({
          title: "The csv file has been sent to email",
        });
      },
    });

  const handleExportCsv = () => {
    exportCsv({
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize,
      sorting: querySorting,
      filters: null,
    });
  };

  const table = useMantineReactTable({
    data: data?.entries ?? [],
    columns,
    manualPagination: true,
    enableTopToolbar: false,
    rowCount: data?.totalEntries ?? 0,
    enableFilters: false,
    state: {
      pagination,
      sorting,
      isLoading,
    },
    enableStickyHeader: true,
    manualSorting: true,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    renderBottomToolbarCustomActions: () => (
      <div
        className={css({
          display: "flex",
          alignItems: "center",
          gap: 2,
        })}
      >
        <Tooltip content="Export to CSV">
          <IconButton
            color="gray"
            variant="soft"
            loading={isLoadingExport}
            onClick={handleExportCsv}
          >
            <MdOutlineDownload />
          </IconButton>
        </Tooltip>
      </div>
    ),
  });

  return <MantineReactTable table={table} />;
};

type Coupon = RouterOutputs["v2_5"]["coupons"]["getByPage"]["entries"][number];

const columns: MRT_ColumnDef<Coupon>[] = [
  {
    header: "Actions",
    id: "actions",
    size: 100,
    Cell: ({ row: { original } }) => {
      const tctx = trpc.useContext();
      const { mutate } = trpc.v2_5.coupons.toggleActive.useMutation({
        onSuccess() {
          tctx.v2_5.coupons.invalidate();
        },
      });

      const { mutate: toggleByEmail } =
        trpc.v2_5.coupons.toggleByEmail.useMutation({
          onSuccess() {
            tctx.v2_5.coupons.invalidate();
          },
        });

      const isActive = original.active;

      function toggleActive() {
        mutate({
          filter: {
            by: "id",
            id: original.id,
          },
        });
      }

      // const openConfirmModalFunction = () => {
      //   openConfirmModal({
      //     title: "Pending carts discounts.",
      //     children: (
      //       <p className={css({ lineHeight: "1.5" })}>
      //         {original.byMail
      //           ? "The e-mails with the coupon will no longer be sent to users with pending carts."
      //           : "Three e-mails with the coupon will be sent to users with pending carts."}
      //       </p>
      //     ),
      //     labels: {
      //       cancel: "Cancel",
      //       confirm: "Confirm",
      //     },
      //     confirmProps: { color: "red" },
      //     onConfirm: () => {
      //       toggleByEmail({ id: original.id });
      //     },
      //   });
      // };

      return (
        <Dropdown.Root>
          <Dropdown.Trigger>
            <Button variant="ghost" size="icon">
              <MenuIcon />
            </Button>
          </Dropdown.Trigger>
          <Dropdown.Content>
            <Dropdown.Item asChild>
              <Link to={`edit/${original.reference}`}>Edit</Link>
            </Dropdown.Item>

            {/* <Dropdown.Item onClick={openConfirmModalFunction}>
              {original.byMail ? "Remove by e-mail" : "Send by e-mail"}
            </Dropdown.Item> */}

            <Dropdown.Item onClick={toggleActive}>
              {isActive ? "Deactivate" : "Activate"}
            </Dropdown.Item>
          </Dropdown.Content>
        </Dropdown.Root>
      );
    },
  },
  {
    header: "Coupon code",
    accessorKey: "code",
    Cell: ({ row: { original } }) => {
      const isActive = original.active;
      const value = original.code;

      return (
        <div className="flex items-start gap-x-2">
          <span className="leading-none">{value}</span>
          <Badge size="1" color={isActive ? "green" : "gray"} radius="full">
            {isActive ? "Active" : "Inactive"}
          </Badge>
        </div>
      );
    },
  },
  {
    header: "Discount",
    id: "discountName",
    Cell: ({ row: { original } }) => {
      const discount = original.discount;
      return (
        <div className="flex items-start gap-x-2">
          <div>{getDiscountLabel(discount)}</div>
          {/* <Badge
            size="1"
            color={"green"}
            hidden={!original.byMail}
            radius="full"
          >
            Sent by email
          </Badge> */}
        </div>
      );
    },
  },
  {
    header: "Active date range",
    id: "activeDateRange",
    Cell: ({ row: { original } }) => {
      return `${dayjs(original.activeAt).format("MMM-DD-YYYY")} - ${dayjs(
        original.inactiveAt,
      ).format("MMM-DD-YYYY")}`;
    },
  },
];

const DrowndownButton = () => {
  return (
    <Dropdown.Root>
      <Dropdown.Trigger>
        <Button
          className={css({
            gap: 4,
          })}
        >
          I want to
          <DropdownMenu.TriggerIcon />
        </Button>
      </Dropdown.Trigger>
      <Dropdown.Content>
        <Dropdown.Item>
          <Link to="create">Create coupon </Link>
        </Dropdown.Item>
      </Dropdown.Content>
    </Dropdown.Root>
  );
};
