import { EllipsisHorizontal } from "#/components-ng";
import { useAuth } from "#/context/AuthContext";
import { css } from "#/css/css";
import { styled } from "#/css/jsx";
import { trpc, RouterOutputs } from "#/trpc";
import { reportUserSuccess } from "#/util";
import { Dropdown } from "@gt/ui";
import { PasswordInput } from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import { IconButton, Tooltip } from "@radix-ui/themes";
import dayjs from "dayjs";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_SortingState,
  useMantineReactTable,
} from "mantine-react-table";
import { Link } from "react-router-dom";
import placeholderImage from "#/placeholder-image.jpg";
import { MdOutlineDownload } from "react-icons/md";

interface RelationalFilter {
  RELATIONAL: boolean;
  NON_RELATIONAL: boolean;
}

interface CatalogsTableProps {
  filter: RelationalFilter;
  search?: string | null;
}

export const CatalogsTable = (props: CatalogsTableProps) => {
  // Pagination
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  // Sorting
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const querySorting = useMemo(() => {
    if (sorting[0]) {
      return {
        desc: sorting[0].desc,
        key: sorting[0].id as any,
      };
    }
    return null;
  }, [sorting]);

  const { data, isLoading } = trpc.v2_5.catalog.getAllPosByPage.useQuery(
    {
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize,
      search: props.search,
      filters: {
        relational:
          props.filter?.RELATIONAL != null ? props.filter.RELATIONAL : null,
        nonRelational:
          props.filter?.NON_RELATIONAL != null
            ? props.filter.NON_RELATIONAL
            : null,
      },
      sorting: querySorting,
    },
    {
      keepPreviousData: true,
    },
  );

  const { mutate: exportCsv, isLoading: isLoadingExport } =
    trpc.v2_5.catalog.exportCatalogsTableToCsv.useMutation({
      onSuccess: () => {
        reportUserSuccess({
          title: "The csv file has been sent to email",
        });
      },
    });

  const handleExportCsv = () => {
    exportCsv({
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize,
      search: props.search,
      filters: {
        relational:
          props.filter?.RELATIONAL != null ? props.filter.RELATIONAL : null,
        nonRelational:
          props.filter?.NON_RELATIONAL != null
            ? props.filter.NON_RELATIONAL
            : null,
      },
      sorting: querySorting,
    });
  };

  const table = useMantineReactTable({
    data: data?.entries ?? [],
    columns: columns,
    manualPagination: true,
    enableTopToolbar: false,
    rowCount: data?.totalEntries ?? 0,
    enableFilters: false,
    state: {
      pagination,
      sorting,
      isLoading,
    },
    enableStickyHeader: true,
    manualSorting: true,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    renderBottomToolbarCustomActions: () => (
      <div
        className={css({
          display: "flex",
          alignItems: "center",
          gap: 2,
        })}
      >
        <Tooltip content="Export to CSV">
          <IconButton
            color="gray"
            variant="soft"
            loading={isLoadingExport}
            onClick={handleExportCsv}
          >
            <MdOutlineDownload />
          </IconButton>
        </Tooltip>
      </div>
    ),
  });

  return <MantineReactTable table={table} />;
};

type Catalog =
  RouterOutputs["v2_5"]["catalog"]["getAllPosByPage"]["entries"][number];

const columns: MRT_ColumnDef<Catalog>[] = [
  {
    id: "actions",
    header: "Actions",
    enableSorting: false,
    enableColumnFilter: false,
    Cell: (table) => {
      const [{ auth }] = useAuth();
      const pin = useRef("abc");
      const ctx = trpc.useContext();

      const { mutate } = trpc.v2_5.catalog.deleteCatalog.useMutation({
        onSuccess: () => {
          ctx.v2_5.catalog.getAllPosByPage.invalidate();
          reportUserSuccess({
            title: "Catalog item has been deleted",
          });
        },
      });
      const deleteCatalogItem = () => {
        mutate({
          id: table.row.original.id,
          adminPin: pin.current,
        });
      };

      const askForPinForDeletion = () => {
        openConfirmModal({
          title: "Are you sure you want to delete this catalog item?",
          children: auth.role.name !== "ROOT" && (
            <PasswordInput
              label="Pin"
              onChange={(e) => (pin.current = e.currentTarget.value)}
            />
          ),
          labels: {
            confirm: "Delete",
            cancel: "Cancel",
          },
          confirmProps: { color: "red" },
          onConfirm: () => {
            deleteCatalogItem();
          },
        });
      };

      return (
        <Dropdown.Root>
          <Dropdown.Trigger>
            <styled.div
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <IconButton variant="ghost" color="gray" size="1">
                <EllipsisHorizontal />
              </IconButton>
            </styled.div>
          </Dropdown.Trigger>
          <Dropdown.Content>
            <Dropdown.Item asChild>
              <Link to={`edit/${table.row.original.id}`}>Edit</Link>
            </Dropdown.Item>
            <Dropdown.Item onClick={askForPinForDeletion}>Delete</Dropdown.Item>
          </Dropdown.Content>
        </Dropdown.Root>
      );
    },
  },
  {
    id: "defaultImage",
    header: "Image",
    Cell(props) {
      return (
        <img
          src={props.row.original.defaultImage ?? placeholderImage}
          className={css({
            width: "45px",
          })}
        />
      );
    },
  },
  {
    id: "title",
    header: "Title",
    accessorKey: "title",
  },

  {
    id: "createdAt",
    header: "Date",
    accessorKey: "createdAt",

    Cell: (table) => {
      const createdAt = table.row.original.createdAt;
      if (!createdAt) return "";
      return dayjs(createdAt).format("MM/DD/YYYY").toString();
    },
  },
  {
    id: "updatedById",
    header: "Updated by",
    enableSorting: false,
    enableColumnFilter: false,
    Cell: (table) => {
      const fullName = `${table.row.original.updatedBy?.firstName ?? ""} ${
        table.row.original.updatedBy?.lastName ?? ""
      }`;
      return fullName;
    },
  },
  {
    id: "type",
    header: "Type",
    accessorKey: "type",
  },
];
