import * as M from "@mantine/core";
import dayjs from "dayjs";
import { Fragment } from "react";
import { PieChart } from "react-minimal-pie-chart";

export const CardContainer = ({ children, sx = undefined as any }) => {
  return (
    <M.Stack
      p="xl"
      sx={[
        (t) => ({
          background: "white",
          border: `solid 1px ${t.colors.gray[2]}`,
          borderRadius: t.radius.sm,
          flex: 1,
        }),
        sx,
      ]}
    >
      {children}
    </M.Stack>
  );
};

export const PieChartWithText = ({
  data,
  text,
  lineWidth = 10 as number | null,
  totalValue,
}) => {
  return (
    <M.Box sx={{ maxWidth: 150, position: "relative" }}>
      <PieChart
        data={data}
        lineWidth={lineWidth === null ? undefined : lineWidth}
        background="#e2f3ff"
        totalValue={totalValue}
      />
      <M.Text
        weight="bold"
        size="lg"
        sx={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        {text}
      </M.Text>
    </M.Box>
  );
};

export const NameWithMetric = ({ name, color, value }) => {
  return (
    <>
      <M.Group>
        <M.Box
          sx={{
            width: 10,
            height: 10,
            borderRadius: "50%",
            background: color,
          }}
        />
        <M.Text>{name}</M.Text>
      </M.Group>
      <M.Text weight="bold">{value}</M.Text>
    </>
  );
};

export const monthsData = [
  { label: "January", value: "jan" },
  { label: "February", value: "feb" },
  { label: "March", value: "mar" },
  { label: "April", value: "apr" },
  { label: "May", value: "may" },
  { label: "June", value: "jun" },
  { label: "July", value: "jul" },
  { label: "August", value: "aug" },
  { label: "September", value: "sep" },
  { label: "October", value: "oct" },
  { label: "November", value: "nov" },
  { label: "December", value: "dec" },
];

const totalYears = dayjs().year() - 2022 + 1;
export const yearsData = Array(totalYears)
  .fill(0)
  .map((i) => ({ label: (2022 + i).toString(), value: (2022 + i).toString() }));

export interface MonthYearSelectProps {
  onChange: (value: { month: string; year: string }) => void;
  value: {
    month: string;
    year: string;
  };
}
export const MonthYearSelect = ({ onChange, value }: MonthYearSelectProps) => {
  return (
    <M.Group position="right">
      <M.Select
        data={monthsData}
        value={value.month}
        onChange={(month) => {
          onChange({
            month: month!,
            year: value.year,
          });
        }}
      />
      <M.Select
        data={yearsData}
        value={value.year}
        onChange={(year) =>
          onChange({
            year: year!,
            month: value.month,
          })
        }
      />
    </M.Group>
  );
};

interface PieChartCardProps {
  title: string;
  text: string;
  total: number;
  isMoney: boolean;
  data: {
    title: string;
    value: number;
    color: string;
  }[];
  children?: React.ReactNode | null;
}

export const PieChartCard = (props: PieChartCardProps) => {
  return (
    <CardContainer sx={{ flex: "1 !important" }}>
      <M.Text weight={600}>{props.title}</M.Text>
      <M.Divider color="gray.2" />
      <M.Group spacing={40} position="center">
        <M.Stack spacing="md" align="center">
          <M.Box sx={{ width: 120 }}>
            <PieChartWithText
              data={
                props.data.map((order) => ({
                  title: order.title,
                  value: props.isMoney ? order.value?.toFixed(2) : order.value,
                  color: order.color,
                })) ?? []
              }
              text={`${props.text}`}
              lineWidth={10}
              totalValue={props.isMoney ? props.total?.toFixed(2) : props.total}
            />
          </M.Box>
        </M.Stack>
        <M.Stack>
          <M.SimpleGrid cols={7} sx={{ columnGap: 10, alignItems: "center" }}>
            {props?.data?.map((element, i) => (
              <Fragment key={i}>
                <M.Box
                  sx={{
                    background: element.color,
                    width: 10,
                    height: 10,
                    borderRadius: "50%",
                  }}
                />
                <M.Text sx={{ gridColumn: "span 3" }}>{element.title}</M.Text>
                <M.Text
                  weight={600}
                  sx={{ gridColumn: "span 3" }}
                  align="right"
                >
                  {props.isMoney
                    ? `$${element.value?.toFixed(2)}`
                    : `${element.value}`}
                </M.Text>
              </Fragment>
            )) ?? []}
          </M.SimpleGrid>
        </M.Stack>
      </M.Group>
      {props.children != null && props.children}
    </CardContainer>
  );
};
