import * as C from "@chakra-ui/react";
import { BoxProps } from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import React from "react";
import {
  FieldValues,
  FormProvider,
  SubmitHandler,
  useForm,
  UseFormProps,
} from "react-hook-form";
import { ZodTypeAny } from "zod";

export interface FormProps<
  TFieldValues extends FieldValues,
  TSchema extends ZodTypeAny
> extends UseFormProps<TFieldValues>,
    Omit<BoxProps, "onSubmit"> {
  onSubmit: SubmitHandler<TFieldValues>;
  children: React.ReactNode;
  schema?: TSchema;
}

export function Form<
  TFieldValues extends FieldValues,
  TSchema extends ZodTypeAny
>({
  onSubmit,
  children,
  schema,
  resolver,
  ...props
}: FormProps<TFieldValues, TSchema>) {
  const formMethods = useForm<TFieldValues>({
    ...props,
    resolver: (schema ? zodResolver(schema) : resolver) as any,
  });

  return (
    <C.Box as="form" onSubmit={formMethods.handleSubmit(onSubmit)} {...props}>
      <FormProvider {...formMethods}>
        <FormSchemaContext.Provider value={schema || null}>
          {children}
        </FormSchemaContext.Provider>
      </FormProvider>
    </C.Box>
  );
}

const FormSchemaContext = React.createContext<ZodTypeAny | null>(null);

export function useFormSchemaContext<TSchema extends ZodTypeAny | null>() {
  return React.useContext(FormSchemaContext) as TSchema;
}
