import { RouterOutputs } from "#/trpc";
import { LocationSection } from "./LocationSection";
import { OtherSection } from "./OtherSection";
import { VendorSection } from "./VendorSection";
import { Divider, Tabs } from "@mantine/core";

type Product = NonNullable<RouterOutputs["itemSku"]["getById"]>;

interface TabsInfoProps {
  itemSkuId: number;
  product?: Product | null;
}

export const TabsInfo = (props: TabsInfoProps) => {
  return (
    <>
      {props.product?.item?.description &&
      props.product?.item?.description !== "" ? (
        <>
          <Divider className="my-5" size={3} color="#E0E0E0" />
          <div className="flex flex-col">
            <p className="mb-3 text-lg font-semibold text-[#212121]">
              Description
            </p>
            <p className="text-sm font-normal text-[#212121]">
              {props.product?.item?.description ?? ""}
            </p>
          </div>
          <Divider className="my-5" size={3} color="#E0E0E0" />
        </>
      ) : null}
      <Tabs defaultValue="location">
        <Tabs.List grow position="center">
          <Tabs.Tab value="location">Location</Tabs.Tab>
          <Tabs.Tab value="vendor">Vendor</Tabs.Tab>
          <Tabs.Tab value="other">Other Information</Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="location">
          <LocationSection itemSkuId={props.itemSkuId} />
        </Tabs.Panel>
        <Tabs.Panel value="vendor">
          <VendorSection product={props.product} />
        </Tabs.Panel>
        <Tabs.Panel value="other">
          <OtherSection product={props.product} />
        </Tabs.Panel>
      </Tabs>
    </>
  );
};
