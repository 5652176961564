import { Button, PencilCustomIcon } from "#/components-ng";
import { trpc } from "#/trpc";
import { reportUserError, reportUserSuccess } from "#/util";
import { locationsAtom } from "../../state";
import * as M from "@mantine/core";
import { useSetAtom } from "jotai";
import { useParams } from "react-router";

type LocationSection = {
  itemSkuId?: number | null;
};

interface LocationState {
  filialId: number;
  filialName: string;
  storeLocation?: string | null;
  warehouseLocation?: string | null;
  reorder: number;
  isLine: boolean;
}

export function LocationSection(props: LocationSection) {
  const params = useParams();
  const setLocations = useSetAtom(locationsAtom);
  const [locationIndex, setLocationIndex] = useState<number | null>(null);
  const [locationState, setLocationState] = useState<LocationState | null>(
    null
  );

  const [itemSkuId, setItemSkuId] = useState<number | null>(() =>
    params.itemSkuId ? Number(params.itemSkuId) : props?.itemSkuId ?? null
  );

  const { data: allFilials } = trpc.filial.getAll.useQuery(undefined, {
    onSuccess: (data) => {
      if (data && !itemSkuId) {
        setLocations(
          data.map((filial) => ({
            filialId: filial.id,
            filialName: filial.name,
            storeLocation: "",
            warehouseLocation: "",
            reorder: 0,
            isLine: false,
          }))
        );
      }
    },
  });
  const { data: itemSkuStocksData } = trpc.itemSkuStock.getByItemSku.useQuery(
    {
      itemSkuId: itemSkuId!,
    },
    {
      cacheTime: 0,
      enabled: !!itemSkuId,
    }
  );

  const itemSkuStocks = useMemo(() => {
    if (itemSkuId) {
      return (
        allFilials?.map((filial) => {
          const itemSkuStock = itemSkuStocksData?.find(
            (itemSkuStock) => itemSkuStock.filialId === filial.id
          );
          if (itemSkuStock) {
            return {
              filialId: itemSkuStock.filialId,
              filialName: itemSkuStock.filial.name,
              storeLocation: itemSkuStock.storeLocation,
              warehouseLocation: itemSkuStock.warehouseLocation,
              reorder: itemSkuStock.reorder,
              isLine: itemSkuStock.isLine,
            };
          } else {
            return {
              filialId: filial.id,
              filialName: filial.name,
              storeLocation: "",
              warehouseLocation: "",
              reorder: 0,
              isLine: false,
            };
          }
        }) ?? []
      );
    }
    return (
      allFilials?.map((filial) => ({
        filialId: filial.id,
        filialName: filial.name,
        storeLocation: "",
        warehouseLocation: "",
        reorder: 0,
        isLine: false,
      })) ?? []
    );
  }, [allFilials, itemSkuId, itemSkuStocksData]);

  const { mutateAsync: updateItemSkuStock } =
    trpc.itemSkuStock.updateByItemSkuAndFilial.useMutation({
      onError: (err) => {
        reportUserError({
          title: "Failed to update item sku stock",
          message: err.message,
        });
      },
      onSuccess: (data) => {
        itemSkuStocks[locationIndex!] = {
          filialId: data.filialId,
          filialName: data.filial.name,
          storeLocation: data?.storeLocation,
          warehouseLocation: data?.warehouseLocation,
          reorder: data.reorder,
          isLine: data.isLine,
        };
        reportUserSuccess({
          title: "location has been updated",
        });
      },
    });

  const handleSaveLocation = async () => {
    if (locationState) {
      if (itemSkuId) {
        await updateItemSkuStock({
          filialId: locationState.filialId,
          itemSkuId: itemSkuId,
          input: {
            storeLocation: locationState.storeLocation,
            warehouseLocation: locationState.warehouseLocation,
            reorder: locationState.reorder,
            isLine: locationState.isLine,
          },
        });
      } else {
        setLocations((prev) => {
          const newLocations = [...prev];
          const filialLocationIndex = newLocations.findIndex(
            (l) => l.filialId === locationState.filialId
          );

          if (filialLocationIndex !== -1) {
            newLocations[filialLocationIndex] = {
              filialId: locationState.filialId,
              filialName: locationState.filialName,
              storeLocation: locationState.storeLocation
                ? locationState.storeLocation
                : newLocations[filialLocationIndex].storeLocation ?? "",
              warehouseLocation: locationState.warehouseLocation
                ? locationState.warehouseLocation
                : newLocations[filialLocationIndex].warehouseLocation ?? "",
              reorder: locationState.reorder
                ? locationState.reorder
                : newLocations[filialLocationIndex].reorder,
              isLine: locationState.isLine
                ? locationState.isLine
                : newLocations[filialLocationIndex].isLine,
            };
          }
          return newLocations;
        });

        itemSkuStocks[locationIndex!] = {
          filialId: locationState.filialId,
          filialName: locationState.filialName,
          storeLocation: locationState?.storeLocation
            ? locationState.storeLocation
            : itemSkuStocks[locationIndex!].storeLocation ?? "",
          warehouseLocation: locationState?.warehouseLocation
            ? locationState.warehouseLocation
            : itemSkuStocks[locationIndex!].warehouseLocation ?? "",
          reorder: locationState.reorder
            ? locationState.reorder
            : itemSkuStocks[locationIndex!].reorder,
          isLine: locationState.isLine
            ? locationState.isLine
            : itemSkuStocks[locationIndex!].isLine,
        };
      }
    }
    setLocationState(null);
    setLocationIndex(null);
  };

  return (
    <section>
      <M.Table className="mb-4 w-full" verticalSpacing="md" align="center">
        <thead>
          <tr>
            <th className="w-1/6">Filial</th>
            <th className="w-1/6">Store Location</th>
            <th className="w-1/6">Warehouse Location</th>
            <th className="w-1/6">Reorder</th>
            <th className="w-1/6">Is line?</th>
            <th className="w-1/6"></th>
          </tr>
        </thead>
        <tbody>
          {itemSkuStocks.map((itemSkuStock, index) => {
            const isEditing = locationIndex === index;

            return (
              <tr
                key={itemSkuStock.filialId}
                className="items-center justify-center"
              >
                <td>{itemSkuStock.filialName}</td>
                <td>
                  {isEditing ? (
                    <M.TextInput
                      placeholder="Type Store Location"
                      defaultValue={itemSkuStock?.storeLocation ?? ""}
                      onChange={(e) => {
                        setLocationState(
                          (prev) =>
                            prev && {
                              ...prev,
                              storeLocation: e.target.value,
                            }
                        );
                      }}
                    />
                  ) : (
                    itemSkuStock?.storeLocation ?? ""
                  )}
                </td>
                <td>
                  {isEditing ? (
                    <M.TextInput
                      placeholder="Type Warehouse Location"
                      defaultValue={itemSkuStock?.warehouseLocation ?? ""}
                      onChange={(e) => {
                        setLocationState(
                          (prev) =>
                            prev && {
                              ...prev,
                              warehouseLocation: e.target.value,
                            }
                        );
                      }}
                    />
                  ) : (
                    itemSkuStock?.warehouseLocation ?? ""
                  )}
                </td>
                <td>
                  {isEditing ? (
                    <M.NumberInput
                      defaultValue={itemSkuStock?.reorder}
                      onChange={(value) => {
                        setLocationState(
                          (prev) =>
                            prev && {
                              ...prev,
                              reorder: Number(value),
                            }
                        );
                      }}
                      min={0}
                      step={1}
                    />
                  ) : (
                    itemSkuStock?.reorder
                  )}
                </td>
                <td>
                  {isEditing ? (
                    <M.Checkbox
                      checked={locationState?.isLine ?? false}
                      onChange={(e) => {
                        setLocationState(
                          (prev) =>
                            prev && {
                              ...prev,
                              isLine: e.target.checked,
                            }
                        );
                      }}
                    />
                  ) : (
                    <M.Checkbox checked={itemSkuStock?.isLine} readOnly={true} />
                  )}
                </td>
                <td className="flex items-center justify-center gap-2">
                  <M.Tooltip label="edit">
                    <M.ActionIcon
                      onClick={() => {
                        setLocationIndex(index);
                        setLocationState({
                          filialId: itemSkuStock.filialId,
                          filialName: itemSkuStock.filialName,
                          isLine: itemSkuStock.isLine,
                          reorder: itemSkuStock.reorder,
                        });
                      }}
                    >
                      <PencilCustomIcon className="h-4 w-4" />
                    </M.ActionIcon>
                  </M.Tooltip>
                  {isEditing && (
                    <Button variant="primary" onClick={handleSaveLocation}>
                      Save Location
                    </Button>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </M.Table>
    </section>
  );
}
