import { cn } from "#/lib/utils.js";
import { DetailedHTMLProps, HTMLAttributes, Ref, forwardRef } from "react";

export type InputWrapperProps = {
  className?: string;
  children?: React.ReactNode;
  leftIcon?: React.ReactNode;
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

function InputWrapper(props: InputWrapperProps, ref: Ref<HTMLDivElement>) {
  const { className, children, leftIcon, ...divProps } = props;

  return (
    <div
      ref={ref}
      className={cn(
        "relative flex items-center",
        leftIcon && "[&>input]:pl-9",
        className
      )}
      {...divProps}
    >
      {leftIcon && (
        <div className="absolute ml-2 text-slate-400">{props.leftIcon}</div>
      )}
      {children}
    </div>
  );
}

const InputWrapperWithRef = forwardRef(InputWrapper);

export { InputWrapperWithRef as InputWrapper };
