import { RouterInputs, RouterOutputs, trpc } from "#/trpc.js";
import { reportUserError, reportUserSuccess } from "#/util/index.js";
import { Discounts } from "../User/Discounts.js";
import {
  AddressInformation,
  PersonalInformation,
  SecondaryInformation,
} from "../User/User.js";
import * as C from "@chakra-ui/react";
import * as M from "@mantine/core";
import dayjs from "dayjs";
import { FormProvider, useForm } from "react-hook-form";

type InputUser = RouterInputs["user"]["register"];
type User = RouterOutputs["user"]["register"];

export interface EditCustomerProps {
  customerId: number;
  customer: InputUser;
  onCancel: () => void;
  onSuccess: (user: User) => void;
}

export function EditCustomer({
  customerId,
  customer,
  onCancel,
  onSuccess,
}: EditCustomerProps) {
  const ctx = trpc.useContext();
  const form = useForm({
    defaultValues: {
      ...customer,
      address: customer.country
        ? {
            address: customer.street,
            city: customer.city,
            state: customer.state,
            country: customer.country,
            zip: customer.zip,
            poBox: customer.poBox,
            apt: customer.apt,
          }
        : undefined,
      birthDate:
        customer?.birthDate && dayjs(customer.birthDate).isValid()
          ? dayjs(customer.birthDate).toISOString().slice(0, 10)
          : undefined,
    },
  });

  const { mutate: updateUser } = trpc.user.update.useMutation({
    onSuccess(data) {
      reportUserSuccess({
        title: "Customer updated successfully",
        message: (
          <>
            Customer{" "}
            <span className="font-medium">
              {data.firstName} {data.lastName}
            </span>{" "}
            updated successfully
          </>
        ),
      });
      ctx.user.invalidate();
      onSuccess(data);
    },
    onError(error) {
      reportUserError({
        title: "Failed to get customer",
        message: error.message,
      });
    },
  });

  const [isAddressRequired, setIsAddressRequired] = useState(false);
  const toast = C.useToast();

  const handleSubmit = useCallback(
    (newCustomer) => {
      try {
        updateUser({
          id: customerId,
          input: {
            role: {
              connect: {
                id: customer!.roleId,
              },
            },
            firstName: newCustomer.firstName,
            lastName: newCustomer.lastName,
            email: newCustomer.email,
            phoneNumber: newCustomer.phoneNumber,
            areaCode: newCustomer.areaCode,
            countryCode: newCustomer.countryCode,
            birthDate:
              newCustomer.birthDate && dayjs(newCustomer.birthDate).isValid()
                ? dayjs(newCustomer.birthDate).toDate()
                : undefined,
            street: isAddressRequired
              ? newCustomer.address?.address
              : undefined,
            city: isAddressRequired ? newCustomer.address?.city : undefined,
            state: isAddressRequired ? newCustomer.address?.state : undefined,
            zip: isAddressRequired ? newCustomer.address?.zip : undefined,
            country: isAddressRequired
              ? newCustomer.address?.country
              : undefined,
            apt: isAddressRequired ? newCustomer.address?.apt : undefined,
            poBox: isAddressRequired ? newCustomer.address?.poBox : undefined,
            verified: newCustomer.verified,
            taxable: newCustomer.taxable,
            note: newCustomer.note,
            company: newCustomer.company ?? null,
            //TODO(v2): add auth pin
            // pin: newCustomer.pin ?? null,
            nickName: newCustomer.nickName ?? null,
            avatar: newCustomer.avatar ?? null,
            taxDocument: newCustomer.taxDocument ?? null,
            ein: newCustomer.ein ?? null,
            preferredSalesAssociated: newCustomer.preferredSalesAssociatedId
              ? {
                  connect: {
                    id: newCustomer.preferredSalesAssociatedId,
                  },
                }
              : undefined,
          },
        });
      } catch (e) {
        toast({
          title: e.cause,
          description: e.message,
          status: "error",
          duration: 6000,
          isClosable: true,
        });
      }
    },
    [customer, customerId, isAddressRequired, toast, updateUser]
  );

  return (
    <M.Accordion
      styles={{ item: { border: "none" } }}
      defaultValue="customer-form"
    >
      <M.Accordion.Item value="customer-form">
        <M.Accordion.Control>
          <M.Title order={2}>Edit customer</M.Title>
        </M.Accordion.Control>
        <M.Accordion.Panel>
          <M.Box
            component="form"
            onSubmit={form.handleSubmit(handleSubmit)}
            sx={{ width: "100%" }}
          >
            <FormProvider {...form}>
              <C.VStack spacing={6} divider={<C.StackDivider />} w="100%">
                <PersonalInformation isCustomer={true} />
                <Discounts id={customerId} />
                <M.Title order={3}>Secondary Information</M.Title>
                <M.Group noWrap align="start">
                  <AddressInformation onRequiredChange={setIsAddressRequired} />
                  <M.Divider color="gray.2" orientation="vertical" mx={32} />
                  <SecondaryInformation />
                </M.Group>
                <C.HStack w="100%" spacing={4} justify="end">
                  <C.Button w="12ch" type="submit">
                    Save
                  </C.Button>
                  <C.Button variant="secondary" onClick={onCancel}>
                    Cancel
                  </C.Button>
                </C.HStack>
              </C.VStack>
            </FormProvider>
          </M.Box>
        </M.Accordion.Panel>
      </M.Accordion.Item>
    </M.Accordion>
  );
}
