import { BadgeDetails, SearchableInput, WidgetContainer } from "./common.js";
import { endOfDayAtom } from "./state.js";
import * as M from "@mantine/core";
import { useAtomValue } from "jotai";
import {
  MRT_ColumnDef,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import { MdFilterAlt } from "react-icons/md/index.js";

const movementTypes = ["SOLD", "RETURNED"];

interface FilterMovementTypes {
  SOLD: boolean;
  RETURNED: boolean;
}

export const InventoryMovement = () => {
  const endOfDay = useAtomValue(endOfDayAtom);
  const [filterInventory, setFilterInventory] = useState<FilterMovementTypes>({
    SOLD: true,
    RETURNED: true,
  });
  const [query, setQuery] = useState<string | null>("");

  const tableData = useMemo(() => {
    const inv = endOfDay.inventoryMovement;
    const productsMap = {};

    if (filterInventory && filterInventory.SOLD)
      inv.noReturned.products.forEach((entry) => {
        productsMap[entry.item.id] = {
          ...entry.item,
          ...productsMap[entry.item.id],
          soldQty: entry.quantity + (productsMap[entry.item.id]?.soldQty ?? 0),
        };
      });

    if (filterInventory && filterInventory.RETURNED)
      inv.returned.products.forEach((entry) => {
        productsMap[entry.item.id] = {
          ...entry.item,
          ...productsMap[entry.item.id],
          returnedQty:
            entry.quantity + (productsMap[entry.item.id]?.returnedQty ?? 0),
        };
      });

    return Object.values(productsMap)
      .filter(
        (p: any) =>
          p.sku.toString().includes(query?.toLowerCase()) ||
          p.title.toLowerCase().includes(query?.toLowerCase())
      )
      .map((p: any) => ({
        ...p,
        soldQty: p.soldQty ?? 0,
        returnedQty: p.returnedQty ?? 0,
      }));
  }, [endOfDay.inventoryMovement, filterInventory, query]);

  const table = useMantineReactTable({
    data: tableData,
    columns: columns,
    initialState: { density: "xs" },
    enableColumnFilters: false,
    enableTopToolbar: false,
    enableFilters: false,
  });

  return (
    <WidgetContainer>
      <M.Stack>
        <M.Title order={3} mb={10} color="#3D3D3D">
          Inventory movement
        </M.Title>
        <M.Group position="left" mb={20}>
          <BadgeDetails
            title="Count"
            total={endOfDay.inventoryMovement.count}
            isMoney={false}
          />
          <BadgeDetails
            title="Returned"
            total={
              endOfDay.inventoryMovement.returned.totalCountProductsReturned
            }
            isMoney={false}
          />
          <BadgeDetails
            title="Sold"
            total={
              endOfDay.inventoryMovement.noReturned.totalCountProductsTransacted
            }
            isMoney={false}
          />
        </M.Group>
        <M.Divider />
        <M.Group spacing="md">
          <SearchableInput
            placeholder="Search product"
            onInput={(e) => setQuery(e.currentTarget.value)}
          />
          <M.Popover zIndex={9000}>
            <M.Popover.Target>
              <M.Button
                variant="light"
                color="dark"
                bg="gray.2"
                leftIcon={<MdFilterAlt />}
              >
                Filter
              </M.Button>
            </M.Popover.Target>
            <M.Popover.Dropdown>
              {
                <M.Stack>
                  {movementTypes.map((type) => (
                    <M.Checkbox
                      key={type}
                      label={type}
                      checked={filterInventory?.[type]}
                      onChange={() =>
                        setFilterInventory((old) => ({
                          ...old,
                          [type]: !old?.[type],
                        }))
                      }
                    />
                  ))}
                </M.Stack>
              }
            </M.Popover.Dropdown>
          </M.Popover>
        </M.Group>
        <MantineReactTable table={table} />
      </M.Stack>
      <M.LoadingOverlay visible={endOfDay.loading} zIndex={9999} />
    </WidgetContainer>
  );
};

const columns: Array<
  MRT_ColumnDef<{
    sku: string;
    title: string;
    soldQty: number;
    returnedQty: number;
  }>
> = [
  {
    header: "SKU",
    accessorKey: "sku",
  },
  {
    header: "Title",
    accessorKey: "title",
  },
  {
    header: "Sold qty",
    accessorKey: "soldQty",
  },
  {
    header: "Return qty",
    accessorKey: "returnedQty",
  },
];
