import styled from "styled-components";

export const TextContainer = styled.div``;

export const TextComponent = styled.p`
  font-weight: ${({ fontWeight }) => fontWeight};
  font-size: ${({ fontSize }) => fontSize};
  text-align: ${({ align }) => align};
  color: ${({ theme, color }) => theme[`${color}`]};
  text-transform: ${({ textTransform }) => textTransform};
  letter-spacing: 0.35px;
  font-family: "Montserrat", sans serif;
  margin: 0;
`;
