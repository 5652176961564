import clsx, { ClassValue } from "clsx/dist/clsx.m.js";
import { twMerge } from "tailwind-merge";

export function cn(...c: ClassValue[]) {
  return twMerge(clsx(...c));
}

export function cx(...c: ClassValue[]) {
  return clsx(...c);
}

export function tx(...c: ClassValue[]) {
  return twMerge(clsx(...c));
}

/**
 * Upserts an item into an array by either updating an existing item or appending a new one.
 *
 * This function always returns a new array.
 *
 * @param array - The array to upsert into.
 * @param predicate - The function used to determine whether the item exists in the array.
 * @param item - The item to upsert into the array.
 * @return The updated array with the upserted item.
 */
export function upsert<T>(
  array: Array<T>,
  predicate: (item: T) => boolean,
  item: T
): Array<T> {
  const index = array.findIndex(predicate);
  if (index >= 0) {
    const newArray = [...array];
    newArray[index] = item;
    return newArray;
  } else {
    return [...array, item];
  }
}
