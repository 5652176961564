import { EllipsisHorizontal, SpinnerIcon } from "#/components-ng";
import { trpc } from "#/trpc";
import { reportUserError } from "#/util";
import {
  MantineReactTable,
  MRT_ColumnDef,
  useMantineReactTable,
} from "mantine-react-table";
import {
  FormValues,
  createCustomAttributeSchema,
  C,
  customAttributeTypes,
} from "./types";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Container, Divider, MultiSelect, Select } from "@mantine/core";
import { DefaultValues, FormProvider, SubmitHandler } from "react-hook-form";
import { useParams } from "react-router";
import { Dropdown } from "@gt/ui";
import { IconButton } from "@radix-ui/themes";
import { styled } from "#/css/jsx";
import placeholderImage from "#/placeholder-image.jpg";

interface ItemSku {
  id: number;
  sku: number;
  title: string;
  defaultImage?: string | null;
}

export interface CustomAttributeFormProps {
  defaultValues?: DefaultValues<FormValues>;
  onSubmit: SubmitHandler<FormValues>;
  isLoading: boolean;
}

export const CustomAttributesForm = ({
  defaultValues,
  isLoading,
  onSubmit,
}: CustomAttributeFormProps) => {
  const [options, setOptions] = useState<
    {
      value: string;
      label: string;
    }[]
  >(
    defaultValues?.options?.map((option) => ({
      value: option!,
      label: option!,
    })) ?? [],
  );

  const params = useParams() as any;

  const form = C.useForm({
    resolver: zodResolver(createCustomAttributeSchema),
    shouldUnregister: false,
    defaultValues: {
      name: "",
      description: "",
      type: "TEXT",
      options: [],
      textLimit: 9,
      ...defaultValues,
    },
  });

  const itemSkus = form.watch("multiSelectItems");

  const table = useMantineReactTable({
    data: itemSkus ?? [],
    columns: columns,
    initialState: { density: "xs" },
  });

  const type = form.watch("type");

  function addItemSkus(itemSkus: ItemSku[]) {
    const currentItemSkus = form.getValues("multiSelectItems");
    const newItemSkus = [...(currentItemSkus ?? []), ...itemSkus];
    const newItemSkusMap = new Map(newItemSkus.map((isku) => [isku.sku, isku]));
    const deduplicatedItemSkus = Array.from(newItemSkusMap.values());
    form.setValue("multiSelectItems", deduplicatedItemSkus);
  }

  return (
    <Container size="xl">
      <div className="mb-10 rounded-md border border-[#E0E0E0] bg-white p-10">
        <h1 className="mb-6 text-3xl font-semibold text-slate-800">
          {params?.customAttributeId ? "Edit attribute" : "Create attribute"}
        </h1>
        <Divider className="my-8 stroke-[#E0E0E0]" />
        <FormProvider {...form}>
          <form
            onSubmit={(e) => {
              e.stopPropagation();
              form.handleSubmit(onSubmit)(e);
            }}
          >
            <div className="grid w-full gap-8 p-2 lg:w-[400px]">
              <C.InputField
                label="Attribute name *"
                name="name"
                placeholder="Enter attribute name"
                required
              />
              <C.SelectField
                label="Select an option *"
                name="type"
                placeholder="Select an option"
                required
                data={customAttributeTypes.map((type) => ({
                  id: type,
                  label: type,
                }))}
              />
              {type === "TEXT" && (
                <C.NumberInputField
                  label="Text limit"
                  name="textLimit"
                  placeholder="Enter text limit"
                  step={1}
                  defaultValue={9}
                  min={1}
                />
              )}
              {type === "SELECT" && (
                <C.M
                  as={MultiSelect}
                  data={options}
                  label="Create your options to select"
                  name="options"
                  placeholder="Enter text limit"
                  searchable
                  creatable
                  getCreateLabel={(query) => `+ ${query}`}
                  onCreate={(query) => {
                    const item = { value: query, label: query };
                    setOptions((current) => [...current, item]);
                    return item;
                  }}
                />
              )}
              {type === "MULTISELECT_ITEM" && (
                <>
                  <ItemSkusSearchField onSelect={addItemSkus} />
                  <MantineReactTable table={table} />
                </>
              )}
              <C.TextareaField
                label="Description"
                name="description"
                placeholder="Enter description"
              />
              <div className="flex w-full lg:justify-start">
                <Button
                  className="mt-2 px-12 "
                  type="submit"
                  loading={isLoading}
                >
                  Save attribute
                </Button>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </Container>
  );
};

interface SearchableFieldProps {
  onSelect: (itemSkus: Array<ItemSku>) => void;
}

const ItemSkusSearchField = (props: SearchableFieldProps) => {
  const [query, setQuery] = useState<string>("");
  const { data, isLoading } = trpc.itemSku.pos.search.useQuery(
    {
      query: `\\"${query}\\"`,
      limit: 10,
    },
    {
      onError(error) {
        reportUserError({
          title: "Failed to get item skus",
          message: error.message,
        });
      },
    },
  );

  const selectData = useMemo(
    () =>
      data?.map((e) => ({
        label: `${e.sku} - ${e.title}`,
        value: e.id.toString(),
        itemSku: e,
        // ^ Add the whole itemSku so that we can refer to it on selection
      })) ?? [],
    [data],
  );

  const handleSelect = (itemSku: ItemSku) => {
    props.onSelect([itemSku]);
  };

  const handleChange = (selectedId: string | null) => {
    if (selectedId == null) return;

    const selectedEntry = selectData.find((e) => e.value === selectedId);
    if (!selectedEntry) {
      throw new Error("selected non existing data");
      // ^ Theoretically, this should never happen.
    }

    handleSelect(selectedEntry.itemSku);
  };

  return (
    <Select
      data={selectData}
      searchable
      placeholder="Search by item sku..."
      rightSection={isLoading && <SpinnerIcon />}
      value={null}
      // ^ We only use this select as search field, so we don't care about
      // showing a value.
      onChange={handleChange}
      onSearchChange={setQuery}
      filter={() => true}
    />
  );
};

const columns: MRT_ColumnDef<ItemSku>[] = [
  {
    id: "actions",
    header: "Actions",
    Cell(table) {
      const form = C.useFormContext();

      const handleDelete = () => {
        const itemSkus = form.getValues("multiSelectItems");
        const index = itemSkus.findIndex(
          (itemSku: ItemSku) => itemSku.id === table.row.original.id,
        );

        if (index !== -1) {
          form.setValue(
            "multiSelectItems",
            itemSkus.filter(
              (itemSku: ItemSku) => itemSku.id !== table.row.original.id,
            ),
          );
        }
      };

      return (
        <Dropdown.Root>
          <Dropdown.Trigger>
            <styled.div
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <IconButton variant="ghost" color="gray" size="1">
                <EllipsisHorizontal />
              </IconButton>
            </styled.div>
          </Dropdown.Trigger>
          <Dropdown.Content>
            <Dropdown.Item onClick={handleDelete}>Delete</Dropdown.Item>
          </Dropdown.Content>
        </Dropdown.Root>
      );
    },
  },
  {
    id: "image",
    accessorKey: "defaultImage",
    header: "Image",
    Cell(props) {
      const image = props.row.original.defaultImage ?? placeholderImage;

      return (
        <img
          src={image}
          alt=""
          className="aspect-square size-[48px] object-cover"
        />
      );
    },
  },
  {
    accessorKey: "sku",
    header: "SKU",
  },
  {
    accessorKey: "title",
    header: "Title",
  },
];
