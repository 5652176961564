import { atom } from "jotai";

export interface Message {
  id?: string;
  title: string;
  description?: string;
}

export const reportAtom = atom({
  error: (msg: Message) => console.error(msg),
  warning: (msg: Message) => console.warn(msg),
  success: (msg: Message) => console.log(msg),
  errorConfirmation: (msg: Message) => console.log(msg),
});
