import { EllipsisHorizontal } from "#/components-ng"
import { useAuth } from "#/context/AuthContext"
import { printTicketFromOrderV2 } from "#/modules/ticket/print"
import { RouterOutputs, trpc } from "#/trpc"
import { reportUserError, reportUserSuccess } from "#/util"
import { TicketPreview } from "../SalesHistory/ticket-preview"
import { exportInvoiceToXLSX } from "../SalesHistory/export-invoice"
import { FilterKey } from "./$common"
import { ShippingInfoDrawer } from "./ShippingInfoDrawer"
import { PickupDetailsDrawer } from "./pickup-details-drawer"
import { Button, Dropdown } from "@gt/ui"
import { Modal, Select } from "@mantine/core"
import { openConfirmModal } from "@mantine/modals"
import dayjs from "dayjs"
import timezone from "dayjs/plugin/timezone.js";
import utc from "dayjs/plugin/utc.js";
import FileSaver from "file-saver"
import {
	MantineReactTable,
	useMantineReactTable,
	MRT_ColumnDef,
	MRT_SortingState,
	MRT_ColumnFiltersState,
} from "mantine-react-table"
import { nanoid } from "nanoid"
import { Link } from "react-router-dom"
import { IconButton, Tooltip } from "@radix-ui/themes"
import { MdOutlineDownload } from "react-icons/md"
import { css } from "#/css/css"
import { CheckIcon } from "lucide-react"

dayjs.extend(utc);
dayjs.extend(timezone);

type EcommerceOrder =
	RouterOutputs["order"]["pos"]["ecommerceOrders"]["table"]["entries"][number]

export interface EcommerceOrdersProps {
	filter: Array<FilterKey>
	search?: string | null
	dateRange: [Date | null, Date | null]
}

export function EcommerceOrdersTable(props: EcommerceOrdersProps) {
	const [{ auth }] = useAuth()
	const [pagination, setPagination] = useState({
		pageIndex: 0,
		pageSize: 10,
	})
	const [sorting, setSorting] = useState<MRT_SortingState>([])
	// Filtering
	const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([])

	const queryFilters = useMemo(() => {
		const filters: {
			associated?: number | null
			unassisted?: boolean | null
			orderType?: "ONLINE" | "PICKUP" | null
		} = {}

		for (const columnFilter of columnFilters) {
			if (columnFilter.id === "associated") {
				if (columnFilter.value === "unassisted") {
					filters.associated = null
					filters.unassisted = true
				} else if (columnFilter.value) {
					filters.associated = Number(columnFilter.value)
					filters.unassisted = false
				}
			}

			if (columnFilter.id === "orderType") {
				filters.orderType = columnFilter.value
					? (columnFilter.value as "ONLINE" | "PICKUP")
					: null
			}
		}

		return filters
	}, [columnFilters])

	const { data } = trpc.order.pos.ecommerceOrders.table.useQuery(
		{
			pageIndex: pagination.pageIndex,
			pageSize: pagination.pageSize,
			filters: {
				status: props.filter,
				rangeDate: {
					start: props.dateRange[0]!,
					end: props.dateRange[1]!,
				},
				associated: queryFilters.associated,
				unassisted: queryFilters.unassisted,
        orderType: queryFilters.orderType,
			},
			filialId: auth?.user?.filialId,
			search: props.search,
		},
		{
			keepPreviousData: true,
			enabled: props.dateRange[0] !== null && props.dateRange[1] !== null,
		},
	)

	const { mutate: exportCsv, isLoading: isLoadingExport } =
		trpc.v2_5.order.exportEcommerceOrdersTableToCsv.useMutation({
			onSuccess: () => {
				reportUserSuccess({
					title: "The csv file has been sent to email",
				})
			},
		})

	const handleExportCsv = () => {
		exportCsv({
			pageIndex: pagination.pageIndex,
			pageSize: pagination.pageSize,
			filters: {
				status: props.filter,
				rangeDate: {
					start: props.dateRange[0]!,
					end: props.dateRange[1]!,
				},
				associated: queryFilters.associated,
				unassisted: queryFilters.unassisted,
			},
			filialId: auth?.user?.filialId,
			search: props.search,
		})
	}

	const table = useMantineReactTable({
		data: data?.entries ?? [],
		columns,
		manualPagination: true,
		onPaginationChange: setPagination,
		onSortingChange: setSorting,
		enableTopToolbar: false,
		rowCount: data?.totalEntries ?? 0,
		// enableSorting: false,
		enableFilters: true,
		manualFiltering: true,
		onColumnFiltersChange: setColumnFilters,
		state: {
			pagination,
			sorting,
			columnFilters,
		},
		initialState: {
			showColumnFilters: true,
		},
		renderBottomToolbarCustomActions: () => (
			<div
				className={css({
					display: "flex",
					alignItems: "center",
					gap: 2,
				})}
			>
				<Tooltip content="Export to CSV">
					<IconButton
						color="gray"
						variant="soft"
						loading={isLoadingExport}
						onClick={handleExportCsv}
					>
						<MdOutlineDownload />
					</IconButton>
				</Tooltip>
			</div>
		),
	})
	return <MantineReactTable table={table} />
}

const columns: Array<MRT_ColumnDef<EcommerceOrder>> = [
	{
		id: "actions",
		header: "Actions",
    size: 100,
		enableSorting: false,
		enableColumnFilter: false,
		Cell: (table) => {
			const [openShippingDetails, setOpenShippingDetails] = useState(false)
			const [pickupDetailsOpened, setPickupDetailsOpened] = useState(false)
			const [reprintEpson, setReprintEpson] = useState<{
				enabled: boolean
				includeLocation?: boolean
			}>({ enabled: false })
			const [exportToXlsx, setExportToXlsx] = useState(false)
			const [previewOpened, setPreviewOpened] = useState(false)
			const [enabledFecth, setEnabledFetch] = useState(false)
			const { data, isLoading } = trpc.order.getById.useQuery(
				{
					id: table.row.original.id,
				},
				{
					enabled: enabledFecth,
					onSuccess(data) {
						if (exportToXlsx) {
							exportInvoiceToXLSX(data as any)
							setExportToXlsx(false)
							setEnabledFetch(false)
						}
						if (reprintEpson.enabled) {
							printTicketFromOrderV2({
								order: data!,
								withLocation: !!reprintEpson.includeLocation,
							})
							setReprintEpson({ enabled: false })
							setEnabledFetch(false)
						}
						setEnabledFetch(false)
					},
					onError(err) {
						reportUserError({
							title: "Failed to get order",
							message: err.message,
						})
					},
				},
			)

			const ctx = trpc.useContext()
			const [idempotencyKey] = useState(() => nanoid())

			const { mutate: refundAllOrder } =
				trpc.v2_5.orderReturn.returnAll.useMutation({
					onSuccess() {
						ctx.order.invalidate()
					},
				})

			const pin = useRef<"SQUARE" | "CREDITS" | "STRIPE">()

			const openConfirmReturnAllModal = () =>
				openConfirmModal({
					title: "Are you sure you want to return all items?",

					children: (
						<Select
							required
							label="Return Method"
							onChange={(e) => (e ? (pin.current = e as any) : null)}
							data={[
								{
									label: "Credits",
									value: "CREDITS",
								},
								{
									label: "Square",
									value: "SQUARE",
								},
								{
									label: "Stripe",
									value: "STRIPE",
								},
							]}
							dropdownPosition="bottom"
							withinPortal
						/>
					),
					labels: {
						confirm: "Return All",
						cancel: "Cancel",
					},
					confirmProps: { color: "red" },
					onConfirm: () => {
						if (pin.current) {
							refundAllOrder({
								id: table.row.original.id,
								idempotencyKey: idempotencyKey,
								method: pin.current,
							})
						}
					},

					lockScroll: true,
				})

			const { mutate: exportQr } = trpc.v2_5.order.exportLabel.useMutation({
				onError(err) {
					reportUserError({
						title: "Failed to export QR",
						message: err.message,
					})
				},
				async onSuccess(data) {
					FileSaver.saveAs(data.url, "order_qr.pdf")

					reportUserSuccess({
						title: "QR exported",
						message: "QR exported successfully",
					})
				},
			})

			return (
				<>
					<ShippingInfoDrawer
						isLoading={isLoading}
						open={openShippingDetails}
						setOpen={setOpenShippingDetails}
						data={data}
					/>
					<PickupDetailsDrawer
						isLoading={isLoading}
						open={pickupDetailsOpened}
						setOpen={setPickupDetailsOpened}
						data={data}
					/>
					<Dropdown.Root>
						<Dropdown.Trigger asChild>
							<Button
								variant="ghost"
								className="relative size-[24px] bg-transparent p-1 text-slate-400"
							>
								<EllipsisHorizontal />
							</Button>
						</Dropdown.Trigger>
						<Dropdown.Content>
							<Dropdown.Item asChild>
								<Link to={`${table.row.original.id}`}>Edit</Link>
							</Dropdown.Item>
							{table.row.original.orderType === "PICKUP" && (
								<Dropdown.Item
									onClick={() => {
										setEnabledFetch(true)
										setPickupDetailsOpened(true)
									}}
								>
									View pick up info
								</Dropdown.Item>
							)}
							{table.row.original.orderType === "ONLINE" && (
								<Dropdown.Item
									onClick={() => {
										setEnabledFetch(true)
										setOpenShippingDetails(true)
									}}
								>
									<span>View shipping info</span>
								</Dropdown.Item>
							)}
							<Dropdown.Item asChild>
								<Link
									to={`/sales/sales-history/invoice/${table.row.original.id}`}
									target="_blank"
								>
									Export to PDF
								</Link>
							</Dropdown.Item>
							<Dropdown.Item
								onClick={async () => {
									setEnabledFetch(true)
									setExportToXlsx(true)
								}}
							>
								Export to XLSX
							</Dropdown.Item>
							<Dropdown.Item
								onClick={async () => {
									setEnabledFetch(true)
									setReprintEpson({ enabled: true })
								}}
							>
								Re-print (Epson)
							</Dropdown.Item>
							<Dropdown.Item
								onClick={async () => {
									setEnabledFetch(true)
									setReprintEpson({ enabled: true, includeLocation: true })
								}}
							>
								Re-print with location (Epson)
							</Dropdown.Item>
							<Dropdown.Item
								onClick={async () => {
									setEnabledFetch(true)
									setPreviewOpened(true)
								}}
							>
								Preview receipt
							</Dropdown.Item>
							{!["CANCELLED", "REVERSED", "FAILED"].includes(
								table.row.original.orderStatus,
							) && (
								<Dropdown.Item onClick={openConfirmReturnAllModal}>
									Cancel order
								</Dropdown.Item>
							)}
							<Dropdown.Item
								onClick={() => {
									exportQr({
										id: table.row.original.id,
                    includeQr: true,
									})
								}}
							>
								Print QR
							</Dropdown.Item>
						</Dropdown.Content>
					</Dropdown.Root>
					<Modal
						opened={previewOpened}
						onClose={() => setPreviewOpened(false)}
						withCloseButton={false}
					>
						{data && <TicketPreview order={data} />}
					</Modal>
				</>
			)
		},
	},
	{
		id: "Customer name",
		header: "Customer name",
		enableSorting: false,
		enableColumnFilter: false,
		Cell: (table) => {
			let fullName = ""
			const customer = table.row.original.customer
			const userBasicInfo = table.row.original.userBasicInfo
			if (customer != null) {
				fullName += customer.firstName
				if (customer.lastName) {
					fullName += ` ${customer.lastName}`
				}
			} else if (userBasicInfo) {
				fullName += userBasicInfo.firstName
				if (userBasicInfo.lastName) {
					fullName += ` ${userBasicInfo.lastName}`
				}
			}

			return (
				<p>
					{fullName}
					{!customer && <span className="text-red-300"> (Guest)</span>}
				</p>
			)
		},
	},
	{
		accessorKey: "$computed.isFirstOrder",
		header: "1st order?",
		enableSorting: false,
		enableColumnFilter: false,
    size: 100,
		Cell: (table) => {
			return (
				<div>{table.row.original.$computed.isFirstOrder && <CheckIcon />}</div>
			)
		},
	},
	{
		header: "Is guest?",
		id: "isGuest",
		accessorKey: "$computed.isGuest",
		enableColumnFilter: false,
		enableSorting: true,
    size: 100,
		Cell: (table) => {
			return <div>{table.row.original.$computed.isGuest && <CheckIcon />}</div>
		},
	},
	{
		accessorKey: "receiptNumber",
		header: "Order #",
		enableSorting: true,
		enableColumnFilter: false,
	},
	{
		id: "orderType",
		accessorKey: "orderType",
		header: "Order type",
		enableSorting: false,
		filterVariant: "select",
		mantineFilterSelectProps() {
			const options = [
				{ label: "ONLINE", value: "ONLINE" },
				{ label: "PICKUP", value: "PICKUP" },
			]

			return {
				data: options,
				className: "border-b-0 mt-1 font-normal",
			}
		},
	},
	{
		id: "associated",
		header: "Associate",
		accessorKey: "associated",
		enableSorting: false,
		Cell: (table) => {
			const associatedFullName = `${
				table.row.original.associated?.firstName ?? ""
			} ${table.row.original.associated?.lastName ?? ""}`

			return <p>{associatedFullName}</p>
		},
		filterVariant: "select",
		mantineFilterSelectProps() {
			const { data } = trpc.v2_5.user.getAssociateds.useQuery()

			const options =
				data
					?.map((user) => ({
						label: `${user.firstName} ${user?.lastName ?? ""}`,
						value: user.id.toString(),
					}))
					.concat({
						label: "Unassisted",
						value: "unassisted",
					}) ?? []

			return {
				data: options,
				className: "border-b-0 mt-1 font-normal",
			}
		},
	},
  {
		id: "total",
		header: "Total",
		accessorKey: "total",
		Cell: (table) => {
			const total = table.row.original.total
			if (!total) return "$0.00"
			return `$${total.toNumber().toFixed(2)}`
		},
		enableSorting: true,
		enableColumnFilter: false,
	},
	{
		accessorKey: "orderStatus",
		header: "Status",
		enableSorting: false,
		enableColumnFilter: false,
	},
	{
		id: "Filial name",
		header: "Filial name",
		Cell: (table) => {
			const filialName = table.row.original.filial?.name
			if (!filialName) return ""
			return filialName
		},
		enableSorting: false,
		enableColumnFilter: false,
	},
	{
		id: "createdAt",
		header: "Created at",
		accessorKey: "createdAt",
		Cell: (table) => {
			const createdAt = table.row.original.createdAt
			if (!createdAt) return ""
			return dayjs(createdAt).tz("America/New_York").format("MM/DD/YYYY HH:MM").toString()
		},
		enableSorting: true,
		enableColumnFilter: false,
	},
]
