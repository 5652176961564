import { atom } from "jotai";

interface State {
  addFeesByWeight: boolean;
}

export const stateAtom = atom<State>({
  addFeesByWeight: false,
});

export const addFeesByWeightAtom = atom(
  (get) => get(stateAtom).addFeesByWeight,
  (get, set, addFeesByWeight: boolean) => {
    set(stateAtom, { ...get(stateAtom), addFeesByWeight });
  },
);