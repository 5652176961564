import { useAuth } from "#/context/AuthContext.js";
import { RouterOutputs, trpc } from "#/trpc.js";
import { reportUserError } from "#/util/index.js";
import { getPrintTemplate, getPrintTemplate2 } from "./print-template.js";
import { CloseIcon } from "@chakra-ui/icons";
import * as C from "@chakra-ui/react";
import { NumberInput } from "#/components/Form/v2/index.js";
import { Select } from "#/components/Form/v3/index.js";
import { atom, useAtomValue, useSetAtom } from "jotai";
import React, { Fragment } from "react";
import ZebraBrowserPrintWrapper from "zebra-browser-print-wrapper";

type ItemSku = RouterOutputs["itemSku"]["search"][0];
interface Product extends ItemSku {
  printQty: number;
}

export const productsBaseAtom = atom<Product[]>([]);
const addProductAtom = atom(null, (get, set, product: Product) => {
  set(productsBaseAtom, [...get(productsBaseAtom), product]);
});
const removeProductAtom = atom(null, (get, set, index: number) => {
  set(
    productsBaseAtom,
    get(productsBaseAtom).filter((_, i) => i !== index)
  );
});
const changeProductPrintQtyAtom = atom(
  null,
  (get, set, { index, qty }: { index: number; qty: number }) => {
    const products = get(productsBaseAtom).map((v, i) => ({
      ...v,
      printQty: i === index ? qty : v.printQty,
    }));
    set(productsBaseAtom, products);
  }
);

export interface PrintLabelsModalProps {
  printer: ZebraBrowserPrintWrapper;
  isLoadingPrinters: boolean;
  defaultProduct?: Product | null;
  setSelectedItemSkuToPrint: React.Dispatch<
    React.SetStateAction<ItemSku | null>
  >;
  opened: boolean;
  setOpened: React.Dispatch<React.SetStateAction<boolean>>;
}

export function PrintLabelsModal({
  printer,
  isLoadingPrinters,
  defaultProduct,
  setSelectedItemSkuToPrint,
  opened,
  setOpened,
}: PrintLabelsModalProps) {
  const products = useAtomValue(productsBaseAtom);
  const addProduct = useSetAtom(addProductAtom);
  const removeProduct = useSetAtom(removeProductAtom);
  const changeProductPrintQty = useSetAtom(changeProductPrintQtyAtom);
  const [defaultProductQty, setDefaultProductQty] = React.useState(2);
  const [{ auth }] = useAuth();
  const [priceTagTemplate, setPriceTagTemplate] = React.useState<
    "priceTag" | "withoutPriceTag" | null
  >("priceTag");
  const [filialId, setFilialId] = React.useState<number | null>(() => {
    if (auth?.user?.filialId) return auth.user.filialId;
    return null;
  });

  const { data: filialsData, isLoading: isLoadingFilials } =
    trpc.filial.getAll.useQuery(undefined, {
      cacheTime: 0,
      enabled: !auth?.user?.filialId,
    });

  const filials = React.useMemo(() => {
    if (!filialsData) return [];
    return filialsData.map((f) => ({
      label: f.name,
      value: f.id.toString(),
    }));
  }, [filialsData]);

  const handlePrint = React.useCallback(() => {
    (async function () {
      if (!priceTagTemplate)
        return reportUserError({
          title: "Please select a template",
        });

      const productsToPrint: Product[] = [];
      let totalPrintQty = 0;
      if (defaultProduct) {
        for (let i = 0; i < defaultProductQty; i++) {
          productsToPrint.push({
            ...defaultProduct,
            printQty: 1,
          });
        }
        totalPrintQty += defaultProductQty;
      }
      for (const product of products) {
        for (let i = 0; i < product.printQty; i++) {
          productsToPrint.push(product);
        }
        totalPrintQty += product.printQty;
      }

      if (productsToPrint.length < 0) {
        return;
      }

      if (totalPrintQty % 2 === 1) {
        productsToPrint[productsToPrint.length - 1].printQty += 1;
      }

      for (let i = 0; i < productsToPrint.length; i += 2) {
        const product0 = productsToPrint[i];
        const product1 = productsToPrint[i + 1];

        const product0LocationStore = product0.itemSkuStock.find(
          (iss) => iss.filialId === filialId
        )?.storeLocation;
        let template: string;
        if (product1) {
          const product1LocationStore = product1.itemSkuStock.find(
            (iss) => iss.filialId === filialId
          )?.storeLocation;
          template = getPrintTemplate2(
            {
              ...product0,
              productLocationStore: product0LocationStore,
              priceTag: priceTagTemplate,
            },
            {
              ...product1,
              productLocationStore: product1LocationStore,
              priceTag: priceTagTemplate,
            }
          );
        } else {
          template = getPrintTemplate({
            ...product0,
            productLocationStore: product0LocationStore,
            priceTag: priceTagTemplate,
          });
        }

        await printer.print(template);
      }
    })();
  }, [
    defaultProduct,
    defaultProductQty,
    filialId,
    priceTagTemplate,
    printer,
    products,
  ]);

  return (
    <C.Modal
      isOpen={opened}
      onClose={() => {
        setSelectedItemSkuToPrint(null);
        setOpened(false);
      }}
    >
      <C.ModalOverlay />
      <C.ModalContent
        maxH="min(95vh, 600px)"
        minH="350px"
        w="600px"
        maxW="min(600px, 95vw)"
      >
        <C.ModalHeader>
          <C.Text as="span">Print label</C.Text> <C.Divider mt={2} />
        </C.ModalHeader>
        <C.ModalCloseButton />
        <C.ModalBody overflow="auto">
          <C.Box position="sticky" top={0} zIndex="docked" mb={2} className="!z-50" >
            <SearchProduct
              onChange={(p: any) =>
                addProduct({
                  ...p,
                  printQty: 1,
                })
              }
            />
          </C.Box>
          <C.Box position="sticky" top={0} zIndex="docked">
            <Select
              placeholder="Item Template"
              options={[
                { label: "Gold Tree - Price Tag", value: "priceTag" },
                {
                  label: "Gold Tree - Without Price Tag",
                  value: "withoutPriceTag",
                },
              ]}
              onChange={(e) => setPriceTagTemplate((e?.value as any) ?? null)}
              defaultValue={{
                label: "Gold Tree - Price Tag",
                value: "priceTag",
              }}
            />
          </C.Box>
          {!auth?.user?.filialId && (
            <C.Box position="sticky" top={0} zIndex="docked" mt={2}>
              <Select
                placeholder="Select Filial"
                options={filials}
                isLoading={isLoadingFilials}
                onChange={(e) => (e ? setFilialId(Number(e.value)) : null)}
              />
            </C.Box>
          )}
          <C.Divider my={3} />
          <C.SimpleGrid columns={3} columnGap={4} rowGap={2}>
            <C.Text color="gray.500">Item name</C.Text>
            <C.Text color="gray.500">SKU</C.Text>
            <C.Text color="gray.500">Qty</C.Text>
            {defaultProduct && (
              <>
                <C.Divider gridColumn="span 3" />
                <C.Text>{defaultProduct.title}</C.Text>
                <C.Text>{defaultProduct.sku}</C.Text>
                <C.HStack>
                  <NumberInput
                    defaultValue={2}
                    onChange={(_, qty) => setDefaultProductQty(qty)}
                  />
                  <C.IconButton
                    aria-label="remove"
                    icon={<CloseIcon />}
                    variant="ghost"
                    size="xs"
                    color="gray.500"
                    isDisabled
                  />
                </C.HStack>
              </>
            )}
            {products.map((product, index) => (
              <Fragment key={`${index}_`}>
                <C.Divider gridColumn="span 3" />
                <C.Text>{product.title}</C.Text>
                <C.Text>{product.sku}</C.Text>
                <C.HStack>
                  <NumberInput
                    defaultValue={1}
                    onChange={(_, qty) => changeProductPrintQty({ index, qty })}
                  />
                  <C.IconButton
                    aria-label="remove"
                    icon={<CloseIcon />}
                    variant="ghost"
                    onClick={() => removeProduct(index)}
                    size="xs"
                    color="gray.500"
                  />
                </C.HStack>
              </Fragment>
            ))}
          </C.SimpleGrid>
        </C.ModalBody>
        <C.ModalFooter>
          <C.Button
            isLoading={isLoadingPrinters}
            loadingText="Loading printer..."
            onClick={handlePrint}
          >
            Print
          </C.Button>
        </C.ModalFooter>
      </C.ModalContent>
    </C.Modal>
  );
}

function SearchProduct({ onChange }) {
  const [query, setQuery] = React.useState("");
  const { data } = trpc.itemSku.search.useQuery({
    query: `\\"${query}\\"`,
  });

  return (
    <Select
      onInputChange={setQuery}
      options={data}
      getOptionLabel={(p: ItemSku) => `${p.sku} | ${p.title}`}
      getOptionValue={(p: ItemSku) => p.id.toString()}
      onChange={onChange}
      filterOption={() => true}
    />
  );
}
