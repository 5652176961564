import { RouterOutputs, trpc } from "#/trpc.js";
import placeholderImage from "#/placeholder-image.jpg";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_FilterOption,
  MRT_PaginationState,
  MRT_SortingState,
  useMantineReactTable,
} from "mantine-react-table";
import { openGeneratePasswordModal } from "./generate-password";
import * as M from "@mantine/core";
import { MdMoreHoriz, MdOutlineDownload } from "react-icons/md/index.js";
import { Link } from "react-router-dom";
import EditIcon from "~icons/ion/create-outline";
import { HStack, styled } from "#/css/jsx";
import { DropdownMenu, IconButton, Tooltip } from "@radix-ui/themes";
import { Button, Dropdown } from "@gt/ui";
import { css } from "#/css/css";
import { SearchButton } from "#/components@v2_5/search-button";
import { useAuth } from "#/context/AuthContext";
import { reportUserSuccess } from "#/util";

export const Users = () => {
  const [{ auth }] = useAuth();
  // Pagination
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });

  // Sorting
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const querySorting = useMemo(() => {
    if (sorting[0]) {
      return {
        desc: sorting[0].desc,
        key: sorting[0].id as any,
      };
    }
    return null;
  }, [sorting]);

  const [globalFilter, setGlobalFilter] = useState<
    MRT_FilterOption | undefined
  >();

  const { data } = trpc.v2_5.user.getUsersByPage.useQuery(
    {
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize,
      sorting: querySorting,
      search: globalFilter,
      filters: {
        type: "COMPANY",
        filialId: auth?.user?.filialId ?? null,
      },
    },
    {
      keepPreviousData: true,
    },
  );

  const { mutate: exportCsv, isLoading: isLoadingExport } =
    trpc.v2_5.user.exportUsersTableToCsv.useMutation({
      onSuccess: () => {
        reportUserSuccess({
          title: "The csv file has been sent to email",
        });
      },
    });

  const handleExportCsv = () => {
    exportCsv({
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize,
      sorting: querySorting,
      search: globalFilter,
      filters: {
        type: "COMPANY",
        filialId: auth?.user?.filialId ?? null,
      },
    });
  };

  const table = useMantineReactTable({
    columns: columns(),
    data: data?.entries ?? [],
    rowCount: data?.totalEntries ?? 0,
    manualPagination: true,
    manualSorting: true,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    onGlobalFilterChange: setGlobalFilter,
    mantineSearchTextInputProps: {
      placeholder: "Search by name",
    },
    state: {
      pagination,
      sorting,
    },
    enableTopToolbar: false,
    enableStickyHeader: true,
    renderBottomToolbarCustomActions: () => (
      <div
        className={css({
          display: "flex",
          alignItems: "center",
          gap: 2,
        })}
      >
        <Tooltip content="Export to CSV">
          <IconButton
            color="gray"
            variant="soft"
            loading={isLoadingExport}
            onClick={handleExportCsv}
          >
            <MdOutlineDownload />
          </IconButton>
        </Tooltip>
      </div>
    ),
  });

  return (
    <>
      <HStack justify="space-between" gap={3}>
        <DropdownButton />
        <SearchButton setQuery={setGlobalFilter} />
      </HStack>
      <div
        className={css({
          marginTop: 5,
        })}
      >
        <MantineReactTable table={table} />
      </div>
    </>
  );
};

type User = RouterOutputs["v2_5"]["user"]["getUsersByPage"]["entries"][number];

const columns = (): MRT_ColumnDef<User>[] => [
  {
    id: "id",
    enableSorting: false,
    enableColumnFilter: false,
    header: "Actions",
    enableColumnActions: false,
    size: 100,

    Cell: ({ row: { original } }) => (
      <M.Menu withinPortal>
        <M.Menu.Target>
          <M.ActionIcon>
            <MdMoreHoriz />
          </M.ActionIcon>
        </M.Menu.Target>
        <M.Menu.Dropdown>
          <M.Menu.Item
            component={Link}
            to={`edit/${original.id}`}
            icon={<EditIcon />}
          >
            Edit
          </M.Menu.Item>
          <M.Menu.Item onClick={() => openGeneratePasswordModal(original.id)}>
            Generate password
          </M.Menu.Item>
        </M.Menu.Dropdown>
      </M.Menu>
    ),
  },

  {
    enableColumnFilterModes: false,
    header: "Photo",
    accessorKey: "defaultPhoto",
    enableSorting: false,
    enableColumnFilter: false,
    Cell: (props) => {
      return (
        <M.Box className={css({ width: 50, height: 50 })}>
          <img src={props.row.original.avatar ?? placeholderImage} />
        </M.Box>
      );
    },
  },

  {
    header: "Name",
    accessorKey: "name",
    enableSorting: true,
    enableColumnFilter: false,
    Cell: ({ row: { original } }) => {
      return (
        <M.Box>
          <styled.p lineClamp={2} overflow={"hidden"}>
            {original.firstName + " " + (original.lastName ?? "")}
          </styled.p>
        </M.Box>
      );
    },
  },
  {
    header: "Phone number",
    accessorKey: "phoneNumber",
    enableColumnFilter: false,
    Cell: ({ row: { original } }) => {
      return (
        <M.Box>
          <M.Text>
            +{original.areaCode} {original.phoneNumber}
          </M.Text>
        </M.Box>
      );
    },
  },
  {
    header: "Email",
    accessorKey: "email",
    enableSorting: true,
    enableColumnFilter: false,
    Cell: ({ row: { original } }) => {
      return (
        <M.Box>
          <styled.p lineClamp={2} overflow={"hidden"}>
            {original.email}
          </styled.p>
        </M.Box>
      );
    },
  },
  {
    header: "Role",
    enableSorting: false,
    accessorKey: "role",
    enableColumnFilter: false,
    Cell: ({ row: { original } }) => {
      return (
        <M.Box>
          <M.Text>{original.role.name}</M.Text>
        </M.Box>
      );
    },
  },
];

const DropdownButton = () => {
  return (
    <Dropdown.Root>
      <Dropdown.Trigger>
        <Button
          className={css({
            gap: 4,
          })}
        >
          I want to
          <DropdownMenu.TriggerIcon />
        </Button>
      </Dropdown.Trigger>
      <Dropdown.Content>
        <Dropdown.Item>
          <Link to="create">Create user</Link>
        </Dropdown.Item>
      </Dropdown.Content>
    </Dropdown.Root>
  );
};
