import placeholderImage from "#/images/placeholder.png";
import { trpc } from "#/trpc";
import { Carousel } from "@mantine/carousel";
import { useNavigate } from "react-router";

interface RelatedProductsProps {
  itemId: number;
}

export const RelatedProducts = (props: RelatedProductsProps) => {
  const navigate = useNavigate();

  const { data } = trpc.itemSkuRelated.private.getByItemId.useQuery(
    {
      itemId: props.itemId,
    },
    {
      cacheTime: 0,
      enabled: !!props.itemId,
    }
  );

  const handleImageError = (event: any) => {
    event.target.src = placeholderImage;
  };

  return (
    <div>
      <h3 className="mb-8 text-xl font-medium text-[#212121]">
        Related products
      </h3>
      {data?.length === 0 ? (
        <p className="text-base text-[#757575]">No related products found</p>
      ) : (
        <Carousel
          slideSize="20%"
          align="start"
          controlsOffset="xs"
          height="350px"
          slideGap={22}
          breakpoints={[
            { maxWidth: "xl", slideSize: "20%" },
            { maxWidth: "md", slideSize: "50%" },
            { maxWidth: "sm", slideSize: "100%", slideGap: 0 },
          ]}
        >
          {data?.map((itemSkuRelated) => {
            const defaultImage =
              !itemSkuRelated.itemSku.defaultImage ||
              itemSkuRelated.itemSku.defaultImage === ""
                ? placeholderImage
                : itemSkuRelated.itemSku.defaultImage;

            return (
              <Carousel.Slide
                key={itemSkuRelated.itemSku.sku}
                onClick={() =>
                  navigate(
                    `/inventory/item-list/preview/${itemSkuRelated.itemSkuId}`
                  )
                }
              >
                <div className="flex flex-col">
                  <img
                    src={defaultImage}
                    className={
                      "aspect-square cursor-pointer rounded-lg object-cover"
                    }
                    onError={handleImageError}
                  />
                  <div className="mt-3 flex w-full flex-col">
                    <div className="flex justify-between">
                      <p className=" text-base font-normal text-slate-700">
                        {itemSkuRelated.itemSku.title}
                      </p>
                      <p className="text-right text-base font-semibold text-slate-700">
                        ${itemSkuRelated.itemSku.price?.toNumber().toFixed(2)}
                      </p>
                    </div>
                    <p className="mt-1 text-xs text-slate-500">
                      SKU {itemSkuRelated.itemSku.sku}
                    </p>
                  </div>
                </div>
              </Carousel.Slide>
            );
          })}
        </Carousel>
      )}
    </div>
  );
};
