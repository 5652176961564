import { RouterInputs } from "#/trpc";

export type SuggestedItemStatus = NonNullable<
  RouterInputs["v2_5"]["suggestedItems"]["getByPage"]["filters"]
>["status"][number];

export const Filter: Record<SuggestedItemStatus, string> = {
  NEW: "New",
  FAVORITE: "Favorite",
  ARCHIVED: "Archived",
  DELETED: "Deleted",
} as const;

export type FilterKey = keyof typeof Filter;
export type Filter = (typeof Filter)[FilterKey];
