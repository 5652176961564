import { Select } from "#/components-ng";
import { trpc } from "#/trpc";
import * as M from "@mantine/core";
import { modals } from "@mantine/modals";
import FileSaver from "file-saver";

export function openExportProductsModal() {
  modals.open({
    title: <h3>Export products</h3>,
    children: <ExportProductsModalChildren />,
  });
}

const ExportProductsModalChildren = () => {
  const [filialId, setFilialId] = useState<number | null>(null);
  const [categoryId, setCategoryId] = useState<number | null>(null);
  const { mutateAsync, isLoading } = trpc.v2_5.itemSku.export.useMutation();

  async function handleExport() {
    if (filialId == null) {
      return;
    }
    const { url } = await mutateAsync({
      sort: {
        by: "stock",
      },
      filialId,
      categoryId,
    });

    FileSaver.saveAs(url, "export.xlsx");
  }

  return (
    <div className="grid gap-y-2">
      <M.Select
        label="Sort by"
        data={SORT_BY_SELECT_DATA}
        defaultValue="BY_STOCK"
        zIndex={1400}
        required
      />
      <FilialSelect onChange={setFilialId} />
      <CategorySelect onChange={setCategoryId} />
      <M.Button loading={isLoading} onClick={handleExport}>
        Export
      </M.Button>
    </div>
  );
};

interface FilialSelectProps {
  onChange: (filialId: number) => void;
}

const FilialSelect = (props: FilialSelectProps) => {
  const { data } = trpc.filial.getAll.useQuery(undefined);
  const [selectedFilialId, setSelectedFilialId] = useState<number | null>(null);
  const { data: selectedFilial } = trpc.filial.getById.useQuery(
    {
      id: selectedFilialId!,
    },
    {
      enabled: selectedFilialId != null,
    }
  );

  return (
    <Select
      label="Filial"
      data={data ?? []}
      entryId={(e) => e.id.toString()}
      entryLabel={(e) => e.name}
      searchable
      withinPortal
      required
      value={selectedFilial}
      onChange={(filial) => {
        if (filial != null) {
          props.onChange(filial.id);
          setSelectedFilialId(filial.id);
        }
      }}
    />
  );
};

interface CategorySelectProps {
  onChange: (categoryId: number | null) => void;
}

const CategorySelect = (props: CategorySelectProps) => {
  const { data } = trpc.category.getAll.useQuery(undefined);
  const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(
    null
  );

  return (
    <Select
      label="Category"
      data={data ?? []}
      entryId={(e) => e.id.toString()}
      entryLabel={(e) => e.name}
      searchable
      withinPortal
      clearable
      value={
        data?.find((category) => category.id === selectedCategoryId) ?? null
      }
      onChange={(category) => {
        if (category != null) {
          props.onChange(category.id);
          setSelectedCategoryId(category.id);
        } else {
          props.onChange(null);
          setSelectedCategoryId(null);
        }
      }}
    />
  );
};

const SORT_BY_SELECT_DATA = [{ label: "By stock", value: "BY_STOCK" }];
