import { ChevronDownOutline, Select } from "#/components-ng"
import type { SelectProps } from "#/components-ng"
import { useAuth } from "#/context/AuthContext"
import { trpc } from "#/trpc"
import { reportUserSuccess } from "#/util"
import type { Order } from "./use-order"
import { Avatar } from "@gt/ui"
import * as M from "@mantine/core"
import { getCountryCallingCode } from "libphonenumber-js"

export interface CustomerPanelProps {
	order?: Order | null
}

export function CustomerPanel(props: CustomerPanelProps) {
	const user = props.order?.customer ?? props.order?.userBasicInfo

	return (
		<>
			<div className="flex items-center justify-between gap-x-4">
				<div className="flex items-center gap-x-4">
					<Avatar.Root className="row-span-2 h-[64px] w-[64px]">
						<Avatar.Image src="" />
						<Avatar.Fallback />
					</Avatar.Root>
					<div className="grid gap-y-2">
						<p className="text-lg font-medium">
							{user?.firstName} {user?.lastName}{" "}
							{!props?.order?.customer && (
								<span className="text-red-300">(Guest)</span>
							)}
						</p>
						<div className="flex items-center gap-x-3">
							{[
								["i-ion-mail-outline", user?.email ?? "N/A"],
								[
									"i-ion-call-outline",
									user?.countryCode
										? `+${getCountryCallingCode(user.countryCode as any)} ${
												user.phoneNumber
											}`
										: "N/A",
								],
							].map(([icon, value], i) => (
								<div
									key={i}
									className="flex items-center gap-x-1 text-sm text-slate-500"
								>
									<i className={icon} />
									<span className="leading-none">{value}</span>
								</div>
							))}
						</div>
					</div>
				</div>
				<div className="flex flex-col justify-end gap-3">
					{/* <AssociatedForm order={props.order} /> */}
					{props.order?.customer &&
						props.order?.customer?.preferredSalesAssociated && (
							<p className="text-sm font-medium text-slate-500">
								Suggested Sales Associate:{" "}
								{props.order?.customer?.preferredSalesAssociated?.firstName}{" "}
								{props.order?.customer?.preferredSalesAssociated?.lastName}
							</p>
						)}
				</div>
			</div>
		</>
	)
}

type User = {
	id: number
	firstName: string
	lastName?: string | null
}

interface AssociatedFormProps {
	order?: Order | null
}

function AssociatedForm(props: AssociatedFormProps) {
	const [associated, setAssociated] = useState<User | null>(null)
	const [assigned, setAssigned] = useState<User | null>(null)

	const { mutate } =
		trpc.order.pos.ecommerceOrders.ecommerceOrder.updateAssociated.useMutation({
			onSuccess() {
				reportUserSuccess({
					title: "Associated updated succesfully",
				})
			},
			onError() {
				reportUserSuccess({
					title: "Failed to update associated",
				})
			},
		})

	function submit() {
		if (props.order == null) {
			return
		}
		mutate({
			id: props.order.id,
			assignedId: assigned?.id ?? null,
			associatedId: associated?.id ?? null,
		})
	}

	useEffect(() => {
		setAssociated(props.order?.associated ?? null)
		setAssigned(props.order?.assigned ?? null)
	}, [props.order])

	return (
		<M.Popover>
			<M.Popover.Target>
				<M.Button
					variant="light"
					color="gray.1"
					c="gray.8"
					rightIcon={<ChevronDownOutline />}
				>
					Associated
				</M.Button>
			</M.Popover.Target>
			<M.Popover.Dropdown>
				<M.Stack>
					<UserSelect
						label="Sales associate"
						value={associated}
						onChange={setAssociated}
					/>
					<UserSelect
						label="Assigned"
						value={assigned}
						onChange={setAssigned}
					/>
					<M.Button fullWidth onClick={submit}>
						Save
					</M.Button>
				</M.Stack>
			</M.Popover.Dropdown>
		</M.Popover>
	)
}

export function UserSelect(
	props: Omit<SelectProps<User | null>, "data" | "entryId" | "entryLabel">,
) {
	const [query, setQuery] = useState("")
	const [{ auth }] = useAuth()
	const { data } = trpc.user.search.useQuery({
		query,
		filialId: auth?.user?.filialId ?? undefined,
	})

	return (
		<Select
			{...props}
			searchable
			data={data ?? []}
			entryId={(v) => v.id.toString()}
			entryLabel={(v) => `${v.firstName} ${v.lastName ?? ""}`}
			onSearchChange={setQuery}
			classNames={{
				input: "h-10 border-input",
			}}
		/>
	)
}
