import Decimal from "decimal.js";

export interface ProductLabelInfo {
  title: string;
  sku?: number | null;
  price: Decimal;
  presentationValue?: number | null;
  presentationType?: string | null;
  productLocationStore?: string | null;
  unitPrice: Decimal;
  priceTag: "priceTag" | "withoutPriceTag";
}

export function footerLeft(title: string, locationStore?: string | null) {
  if (title.length < 17) {
    return `
          ^FB233,,,C^FO0,142^FD${title}\\&^FS
          ^FB233,,,C^FO0,167^FD${locationStore ?? ""}\\&^FS
        `;
  } else {
    return `
          ^FB233,,,C^FO0,142^FD${title.substring(0, 17)}\\&^FS
          ^FB233,,,C^FO0,160^FD${title.substring(17, 34)}\\&^FS
          ^FB233,,,C^FO0,186^FD${locationStore ?? ""}\\&^FS
        `;
  }
}

export function footerRight(title: string, locationStore?: string | null) {
  if (title.length < 17) {
    return `
          ^FB700,,,C^FO0,142^FD${title}\\&^FS
          ^FB700,,,C^FO0,167^FD${locationStore ?? ""}\\&^FS
        `;
  } else {
    return `
          ^FB700,,,C^FO0,142^FD${title.substring(0, 17)}\\&^FS
          ^FB700,,,C^FO0,160^FD${title.substring(17, 34)}\\&^FS
          ^FB700,,,C^FO0,186^FD${locationStore ?? ""}\\&^FS
        `;
  }
}

export function getPrintTemplate(product0: ProductLabelInfo): string {
  return `^XA
      ^FX Price and size
      ^CFA,15
      ^FO15,10^FD${product0?.priceTag === "priceTag" ? "$" : ""}${
    product0?.unitPrice && product0.unitPrice.greaterThan(0)
      ? product0.unitPrice.toNumber().toFixed(2)
      : product0.price
          .dividedBy(product0?.presentationValue ?? 1)
          .toNumber()
          .toFixed(2)
  }^FS
      ^FO218,10,1^FD${`${product0.presentationValue ?? ""} ${
        product0.presentationType ?? ""
      }`}^FS
      
      ^FX SKU
      ^CF0,20
      ^FB233,,,C^FO0,30^FD${product0.sku}\\&^FS
      
      ^FX Barcode
      ^BY2,2,50
      ^FX ---- Change BC,130,N to BC,130,Y to print the code number ----
      ^FO25,55^BC,,N^FD${product0.sku}^FS
      
      ^FX Product name
      ^CFA,15
      ^FB233,,,C^FO0,115^FDMSRP XDR${product0.price.toNumber().toFixed(2)}\\&^FS
      ^CFL,20
      ${footerLeft(product0.title, product0?.productLocationStore ?? null)}
      
      ^XZ`;
}

export function getPrintTemplate2(
  product0: ProductLabelInfo,
  product1: ProductLabelInfo
): string {
  return `^XA
      ^FX Price and size
      ^CFA,15
      ^FO15,10^FD${product0?.priceTag === "priceTag" ? "$" : ""}${
    product0?.unitPrice && product0.unitPrice.greaterThan(0)
      ? product0.unitPrice.toNumber().toFixed(2)
      : product0.price
          .dividedBy(product0?.presentationValue ?? 1)
          .toNumber()
          .toFixed(2)
  }^FS
      ^FO218,10,1^FD${`${product0.presentationValue ?? ""} ${
        product0.presentationType ?? ""
      }`}^FS
      
      ^FX SKU
      ^CF0,20
      ^FB233,,,C^FO0,30^FD${product0.sku}\\&^FS
      
      ^FX Barcode
      ^BY2,2,50
      ^FX ---- Change BC,130,N to BC,130,Y to print the code number ----
      ^FO25,55^BC,,N^FD${product0.sku}^FS
      
      ^FX Product name
      ^CFA,15
      ^FB233,,,C^FO0,115^FDMSRP XDR${product0.price.toFixed(2)}\\&^FS
      ^CFL,20
      ${footerLeft(product0.title, product0?.productLocationStore ?? "")}

      ^FX Price and size
      ^CFA,15
      ^FO248,10^FD${product1?.priceTag === "priceTag" ? "$" : ""}${
    product1?.unitPrice && product1.unitPrice.greaterThan(0)
      ? product1.unitPrice.toNumber().toFixed(2)
      : product1.price
          .dividedBy(product1?.presentationValue ?? 1)
          .toNumber()
          .toFixed(2)
  }^FS
      ^FO451,10,1^FD${`${product0.presentationValue ?? ""} ${
        product0.presentationType ?? ""
      }`}^FS
      
      ^FX SKU
      ^CF0,20
      ^FB700,,,C^FO0,30^FD${product1.sku}\\&^FS
      
      ^FX Barcode
      ^BY2,2,50
      ^FX ---- Change BC,130,N to BC,130,Y to print the code number ----
      ^FO258,55^BC,,N^FD${product1.sku}^FS
      
      ^FX Product name
      ^CFA,15
      ^FB700,,,C^FO0,115^FDMSRP XDR${product1.price.toNumber().toFixed(2)}\\&^FS
      ^CFL,20
      ${footerRight(product1.title, product1?.productLocationStore ?? null)}
      
      ^XZ`;
}
