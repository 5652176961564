import { Checkbox, Switch } from "@mantine/core";
import React from "react";
import {
  Control,
  FieldPath,
  FieldValues,
  useController,
  useForm,
  useFormContext,
  UseFormProps,
  Controller as RHFController,
  ControllerProps as RHFControllerProps,
} from "react-hook-form";

export interface ControllerProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  TContext extends object = any,
  Props extends object = Record<string, any>
> {
  $as: React.ComponentType<Props>;
  name: TName;
  defaultValue?: any;
  value?: any;
  control?: Control<TFieldValues, TContext>;
  ctx?: TFieldValues;
  noNullish?: boolean;
}

export const Controller = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  TContext extends object = any,
  Props extends object = Record<string, any>
>(
  props: ControllerProps<TFieldValues, TName, TContext, Props> &
    Omit<Props, "name">
) => {
  // We provide `as` as an alternative because `wrap` is a prop in some elements (e.g. textarea)
  const Child = props.$as;
  const context = useFormContext<TFieldValues>();
  if (!context) {
    throw new Error("component must be used under a FormProvider");
  }

  const {
    field,
    fieldState: { error },
  } = useController({
    control: props.control ?? context.control,
    name: props.name,
    defaultValue: props.defaultValue,
    rules: {
      required:
        ((props as any)["isRequired"] || (props as any)["required"]) &&
        "Field required",
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { defaultValue, ctx, name, control, $as, noNullish, ...childProps } =
    props;

  let onChange: any = field.onChange;
  let valueProps: any = { value: props.value ?? field.value };
  if (Child === Switch || Child === Checkbox) {
    onChange = (e: any) => field.onChange(e.currentTarget.checked);
    valueProps = { checked: props.value ?? field.value };
  }

  return (
    // @ts-ignore
    <Child
      {...field}
      {...childProps}
      error={error?.message}
      onChange={(...params: any) => {
        // @ts-ignore
        onChange(...params);
        // @ts-ignore
        if (props.onChange) {
          // @ts-ignore
          props.onChange(...params);
        }
      }}
      {...valueProps}
    />
  );
};

/**
 *
 * @example
 * const Mc = makeController({ ctx: {} as InputProduct });
 * // error: `descriatiption` is not a name in `InputProduct`
 * <Mc as={M.TextInput} name="descriatiption" />
 */
export const createFormContext = <
  TFieldValues extends FieldValues = FieldValues
>() =>
  // eslint-disable-next-line react/display-name
  ({
    C: <Props extends object = Record<string, any>>(
      props: ControllerProps<
        TFieldValues,
        FieldPath<TFieldValues>,
        any,
        Props
      > &
        Omit<Props, "name">
    ) => <Controller {...props} />,
    useForm: (props?: UseFormProps<TFieldValues, any>) =>
      useForm<TFieldValues>(props),

    useFormContext: () => useFormContext<TFieldValues>(),

    Controller: <TName extends FieldPath<TFieldValues>>(
      props: RHFControllerProps<TFieldValues, TName>
    ) => {
      return <RHFController {...props} />;
    },
  });
