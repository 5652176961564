import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    input[type=number]::-webkit-outer-spin-button,
    input[type=number]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
        -moz-appearance:textfield;
    }

    .factory-view-header-cart {
        width: 100%;
        .anticon {
            width: 95%;
            padding: 10px 8px;
            background-color: ${({ theme }) => theme.gold};
            color: #fff !important;
            font-size: 35px !important;
            border-radius: 5px;
        }
    }

    @media only screen and (min-width: 580px) {
        .factory-view-header-cart{
            width: 25px !important;
            .anticon {
                font-size: 25px !important;
                width: 25px;
                padding: 0;
                background-color: transparent !important;
                color: rgb(179, 179, 179) !important;
            }
        }
    }

    .hide-on-mobile {
        display: none;
    }

    @media only screen and (min-width: 768px) {
        .hide-on-mobile {
            display: initial;
        }
    }

    .no-wrap {
        white-space: nowrap;
    }
`;

export default GlobalStyle;
