import { CrmScene } from ".";
import BlogList from "./Blog/BlogList";
import CreatePost from "./Blog/CreatePost";
import UpdatePost from "./Blog/UpdatePost";
import { HomePageBanners } from "./HomePageBanner";
import { CreateHomePageBanner } from "./HomePageBanner/Create";
import { UpdateHomePageBanner } from "./HomePageBanner/Update";
import { QuizScene } from "./quiz";
import { CreateQuizForm } from "./quiz/Create";
import UpdateQuiz from "./quiz/Update";
import { Roulette } from "./Roulette";
import { CreateRouletteForm } from "./Roulette/Create";
import { UpdateRouletteForm } from "./Roulette/Update";

export const crmRoutes = [
  {
    index: true,
    element: <CrmScene />,
    breadcrumb: "Ecommerce CRM",
  },
  {
    path: "blog",
    element: <BlogList />,
    breadcrumb: "Blog",
  },
  {
    path: "blog/create",
    element: <CreatePost />,
    breadcrumb: "create Post",
  },
  {
    path: "blog/edit/:id",
    element: <UpdatePost />,
    breadcrumb: "Update Post",
  },
  {
    path: "banner",
    element: <HomePageBanners />,
    breadcrumb: "Home page banner",
  },
  {
    path: "banner/create",
    element: <CreateHomePageBanner />,
    breadcrumb: "Create home page banner",
  },
  {
    path: "banner/edit/:id",
    element: <UpdateHomePageBanner />,
    breadcrumb: "Edit home page banner",
  },
  {
    path: "quiz",
    element: <QuizScene />,
    breadcrumb: "Quiz",
  },
  {
    path: "quiz/create",
    element: <CreateQuizForm />,
    breadcrumb: "Create Quiz",
  },
  {
    path: "quiz/edit/:id",
    element: <UpdateQuiz />,
    breadcrumb: "Edit Quiz",
  },
  {
    path: "roulette",
    element: <Roulette />,
    breadcrumb: "Roulette",
  },
  {
    path: "roulette/create",
    element: <CreateRouletteForm />,
    breadcrumb: "Create Roulette",
  },
  {
    path: "roulette/edit/:id",
    element: <UpdateRouletteForm />,
    breadcrumb: "Edit Roulette",
  },
];
