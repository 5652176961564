import { RouterOutputs } from "#/trpc.js";
import dayjs from "dayjs";
import Decimal from "decimal.js";
import ExcelJS, { Style } from "exceljs";
import { saveAs } from "file-saver";
import _ from "lodash";

type EndOfDay = RouterOutputs["report"]["getEndOfDay"];

export const exportEndOfDayToXLSX = async (endOfDay: EndOfDay) => {
  const wb = new ExcelJS.Workbook();
  const sheet = wb.addWorksheet("Sheet 1", {
    pageSetup: { paperSize: 9 },
  });
  sheet.views = [{ showGridLines: false }];

  sheet.columns = [
    { key: "A", width: 5.43 },
    { key: "B", width: 7 },
    { key: "C", width: 30.43 },
    { key: "D", width: 15.29 },
    { key: "E", width: 18.14 },
    { key: "F", width: 17.71 },
    { key: "G", width: 5.43 },
  ];

  const style = {
    name: "Montserrat",
    size: 10,
  };

  const styleBackground = (
    aligment: "left" | "right" | "center"
  ): Partial<Style> => {
    return {
      fill: {
        type: "pattern",
        bgColor: { argb: "fff2f2f2" },
        fgColor: { argb: "fff2f2f2" },
        pattern: "solid",
      },
      alignment: {
        horizontal: `${aligment}`,
        vertical: "middle",
      },
    };
  };

  const generateSalesSummary = (
    title: string,
    rowTitle: number,
    cellsTitle: string,
    data: {
      subTotalSales: {
        value: Decimal;
        cells: string[];
      };
      returns: {
        value: Decimal;
        cells: string[];
      };
      net: {
        value: Decimal;
        cells: string[];
      };
    }
  ) => {
    sheet.getRow(rowTitle).height = 39.75;
    sheet.mergeCells(`${cellsTitle}`);
    sheet.getCell(`${cellsTitle}`).font = {
      ...style,
      size: 12,
      color: { argb: "ffa67744" },
    };
    sheet.getCell(`${cellsTitle}`).alignment = {
      vertical: "middle",
    };
    sheet.getCell(`${cellsTitle}`).value = title;

    // Subtotal Sales
    sheet.mergeCells(`${data.subTotalSales.cells[0]}`);
    sheet.mergeCells(`${data.subTotalSales.cells[1]}}`);
    sheet.getCell(`${data.subTotalSales.cells[0]}`).value = `Subtotal Sales`;
    sheet.getCell(`${data.subTotalSales.cells[0]}`).style =
      styleBackground("left");
    sheet.getCell(`${data.subTotalSales.cells[1]}`).style =
      styleBackground("right");
    sheet.getCell(`${data.subTotalSales.cells[1]}`).value =
      data.subTotalSales.value?.toNumber().toFixed(2) ?? 0.0;

    // Returns
    sheet.mergeCells(`${data.returns.cells[0]}`);
    sheet.mergeCells(`${data.returns.cells[1]}}`);
    sheet.getCell(`${data.returns.cells[0]}`).value = `Returns`;
    sheet.getCell(`${data.returns.cells[1]}`).alignment = {
      horizontal: "right",
      vertical: "middle",
    };
    sheet.getCell(`${data.returns.cells[1]}`).value =
      data.returns.value?.toNumber().toFixed(2) ?? 0.0;
    // Net
    sheet.mergeCells(`${data.net.cells[0]}`);
    sheet.mergeCells(`${data.net.cells[1]}}`);
    sheet.getCell(`${data.net.cells[0]}`).style = styleBackground("left");
    sheet.getCell(`${data.net.cells[1]}`).style = styleBackground("right");
    sheet.getCell(`${data.net.cells[0]}`).value = `Net`;
    sheet.getCell(`${data.net.cells[1]}`).value =
      data.net.value?.toNumber().toFixed(2) ?? 0.0;
  };

  const generatePaymentsSummary = (
    title: string,
    rowTitle: number,
    cellsTitle: string,
    data: {
      totalPaidIn: {
        value: Decimal;
        cells: string[];
      };
      totalPaidOut: {
        value: Decimal;
        cells: string[];
      };
      totalAmount: {
        value: Decimal;
        cells: string[];
      };
    }
  ) => {
    sheet.getRow(rowTitle).height = 39.75;
    sheet.mergeCells(`${cellsTitle}`);
    sheet.getCell(`${cellsTitle}`).font = {
      ...style,
      size: 12,
      color: { argb: "ffa67744" },
    };
    sheet.getCell(`${cellsTitle}`).alignment = {
      vertical: "middle",
    };
    sheet.getCell(`${cellsTitle}`).value = title;

    // Total Paid In
    sheet.mergeCells(`${data.totalPaidIn.cells[0]}`);
    sheet.mergeCells(`${data.totalPaidIn.cells[1]}}`);
    sheet.getCell(`${data.totalPaidIn.cells[0]}`).value = `Total Paid In`;
    sheet.getCell(`${data.totalPaidIn.cells[0]}`).style =
      styleBackground("left");
    sheet.getCell(`${data.totalPaidIn.cells[1]}`).style =
      styleBackground("right");
    sheet.getCell(`${data.totalPaidIn.cells[1]}`).value =
      data.totalPaidIn.value?.toNumber().toFixed(2) ?? 0.0;
    // Total Paid Out
    sheet.mergeCells(`${data.totalPaidOut.cells[0]}`);
    sheet.mergeCells(`${data.totalPaidOut.cells[1]}}`);
    sheet.getCell(`${data.totalPaidOut.cells[0]}`).value = `Total Paid Out`;
    sheet.getCell(`${data.totalPaidOut.cells[1]}`).alignment = {
      horizontal: "right",
      vertical: "middle",
    };
    sheet.getCell(`${data.totalPaidOut.cells[1]}`).value =
      data.totalPaidOut.value?.toNumber().toFixed(2) ?? 0.0;
    // Total Amount
    sheet.mergeCells(`${data.totalAmount.cells[0]}`);
    sheet.mergeCells(`${data.totalAmount.cells[1]}}`);
    sheet.getCell(`${data.totalAmount.cells[0]}`).value = `Total Amount`;
    sheet.getCell(`${data.totalAmount.cells[0]}`).style =
      styleBackground("left");
    sheet.getCell(`${data.totalAmount.cells[1]}`).style =
      styleBackground("right");
    sheet.getCell(`${data.totalAmount.cells[1]}`).value =
      data.totalAmount.value?.toNumber().toFixed(2) ?? 0.0;
  };

  // Here starts generating the excel
  // Header
  sheet.mergeCells("A1:G1");
  sheet.getRow(1).height = 6;
  sheet.getCell("A1:G1").style = {
    fill: {
      type: "pattern",
      bgColor: { argb: "ffa67744" },
      fgColor: { argb: "ffa67744" },
      pattern: "solid",
    },
  };

  sheet.getRow(3).height = 32.25;
  sheet.mergeCells("B3:G3");
  sheet.getCell("B3:G3").font = {
    ...style,
    size: 20,
    color: { argb: "ffa67744" },
  };
  sheet.getCell("B3:G3").value = "END OF DAY";

  sheet.getRow(4).height = 12.75;
  sheet.getRow(5).height = 12.75;
  sheet.mergeCells("B4:D4");
  sheet.getCell("B4:D4").font = {
    ...style,
    color: { argb: "ff707070" },
  };
  sheet.getCell("B4:D4").value = `Created at: ${dayjs(endOfDay.from).format(
    "DD/MM/YYYY"
  )} - ${dayjs(endOfDay.to).format("DD/MM/YYYY")}`;
  sheet.getCell("B5:D5").font = {
    ...style,
    color: { argb: "ff707070" },
  };

  sheet.getRow(6).height = 12.75;
  sheet.getRow(7).height = 12.75;
  sheet.mergeCells("B6:F6");
  sheet.getCell("B6:F6").border = {
    bottom: { style: "thin" },
  };

  // Sales Summary
  sheet.getRow(8).height = 51.75;
  sheet.mergeCells("B8:F8");
  sheet.getCell("B8:F8").font = {
    ...style,
    size: 14,
    bold: true,
    color: { argb: "ffa67744" },
  };
  sheet.getCell("B8:F8").alignment = {
    vertical: "middle",
  };
  sheet.getCell("B8:F8").value = "Sales Summary";

  const startRow = 9;
  const endRow = 200;
  const rowHeight = 19.5;
  for (let i = startRow; i <= endRow; i++) {
    sheet.getRow(i).height = rowHeight;
    sheet.getRow(i).alignment = {
      vertical: "middle",
    };
  }

  sheet.mergeCells("B9:C9");
  sheet.mergeCells("D9:F9");
  sheet.getCell("B9:C9").style = styleBackground("left");
  sheet.getCell("D9:F9").style = styleBackground("right");
  sheet.getCell("B9:C9").value = "Completed Orders";
  sheet.getCell("D9:F9").value = endOfDay?.ordersHistory?.orderCount ?? 0;
  sheet.mergeCells("B10:C10");
  sheet.mergeCells("D10:F10");
  sheet.getCell("B10:C10").value = "Subtotal sales";
  sheet.getCell("D10:F10").value =
    endOfDay?.totalOrderAmount?.toNumber().toFixed(2) ?? 0.0;
  sheet.getCell("D10:F10").alignment = {
    horizontal: "right",
    vertical: "middle",
  };
  sheet.mergeCells("B11:C11");
  sheet.mergeCells("D11:F11");
  sheet.getCell("B11:C11").style = styleBackground("left");
  sheet.getCell("D11:F11").style = styleBackground("right");
  sheet.getCell("B11:C11").value = "Total returns";
  sheet.getCell("D11:F11").value =
    endOfDay?.totalReturnedAmount?.toNumber().toFixed(2) ?? 0.0;
  sheet.mergeCells("B12:C12");
  sheet.mergeCells("D12:F12");
  sheet.getCell("B12:C12").value = "Total sales";
  sheet.getCell("D12:F12").value =
    endOfDay?.totalSale?.toNumber().toFixed(2) ?? 0.0;
  sheet.getCell("D12:F12").alignment = {
    horizontal: "right",
    vertical: "middle",
  };
  sheet.mergeCells("B13:C13");
  sheet.mergeCells("D13:F13");
  sheet.getCell("B13:C13").style = styleBackground("left");
  sheet.getCell("D13:F13").style = styleBackground("right");
  sheet.getCell("B13:C13").value = "Total shipping";
  sheet.getCell("D13:F13").value =
    endOfDay?.totalShipping?.toNumber().toFixed(2) ?? 0.0;
  sheet.mergeCells("B14:C14");
  sheet.mergeCells("D14:F14");
  sheet.getCell("B14:C14").value = "Total tax";
  sheet.getCell("D14:F14").value =
    endOfDay?.totalTaxAmount?.toNumber().toFixed(2) ?? 0.0;
  sheet.getCell("D14:F14").alignment = {
    horizontal: "right",
    vertical: "middle",
  };
  sheet.mergeCells("B15:C15");
  sheet.mergeCells("D15:F15");
  sheet.getCell("B15:C15").style = styleBackground("left");
  sheet.getCell("D15:F15").style = styleBackground("right");
  sheet.getCell("B15:C15").value = "Total activity";
  sheet.getCell("D15:F15").value =
    endOfDay?.totalActivityAmount?.toNumber().toFixed(2) ?? 0.0;

  generateSalesSummary("Sales Non Taxable", 16, "B16:F16", {
    subTotalSales: {
      value: endOfDay?.subTotalNonTaxable ?? 0.0,
      cells: ["B17:C17", "D17:F17"],
    },
    returns: {
      value: endOfDay?.totalReturnNonTaxable ?? 0.0,
      cells: ["B18:C18", "D18:F18"],
    },
    net: {
      value: endOfDay?.netSalesNonTaxable ?? 0.0,
      cells: ["B19:C19", "D19:F19"],
    },
  });

  generateSalesSummary("Sales Taxable", 20, "B20:F20", {
    subTotalSales: {
      value: endOfDay?.subTotalTaxable ?? 0.0,
      cells: ["B21:C21", "D21:F21"],
    },
    returns: {
      value: endOfDay?.totalReturnTaxable ?? 0.0,
      cells: ["B22:C22", "D22:F22"],
    },
    net: {
      value: endOfDay?.netSalesTaxable ?? 0.0,
      cells: ["B23:C23", "D23:F23"],
    },
  });

  generateSalesSummary("Sales Tax", 24, "B24:F24", {
    subTotalSales: {
      value: endOfDay?.subTotalNonTaxable ?? 0.0,
      cells: ["B25:C25", "D25:F25"],
    },
    returns: {
      value: endOfDay?.totalReturnNonTaxable ?? 0.0,
      cells: ["B26:C26", "D26:F26"],
    },
    net: {
      value: endOfDay?.netSalesNonTaxable ?? 0.0,
      cells: ["B27:C27", "D27:F27"],
    },
  });

  // Payments
  sheet.getRow(28).height = 51.75;
  sheet.mergeCells("B28:F28");
  sheet.getCell("B28:F28").font = {
    ...style,
    size: 14,
    bold: true,
    color: { argb: "ffa67744" },
  };
  sheet.getCell("B28:F28").alignment = {
    vertical: "middle",
  };
  sheet.getCell("B28:F28").value = "Payments";

  sheet.mergeCells("B29:C29");
  sheet.mergeCells("D29:F29");
  sheet.getCell("B29:C29").style = styleBackground("left");
  sheet.getCell("D29:F29").style = styleBackground("right");
  sheet.getCell("B29:C29").value = "Total Paid in";
  sheet.getCell("D29:F29").value =
    endOfDay?.payments.totalPaidIn?.toNumber().toFixed(2) ?? 0.0;

  sheet.mergeCells("B30:C30");
  sheet.mergeCells("D30:F30");
  sheet.getCell("D30:F30").alignment = {
    horizontal: "right",
    vertical: "middle",
  };
  sheet.getCell("B30:C30").value = "Total Paid out";
  sheet.getCell("D30:F30").value =
    endOfDay?.payments.totalPaidOut?.toNumber().toFixed(2) ?? 0.0;

  sheet.mergeCells("B31:C31");
  sheet.mergeCells("D31:F31");
  sheet.getCell("B31:C31").style = styleBackground("left");
  sheet.getCell("D31:F31").style = styleBackground("right");
  sheet.getCell("B31:C31").value = "Total Paid";
  sheet.getCell("D31:F31").value =
    endOfDay?.payments.totalAmount?.toNumber().toFixed(2) ?? 0.0;

  // payments cash
  const cash = endOfDay?.payments.orders
    .filter((order) => order.method === "CASH")
    ?.reduce(
      (acc, order) => ({
        totalPaidIn: acc.totalPaidIn.plus(order.totalPaidIn),
        totalPaidOut: acc.totalPaidOut.plus(order.totalPaidOut),
        totalAmount: acc.totalAmount.plus(order.totalAmount),
      }),
      {
        totalPaidIn: new Decimal(0),
        totalPaidOut: new Decimal(0),
        totalAmount: new Decimal(0),
      }
    );
  generatePaymentsSummary("CASH", 32, "B32:F32", {
    totalPaidIn: {
      value: cash?.totalPaidIn ?? 0.0,
      cells: ["B33:C33", "D33:F33"],
    },
    totalPaidOut: {
      value: cash?.totalPaidOut ?? 0.0,
      cells: ["B34:C34", "D34:F34"],
    },
    totalAmount: {
      value: cash?.totalAmount ?? 0.0,
      cells: ["B35:C35", "D35:F35"],
    },
  });
  // payments all credit/debit cards
  const allCards = endOfDay?.payments.orders
    .filter((order) => order.method !== "CASH")
    ?.reduce(
      (acc, order) => ({
        totalPaidIn: acc.totalPaidIn.plus(order.totalPaidIn),
        totalPaidOut: acc.totalPaidOut.plus(order.totalPaidOut),
        totalAmount: acc.totalAmount.plus(order.totalAmount),
      }),
      {
        totalPaidIn: new Decimal(0),
        totalPaidOut: new Decimal(0),
        totalAmount: new Decimal(0),
      }
    );
  generatePaymentsSummary("All Credit/Debit cards", 36, "B36:F36", {
    totalPaidIn: {
      value: allCards?.totalPaidIn ?? 0.0,
      cells: ["B37:C37", "D37:F37"],
    },
    totalPaidOut: {
      value: allCards?.totalPaidOut ?? 0.0,
      cells: ["B38:C38", "D38:F38"],
    },
    totalAmount: {
      value: allCards?.totalAmount ?? 0.0,
      cells: ["B39:C39", "D39:F39"],
    },
  });

  // payments summary by each card type
  // eslint-disable-next-line prefer-const
  let lastRow = 39;
  endOfDay?.payments.orders
    .filter((order) => order.method !== "CASH")
    .map((p) => p.method)
    .filter((v, i, a) => a.indexOf(v) === i)
    .forEach((method) => {
      generatePaymentsSummary(
        method,
        lastRow + 1,
        `B${lastRow + 1}:F${lastRow + 1}`,
        {
          totalPaidIn: {
            value: endOfDay?.payments.orders
              .filter((order) => order.method === method)
              .reduce(
                (acc, order) => acc.plus(order.totalPaidIn),
                new Decimal(0)
              ),
            cells: [
              `B${lastRow + 2}:C${lastRow + 2}`,
              `D${lastRow + 2}:F${lastRow + 2}`,
            ],
          },
          totalPaidOut: {
            value: endOfDay?.payments.orders
              .filter((order) => order.method === method)
              .reduce(
                (acc, order) => acc.plus(order.totalPaidOut),
                new Decimal(0)
              ),
            cells: [
              `B${lastRow + 3}:C${lastRow + 3}`,
              `D${lastRow + 3}:F${lastRow + 3}`,
            ],
          },
          totalAmount: {
            value: endOfDay?.payments.orders
              .filter((order) => order.method === method)
              .reduce(
                (acc, order) => acc.plus(order.totalAmount),
                new Decimal(0)
              ),
            cells: [
              `B${lastRow + 4}:C${lastRow + 4}`,
              `D${lastRow + 4}:F${lastRow + 4}`,
            ],
          },
        }
      );
      lastRow = lastRow + 4;
    });

  // Inventory movement
  sheet.getRow(lastRow + 1).height = 51.75;
  sheet.mergeCells(`B${lastRow + 1}:F${lastRow + 1}`);
  sheet.getCell(`B${lastRow + 1}:F${lastRow + 1}`).font = {
    ...style,
    size: 14,
    bold: true,
    color: { argb: "ffa67744" },
  };
  sheet.getCell(`B${lastRow + 1}:F${lastRow + 1}`).alignment = {
    vertical: "middle",
  };
  sheet.getCell(`B${lastRow + 1}:F${lastRow + 1}`).value = "Inventory Movement";

  sheet.mergeCells(`B${lastRow + 2}:C${lastRow + 2}`);
  sheet.getCell(`B${lastRow + 2}:C${lastRow + 2}`).style =
    styleBackground("center");
  sheet.getCell(`B${lastRow + 2}:C${lastRow + 2}`).font = {
    bold: true,
    color: { argb: "ff707070" },
  };
  sheet.getCell(`B${lastRow + 2}:C${lastRow + 2}`).value = "Count";
  sheet.getCell(`D${lastRow + 2}`).style = styleBackground("center");
  sheet.getCell(`D${lastRow + 2}`).font = {
    bold: true,
    color: { argb: "ff707070" },
  };
  sheet.getCell(`D${lastRow + 2}`).value = "Sold Items";
  sheet.mergeCells(`E${lastRow + 2}:F${lastRow + 2}`);
  sheet.getCell(`E${lastRow + 2}:F${lastRow + 2}`).style =
    styleBackground("center");
  sheet.getCell(`E${lastRow + 2}:F${lastRow + 2}`).font = {
    bold: true,
    color: { argb: "ff707070" },
  };
  sheet.getCell(`E${lastRow + 2}:F${lastRow + 2}`).value = "Returned Items";

  sheet.mergeCells(`B${lastRow + 3}:C${lastRow + 3}`);
  sheet.getCell(`B${lastRow + 3}:C${lastRow + 3}`).alignment = {
    horizontal: "center",
    vertical: "middle",
  };
  sheet.getCell(`B${lastRow + 3}:C${lastRow + 3}`).value =
    endOfDay?.inventoryMovement.count;
  sheet.getCell(`D${lastRow + 3}`).alignment = {
    horizontal: "center",
    vertical: "middle",
  };
  sheet.getCell(`D${lastRow + 3}`).value =
    endOfDay?.inventoryMovement.noReturned.totalCountProductsTransacted;
  sheet.mergeCells(`E${lastRow + 3}:F${lastRow + 3}`);
  sheet.getCell(`E${lastRow + 3}:F${lastRow + 3}`).alignment = {
    horizontal: "center",
    vertical: "middle",
  };
  sheet.getCell(`E${lastRow + 3}:F${lastRow + 3}`).value =
    endOfDay?.inventoryMovement.returned.totalCountProductsReturned;

  lastRow = lastRow + 3;

  // Payment methods: Credit/Debit card
  sheet.getRow(lastRow + 1).height = 39.75;
  sheet.mergeCells(`B${lastRow + 1}:F${lastRow + 1}`);
  sheet.getCell(`B${lastRow + 1}:F${lastRow + 1}`).font = {
    ...style,
    size: 12,
    color: { argb: "ffa67744" },
  };
  sheet.getCell(`B${lastRow + 1}:F${lastRow + 1}`).alignment = {
    vertical: "middle",
  };
  sheet.getCell(`B${lastRow + 1}:F${lastRow + 1}`).value =
    "Payment Methods: Credit/Debit Card";
  sheet.mergeCells(`B${lastRow + 2}:C${lastRow + 2}`);
  sheet.getCell(`B${lastRow + 2}:C${lastRow + 2}`).style =
    styleBackground("center");
  sheet.getCell(`B${lastRow + 2}:C${lastRow + 2}`).font = {
    bold: true,
    color: { argb: "ff707070" },
  };
  sheet.getCell(`B${lastRow + 2}:C${lastRow + 2}`).value = "Order #";
  sheet.getCell(`D${lastRow + 2}`).style = styleBackground("center");
  sheet.getCell(`D${lastRow + 2}`).font = {
    bold: true,
    color: { argb: "ff707070" },
  };
  sheet.getCell(`D${lastRow + 2}`).value = "Customer";
  sheet.getCell(`E${lastRow + 2}`).style = styleBackground("center");
  sheet.getCell(`E${lastRow + 2}`).font = {
    bold: true,
    color: { argb: "ff707070" },
  };
  sheet.getCell(`E${lastRow + 2}`).value = "Method";
  sheet.getCell(`F${lastRow + 2}`).style = styleBackground("center");
  sheet.getCell(`F${lastRow + 2}`).font = {
    bold: true,
    color: { argb: "ff707070" },
  };
  sheet.getCell(`F${lastRow + 2}`).value = "Total Amount";

  lastRow = lastRow + 2;
  let lastRowPayment = 0;
  _.sortBy(
    endOfDay?.payments.orders.filter(
      (o) => o.method !== "CASH" && o.totalAmount.greaterThan(0.001)
    ),
    (o) =>
      o.method === "VISA"
        ? 0
        : o.method === "MASTERCARD"
        ? 1
        : o.method === "AMERICAN_EXPRESS"
        ? 2
        : 3
  ).forEach((order, index) => {
    sheet.mergeCells(`B${lastRow + (index + 1)}:C${lastRow + (index + 1)}`);
    sheet.getCell(
      `B${lastRow + (index + 1)}:C${lastRow + (index + 1)}`
    ).alignment = {
      vertical: "middle",
    };
    sheet.getCell(`B${lastRow + (index + 1)}:C${lastRow + (index + 1)}`).value =
      order.orderId;
    sheet.getCell(`D${lastRow + (index + 1)}`).alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    sheet.getCell(`D${lastRow + (index + 1)}`).value = order.customerFullName;
    sheet.getCell(`E${lastRow + (index + 1)}`).alignment = {
      horizontal: "center",
      vertical: "middle",
      wrapText: true,
    };
    sheet.getCell(`E${lastRow + (index + 1)}`).value = order.method;
    sheet.getCell(`F${lastRow + (index + 1)}`).alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    sheet.getCell(`F${lastRow + (index + 1)}`).value = order.totalAmount
      ?.toNumber()
      .toFixed(3);

    lastRowPayment++;
  });

  lastRow = lastRow + lastRowPayment;
  // Refund: Credit/Debit card
  sheet.getRow(lastRow + 1).height = 39.75;
  sheet.mergeCells(`B${lastRow + 1}:F${lastRow + 1}`);
  sheet.getCell(`B${lastRow + 1}:F${lastRow + 1}`).font = {
    ...style,
    size: 12,
    color: { argb: "ffa67744" },
  };
  sheet.getCell(`B${lastRow + 1}:F${lastRow + 1}`).alignment = {
    vertical: "middle",
  };
  sheet.getCell(`B${lastRow + 1}:F${lastRow + 1}`).value =
    "Refund: Credit/Debit Card";
  sheet.mergeCells(`B${lastRow + 2}:C${lastRow + 2}`);
  sheet.getCell(`B${lastRow + 2}:C${lastRow + 2}`).style =
    styleBackground("center");
  sheet.getCell(`B${lastRow + 2}:C${lastRow + 2}`).font = {
    bold: true,
    color: { argb: "ff707070" },
  };
  sheet.getCell(`B${lastRow + 2}:C${lastRow + 2}`).value = "Order #";
  sheet.getCell(`D${lastRow + 2}`).style = styleBackground("center");
  sheet.getCell(`D${lastRow + 2}`).font = {
    bold: true,
    color: { argb: "ff707070" },
  };
  sheet.getCell(`D${lastRow + 2}`).value = "Customer";
  sheet.getCell(`E${lastRow + 2}`).style = styleBackground("center");
  sheet.getCell(`E${lastRow + 2}`).font = {
    bold: true,
    color: { argb: "ff707070" },
  };
  sheet.getCell(`E${lastRow + 2}`).value = "Method";
  sheet.getCell(`F${lastRow + 2}`).style = styleBackground("center");
  sheet.getCell(`F${lastRow + 2}`).font = {
    bold: true,
    color: { argb: "ff707070" },
  };
  sheet.getCell(`F${lastRow + 2}`).value = "Total Amount";

  lastRow = lastRow + 2;
  lastRowPayment = 0;

  _.sortBy(
    endOfDay?.payments.orders.filter(
      (o) => o.method !== "CASH" && o.totalAmount.lessThan(0.001)
    ),
    (o) =>
      o.method === "VISA"
        ? 0
        : o.method === "MASTERCARD"
        ? 1
        : o.method === "AMERICAN_EXPRESS"
        ? 2
        : 3
  ).forEach((order, index) => {
    sheet.mergeCells(`B${lastRow + (index + 1)}:C${lastRow + (index + 1)}`);
    sheet.getCell(
      `B${lastRow + (index + 1)}:C${lastRow + (index + 1)}`
    ).alignment = {
      vertical: "middle",
    };
    sheet.getCell(`B${lastRow + (index + 1)}:C${lastRow + (index + 1)}`).value =
      order.orderId;
    sheet.getCell(`D${lastRow + (index + 1)}`).alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    sheet.getCell(`D${lastRow + (index + 1)}`).value = order.customerFullName;
    sheet.getCell(`E${lastRow + (index + 1)}`).alignment = {
      horizontal: "center",
      vertical: "middle",
      wrapText: true,
    };
    sheet.getCell(`E${lastRow + (index + 1)}`).value = order.method;
    sheet.getCell(`F${lastRow + (index + 1)}`).alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    sheet.getCell(`F${lastRow + (index + 1)}`).value = order.totalAmount
      ?.toNumber()
      .toFixed(3);

    lastRowPayment++;
  });

  lastRow = lastRow + lastRowPayment;
  // Payment methods: Cash
  sheet.getRow(lastRow + 1).height = 39.75;
  sheet.mergeCells(`B${lastRow + 1}:F${lastRow + 1}`);
  sheet.getCell(`B${lastRow + 1}:F${lastRow + 1}`).font = {
    ...style,
    size: 12,
    color: { argb: "ffa67744" },
  };
  sheet.getCell(`B${lastRow + 1}:F${lastRow + 1}`).alignment = {
    vertical: "middle",
  };
  sheet.getCell(`B${lastRow + 1}:F${lastRow + 1}`).value =
    "Payment Methods: Cash";
  sheet.mergeCells(`B${lastRow + 2}:C${lastRow + 2}`);
  sheet.getCell(`B${lastRow + 2}:C${lastRow + 2}`).style =
    styleBackground("center");
  sheet.getCell(`B${lastRow + 2}:C${lastRow + 2}`).font = {
    bold: true,
    color: { argb: "ff707070" },
  };
  sheet.getCell(`B${lastRow + 2}:C${lastRow + 2}`).value = "Order #";
  sheet.getCell(`D${lastRow + 2}`).style = styleBackground("center");
  sheet.getCell(`D${lastRow + 2}`).font = {
    bold: true,
    color: { argb: "ff707070" },
  };
  sheet.getCell(`D${lastRow + 2}`).value = "Customer";
  sheet.getCell(`E${lastRow + 2}`).style = styleBackground("center");
  sheet.getCell(`E${lastRow + 2}`).font = {
    bold: true,
    color: { argb: "ff707070" },
  };
  sheet.getCell(`E${lastRow + 2}`).value = "Method";
  sheet.getCell(`F${lastRow + 2}`).style = styleBackground("center");
  sheet.getCell(`F${lastRow + 2}`).font = {
    bold: true,
    color: { argb: "ff707070" },
  };
  sheet.getCell(`F${lastRow + 2}`).value = "Total Amount";

  lastRow = lastRow + 2;
  lastRowPayment = 0;

  endOfDay?.payments.orders
    .filter((o) => o.method === "CASH" && o.totalAmount.greaterThan(0.001))
    .forEach((order, index) => {
      sheet.mergeCells(`B${lastRow + (index + 1)}:C${lastRow + (index + 1)}`);
      sheet.getCell(
        `B${lastRow + (index + 1)}:C${lastRow + (index + 1)}`
      ).alignment = {
        vertical: "middle",
      };
      sheet.getCell(
        `B${lastRow + (index + 1)}:C${lastRow + (index + 1)}`
      ).value = order.orderId;
      sheet.getCell(`D${lastRow + (index + 1)}`).alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      sheet.getCell(`D${lastRow + (index + 1)}`).value = order.customerFullName;
      sheet.getCell(`E${lastRow + (index + 1)}`).alignment = {
        horizontal: "center",
        vertical: "middle",
        wrapText: true,
      };
      sheet.getCell(`E${lastRow + (index + 1)}`).value = order.method;
      sheet.getCell(`F${lastRow + (index + 1)}`).alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      sheet.getCell(`F${lastRow + (index + 1)}`).value = order.totalAmount
        ?.toNumber()
        .toFixed(3);
      lastRowPayment++;
    });

  lastRow = lastRow + lastRowPayment;

  const xlsx = await wb.xlsx.writeBuffer({
    // @ts-ignore
    base64: true,
  });
  saveAs(
    new Blob([xlsx], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    }),
    `endofday_${dayjs(endOfDay.from).format("YYYY-MM-DD")} - ${dayjs(
      endOfDay.to
    ).format("YYYY-MM-DD")}.xlsx`
  );
};
