import { showNotification } from "@mantine/notifications";
import React from "react";

interface ReportUserErrorProps {
  id?: string;
  title?: string;
  message?: React.ReactNode;
}
export function reportUserError(props: ReportUserErrorProps) {
  showNotification({
    id: props.id,
    title: props.title,
    message: props.message,
    color: "red",
  });
}
