import GTLoader from "#/components-ng/loader.js";
import { CardLayout } from "../components/index.js";
import { Text } from "@mantine/core";

interface NoteCardProps {
  note: string;
  isLoading: boolean;
}

export const NoteCard = ({ note, isLoading }: NoteCardProps) => {
  return (
    <CardLayout title="Note">
      {isLoading ? (
        <GTLoader width={100} height={100} />
      ) : (
        <Text className="text-sm font-normal text-[#666666]">{note ?? ""}</Text>
      )}
    </CardLayout>
  );
};
