import Checkbox from "./Checkbox/index.jsx";
import Combobox from "./Combobox/index.jsx";
import FormControl from "./FormControl/index.jsx";
import Input from "./Input/index.jsx";
import NativeSelect from "./NativeSelect/index.jsx";
// re-exports
import SearchField from "./SearchField/index.jsx";
import Select2 from "./Select2/index.js";
import Switch from "./Switch/index.jsx";
import TextArea from "./TextArea/index.jsx";
import { Box } from "@chakra-ui/react";
import { useForm, FormProvider } from "react-hook-form";

export {
  SearchField,
  Input,
  FormControl,
  Combobox,
  TextArea,
  NativeSelect,
  Checkbox,
  Switch,
  Select2,
};

/**
 *
 * @param {{
 *    defaultValues?: ?,
 *    onSubmit?: any,
 *    children?: ?,
 * } & import("@chakra-ui/react").BoxProps} props
 */
export default function Form({ defaultValues, children, onSubmit, ...props }) {
  const methods = useForm({ defaultValues });
  const { handleSubmit } = methods;

  return (
    <FormProvider {...methods}>
      <Box as="form" onSubmit={handleSubmit(onSubmit)} {...props}>
        {typeof children === "function" ? children(methods) : children}
      </Box>
    </FormProvider>
  );
}
