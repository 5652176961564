import Decimal from "decimal.js";
import { atom } from "jotai";
import { ItemSku, OrderType, PaymentType } from "server";

export const endOfDayAtom = atom({
  id: 0,
  totalOrderAmount: new Decimal(0),
  totalTaxAmount: new Decimal(0),
  totalReturnedAmount: new Decimal(0),
  totalActivityAmount: new Decimal(0),
  totalShipping: new Decimal(0),
  totalAvaliableForDeposit: new Decimal(0),
  loading: true,
  payments: {
    totalPaidIn: new Decimal(0),
    totalPaidOut: new Decimal(0),
    totalAmount: new Decimal(0),
    orders: [] as {
      method: PaymentType;
      customerFullName: string;
      orderId: number;
      totalPaidIn: Decimal;
      totalPaidOut: Decimal;
      totalAmount: Decimal;
      orderType: OrderType;
    }[],
  },
  inventoryMovement: {
    count: 0,
    returned: {
      totalAmount: new Decimal(0),
      totalCountProductsReturned: 0,
      products: [] as { item: ItemSku; quantity: number }[],
    },
    noReturned: {
      totalAmount: new Decimal(0),
      totalCountProductsTransacted: 0,
      products: [] as { item: ItemSku; quantity: number }[],
    },
  },
  ordersHistory: {
    orderCount: 0,
    orders: [] as {
      id: number;
      customerFullName?: string;
      associatedFullName?: string | null;
      total: Decimal;
      orderType: OrderType;
      receiptNumber?: number | null;
    }[],
  },
  filialId: 1,
  from: new Date(),
  to: new Date(),
  name: "",
  createdBy: "",
  summaryByOrderType: [] as {
    orderType: OrderType;
    totalPaidIn: Decimal;
    totalPaidOut: Decimal;
    totalAmount: Decimal;
  }[],
  salesActivityByOrderType: [] as {
    orderType: OrderType;
    orderCount: number;
    totalOrderAmount: Decimal;
    totalTaxAmount: Decimal;
    totalReturnedAmount: Decimal;
    totalActivityAmount: Decimal;
    totalShipping: Decimal;
  }[],
});
