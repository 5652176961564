/* eslint-disable */

export const LOGO_URL =
  "https://aemabitfolder.sfo2.digitaloceanspaces.com/Logo-gold-tree-gold.png";

export const API_URL = import.meta.env.SECRET_API_URL;
export const ENTITY_POS_API_URL = import.meta.env.SECRET_ENTITY_POS_API_URL;

export const API_KEY = "bd1a9afa-c075-4823-baaf-2d2ca74feadc";

export const CACHE_VERSION = "1";
export const PERSIST_KEY = "root";
export const PERSIST_TIMEOUT = 9000000000000000;
export const PERSIST_STORAGE_KEY = `${CACHE_VERSION}${PERSIST_KEY}`;

export const isProductionBuild = true;

export const ReCAPTCHASiteKey = "6LflEQ8dAAAAAFdrAoxtE9pwsVpl1DkdFJ3IsiAr";
export const ReCAPTCHASecretKey = "6LflEQ8dAAAAALW7HypPmLoeC2nI0aMTzkuh-H2S";
export const S3_URL = "https://goldtree-assets.s3.amazonaws.com";
export const SHOP_BASE_URL = import.meta.env.SHOP_BASE_URL;