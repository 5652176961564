import GTLoader from "#/components-ng/loader.js";
import { LOGO_URL } from "#/constants/envt.js";
import { trpc } from "#/trpc.js";
import { reportUserError } from "#/util/index.js";
import { applyDiscountV2 } from "../util/index.js";
import * as M from "@mantine/core";
import dayjs from "dayjs";
import Decimal from "decimal.js";
import React from "react";
import { useParams } from "react-router";

export const ReturnPdf = () => {
  const [imageLoaded, setImageLoaded] = React.useState(false);
  const { reference } = useParams();
  const { data, isLoading } = trpc.refundOrderItemSku.getByReference.useQuery(
    {
      reference: Number(reference),
    },
    {
      onError(error) {
        reportUserError({
          title: "Failed to load refund",
          message: error.message,
        });
      },
    }
  );

  React.useEffect(() => {
    if (data && imageLoaded) {
      window.print();
    }
  }, [data, imageLoaded]);
  if (isLoading)
    return <M.LoadingOverlay loader={<GTLoader />} visible={isLoading} />;

  const refunds = data;
  const billTo = refunds && refunds?.length > 0 ? refunds[0].customer : null;
  const total = refunds?.reduce((acc, entry) => {
    return new Decimal(entry?.total ?? 0)?.plus(acc)?.toNumber();
  }, 0);

  return (
    <>
      <M.Stack spacing={0}>
        <M.Text>Printed: {dayjs().format("MM/DD/YYYY")}</M.Text>
        <M.Text>
          Refund Date:{" "}
          {dayjs(
            refunds && refunds?.length > 0 ? refunds[0].createdAt : ""
          ).format("MM/DD/YYYY")}
        </M.Text>
        <M.Text>Store: 1</M.Text>
        <M.Text>Workstation: 1</M.Text>
        <M.Text>Order R{reference}</M.Text>
      </M.Stack>
      <M.Group position="center">
        <M.Stack align="center" spacing={1}>
          <M.Title order={3}>REFUND</M.Title>
          <M.Image
            src={LOGO_URL}
            width={150}
            onLoad={() => setImageLoaded(true)}
          />
          <M.Text weight="bold">GOLD TREE</M.Text>
          <M.Text>755 NW 72nd AVE PLAZA 33</M.Text>
          <M.Text>MIAMI, FL, 33126</M.Text>
          <M.Text>PHONE: +1 (786) 7177131</M.Text>
        </M.Stack>
      </M.Group>
      <M.Stack>
        <M.Text>
          Bill to:{" "}
          <M.Text span>
            {billTo && billTo?.company
              ? `${billTo?.company}`
              : `${billTo?.firstName} ${billTo?.lastName ?? ""}`}
          </M.Text>
        </M.Text>
      </M.Stack>
      <M.Table
        sx={(t) => ({
          "td,th": { border: `solid 1px ${t.colors.gray[3]}` },
        })}
      >
        <thead>
          <tr>
            <th>SKU</th>
            <th>Qty</th>
            <th>Product Name</th>
            <th>Price</th>
            <th>Discount</th>
            <th>Ext. price</th>
          </tr>
        </thead>
        <tbody>
          {refunds?.map((p, i) => (
            <tr key={i}>
              <td>{p?.orderItemSku.itemSku.sku}</td>
              <td>-{p?.quantity}</td>
              <td>{p?.orderItemSku.itemSku.title}</td>
              <td>-${p?.orderItemSku.price.toNumber().toFixed(2)}</td>
              <td>-{`$${p?.orderItemSku.discountAmount?.toNumber() ?? 0}`}</td>
              <td>-${p?.total.toNumber().toFixed(2) ?? 0}</td>
            </tr>
          ))}
          <tr>
            <td style={{ border: "none" }}></td>
            <td style={{ border: "none" }}></td>
            <td style={{ border: "none" }}></td>
            <td style={{ border: "none" }}></td>
            <td>Subtotal:</td>
            <td>$0</td>
          </tr>
          <tr>
            <td style={{ border: "none" }}></td>
            <td style={{ border: "none" }}></td>
            <td style={{ border: "none" }}></td>
            <td style={{ border: "none" }}></td>
            <td>Discount:</td>
            <td>
              -$
              {refunds?.reduce(
                (old, e) =>
                  old +
                  (e.orderItemSku.price.toNumber() * e.quantity -
                    (applyDiscountV2(
                      e.orderItemSku.price.times(e.quantity),
                      e.orderItemSku?.discountAmount &&
                        e.orderItemSku?.discountType
                        ? {
                            amount: e.orderItemSku?.discountAmount,
                            type: e.orderItemSku?.discountType,
                          }
                        : null
                    )?.toNumber() ?? 0)),
                0
              )}
            </td>
          </tr>
          <tr>
            <td style={{ border: "none" }}></td>
            <td style={{ border: "none" }}></td>
            <td style={{ border: "none" }}></td>
            <td style={{ border: "none" }}></td>
            <M.Box
              component="td"
              sx={(t) => ({
                border: `solid 1px ${t.colors.gray[3]} !important`,
              })}
            >
              Total
            </M.Box>
            <M.Box
              component="td"
              sx={(t) => ({
                border: `solid 1px ${t.colors.gray[3]} !important`,
              })}
            >
              -${total?.toFixed(2) ?? 0}
            </M.Box>{" "}
          </tr>
        </tbody>
      </M.Table>
    </>
  );
};
