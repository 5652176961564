import { Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { useFormContext } from "react-hook-form";

/**
 *
 * @param {{ name?: string, inputProps?: ?, placeholder?: string, onChange?: ?}
 * & import("@chakra-ui/react").InputGroupProps} props
 */
const SearchField = ({
  name = "search",
  inputProps,
  placeholder = "Seach...",
  onChange,
  value = undefined,
  ...props
}) => {
  const { register = null } = useFormContext() || {};
  const formProps = register ? register(name) : {};

  return (
    <InputGroup {...props}>
      <InputLeftElement
        pointerEvents="none"
        children={<SearchIcon color="gray.300" />}
      />
      <Input
        borderRadius="full"
        placeholder={placeholder}
        bg="white"
        value={value}
        {...formProps}
        {...inputProps}
        onChange={(e) => {
          if (onChange) {
            onChange(e);
          }
          if (formProps.onChange) {
            formProps.onChange(e);
          }
        }}
      />
    </InputGroup>
  );
};

export default SearchField;
