import SceneLayout from "#/components/SceneLayout/index.jsx";
import { AiOutlineSetting } from "react-icons/ai/index.js";
import { Outlet } from "react-router";

export const SettingsLayout = () => {
  return (
    <SceneLayout icon={<AiOutlineSetting />}>
      <Outlet />
    </SceneLayout>
  );
};
