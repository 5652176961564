import GTLoader from "#/components-ng/loader";
import { useAuth } from "#/context/AuthContext";
import { trpc } from "#/trpc";
import { reportUserError, reportUserSuccess } from "#/util";
import {
  FilialForm,
  FilialFormValues,
  RolesAllowed,
  fileDisclosureAtom,
  fileTermsAtom,
} from ".";
import { LoadingOverlay } from "@mantine/core";
import Decimal from "decimal.js";
import { useNavigate } from "react-router";

interface UpdateFilialProps {
  id?: number | null;
}

export const UpdateFilial = (props: UpdateFilialProps) => {
  const [{ auth }] = useAuth();
  const setFileTerms = useSetAtom(fileTermsAtom);
  const setFileDisclosure = useSetAtom(fileDisclosureAtom);
  const navigate = useNavigate();

  const { data, isLoading, isFetchedAfterMount } = trpc.filial.getById.useQuery(
    {
      id: props.id!,
    },
    {
      cacheTime: 0,
      enabled: !!props.id,
      onSuccess: (data) => {
        setFileTerms({
          url: data?.termsAndConditions ?? "",
          isLoading: false,
        });
        setFileDisclosure({
          url: data?.disclosure ?? "",
          isLoading: false,
        });
      },
      onError: (error) => {
        reportUserError({
          title: "Failed to load filial",
          message: error.message,
        });
      },
    }
  );

  const rolesAllowed: RolesAllowed[] = [
    "ENTITY MANAGER",
    "FILIAL MANAGER",
    "ROOT",
  ];

  const { mutate, isLoading: isLoadingUpdate } = trpc.filial.update.useMutation(
    {
      onSuccess: () => {
        reportUserSuccess({
          title: "Filial updated successfully",
          message: "",
        });
        navigate("/settings");
      },
      onError: (error) => {
        reportUserError({
          title: "Failed to update filial",
          message: error.message,
        });
      },
    }
  );

  const handleSubmit = (values: FilialFormValues) => {
    if (rolesAllowed.includes(auth?.role.name as any)) {
      mutate({
        id: Number(props.id),
        input: {
          ...values,
          entityId: 1,
        },
      });
      return;
    } else {
      reportUserError({
        title: "You are not allowed to edit this filial",
      });
      return;
    }
  };

  return isLoading || !isFetchedAfterMount ? (
    <LoadingOverlay
      visible={isLoading}
      loader={<GTLoader width={100} height={100} />}
    />
  ) : (
    <FilialForm
      rolesAllowed={rolesAllowed}
      defaultValues={{
        avatar: data?.avatar ?? "",
        name: data?.name ?? "",
        phoneNumber: data?.phoneNumber ?? "",
        about: data?.about ?? "",
        verified: data?.verified ?? false,
        disclosure: data?.disclosure ?? "",
        termsAndConditions: data?.termsAndConditions ?? "",
        areaCode: data?.areaCode ?? "",
        countryCode: data?.countryCode ?? "",
        street: data?.street ?? "",
        city: data?.city ?? "",
        state: data?.state ?? "",
        country: data?.country ?? "",
        zip: data?.zip ?? "",
        taxRate: Number(new Decimal(data?.taxRate ?? 0)),
        adminPin: data?.adminPin ?? "",
        defaultImage: data?.defaultImage ?? null,
        newArrivalSectionImage: data?.newArrivalSectionImage ?? null,
        email: data?.email ?? null,
        timezone: data?.timezone ?? null,
        brevis: data?.brevis ?? null,
        salesWithoutStock: data?.salesWithoutStock ?? false,
        notifyLowerStock: data?.notifyLowerStock ?? false,
        entityId: 1,
        type: data?.type ?? "SHOP",
      }}
      isLoading={isLoadingUpdate}
      onSubmit={handleSubmit}
    />
  );
};
