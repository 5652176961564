import { SearchButton } from "#/components@v2_5/search-button";
import { css } from "#/css/css";
import { HStack } from "#/css/jsx";
import { RouterOutputs, trpc } from "#/trpc";
import { Button, Dropdown } from "@gt/ui";
import { DropdownMenu } from "@radix-ui/themes";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_SortingState,
  useMantineReactTable,
} from "mantine-react-table";
import { Link } from "react-router-dom";
import MenuIcon from "~icons/ion/ellipsis-horizontal-outline";
import EditIcon from "~icons/ion/create-outline";
import dayjs from "dayjs";

export const SysSectionsChangelogSection = () => {
  const [search, setSearch] = useState<string | null>(null);

  return (
    <>
      <HStack justify="space-between" gap={3}>
        <DrowndownButton />
        <SearchButton setQuery={setSearch} />
      </HStack>
      <div
        className={css({
          marginTop: 5,
        })}
      >
        <SysSectionsTable search={search} />
      </div>
    </>
  );
};

const DrowndownButton = () => {
  return (
    <Dropdown.Root>
      <Dropdown.Trigger>
        <Button
          className={css({
            gap: 4,
          })}
        >
          I want to
          <DropdownMenu.TriggerIcon />
        </Button>
      </Dropdown.Trigger>
      <Dropdown.Content>
        <Dropdown.Item>
          <Link to="create">Create</Link>
        </Dropdown.Item>
      </Dropdown.Content>
    </Dropdown.Root>
  );
};

interface SysSectionTableProps {
  search?: string | null;
}

const SysSectionsTable = (props: SysSectionTableProps) => {
  // pagination
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 25,
  });

  // sorting
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const querySorting = useMemo(() => {
    if (sorting[0]) {
      return {
        desc: sorting[0].desc,
        key: sorting[0].id as any,
      };
    }
    return null;
  }, [sorting]);

  const { data, isLoading } =
    trpc.v2_5.changelog.sysSections.getByPage.useQuery(
      {
        pageIndex: pagination.pageIndex,
        pageSize: pagination.pageSize,
        sorting: querySorting,
        search: props.search,
        filters: null,
      },
      {
        cacheTime: 0,
        keepPreviousData: true,
      },
    );

  const table = useMantineReactTable({
    data: data?.entries ?? [],
    columns,
    manualPagination: true,
    enableTopToolbar: false,
    rowCount: data?.totalEntries ?? 0,
    enableFilters: false,
    state: {
      pagination,
      sorting,
      isLoading,
    },
    enableStickyHeader: true,
    manualSorting: true,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
  });

  return <MantineReactTable table={table} />;
};

type SysBlock =
  RouterOutputs["v2_5"]["changelog"]["sysSections"]["getByPage"]["entries"][number];

const columns: MRT_ColumnDef<SysBlock>[] = [
  {
    header: "Actions",
    id: "actions",
    enableSorting: false,
    size: 100,
    Cell: (table) => {
      const original = table.row.original;

      return (
        <>
          <Dropdown.Root>
            <Dropdown.Trigger>
              <Button variant="ghost" size="icon">
                <MenuIcon />
              </Button>
            </Dropdown.Trigger>
            <Dropdown.Content>
              <Dropdown.Item asChild>
                <Link to={`edit/${original.id}`}>
                  <div
                    className={css({
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 2,
                    })}
                  >
                    <EditIcon />
                    Edit
                  </div>
                </Link>
              </Dropdown.Item>
            </Dropdown.Content>
          </Dropdown.Root>
        </>
      );
    },
  },
  {
    header: "Name",
    id: "name",
    accessorKey: "name",
  },
  {
    header: "SysBlock",
    id: "block",
    accessorKey: "sysBlock.name",
  },
  {
    header: "Date",
    id: "createdAt",
    accessorKey: "createdAt",
    Cell: (table) => {
      const createdAt = table.row.original.createdAt;
      if (!createdAt) return "";
      return dayjs(createdAt).format("MM/DD/YYYY").toString();
    },
  },
];
