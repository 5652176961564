import { makeController } from "#/components-ng/index.js";
import { z } from "zod";

export const createItemSchema = z.object({
  title: z
    .string({
      required_error: "Title is required",
    })
    .min(3, {
      message: "Title must be at least 3 characters",
    })
    .max(255, {
      message: "Title must be at most 255 characters",
    }),
  department: z.object({
    id: z.number({
      required_error: "Department is required",
    }),
    name: z.string(),
    active: z.boolean(),
  }),
  category: z.object({
    id: z.number({
      required_error: "Category is required",
    }),
    name: z.string(),
    active: z.boolean(),
  }),
  season: z.object({
    id: z.number().optional(),
    name: z.string().optional(),
    active: z.boolean().optional(),
  }),
  description: z.string().optional(),
  taxable: z.boolean().optional().default(true),
  active: z.boolean().optional().default(true),
  condition: z.string().optional().default("NEW"),
  material: z.string().optional().nullish(),
});

export type FormValues = z.infer<typeof createItemSchema>;
export const C = makeController<FormValues>();
