import {
  ArrowCircleIcon,
  CurrencyCircleDollarIcon,
  FileIcon,
  MoneyIcon,
  TruckIcon,
} from "#/components-ng/icons.js";
import { DetailSalesBox, WidgetContainer } from "./common.js";
import { endOfDayAtom } from "./state.js";
import * as M from "@mantine/core";
import { useAtomValue } from "jotai";

export const GeneralInformation = () => {
  const endOfDay = useAtomValue(endOfDayAtom);

  return (
    <WidgetContainer>
      <M.Stack>
        <M.Group position="apart" mb={20}>
          <M.Title order={3} color="#3D3D3D">
            General information
          </M.Title>
          <M.Text weight={500} className="text-slate-500">
            {endOfDay.name}
          </M.Text>
        </M.Group>
        <M.SimpleGrid
          cols={5}
          spacing="lg"
          breakpoints={[
            { maxWidth: "70rem", cols: 3, spacing: "md" },
            { maxWidth: "50rem", cols: 2, spacing: "sm" },
            { maxWidth: "36rem", cols: 1, spacing: "sm" },
          ]}
        >
          <DetailSalesBox
            title="Total order"
            total={endOfDay.totalOrderAmount.toNumber()}
            color="bg-emerald-50"
            icon={<CurrencyCircleDollarIcon />}
          />
          <DetailSalesBox
            title="Total tax"
            total={endOfDay.totalTaxAmount.toNumber()}
            color="bg-blue-50"
            icon={<FileIcon />}
          />
          <DetailSalesBox
            title="Total activity"
            total={endOfDay.totalActivityAmount.toNumber()}
            color="bg-purple-50"
            icon={<MoneyIcon />}
          />
          <DetailSalesBox
            title="Total returns"
            total={endOfDay.totalReturnedAmount.toNumber()}
            color="bg-rose-50"
            icon={<ArrowCircleIcon />}
          />
          <DetailSalesBox
            title="Total shipping"
            total={endOfDay.totalShipping.toNumber()}
            color="bg-yellow-50"
            icon={<TruckIcon />}
          />
        </M.SimpleGrid>
      </M.Stack>
      <M.LoadingOverlay visible={endOfDay.loading} />
    </WidgetContainer>
  );
};
