import { BadgeDetails, SearchableInput, WidgetContainer } from "./common.js";
import { endOfDayAtom } from "./state.js";
import * as M from "@mantine/core";
import Decimal from "decimal.js";
import { useAtomValue } from "jotai";
import {
  MRT_ColumnDef,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import React from "react";
import { MdFilterAlt } from "react-icons/md";
import { CacheEndOfDay, OrderType, PaymentType } from "server";

const payments: PaymentType[] = [
  "CASH",
  "VISA",
  "MASTERCARD",
  "AMERICAN_EXPRESS",
  "DISCOVER",
  "OTHER",
  "ZELLE",
  "PAYPAL",
  "BANK_TRANSFER",
  "USER_CREDITS",
];

interface FilterPayments {
  CASH: boolean;
  VISA: boolean;
  MASTERCARD: boolean;
  AMERICAN_EXPRESS: boolean;
  DISCOVER: boolean;
  OTHER: boolean;
  ZELLE: boolean;
  PAYPAL: boolean;
  BANK_TRANSFER: boolean;
  USER_CREDITS: boolean;
}

export const Payments = () => {
  const endOfDay = useAtomValue(endOfDayAtom);
  const [query, setQuery] = React.useState<string | null>("");
  const [filterPayments, setFilterPayments] = useState<FilterPayments>({
    CASH: true,
    VISA: true,
    MASTERCARD: true,
    AMERICAN_EXPRESS: true,
    DISCOVER: true,
    OTHER: true,
    ZELLE: true,
    PAYPAL: true,
    BANK_TRANSFER: true,
    USER_CREDITS: true,
  });

  const [totalBadges, setTotalBadges] = useState<{
    totalPaidIn: Decimal;
    totalPaidOut: Decimal;
    totalAmount: Decimal;
  }>(() => getTotalBadges(filterPayments, endOfDay.payments.orders));

  const tableData = useMemo(() => {
    return (
      endOfDay.payments.orders
        .filter(
          (o) => filterPayments[o.method as keyof FilterPayments] === true
        )
        .filter((o) => {
          if (!query) return true;
          return o.customerFullName
            ?.toLowerCase()
            .includes(query.toLowerCase());
        }) ?? []
    );
  }, [endOfDay.payments.orders, filterPayments, query]);

  useEffect(() => {
    setTotalBadges(getTotalBadges(filterPayments, endOfDay.payments.orders));
  }, [endOfDay.payments.orders, filterPayments]);

  const table = useMantineReactTable({
    data: tableData,
    columns: columns,
    initialState: { density: "xs" },
    enableColumnFilters: false,
    enableTopToolbar: false,
    enableFilters: false,
  });

  return (
    <WidgetContainer>
      <M.Stack>
        <M.Title order={3} mb={10} color="#3D3D3D">
          Payments
        </M.Title>
        <M.Group position="left" mb={20}>
          <BadgeDetails
            title="Total paid in"
            total={totalBadges.totalPaidIn.toNumber()}
            isMoney={true}
          />
          <BadgeDetails
            title="Total paid out"
            total={totalBadges.totalPaidOut.toNumber()}
            isMoney={true}
          />
          <BadgeDetails
            title="Total activity"
            total={totalBadges.totalAmount.toNumber()}
            isMoney={true}
          />
        </M.Group>
        <M.Divider />
        <M.Group position="apart" mb={20}>
          <SearchableInput
            placeholder="Search by customer"
            onInput={(e) => setQuery(e.currentTarget.value)}
          />
          <M.Popover zIndex={9000}>
            <M.Popover.Target>
              <M.Button
                variant="light"
                color="dark"
                bg="gray.2"
                leftIcon={<MdFilterAlt />}
              >
                Filter by payment method
              </M.Button>
            </M.Popover.Target>
            <M.Popover.Dropdown>
              <M.Stack>
                {payments.map((payment) => (
                  <M.Checkbox
                    key={payment}
                    label={payment}
                    checked={filterPayments[payment]}
                    onChange={() =>
                      setFilterPayments({
                        ...filterPayments,
                        [payment]: !filterPayments[payment],
                      })
                    }
                  />
                ))}
              </M.Stack>
            </M.Popover.Dropdown>
          </M.Popover>
        </M.Group>
        <MantineReactTable table={table} />
      </M.Stack>
      <M.LoadingOverlay visible={endOfDay.loading} zIndex={9999} />
    </WidgetContainer>
  );
};

const columns: Array<
  MRT_ColumnDef<{
    customerFullName: string;
    method: PaymentType;
    totalPaidIn: Decimal;
    totalPaidOut: Decimal;
    totalAmount: Decimal;
    orderId: number;
    orderType: OrderType;
  }>
> = [
  {
    header: "Customer",
    accessorKey: "customerFullName",
  },
  {
    header: "Payment method",
    accessorKey: "method",
  },
  {
    header: "Total paid in",
    accessorKey: "totalPaidIn",
    Cell: (table) => {
      return `$${table.row.original.totalPaidIn.toFixed(2)}`;
    },
  },
  {
    header: "Total paid out",
    accessorKey: "totalPaidOut",
    Cell: (table) => {
      return `$${table.row.original.totalPaidOut.toFixed(2)}`;
    },
  },
  {
    header: "Total activity",
    accessorKey: "totalAmount",
    Cell: (table) => {
      return `$${table.row.original.totalAmount.toFixed(2)}`;
    },
  },
];

const getTotalBadges = (
  filters: FilterPayments,
  payments: CacheEndOfDay["payments"]["orders"]
) => {
  return payments
    .filter((payment) => filters[payment.method as keyof FilterPayments])
    .reduce(
      (acc, payment) => {
        return {
          totalPaidIn: acc.totalPaidIn.add(payment.totalPaidIn),
          totalPaidOut: acc.totalPaidOut.add(payment.totalPaidOut),
          totalAmount: acc.totalAmount.add(payment.totalAmount),
        };
      },
      {
        totalPaidIn: new Decimal(0),
        totalPaidOut: new Decimal(0),
        totalAmount: new Decimal(0),
      }
    );
};
