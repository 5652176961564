import GTLoader from "#/components-ng/loader.js";
import { trpc } from "#/trpc.js";
import { reportUserError, reportUserSuccess } from "#/util/index.js";
import { FormValues, PromotionForm } from "./PromotionForm.js";
import * as M from "@mantine/core";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone.js";
import utc from "dayjs/plugin/utc.js";
import React from "react";
import { useParams, useNavigate } from "react-router-dom";

dayjs.extend(utc);
dayjs.extend(timezone);

export const UpdatePromotion = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const ctx = trpc.useContext();

  const { data, isLoading, isFetchedAfterMount } =
    trpc.promotion.getById.useQuery(
      {
        id: Number(id!),
      },
      {
        cacheTime: 0,
        onError: (err) => {
          reportUserError({
            title: "Failed to load promotion",
            message: err.message,
          });
        },
      }
    );

  const defaultValues = React.useMemo(() => {
    if (data?.promotion == null) return null;

    const promotion = data.promotion;

    return {
      active: promotion.active,
      activeRange: [
        new Date(dayjs(promotion.activeAt).tz("America/New_York").toDate()),
        new Date(dayjs(promotion.inactiveAt).tz("America/New_York").toDate()),
      ] as [Date, Date],
      name: promotion.name,
      description: promotion.description || "",
      discount: promotion.discount.id,
      defaultImage: promotion.defaultImage ?? null,
      accentHexColor: promotion.accentHexColor,
      withTimer: promotion.withTimer,
    };
  }, [data]);

  const { mutate, isLoading: isLoadingUpdate } =
    trpc.v2_5.promotion.update.useMutation({
      onSuccess: () => {
        reportUserSuccess({
          title: "Promotion updated",
          message: "Promotion updated successfully",
        });
        ctx.promotion.invalidate();
        ctx.promotionItemSku.invalidate();
        ctx.v2_5.promotion.invalidate();
        navigate("/inventory/promotions");
      },
      onError: (err) => {
        reportUserError({
          title: "Failed to update promotion",
          message: err.message,
        });
      },
    });

  const handleSubmit = (values: FormValues) => {
    mutate({
      id: Number(id!),
      activeRange: [
        dayjs(values.activeRange![0]).toDate(),
        dayjs(values.activeRange![1]).toDate(),
      ],
      discountId: Number(values.discount),
      name: values.name,
      description: values.description,
      defaultImage: values?.defaultImage ? values.defaultImage : null,
      accentHexColor: values.accentHexColor,
      withTimer: values.withTimer,
    });
  };

  return isLoading || !isFetchedAfterMount ? (
    <M.LoadingOverlay
      visible={isLoading}
      loader={<GTLoader width={100} height={100} />}
    />
  ) : (
    <PromotionForm
      onSubmit={handleSubmit}
      defaultValues={defaultValues as FormValues}
      loading={isLoadingUpdate}
    />
  );
};
