// @ts-nocheck
import { Changelog } from "#/changelog/changelog.js"
import { changelogDialogAtom } from "#/changelog/dialog.state.js"
import { MenuOutlineIcon } from "#/components-ng/index.js"
import Form from "#/components/Form/index.jsx"
import { LOGO_URL } from "#/constants/envt.js"
import { useAuth } from "#/context/AuthContext.js"
import { css } from "#/css/css"
import { styled } from "#/css/jsx"
import { token } from "#/css/tokens"
import { flattenedRoutes } from "#/scenes/routes.js"
import { trpc } from "#/trpc.js"
import { reportUserError } from "#/util/index.js"
import { HeaderAuth } from "./HeaderAuth.js"
import { Search2Icon } from "@chakra-ui/icons"
import * as C from "@chakra-ui/react"
import * as M from "@mantine/core"
import { Badge, Tooltip } from "@radix-ui/themes"
import Fuse from "fuse.js"
import { atom, useSetAtom } from "jotai"
import React, { useMemo, useState } from "react"
import "react-circular-progressbar/dist/styles.css"
import { Link } from "react-router-dom"

// eslint-disable-next-line @typescript-eslint/no-empty-function
const closeSearchMenuFuncAtom = atom({
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	callback: () => {},
})
const closeSearchMenuAtom = atom(null, (get, set, _) => {
	get(closeSearchMenuFuncAtom).callback()
})

const Header = ({ onMenuToggle }) => {
	const searchPopoverRef = React.useRef<HTMLDivElement | null>(null)
	const searchPopoverContainerRef = React.useRef<HTMLDivElement | null>(null)
	const setCloseSearchMenu = useSetAtom(closeSearchMenuFuncAtom)
	const setChangelogDialog = useSetAtom(changelogDialogAtom)

	const searchDisclosure = C.useDisclosure()
	C.useOutsideClick({
		ref: searchPopoverRef,
		handler: (e) => {
			if (
				e.currentTarget &&
				!searchPopoverContainerRef.current?.contains(e.target as Node)
			) {
				searchDisclosure.onClose()
			}
		},
	})

	React.useEffect(() => {
		setCloseSearchMenu({
			callback: searchDisclosure.onClose,
		})
	}, [searchDisclosure, setCloseSearchMenu])

	const [query, setQuery] = useState("")
	const routesFuse = C.useConst(
		() =>
			new Fuse(
				flattenedRoutes.filter((r) => !!r.breadcrumb && !r.skipIndex),
				{
					keys: ["path", "breadcrumb"],
					minMatchCharLength: 2,
					threshold: 0.4,
					includeScore: true,
				},
			),
	)
	const routesResults = useMemo(
		() => routesFuse.search(query).slice(0, 5),
		[query, routesFuse],
	)

	const usersQuery = trpc.user.search.useQuery({
		query,
	})
	const itemSkusQuery = trpc.itemSku.search.useQuery({
		query: `\\"${query}\\"`,
	})

	const [{ auth }] = useAuth()

	const { data: dataEntity } = trpc.entity.getById.useQuery(
		{
			id: 1,
		},
		{
			cacheTime: 0,
			enabled: !auth?.user?.filialId,
		},
	)

	const { data: dataFilial } = trpc.filial.getById.useQuery(
		{
			id: auth?.user?.filialId ?? 0,
		},
		{
			cacheTime: 0,
			enabled: !!auth?.user?.filialId,
		},
	)
	const [showUpdateVersionBadge, setShowUpdateVersionBadge] =
		useState<boolean>(false)
	const currentVersion = localStorage.getItem("sysVersion")

	const currentVersionParsed = JSON.parse(currentVersion) as {
		version: string
		required_reload: boolean
		user_already_reload: boolean
	} | null

	const { data: dataChangelog } =
		trpc.v2_5.changelog.sysVersions.getLastChangelog.useQuery(undefined, {
			onSuccess(data) {
				if (data?.sysChangeSets.length > 0) {
					if (currentVersion) {
						const someExistsRequiredReload = data?.sysChangeSets.find(
							(changeSet) => changeSet.requiredReload === true,
						)

						if (currentVersionParsed.version !== data?.version) {
							localStorage.setItem(
								"sysVersion",
								JSON.stringify({
									version: data?.version,
									required_reload: someExistsRequiredReload ? true : false,
									user_already_reload: false,
								}),
							)

							setShowUpdateVersionBadge(true)

							reportUserError({
								message:
									"New version available, please reload the page to get the latest changes",
								title: `New version available: ${data?.version}`,
							})
						} else {
							localStorage.setItem(
								"sysVersion",
								JSON.stringify({
									version: data?.version,
									required_reload: someExistsRequiredReload ? true : false,
									user_already_reload: true,
								}),
							)
						}
					} else {
						localStorage.setItem(
							"sysVersion",
							JSON.stringify({
								version: data?.version,
								required_reload: true,
								user_already_reload: false,
							}),
						)

						setShowUpdateVersionBadge(true)
					}
				}
			},
		})

	useEffect(() => {
		if (dataChangelog) {
			const changelog = new Changelog({
				date: new Date(dataChangelog?.createdAt),
				changes: dataChangelog?.sysChangeSets.map((changeSet) => {
					const moduleName =
						changeSet.sysSection.sysBlock.name === changeSet.sysSection.name
							? changeSet.sysSection.sysBlock.name
							: `${changeSet.sysSection.sysBlock.name}.${changeSet.sysSection.name}`

					return {
						module: moduleName,
						display: true,
						type: changeSet.type === "FEATURE" ? "feature" : "fix",
					}
				}),
			})

			setChangelogDialog({
				open: false,
				changelog,
			})
		}
	}, [dataChangelog, setChangelogDialog])

	return (
		<>
			<C.Flex
				as="header"
				padding="8px 72px 8px 36px"
				justify="space-between"
				height="60px"
				borderBottom="solid 1px"
				borderBottomColor="gray.200"
				position="sticky"
				top="0"
				zIndex="15"
				className={css({
					display: "none !important",
					lg: {
						display: "flex !important",
					},
				})}
			>
				<C.Flex align="center" gap={4}>
					<C.Button
						onClick={onMenuToggle}
						display="inline-block"
						cursor="pointer"
						colorScheme="gray"
						variant="ghost"
						border="none"
						borderRadius="sm"
					>
						<MenuOutlineIcon />
					</C.Button>
					<C.Flex as={Link} to="/" gap={2} align="center">
						{dataEntity || dataFilial ? (
							<C.Image
								className="logo"
								src={
									dataFilial?.avatar ||
									LOGO_URL ||
									dataFilial?.avatar ||
									LOGO_URL
								}
								alt="gold tree logo"
								maxH="30px"
							/>
						) : null}
						<C.Text fontWeight="600" fontSize="1rem">
							{dataFilial?.name || dataEntity?.name || "Gold Tree"}
						</C.Text>
						{process.env.NODE_ENV === "development" && (
							<M.Badge bg={token("colors.red.500")} c="white">
								DEV
							</M.Badge>
						)}
						{auth?.user?.filialId && (
							<M.Badge bg={token("colors.yellow.500")} c="white">
								{dataFilial?.type ?? ""}
							</M.Badge>
						)}
						{dataChangelog && (
							<M.Badge bg={token("colors.blue.500")} c="white">
								v{currentVersionParsed?.version ?? ""}
							</M.Badge>
						)}
						{currentVersion && showUpdateVersionBadge && (
							<Tooltip content="New version available, please reload the page to get the latest changes">
								<M.Badge c="white" bg={token("colors.red.500")}>
									New version available
								</M.Badge>
							</Tooltip>
						)}
					</C.Flex>
				</C.Flex>
				<SearchSection
					searchPopoverContainerRef={searchPopoverContainerRef}
					searchDisclosure={searchDisclosure}
					setQuery={setQuery}
					routesResults={routesResults}
					usersQuery={usersQuery}
					itemSkusQuery={itemSkusQuery}
					query={query}
					searchPopoverRef={searchPopoverRef}
				/>
				<HeaderAuth />
			</C.Flex>

			<styled.header
				display={{
					base: "flex",
					lg: "none",
				}}
				zIndex="101"
				position="sticky"
				top={0}
				height="auto"
				flexDirection="column"
				padding="8px 16px"
				width="100%"
				backgroundColor="#FFF"
			>
				<styled.div
					display="flex"
					justifyContent="space-between"
					alignItems="center"
					backgroundColor="#F8FAFC"
					paddingY="0.5rem"
				>
					<styled.p fontSize="14px" color="#666666">
						{dataFilial?.name || dataEntity?.name || "Gold Tree"}
					</styled.p>
					{dataChangelog && (
						<Badge
							color="blue"
							className={css({
								backgroundColor: "blue.500 !important",
								color: "white !important",
							})}
							size="2"
							radius="full"
						>
							v{currentVersionParsed?.version ?? ""}
						</Badge>
					)}
				</styled.div>
				<styled.div
					display="flex"
					justifyContent="space-between"
					alignItems="center"
					paddingY="0.5rem"
					backgroundColor="#FFF"
				>
					<Link to="/">
						{dataEntity || dataFilial ? (
							<styled.img
								className="logo"
								src={dataFilial?.avatar || dataEntity?.avatar || LOGO_URL}
								alt="gold tree logo"
								maxHeight="50px"
								maxWidth="80px"
								height="100%"
								width="100%"
							/>
						) : null}
					</Link>
					<HeaderAuth />
				</styled.div>
				<styled.div width="100%" paddingY="0.5rem" backgroundColor="#FFF">
					<SearchSection
						searchPopoverContainerRef={searchPopoverContainerRef}
						searchDisclosure={searchDisclosure}
						setQuery={setQuery}
						routesResults={routesResults}
						usersQuery={usersQuery}
						itemSkusQuery={itemSkusQuery}
						query={query}
						searchPopoverRef={searchPopoverRef}
					/>
				</styled.div>
			</styled.header>
		</>
	)
}

interface SearchResultEntriesProps<T> {
	items?: Array<T> | null
	header: string
	title: (item: T) => string
	description: (item: T) => string
	path: (item: T) => string
}

function SearchResultEntries<T>({
	items,
	header,
	title,
	description,
	path,
}: SearchResultEntriesProps<T>) {
	const closeSearchMenu = useSetAtom(closeSearchMenuAtom)

	if (!items || !items?.length) {
		return <></>
	}
	return (
		<>
			<C.Text fontWeight="bold" px={4} py={1}>
				{header}
			</C.Text>
			<C.Divider />
			{items.map((item, i) => (
				<C.LinkBox
					as={C.VStack}
					key={i}
					_hover={{ bg: "gray.50" }}
					px={4}
					py={1}
					w="100%"
					spacing={1}
					onClick={closeSearchMenu}
				>
					<C.LinkOverlay as={Link} to={path(item)} fontWeight="500">
						{title(item)}
					</C.LinkOverlay>
					<C.Text fontSize="xs" color="gray.400">
						{description(item)}
					</C.Text>
				</C.LinkBox>
			))}
			<C.Divider />
		</>
	)
}

export default Header

interface SearchSectionProps {
	searchPopoverContainerRef: React.MutableRefObject<HTMLDivElement | null>
	searchDisclosure: any
	setQuery: React.Dispatch<React.SetStateAction<string>>
	routesResults: Fuse.FuseResult<any>[]
	usersQuery: any
	itemSkusQuery: any
	query: string
	searchPopoverRef: React.MutableRefObject<HTMLDivElement | null>
}

function SearchSection({
	searchPopoverContainerRef,
	searchDisclosure,
	setQuery,
	routesResults,
	usersQuery,
	itemSkusQuery,
	query,
	searchPopoverRef,
}: SearchSectionProps) {
	const [{ auth }] = useAuth()

	return (
		<C.Flex
			grow={1}
			shrink={1}
			justifyContent="center"
			ref={searchPopoverContainerRef}
		>
			<C.Popover
				{...searchDisclosure}
				autoFocus={false}
				returnFocusOnClose={false}
				closeOnBlur={false}
			>
				{/* @ts-ignore */}
				<C.PopoverAnchor>
					<C.Box
						flexBasis="600px"
						maxW="calc(100% - 60px)"
						className="!max-w-full lg:max-w-[calc(100% - 60px)]"
					>
						<Form>
							<M.Input
								placeholder="Search"
								icon={<Search2Icon />}
								onChange={(e) => setQuery(e.currentTarget.value)}
								onFocus={searchDisclosure.onOpen}
								sx={(t) => ({ borderRadius: t.radius.sm })}
							/>
						</Form>
					</C.Box>
				</C.PopoverAnchor>
				<C.PopoverContent>
					<C.PopoverBody
						p={0}
						maxH="min(100vh, 600px)"
						overflow="auto"
						ref={searchPopoverRef}
					>
						<C.VStack>
							{!query && (
								<C.Box px={4} py={1} w="100%">
									<C.Text fontSize="sm" color="gray.400">
										Search something
									</C.Text>
								</C.Box>
							)}
							{query &&
								!routesResults.length &&
								!usersQuery.data?.length &&
								!itemSkusQuery.data?.length && (
									<C.Box px={4} py={1} w="100%">
										<C.Text fontSize="sm" color="gray.400">
											Could not find anything
										</C.Text>
									</C.Box>
								)}
							<SearchResultEntries
								items={routesResults}
								header="Scenes"
								title={(scene) => scene.item.breadcrumb || ""}
								description={(scene) =>
									scene.item.path?.substring(1).replaceAll("/", " > ") || ""
								}
								path={(scene) => scene.item.path || "/"}
							/>
							<SearchResultEntries
								items={itemSkusQuery.data}
								header="Products"
								title={(item) => `${item?.sku} ${item?.title}`}
								description={(item) =>
									`Store stock: ${
										item?.itemSkuStock.find(
											(s) => s.filialId === auth?.user.filialId,
										)?.quantity ?? 0
									} | Price: $${item?.price.toFixed(2)}`
								}
								path={(item) => `/inventory/item-list/edit/${item?.id}`}
							/>
							<SearchResultEntries
								items={usersQuery.data}
								header="Customers"
								title={(user) => `${user?.firstName} ${user?.lastName || ""}`}
								description={(user) =>
									`${user?.email || ""} / ${user?.areaCode || ""} ${
										user?.phoneNumber || ""
									}`
								}
								path={(user) => `/customers/list/edit/${user?.id}`}
							/>
						</C.VStack>
					</C.PopoverBody>
				</C.PopoverContent>
			</C.Popover>
		</C.Flex>
	)
}
