import { MultiSelect } from "#/components-ng";
import { trpc } from "#/trpc";
import { reportUserError } from "#/util";
import * as M from "@mantine/core";
import { modals } from "@mantine/modals";
import { useParams } from "react-router";

interface ItemJoinSectionProps {
  itemId?: number | null;
}
export const ItemRelationshipsAndTagsSection = (
  props: ItemJoinSectionProps
) => {
  return (
    <div className="mb-10 rounded-md border border-[#E0E0E0] bg-white p-10">
      <h2 className="mb-4">Item Relationships and Tags</h2>
      <div className="grid grid-cols-2 gap-8">
        <ItemTagsField itemId={props?.itemId} />
        <ItemSkuRelatedField itemId={props?.itemId} />
      </div>
    </div>
  );
};

type RelatedItem = {
  itemSkuId: number;
  title: string;
  defaultImage: string | null;
};

interface ItemSkuRelatedFieldProps {
  itemId?: number | null;
}

function ItemSkuRelatedField(props: ItemSkuRelatedFieldProps) {
  const params = useParams() as any;
  const [itemId] = useState<number>(() =>
    params.id ? Number(params.id) : props.itemId ?? -1
  );
  const [selectedRelatedItems, setSelectedRelatedItems] = useState<
    Array<RelatedItem>
  >([]);

  const [query, setQuery] = useState("");
  const { data: availableItemSkus } = trpc.itemSku.search.useQuery(
    { query: `\\"${query}\\"` },
    {
      enabled: query.length > 0,
      onError: (err) => {
        reportUserError({
          title: "Failed to get products",
          message: err.message,
        });
      },
    }
  );

  const fieldData = useMemo(
    () =>
      availableItemSkus?.map((itemSku) => ({
        itemSkuId: itemSku.id,
        title: `${itemSku.sku} - ${itemSku.title}`,
        defaultImage: itemSku.defaultImage,
      })) ?? [],
    [availableItemSkus]
  );

  const {
    data: relatedItems,
    isFetchedAfterMount: shouldInitializeRelatedItems,
  } = trpc.itemSkuRelated.private.getByItemId.useQuery(
    {
      itemId: itemId!,
    },
    {
      cacheTime: 0,
      enabled: !!itemId,
      refetchOnWindowFocus: false,
      refetchInterval: false,
    }
  );

  // Initialize selected items
  useEffect(() => {
    if (relatedItems != null && shouldInitializeRelatedItems) {
      setSelectedRelatedItems(
        relatedItems.map((relatedItem) => ({
          itemSkuId: relatedItem.itemSkuId,
          title: relatedItem.itemSku.title,
          defaultImage: relatedItem.itemSku.defaultImage,
        }))
      );
    }
  }, [relatedItems, shouldInitializeRelatedItems]);

  const { mutate: updateRelatedItemSkusMut } =
    trpc.itemSkuRelated.private.updateAll.useMutation({
      onSuccess(data) {
        setSelectedRelatedItems(
          data.map((relatedItem) => ({
            itemSkuId: relatedItem.itemSkuId,
            title: relatedItem.itemSku.title,
            defaultImage: relatedItem.itemSku.defaultImage,
          }))
        );
      },
    });

  function updateRelatedItemSkus(relatedItems: RelatedItem[]) {
    console.log(relatedItems);
    updateRelatedItemSkusMut({
      itemId,
      itemSkuIds: relatedItems.map((relatedItem) => relatedItem.itemSkuId),
    });
  }

  return (
    <MultiSelect
      label="Related items"
      data={fieldData}
      entryId={(e) => e.itemSkuId.toString()}
      entryLabel={(e) => e.title}
      searchable
      filter={() => true}
      valueComponent={RelatedItemSkuMultiSelectValue}
      onSearchChange={setQuery}
      onChange={updateRelatedItemSkus}
      value={selectedRelatedItems}
    />
  );
}

function RelatedItemSkuMultiSelectValue({
  original,
  label,
  onRemove,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  classNames,
  ...otherProps
}: M.MultiSelectValueProps & { original: RelatedItem }) {
  function openImageModal() {
    modals.open({
      children: (
        <>
          <M.Image src={original.defaultImage} />
        </>
      ),
    });
  }

  return (
    <div {...otherProps}>
      <M.Badge
        bg="gray.1"
        pr={0}
        c="gray.8"
        fw={500}
        radius="sm"
        leftSection={
          <M.Avatar
            src={original.defaultImage}
            w="16px"
            h="16px"
            miw="auto"
            onClick={
              original.defaultImage != null && original.defaultImage.length > 0
                ? openImageModal
                : undefined
            }
            classNames={{
              root: cx(original.defaultImage && "cursor-pointer"),
            }}
          />
        }
        rightSection={
          <M.CloseButton onClick={onRemove} color="gray.7" size="sm" />
        }
      >
        {label}
      </M.Badge>
    </div>
  );
}

interface ItemTagsFieldProps {
  itemId?: number | null;
}

type Tag = {
  id: number;
  name: string;
};

function ItemTagsField(props: ItemTagsFieldProps) {
  const params = useParams() as any;
  const [itemId] = useState<number>(() =>
    params.id ? Number(params.id) : props.itemId ?? -1
  );
  const [selectedTags, setSelectedTags] = useState<Array<Tag>>([]);

  const { data: availableTags } = trpc.tag.getTags.useQuery(undefined, {
    cacheTime: 0,
  });

  const fieldData = useMemo(
    () =>
      availableTags?.map((tag) => ({
        id: tag.id,
        name: tag.name,
      })) ?? [],
    [availableTags]
  );

  const { data: itemTags, isFetchedAfterMount: shouldInitializeItemTags } =
    trpc.itemTag.getByItemId.useQuery(
      {
        itemId: itemId!,
      },
      {
        cacheTime: 0,
        enabled: !!itemId,
        refetchOnWindowFocus: false,
        refetchInterval: false,
      }
    );

  // Initialize selected tags
  useEffect(() => {
    if (itemTags != null && shouldInitializeItemTags) {
      setSelectedTags(
        itemTags.map((itemTag) => ({
          id: itemTag.tagId,
          name: itemTag.tag.name,
        }))
      );
    }
  }, [itemTags, shouldInitializeItemTags]);

  const { mutate: updateItemTagsMut } = trpc.itemTag.updateAll.useMutation({
    onSuccess(data) {
      setSelectedTags(
        data.map((itemTag) => ({
          id: itemTag.tagId,
          name: itemTag.tag.name,
        }))
      );
    },
  });

  function updateTags(tags: Tag[]) {
    updateItemTagsMut({
      itemId,
      tags: tags.map((tag) => tag.id),
    });
  }

  return (
    <MultiSelect
      label="Tags"
      data={fieldData}
      entryId={(e) => e.id.toString()}
      entryLabel={(e) => e.name}
      searchable
      onChange={updateTags}
      value={selectedTags}
    />
  );
}
