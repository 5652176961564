import { Entity } from "./Entity/index.js"
import ChoseFilial from "./Filial/ChoseFilial.js"
import { Milestones } from "./Milestones/index.js"
import { CreateMilestone } from "./Milestones/CreateMilestone.js"
import { EditMilestone } from "./Milestones/EditMilestone.js"
import { Settings } from "./Settings.js"
import { Users } from "./Users/index.js"
import { CreateUser } from "./Users/CreateUser/index.js"
import { EditUser } from "./Users/EditUser/index.js"
import { CreateFilial } from "./Filial/Create.js"
import { ChangelogVersionsSection } from "./Changelog/Versions/index.js"
import { ChangelogSection } from "./Changelog/index.js"
import { SysBlocksChangelogSection } from "./Changelog/SysBlocks/index.js"
import { SysSectionsChangelogSection } from "./Changelog/SysSections/index.js"
import { CreateSysBlockSection } from "./Changelog/SysBlocks/create.js"
import { UpdateSysBlockSection } from "./Changelog/SysBlocks/update.js"
import { CreateSysSection } from "./Changelog/SysSections/create.js"
import { UpdateSysSection } from "./Changelog/SysSections/update.js"
import { SysChangeSetSection } from "./Changelog/Versions/SysChangeSet/index.js"
import { CreateChangeSet } from "./Changelog/Versions/SysChangeSet/create.js"
import { UpdateChangeSet } from "./Changelog/Versions/SysChangeSet/update.js"
import { FeatureFlagsPage } from "./feature-flags/index.js"

export const settingsRoutes = [
	{
		index: true,
		element: <Settings />,
	},
	{
		path: "admin/users",
		element: <Users />,
	},
	{
		path: "admin/users/create",
		element: <CreateUser />,
	},
	{
		path: "admin/users/edit/:id",
		element: <EditUser />,
	},
	{
		path: "admin/milestones",
		element: <Milestones />,
	},
	{
		path: "admin/milestones/create",
		element: <CreateMilestone />,
	},
	{
		path: "admin/milestones/edit/:id",
		element: <EditMilestone />,
	},
	{
		path: "admin/entity",
		element: <Entity />,
	},
	{
		path: "admin/filial",
		element: <ChoseFilial />,
	},
	{
		path: "admin/filial/create",
		element: <CreateFilial />,
	},
	{
		path: "admin/changelog",
		element: <ChangelogSection />,
	},
	{
		path: "admin/changelog/versions",
		element: <ChangelogVersionsSection />,
	},
	{
		path: "admin/changelog/versions/:sysVersionId",
		element: <SysChangeSetSection />,
	},
	{
		path: "admin/changelog/versions/:sysVersionId/changeset",
		element: <CreateChangeSet />,
	},
	{
		path: "admin/changelog/versions/:sysVersionId/changeset/:sysChangeSetId",
		element: <UpdateChangeSet />,
	},
	{
		path: "admin/changelog/sys-blocks",
		element: <SysBlocksChangelogSection />,
	},
	{
		path: "admin/changelog/sys-blocks/create",
		element: <CreateSysBlockSection />,
	},
	{
		path: "admin/changelog/sys-blocks/edit/:id",
		element: <UpdateSysBlockSection />,
	},
	{
		path: "admin/changelog/sys-sections",
		element: <SysSectionsChangelogSection />,
	},
	{
		path: "admin/changelog/sys-sections/create",
		element: <CreateSysSection />,
	},
	{
		path: "admin/changelog/sys-sections/edit/:id",
		element: <UpdateSysSection />,
	},
	{
		path: "admin/feature-flags",
		element: <FeatureFlagsPage />,
	},
]
