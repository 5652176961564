import { trpc } from "#/trpc.js";
import { reportUserError, reportUserSuccess } from "#/util/index.js";
import CategoryForm from "./Form.js";
import React from "react";
import { useNavigate } from "react-router";

export default function CreateCategory() {
  const ctx = trpc.useContext();
  const navigate = useNavigate();
  const { mutate, isLoading } = trpc.category.create.useMutation({
    onSuccess(data) {
      ctx.category.invalidate();
      ctx.v2_5.categories.invalidate();
      reportUserSuccess({
        title: "Category created successfully",
        message: (
          <span>
            Category <span className="font-semibold">{data.name}</span> created
            successfully
          </span>
        ),
      });
      navigate("/inventory/categories");
    },
    onError(error) {
      reportUserError({
        title: "Failed to create category",
        message: error.message,
      });
    },
  });

  const handleSubmit = React.useCallback(
    (c) => {
      mutate({
        name: c.name,
        description: c.description,
        defaultImage: c.image,
        active: c.active,
        top: c.top,
        sizeGuideImage: c.sizeGuideImage,
        metaDescription: c?.metaDescription ?? null,
        type: c.type,
      });
    },
    [mutate],
  );
  return (
    <CategoryForm
      onSubmit={handleSubmit}
      isLoading={isLoading}
      defaultValues={{
        type: "READY_TO_USE",
      }}
    />
  );
}
