import { z } from "zod";

export const createSuggestedItemSchema = z.object({
  userId: z.number({
    required_error: "userId is required",
  }),
  note: z.string({
    required_error: "note is required",
  }),
  image: z.string({
    required_error: "image is required",
  }),
});
