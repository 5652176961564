import { EyeIcon } from "#/components/index.js";
import { trpc } from "#/trpc.js";
import { RouterOutputs } from "#/trpc.js";
import { generateCsv } from "#/util/csv/index.js";
import { reportUserError, reportUserSuccess } from "#/util/index.js";
import { Button, Dropdown } from "@gt/ui";
import DepartmentDetails from "./DepartmentCard/index.js";
import * as M from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import dayjs from "dayjs";
import FileSaver from "file-saver";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_SortingState,
  useMantineReactTable,
} from "mantine-react-table";
import { useState, useMemo } from "react";
import { Link } from "react-router-dom";
import CheckIcon from "~icons/ic/baseline-check";
import CloseIcon from "~icons/ic/baseline-close";
import DisableIcon from "~icons/ion/ban-outline";
import DisabledIcon from "~icons/ion/checkmark-outline";
import EditIcon from "~icons/ion/create-outline";
import MenuIcon from "~icons/ion/ellipsis-horizontal-outline";
import { css } from "#/css/css";
import { DropdownMenu, Flex, IconButton, Tooltip } from "@radix-ui/themes";
import { HStack } from "#/css/jsx";
import { SearchButton } from "#/components@v2_5/search-button.js";
import { useDisclosure } from "@mantine/hooks";
import { MdOutlineDownload } from "react-icons/md";

export type Discount =
  RouterOutputs["v2_5"]["discounts"]["getByPage"]["entries"][number];

const DiscountScene = () => {
  const [search, setSearch] = useState<string | null>(null);

  // pagination
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 25,
  });

  // sorting
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const querySorting = useMemo(() => {
    if (sorting[0]) {
      return {
        desc: sorting[0].desc,
        key: sorting[0].id as any,
      };
    }
    return null;
  }, [sorting]);

  const { data, isLoading } = trpc.v2_5.discounts.getByPage.useQuery(
    {
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize,
      sorting: querySorting,
      search: search,
      filters: null,
    },
    {
      onError(error) {
        reportUserError({
          title: "Failed to get discounts",
          message: error.message,
        });
      },
    },
  );

  const { mutate: exportCsv, isLoading: isLoadingExport } =
    trpc.v2_5.discounts.exportDiscountsTableToCsv.useMutation({
      onSuccess: () => {
        reportUserSuccess({
          title: "The csv file has been sent to email",
        });
      },
    });

  const handleExportCsv = () => {
    exportCsv({
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize,
      sorting: querySorting,
      search: search,
      filters: null,
    });
  };

  const table = useMantineReactTable({
    data: data?.entries ?? [],
    columns,
    manualPagination: true,
    enableTopToolbar: false,
    rowCount: data?.totalEntries ?? 0,
    enableFilters: false,
    state: {
      pagination,
      sorting,
      isLoading,
    },
    enableStickyHeader: true,
    manualSorting: true,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    renderBottomToolbarCustomActions: () => (
      <div
        className={css({
          display: "flex",
          alignItems: "center",
          gap: 2,
        })}
      >
        <Tooltip content="Export to CSV">
          <IconButton
            color="gray"
            variant="soft"
            loading={isLoadingExport}
            onClick={handleExportCsv}
          >
            <MdOutlineDownload />
          </IconButton>
        </Tooltip>
      </div>
    ),
  });

  return (
    <>
      <HStack justify="space-between" gap={3}>
        <DrowndownButton />
        <SearchButton setQuery={setSearch} />
      </HStack>
      <div
        className={css({
          marginTop: 5,
        })}
      >
        <MantineReactTable table={table} />
      </div>
    </>
  );
};

export default DiscountScene;

const columns: MRT_ColumnDef<Discount>[] = [
  {
    header: "Actions",
    id: "actions",
    accessorKey: "id",
    enableSorting: false,
    size: 100,
    Cell: (table) => {
      const [opened, { open, close }] = useDisclosure(false);
      const original = table.row.original;
      const ctx = trpc.useContext();

      const { mutate } = trpc.v2_5.discounts.updateStatus.useMutation({
        async onSuccess() {
          reportUserSuccess({
            title: "Successfully changed status",
            message: "The discount status has been changed",
          });
          ctx.v2_5.discounts.invalidate();
        },
        async onError(error) {
          reportUserError({
            title: "Failed to change status",
            message: error.message,
          });
        },
      });

      const { mutate: getCustomersAssociated } =
        trpc.discount.getCustomersAssociated.useMutation({
          onError(error) {
            reportUserError({
              title: "Failed to get customers associated",
              message: error.message,
            });
          },
          async onSuccess(data) {
            const headers = [
              { label: "Name", value: "name" },
              { label: "Phone Number", value: "phoneNumber" },
              { label: "Email", value: "email" },
              { label: "Discount Name", value: "discountName" },
              { label: "Discount %", value: "discountAmount" },
              { label: "Assigned By", value: "updatedBy" },
            ];

            const csv = await generateCsv({
              headers,
              data: data.customers,
            });
            FileSaver.saveAs(
              new Blob([csv], { type: "text/csv" }),
              `customers_associated_with_${data?.discount?.reference ?? ""}.csv`,
            );
          },
        });

      const changeStatusDiscount = () => {
        openConfirmModal({
          title: `Are you sure you want to ${
            original.active ? "disable" : "enable"
          } this discount?`,
          labels: {
            cancel: "Cancel",
            confirm: original.active ? "Disable" : "Enable",
          },
          confirmProps: { color: "red" },
          onConfirm: () =>
            mutate({
              id: original.id,
              status: !original.active,
            }),
        });
      };
      return (
        <>
          <Flex>
            <Dropdown.Root>
              <Dropdown.Trigger>
                <Button variant="ghost" size="icon">
                  <MenuIcon />
                </Button>
              </Dropdown.Trigger>
              <Dropdown.Content>
                {original.active && (
                  <Dropdown.Item asChild>
                    <Link to={`edit/${encodeURI(original.reference)}`}>
                      <div
                        className={css({
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: 2,
                        })}
                      >
                        <EditIcon />
                        Edit
                      </div>
                    </Link>
                  </Dropdown.Item>
                )}
                <Dropdown.Item onClick={changeStatusDiscount}>
                  <div
                    className={css({
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 2,
                    })}
                  >
                    {original.active ? <DisableIcon /> : <DisabledIcon />}
                    {original.active ? "Disable" : "Enable"}
                  </div>
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() =>
                    getCustomersAssociated({ id: Number(original.id) })
                  }
                >
                  Download customers
                </Dropdown.Item>
              </Dropdown.Content>
            </Dropdown.Root>
            <Button variant="ghost" size="icon" onClick={open}>
              <EyeIcon />
            </Button>
          </Flex>
          {opened && (
            <DepartmentDetails
              opened={opened}
              onClose={close}
              discountId={original.id}
            />
          )}
        </>
      );
    },
  },
  {
    header: "Discount",
    accessorKey: "reference",
  },
  {
    header: "Type",
    accessorKey: "type",
    size: 100,
  },
  {
    header: "Amount",
    accessorKey: "amount",
    size: 100,
    Cell: (table) => {
      return `${table.row.original.amount.toNumber()}`;
    },
  },
  {
    header: "Active",
    accessorKey: "active",
    size: 100,
    Cell: (table) => {
      const value = table.row.original.active;

      return (
        <M.Box c={value ? "lime.8" : "red.7"} sx={{ borderRadius: "50%" }}>
          {value ? <CheckIcon /> : <CloseIcon />}
        </M.Box>
      );
    },
  },
  {
    header: "Discount mode",
    accessorKey: "discountMode",
  },
  {
    header: "Last update",
    accessorKey: "updatedAt",
    Cell: (table) => {
      const updatedAt = table.row.original.updatedAt;
      if (!updatedAt) return "";
      return dayjs(updatedAt).format("MM/DD/YYYY").toString();
    },
  },
];

const DrowndownButton = () => {
  return (
    <Dropdown.Root>
      <Dropdown.Trigger>
        <Button
          className={css({
            gap: 4,
          })}
        >
          I want to
          <DropdownMenu.TriggerIcon />
        </Button>
      </Dropdown.Trigger>
      <Dropdown.Content>
        <Dropdown.Item>
          <Link to="create">Create discount</Link>
        </Dropdown.Item>
      </Dropdown.Content>
    </Dropdown.Root>
  );
};
