import { Box } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";

/**
 * Container used to hold any main inventory table.
 */
const DisplayTableContainer = ({ children, bottomMargin = 40, ...props }) => {
  // The `maxH` prop is calculated by taking into account the top position of the
  // element and adding `BOTTOM_MARGIN` to it to leave a gap at the bottom.

  const BOTTOM_MARGIN = bottomMargin || 40;
  const [topSpace, setTopSpace] = useState(220);
  const ref = useRef(null);

  useEffect(() => {
    if (ref) {
      let top = ref.current.getBoundingClientRect().top;

      setTopSpace(top + BOTTOM_MARGIN);
    }
  }, [BOTTOM_MARGIN, ref]);

  return (
    <Box
      borderRadius="md"
      bg="white"
      maxH={`calc(100vh - ${topSpace}px)`}
      overflowY="auto"
      ref={ref}
      {...props}
    >
      {children}
    </Box>
  );
};

export default DisplayTableContainer;
