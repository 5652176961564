import placeholderImage from "#/images/placeholder.png";
import { trpc } from "#/trpc";
import { useNavigate } from "react-router";

interface VariantsProps {
  sku?: number | null;
  itemId?: number | null;
}

export function VariantsGallery(props: VariantsProps) {
  const navigate = useNavigate();
  const { data: item } = trpc.item.getById.useQuery(
    {
      id: props.itemId ?? 0,
    },
    {
      cacheTime: 0,
      enabled: !!props.itemId,
    }
  );

  const handleImageError = (event: any) => {
    event.target.src = placeholderImage;
  };

  return (
    <div>
      {item?.itemSku && item.itemSku.length > 1 ? (
        <>
          <p className={"mb-2 font-semibold text-slate-700"}>Variants</p>
          <div className={"grid grid-cols-4 gap-4"}>
            {item?.itemSku
              .filter((itemSku) => itemSku.sku !== props.sku)
              .map((itemSku) => {
                const defaultImage =
                  !itemSku.defaultImage || itemSku.defaultImage === ""
                    ? placeholderImage
                    : itemSku.defaultImage;

                return (
                  <div
                    key={itemSku.sku}
                    onClick={() =>
                      navigate(`/inventory/item-list/preview/${itemSku.id}`)
                    }
                  >
                    <img
                      src={defaultImage}
                      className={"aspect-square rounded-lg object-cover"}
                      onError={handleImageError}
                    />
                  </div>
                );
              })}
          </div>
        </>
      ) : null}
    </div>
  );
}
