import { RouterOutputs } from "#/trpc";
import { applyDiscountV2 } from "../util";
import Decimal from "decimal.js";

const RefundReasons = {
  DAMAGED: "Damaged",
  RETURN: "Return",
  EXCHANGE: "Exchange",
  WRONG_ITEM: "Wrong Item",
} as const;

type RefundReason = keyof typeof RefundReasons;

type OrderItemSku = NonNullable<
  RouterOutputs["order"]["pos"]["ecommerceOrders"]["ecommerceOrder"]["get"]
>["orderItemSku"][number];

interface FormItem {
  orderItemSkuId: number;
  quantity: number;
  reason: RefundReason;
}

interface CalculateMaxTotalItemToRefundInput {
  itemsForm: FormItem[];
  orderItemSkus: OrderItemSku[];
}

/**
 * Funcion para calcular el total de los items actuales a retornar en base a la cantidad elegida de retorno
 */
export function calculateMaxTotalItemsToRefund({
  itemsForm,
  orderItemSkus,
}: CalculateMaxTotalItemToRefundInput) {
  return itemsForm.reduce((acc, item) => {
    const orderItemSku = getOrderItemSku(orderItemSkus, item.orderItemSkuId);

    if (!orderItemSku) {
      return acc;
    }

    const maxRefund = calculateMaxRefund(item, orderItemSku);
    return acc.plus(maxRefund);
  }, new Decimal(0));
}

/**
 * Retorna el total segun el precio, descuento y cantidad de un solo items
 */
function calculateMaxRefund(item: FormItem, orderItemSku: OrderItemSku) {
  return orderItemSku.discountAmount
    ? applyDiscountV2(orderItemSku.price.times(item?.quantity ?? 0), {
        amount: orderItemSku?.discountAmount,
        type: orderItemSku!.discountType!,
      })
    : orderItemSku.price.times(item?.quantity ?? 0);
}

function getOrderItemSku(
  orderItemSkus: OrderItemSku[],
  orderItemSkuId: number
) {
  return orderItemSkus.find((oiSku) => oiSku.id === orderItemSkuId);
}

type OrderPayment = NonNullable<
  RouterOutputs["order"]["pos"]["ecommerceOrders"]["ecommerceOrder"]["get"]
>["orderPayment"][number];

interface CalculateMaxOrderToRefundInput {
  orderPayments: OrderPayment[];
  itemToRefunds: (FormItem & {
    previousRefund: NonNullable<OrderItemSku["refundOrderItemSku"]>;
  })[];
  currentMethodUsage: number;
}

/**
 * Funcion para calcular el maximo que se puede retornar en base a la orden por stripe
 */
export function calculateMaxStripeOrderToRefund({
  orderPayments,
  itemToRefunds,
  currentMethodUsage,
}: CalculateMaxOrderToRefundInput) {
  let max = getUserCreditPayment(
    orderPayments,
    (op) => op.paymentType !== "USER_CREDITS"
  );

  const maxCredits_ = getUserCreditPayment(
    orderPayments,
    (op) => op.paymentType === "USER_CREDITS"
  );

  const maxTotalItems = calculateMaxTotalRefundAmount({
    itemsToRefund: itemToRefunds,
    filter: (op) => op.paymentType === "USER_CREDITS",
  });

  max = max.minus(maxTotalItems);

  return max.minus(
    new Decimal(currentMethodUsage).gt(maxCredits_)
      ? currentMethodUsage - maxCredits_.toNumber()
      : new Decimal(0)
  );
}

/**
 * Funcion para calcular el maximo que se puede retornar en base a la orden por creditos
 */
export function calculateMaxCreditsOrderToRefund({
  orderPayments,
  itemToRefunds,
  currentMethodUsage,
}: CalculateMaxOrderToRefundInput) {
  let max =
    orderPayments?.reduce(
      (acc, op) => acc.plus(op.paymentAmount),
      new Decimal(0)
    ) ?? new Decimal(0);

  const maxTotalItems = calculateMaxTotalRefundAmount({
    itemsToRefund: itemToRefunds,
    filter: (op) => op.paymentType !== "USER_CREDITS",
  });

  max = max.minus(maxTotalItems);

  return max.minus(currentMethodUsage);
}

function getUserCreditPayment(
  orderPayments: OrderPayment[],
  filter: (op: OrderPayment) => boolean
): Decimal {
  return orderPayments?.find(filter)?.paymentAmount ?? new Decimal(0);
}

interface CalculateMaxTotalItemsInput {
  itemsToRefund: (FormItem & {
    previousRefund: NonNullable<OrderItemSku["refundOrderItemSku"]>;
  })[];
  filter: any;
}

/**
 * Funcion para recorrer los itemsToRefund y calcular el maximo que se puede retornar
 * en base a si hay retornos previos o no
 */
function calculateMaxTotalRefundAmount({
  itemsToRefund,
  filter,
}: CalculateMaxTotalItemsInput) {
  let max = new Decimal(0);

  for (const item of itemsToRefund) {
    const totalPreviousRefund = item.previousRefund.reduce(
      (acc, refund) =>
        refund.isReversed || filter ? acc : acc.plus(refund.total),
      new Decimal(0)
    );

    max = max.plus(totalPreviousRefund);
  }

  return max;
}
