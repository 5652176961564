import { useAuth } from "#/context/AuthContext.js";
import { SalesReportForm } from "./form/index.js";
import { SalesFilialInner } from "./report/ReportInner.js";
import { reportBaseDataAtom } from "./state/index.js";
import * as M from "@mantine/core";
import dayjs from "dayjs";
import { useAtom } from "jotai";
import React from "react";

export const SalesFilialReport = () => {
  const [auth] = useAuth();
  const [baseData, setBaseData] = useAtom(reportBaseDataAtom);

  React.useEffect(() => {
    if (auth?.auth?.user?.filialId) {
      setBaseData({
        ...baseData,
        type: "filial",
        filialId: auth.auth.user.filialId,
        entityId: null,
        send: true,
      });
    } else {
      setBaseData({
        filialId: null,
        entityId: null,
        send: false,
        fromDate: dayjs().startOf("month").toDate(),
        toDate: dayjs().endOf("month").toDate(),
        type: "entity",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <M.Container size="lg">
      {baseData.send && (baseData.entityId || baseData.filialId) ? (
        <SalesFilialInner />
      ) : (
        <SalesReportForm />
      )}
    </M.Container>
  );
};
