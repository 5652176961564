import { ActionsMenuIcon } from "#/components-ng";
import { RouterOutputs, trpc } from "#/trpc";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { styled } from "#/css/jsx";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_SortingState,
  useMantineReactTable,
} from "mantine-react-table";
import { Dropdown } from "@gt/ui";
import { IconButton, Tooltip } from "@radix-ui/themes";
import { openConfirmModal } from "@mantine/modals";
import { reportUserSuccess } from "#/util";
import { css } from "#/css/css";
import { MdOutlineDownload } from "react-icons/md";

interface QuizTableProps {
  search?: string | null;
}

export const QuizTable = (props: QuizTableProps) => {
  // Pagination
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  // Sorting
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const querySorting = useMemo(() => {
    if (sorting[0]) {
      return {
        desc: sorting[0].desc,
        key: sorting[0].id as any,
      };
    }
    return null;
  }, [sorting]);

  const { data, isLoading } = trpc.v2_5.quiz.getByPage.useQuery(
    {
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize,
      search: props.search,
      sorting: querySorting,
    },
    {
      keepPreviousData: true,
    },
  );

  const { mutate: exportCsv, isLoading: isLoadingExport } =
    trpc.v2_5.quiz.exportQuizTableToCsv.useMutation({
      onSuccess: () => {
        reportUserSuccess({
          title: "The csv file has been sent to email",
        });
      },
    });

  const handleExportCsv = () => {
    exportCsv({
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize,
      search: props.search,
      sorting: querySorting,
    });
  };

  const table = useMantineReactTable({
    data: data?.entries ?? [],
    columns: columns,
    manualPagination: true,
    enableTopToolbar: false,
    rowCount: data?.totalEntries ?? 0,
    enableFilters: false,
    state: {
      pagination,
      sorting,
      isLoading,
    },
    enableStickyHeader: true,
    manualSorting: true,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    renderBottomToolbarCustomActions: () => (
      <div
        className={css({
          display: "flex",
          alignItems: "center",
          gap: 2,
        })}
      >
        <Tooltip content="Export to CSV">
          <IconButton
            color="gray"
            variant="soft"
            loading={isLoadingExport}
            onClick={handleExportCsv}
          >
            <MdOutlineDownload />
          </IconButton>
        </Tooltip>
      </div>
    ),
  });

  return <MantineReactTable table={table} />;
};

type Quiz = RouterOutputs["v2_5"]["quiz"]["getByPage"]["entries"][number];

const columns: MRT_ColumnDef<Quiz>[] = [
  {
    header: "Actions",
    id: "actions",
    enableSorting: false,
    enableColumnFilter: false,
    Cell: (table) => {
      const original = table.row.original;
      const ctx = trpc.useContext();

      const { mutate: deleteQuiz } = trpc.v2_5.quiz.delete.useMutation({
        onSuccess() {
          ctx.v2_5.quiz.invalidate();
        },
      });

      const openConfirmDeleteModal = () =>
        openConfirmModal({
          title: "Are you sure you want to delete this quiz?",
          labels: { cancel: "Cancel", confirm: "Delete" },
          confirmProps: { color: "red" },
          onConfirm: () => deleteQuiz({ id: original.id }),
        });

      return (
        <Dropdown.Root>
          <Dropdown.Trigger>
            <styled.div
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <IconButton variant="ghost" color="gray" size="1">
                <ActionsMenuIcon />
              </IconButton>
            </styled.div>
          </Dropdown.Trigger>
          <Dropdown.Content>
            <Dropdown.Item asChild>
              <Link to={`edit/${table.row.original.id}`}>Edit</Link>
            </Dropdown.Item>
            <Dropdown.Item onClick={openConfirmDeleteModal}>
              Delete
            </Dropdown.Item>
          </Dropdown.Content>
        </Dropdown.Root>
      );
    },
  },
  {
    id: "title",
    header: "Title",
    accessorKey: "title",
    enableColumnFilter: false,
  },
  {
    id: "description",
    header: "Description",
    accessorKey: "description",
    enableColumnFilter: false,
  },
  {
    id: "finishedAt",
    header: "Finished at",
    accessorKey: "finishedAt",
    enableColumnFilter: false,
    Cell: (table) => {
      const finishedAt = table.row.original.finishedAt;
      if (!finishedAt) return "";
      return dayjs(finishedAt).format("MM/DD/YYYY").toString();
    },
  },
  {
    id: "publishedAt",
    header: "Published at",
    accessorKey: "publishedAt",
    enableColumnFilter: false,
    Cell: (table) => {
      const publishedAt = table.row.original.publishedAt;
      if (!publishedAt) return "";
      return dayjs(publishedAt).format("MM/DD/YYYY").toString();
    },
  },
  {
    id: "needsAuth",
    header: "Needs auth",
    accessorKey: "needsAuth",
    enableColumnFilter: false,
    Cell: (table) => {
      return table.row.original.needsAuth ? "Yes" : "No";
    },
  },
];
