import { RouterOutputs, trpc } from "#/trpc.js";
import { reportUserError, reportUserSuccess } from "#/util/ux/index.js";
import * as M from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_FilterOption,
  MRT_PaginationState,
  MRT_SortingState,
  useMantineReactTable,
} from "mantine-react-table";
import { MdMoreHoriz } from "react-icons/md/index.js";
import { Link, useNavigate } from "react-router-dom";
import EditIcon from "~icons/ion/create-outline";
import DeleteIcon from "~icons/ion/trash-outline";
import { DropdownMenu, IconButton, Tooltip } from "@radix-ui/themes";
import { Button, Dropdown } from "@gt/ui";
import { css } from "#/css/css";
import { HStack } from "#/css/jsx";
import { SearchButton } from "#/components@v2_5/search-button";
import { MdOutlineDownload } from "react-icons/md";

export const RateCost = () => {
  // Pagination
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });

  // Sorting
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const querySorting = useMemo(() => {
    if (sorting[0]) {
      return {
        desc: sorting[0].desc,
        key: sorting[0].id as any,
      };
    }
    return null;
  }, [sorting]);

  const [globalFilter, setGlobalFilter] = useState<
    MRT_FilterOption | undefined
  >();

  const { data, isLoading } = trpc.v2_5.rateCost.getAllRateCostsByPage.useQuery(
    {
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize,
      sorting: querySorting,
      search: globalFilter,
    },
    {
      keepPreviousData: true,
      onError(error) {
        reportUserError({
          title: "Failed to get rate costs",
          message: error.message,
        });
      },
    },
  );

  const { mutate: exportCsv, isLoading: isLoadingExport } =
    trpc.v2_5.rateCost.exportRateCostsTableToCsv.useMutation({
      onSuccess: () => {
        reportUserSuccess({
          title: "The csv file has been sent to email",
        });
      },
    });

  const handleExportCsv = () => {
    exportCsv({
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize,
      sorting: querySorting,
      search: globalFilter,
    });
  };

  const table = useMantineReactTable({
    columns: columns(),
    data: data?.entries ?? [],
    enableTopToolbar: false,
    enableStickyHeader: true,
    rowCount: data?.totalEntries ?? 0,
    manualPagination: true,
    manualSorting: true,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    state: {
      pagination,
      sorting,
      isLoading,
    },
    renderBottomToolbarCustomActions: () => (
      <div
        className={css({
          display: "flex",
          alignItems: "center",
          gap: 2,
        })}
      >
        <Tooltip content="Export to CSV">
          <IconButton
            color="gray"
            variant="soft"
            loading={isLoadingExport}
            onClick={handleExportCsv}
          >
            <MdOutlineDownload />
          </IconButton>
        </Tooltip>
      </div>
    ),
  });

  return (
    <>
      <HStack mb={6} justify="space-between">
        <DrowndownButton />
        <SearchButton setQuery={setGlobalFilter} />
      </HStack>
      <M.Box sx={{ background: "white" }}>
        <MantineReactTable table={table} />
      </M.Box>
    </>
  );
};

type RateCost =
  RouterOutputs["v2_5"]["rateCost"]["getAllRateCostsByPage"]["entries"][number];

const columns = (): MRT_ColumnDef<RateCost>[] => [
  {
    header: "Actions",
    id: "actions",
    Cell: ({ row: { original } }) => {
      const ctx = trpc.useContext();
      const navigate = useNavigate();

      const { mutate } = trpc.rateCost.delete.useMutation({
        async onSuccess() {
          reportUserSuccess({
            title: "Rate cost deleted",
            message: "Rate cost deleted successfully",
          });
          ctx.v2_5.rateCost.invalidate();
        },
        onError(error) {
          reportUserError({
            title: "Failed to delete rate cost",
            message: error.message,
          });
        },
      });

      const openConfirmationModal = () =>
        openConfirmModal({
          title: "Are you sure you want to delete this rate cost?",
          labels: { cancel: "Cancel", confirm: "Delete" },
          confirmProps: { color: "red" },
          onConfirm: () =>
            mutate({
              id: Number(original.id),
            }),
        });

      return (
        <>
          <M.Menu>
            <M.Menu.Target>
              <M.ActionIcon>
                <MdMoreHoriz />
              </M.ActionIcon>
            </M.Menu.Target>
            <M.Menu.Dropdown>
              <M.Menu.Item
                onClick={() =>
                  navigate(`/inventory/rate-cost/edit/${original.id}`)
                }
                icon={<EditIcon />}
              >
                Edit
              </M.Menu.Item>
              <M.Menu.Item
                onClick={openConfirmationModal}
                icon={<DeleteIcon />}
              >
                Delete
              </M.Menu.Item>
            </M.Menu.Dropdown>
          </M.Menu>
        </>
      );
    },
  },
  {
    header: "Reference code",
    accessorKey: "id",
    Cell: ({
      row: {
        original: { id },
      },
    }) => `RC${id}`,
  },
  {
    header: "Reference name",
    accessorKey: "name",
  },
  {
    header: "With weight?",
    accessorKey: "isWithWeight",
    Cell: ({
      row: {
        original: { isWithWeight },
      },
    }) => (isWithWeight ? "Yes" : "No"),
  },
];

const DrowndownButton = () => {
  return (
    <Dropdown.Root>
      <Dropdown.Trigger>
        <Button
          className={css({
            gap: 4,
          })}
        >
          I want to
          <DropdownMenu.TriggerIcon />
        </Button>
      </Dropdown.Trigger>
      <Dropdown.Content>
        <Dropdown.Item>
          <Link to="create">Create rate cost</Link>
        </Dropdown.Item>
      </Dropdown.Content>
    </Dropdown.Root>
  );
};
