import { useState } from "react";
import { MdPictureAsPdf } from "react-icons/md/index.js";
import { Link, useNavigate } from "react-router-dom";
import { Filter, FilterKey } from "./$common.js";
import { css } from "#/css/css";
import { HStack } from "#/css/jsx";
import { Button, Checkbox, Dropdown } from "@gt/ui";
import { DropdownMenu, Tooltip } from "@radix-ui/themes";
import { SearchButton } from "#/components@v2_5/search-button.js";
import { FilterOutlineIcon } from "#/components-ng/icons.js";
import { SuggestedItemsTable } from "./table.js";

export default function SuggestedItemsScene() {
  const navigate = useNavigate();
  const [search, setSearch] = useState<string | null>(null);
  const [filters, setFilters] = useState<Record<FilterKey, boolean>>(
    () =>
      Object.fromEntries(
        Object.keys(Filter).map((key) => [
          key,
          key === "NEW" || key === "FAVORITE" ? true : false,
        ]),
      ) as any,
  );

  function selectFilter(filter: FilterKey) {
    setFilters((old) => ({
      ...old,
      [filter]: !old[filter],
    }));
  }

  const exportPdf = () => {
    navigate("pdf", {
      state: {
        statuses: Object.entries(filters)
          .filter(([, active]) => active)
          .map(([filter]) => filter) as any,
      },
    });
  };

  return (
    <>
      <HStack justify="space-between" gap={3}>
        <HStack width="100%" gap={3}>
          <DrowndownButton />
          <SuggestedItemStatusDropdown
            filters={filters}
            selectFilter={selectFilter}
          />
          <Tooltip content="Export to PDF">
            <Button variant="secondary" size="icon" onClick={exportPdf}>
              <MdPictureAsPdf />
            </Button>
          </Tooltip>
        </HStack>
        <SearchButton setQuery={setSearch} />
      </HStack>
      <div className={css({ marginTop: 15 })}>
        <SuggestedItemsTable
          filter={
            Object.entries(filters)
              .filter(([, active]) => active)
              .map(([filter]) => filter) as any
          }
          search={search}
        />
      </div>
    </>
  );
}

const DrowndownButton = () => {
  return (
    <Dropdown.Root>
      <Dropdown.Trigger>
        <Button
          className={css({
            gap: 4,
          })}
        >
          I want to
          <DropdownMenu.TriggerIcon />
        </Button>
      </Dropdown.Trigger>
      <Dropdown.Content>
        <Dropdown.Item>
          <Link to="create">Create suggested item</Link>
        </Dropdown.Item>
      </Dropdown.Content>
    </Dropdown.Root>
  );
};

interface SuggestedItemStatusDropdownProps {
  filters: Record<FilterKey, boolean>;
  selectFilter: (filter: FilterKey) => void;
}

const SuggestedItemStatusDropdown = (
  props: SuggestedItemStatusDropdownProps,
) => {
  return (
    <Dropdown.Root>
      <Dropdown.Trigger>
        <Button variant="secondary">
          <FilterOutlineIcon
            className={css({
              marginRight: 2,
            })}
          />
          Filter
        </Button>
      </Dropdown.Trigger>
      <Dropdown.Content
        className={css({
          display: "grid",
          gridGap: "0.1rem",
        })}
        loop
      >
        {Object.entries(props.filters)
          .filter(([key]) => key !== "PROCESSING")
          .map(([filterKey, isFilterActive], i) => (
            <Dropdown.Item
              key={i}
              className={css({
                display: "flex",
                alignItems: "center",
                gap: 2,
              })}
              onSelect={(e) => {
                e.preventDefault();
                props.selectFilter(filterKey as FilterKey);
              }}
            >
              <Checkbox checked={isFilterActive} />
              <span>{Filter[filterKey]}</span>
            </Dropdown.Item>
          ))}
      </Dropdown.Content>
    </Dropdown.Root>
  );
};
