import { useAuth } from "#/context/AuthContext.js";
import { trpc } from "#/trpc.js";
import { reportUserError } from "#/util/index.js";
import {
  getPrintInventoryTemplate,
  getPrintInventoryTemplate2,
} from "./print-template.js";
import {
  ItemSkusSelectedAtom,
  ItemSkusSelectedAddAtom,
  ItemSkusSelectedAddQtyAtom,
  QtyToDepartmentCategoryAtom,
  categorySelectedAtom,
  departmentSelectedAtom,
  ItemSkuTableProps,
} from "./state.js";
import * as M from "@mantine/core";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import React from "react";
import ZebraBrowserPrintWrapper from "zebra-browser-print-wrapper";

export interface PrintInventoryLabelsModalProps {
  printer: ZebraBrowserPrintWrapper;
  isLoadingPrinters: boolean;
  opened: boolean;
  setOpened: React.Dispatch<React.SetStateAction<boolean>>;
}

export function PrintInventoryLabel({
  printer,
  isLoadingPrinters,
  opened,
  setOpened,
}: PrintInventoryLabelsModalProps) {
  const [type, setType] = React.useState<
    "department" | "category" | "item" | null
  >(null);
  const [itemSkusSelected, setItemSkusSelected] = useAtom(ItemSkusSelectedAtom);
  const [qtyByLabels, setQtyByLabels] = useAtom(QtyToDepartmentCategoryAtom);
  const [categorySelected, setCategorySelected] = useAtom(categorySelectedAtom);
  const [departmentSelected, setDepartmentSelected] = useAtom(
    departmentSelectedAtom
  );

  const [{ auth }] = useAuth();

  const { mutateAsync: getItemSkusAssociatedCategory } =
    trpc.category.getItemSkusAssociated.useMutation({
      onError: (error) => {
        reportUserError({
          title: "Error getting item skus",
          message: error.message,
        });
      },
    });

  const { mutateAsync: getItemSkusAssociatedDepartment } =
    trpc.department.getItemSkusAssociated.useMutation({
      onError: (error) => {
        reportUserError({
          title: "Error getting item skus",
          message: error.message,
        });
      },
    });

  const handlePrint = React.useCallback(() => {
    (async () => {
      if (type === "item") {
        if (itemSkusSelected.length === 0) {
          return reportUserError({
            title: "No items selected",
            message: "Please select at least one item to print",
          });
        }

        for (let i = 0; i < itemSkusSelected.length; i++) {
          const itemSku = itemSkusSelected[i];
          const productsToPrint: ItemSkuTableProps[] = [];
          for (let j = 0; j < itemSku.qty; j++) {
            productsToPrint.push(itemSku);
          }

          for (let j = 0; j < productsToPrint.length; j += 2) {
            const itemSku0 = productsToPrint[j];
            const itemSku1 = productsToPrint[j + 1];
            let template: string;

            if (itemSku1) {
              template = getPrintInventoryTemplate2(
                {
                  sku: itemSku0.sku,
                  ubication: itemSku0.location,
                  price: itemSku0.price,
                },
                {
                  sku: itemSku1.sku,
                  ubication: itemSku1.location,
                  price: itemSku1.price,
                }
              );
            } else {
              template = getPrintInventoryTemplate({
                sku: itemSku0.sku,
                ubication: itemSku0.location,
                price: itemSku0.price,
              });
            }

            await printer.print(template);
          }
        }
        setItemSkusSelected([]);
      }
      if (type === "category") {
        if (!categorySelected) {
          return reportUserError({
            title: "No category selected",
            message: "Please select a category to print",
          });
        }
        const items = await getItemSkusAssociatedCategory({
          categoryId: categorySelected,
        });

        for await (const item of items) {
          const productsToPrint: any[] = [];
          for (let j = 0; j < qtyByLabels; j++) {
            productsToPrint.push(item);
          }

          for (let j = 0; j < productsToPrint.length; j += 2) {
            const item0 = productsToPrint[j];
            const item1 = productsToPrint[j + 1];
            let template: string;

            const ubication0 =
              item0.itemSkuStock.find(
                (itemSkuStock) => itemSkuStock.filialId === auth?.user?.filialId
              )?.storeLocation ?? "";
            const ubication1 =
              item1?.itemSkuStock?.find(
                (itemSkuStock) => itemSkuStock.filialId === auth?.user?.filialId
              )?.storeLocation ?? "";

            if (item1) {
              template = getPrintInventoryTemplate2(
                {
                  sku: item0.sku,
                  ubication: ubication0,
                  price: item0.price,
                },
                {
                  sku: item1.sku,
                  ubication: ubication1,
                  price: item1.price,
                }
              );
            } else {
              template = getPrintInventoryTemplate({
                sku: item0.sku,
                ubication: ubication0,
                price: item0.price,
              });
            }

            await printer.print(template);
          }
        }

        setCategorySelected(null);
        setQtyByLabels(1);
      }
      if (type === "department") {
        if (!departmentSelected) {
          return reportUserError({
            title: "No department selected",
            message: "Please select a department to print",
          });
        }
        const items = await getItemSkusAssociatedDepartment({
          departmentId: departmentSelected,
        });

        for await (const item of items) {
          const productsToPrint: any[] = [];
          for (let j = 0; j < qtyByLabels; j++) {
            productsToPrint.push(item);
          }

          for (let j = 0; j < productsToPrint.length; j += 2) {
            const item0 = productsToPrint[j];
            const item1 = productsToPrint[j + 1];
            let template: string;

            const ubication0 =
              item0.itemSkuStock.find(
                (itemSkuStock) => itemSkuStock.filialId === auth?.user?.filialId
              )?.storeLocation ?? "";
            const ubication1 =
              item1?.itemSkuStock?.find(
                (itemSkuStock) => itemSkuStock.filialId === auth?.user?.filialId
              )?.storeLocation ?? "";

            if (item1) {
              template = getPrintInventoryTemplate2(
                {
                  sku: item0.sku,
                  ubication: ubication0,
                  price: item0.price,
                },
                {
                  sku: item1.sku,
                  ubication: ubication1,
                  price: item1.price,
                }
              );
            } else {
              template = getPrintInventoryTemplate({
                sku: item0.sku,
                ubication: ubication0,
                price: item0.price,
              });
            }

            await printer.print(template);
          }
        }
        setDepartmentSelected(null);
        setQtyByLabels(1);
      }
    })();
  }, [
    type,
    itemSkusSelected,
    setItemSkusSelected,
    printer,
    categorySelected,
    getItemSkusAssociatedCategory,
    setCategorySelected,
    setQtyByLabels,
    auth?.user?.filialId,
    qtyByLabels,
    departmentSelected,
    getItemSkusAssociatedDepartment,
    setDepartmentSelected,
  ]);

  return (
    <M.Modal
      opened={opened}
      onClose={() => {
        setOpened(false);
        setItemSkusSelected([]);
        setQtyByLabels(1);
        setCategorySelected(null);
        setDepartmentSelected(null);
      }}
      closeOnClickOutside={false}
      closeOnEscape={false}
      size="500px"
      zIndex={10000}
      transitionProps={{
        transition: "fade",
        duration: 300,
        timingFunction: "linear",
      }}
    >
      <M.Title order={3} align="center">
        Print Inventory Label
      </M.Title>
      <M.Divider size={2} my={"md"} />
      <M.Stack>
        <M.Select
          data={[
            { value: "department", label: "Department" },
            { value: "category", label: "Category" },
            { value: "item", label: "Item" },
          ]}
          label="Select type"
          dropdownPosition="bottom"
          clearable
          value={type}
          onChange={(value) => setType(value as any)}
        />
        {type === "department" && <DepartmentSelect type={type} />}
        {type === "category" && <CategorySelect type={type} />}
        {type === "item" && <ItemSkuSelect type={type} />}
        {type === "item" && <ItemSkuTable />}
        {type === "department" || type === "category" ? (
          <SelectQtyField />
        ) : null}
      </M.Stack>
      <M.Divider size={2} my={"md"} />
      <M.Button
        fullWidth
        onClick={() => {
          setOpened(false);
          handlePrint();
        }}
        loading={isLoadingPrinters}
      >
        Print
      </M.Button>
    </M.Modal>
  );
}

interface SelectionProps {
  type: "department" | "category" | "item" | null;
}

function DepartmentSelect({ type }: SelectionProps) {
  const [departmentSelected, setDepartmentSelected] = useAtom(
    departmentSelectedAtom
  );
  const { data } = trpc.department.getAll.useQuery(undefined, {
    cacheTime: 0,
    enabled: type === "department",
  });

  const departments = React.useMemo(() => {
    return (
      data?.map((department) => ({
        value: department.id.toString(),
        label: department.name,
      })) ?? []
    );
  }, [data]);

  return (
    <M.Select
      data={departments}
      label="Select department"
      dropdownPosition="bottom"
      searchable
      clearable
      value={departmentSelected?.toString() ?? ""}
      onChange={(value) => {
        setDepartmentSelected(Number(value));
      }}
    />
  );
}

function CategorySelect({ type }: SelectionProps) {
  const [categorySelected, setCategorySelected] = useAtom(categorySelectedAtom);

  const { data } = trpc.category.getAll.useQuery(undefined, {
    cacheTime: 0,
    enabled: type === "category",
  });

  const categories = React.useMemo(() => {
    return (
      data?.map((category) => ({
        value: category.id.toString(),
        label: category.name,
      })) ?? []
    );
  }, [data]);

  return (
    <M.Select
      data={categories}
      label="Select category"
      dropdownPosition="bottom"
      searchable
      clearable
      value={categorySelected?.toString() ?? ""}
      onChange={(value) => {
        setCategorySelected(Number(value));
      }}
    />
  );
}

function ItemSkuSelect({ type }: SelectionProps) {
  const [query, setQuery] = React.useState<string>("");
  const dispatch = useSetAtom(ItemSkusSelectedAddAtom);
  const { data } = trpc.itemSku.search.useQuery(
    { query: `\\"${query}\\"`, limit: 12 },
    {
      cacheTime: 0,
      enabled: type === "item",
    }
  );

  const itemSkus = React.useMemo(() => {
    return (
      data?.map((itemSku) => ({
        value: itemSku.id.toString(),
        label: `${itemSku.sku} - (${itemSku.title})`,
        itemSku: {
          itemSkuId: itemSku.id,
          sku: itemSku.sku,
          title: itemSku.title,
          location:
            itemSku.itemSkuStock.find(
              (itemSkuStock) => itemSkuStock.filialId === 1
            )?.storeLocation ?? "",
          price: itemSku.price,
        },
      })) ?? []
    );
  }, [data]);

  return (
    <M.Select
      data={itemSkus}
      label="Select item"
      dropdownPosition="bottom"
      searchable
      clearable
      onInput={(event) => {
        setQuery(event.currentTarget.value);
      }}
      onChange={(value) => {
        const itemSku = itemSkus.find((itemSku) => itemSku.value === value);
        if (itemSku) {
          dispatch({
            itemSkuId: itemSku.itemSku.itemSkuId,
            sku: itemSku.itemSku.sku,
            title: itemSku.itemSku.title,
            location: itemSku.itemSku.location,
            qty: 1,
            price: itemSku.itemSku.price,
          });
        }
      }}
      value={null}
    />
  );
}

function ItemSkuTable() {
  const itemSkusSelected = useAtomValue(ItemSkusSelectedAtom);
  const addQty = useSetAtom(ItemSkusSelectedAddQtyAtom);

  return (
    <M.Box className="max-h-[24rem] overflow-auto">
      <M.Table striped highlightOnHover withBorder withColumnBorders>
        <thead>
          <th>Sku</th>
          <th>Title</th>
          <th>Qty</th>
        </thead>
        <tbody>
          {itemSkusSelected.length > 0 ? (
            itemSkusSelected.map((itemSku, index) => (
              <tr key={index}>
                <td>{itemSku.sku}</td>
                <td>{itemSku.title}</td>
                <td>
                  <M.NumberInput
                    min={1}
                    value={itemSku.qty}
                    onChange={(event: number) => {
                      addQty({
                        index: index,
                        qty: event,
                      });
                    }}
                    className="w-20 "
                  />
                </td>
              </tr>
            ))
          ) : (
            <tr key="no-items-selected">
              <td colSpan={3} className="text-center">
                No items selected
              </td>
            </tr>
          )}
        </tbody>
      </M.Table>
    </M.Box>
  );
}

function SelectQtyField() {
  const [qtyByLabels, setQtyByLabels] = useAtom(QtyToDepartmentCategoryAtom);

  return (
    <M.NumberInput
      min={1}
      label="Label qty by item"
      defaultValue={1}
      value={qtyByLabels}
      onChange={(event: number) => {
        setQtyByLabels(event);
      }}
    />
  );
}
