import GTLoader from "#/components-ng/loader.js";
import { RouterOutputs, trpc } from "#/trpc.js";
import { reportUserError, reportUserSuccess } from "#/util/index.js";
import { FormValues, ProductForm } from "./Form/index.js";
import { locationsAtom } from "./Form/state.js";
import * as M from "@mantine/core";
import { useSetAtom } from "jotai";
import { useState } from "react";
import { useParams } from "react-router-dom";

type ItemUpdateOutput = RouterOutputs["item"]["private"]["update"];
export type UpdateItemFormOutput = {
  item: ItemUpdateOutput;
};

export type UpdateItemFormProps = {
  itemId?: number;
  onSuccess?: (data: UpdateItemFormOutput) => void;
  redirectOnSuccess?: boolean;
  showTitle?: boolean;
};
export default function UpdateItemForm(props: UpdateItemFormProps) {
  const params = useParams() as any;
  const ctx = trpc.useContext();

  const [itemId] = useState<number | null>(() =>
    params.id ? Number(params.id) : props.itemId ?? null
  );

  const setLocationsToCreate = useSetAtom(locationsAtom);

  useEffect(() => {
    setLocationsToCreate([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data, isLoading, isFetchedAfterMount } = trpc.item.getById.useQuery(
    { id: itemId! },
    {
      enabled: !!itemId,
      cacheTime: 0,
    }
  );

  const { mutate } = trpc.item.private.update.useMutation({
    onError(err) {
      reportUserError({
        title: "Failed to updated item",
        message: err.message,
      });
    },
    onSuccess(data) {
      reportUserSuccess({
        title: "Item updated successfully",
        message: (
          <>
            Product <span className="font-medium">{data.title}</span> has been
            updated
          </>
        ),
      });
      ctx.item.invalidate();
    },
  });

  function handleSubmit(values: FormValues) {
    mutate({
      id: itemId!,
      input: {
        title: values.title,
        description: values.description,
        department: values.department.id,
        category: values.category.id,
        season: values.season.id ? values.season.id : undefined,
        active: values.active,
        material: values?.material ?? null,
      },
    });
  }

  return data && isFetchedAfterMount ? (
    <ProductForm
      itemId={itemId}
      onSubmit={handleSubmit}
      showTitle={props.showTitle}
      defaultValues={{
        title: data.title,
        description: data.description ?? "",
        department: {
          id: data.department.id,
          name: data.department.name,
          active: data.department.active,
        },
        category: {
          id: data.category.id,
          name: data.category.name,
          active: data.category.active,
        },
        season: {
          id: data.season?.id,
          name: data.season?.name,
          active: data.season?.active,
        },
        active: data.active,
        material: data?.material,
      }}
      hasVariants={data?.itemSku.length > 0 ? true : false}
    />
  ) : (
    <M.LoadingOverlay
      visible={isLoading}
      loader={<GTLoader width={100} height={100} />}
    />
  );
}
