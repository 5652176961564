import Card from "#/components/Card";
import { css } from "#/css/css";
import { HStack } from "#/css/jsx";
import { DocumentTextOutlineIcon } from "#/components-ng";

export const ChangelogSection = () => {
  return (
    <>
      <div
        className={css({
          display: "flex",
          flexDirection: "column",
          rowGap: "1rem",
        })}
      >
        <h1>Changelog</h1>
        <HStack gap={10}>
          <Card
            icon={<DocumentTextOutlineIcon fontSize={30} />}
            text="SysBlocks"
            path="sys-blocks"
          />
          <Card
            icon={<DocumentTextOutlineIcon fontSize={30} />}
            text="SysSections"
            path="sys-sections"
          />
          <Card
            icon={<DocumentTextOutlineIcon fontSize={30} />}
            text="Versions"
            path="versions"
          />
        </HStack>
      </div>
    </>
  );
};
