import { DefaultValues, FormProvider, SubmitHandler } from "react-hook-form";
import { CCreateAnswer, createAnswerSchema, FormAnswerCreate } from "./types";
import { zodResolver } from "@hookform/resolvers/zod";
import * as M from "@mantine/core";
import { trpc } from "#/trpc";
import { css } from "#/css/css";

interface AnswerFormDrawerProps {
  open: boolean;
  close: () => void;
  defaultValues?: DefaultValues<FormAnswerCreate>;
  isLoading: boolean;
  onSubmit: SubmitHandler<FormAnswerCreate>;
  title: string;
  quizId: number;
}

export const AnswerFormDrawer = ({
  open,
  close,
  defaultValues,
  isLoading,
  onSubmit,
  title,
  quizId,
}: AnswerFormDrawerProps) => {
  const { data: values } = trpc.v2_5.quiz.getValuesByQuizId.useQuery(
    {
      id: quizId,
    },
    {
      enabled: open,
    },
  );

  const form = CCreateAnswer.useForm({
    resolver: zodResolver(createAnswerSchema),
    shouldUnregister: false,
    defaultValues: {
      answer: "",
      valueId: "",
      score: "",
      ...defaultValues,
    },
  });

  return (
    <M.Drawer
      position="right"
      opened={open}
      onClose={close}
      title={<M.Title order={3}>{title}</M.Title>}
      zIndex={50}
    >
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit((values) => {
            form.reset();
            onSubmit(values);
          })}
        >
          <M.Stack spacing={30}>
            <M.Tabs defaultValue="en">
              <M.Tabs.List>
                <M.Tabs.Tab value="en">English</M.Tabs.Tab>
                <M.Tabs.Tab value="es">Spanish</M.Tabs.Tab>
              </M.Tabs.List>
              <M.Tabs.Panel value="en" mt="lg">
                <CCreateAnswer.InputField
                  name="answer"
                  label="Answer"
                  placeholder="Answer"
                />
              </M.Tabs.Panel>
              <M.Tabs.Panel value="es" mt="lg">
                <CCreateAnswer.InputField
                  name="answerEsp"
                  label="Respuesta"
                  placeholder="Respuesta"
                />
              </M.Tabs.Panel>
            </M.Tabs>

            <CCreateAnswer.SelectField
              label="Select value"
              name="valueId"
              placeholder="Select value"
              data={
                values?.map((value) => ({
                  id: value.id.toString(),
                  label: value.title,
                })) ?? []
              }
            />
            <CCreateAnswer.InputField
              name="score"
              label="Score"
              placeholder="Score"
              type="number"
            />
            <M.Button
              type="submit"
              loading={isLoading}
              className={css({ width: "fit" })}
            >
              Save answer
            </M.Button>
          </M.Stack>
        </form>
      </FormProvider>
    </M.Drawer>
  );
};
