import { RouterOutputs } from "#/trpc";
import { match } from "ts-pattern";

type Discount = Pick<
  NonNullable<
    RouterOutputs["v2_5"]["discounts"]["getBestDiscounts"][0]["discount"]
  >,
  "amount" | "type"
>;

export function getDiscountLabel(discount: Discount) {
  return match(discount.type)
    .with("AMOUNT", () => `$${discount.amount.toFixed(2)}`)
    .with("PERCENTAGE", () => `${discount.amount.toFixed(2)}%`)
    .with(
      "ORDER_SUBTOTAL_PERCENTAGE",
      () => `Order subtotal ${discount.amount.toFixed(2)}% off`,
    )
    .with(
      "ORDER_SUBTOTAL_AMOUNT",
      () => `Order subtotal $${discount.amount.toFixed(2)} off`,
    )
    .with("SHIPPING_PERCENTAGE", () => {
      if (discount.amount.eq(100)) {
        return "Free shipping";
      } else {
        return `Shipping ${discount.amount.toFixed(2)}% off`;
      }
    })
    .exhaustive();
}

export function getDiscountNumberWithSymbol(discount: Discount) {
  return match(discount.type)
    .with("AMOUNT", () => `$${discount.amount.toFixed(2)}`)
    .with("PERCENTAGE", () => `${discount.amount.toFixed(2)}%`)
    .with("ORDER_SUBTOTAL_PERCENTAGE", () => `${discount.amount.toFixed(2)}%`)
    .with("ORDER_SUBTOTAL_AMOUNT", () => `$${discount.amount.toFixed(2)}`)
    .with("SHIPPING_PERCENTAGE", () => `${discount.amount.toFixed(2)}%`)
    .exhaustive();
}
