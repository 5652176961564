import { RouterPrompt } from "#/components/index.js";
import { trpc } from "#/trpc.js";
import { reportUserError, reportUserSuccess } from "#/util/index.js";
import { createSuggestedItemSchema } from "./types.js";
import * as C from "@chakra-ui/react";
import { ImageUploadWithPreviewS3 } from "#/components/Form/v2/index.js";
import { Controller, CustomerSelect, Form } from "#/components/Form/v3/index.js";
import { Field } from "#/components/Form/v3/Field.js";
import { useAuth } from "#/context/AuthContext.js";
import React from "react";
import { useNavigate } from "react-router-dom";

export interface SuggestedItemsFormProps {
  userId: number;
  image: string;
  note: string;
}

export function SuggestedItemsForm() {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const [{ auth }] = useAuth()!;
  const navigate = useNavigate();

  const { mutate, isLoading } = trpc.suggestedItem.create.useMutation({
    onSuccess: () => {
      reportUserSuccess({
        title: "Suggested item created successfully",
        message: "Suggested item created successfully",
      });
      navigate("/inventory/suggested-items");
    },
    onError: (err) => {
      reportUserError({
        title: "Failed to create suggested item",
        message: err.message,
      });
    },
  });

  const [submitted, setSubmitted] = React.useState(false);
  const handleSubmit = (f: SuggestedItemsFormProps) => {
    setSubmitted(true);
    if (!auth?.user.id) return;
    mutate({
      userId: f.userId || auth.user.id,
      images: [f.image],
      note: f.note,
      status: "NEW",
    });
  };

  return (
    <C.Container maxW="container.lg">
      <RouterPrompt when={!submitted} />
      <C.Heading as="h3">Suggested items</C.Heading>
      <Form
        onSubmit={handleSubmit}
        criteriaMode="all"
        mt={6}
        schema={createSuggestedItemSchema}
      >
        <C.VStack spacing={4}>
          <Field
            label="Suggested by"
            name="userId"
            render={({ name }) => (
              <Controller
                name={name}
                render={({ onChange, value }) => (
                  <CustomerSelect
                    onChange={(user) => onChange(user?.id)}
                    userId={value}
                  />
                )}
              />
            )}
          />
          <C.HStack w="100%" align="start">
            <Field
              label="Note"
              name="note"
              render={({ name }) => (
                <Controller
                  name={name}
                  render={({ onChange, value }) => (
                    <C.Textarea onChange={onChange} value={value} h="142px" />
                  )}
                />
              )}
              flex={1}
            />
            <C.Box flex={1} maxW="450px">
              <Field
                label="Image"
                name="image"
                render={({ name }) => (
                  <ImageUploadWithPreviewS3
                    name={name}
                    accept=".jpeg,.jpg,.png"
                    folder={"suggested-items"}
                    maxFiles={undefined}
                    id={undefined}
                    onDrop={undefined}
                    defaultSrc={undefined}
                  />
                )}
              />
            </C.Box>
          </C.HStack>
          <C.Button type="submit" isLoading={isLoading}>
            Submit
          </C.Button>
        </C.VStack>
      </Form>
    </C.Container>
  );
}
