import { EllipsisHorizontal, SpinnerIcon } from "#/components-ng";
import { Flex, styled } from "#/css/jsx";
import { RouterOutputs, trpc } from "#/trpc";
import { reportUserError, reportUserSuccess } from "#/util";
import { Button, Dropdown } from "@gt/ui";
import { IconButton } from "@radix-ui/themes";
import {
  MantineReactTable,
  MRT_ColumnDef,
  useMantineReactTable,
} from "mantine-react-table";
import placeholderImage from "#/placeholder-image.jpg";
import {
  CatalogItemSkuFormValues,
  CIS,
  createCatalogItemSkuSchema,
} from "./types";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider } from "react-hook-form";
import { Select } from "@mantine/core";
import { css } from "#/css/css";

type Department = RouterOutputs["department"]["pos"]["search"][number];
type Category = RouterOutputs["category"]["pos"]["search"][number];
type Tag = RouterOutputs["v2_5"]["tags"]["getAll"][number];
type Season = RouterOutputs["v2_5"]["season"]["getAll"][number];
type Promotion = RouterOutputs["v2_5"]["promotion"]["getAll"][number];

interface ItemSku {
  id: number;
  sku: number;
  title: string;
  defaultImage?: string | null;
}

interface CatalogItemsTableProps {
  catalogId: number;
  itemSkus?: ItemSku[];
}

export const CatalogItemsTable = (props: CatalogItemsTableProps) => {
  const ctx = trpc.useContext();
  const form = CIS.useForm({
    resolver: zodResolver(createCatalogItemSkuSchema),
    shouldUnregister: false,
    defaultValues: {
      itemSkus: props.itemSkus ?? [],
    },
  });

  const itemSkus = form.watch("itemSkus");

  const table = useMantineReactTable({
    data: itemSkus ?? [],
    columns: columns,
    initialState: { density: "xs" },
    mantineTableBodyRowProps({ row }) {
      if (!row.original.defaultImage) {
        return {
          sx: {
            "& td": {
              backgroundColor: "rgba(252,165,165,0.1)",
            },
          },
        };
      }
      return {};
    },
  });

  const { mutate, isLoading } =
    trpc.v2_5.catalog.createCatalogItemSku.useMutation({
      onSuccess() {
        ctx.v2_5.catalog.getById.invalidate();
        reportUserSuccess({
          title: "Item skus added to catalog",
          message: "The item skus have been added to the catalog successfully",
        });
      },
      onError(error) {
        reportUserError({
          title: "Failed to add item skus to catalog",
          message: error.message,
        });
      },
    });

  const handleSubmit = (values: CatalogItemSkuFormValues) => {
    if (values.itemSkus.length === 0) {
      reportUserError({
        title: "Failed to add item skus to catalog",
        message: "Please select at least one item sku",
      });
      return;
    }

    mutate({
      catalogId: props.catalogId,
      itemSkuIds: values.itemSkus.map((isku) => isku.id),
    });
  };

  return (
    <styled.div
      bg="white"
      p="40px"
      rounded="md"
      border="1px solid #E0E0E0"
      mb={10}
      height="auto"
    >
      <Flex direction="column" gap="4">
        <styled.h2 mb="1">Catalog items</styled.h2>
        <span
          className={css({
            color: "gray",
            fontSize: "13px",
            lineHeight: "20px",
          })}
        >
          Items without a default image are not displayed in the ecommerce
          catalog list. These items are typically highlighted with a light red
          background color.*
        </span>
        <FormProvider {...form}>
          <form
            onSubmit={form.handleSubmit((values) => {
              handleSubmit(values);
            })}
          >
            <SearchableFields />
            <MantineReactTable table={table} />
            <Button
              type="submit"
              className={css({
                marginTop: "20px",
                width: "100%",
              })}
              isLoading={isLoading}
            >
              Save
            </Button>
          </form>
        </FormProvider>
      </Flex>
    </styled.div>
  );
};

const columns: MRT_ColumnDef<ItemSku>[] = [
  {
    id: "actions",
    header: "Actions",
    Cell(table) {
      const form = CIS.useFormContext();

      const handleDelete = () => {
        const itemSkus = form.getValues("itemSkus");
        const index = itemSkus.findIndex(
          (itemSku: ItemSku) => itemSku.id === table.row.original.id,
        );

        if (index !== -1) {
          form.setValue(
            "itemSkus",
            itemSkus.filter(
              (itemSku: ItemSku) => itemSku.id !== table.row.original.id,
            ),
          );
        }
      };

      return (
        <Dropdown.Root>
          <Dropdown.Trigger>
            <styled.div
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <IconButton variant="ghost" color="gray" size="1">
                <EllipsisHorizontal />
              </IconButton>
            </styled.div>
          </Dropdown.Trigger>
          <Dropdown.Content>
            <Dropdown.Item onClick={handleDelete}>Delete</Dropdown.Item>
          </Dropdown.Content>
        </Dropdown.Root>
      );
    },
  },
  {
    id: "image",
    accessorKey: "defaultImage",
    header: "Image",
    Cell(props) {
      const image = props.row.original.defaultImage ?? placeholderImage;

      return (
        <img
          src={image}
          alt=""
          className="aspect-square size-[48px] object-cover"
        />
      );
    },
  },
  {
    accessorKey: "sku",
    header: "SKU",
  },
  {
    accessorKey: "title",
    header: "Title",
  },
];

function SearchableFields() {
  const form = CIS.useFormContext();

  function addItemSkus(itemSkus: ItemSku[]) {
    const currentItemSkus = form.getValues("itemSkus");
    const newItemSkus = [...currentItemSkus, ...itemSkus];
    const newItemSkusMap = new Map(newItemSkus.map((isku) => [isku.sku, isku]));
    const deduplicatedItemSkus = Array.from(newItemSkusMap.values());

    form.setValue("itemSkus", deduplicatedItemSkus);
  }

  return (
    <styled.div display="grid" gridTemplateColumns="3" gap="4" mb="4">
      <ItemSkusSearchField onSelect={addItemSkus} />
      <DepartmentsSearchField onSelect={addItemSkus} />
      <CategoriesSearchField onSelect={addItemSkus} />
      <TagsSearchField onSelect={addItemSkus} />
      <SeasonSearchField onSelect={addItemSkus} />
      <PromotionSearchField onSelect={addItemSkus} />
    </styled.div>
  );
}

interface SearchableFieldProps {
  onSelect: (itemSkus: Array<ItemSku>) => void;
}

const ItemSkusSearchField = (props: SearchableFieldProps) => {
  const [query, setQuery] = useState("");
  const { data, isLoading } = trpc.itemSku.pos.search.useQuery(
    {
      query: `\\"${query}\\"`,
      limit: 10,
    },
    {
      onError(error) {
        reportUserError({
          title: "Failed to get item skus",
          message: error.message,
        });
      },
    },
  );

  const selectData = useMemo(
    () =>
      data?.map((e) => ({
        label: `${e.sku} - ${e.title}`,
        value: e.id.toString(),
        itemSku: e,
        // ^ Add the whole itemSku so that we can refer to it on selection
      })) ?? [],
    [data],
  );

  const handleSelect = (itemSku: ItemSku) => {
    props.onSelect([itemSku]);
  };

  const handleChange = (selectedId: string | null) => {
    if (selectedId == null) return;

    const selectedEntry = selectData.find((e) => e.value === selectedId);
    if (!selectedEntry) {
      throw new Error("selected non existing data");
      // ^ Theoretically, this should never happen.
    }

    handleSelect(selectedEntry.itemSku);
  };

  return (
    <Select
      data={selectData}
      searchable
      placeholder="Search by item sku..."
      rightSection={isLoading && <SpinnerIcon />}
      value={null}
      // ^ We only use this select as search field, so we don't care about
      // showing a value.
      onChange={handleChange}
      onSearchChange={setQuery}
      filter={() => true}
    />
  );
};

const DepartmentsSearchField = (props: SearchableFieldProps) => {
  const [query, setQuery] = useState("");
  const tctx = trpc.useContext();
  const { data, isLoading } = trpc.department.pos.search.useQuery(
    {
      query,
      limit: 10,
    },
    {
      onError(error) {
        reportUserError({
          title: "Failed to get departments",
          message: error.message,
        });
      },
    },
  );

  const selectData = useMemo(
    () =>
      data?.map((e) => ({
        label: e.name,
        value: e.id.toString(),
        department: e,
        // ^ Add the whole department so that we can refer to it on selection
      })) ?? [],
    [data],
  );

  const handleSelect = async (department: Department) => {
    const itemSkus = await tctx.client.department.pos.itemSkus.query({
      departmentId: department.id,
    });
    props.onSelect(itemSkus);
  };

  const handleChange = (selectedId: string | null) => {
    if (selectedId == null) return;

    const selectedEntry = selectData.find((e) => e.value === selectedId);
    if (!selectedEntry) {
      throw new Error("selected non existing data");
      // ^ Theoretically, this should never happen.
    }

    handleSelect(selectedEntry.department);
  };

  return (
    <Select
      data={selectData}
      searchable
      placeholder="Search by department..."
      rightSection={isLoading && <SpinnerIcon />}
      value={null}
      // ^ We only use this select as search field, so we don't care about
      // showing a value.
      onChange={handleChange}
      onSearchChange={setQuery}
      filter={() => true}
    />
  );
};

const CategoriesSearchField = (props: SearchableFieldProps) => {
  const [query, setQuery] = useState("");
  const { data, isLoading } = trpc.category.pos.search.useQuery(
    {
      query,
      limit: 10,
    },
    {
      onError(error) {
        reportUserError({
          title: "Failed to get categories",
          message: error.message,
        });
      },
    },
  );

  const selectData = useMemo(
    () =>
      data?.map((e) => ({
        label: e.name,
        value: e.id.toString(),
        category: e,
        // ^ Add the whole category so that we can refer to it on selection
      })) ?? [],
    [data],
  );

  const tctx = trpc.useContext();
  const handleSelect = async (category: Category) => {
    const itemSkus = await tctx.client.category.pos.itemSkus.query({
      categoryId: category.id,
    });

    props.onSelect(itemSkus);
  };

  const handleChange = (selectedId: string | null) => {
    if (selectedId == null) return;

    const selectedEntry = selectData.find((e) => e.value === selectedId);
    if (!selectedEntry) {
      throw new Error("selected non existing data");
      // ^ Theoretically, this should never happen.
    }

    handleSelect(selectedEntry.category);
  };

  return (
    <Select
      data={selectData}
      searchable
      placeholder="Search by category..."
      rightSection={isLoading && <SpinnerIcon />}
      value={null}
      // ^ We only use this select as search field, so we don't care about
      // showing a value.
      onChange={handleChange}
      onSearchChange={setQuery}
      filter={() => true}
    />
  );
};

const TagsSearchField = (props: SearchableFieldProps) => {
  const [query, setQuery] = useState("");
  const tctx = trpc.useContext();
  const { data, isLoading } = trpc.v2_5.tags.getAll.useQuery();

  const selectData = useMemo(
    () =>
      data
        ?.map((e) => ({
          label: e.name,
          value: e.id.toString(),
          tag: e,
          // ^ Add the whole tag so that we can refer to it on selection
        }))
        ?.filter((e) => e.label.toLowerCase().includes(query.toLowerCase())) ??
      [],
    [data, query],
  );

  const handleSelect = async (tag: Tag) => {
    const itemSkus = await tctx.client.v2_5.tags.getItemSkus.query({
      tagId: tag.id,
    });

    props.onSelect(itemSkus);
  };

  const handleChange = (selectedId: string | null) => {
    if (selectedId == null) return;

    const selectedEntry = selectData.find((e) => e.value === selectedId);
    if (!selectedEntry) {
      throw new Error("selected non existing data");
      // ^ Theoretically, this should never happen.
    }

    handleSelect(selectedEntry.tag);
  };

  return (
    <Select
      data={selectData}
      searchable
      placeholder="Search by tag..."
      value={null}
      rightSection={isLoading && <SpinnerIcon />}
      onChange={handleChange}
      onSearchChange={setQuery}
      filter={() => true}
      limit={10}
    />
  );
};

const SeasonSearchField = (props: SearchableFieldProps) => {
  const [query, setQuery] = useState("");
  const tctx = trpc.useContext();
  const { data, isLoading } = trpc.v2_5.season.getAll.useQuery();

  const selectData = useMemo(
    () =>
      data
        ?.map((e) => ({
          label: e.name,
          value: e.id.toString(),
          season: e,
          // ^ Add the whole season so that we can refer to it on selection
        }))
        ?.filter((e) => e.label.toLowerCase().includes(query.toLowerCase())) ??
      [],
    [data, query],
  );

  const handleSelect = async (season: Season) => {
    const itemSkus = await tctx.client.v2_5.season.getItemSkus.query({
      seasonId: season.id,
    });

    props.onSelect(itemSkus);
  };

  const handleChange = (selectedId: string | null) => {
    if (selectedId == null) return;

    const selectedEntry = selectData.find((e) => e.value === selectedId);
    if (!selectedEntry) {
      throw new Error("selected non existing data");
      // ^ Theoretically, this should never happen.
    }

    handleSelect(selectedEntry.season);
  };

  return (
    <Select
      data={selectData}
      searchable
      placeholder="Search by season..."
      value={null}
      rightSection={isLoading && <SpinnerIcon />}
      onChange={handleChange}
      onSearchChange={setQuery}
      filter={() => true}
      limit={10}
    />
  );
};

const PromotionSearchField = (props: SearchableFieldProps) => {
  const [query, setQuery] = useState("");
  const tctx = trpc.useContext();
  const { data, isLoading } = trpc.v2_5.promotion.getAll.useQuery();

  const selectData = useMemo(
    () =>
      data
        ?.map((e) => ({
          label: e.name,
          value: e.id.toString(),
          promotion: e,
          // ^ Add the whole promotion so that we can refer to it on selection
        }))
        ?.filter((e) => e.label.toLowerCase().includes(query.toLowerCase())) ??
      [],
    [data, query],
  );

  const handleSelect = async (promotion: Promotion) => {
    const itemSkus = await tctx.client.v2_5.promotion.getItemSkus.query({
      promotionId: promotion.id,
    });

    props.onSelect(itemSkus);
  };

  const handleChange = (selectedId: string | null) => {
    if (selectedId == null) return;

    const selectedEntry = selectData.find((e) => e.value === selectedId);
    if (!selectedEntry) {
      throw new Error("selected non existing data");
      // ^ Theoretically, this should never happen.
    }

    handleSelect(selectedEntry.promotion);
  };

  return (
    <Select
      data={selectData}
      searchable
      placeholder="Search by promotion..."
      value={null}
      rightSection={isLoading && <SpinnerIcon />}
      onChange={handleChange}
      onSearchChange={setQuery}
      filter={() => true}
      limit={10}
    />
  );
};
