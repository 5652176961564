import GTLoader from "#/components-ng/loader.js";
import { useAuth } from "#/context/AuthContext.js";
import { trpc } from "#/trpc.js";
import { reportUserError } from "#/util/index.js";
import {
  InventoryTransferForm,
  valuesDefaultInventoryTransfer,
} from "./form/index.js";
import { itemSkusAtom, resetItemsAtom } from "./form/state.js";
import { LoadingOverlay } from "@mantine/core";
import { useSetAtom } from "jotai";
import React from "react";
import { useNavigate, useParams } from "react-router";

export const EditInventoryTransfer = () => {
  const { id } = useParams();
  const [{ auth }] = useAuth();
  const setItemSkusAtom = useSetAtom(itemSkusAtom);
  const resetItems = useSetAtom(resetItemsAtom);
  const navigate = useNavigate();

  const { data, isLoading } = trpc.inventoryTransfer.getById.useQuery(
    {
      id: Number(id),
    },
    {
      cacheTime: 0,
      onError(error) {
        reportUserError({
          title: "Failed to get inventory transfer",
          message: error.message,
        });
      },
    }
  );

  const defaultValues = React.useMemo(() => {
    if (!data) return null;

    const items = data.inventoryTransferItemSkus.map((item) => {
      const fromStock = item.itemSku.itemSkuStock.find(
        (stock) => stock.filialId === data.filialFromId
      );
      const toStock = item.itemSku.itemSkuStock.find(
        (stock) => stock.filialId === data.filialToId
      );

      return {
        sku: item.itemSku.sku,
        id: item.itemSkuId,
        title: item.itemSku.title,
        quantity: item.quantity,
        stockFrom: fromStock?.quantity ?? 0,
        stockTo: toStock?.quantity ?? 0,
        diffStockFrom: (fromStock?.quantity ?? 0) - item.quantity,
        diffStockTo: (toStock?.quantity ?? 0) + item.quantity,
      };
    });

    setItemSkusAtom(items);

    return {
      filialFromId: data.filialFromId.toString(),
      filialToId: data.filialToId.toString(),
      reason: data.reason,
      note: data.note,
    };
  }, [data, setItemSkusAtom]);

  const { mutate, isLoading: isLoadingUpdate } =
    trpc.inventoryTransfer.update.useMutation({
      onSuccess() {
        navigate("/inventory/inventory-transfer");
        resetItems();
      },
      onError(error) {
        reportUserError({
          title: "Failed to update inventory transfer",
          message: error.message,
        });
      },
    });

  const handleSubmit = React.useCallback(
    (inventoryTransfer: valuesDefaultInventoryTransfer) => {
      if (!auth?.user?.id) {
        reportUserError({
          title: "Failed to update inventory transfer",
          message: "User id is undefined",
        });
        return;
      }

      if (!inventoryTransfer.filialFromId || !inventoryTransfer.filialToId) {
        reportUserError({
          title: "Failed to update inventory transfer",
          message: "You must select filials",
        });
        return;
      }

      if (!inventoryTransfer.reason) {
        reportUserError({
          title: "Failed to update inventory transfer",
          message: "You must select reason",
        });
        return;
      }

      if (inventoryTransfer.itemSkus.length === 0) {
        reportUserError({
          title: "Failed to update inventory transfer",
          message: "You must add at least one item",
        });
        return;
      }

      if (
        inventoryTransfer.status === "COMPLETE" &&
        inventoryTransfer.itemSkus.some((item) => item.quantity === 0)
      ) {
        reportUserError({
          title: "Failed to create inventory transfer",
          message: "You must add quantity for all items",
        });
        return;
      }

      mutate({
        id: Number(id),
        input: {
          filialFromId: Number(inventoryTransfer.filialFromId),
          filialToId: Number(inventoryTransfer.filialToId),
          status: inventoryTransfer.status,
          reason: inventoryTransfer.reason,
          note: inventoryTransfer.note,
          userId: auth.user.id,
          inventoryTransferItemSku: inventoryTransfer.itemSkus.map((item) => ({
            itemSkuId: item.id,
            quantity: item.quantity,
            previousQuantityFrom: item.fromStock,
            previousQuantityTo: item.toStock,
          })),
        },
      });
    },
    [auth, mutate, id]
  );

  return isLoading ? (
    <LoadingOverlay
      visible={isLoading}
      loader={<GTLoader width={100} height={100} />}
    />
  ) : (
    <InventoryTransferForm
      isLoading={isLoadingUpdate}
      onSubmit={handleSubmit}
      defaultValues={defaultValues as any}
    />
  );
};
