import {
  CatalogCustomIcon,
  CustomAttributeCustomIcon,
  InventoryTransferCustomIcon,
  TagCustomIcon,
  TicketOutlineIcon,
  TruckThinIcon,
} from "#/components-ng/index.js";
import { css } from "#/css/css";
import Card from "../../components/Card/index.js";
import CategoryGridLayout from "../../components/CategoryGridLayout/index.js";
import {
  CartCancelIcon,
  ChatBalloonIcon,
  LeafIcon,
  MoneyBackIcon,
  NewArrivalsIcon,
  PaperTimeIcon,
  PercentageCircleIcon,
  SpeakerIcon,
  UserIcon,
  ItemListIcon,
  DepartmentIcon,
  CategoryIcon,
  AdjustInventoryIcon,
} from "../../components/icons/index.jsx";
import { Stack, StackDivider } from "@chakra-ui/react";
import * as M from "@mantine/core";
import PricetagIcon from "~icons/ion/pricetag-outline";

const InventoryScene = () => {
  return (
    <Stack spacing={6} divider={<StackDivider />}>
      <CategoryGridLayout category="Items">
        <Card
          icon={<ItemListIcon size="big" />}
          text="Item list"
          path="/inventory/item-list"
          module="inventory.itemList"
        ></Card>
        <Card
          icon={<NewArrivalsIcon size="giant" />}
          text="New arrivals"
          path="/inventory/new-arrivals"
        ></Card>
        <Card
          icon={<DepartmentIcon size="big" />}
          text="Department"
          path="/inventory/departments"
          module="inventory.departments"
        ></Card>
        <Card
          icon={<CategoryIcon size="big" />}
          text="Category"
          path="/inventory/categories"
          module="inventory.categories"
        ></Card>
        <Card
          icon={<LeafIcon size="big" fontSize="large" />}
          text="Season"
          path="/inventory/seasons"
          module="inventory.seasons"
        ></Card>
        <Card
          icon={<PercentageCircleIcon size="big" fontSize="large" />}
          text="Discount"
          path="/inventory/discounts"
          module="inventory.discounts"
        ></Card>
        <Card
          icon={<TicketOutlineIcon fontSize={28} />}
          text="Coupon"
          path="/inventory/coupons"
          module="inventory.coupons"
        ></Card>
        <Card
          icon={
            <M.Box sx={{ "& svg path ": { strokeWidth: 18 } }}>
              <PricetagIcon fontSize={32} />
            </M.Box>
          }
          text="Promotions"
          path="/inventory/promotions"
          module="inventory.promotions"
        ></Card>
        <Card
          icon={<AdjustInventoryIcon size="big" />}
          text="Inventory Adjustment"
          path="/inventory/adjust"
          module="inventory.inventoryAdjustments"
        />
        <Card
          icon={<TagCustomIcon />}
          text="Tags"
          path="/inventory/tags"
          module="inventory.tags"
        />
        <Card
          icon={<InventoryTransferCustomIcon />}
          text="Inventory transfer"
          path="/inventory/inventory-transfer"
          module="inventory.inventoryTransfer"
        />
        <Card
          icon={<CustomAttributeCustomIcon />}
          text="Custom attributes"
          path="/inventory/custom-attributes"
          module="inventory.customAttributes"
        />
      </CategoryGridLayout>
      <CategoryGridLayout category="News">
        <Card
          icon={<PaperTimeIcon size="big" />}
          text="Waiting list"
          path="/inventory/waiting-list"
          module="inventory.waitingList"
        ></Card>
        <Card
          icon={<SpeakerIcon size="big" />}
          text="Upcoming items"
          path="/inventory/upcoming-items"
          module="inventory.upcomingItems"
        ></Card>
        <Card
          icon={<CatalogCustomIcon />}
          text="Catalog"
          path="/inventory/catalog"
          module="inventory.catalog"
        ></Card>
        <Card
          icon={
            <TruckThinIcon
              className={css({
                width: 28,
                height: 8,
              })}
            />
          }
          text="Suppliers"
          path="/inventory/suppliers"
          module="inventory.suppliers"
        ></Card>
      </CategoryGridLayout>
      <CategoryGridLayout category="Providers">
        <Card
          icon={<UserIcon size="big" />}
          text="Vendors"
          path="/inventory/vendors"
          module="inventory.vendors"
        ></Card>
        <Card
          icon={<MoneyBackIcon size="big" />}
          text="Rate cost"
          path="/inventory/rate-cost"
          module="inventory.rateCost"
        ></Card>
      </CategoryGridLayout>
      <CategoryGridLayout category="Help">
        <Card
          icon={<ChatBalloonIcon size="big" />}
          text="Suggested items"
          path="/inventory/suggested-items"
          module="inventory.suggestedItems"
        ></Card>
        <Card
          icon={<CartCancelIcon size="big" />}
          text="Out of stock"
          path="/inventory/out-of-stock"
          module="inventory.outOfStock"
        ></Card>
        <Card
          icon={<CartCancelIcon size="big" />}
          text="Reorder point"
          path="/inventory/reorder-point"
          module="inventory.reorderPoint"
        />
      </CategoryGridLayout>
    </Stack>
  );
};

export default InventoryScene;
