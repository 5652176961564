import { RouterOutputs, trpc } from "#/trpc";
import { VariantsGallery } from "./VariantsGallery";
import { Badge, Divider } from "@mantine/core";

type Product = NonNullable<RouterOutputs["itemSku"]["getById"]>;

interface ProductInfoProps {
  product?: Product | null;
}
export function ProductInfo(props: ProductInfoProps) {
  const { data: itemTags } = trpc.itemTag.getByItemId.useQuery(
    {
      itemId: props.product?.itemId ?? 0,
    },
    {
      enabled: !!props.product?.itemId,
      cacheTime: 0,
    }
  );

  return (
    <div className="max-w-[500px]">
      <h2 className="mb-5 text-xl font-semibold">SKU {props.product?.sku}</h2>
      <div className="grid grid-cols-1 gap-y-2 lg:grid-cols-2">
        <p className="text-sm font-normal text-[#666]">Title</p>
        <p className="text-sm font-normal text-[#212121]">
          {props.product?.title}
        </p>
        <p className="text-sm font-normal text-[#666]">Tags</p>
        <div className="flex flex-wrap gap-2">
          {itemTags?.map((tag) => (
            <Badge key={tag.id} bg="#F0F0F0" className="text-[#525252]/70">
              {tag.tag.name}
            </Badge>
          ))}
        </div>
      </div>
      <Divider className="my-5" size={3} color="#E0E0E0" />
      <div className="grid grid-cols-1 gap-y-2 lg:grid-cols-2 lg:gap-x-10">
        <Details title="Package" value={props.product?.presentationValue} />
        <Details title="Heigh (mm)" value={props.product?.height} />
        <Details title="Unit" value={props.product?.presentationType} />
        <Details title="Lenght (mm)" value={props.product?.length} />
        <Details title="Width (mm)" value={props.product?.width} />
        <Details title="Weight (g)" value={props.product?.weight} />
        <Details title="Material" value={props.product?.item?.material} />
        <Details title="Size" value={props.product?.size} />
      </div>
      <Divider className="my-5" size={3} color="#E0E0E0" />
      <div>
        <VariantsGallery
          sku={props.product?.sku}
          itemId={props?.product?.itemId}
        />
      </div>
    </div>
  );
}

interface Details {
  title: string;
  value?: string | number | null;
}
const Details = (props: Details) => {
  const value =
    typeof props.value === "number"
      ? props.value > 0
        ? props.value
        : "-"
      : props.value;

  return (
    <div className="flex justify-between">
      <p className="text-sm font-normal text-[#666]">{props.title}</p>
      <p className="text-sm font-normal text-[#212121]">{value ?? "-"}</p>
    </div>
  );
};
