import { RouterOutputs, trpc } from "#/trpc.js";
import { reportUserSuccess } from "#/util/index.js";
import { TagFormCreateContent } from "./create.js";
import { TagFormUpdateContent } from "./update.js";
import { ActionIcon, Menu } from "@mantine/core";
import { DropdownMenu, IconButton, Tooltip } from "@radix-ui/themes";
import { openConfirmModal } from "@mantine/modals";
import { atom, useAtom, useSetAtom } from "jotai";
import { useMemo, useState } from "react";
import { MdMenu, MdOutlineDownload } from "react-icons/md/index.js";
import EditIcon from "~icons/ion/create-outline";
import DeleteIcon from "~icons/ion/trash-outline";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_FilterOption,
  MRT_PaginationState,
  MRT_SortingState,
  useMantineReactTable,
} from "mantine-react-table";
import { Box, HStack } from "#/css/jsx";
import { css } from "#/css/css";
import { Button, Dropdown } from "@gt/ui";
import { SearchButton } from "#/components@v2_5/search-button.js";
import placeholderImage from "#/placeholder-image.jpg";
import dayjs from "dayjs";

const isFormOpenAtom = atom<boolean>(false);
const tagSelectedAtom = atom<number | null>(null);

export function Tags() {
  const [isFormOpen, setIsFormOpen] = useAtom(isFormOpenAtom);
  const [tagSelected, setTagSelected] = useAtom(tagSelectedAtom);

  // Pagination
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });

  // Sorting
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const querySorting = useMemo(() => {
    if (sorting[0]) {
      return {
        desc: sorting[0].desc,
        key: sorting[0].id as any,
      };
    }
    return null;
  }, [sorting]);

  const [globalFilter, setGlobalFilter] = useState<
    MRT_FilterOption | undefined
  >();

  const { data, isLoading } = trpc.v2_5.tags.getAllTagsByPage.useQuery(
    {
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize,
      sorting: querySorting,
      search: globalFilter,
      filters: null,
    },
    {
      cacheTime: 0,
      keepPreviousData: true,
    },
  );

  const { mutate: exportCsv, isLoading: isLoadingExport } =
    trpc.v2_5.tags.exportTagsTableToCsv.useMutation({
      onSuccess: () => {
        reportUserSuccess({
          title: "The csv file has been sent to email",
        });
      },
    });

  const handleExportCsv = () => {
    exportCsv({
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize,
      sorting: querySorting,
      search: globalFilter,
      filters: null,
    });
  };

  const table = useMantineReactTable({
    data: data?.entries ?? [],
    columns: columns(),
    manualPagination: true,
    enableTopToolbar: false,
    rowCount: data?.totalEntries ?? 0,
    enableFilters: false,
    state: {
      pagination,
      sorting,
      isLoading,
    },
    enableStickyHeader: true,
    manualSorting: true,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    renderBottomToolbarCustomActions: () => (
      <div
        className={css({
          display: "flex",
          alignItems: "center",
          gap: 2,
        })}
      >
        <Tooltip content="Export to CSV">
          <IconButton
            color="gray"
            variant="soft"
            loading={isLoadingExport}
            onClick={handleExportCsv}
          >
            <MdOutlineDownload />
          </IconButton>
        </Tooltip>
      </div>
    ),
  });

  return (
    <>
      <TagFormCreateContent
        opened={isFormOpen}
        onClose={() => setIsFormOpen(false)}
      />
      {tagSelected && (
        <TagFormUpdateContent
          opened={isFormOpen}
          onClose={() => {
            setIsFormOpen(false);
            setTagSelected(null);
          }}
          id={tagSelected}
        />
      )}
      <div>
        <HStack mb={6} justify="space-between" gap={3}>
          <DrowndownButton setIsFormOpen={() => setIsFormOpen(true)} />
          <SearchButton setQuery={setGlobalFilter} />
        </HStack>
        <MantineReactTable table={table} />
      </div>
    </>
  );
}

type Tags =
  RouterOutputs["v2_5"]["tags"]["getAllTagsByPage"]["entries"][number];

const columns = (): MRT_ColumnDef<Tags>[] => [
  {
    header: "Actions",
    id: "actions",
    size: 10,
    Cell: ({ row: { original } }) => {
      const ctx = trpc.useContext();
      const setIsFormOpen = useSetAtom(isFormOpenAtom);
      const setTagSelected = useSetAtom(tagSelectedAtom);

      const { mutate: deleteTag } = trpc.tag.delete.useMutation({
        onSuccess() {
          ctx.v2_5.tags.invalidate();
          reportUserSuccess({
            title: "Tag deleted",
          });
        },
      });

      return (
        <Menu>
          <Menu.Target>
            <ActionIcon>
              <MdMenu size={20} />
            </ActionIcon>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item
              onClick={() => {
                setTagSelected(Number(original.id));
                setIsFormOpen(true);
              }}
              icon={<EditIcon />}
            >
              Edit
            </Menu.Item>
            <Menu.Item
              onClick={() =>
                openConfirmModal({
                  title: "Are you sure you want to delete this tag",
                  labels: {
                    confirm: "Delete",
                    cancel: "Cancel",
                  },
                  confirmProps: { color: "red" },
                  onConfirm: () =>
                    deleteTag({
                      id: original.id as number,
                    }),
                })
              }
              icon={<DeleteIcon />}
            >
              Delete
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      );
    },
  },
  {
    header: "Image",
    accessorKey: "defaultImage",
    enableSorting: false,
    size: 50,
    Cell: ({ row: { original } }) => {
      return (
        <Box w={16}>
          <img src={original.defaultImage ?? placeholderImage} alt="" />
        </Box>
      );
    },
  },
  {
    header: "Tag name",
    accessorKey: "name",
  },
  {
    header: "Description",
    accessorKey: "description",
  },
  {
    header: "createdAt",
    accessorKey: "createdAt",
    Cell: ({
      row: {
        original: { createdAt },
      },
    }) => {
      return dayjs(createdAt).format("MM/DD/YYYY").toString();
    },
  },
];

const DrowndownButton = ({ setIsFormOpen }: { setIsFormOpen: () => void }) => {
  return (
    <Dropdown.Root>
      <Dropdown.Trigger>
        <Button
          className={css({
            gap: 4,
          })}
        >
          I want to
          <DropdownMenu.TriggerIcon />
        </Button>
      </Dropdown.Trigger>
      <Dropdown.Content>
        <Dropdown.Item>
          <p onClick={setIsFormOpen}>Create tag</p>
        </Dropdown.Item>
      </Dropdown.Content>
    </Dropdown.Root>
  );
};
