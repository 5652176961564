import React from "react";
import * as C from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import _ from "lodash";

/**
 * @typedef {{
 *     required?: boolean,
 *     maxLength?: number,
 *     minLength?: number,
 *     max?: number,
 *     min?: number,
 *     pattern?: import("react-hook-form").ValidationRule<RegExp>,
 *     validate?: import("react-hook-form").Validate<?>,
 *     valueAsNumber?: boolean,
 *     valueAsDate?: boolean
 *   }
 * } Rules
 * @returns
 */

/**
 * @param {{
 *   name: string,
 *   children?: React.ReactChild | (params: ?) => ?,
 *   label?: string,
 *   htmlFor?: string,
 *   render?: (renderProps: Rules & { uid: string, name?: string }) => React.ReactNode,
 * } & Rules & import("@chakra-ui/react").BoxProps} props
 */
export default function FormControl({
  name,
  children,
  label,
  htmlFor,
  render,
  // rules
  required,
  maxLength,
  minLength,
  max,
  min,
  pattern,
  validate,
  valueAsNumber,
  valueAsDate,
  ...props
}) {
  const uid = _.uniqueId("fc_");
  const {
    formState: { errors },
  } = useFormContext();
  const error = _.get(errors, name.split("."));
  let errorMessage = error?.message || "";
  if (error && errorMessage.length === 0) {
    switch (error.type) {
      case "required":
        errorMessage = "Field is required.";
        break;
      case "minLength":
        errorMessage = "Minimum length not met.";
        break;
      case "maxLength":
        errorMessage = "Maximum length passed.";
        break;
      case "min":
        errorMessage = "Minimum value not met.";
        break;
      case "max":
        errorMessage = "Maximum value passed.";
        break;
      case "pattern":
        errorMessage = "Value does not match pattern.";
        break;
      case "validate":
      default:
        errorMessage = "Invalid value";
    }
  }

  return (
    <C.FormControl isInvalid={!!error} position="relative" {...props}>
      {label && (
        <C.FormLabel
          htmlFor={uid}
          _after={{ content: required ? '"*"' : '""' }}
        >
          {label}
        </C.FormLabel>
      )}
      {render
        ? render({
            uid,
            id: uid,
            name,
            required,
            maxLength,
            minLength,
            max,
            min,
            pattern,
            validate,
            valueAsNumber,
            valueAsDate,
          })
        : typeof children === "function"
        ? children({
            uid,
            id: uid,
            name,
            required,
            maxLength,
            minLength,
            max,
            min,
            pattern,
            validate,
            valueAsNumber,
            valueAsDate,
          })
        : React.cloneElement(children, {
            name,
            ...children.props,
            id: children.props.id ? children.props.id + ` ${uid}` : uid,
            required,
            maxLength,
            minLength,
            max,
            min,
            pattern,
            validate,
            valueAsNumber,
            valueAsDate,
          })}
      {error && (
        <C.FormErrorMessage
          position="absolute"
          transform="translateY(0%)"
          mt={1}
          bg="white"
          zIndex="docked"
        >
          {errorMessage}
        </C.FormErrorMessage>
      )}
    </C.FormControl>
  );
}
