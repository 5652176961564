import { trpc } from "#/trpc";
import { reportUserError, reportUserSuccess } from "#/util";
import { useNavigate } from "react-router";
import { FormValues } from "./Form/types";
import { RouletteForm } from "./Form";

export const CreateRouletteForm = () => {
  const navigate = useNavigate();
  const ctx = trpc.useContext();

  const { mutate, isLoading } = trpc.v2_5.roulette.create.useMutation({
    onSuccess(data) {
      ctx.v2_5.roulette.invalidate();
      reportUserSuccess({
        title: "Roulette created",
      });
      navigate(`/ecommerce-crm/roulette/edit/${data.id}`);
    },
  });

  const handleSubmit = (values: FormValues) => {
    if (!values.startDate || !values.startDate) {
      reportUserError({
        title: "Please enter a start date and end date.",
      });
      return;
    }

    mutate({
      title: values.title,
      description: values.description,
      startDate: values.startDate!,
      endDate: values.endDate!,
      active: values.active,
    });
  };

  return (
    <RouletteForm
      isLoading={isLoading}
      onSubmit={handleSubmit}
      rouletteItems={[]}
    />
  );
};
