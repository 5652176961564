import { FilterOutlineIcon } from "#/components-ng/icons";
import DisplayTableContainer from "#/components/DisplayTableContainer/index.jsx";
import DisplayTable from "#/components/Table/DisplayTable/index.jsx";
import { useAuth } from "#/context/AuthContext";
import { trpc } from "#/trpc.js";
import { reportUserError } from "#/util";
import * as M from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import dayjs from "dayjs";
import { debounce } from "lodash";
import { useParams } from "react-router-dom";
import SearchIcon from "~icons/ic/baseline-search";
import CalendarIcon from "~icons/ion/calendar-outline";

type FilialFilterProps = {
  title: string;
  filialId: number;
  state: boolean;
};

type FilteredItemLogsProps = {
  filialFilter: FilialFilterProps[];
  itemSkuId: number;
  startDate: Date | null;
  endDate: Date | null;
  query: string;
};

export default function PricingHistory() {
  const [{ auth }] = useAuth();
  const params = useParams() as any;
  const itemSkuId = Number.parseInt(params.id);

  const [query, setQuery] = useState<string>("");
  const [filialFilter, setFilialFilter] = useState<FilialFilterProps[]>([]);
  const [dateRangeFilter, setDateRangeFilter] = useState<
    [Date | null, Date | null]
  >([dayjs().subtract(1, "month").toDate(), new Date()]);

  trpc.filial.getAll.useQuery(undefined, {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      if (data) {
        //manda al estado el title, state e filiald
        setFilialFilter(
          data.map((f) => ({ title: f.name, state: true, filialId: f.id }))
        );
      }
    },

    onError: (err) => {
      reportUserError({
        title: "Failed to load filials",
        message: err.message,
      });
    },
  });

  const handleFilterChange = (filialPosition) => {
    setFilialFilter({
      ...filialFilter,
      [filialPosition]: {
        ...filialFilter[filialPosition],
        state: !filialFilter[filialPosition].state,
      },
    });
  };

  return (
    <div>
      <M.Flex justify="space-between" gap={3}>
        <M.Group>
          {!auth?.user.filialId && (
            <M.Popover
              width={200}
              position="bottom"
              withArrow
              shadow="md"
              zIndex={1600}
              radius="sm"
            >
              <M.Popover.Target>
                <M.Button
                  leftIcon={<FilterOutlineIcon />}
                  color="white"
                  c="dark.6"
                >
                  Filter by filial
                </M.Button>
              </M.Popover.Target>
              <M.Popover.Dropdown>
                <M.Stack>
                  {Object.keys(filialFilter ?? {}).map((id) => (
                    // un array de las keys de los objetos (id del objeto)
                    // entonces obtien la informacion del objeto con el id
                    <div key={filialFilter[id].filialId}>
                      <M.Checkbox
                        label={filialFilter[id].title}
                        checked={filialFilter[id].state}
                        onChange={() => handleFilterChange(id)}
                      />
                    </div>
                  ))}
                </M.Stack>
              </M.Popover.Dropdown>
            </M.Popover>
          )}
          <M.Popover zIndex={9999}>
            <M.Popover.Target>
              <M.Button
                color="gray.1"
                leftIcon={<CalendarIcon />}
                sx={(t) => ({ color: t.colors.gray[8] })}
              >
                Filter by date
              </M.Button>
            </M.Popover.Target>
            <M.Popover.Dropdown>
              <DatePicker
                type="range"
                value={dateRangeFilter}
                onChange={setDateRangeFilter}
                allowSingleDateInRange={true}
              />
            </M.Popover.Dropdown>
          </M.Popover>
        </M.Group>
        <M.TextInput
          icon={<SearchIcon />}
          placeholder="Search..."
          onChange={(e) => {
            const v = e.currentTarget.value.toLowerCase();
            debounce(() => setQuery(v), 200)();
          }}
        />
      </M.Flex>
      <M.Flex gap={5} mt={24} align={"start"}>
        <DisplayTableContainer flexGrow={1}>
          <FilteredItemLogs
            startDate={dateRangeFilter[0]}
            endDate={dateRangeFilter[1]}
            query={query}
            itemSkuId={itemSkuId}
            filialFilter={filialFilter}
          />
        </DisplayTableContainer>
      </M.Flex>
    </div>
  );
}

function FilteredItemLogs({
  filialFilter,
  itemSkuId,
  startDate,
  endDate,
  query,
}: FilteredItemLogsProps) {
  const [{ auth }] = useAuth();

  //extrae los ids de los filiales activos (true)
  const filialIds = Object.values(filialFilter)
    .filter((filial) => filial.state)
    .map((filial) => filial.filialId);

  const { data, isLoading } =
    trpc.v2_5.itemLog.getPricingHistoryByItemSku.useQuery({
      itemSkuId: itemSkuId,
      startDate: startDate,
      endDate: endDate,
      filialIds: auth?.user?.filialId ? [auth?.user?.filialId] : filialIds,
      search: query,
    });

  return (
    <DisplayTable data={data ?? []} columns={columns} isLoading={isLoading} />
  );
}

const columns = [
  {
    Header: "Filial",
    id: "id",
    isnumeric: true,
    Cell: ({ row: { original } }) => {
      return <M.Text color="#3D3D3D">{original.filial.name}</M.Text>;
    },
  },
  {
    Header: "User",

    Cell: ({ row: { original } }) => {
      return (
        <M.Text color="#3D3D3D">
          {original.user.firstName ?? " " + " " + original.user.lastName ?? " "}
        </M.Text>
      );
    },
  },
  {
    Header: "Date",
    Cell: ({ row: { original } }) => {
      return dayjs(original.createdAt).format("DD/MM/YYYY");
    },
  },
  {
    Header: "Old cost",
    Cell: ({ row: { original } }) => {
      return <M.Text>{original.oldCost?.toNumber().toFixed(2) ?? ""}</M.Text>;
    },
  },
  {
    Header: "New cost",
    Cell: ({ row: { original } }) => {
      return <M.Text>{original.newCost?.toNumber().toFixed(2) ?? ""}</M.Text>;
    },
  },
  {
    Header: "Cost var %",
    Cell: ({ row: { original } }) => {
      return (
        <M.Text>{`%${(
          ((original.newCost!.toNumber() - original.oldCost!.toNumber()) /
            (original.oldCost?.toNumber() === 0
              ? 1
              : original.oldCost!.toNumber())) *
          100
        ).toFixed(2)}`}</M.Text>
      );
    },
  },
  {
    Header: "Old price",
    Cell: ({ row: { original } }) => {
      return <M.Text>{original.oldPrice?.toNumber().toFixed(2) ?? ""}</M.Text>;
    },
  },
  {
    Header: "New price",
    Cell: ({ row: { original } }) => {
      return <M.Text>{original.newPrice?.toNumber().toFixed(2) ?? ""}</M.Text>;
    },
  },
  {
    Header: "Price var %",
    Cell: ({ row: { original } }) => {
      return (
        <M.Text>{`%${(
          ((original.newPrice!.toNumber() - original.oldPrice!.toNumber()) /
            (original.oldPrice?.toNumber() === 0
              ? 1
              : original.oldPrice!.toNumber())) *
          100
        ).toFixed(2)}`}</M.Text>
      );
    },
  },
];
