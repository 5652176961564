import { trpc, RouterOutputs } from "#/trpc";
import { Button, Dropdown } from "@gt/ui";
import {
  MantineReactTable,
  MRT_ColumnDef,
  useMantineReactTable,
} from "mantine-react-table";
import { Flex, styled } from "#/css/jsx";
import { IconButton } from "@radix-ui/themes";
import { EllipsisHorizontal } from "#/components-ng";
import placeholderImage from "#/placeholder-image.jpg";
import { css } from "#/css/css";
import dayjs from "dayjs";
import { reportUserError, reportUserSuccess } from "#/util";
import { CatalogImageFormDrawer } from "./catalog-image-form-drawer";
import { CatalogImageFormValues } from "./types";
import { v4 as uuidv4 } from "uuid";
import { useDisclosure } from "@mantine/hooks";

interface CatalogImagesTableProps {
  catalogId: number;
}

export const CatalogImagesTable = (props: CatalogImagesTableProps) => {
  const [opened, { open, close }] = useDisclosure(false);

  // Pagination
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const { data, isLoading, isFetchedAfterMount } =
    trpc.v2_5.catalog.getCatalogImagesByPage.useQuery(
      {
        pageIndex: pagination.pageIndex,
        pageSize: pagination.pageSize,
        filters: {
          catalogId: props.catalogId,
        },
      },
      {
        cacheTime: 0,
      },
    );

  const catalogImages = useMemo(() => data?.entries ?? [], [data?.entries]);

  const table = useMantineReactTable({
    data: catalogImages ?? [],
    columns: columns,
    manualPagination: true,
    enableTopToolbar: false,
    enableSorting: false,
    rowCount: data?.totalEntries ?? 0,
    enableFilters: false,
    state: {
      pagination,
      isLoading: isLoading || !isFetchedAfterMount ? true : false,
    },
    enableStickyHeader: true,
    manualSorting: true,
    onPaginationChange: setPagination,
  });

  return (
    <>
      {opened && (
        <CreateCatalogImageFormDrawer
          catalogId={props.catalogId}
          opened={opened}
          onClose={close}
        />
      )}
      <styled.div
        bg="white"
        p="40px"
        rounded="md"
        border="1px solid #E0E0E0"
        mb={10}
        height="auto"
      >
        <Flex direction="column" gap="4">
          <styled.h2 mb="1">Catalog images</styled.h2>
          <styled.p lineHeight="20px" fontSize="sm" color="#757575">
            Add images to your catalog
          </styled.p>
          <Button className={css({ width: "fit-content" })} onClick={open}>
            Add Image
          </Button>
          <MantineReactTable table={table} />
        </Flex>
      </styled.div>
    </>
  );
};

type CatalogImage =
  RouterOutputs["v2_5"]["catalog"]["getCatalogImagesByPage"]["entries"][number];

const columns: MRT_ColumnDef<CatalogImage>[] = [
  {
    id: "actions",
    header: "Actions",
    Cell(table) {
      const ctx = trpc.useContext();
      const [opened, { open, close }] = useDisclosure(false);

      const { mutate: updateCatalogImage, isLoading } =
        trpc.v2_5.catalog.updateCatalogImage.useMutation({
          onError(error) {
            reportUserError({
              title: "Error updating catalog image",
              message: error.message,
            });
          },
          onSuccess() {
            ctx.v2_5.catalog.getCatalogImagesByPage.invalidate();
            close();
            reportUserSuccess({
              title: "Catalog image updated",
              message: "Catalog image has been updated successfully",
            });
          },
        });

      const { mutate: deleteCatalogImage } =
        trpc.v2_5.catalog.deleteCatalogImage.useMutation({
          onError(error) {
            reportUserError({
              title: "Error deleting catalog image",
              message: error.message,
            });
          },
          onSuccess() {
            ctx.v2_5.catalog.getCatalogImagesByPage.invalidate();
            reportUserSuccess({
              title: "Catalog image deleted",
              message: "Catalog image has been deleted successfully",
            });
          },
        });

      const handleSubmit = (values: CatalogImageFormValues) => {
        close();
        if (!values.image || !values.image.url) {
          reportUserError({
            title: "Error creating catalog image",
            message: "Image is required",
          });
          return;
        }

        if (values.image.tags.length === 0) {
          reportUserError({
            title: "Error creating catalog image",
            message: "At least one tag is required",
          });
          return;
        }

        updateCatalogImage({
          catalogId: table.row.original.catalogItemId,
          catalogImageId: table.row.original.id,
          image: {
            url: values.image!.url,
            tags: values.image!.tags.map((tag) => ({
              title: tag.title,
              url: tag.url,
              x: tag.x,
              y: tag.y,
              itemSkuId: tag.itemSkuId,
            })),
          },
        });
      };

      const handleDelete = () => {
        deleteCatalogImage({
          id: table.row.original.id,
        });
      };

      const defaultValues = useMemo(() => {
        return {
          image: {
            url: table.row.original.url,
            isLoading: false,
            uid: uuidv4(),
            tags: table.row.original.tags.map((tag) => ({
              title: tag.title,
              url: tag.url,
              x: tag.x,
              y: tag.y,
              itemSkuId: tag.itemSkuId,
            })),
          },
        };
      }, [table.row.original]);

      return (
        <>
          {opened && (
            <CatalogImageFormDrawer
              opened={opened}
              onClose={close}
              title="Edit Catalog Image"
              onSubmit={handleSubmit}
              isLoading={isLoading}
              defaultValues={defaultValues}
            />
          )}
          <Dropdown.Root>
            <Dropdown.Trigger>
              <styled.div
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <IconButton variant="ghost" color="gray" size="1">
                  <EllipsisHorizontal />
                </IconButton>
              </styled.div>
            </Dropdown.Trigger>
            <Dropdown.Content>
              <Dropdown.Item onClick={open}>Edit</Dropdown.Item>
              <Dropdown.Item onClick={handleDelete}>Delete</Dropdown.Item>
            </Dropdown.Content>
          </Dropdown.Root>
        </>
      );
    },
  },
  {
    id: "image",
    header: "Image",
    Cell(table) {
      const image = table.row.original.url ?? placeholderImage;

      return (
        <img
          src={image}
          className={css({
            width: "50px",
            height: "50px",
          })}
        />
      );
    },
  },
  {
    id: "tags",
    header: "Tags",
    accessorKey: "tags",
    Cell(table) {
      return table.row.original.tags.length;
    },
  },
  {
    id: "createdAt",
    header: "Date",
    accessorKey: "createdAt",
    Cell(table) {
      const createdAt = table.row.original.createdAt;
      if (!createdAt) return "";
      return dayjs(createdAt).format("MM/DD/YYYY").toString();
    },
  },
];

interface CreateCatalogImageFormDrawer {
  catalogId: number;
  opened: boolean;
  onClose: () => void;
}

function CreateCatalogImageFormDrawer(props: CreateCatalogImageFormDrawer) {
  const ctx = trpc.useContext();

  const { mutate, isLoading } =
    trpc.v2_5.catalog.createCatalogImage.useMutation({
      onError(error) {
        reportUserError({
          title: "Error creating catalog image",
          message: error.message,
        });
      },
      onSuccess() {
        props.onClose();
        ctx.v2_5.catalog.invalidate();
        reportUserSuccess({
          title: "Catalog image created",
          message: "Catalog image has been created successfully",
        });
      },
    });

  const handleSubmit = (values: CatalogImageFormValues) => {
    if (!values.image || !values.image.url) {
      reportUserError({
        title: "Error creating catalog image",
        message: "Image is required",
      });
      return;
    }

    if (values.image.tags.length === 0) {
      reportUserError({
        title: "Error creating catalog image",
        message: "At least one tag is required",
      });
      return;
    }

    mutate({
      catalogId: props.catalogId,
      image: {
        url: values.image!.url,
        tags: values.image!.tags.map((tag) => ({
          title: tag.title,
          url: tag.url,
          x: tag.x,
          y: tag.y,
          itemSkuId: tag.itemSkuId,
        })),
      },
    });
  };

  return (
    <CatalogImageFormDrawer
      opened={props.opened}
      onClose={props.onClose}
      title="Create Catalog Image"
      onSubmit={handleSubmit}
      isLoading={isLoading}
      defaultValues={{ image: null }}
    />
  );
}
