import { trpc } from "#/trpc.js";
import { reportUserError, reportUserSuccess } from "#/util/index.js";
import { RateCostForm, RateCostFormValues } from "../Form/index.js";
import React from "react";
import { useNavigate } from "react-router-dom";

const inputRateCostDefaultValues = {
  id: undefined,
  isWithWeight: false,
  name: "",
  extendedByCost: {
    exchangeRate: 1,
    fees: [],
  },
  extendedByWeight: undefined,
  utilityRangeMin: 0,
  utilityRangeMax: 0,
};

export const CreateRateCost = () => {
  const ctx = trpc.useContext();
  const { data: nextReferenceCodeData } =
    trpc.rateCost.getNextRateCostId.useQuery(undefined, {
      onError: (error) => {
        reportUserError({
          title: "Failed to get next rate cost reference code",
          message: error.message,
        });
      },
    });

  const defaultValues: RateCostFormValues = React.useMemo(
    () => ({
      ...inputRateCostDefaultValues,
      id: nextReferenceCodeData,
    }),
    [nextReferenceCodeData],
  );
  const navigate = useNavigate();

  const { mutate, isLoading } = trpc.rateCost.create.useMutation({
    onSuccess: () => {
      reportUserSuccess({
        title: "Rate cost created successfully",
        message: "Rate cost created successfully",
      });
      ctx.v2_5.rateCost.invalidate();
      navigate("/inventory/rate-cost");
    },
    onError: (error) => {
      reportUserError({
        title: "Failed to create rate cost",
        message: error.message,
      });
    },
  });

  const handleSubmit = (formValues: RateCostFormValues) => {
    mutate({
      name: formValues.name,
      isWithWeight: formValues.isWithWeight,
      utilityRangeMax: formValues.utilityRangeMax,
      utilityRangeMin: formValues.utilityRangeMin,
      extendedByCost: {
        create: {
          exchangeRate: formValues.extendedByCost.exchangeRate,
          fees: {
            createMany: {
              data:
                formValues.extendedByCost.fees.map((fee) => ({
                  description: fee.description,
                  amount: fee.amount,
                  type: fee.type,
                  category: fee.category,
                })) ?? [],
            },
          },
        },
      },
      extendedByWeight: formValues.isWithWeight
        ? {
            create: {
              taxCostPercentage:
                formValues?.extendedByWeight!.taxCostPercentage,
              fees: {
                createMany: {
                  data:
                    formValues?.extendedByWeight!.fees.map((fee) => ({
                      description: fee.description,
                      amount: fee.amount,
                      type: fee.type,
                      category: fee.category,
                    })) ?? [],
                },
              },
            },
          }
        : undefined,
    });
  };

  return (
    <RateCostForm
      onSubmit={handleSubmit}
      defaultValues={defaultValues}
      loading={isLoading}
    />
  );
};
