import { RouterOutputs, trpc } from "#/trpc";
import { modals } from "@mantine/modals";
import dayjs from "dayjs";
import {
  MantineReactTable,
  MRT_ColumnDef,
  useMantineReactTable,
} from "mantine-react-table";

export function openWinnersByRouletteItemIdModal(id: number) {
  modals.open({
    size: "xl",
    children: <WinnersByRouletteItemIdModalChildren id={id} />,
    modalId: "WINNERS-BY-ROULETTE-ITEM-ID-MODAL",
  });
}

interface WinnersByRouletteItemIdModalChildrenProps {
  id: number;
}

const WinnersByRouletteItemIdModalChildren = (
  props: WinnersByRouletteItemIdModalChildrenProps,
) => {
  const { data, isLoading } =
    trpc.v2_5.roulette.getWinnersByRouletteItemId.useQuery(
      {
        rouletteItemId: props.id,
      },
      {
        cacheTime: 0,
      },
    );

  const table = useMantineReactTable({
    data: data ?? [],
    columns,
    state: {
      isLoading: isLoading,
    },
  });

  return <MantineReactTable table={table} />;
};

type UserRouletteReward =
  RouterOutputs["v2_5"]["roulette"]["getWinnersByRouletteItemId"][number];

const columns: MRT_ColumnDef<UserRouletteReward>[] = [
  {
    header: "Name",
    id: "name",
    Cell: (table) => {
      const user = table.row.original.user;
      return `${user.firstName} ${user?.lastName ?? ""}`;
    },
  },
  {
    header: "Email",
    id: "email",
    Cell: (table) => table.row.original.user.email,
  },
  {
    header: "Date",
    id: "date",
    Cell: (table) => {
      const date = table.row.original.createdAt;
      if (!date) return "";
      return dayjs(date).format("MM/DD/YYYY").toString();
    },
  },
];
