import { trpc } from "#/trpc.js";
import { reportUserError, reportUserSuccess } from "#/util/index.js";
import SeasonForm from "./Form.js";
import React from "react";
import { useNavigate } from "react-router";

export default function CreateSeason() {
  const ctx = trpc.useContext();
  const navigate = useNavigate();
  const { mutate, isLoading } = trpc.season.create.useMutation({
    onSuccess(data) {
      ctx.season.invalidate();
      ctx.v2_5.season.invalidate();
      reportUserSuccess({
        title: "Season created successfully",
        message: (
          <span>
            Category <span className="font-semibold">{data.name}</span> created
            successfully
          </span>
        ),
      });
      navigate("/inventory/seasons");
    },
    onError(error) {
      reportUserError({
        title: "Failed to create season",
        message: error.message,
      });
    },
  });

  const handleSubmit = React.useCallback(
    (s) => {
      mutate({
        name: s.season,
        description: s.description,
        defaultBannerImage:
          s?.banner?.length > 0
            ? s.banner.find((banner: any) => banner.default)?.url ||
              s.banner[0]?.url
            : null,
        images: s.banner.map((b: any) => b.url),
        initialDate: s.initialDate,
        finalDate: s.endDate,
        metaDescription: s?.metaDescription ?? null,
        active: s.current ?? true,
      });
    },
    [mutate],
  );
  return (
    <SeasonForm
      onSubmit={handleSubmit}
      isLoading={isLoading}
      defaultValues={undefined}
    />
  );
}
