import React from "react";
import { Select as CRSelect } from "chakra-react-select";
import { Controller, useFormContext } from "react-hook-form";

/**
 *
 * @param {{
 *    defaultValue?: any,
 *    options?: any[],
 *    isLoading?: boolean,
 *    name?: string,
 *    required?: boolean,
 *    onInputChange?: any,
 *    placeholder?: string,
 *    inputId?: string,
 *    mapValue?: (v: ?) => ?,
 * } & import("chakra-react-select").Props
 * & import("@chakra-ui/react").BoxProps} props
 * @returns
 */
export default function Select({
  defaultValue,
  options,
  isLoading,
  name,
  required,
  onInputChange,
  placeholder,
  inputId,
  mapValue,
  unmapValue,
  ...props
}) {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      rules={{ required }}
      render={({ field: { onChange, onBlur, value, ref } }) => {
        return (
          <CRSelect
            onChange={(v) => {
              const mapped = mapValue ? mapValue(v) : v;
              onChange(mapped);
            }}
            onBlur={onBlur}
            value={
              unmapValue
                ? unmapValue(value) || null
                : mapValue
                ? options.find((o) => mapValue(o) === value) || null
                : value
            }
            ref={ref}
            size="sm"
            defaultValue={value}
            options={options}
            isLoading={isLoading}
            onInputChange={onInputChange}
            placeholder={placeholder || ""}
            inputId={inputId}
            {...props}
            chakraStyles={{
              control: (old, { isFocused }) => ({
                ...old,
                bg: isFocused ? "brand.50" : "white",
                _focus: {
                  boxShadow: "none",
                  border: "solid 2px",
                  borderColor: "brand.200",
                },
                _hover: {
                  borderColor: "brand.100",
                },
              }),
              menu: (old) => ({
                ...old,
                w: "auto",
                zIndex: "dropdown",
              }),
            }}
          />
        );
      }}
    />
  );
}
