import { LOGO_URL } from "#/constants/envt.js";
import * as M from "@mantine/core";
import { Link } from "react-router-dom";
import { css } from "#/css/css";

type CardProps = {
  title: string;
  image?: string;
  link: string;
  bg?: string;
};

export default function Card({ title, image, link, bg }: CardProps) {
  return (
    <M.Box className={css({ borderRadius: 12, overflow: "hidden" })}>
      <M.Stack w={200}>
        <Link to={link}>
          <M.Box py={12} className={bg ?? "bg-transparent"}>
            <img src={image ?? LOGO_URL} alt="" />
          </M.Box>
          <M.Box
            py={20}
            px={4}
            fz={16}
            fw={500}
            className="bg-slate-200/70 text-center"
          >
            {title}
          </M.Box>
        </Link>
      </M.Stack>
    </M.Box>
  );
}
