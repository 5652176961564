import {
  BankTransferCustomIcon,
  CashPaymentMethodCustomIcon,
  PaypalCustomIcon,
  ZelleCustomIcon,
} from "#/components-ng/index.js";
import {
  AmericanExpressIcon,
  DinersClubIcon,
  DiscoverIcon,
  MastercardIcon,
  VisaIcon,
} from "#/components/icons/index.jsx";
import { MdOutlineMonetizationOn } from "react-icons/md/index.js";

export const paymentMethodsMap = {
  CASH: {
    text: "Cash",
    icon: CashPaymentMethodCustomIcon,
  },
  VISA: {
    text: "Visa",
    icon: VisaIcon,
  },
  MASTERCARD: {
    text: "Mastercard",
    icon: MastercardIcon,
  },
  AMERICAN_EXPRESS: {
    text: "American Express",
    icon: AmericanExpressIcon,
  },
  DISCOVER: {
    text: "Discover",
    icon: DiscoverIcon,
  },
  DINERS: {
    text: "Diners club",
    icon: DinersClubIcon,
  },
  OTHER: {
    text: "Other",
    icon: MdOutlineMonetizationOn,
  },
  ZELLE: {
    text: "Zelle",
    icon: ZelleCustomIcon,
  },
  PAYPAL: {
    text: "PayPal",
    icon: PaypalCustomIcon,
  },
  BANK_TRANSFER: {
    text: "Bank transfer",
    icon: BankTransferCustomIcon,
  },
};
