import { trpc } from "#/trpc";
import { reportUserSuccess } from "#/util";
import { useNavigate } from "react-router";
import { FormValues } from "./Form/types";
import { QuizForm } from "./Form";

export const CreateQuizForm = () => {
  const navigate = useNavigate();
  const ctx = trpc.useContext();

  const { mutate, isLoading } = trpc.v2_5.quiz.create.useMutation({
    onSuccess(data) {
      ctx.v2_5.quiz.invalidate();
      reportUserSuccess({
        title: "Quiz created",
      });
      navigate(`/ecommerce-crm/quiz/edit/${data.id}`);
    },
  });

  const handleSubmit = (post: FormValues) => {
    mutate({
      title: post.title,
      description: post.description,
      finishedAt: post.finishedAt,
      needAuth: post.needAuth,
      publishedAt: post.publishedAt,
      defaultImage: post?.defaultImage,
      titleEsp: post?.titleEsp,
      descriptionEsp: post?.descriptionEsp,
    });
  };

  return (
    <QuizForm isLoading={isLoading} onSubmit={handleSubmit} title="Create" />
  );
};
