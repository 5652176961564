import { RouterOutputs, trpc } from "#/trpc.js";
import { reportUserError, reportUserSuccess } from "#/util/index.js";
import { FormValues, ProductForm } from "./Form/index.js";
import { useNavigate } from "react-router";

type CreateItemFormProps = {
  onSuccess?: (data: RouterOutputs["item"]["private"]["create"]) => void;
  redirectOnSuccess?: boolean;
  showTitle?: boolean;
};
export default function CreateItemForm(props: CreateItemFormProps) {
  const navigate = useNavigate();

  const { mutate } = trpc.item.private.create.useMutation({
    onError(err) {
      reportUserError({
        title: "Failed to create product",
        message: err.message,
      });
    },
    onSuccess(data) {
      props.onSuccess?.(data);
      reportUserSuccess({
        title: "Product created successfully",
        message: (
          <>
            Product <span className="font-medium">{data.title}</span> has been
            created
          </>
        ),
      });

      if (props.redirectOnSuccess ?? true) {
        navigate(`/inventory/item-list/edit/${data.id}`);
      }
    },
  });

  const handleSubmit = (values: FormValues) => {
    mutate({
      title: values.title,
      description: values.description,
      category: values.category!.id,
      department: values.department!.id,
      season: values.season?.id ? values.season?.id : undefined,
      active: values.active,
      material: values?.material ?? null,
    });
  };
  return (
    <ProductForm onSubmit={handleSubmit} showTitle={props.showTitle ?? true} />
  );
}
