import GTLoader from "#/components-ng/loader";
import * as M from "@mantine/core";
import CircleCheck from "~icons/ion/checkmark-circle-outline";
import CircleClose from "~icons/ion/close-circle-outline";

export default function Preview({ customer, isLoading }) {
  if (isLoading) {
    return (
      <M.LoadingOverlay
        visible={isLoading}
        loader={<GTLoader width="100px" height="100px" />}
      />
    );
  }
  return (
    <div className="px-14">
      <M.Title className="mb-10 text-2xl font-semibold">
        Personal information
      </M.Title>
      <div className="grid w-2/3 grid-cols-2 gap-y-8">
        <p className="text-gray-500">Name</p>
        <p>{customer.firstName}</p>
        <p className="text-gray-500">Last name</p>
        <p>{customer.lastName}</p>
        <p className="text-gray-500">Email</p>
        <p>{customer.email}</p>
        <p className="text-gray-500">Area Code</p>
        <p>
          {customer.areaCode}({customer.countryCode})
        </p>
        <p className="text-gray-500">Phone number</p>
        <p>{customer.phoneNumber}</p>
        <p className="text-gray-500">Date of birthday</p>
        <p>{customer.birthday}</p>
      </div>
      <M.Title className="my-8 text-2xl font-semibold">
        Secondary information
      </M.Title>
      <div className="grid w-2/3 grid-cols-2 gap-y-8">
        <p className="text-gray-500">Country</p>
        <p>{customer.country}</p>
        <p className="text-gray-500">State</p>
        <p>{customer.state}</p>
        <p className="text-gray-500">City</p>
        <p>{customer.city}</p>
        <p className="text-gray-500">Zip Code</p>
        <p>{customer.zip}</p>
        <p className="text-gray-500">Address</p>
        <p>{customer.secondaryAddress}</p>
        <p className="text-gray-500">APT</p>
        <p>{customer.apt}</p>
        <p className="text-gray-500">Po Box</p>
        <p>{customer.poBox}</p>
        <p className="text-gray-500">PIN</p>
        <p>2132</p>
        <p className="text-gray-500">User Name</p>
        <p>{customer.nickname}</p>
        <p className="text-gray-500">Bio</p>
        <p>{customer.bio} </p>
        <p className="text-gray-500">Company name</p>
        <p>{customer.company}</p>
        <p className="text-gray-500">Tax document</p>
        <p>{customer.taxDocument}</p>
        <p className="text-gray-500">Verified</p>
        <p>{customer.verified ? <CircleCheck /> : <CircleClose />}</p>
        <p className="text-gray-500">Taxable</p>
        <p>{customer.taxable ? <CircleCheck /> : <CircleClose />}</p>
        <p className="text-gray-500">Ein ***</p>
        <p>{customer.ein}</p>
        <p className="text-gray-500">Note</p>
        <p>{customer.note}</p>
      </div>
    </div>
  );
}
