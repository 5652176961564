import { CatalogFormProps, CatalogInfo } from "./catalog-info.js";
import { Container } from "@radix-ui/themes";
import { styled } from "#/css/jsx";
import { CatalogImagesTable } from "./catalog-images-table.js";
import { CatalogItemsTable } from "./catalog-items-table.js";

export const CatalogForm = (props: CatalogFormProps) => {
  return (
    <Container size="4">
      <styled.h1 mb={6}>{props.title}</styled.h1>
      <CatalogInfo {...props} />
      {props.catalogId && props.defaultValues?.type === "BY_CATALOG_IMAGE" && (
        <CatalogImagesTable catalogId={props.catalogId} />
      )}
      {props.catalogId && props.defaultValues?.type === "BY_ITEM" && (
        <CatalogItemsTable
          catalogId={props.catalogId}
          itemSkus={props.itemSkus ?? []}
        />
      )}
    </Container>
  );
};

export * from "./types.js";
