import GTLoader from "#/components-ng/loader.js";
import { trpc } from "#/trpc.js";
import { CardLayout } from "../components/index.js";
import { Badge } from "@chakra-ui/react";
import { Group } from "@mantine/core";
import { useMemo } from "react";

interface TagsCardProps {
  itemId: number;
}

export const TagsCard = ({ itemId }: TagsCardProps) => {
  const { data, isLoading } = trpc.itemTag.getByItemId.useQuery(
    {
      itemId: itemId!,
    },
    {
      cacheTime: 0,
      enabled: !!itemId,
    }
  );

  const tags = useMemo(() => {
    if (!data) return [];
    return data.map((itemTag) => itemTag.tag.name);
  }, [data]);

  return (
    <CardLayout title="Tags">
      {isLoading ? (
        <GTLoader width={100} height={100} />
      ) : (
        <Group maw="100%">
          {tags.map((tag) => (
            <Badge
              key={tag}
              variant="solid"
              fontSize="12px"
              fontWeight={400}
              textColor="#525252"
              borderRadius="xl"
              p="1.5"
              bg="#F0F0F0"
            >
              {tag}
            </Badge>
          ))}
        </Group>
      )}
    </CardLayout>
  );
};
