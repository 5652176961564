import { makeController } from "#/components-ng/index.js";
import { z } from "zod";

export const generateReportProductSegmentationSchema = z.object({
  dateRange: z.array(z.date()),
  filialId: z.string().default("1"),
  departmentId: z.string().optional().nullish(),
  categoryId: z.string().optional().nullish(),
  seasonId: z.string().optional().nullish(),
});

export type FormValues = z.infer<
  typeof generateReportProductSegmentationSchema
>;
export const C = makeController<FormValues>();
