import GTLoader from "#/components-ng/loader.js";
import DisplayTableContainer from "#/components/DisplayTableContainer/index.jsx";
import { DisplayTable } from "#/components/index.js";
import { trpc } from "#/trpc.js";
import * as M from "@mantine/core";
import React from "react";

interface PreviewProps {
  id: number;
  opened: boolean;
  onClose: () => void;
}

export const Preview = ({ id, opened, onClose }: PreviewProps) => {
  const { data, isLoading, isFetchedAfterMount } =
    trpc.inventoryTransfer.getById.useQuery({ id: id }, { cacheTime: 0 });

  const inventoriesTransfer = React.useMemo(() => {
    if (!data) return [];
    return data.inventoryTransferItemSkus;
  }, [data]);

  const fromDiffTotalStock = React.useMemo(() => {
    if (!data) return 0;
    return data.inventoryTransferItemSkus.reduce(
      (acc, cur) => acc + cur.previousQuantityFrom,
      0
    );
  }, [data]);

  const toDiffTotalStock = React.useMemo(() => {
    if (!data) return 0;
    return data.inventoryTransferItemSkus.reduce(
      (acc, cur) => acc + cur.previousQuantityTo,
      0
    );
  }, [data]);

  if (isLoading || !isFetchedAfterMount) {
    return (
      <M.LoadingOverlay
        visible={isLoading}
        loader={<GTLoader width={100} height={100} />}
      />
    );
  }

  return (
    <M.Modal opened={opened} onClose={onClose} size="auto" zIndex={1200}>
      <M.Container fluid>
        <M.Group noWrap grow align="end">
          <M.Stack p={10} className="gap-y-3">
            <M.Text className="text-sm font-semibold">Reason:</M.Text>
            <M.Text className="text-sm">{data?.reason ?? ""}</M.Text>
          </M.Stack>
          <M.Stack p={10} className="gap-y-3">
            <M.Text className="text-sm font-semibold">From:</M.Text>
            <M.Text className="text-sm text-[#196799]">
              {data?.filialFrom?.name ?? ""}
            </M.Text>
          </M.Stack>
          <M.Stack p={10} className="gap-y-3">
            <M.Text className="text-sm font-semibold">To:</M.Text>
            <M.Text className="text-sm text-[#3F936F]">
              {data?.filialTo?.name ?? ""}
            </M.Text>
          </M.Stack>
          <M.Stack p={10} className="gap-y-3">
            <M.Text className="text-sm font-semibold">Status:</M.Text>
            <M.Text className="text-sm">{data?.status ?? ""}</M.Text>
          </M.Stack>
        </M.Group>
        <M.Divider my={16} />
        <M.Box>
          <DisplayTableContainer>
            <DisplayTable columns={columns} data={inventoriesTransfer} />
          </DisplayTableContainer>
        </M.Box>
        <M.Divider my={16} />
        <M.Group position="right">
          <M.Group position="apart" className="mt-5">
            <M.SimpleGrid cols={2} className="gap-10">
              <M.Box className="inline-flex items-center justify-center gap-8 rounded-md bg-[#EDF8FF] p-5 text-sm text-[#4DBAFF]">
                <M.Text inherit>
                  {data?.filialFrom?.brevis ?? "From diff"} total stock
                </M.Text>
                <M.Text
                  inherit
                  className="rounded-md bg-white p-0 pl-10 pr-1 text-center"
                >
                  -{fromDiffTotalStock}
                </M.Text>
              </M.Box>
              <M.Box className="inline-flex items-center justify-center gap-8 rounded-md bg-[#F3FFFA] p-5 text-sm text-[#60C69B]">
                <M.Text inherit>
                  {data?.filialTo?.brevis ?? "To diff"} total stock
                </M.Text>
                <M.Text
                  inherit
                  className="rounded-md bg-white p-0 pl-10 pr-1 text-center"
                >
                  {toDiffTotalStock}
                </M.Text>
              </M.Box>
            </M.SimpleGrid>
          </M.Group>
        </M.Group>
      </M.Container>
    </M.Modal>
  );
};

const columns = [
  {
    Header: "SKU",
    accessor: "itemSku.sku",
  },
  {
    Header: "Product name",
    accessor: "itemSku.title",
  },
  {
    Header: "Move quantity",
    accessor: "quantity",
    isNumeric: true,
  },
  {
    Header: "From diff stock",
    accessor: "previousQuantityFrom",
    isNumeric: true,
    Cell: ({ value }) => <M.Text className="text-[#196799]">{value}</M.Text>,
  },
  {
    Header: "To diff stock",
    accessor: "previousQuantityTo",
    isNumeric: true,
    Cell: ({ value }) => <M.Text className="text-[#3F936F]">{value}</M.Text>,
  },
];
