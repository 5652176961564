import { DefaultValues, FormProvider, SubmitHandler } from "react-hook-form";
import {
  CCreateValue,
  createValueSchema,
  FormValueCreate,
  quizValueTypes,
} from "./types";
import * as M from "@mantine/core";
import { SpinnerIcon } from "#/components-ng";
import { trpc } from "#/trpc";
import { reportUserError } from "#/util";
import { zodResolver } from "@hookform/resolvers/zod";
import { css } from "#/css/css";

interface ValueFormDrawerProps {
  open: boolean;
  close: () => void;
  defaultValues?: DefaultValues<FormValueCreate>;
  isLoading: boolean;
  onSubmit: SubmitHandler<FormValueCreate>;
  title: string;
}

export const ValueFormDrawer = ({
  open,
  close,
  defaultValues,
  isLoading,
  onSubmit,
  title,
}: ValueFormDrawerProps) => {
  const form = CCreateValue.useForm({
    resolver: zodResolver(createValueSchema),
    shouldUnregister: false,
    defaultValues: {
      title: "",
      description: "",
      blogId: null,
      catalogId: null,
      promotionId: null,
      departmentId: null,
      categoryId: null,
      seasonId: null,
      type: "BLOG",
      descriptionEsp: null,
      titleEsp: null,
      ...defaultValues,
    },
  });

  return (
    <M.Drawer
      position="right"
      opened={open}
      onClose={close}
      title={<M.Title order={3}>{title}</M.Title>}
      zIndex={50}
    >
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit((values) => {
            form.reset();
            onSubmit(values);
          })}
        >
          <M.Stack spacing={34}>
            <M.Tabs defaultValue="en">
              <M.Tabs.List>
                <M.Tabs.Tab value="en">English</M.Tabs.Tab>
                <M.Tabs.Tab value="es">Spanish</M.Tabs.Tab>
              </M.Tabs.List>
              <M.Tabs.Panel value="en">
                <M.Stack spacing={34} mt="lg">
                  <CCreateValue.InputField
                    name="title"
                    label="Value"
                    placeholder="Enter name"
                  />
                  <CCreateValue.TextareaField
                    name="description"
                    label="Description"
                    placeholder="Enter description"
                  />
                </M.Stack>
              </M.Tabs.Panel>
              <M.Tabs.Panel value="es">
                <M.Stack spacing={34} mt="lg">
                  <CCreateValue.InputField
                    name="titleEsp"
                    label="Nombre"
                    placeholder="Ingrese nombre"
                  />
                  <CCreateValue.TextareaField
                    name="descriptionEsp"
                    label="Descripción"
                    placeholder="Ingrese descripción"
                  />
                </M.Stack>
              </M.Tabs.Panel>
            </M.Tabs>
            <CCreateValue.SelectField
              label="Redirect to"
              name="type"
              data={quizValueTypes.map((value) => ({
                id: value,
                label: value,
              }))}
            />
            <SearchableFields />
            <M.Divider />
            <M.Button type="submit" loading={isLoading}>
              Save value
            </M.Button>
          </M.Stack>
        </form>
      </FormProvider>
    </M.Drawer>
  );
};

function SearchableFields() {
  const form = CCreateValue.useFormContext();
  const type = form.watch("type");

  return (
    <>
      {type === "BLOG" && <BlogSearchField type={type} />}
      {type === "CATALOG" && <CatalogSearchField type={type} />}
      {type === "PROMOTION" && <PromotionSearchField type={type} />}
      {type === "DEPARTMENT" && <DepartmentsSearchField type={type} />}
      {type === "CATEGORY" && <CategoriesSearchField type={type} />}
      {type === "SEASON" && <SeasonSearchField type={type} />}
    </>
  );
}

type SearchableFieldsProps = {
  type: "BLOG" | "CATALOG" | "PROMOTION" | "DEPARTMENT" | "CATEGORY" | "SEASON";
};

const DepartmentsSearchField = (props: SearchableFieldsProps) => {
  const { data, isLoading } = trpc.v2_5.departments.getAll.useQuery(undefined, {
    onError(error) {
      reportUserError({
        title: "Failed to get departments",
        message: error.message,
      });
    },
    enabled: props.type === "DEPARTMENT",
  });

  const selectData = useMemo(
    () =>
      data?.map((e) => ({
        label: e.name,
        value: e.id.toString(),
      })) ?? [],
    [data],
  );

  return (
    <CCreateValue.M
      as={M.Select}
      searchable
      name="departmentId"
      label="Select department"
      clearable
      placeholder="Search Department"
      data={selectData}
      rightSection={isLoading && <SpinnerIcon />}
      filter={() => true}
      withinPortal
      classNames={{
        label: css({
          color: "#64748B",
        }),
      }}
    />
  );
};

const CategoriesSearchField = (props: SearchableFieldsProps) => {
  const { data, isLoading } = trpc.category.getAll.useQuery(undefined, {
    onError(error) {
      reportUserError({
        title: "Failed to get categories",
        message: error.message,
      });
    },
    enabled: props.type === "CATEGORY",
  });

  const selectData = useMemo(
    () =>
      data?.map((e) => ({
        label: e.name,
        value: e.id.toString(),
      })) ?? [],
    [data],
  );

  return (
    <CCreateValue.M
      as={M.Select}
      searchable
      name="categoryId"
      label="Select category"
      clearable
      placeholder="Search Category"
      data={selectData}
      rightSection={isLoading && <SpinnerIcon />}
      filter={() => true}
      withinPortal
      classNames={{
        label: css({
          color: "#64748B",
        }),
      }}
    />
  );
};

const SeasonSearchField = (props: SearchableFieldsProps) => {
  const { data, isLoading } = trpc.v2_5.season.getAll.useQuery(undefined, {
    onError(error) {
      reportUserError({
        title: "Failed to get seasons",
        message: error.message,
      });
    },
    enabled: props.type === "SEASON",
  });

  const selectData = useMemo(
    () =>
      data?.map((e) => ({
        label: e.name,
        value: e.id.toString(),
      })) ?? [],
    [data],
  );

  return (
    <CCreateValue.M
      as={M.Select}
      searchable
      name="seasonId"
      label="Select season"
      clearable
      placeholder="Search Season"
      data={selectData}
      rightSection={isLoading && <SpinnerIcon />}
      filter={() => true}
      withinPortal
      classNames={{
        label: css({
          color: "#64748B",
        }),
      }}
    />
  );
};

const PromotionSearchField = (props: SearchableFieldsProps) => {
  const { data, isLoading } = trpc.v2_5.promotion.getAll.useQuery(undefined, {
    onError(error) {
      reportUserError({
        title: "Failed to get promotions",
        message: error.message,
      });
    },
    enabled: props.type === "PROMOTION",
  });

  const selectData = useMemo(
    () =>
      data?.map((e) => ({
        label: e.name,
        value: e.id.toString(),
      })) ?? [],
    [data],
  );

  return (
    <CCreateValue.M
      as={M.Select}
      searchable
      name="promotionId"
      label="Select promotion"
      clearable
      placeholder="Search Promotion"
      data={selectData}
      rightSection={isLoading && <SpinnerIcon />}
      filter={() => true}
      withinPortal
      classNames={{
        label: css({
          color: "#64748B",
        }),
      }}
    />
  );
};

const BlogSearchField = (props: SearchableFieldsProps) => {
  const { data, isLoading } = trpc.v2_5.blog.getAll.useQuery(undefined, {
    onError(error) {
      reportUserError({
        title: "Failed to get blogs",
        message: error.message,
      });
    },
    enabled: props.type === "BLOG",
  });

  const selectData = useMemo(
    () =>
      data?.map((e) => ({
        label: e.title,
        value: e.id.toString(),
      })) ?? [],
    [data],
  );

  return (
    <CCreateValue.M
      as={M.Select}
      searchable
      name="blogId"
      label="Select blog"
      clearable
      placeholder="Search Blog"
      data={selectData}
      rightSection={isLoading && <SpinnerIcon />}
      filter={() => true}
      withinPortal
      classNames={{
        label: css({
          color: "#64748B",
        }),
      }}
    />
  );
};

const CatalogSearchField = (props: SearchableFieldsProps) => {
  const { data, isLoading } = trpc.v2_5.catalog.getAll.useQuery(undefined, {
    onError(error) {
      reportUserError({
        title: "Failed to get catalogs",
        message: error.message,
      });
    },
    enabled: props.type === "CATALOG",
  });

  const selectData = useMemo(
    () =>
      data?.map((e) => ({
        label: e.title,
        value: e.id.toString(),
      })) ?? [],
    [data],
  );

  return (
    <CCreateValue.M
      as={M.Select}
      searchable
      name="catalogId"
      label="Select catalog"
      clearable
      placeholder="Search Catalog"
      data={selectData}
      rightSection={isLoading && <SpinnerIcon />}
      filter={() => true}
      withinPortal
      classNames={{
        label: css({
          color: "#64748B",
        }),
      }}
    />
  );
};
