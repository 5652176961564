import { S3_URL } from "#/constants/envt.js";
import { trpc } from "#/trpc.js";
import * as Icons from "@chakra-ui/icons";
import { DownloadIcon } from "@chakra-ui/icons";
import * as C from "@chakra-ui/react";
import PropTypes from "prop-types";
import React from "react";
import { useDropzone } from "react-dropzone";
import { useFormContext } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";

export function ImageUploadWithPreviewS3({
  defaultSrc,
  onDrop: userOnDrop,
  name,
  folder,
  ...props
}) {
  const trpcClient = trpc.useContext().client;
  const toast = C.useToast();
  const [isLoading, setIsLoading] = React.useState(false);
  const { register, setValue } = useFormContext();
  React.useEffect(() => {
    register(name);
  }, [name, register]);

  const handleOnDrop = React.useCallback(
    async (files, badFiles) => {
      if (badFiles.length > 0) {
        for (const badFile of badFiles) {
          toast({
            title: "File not allowed",
            description: badFile.errors.map((e) => e.message).join(", "),
            status: "error",
          });
        }
      }
      if (files.length === 0) {
        return;
      }
      try {
        setIsLoading(true);
        const uuid = uuidv4();
        let extension = files[0].name.split(".").pop();
        extension = extension ? `.${extension}` : "";
        const newFileName = `${uuid}${extension}`;
        const response = await trpcClient.s3.getPresignedUrl.query({
          key: `${folder}/${newFileName}`,
        });
        const presignedUrl = response.presignedUrl;
        await fetch(presignedUrl, {
          method: "PUT",
          body: files[0],
        });
        setValue(name, `${S3_URL}/${folder}/${newFileName}`);
        setIsLoading(false);
      } catch (e) {
        console.error(e);
        toast({
          title: "Failed to upload image",
          description: e.message,
          status: "error",
          duration: 6000,
          isClosable: true,
        });
      }
      if (userOnDrop) {
        userOnDrop(files, badFiles);
      }
    },
    [setValue, toast, folder, name, userOnDrop]
  );

  return (
    <ImageUploadWithPreview
      defaultSrc={defaultSrc}
      onDrop={handleOnDrop}
      isLoading={isLoading}
      {...props}
    />
  );
}

export function ImageUploadWithPreview({
  defaultSrc,
  onDrop: userOnDrop,
  isLoading,
  ...props
}) {
  const [img, setImg] = React.useState(defaultSrc);

  const onDrop = React.useCallback(
    (files, badFiles) => {
      if (files.length > 0) {
        setImg(URL.createObjectURL(files[0]));
      }
      userOnDrop(files, badFiles);
    },
    [userOnDrop]
  );

  if (img) {
    return (
      <C.Box position="relative" w="100%">
        <C.Image src={img} borderRadius="md" w="100%" />
        <C.IconButton
          aria-label="Close"
          icon={<Icons.CloseIcon />}
          position="absolute"
          top={0}
          right={0}
          transform="translate(50%, -50%)"
          borderRadius="full"
          size="xs"
          bg="red.100"
          color="red.600"
          _hover={{ bg: "red.200" }}
          _active={{ bg: "red.300" }}
          onClick={() => setImg(null)}
        />
        {isLoading && (
          <C.Center
            position="absolute"
            left="0"
            top="0"
            bg="#00000088"
            w="100%"
            h="100%"
          >
            <C.Spinner />
          </C.Center>
        )}
      </C.Box>
    );
  } else {
    // @ts-ignore
    return <FileUpload onDrop={onDrop} {...props} />;
  }
}

ImageUploadWithPreview.defaultProps = {
  onDrop: () => {},
};

ImageUploadWithPreview.propTypes = {
  onDrop: PropTypes.func,
  defaultSrc: PropTypes.string,
};

export default function FileUpload({
  name,
  id,
  accept,
  onDrop,
  maxFiles,
  maxSize = 1024 * 1024 * 2.5,
  ...props
}) {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    maxFiles,
    maxSize,
  });

  return (
    <C.VStack
      bg="white"
      py={6}
      justify="center"
      align="center"
      border={isDragActive ? "dashed 4px" : "solid 1px"}
      borderColor="gray.200"
      borderRadius="md"
      cursor="pointer"
      _hover={{ borderColor: "brand.lightAccent" }}
      boxShadow={isDragActive ? "inner" : ""}
      {...props}
      {...getRootProps()}
    >
      <DownloadIcon
        transform="rotateZ(180deg)"
        fontSize="4xl"
        color="brand.500"
      />
      <C.Text fontSize="0.9rem">
        Drop your image here, or{" "}
        <C.Text as="span" color="brand.500">
          browse
        </C.Text>
      </C.Text>
      <C.Text color="gray.400" fontSize="0.75rem">
        Supports: {accept?.replaceAll(",", ", ") || "anything"}
      </C.Text>
      <C.VisuallyHiddenInput name={name} id={id} {...getInputProps()} />
    </C.VStack>
  );
}

FileUpload.defaultProps = {
  accept: "",
};

FileUpload.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  accept: PropTypes.string,
  onDrop: PropTypes.func,
  maxFiles: PropTypes.number,
};
