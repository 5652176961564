import { ActionsMenuIcon } from "#/components-ng";
import { RouterOutputs, trpc } from "#/trpc";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { Box, styled } from "#/css/jsx";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_SortingState,
  useMantineReactTable,
} from "mantine-react-table";
import { Dropdown } from "@gt/ui";
import { IconButton, Tooltip } from "@radix-ui/themes";
import CheckIcon from "~icons/ic/baseline-check";
import CloseIcon from "~icons/ic/baseline-close";
import { reportUserSuccess } from "#/util";
import { css } from "#/css/css";
import { MdOutlineDownload } from "react-icons/md";

interface RouletteTableProps {
  search?: string | null;
}

export const RouletteTable = (props: RouletteTableProps) => {
  // Pagination
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  // Sorting
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const querySorting = useMemo(() => {
    if (sorting[0]) {
      return {
        desc: sorting[0].desc,
        key: sorting[0].id as any,
      };
    }
    return null;
  }, [sorting]);

  const { data, isLoading } = trpc.v2_5.roulette.getByPage.useQuery(
    {
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize,
      search: props.search,
      sorting: querySorting,
    },
    {
      keepPreviousData: true,
    },
  );

  const { mutate: exportCsv, isLoading: isLoadingExport } =
    trpc.v2_5.roulette.exportRouletteTableToCsv.useMutation({
      onSuccess: () => {
        reportUserSuccess({
          title: "The csv file has been sent to email",
        });
      },
    });

  const handleExportCsv = () => {
    exportCsv({
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize,
      search: props.search,
      sorting: querySorting,
    });
  };

  const table = useMantineReactTable({
    data: data?.entries ?? [],
    columns: columns,
    manualPagination: true,
    enableTopToolbar: false,
    rowCount: data?.totalEntries ?? 0,
    enableFilters: false,
    state: {
      pagination,
      sorting,
      isLoading,
    },
    enableStickyHeader: true,
    manualSorting: true,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    renderBottomToolbarCustomActions: () => (
      <div
        className={css({
          display: "flex",
          alignItems: "center",
          gap: 2,
        })}
      >
        <Tooltip content="Export to CSV">
          <IconButton
            color="gray"
            variant="soft"
            loading={isLoadingExport}
            onClick={handleExportCsv}
          >
            <MdOutlineDownload />
          </IconButton>
        </Tooltip>
      </div>
    ),
  });

  return <MantineReactTable table={table} />;
};

type Roulette =
  RouterOutputs["v2_5"]["roulette"]["getByPage"]["entries"][number];

const columns: MRT_ColumnDef<Roulette>[] = [
  {
    header: "Actions",
    id: "actions",
    enableSorting: false,
    enableColumnFilter: false,
    size: 100,
    Cell: (table) => {
      const original = table.row.original;

      return (
        <Dropdown.Root>
          <Dropdown.Trigger>
            <styled.div
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <IconButton variant="ghost" color="gray" size="1">
                <ActionsMenuIcon />
              </IconButton>
            </styled.div>
          </Dropdown.Trigger>
          <Dropdown.Content>
            <Dropdown.Item asChild>
              <Link to={`edit/${original.id}`}>Edit</Link>
            </Dropdown.Item>
          </Dropdown.Content>
        </Dropdown.Root>
      );
    },
  },
  {
    id: "title",
    header: "Title",
    accessorKey: "title",
    enableColumnFilter: false,
  },
  {
    id: "description",
    header: "Description",
    accessorKey: "description",
    enableColumnFilter: false,
  },
  {
    id: "active",
    header: "Active",
    accessorKey: "active",
    size: 100,
    Cell(props) {
      const value = props.row.original.active;
      return (
        <Box color={value ? "green" : "red"}>
          {value ? <CheckIcon /> : <CloseIcon />}
        </Box>
      );
    },
  },
  {
    id: "startDate",
    header: "Start date",
    accessorKey: "startDate",
    enableColumnFilter: false,
    Cell: (table) => {
      const startDate = table.row.original.startDate;
      if (!startDate) return "";
      return dayjs(startDate).format("MM/DD/YYYY").toString();
    },
  },
  {
    id: "endDate",
    header: "End date",
    accessorKey: "endDate",
    enableColumnFilter: false,
    Cell: (table) => {
      const endDate = table.row.original.endDate;
      if (!endDate) return "";
      return dayjs(endDate).format("MM/DD/YYYY").toString();
    },
  },
];
