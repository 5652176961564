import * as M from "@mantine/core";
import { Invoice } from "./Invoice";
import { trpc } from "#/trpc";
import { reportUserError, reportUserSuccess } from "#/util";

interface SendOrderEmailFormDrawerProps {
  opened: boolean;
  onClose: () => void;
  orderId: number;
  receiptNumber?: number | null;
}

export const SendOrderEmailFormDrawer = (
  props: SendOrderEmailFormDrawerProps,
) => {
  const { mutate: sendEmail } = trpc.v2_5.order.sendEmail.useMutation({
    onSuccess() {
      reportUserSuccess({
        title: "Email sent",
        message: "Email sent successfully, please wait few seconds",
      });
    },
    onError(error) {
      reportUserError({
        title: "Failed to send email",
        message: error.message,
      });
    },
  });

  const handleSubmitEmail = () => {
    sendEmail({ orderId: props.orderId });
    props.onClose();
  };

  return (
    <M.Drawer
      title="Send as e-mail"
      opened={props.opened}
      position="right"
      onClose={props.onClose}
      size="auto"
      styles={{
        title: {
          fontSize: "20px",
          fontWeight: 500,
          paddingLeft: "1rem",
          paddingTop: "1rem",
          color: "#424242",
        },
      }}
    >
      <M.Box m="1.5rem" p="1rem" mb="xl" bg={"#F4F4F4"}>
        <M.Text mb="xl" fz={"1rem"} fw={500} c="#424242">
          Receipt preview
        </M.Text>
        <M.Box px="lg" sx={{ scale: "0.95" }}>
          <Invoice id={props.orderId} isDrawer={true} />
        </M.Box>
      </M.Box>
      <M.Text ml="1rem" mb="sm">
        Subject
      </M.Text>
      <M.Text
        style={{
          border: "2px",
          borderColor: "#E0E0E0",
          borderStyle: "solid",
          borderRadius: "12px",
        }}
        ml="1rem"
        p="sm"
        mb="xl"
      >
        Gold Tree: Your invoice attached
      </M.Text>
      <M.Text ml="1rem" mb="sm">
        Message
      </M.Text>
      <M.Text
        style={{
          border: "2px",
          borderColor: "#E0E0E0",
          borderStyle: "solid",
          borderRadius: "12px",
        }}
        ml="1rem"
        p="sm"
        mb="xl"
      >
        We have sent your invoice for order {props.receiptNumber} to your email.
        Thank you for choosing us!
      </M.Text>

      <M.Box
        style={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "20px",
        }}
      >
        <M.Button onClick={() => handleSubmitEmail()}>Send as e-mail</M.Button>
        <M.Button
          styles={{
            label: { color: "#000" },
            root: { backgroundColor: "#F4F4F4" },
          }}
          sx={{ "&:hover": { backgroundColor: "#e0e0e0" } }}
          onClick={props.onClose}
        >
          Close
        </M.Button>
      </M.Box>
    </M.Drawer>
  );
};
