import { useAuth } from "#/context/AuthContext.js";
import { trpc, RouterOutputs } from "#/trpc.js";
import { reportUserError } from "#/util/ux/index.js";
import { Parser } from "@json2csv/plainjs";
import * as M from "@mantine/core";
import FileSaver from "file-saver";
import _ from "lodash";
import React from "react";

export type DataOuputDepartment = RouterOutputs["department"]["getById"];
export type DataOuputSeason = RouterOutputs["season"]["getById"];
export type DataOuputCategory = RouterOutputs["category"]["get"];

interface ItemAssociatedModalProps {
  id: number;
  opened: boolean;
  onClose: () => void;
  type: "departments" | "categories" | "seasons";
}

export const ItemAssociatedModal = ({
  id,
  opened,
  type,
  onClose,
}: ItemAssociatedModalProps) => {
  const [typeFileDownload, setTypeFileDownload] = React.useState<
    string | "pdf" | "csv"
  >("pdf");
  const [auth] = useAuth();

  const { data: dataDepartment } = trpc.department.getById.useQuery(
    {
      id,
    },
    {
      enabled: type === "departments" && opened,
      suspense: type !== "departments" || !opened,
      onError(error) {
        reportUserError({
          title: "Failed to get department to download",
          message: error.message,
        });
      },
    },
  );

  const { data: dataCategory } = trpc.category.get.useQuery(
    {
      id,
    },
    {
      enabled: type === "categories" && opened,
      suspense: type !== "categories" || !opened,
      onError(error) {
        reportUserError({
          title: "Failed to get category to download",
          message: error.message,
        });
      },
    },
  );

  const { data: dataSeason } = trpc.season.getById.useQuery(
    {
      id,
    },
    {
      enabled: type === "seasons" && opened,
      suspense: type !== "seasons" || !opened,
      onError(error) {
        reportUserError({
          title: "Failed to get season to download",
          message: error.message,
        });
      },
    },
  );

  return (
    <M.Modal
      size={400}
      opened={opened}
      onClose={onClose}
      title={<M.Title order={3}>Download Items associated</M.Title>}
      zIndex={50}
    >
      <M.Stack spacing={10}>
        <M.Group position="center">
          <M.Text>Download as</M.Text>
          <M.Select
            placeholder="Select type"
            data={[
              { label: "PDF", value: "pdf" },
              { label: "CSV", value: "csv" },
            ]}
            value={typeFileDownload}
            onChange={(e) => setTypeFileDownload(e!)}
            withinPortal
          />
        </M.Group>
        <M.Group position="center">
          <M.Button
            variant="outline"
            onClick={() => {
              onClose();
              setTypeFileDownload("pdf");
            }}
          >
            Cancel
          </M.Button>
          <M.Button
            onClick={() => {
              if (typeFileDownload === "pdf") {
                window.open(`${type}/print/${id}`, "_blank");
              } else if (typeFileDownload === "csv") {
                const data =
                  type === "departments"
                    ? dataDepartment
                    : type === "categories"
                      ? dataCategory
                      : dataSeason;

                generateItemsAssociatedCSV(
                  auth.auth?.user.filialId as number,
                  data as any,
                );
              }
              onClose();
              setTypeFileDownload("pdf");
            }}
          >
            Download
          </M.Button>
        </M.Group>
      </M.Stack>
    </M.Modal>
  );
};

const generateItemsAssociatedCSV = (
  filialId: number,
  data: DataOuputDepartment | DataOuputCategory | DataOuputSeason,
) => {
  if (data) {
    const dataJson = _.flattenDeep(
      data.item.map((item) =>
        item.itemSku.map((itemSku) => ({
          sku: itemSku.sku,
          title: itemSku.title,
          price: `$${itemSku.price.toNumber()}`,
          stock:
            itemSku.itemSkuStock?.find((stock) => stock.filialId === filialId)
              ?.quantity ?? 0,
        })),
      ),
    );

    const fields = ["sku", "title", "price", "stock"];

    const json2csvParser = new Parser({ fields });
    const csv = json2csvParser.parse(dataJson);

    FileSaver.saveAs(
      new Blob([csv], { type: "text/plain;charset=utf-8" }),
      `export_items_associated.csv`,
    );
  } else {
    reportUserError({
      title: "Failed to generate CSV",
    });
  }
};
