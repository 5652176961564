import { DisplayTable } from "#/components/index.js";
import * as M from "@mantine/core";

export const InventoryAdjustmentModal = ({ opened, onClose, adjustment }) => {
  return (
    <M.Modal
      title={adjustment.tagName}
      opened={opened}
      onClose={onClose}
      withCloseButton={false}
      size={1350}
      zIndex={1400}
    >
      <M.Stack>
        <DisplayTable
          columns={adjustmentColumns}
          data={adjustment.adjustmentItemSku}
        />
      </M.Stack>
    </M.Modal>
  );
};

const adjustmentColumns = [
  {
    Header: "SKU",
    accessor: "itemSku.sku",
  },
  {
    Header: "Product",
    accessor: "itemSku.title",
  },
  {
    Header: "Original stock",
    accessor: "oldStock",
    isNumeric: true,
  },
  {
    Header: "New stock",
    accessor: "newStock",
    isNumeric: true,
  },
  {
    Header: "Diff. stock",
    isNumeric: true,
    Cell: ({ row: { original } }) => {
      const value = original.newStock - original.oldStock;
      return <M.Text color={value < 0 ? "red.5" : "green.5"}>{value}</M.Text>;
    },
  },
];
