import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar, getElementsAtEvent } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface BarChartProps {
  textLabel: string;
  pathname: string;
  enableRedirect: boolean;
  data?:
    | {
        id: number;
        label: string;
        total: number;
      }[]
    | null;
}

export const BarChart = ({
  data,
  textLabel,
  pathname,
  enableRedirect,
}: BarChartProps) => {
  const chartRef = useRef(null);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  };

  const chartData = {
    labels: data?.map((p) => p.label),
    datasets: [
      {
        label: textLabel,
        data: data?.map((p) => p.total),
        backgroundColor: data?.map(() => generateRandomHexColor()),
      },
    ],
  };

  const handleOnClick = (e: React.MouseEvent<HTMLCanvasElement>) => {
    if (!enableRedirect) {
      return;
    }

    if (!chartRef.current) {
      return;
    }

    const element = getElementsAtEvent(chartRef.current, e);

    if (element.length > 0) {
      const index = element[0].index;
      const item = data ? data[index] : null;

      if (item) {
        window.open(`${pathname}${item.id}`, "_blank");
      }
    }
  };

  return (
    <Bar
      data={chartData}
      options={options}
      ref={chartRef}
      onClick={handleOnClick}
    />
  );
};

const generateRandomHexColor = () => {
  return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
};
