import { Select as ChakraSelect } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import React from "react";

const NativeSelect = React.forwardRef(({ name, ...props }, ref) => {
  const formContext = useFormContext();
  const formProps = formContext ? formContext.register(name) : {};

  return <ChakraSelect ref={ref} {...formProps} {...props} />;
});

export default NativeSelect;
