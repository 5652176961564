import { RouterOutputs, trpc } from "#/trpc";
import { Modal } from "@mantine/core";
import {
  MantineReactTable,
  MRT_ColumnDef,
  useMantineReactTable,
} from "mantine-react-table";
import { FilterKey } from "./$common";
import { reportUserSuccess } from "#/util";
import { Button, Dropdown } from "@gt/ui";
import MenuIcon from "~icons/ion/ellipsis-horizontal-outline";
import { openConfirmModal } from "@mantine/modals";
import { Badge } from "@radix-ui/themes";
import { css } from "#/css/css";

type UserWaitingListModalProps = {
  opened: boolean;
  close: () => void;
  itemSkuId: number;
  status: Array<FilterKey>;
};

export const UsersWaitingListModal = ({
  opened,
  close,
  itemSkuId,
  status,
}: UserWaitingListModalProps) => {
  const { data, isLoading } = trpc.v2_5.waitingProducts.getByItemSkuId.useQuery(
    {
      itemSkuId: itemSkuId,
      status: status,
    },
    {
      enabled: opened,
    },
  );

  const table = useMantineReactTable({
    data: data ?? [],
    columns,
    enableColumnFilters: false,
    enableTopToolbar: false,
    enableFilters: false,
    state: {
      isLoading: isLoading,
    },
  });

  const sku = data ? data[0].itemSku.sku : "";

  return (
    <Modal
      title={<h3>Detailed view - SKU {sku}</h3>}
      opened={opened}
      onClose={close}
      zIndex={50}
      size="auto"
    >
      <MantineReactTable table={table} />
    </Modal>
  );
};

type UserWaitingList =
  RouterOutputs["v2_5"]["waitingProducts"]["getByItemSkuId"][number];

const columns: MRT_ColumnDef<UserWaitingList>[] = [
  {
    id: "Actions",
    header: "Actions",
    size: 100,
    Cell: (table) => {
      const ctx = trpc.useContext();

      const { mutate } = trpc.userWaitingList.delete.useMutation({
        onSuccess() {
          ctx.v2_5.waitingProducts.invalidate();
          reportUserSuccess({
            title: "Waiting list deleted successfully",
          });
        },
      });

      const handleOpenConfirmModal = () => {
        openConfirmModal({
          title: "Are you sure you want to delete this waiting list?",
          labels: { confirm: "Delete", cancel: "Cancel" },
          onConfirm: () => mutate({ id: table.row.original.id }),
          confirmProps: { color: "red" },
        });
      };

      return (
        <Dropdown.Root>
          <Dropdown.Trigger>
            <Button variant="ghost" size="icon">
              <MenuIcon />
            </Button>
          </Dropdown.Trigger>
          <Dropdown.Content>
            <Dropdown.Item onClick={handleOpenConfirmModal}>
              Delete
            </Dropdown.Item>
          </Dropdown.Content>
        </Dropdown.Root>
      );
    },
  },
  {
    header: "User",
    Cell: (table) => {
      return `${table.row.original.user.firstName} ${table.row.original.user?.lastName ?? ""}`;
    },
  },
  {
    header: "Phone number",
    Cell: (table) => {
      return `+${table.row.original.user.areaCode} ${table.row.original.user.phoneNumber}`;
    },
  },
  {
    header: "Email",
    accessorKey: "user.email",
  },
  {
    header: "Company",
    accessorKey: "user.company",
  },
  {
    header: "Status",
    enableSorting: false,
    accessorKey: "waitingListStatus",
    Cell: (table) => {
      const status = table.row.original.waitingListStatus;
      const value = `${status[0]}${status.substring(1).toLowerCase()}`;

      return (
        <Badge
          color={status === "WAITING" ? "orange" : "green"}
          className={css({
            padding: "5px 10px!",
            borderRadius: "20px!",
          })}
        >
          {value}
        </Badge>
      );
    },
  },
  {
    header: "QTY",
    accessorKey: "quantity",
  },
];
