import { makeController } from "#/components-ng/index.js";
import { z } from "zod";

export const createSysSectionSchema = z.object({
  name: z.string(),
  sysBlockId: z.string().nullish(),
});

export type FormValues = z.infer<typeof createSysSectionSchema>;
export const C = makeController<FormValues>();
