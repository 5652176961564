import GTLoader from "#/components-ng/loader.js";
import { useAuth } from "#/context/AuthContext.js";
import { trpc } from "#/trpc.js";
import { CardLayout, CardTable } from "../components/index.js";
import { useMemo } from "react";

interface StockCardProps {
  itemSkuId: number;
}

export const StockCard = ({ itemSkuId }: StockCardProps) => {
  const [{ auth }] = useAuth();

  const { data: allStocks, isLoading: isLoadingAll } =
    trpc.itemSkuStock.getByFilialAndItemSku.useQuery(
      {
        itemSkuId: itemSkuId,
        filialId: auth?.user?.filialId,
      },
      {
        cacheTime: 0,
        enabled: !!itemSkuId,
      }
    );

  const summary = useMemo(() => {
    if (!allStocks) return [];
    const total = allStocks?.reduce((acc, curr) => {
      return acc + curr.quantity;
    }, 0);

    const holdStock = allStocks?.reduce((acc, curr) => {
      return acc + curr.holdStock;
    }, 0);

    const available = total - holdStock;
    return [available, holdStock, total];
  }, [allStocks]);

  return (
    <CardLayout title="Stock">
      {isLoadingAll ? (
        <GTLoader width={100} height={100} />
      ) : (
        <CardTable
          headers={["Available Stock", "Hold Stock", "Total Stock"]}
          rows={[summary]}
        />
      )}
    </CardLayout>
  );
};
