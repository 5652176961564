import { cn } from "#/lib/utils.js";
import {
  Combobox as ComboboxPrimitive,
  ComboboxInputProps,
  ComboboxProps,
} from "@headlessui/react";
import React from "react";

export function ComboboxInput<TValue>({
  className,
  ...props
}: ComboboxInputProps<"input", TValue>) {
  return (
    <ComboboxPrimitive.Input
      autoComplete="off"
      className={cn(
        "flex h-10 w-full rounded-md border border-slate-200 bg-white px-3 py-2 text-sm placeholder:text-slate-400",
        "focus:bg-brand-50 focus:bg-opacity-50 focus:outline-none focus:ring-2 focus:ring-brand-400 focus:ring-offset-0",
        "disabled:cursor-not-allowed disabled:opacity-50 dark:border-slate-700 dark:text-slate-50 dark:focus:ring-slate-400 dark:focus:ring-offset-slate-900",
        className
      )}
      {...props}
    />
  );
}

export function ComboboxOptions({
  className,
  ...props
}: React.ComponentPropsWithoutRef<typeof ComboboxPrimitive.Options>) {
  return (
    <ComboboxPrimitive.Options
      className={cn(
        "absolute -bottom-1 z-50 w-full min-w-[8rem] translate-y-full overflow-hidden rounded-md border border-slate-100 bg-white p-1 text-slate-700 shadow-md animate-in fade-in-80 dark:border-slate-800 dark:bg-slate-800 dark:text-slate-400",
        className
      )}
      {...props}
    />
  );
}

export function ComboboxOption({
  className,
  ...props
}: React.ComponentPropsWithoutRef<typeof ComboboxPrimitive.Option>) {
  return (
    <ComboboxPrimitive.Option
      className={cn(
        "flex cursor-pointer items-center space-x-2 p-2 text-sm",
        "ui-active:bg-slate-100 dark:hover:bg-slate-700",
        className
      )}
      {...props}
    />
  );
}

export function Combobox<
  TValue,
  TNullable extends boolean | undefined,
  TMultiple extends boolean | undefined
>({
  children,
  className,
  ...props
}: ComboboxProps<TValue, TNullable, TMultiple, "div">) {
  return (
    <div className={cn("relative", className)}>
      {/* @ts-ignore */}
      <ComboboxPrimitive {...props}>{children}</ComboboxPrimitive>
    </div>
  );
}
