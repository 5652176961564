import { css } from "#/css/css"
import { type RouterOutputs, trpc } from "#/trpc"
import {
	MantineReactTable,
	type MRT_ColumnDef,
	useMantineReactTable,
} from "mantine-react-table"

type DiscountType =
	RouterOutputs["userDiscount"]["getManyByUserId"][number]["discount"]["type"]

interface Discount {
	reference: string
	type: DiscountType
	amount: number
}

interface DiscountsSectionProps {
	customerId: number
}

export const DiscountsSection = (props: DiscountsSectionProps) => {
	const { data } = trpc.userDiscount.getManyByUserId.useQuery({
		userId: props.customerId,
	})

	const discounts = useMemo(
		() =>
			data?.map((d) => ({
				reference: d.discount.reference,
				type: d.discount.type,
				amount: d.discount.amount.toNumber(),
			})) ?? [],
		[data],
	)

	const table = useMantineReactTable({
		columns,
		data: discounts ?? [],
		enableTopToolbar: false,
	})

	return (
		<div
			className={css({
				marginTop: 5,
			})}
		>
			<MantineReactTable table={table} />
		</div>
	)
}

const columns: MRT_ColumnDef<Discount>[] = [
	{
		id: "reference",
		header: "Name",
		accessorKey: "reference",
	},
	{
		id: "amount",
		header: "Amount",
		accessorKey: "amount",
		Cell: (table) => {
			const original = table.row.original

			return (
				<p>
					{original.type === "AMOUNT" && "$"}
					{original.amount.toFixed(2)}
					{original.type === "PERCENTAGE" && "%"}
				</p>
			)
		},
	},
]
