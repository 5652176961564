// @ts-nocheck
import { CalendarFilledIcon, PlusFilledIcon } from '#/components-ng/index.js'
import {
  ImageUpload,
  UseS3UploadObject,
  UseS3UploadResult,
} from '#/components-ng/ui/media-upload.js'
import {
  Field,
  FormControl,
  Input,
  Select,
  Switch,
} from '#/components/Form/v2/index.js'
import { FormTextarea } from '#/components/Form/v3/index.js'
import { API_KEY, API_URL, S3_URL } from '#/constants/envt.js'
import { trpc } from '#/trpc.js'
import { reportUserError, reportUserSuccess } from '#/util/index.js'
import { Discounts } from './Discounts.js'
import { DiscountsNewCustomers } from './DiscountsNewCustomers.js'
import * as C from '@chakra-ui/react'
import * as M from '@mantine/core'
import { Select as CRSelect } from 'chakra-react-select'
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import countryTelData from 'country-telephone-data'
import _, { uniqBy } from 'lodash'
import React, { useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useFormContext, useWatch } from 'react-hook-form'
import { useParams } from 'react-router'
import { v4 as uuidv4 } from 'uuid'

/**
 * @param {{ customerButtons?: ?, discountsButtons?: ?, onAddressRequiredChange: ?, isDiscountsTabDisabled?: boolean, isDiscountsTabDisabledNewCustomers?: boolean, isSecuritySectionDisabled?: boolean }} props
 */
export default function CreateUserForm({
  onAddressRequiredChange,
  customerButtons,
  discountsButtons,
  isDiscountsTabDisabled = true,
  isDiscountsTabDisabledNewCustomers = true,
  isSecuritySectionDisabled = true,
  isCustomer = false,
}) {
  return (
    <C.Box>
      <Tabs
        onAddressRequiredChange={onAddressRequiredChange}
        isDiscountsTabDisabled={isDiscountsTabDisabled}
        customerButtons={customerButtons}
        discountsButtons={discountsButtons}
        isDiscountsTabDisabledNewCustomers={isDiscountsTabDisabledNewCustomers}
        isSecuritySectionDisabled={isSecuritySectionDisabled}
        isCustomer={isCustomer}
      />
    </C.Box>
  )
}

function Tabs({
  onAddressRequiredChange,
  isDiscountsTabDisabled,
  customerButtons,
  isDiscountsTabDisabledNewCustomers,
  isSecuritySectionDisabled,
  isCustomer,
}) {
  return (
    <M.Accordion
      styles={{ item: { border: 'none' } }}
      defaultValue="customer-form"
    >
      <M.Accordion.Item value="customer-form">
        <M.Accordion.Control>
          <M.Title order={2}>Edit user</M.Title>
        </M.Accordion.Control>
        <M.Divider color="gray.2" mb={16} />
        <M.Accordion.Panel>
          <PersonalInformation isCustomer={isCustomer} />
          <M.Divider color="gray.2" my={32} />
          {!isDiscountsTabDisabled && (
            <>
              <Discounts />
              <M.Divider color="gray.2" my={32} />
            </>
          )}
          {!isDiscountsTabDisabledNewCustomers && (
            <>
              <DiscountsNewCustomers />
              <M.Divider color="gray.2" my={32} />
            </>
          )}
          <M.Title order={3} mb={32}>
            Secondary Information
          </M.Title>
          <M.Group noWrap align="start">
            <AddressInformation onRequiredChange={onAddressRequiredChange} />
            <M.Divider color="gray.2" orientation="vertical" mx={32} />
            <SecondaryInformation />
          </M.Group>
          {!isSecuritySectionDisabled && (
            <>
              <M.Divider color="gray.2" my={32} />
              <M.Title order={3} mb={32}>
                Security
              </M.Title>
              <SecuritySection />
            </>
          )}
          <M.Divider color="gray.2" my={32} />

          <M.Group position="right" mt="md">
            {customerButtons}
          </M.Group>
        </M.Accordion.Panel>
      </M.Accordion.Item>
    </M.Accordion>
  )
}

export function PersonalInformation({ isCustomer }: { isCustomer: boolean }) {
  const { data: salesUsers } = trpc.user.getAll.useQuery(
    {
      filialId: null,
    },
    {
      enabled: !!isCustomer,
    },
  )
  const { register, setValue, getValues, control } = useFormContext()
  const areaCode = useWatch({
    control,
    name: 'areaCode',
  })
  const countryCode =
    useWatch({
      control,
      name: 'countryCode',
    }) ?? ''
  const preferredAssociated = useWatch({
    control,
    name: 'preferredSalesAssociatedId',
  })

  // We join both codes so we can use it as a single unique value
  const joinedAreaCode = `${countryCode}/${areaCode}`
  const areaCodes = C.useConst(() =>
    uniqBy(
      countryTelData.allCountries.map((c) => ({
        label: `${getUnicodeFlagIcon(c.iso2)} +${
          c.dialCode
        } (${c.iso2.toUpperCase()})`,
        value: `${c.iso2.toUpperCase()}/${c.dialCode}`,
      })),
      (c) => c.value,
    ),
  )
  const salesUsersOptions = useMemo(
    () =>
      salesUsers?.map((u) => ({
        label: `${u.firstName} ${u.lastName ?? ''}`,
        value: u.id,
      })) ?? [],
    [salesUsers],
  )

  const toast = C.useToast()

  const [photo, setPhoto] = React.useState(() => getValues().photo)
  const [isUploading, setIsUploading] = React.useState(false)
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: '.jpg,.jpeg,.png',
    maxFiles: 1,
    onDrop: async (files) => {
      if (files.length === 0) {
        return
      }
      try {
        setIsUploading(true)
        setPhoto(URL.createObjectURL(files[0]))
        const uuid = crypto.randomUUID()
        let extension = files[0].name.split('.').pop()
        extension = extension ? `.${extension}` : ''
        const newFileName = `${uuid}${extension}`
        const response = await fetch(
          `${API_URL}/s3/presigned-url?key=photos/${newFileName}`,
          {
            headers: {
              'api-key': API_KEY,
            },
          },
        )
        const presignedUrl = (await response.json()).presignedUrl
        await fetch(presignedUrl, {
          method: 'PUT',
          body: files[0],
        })
        setValue('avatar', `${S3_URL}/photos/${newFileName}`)
        setIsUploading(false)
      } catch (e) {
        console.error(e)
        toast({
          title: 'Failed to upload photo',
          description: e.message,
          status: 'error',
          duration: 6000,
          isClosable: true,
        })
      }
    },
  })

  React.useEffect(() => {
    register('avatar')
  }, [register])

  return (
    <M.Box>
      <C.HStack spacing={6} w="100%">
        <C.Box position="relative" {...getRootProps()}>
          <C.Avatar
            name={
              useWatch().firstName
                ? `${getValues().firstName[0] ?? ''} ${
                    getValues().lastName ? getValues().lastName[0] : ''
                  }`
                : null
            }
            src={photo}
            bg="gray.200"
            size="xl"
            {...(photo
              ? {
                  borderWidth: '2px',
                  borderStyle: 'solid',
                  borderColor: 'brand.100',
                }
              : {})}
            {...(isDragActive
              ? {
                  boxShadow: 'inner',
                  borderWidth: '2px',
                  borderStyle: 'dashed',
                  borderColor: 'gray.500',
                }
              : {})}
          />
          <C.Box position="absolute" bottom={0} right={0}>
            <C.VisuallyHiddenInput {...getInputProps()} />
            <C.IconButton
              icon={<PlusFilledIcon />}
              borderRadius="full"
              size="xs"
              color="white"
              boxShadow="base"
            />
          </C.Box>
          {isUploading && (
            <C.Circle
              w="100%"
              h="100%"
              borderRadius="full"
              position="absolute"
              left={0}
              top={0}
              bg="#00000088"
            >
              <C.Spinner color="white" />
            </C.Circle>
          )}
        </C.Box>
        <M.Title order={3}>Personal information</M.Title>
      </C.HStack>
      <M.SimpleGrid cols={3} mt={32}>
        <FormControl name="firstName" label="Name" required>
          <Field as={Input} />
        </FormControl>
        <FormControl name="lastName" label="Last name">
          <Field as={Input} />
        </FormControl>
        <FormControl name="active" label="Active">
          <Field as={Switch} defaultChecked />
        </FormControl>
      </M.SimpleGrid>
      <M.SimpleGrid cols={3} mt={18}>
        <FormControl name="email" label="Email" required>
          <Field as={Input} type="email" />
        </FormControl>
        <M.Group>
          <M.Box sx={{ width: '15ch' }}>
            <FormControl
              name="areaCode"
              label="Area code"
              required
              flex={2}
              render={({ uid, ...rest }) => (
                <CRSelect
                  inputId={uid}
                  options={areaCodes}
                  size="sm"
                  value={areaCodes.find(
                    ({ value }) => value === joinedAreaCode,
                  )}
                  onChange={({ value }) => {
                    const parts = value.split('/')
                    const countryCode = parts[0]
                    const areaCode = parts[1]
                    setValue('countryCode', countryCode)
                    setValue('areaCode', areaCode)
                  }}
                  {...rest}
                />
              )}
            />
          </M.Box>
          <FormControl
            name="phoneNumber"
            label="Phone number"
            required
            flex={'5 !important'}
          >
            <Field
              as={Input}
              type="tel"
              onInput={(e) => {
                const value = e.target.value
                const onlyNumbers = value.replace(/[^0-9]/g, '')
                e.target.value = onlyNumbers
              }}
            />
          </FormControl>
        </M.Group>
        <FormControl
          name="birthDate"
          label="Date of birth"
          valueAsDate
          render={(props) => (
            <C.InputGroup>
              <C.InputLeftElement pointerEvents="none">
                <C.Icon as={CalendarFilledIcon} color="gray.600" />
              </C.InputLeftElement>
              <Field as={Input} type="date" {...props} pl={6} />
            </C.InputGroup>
          )}
        />
      </M.SimpleGrid>
      {isCustomer && (
        <M.SimpleGrid cols={3} mt={18}>
          <FormControl
            name="preferredSalesAssociatedId"
            label="Preferred Sales Associated"
            flex={2}
            zIndex={49}
            render={({ uid, ...rest }) => (
              <Select
                inputId={uid}
                options={salesUsersOptions}
                size="sm"
                value={salesUsersOptions.find(
                  ({ value }) => value === preferredAssociated,
                )}
                onChange={({ value }) => {
                  setValue('preferredSalesAssociatedId', value)
                }}
                {...rest}
              />
            )}
          />
        </M.SimpleGrid>
      )}
    </M.Box>
  )
}

export function AddressInformation({ onRequiredChange }) {
  const { watch, getValues, trigger, setValue } = useFormContext()
  const [isRequired, setIsRequired] = React.useState(() => {
    const v = getValues()
    return (
      v?.address?.country ||
      v?.address?.state ||
      v?.address?.city ||
      v?.address?.address ||
      v?.address?.zipCode
    )
  })
  const countries = C.useConst(() =>
    countryTelData.allCountries.map((c) => ({
      label: `${getUnicodeFlagIcon(c.iso2)} ${c.name}`,
      value: c.iso2,
      iso2: c.iso2,
    })),
  )

  React.useEffect(() => {
    const sub = watch((data, { name }) => {
      if (name.startsWith('address.')) {
        const fieldValue = _.get(data, name)
        // If the user inserted something, make the rest of the fields required.
        if (fieldValue && !isRequired) {
          setIsRequired(true)
        } else {
          const newRequired =
            !!data?.address?.country ||
            !!data?.address?.state ||
            !!data?.address?.city ||
            !!data?.address?.address ||
            !!data?.address?.zipCode
          if (isRequired !== newRequired) {
            setIsRequired(newRequired)
          }
        }
      }
    })
    return () => sub.unsubscribe()
  }, [watch, getValues, trigger, isRequired])

  React.useEffect(() => {
    onRequiredChange(isRequired)
  }, [isRequired, onRequiredChange])

  React.useEffect(() => {
    if (!isRequired) {
      trigger('address')
    }
  }, [isRequired, trigger, setValue])

  return (
    <M.Stack sx={{ flex: '1 !important' }}>
      <M.SimpleGrid cols={2}>
        <FormControl
          name="address.country"
          label="Country"
          required={isRequired}
          render={({ uid, ...rest }) => (
            <Select
              inputId={uid}
              options={countries}
              mapValue={(c) => (c ? c.value : null)}
              isClearable
              {...rest}
            />
          )}
        />
        <FormControl name="address.state" label="State" required={isRequired}>
          <Field as={Input} />
        </FormControl>
        <FormControl name="address.city" label="City" required={isRequired}>
          <Field as={Input} />
        </FormControl>
        <FormControl name="address.zip" label="ZIP code" required={isRequired}>
          <Field as={Input} />
        </FormControl>
        <M.Box sx={{ gridColumn: 'span 2' }}>
          <FormControl
            name="address.address"
            label="Address"
            required={isRequired}
          >
            <Field as={Input} />
          </FormControl>
        </M.Box>
        <FormControl name="address.apt" label="APT">
          <Field as={Input} />
        </FormControl>
        <FormControl name="address.poBox" label="PO. Box">
          <Field as={Input} />
        </FormControl>
      </M.SimpleGrid>
      <M.SimpleGrid cols={1}>
        <FormControl name="nickName" label="Username">
          <Field as={Input} />
        </FormControl>
      </M.SimpleGrid>
      <M.Box sx={{ gridColumn: 'span 2' }}>
        <FormControl name="bio" label="Bio">
          <FormTextarea />
        </FormControl>
      </M.Box>
    </M.Stack>
  )
}

export function SecondaryInformation() {
  const { setValue } = useFormContext()
  const formMethods = useFormContext()
  const [taxDocumentName, setTaxDocumentName] = useState<{
    url: string
    uuid: string
    isLoading: boolean
  } | null>(() =>
    formMethods.getValues().taxDocument
      ? {
          url: formMethods.getValues().taxDocument,
          uuid: uuidv4(),
          isLoading: false,
        }
      : null,
  )

  const handleS3UploadSuccess = (result: UseS3UploadResult) => {
    setTaxDocumentName({
      url: result.url,
      uuid: result.uuid,
      isLoading: false,
    })
    setValue('taxDocument', result.url)
  }

  const handleS3UploadingStart = (upload: UseS3UploadObject) => {
    setTaxDocumentName({
      url: upload.url!,
      uuid: upload.uuid,
      isLoading: true,
    })
  }

  return (
    <M.Stack sx={{ flex: '1 !important' }}>
      <FormControl name="company" label="Company name">
        <Field as={Input} />
      </FormControl>
      <FormControl
        name="taxDocument"
        label="Tax document"
        render={({ uid }) => (
          <C.VStack w="100%">
            <ImageUpload
              onS3UploadSuccess={handleS3UploadSuccess}
              onS3UploadingStart={handleS3UploadingStart}
              directory="tax-documents"
              supports={{
                image: true,
              }}
            />
            <C.HStack>
              {taxDocumentName && taxDocumentName.url.startsWith('https') && (
                <C.Button
                  as="a"
                  href={taxDocumentName.url}
                  target="_blank"
                  variant="link"
                >
                  Download saved document
                </C.Button>
              )}
              {taxDocumentName?.isLoading && <C.Spinner size="xs" />}
            </C.HStack>
          </C.VStack>
        )}
      />
      <M.Group noWrap>
        <M.Box>
          <FormControl name="verified" label="Verified">
            <Field as={Switch} />
          </FormControl>
        </M.Box>
        <M.Box>
          <FormControl name="taxable" label="Taxable">
            <Field as={Switch} defaultChecked value={true} />
          </FormControl>
        </M.Box>
        <M.Box>
          <FormControl name="ein" label="EIN **">
            <Field as={Input} />
          </FormControl>
        </M.Box>
      </M.Group>
      <M.Text size="sm" color="gray.500">
        ** EIN is required for non-taxable customers
      </M.Text>
      <FormControl name="note" label="Note">
        <FormTextarea />
      </FormControl>
    </M.Stack>
  )
}

export function SecuritySection() {
  const { id } = useParams()
  const [passwordData, setPasswordData] = useState({
    oldPassword: '',
    newPassword: '',
  })
  const [pin, setPin] = useState<string | null>(null)

  const { mutate } = trpc.user.private.resetPassword.useMutation({
    onSuccess: () => {
      reportUserSuccess({
        title: 'Password reset',
        message: 'Password reset successfully',
      })
      setPasswordData({
        oldPassword: '',
        newPassword: '',
      })
    },
  })

  const { mutate: updateUserPin } = trpc.v2_5.user.updatePin.useMutation({
    onSuccess: () => {
      reportUserSuccess({
        title: 'PIN updated',
        message: 'PIN updated successfully',
      })
      setPin(null)
    },
  })

  const resetPassword = () => {
    if (!passwordData.oldPassword || !passwordData.newPassword) {
      return reportUserError({
        title: 'Error while resetting password',
        message: 'Please fill in both fields',
      })
    }
    if (id) {
      mutate({
        id: Number(id),
        oldPassword: passwordData.oldPassword,
        newPassword: passwordData.newPassword,
      })
    }
  }

  const updatePin = () => {
    if (!pin) {
      return reportUserError({
        title: 'Error while updating PIN',
        message: 'Please fill in the PIN field',
      })
    }
    if (id) {
      updateUserPin({
        id: Number(id),
        pin: pin,
      })
    }
  }

  return (
    <M.Stack sx={{ flex: 1 }}>
      {!id ? (
        <>
          <FormControl name="password" label="Password" required={!id}>
            <Field as={M.PasswordInput} className="max-w-sm" />
          </FormControl>
          <FormControl name="pin" label="PIN">
            <Field as={Input} className="max-w-sm" />
          </FormControl>
        </>
      ) : (
        <>
          <M.Group position="apart" grow noWrap>
            <M.PasswordInput
              label="Old Password"
              name="oldPassword"
              value={passwordData.oldPassword}
              onChange={(e) =>
                setPasswordData({
                  ...passwordData,
                  oldPassword: e.target.value,
                })
              }
            />
            <M.PasswordInput
              label="New Password"
              name="newPassword"
              value={passwordData.newPassword}
              onChange={(e) =>
                setPasswordData({
                  ...passwordData,
                  newPassword: e.target.value,
                })
              }
            />
          </M.Group>
          <M.Group position="right" noWrap>
            <M.Button onClick={resetPassword}>Change Password</M.Button>
          </M.Group>
          <M.Divider />
          <M.Group position="left" grow noWrap align="end">
            <M.PasswordInput
              label="PIN"
              name="pin"
              value={pin}
              onChange={(e) =>
                setPin(e.target.value === '' ? null : e.target.value)
              }
              minLength={4}
              maxLength={4}
              error={pin && pin.length !== 4}
            />
            <M.Button onClick={updatePin}>Update PIN</M.Button>
          </M.Group>
        </>
      )}
    </M.Stack>
  )
}
