import { trpc } from "#/trpc.js";
import { reportUserError, reportUserSuccess } from "#/util/index.js";
import { TagFormDrawer, TagSchema } from "./form.js";

type TagFormCreateContentProps = {
  opened: boolean;
  onClose: () => void;
};
export const TagFormCreateContent = ({
  opened,
  onClose,
}: TagFormCreateContentProps) => {
  const ctx = trpc.useContext();
  const { mutate, isLoading } = trpc.tag.create.useMutation({
    onError: (err) => {
      onClose();
      reportUserError({
        title: "Error creating tag",
        message: err.message,
      });
    },
    onSuccess: () => {
      onClose();
      ctx.tag.invalidate();
      ctx.v2_5.tags.invalidate();
      reportUserSuccess({
        title: "Tag created",
      });
    },
  });

  const handleSubmit = (values: TagSchema) => {
    mutate({
      name: values.name,
      description: values?.description ?? null,
      defaultImage: values?.defaultImage?.url ?? null,
    });
  };

  return (
    <TagFormDrawer
      opened={opened}
      onClose={onClose}
      onSubmit={handleSubmit}
      loading={isLoading}
    />
  );
};
