import { RouterPrompt } from "#/components/index.js";
import { trpc } from "#/trpc.js";
import {
  makeMantineController,
  MantineController,
} from "#/components/Form/v3/index.js";
import {
  reportUserError,
  reportUserSuccess,
  useProductSearch,
} from "#/util/index.js";
import * as M from "@mantine/core";
import React from "react";
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { useNavigate } from "react-router";
import CloseIcon from "~icons/ic/baseline-close";

interface FormValues {
  customer: {
    id: string;
    firstName: string;
    lastName: string;
  };
  email: string;
  entries: {
    product: {
      id: string;
      title: string;
    };
    qty: number;
  }[];
}

const Mc = makeMantineController({ ctx: {} as FormValues });

export const WaitingListForm = () => {
  const form = useForm<FormValues>({
    defaultValues: {
      entries: [{ qty: 0 }],
    },
  });
  const navigate = useNavigate();
  const productFa = useFieldArray({ control: form.control, name: "entries" });
  const { mutate, isLoading } = trpc.userWaitingList.createMany.useMutation({
    onSuccess() {
      reportUserSuccess({
        title: "Waiting list created successfully",
      });
      navigate("/inventory/waiting-list");
    },
    onError(error) {
      reportUserError({
        title: "Failed to create waiting list",
        message: error.message,
      });
    },
  });

  const [submitted, setSubmitted] = React.useState(false);
  const handleSubmit = (v: FormValues) => {
    setSubmitted(true);
    mutate({
      userId: Number.parseInt(v.customer.id),
      itemSkus: v.entries.map((e: any) => ({
        id: Number.parseInt(e.product.id),
        quantity: e.qty,
      })),
      waitingListStatus: "WAITING",
      email: v.email,
    });
  };

  return (
    <M.Container>
      <RouterPrompt when={!submitted} />
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <FormProvider {...form}>
          <M.Stack>
            <CustomerField />
            <Mc as={M.TextInput} name="email" label="Email" required />
            {productFa.fields.map((field, index) => (
              <M.Stack key={field.id} spacing={6}>
                <M.Group align="end" sx={{ width: "100%" }}>
                  <ProductField
                    name={`entries.${index}.product`}
                    idName="id"
                    label="Product"
                    required
                    sx={{ flex: 3 }}
                  />
                  <Mc
                    as={M.NumberInput}
                    name={`entries.${index}.qty`}
                    label="Qty"
                    required
                    sx={{ flex: 1 }}
                  />
                  <M.ActionIcon onClick={() => productFa.remove(index)}>
                    <CloseIcon />
                  </M.ActionIcon>
                </M.Group>
              </M.Stack>
            ))}
            <M.Box>
              <M.Button
                mt="md"
                color="darkGray"
                variant="light"
                onClick={() =>
                  productFa.append({
                    product: null as any,
                    qty: 0,
                  })
                }
              >
                Add product
              </M.Button>
            </M.Box>
            <M.Group position="right">
              <M.Button
                type="submit"
                loading={isLoading}
                disabled={productFa.fields.length === 0}
              >
                Submit
              </M.Button>
            </M.Group>
          </M.Stack>
        </FormProvider>
      </form>
    </M.Container>
  );
};

export interface ProductFieldProps extends Omit<M.SelectProps, "data"> {
  name: string;
  idName: string;
}
export const ProductField: React.FC<ProductFieldProps> = (ps) => {
  const [search, searchState] = useProductSearch();
  const { setValue } = useFormContext();
  const selectedProduct = useWatch({
    control: useFormContext().control,
    name: ps.name,
  });
  const selectedOption = React.useMemo(
    () =>
      selectedProduct?.id
        ? {
            label: selectedProduct?.title,
            value: selectedProduct?.id,
            itemSku: selectedProduct,
          }
        : null,
    [selectedProduct]
  );

  return (
    <MantineController
      as={M.Select}
      {...ps}
      data={
        selectedOption
          ? [selectedOption, ...searchState.options]
          : searchState.options
      }
      name={`${ps.name}.${ps.idName}`}
      onInput={(e: any) => search(e.currentTarget.value)}
      onChange={(v: any) => {
        const item = searchState.options.find((o) => o.value === v)?.itemSku;
        setValue(ps.name, {
          ...item,
          id: item?.id.toString(),
        });
      }}
      filter={() => true}
      searchable
    />
  );
};

const CustomerField = () => {
  const { setValue } = useFormContext();
  const [search, setSearch] = React.useState<string | null>("");
  const { data } = trpc.user.getManyWithCustomerRole.useQuery();

  const options = React.useMemo(
    () =>
      data
        ?.map((user) => ({
          label: `${user.firstName} ${user.lastName ?? ""}`,
          value: user.id.toString(),
          user,
        }))
        ?.filter((o) =>
          o.label.toLowerCase().includes(search?.toLowerCase() ?? "")
        ) ?? [],
    [search, data]
  );

  return (
    <Mc
      as={M.Select}
      name="customer.id"
      label="Customer"
      required
      data={options}
      onInput={(e: any) => setSearch(e.currentTarget.value)}
      onChange={(v: any) => {
        if (!v) {
          setValue("email", "");
          setValue("customer", null);
          return;
        }
        const customer = options.find((o) => o.value === v)?.user;
        setValue("email", customer?.email);
        setValue("customer", {
          ...customer,
          id: customer?.id.toString(),
        });
      }}
      clearable
      searchable
      filter={() => true}
    />
  );
};
