import * as C from "@chakra-ui/react";
import { ReactNode, useRef } from "react";

export interface ConfirmDialogProps
  extends Omit<C.AlertDialogProps, "leastDestructiveRef" | "children"> {
  title?: string | ReactNode;
  description?: string | ReactNode;
  colorScheme?: string;
  leastDestructiveAction?: string;
  mostDestructiveAction?: string;
  onLeastDestructiveAction?: () => void;
  onMostDestructiveAction?: () => void;
}

export default function ConfirmDialog(props: ConfirmDialogProps) {
  const {
    title,
    description,
    colorScheme = "red",
    leastDestructiveAction = "Cancel",
    mostDestructiveAction = "Confirm",
    onLeastDestructiveAction,
    onMostDestructiveAction,
    ...modalProps
  } = props;

  const ref = useRef(null);

  return (
    <C.AlertDialog {...modalProps} leastDestructiveRef={ref}>
      <C.AlertDialogOverlay>
        <C.AlertDialogContent>
          <C.AlertDialogHeader fontSize="lg" fontWeight="bold">
            {title}
          </C.AlertDialogHeader>
          <C.AlertDialogBody>{description}</C.AlertDialogBody>
          <C.AlertDialogFooter>
            <C.Button
              colorScheme="gray"
              color="gray.600"
              ref={ref}
              onClick={() => {
                modalProps.onClose();
                if (onLeastDestructiveAction) {
                  onLeastDestructiveAction();
                }
              }}
            >
              {leastDestructiveAction}
            </C.Button>
            <C.Button
              colorScheme={colorScheme}
              onClick={() => {
                modalProps.onClose();
                if (onMostDestructiveAction) {
                  onMostDestructiveAction();
                }
              }}
              ml={3}
            >
              {mostDestructiveAction}
            </C.Button>
          </C.AlertDialogFooter>
        </C.AlertDialogContent>
      </C.AlertDialogOverlay>
    </C.AlertDialog>
  );
}
