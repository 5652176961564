import { trpc } from "#/trpc";
import { reportUserError, reportUserSuccess } from "#/util";
import * as M from "@mantine/core";
import { modals } from "@mantine/modals";
import CheckIcon from "~icons/ic/baseline-check";
import ClipboardIcon from "~icons/ion/clipboard-outline";

export function openGeneratePasswordModal(id: number) {
  modals.open({
    title: <h3>Generate password</h3>,
    children: <GeneratePasswordModalChildren id={id} />,
    size: "md",
  });
}

const GeneratePasswordModalChildren = ({ id }: { id: number }) => {
  const {
    mutate,
    data: newPassword,
    isLoading,
  } = trpc.v2_5.user.generateRandomPassword.useMutation({
    onSuccess: () => {
      reportUserSuccess({
        title: "Password generated",
        message: "Password has been successfully generated",
      });
    },
    onError: (err) => {
      reportUserError({
        title: "Failed to generate password",
        message: err.message,
      });
    },
  });

  const generateRandomPassword = () => {
    mutate({
      id,
    });
  };

  return (
    <div className="flex flex-col gap-y-5">
      <M.Text align="center" weight={500} color="red">
        Are you sure you want to generate your password?
      </M.Text>
      <M.Text align="center">
        This action will change the old password and generate a new random
        password.
      </M.Text>
      <M.Button loading={isLoading} onClick={generateRandomPassword}>
        Generate
      </M.Button>
      {isLoading && (
        <M.Center>
          <M.Loader />
        </M.Center>
      )}
      {!isLoading && newPassword && (
        <M.Flex align="center">
          <M.CopyButton value={newPassword} timeout={2000}>
            {({ copied, copy }) => (
              <M.Tooltip
                label={copied ? "Copied" : "Copy"}
                withArrow
                position="right"
                withinPortal
              >
                <M.Button
                  color={copied ? "teal" : "gray"}
                  onClick={copy}
                  variant="outline"
                  className="w-full"
                  rightIcon={copied ? <CheckIcon /> : <ClipboardIcon />}
                >
                  {newPassword}
                </M.Button>
              </M.Tooltip>
            )}
          </M.CopyButton>
        </M.Flex>
      )}
    </div>
  );
};
