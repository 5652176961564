import { RouterInputs, RouterOutputs, trpc } from "#/trpc"
import {
	MantineReactTable,
	MRT_ColumnDef,
	MRT_SortingState,
	useMantineReactTable,
} from "mantine-react-table"
import { FilterKey } from "./$common"
import { reportUserError, reportUserSuccess } from "#/util"
import { useAuth } from "#/context/AuthContext"
import { useDisclosure } from "@mantine/hooks"
import { exportInvoicePendingCartsToXLSX } from "./export-invoice"
import { printTicketFromCartV2 } from "#/modules/ticket/print"
import { openConfirmModal } from "@mantine/modals"
import { TicketPreview } from "./ticket-preview"
import { ActionIcon, Modal } from "@mantine/core"
import { PendingCartContent } from "./PendingCartContent"
import { Badge, Flex, IconButton, Tooltip } from "@radix-ui/themes"
import { Dropdown } from "@gt/ui"
import MenuIcon from "~icons/ion/ellipsis-horizontal-outline"
import EditIcon from "~icons/ic/baseline-edit"
import { MdOutlineDownload, MdVisibility } from "react-icons/md"
import { Link } from "react-router-dom"
import { NoteBlackCustomIcon } from "#/components-ng/index.js"
import { match } from "ts-pattern"
import dayjs from "dayjs"
import { css } from "#/css/css"

export type FilialFilter = NonNullable<
	RouterInputs["v2_5"]["cart"]["getByPage"]["filters"]
>["filial"]

export interface PendingCartTableProps {
	filter: Array<FilterKey>
	search?: string | null
	dateRange: [Date | null, Date | null]
	filialFilter: FilialFilter
}

export const PendingCartsTable = (props: PendingCartTableProps) => {
	// Pagination
	const [pagination, setPagination] = useState({
		pageIndex: 0,
		pageSize: 25,
	})
	// Sorting
	const [sorting, setSorting] = useState<MRT_SortingState>([])

	const querySorting = useMemo(() => {
		if (sorting[0]) {
			return {
				desc: sorting[0].desc,
				key: sorting[0].id as any,
			}
		}
		return null
	}, [sorting])

	const { data, isLoading } = trpc.v2_5.cart.getByPage.useQuery(
		{
			pageIndex: pagination.pageIndex,
			pageSize: pagination.pageSize,
			sorting: querySorting,
			search: props.search,
			filters: {
				status: props.filter.filter((f) => f !== "ECOMMERCE") as any,
				dateRange: [props.dateRange[0]!, props.dateRange[1]!],
				filial: props.filialFilter,
			},
		},
		{
			enabled: !!props.dateRange[0] && !!props.dateRange[1],
			keepPreviousData: true,
			onError(error) {
				reportUserError({
					title: "Failed to get carts",
					message: error.message,
				})
			},
		},
	)

	const { mutate: exportCsv, isLoading: isLoadingExport } =
		trpc.v2_5.cart.exportPendingCartsTableToCsv.useMutation({
			onSuccess: () => {
				reportUserSuccess({
					title: "The csv file has been sent to email",
				})
			},
		})

	const handleExportCsv = () => {
		exportCsv({
			pageIndex: pagination.pageIndex,
			pageSize: pagination.pageSize,
			sorting: querySorting,
			search: props.search,
			filters: {
				status: props.filter.filter((f) => f !== "ECOMMERCE") as any,
				dateRange: [props.dateRange[0]!, props.dateRange[1]!],
				filial: props.filialFilter,
			},
		})
	}

	const table = useMantineReactTable({
		data: data?.entries ?? [],
		columns,
		manualPagination: true,
		enableTopToolbar: false,
		rowCount: data?.totalEntries ?? 0,
		enableFilters: false,
		state: {
			pagination,
			sorting,
			isLoading,
		},
		enableStickyHeader: true,
		manualSorting: true,
		onSortingChange: setSorting,
		onPaginationChange: setPagination,
		renderBottomToolbarCustomActions: () => (
			<div
				className={css({
					display: "flex",
					alignItems: "center",
					gap: 2,
				})}
			>
				<Tooltip content="Export to CSV">
					<IconButton
						color="gray"
						variant="soft"
						loading={isLoadingExport}
						onClick={handleExportCsv}
					>
						<MdOutlineDownload />
					</IconButton>
				</Tooltip>
			</div>
		),
	})

	return <MantineReactTable table={table} />
}

type Cart = RouterOutputs["v2_5"]["cart"]["getByPage"]["entries"][number]

const columns: MRT_ColumnDef<Cart>[] = [
	{
		id: "actions",
		header: "Actions",
		size: 100,
		enableSorting: false,
		Cell: (table) => {
			const [{ auth }] = useAuth()
			const ctx = trpc.useContext()
			const original = table.row.original

			const [previewTicketOpened, setPreviewTicketOpened] =
				useState<boolean>(false)
			const [reprintEpson, setReprintEpson] = useState<{
				enabled: boolean
				includeLocation?: boolean
			}>({ enabled: false })
			const [exportToXlsx, setExportToXlsx] = useState<boolean>(false)
			const [mustFetchCart, setMustFetchCart] = useState<boolean>(false)
			const [
				openedCartContent,
				{ open: openCartContent, close: closeCartContent },
			] = useDisclosure(false)

			const { mutate: deleteCart } = trpc.v2_5.cart.delete.useMutation({
				async onError(error) {
					reportUserError({
						title: "Failed to delete cart",
						message: error.message,
					})
				},
				async onSuccess() {
					ctx.cart.invalidate()
					ctx.v2_5.cart.invalidate()
					reportUserSuccess({
						title: "Cart deleted successfully",
					})
				},
			})

			const { data } = trpc.cart.getById.useQuery(
				{ id: Number(original.id) },
				{
					enabled: mustFetchCart,
					onError(error) {
						reportUserError({
							title: "Failed to get cart",
							message: error.message,
						})
					},
					onSuccess(data) {
						if (exportToXlsx) {
							exportInvoicePendingCartsToXLSX(data as any)
							setExportToXlsx(false)
							setMustFetchCart(false)
						}
						if (reprintEpson.enabled) {
							printTicketFromCartV2({
								cart: {
									...data,
									cartItemSku: data.cartItemSku.map((cartItemSku) => {
										return {
											...cartItemSku,
											discount:
												data?.cartType !== "IN_STORE"
													? // @ts-ignore
														cartItemSku?.itemSku?.discount
													: cartItemSku?.discount,
										}
									}),
								}!,
								withLocation: !!reprintEpson.includeLocation,
								filialId: auth?.user?.filialId ?? 1,
							})
							setReprintEpson({ enabled: false })
							setMustFetchCart(false)
						}
					},
				},
			)

			const { mutate: transferCart } = trpc.v2_5.cart.transferCart.useMutation({
				onError(error) {
					reportUserError({
						title: "Failed to transfer cart",
						message: error.message,
					})
				},
				onSuccess() {
					ctx.cart.invalidate()
					ctx.v2_5.cart.invalidate()
					reportUserSuccess({
						title: "Cart transferred successfully",
					})
				},
			})

			const openMoveCartConfirmModal = () =>
				openConfirmModal({
					title: "Are you sure you want to transfer this cart?",
					labels: { cancel: "Cancel", confirm: "Move" },
					confirmProps: { color: "red" },
					onConfirm: () =>
						transferCart({
							id: Number(original.id),
						}),
				})

			const openDeleteCartConfirmModal = () =>
				openConfirmModal({
					title: "Are you sure you want to delete this cart?",
					labels: { cancel: "Cancel", confirm: "Delete" },
					confirmProps: { color: "red" },
					onConfirm: () =>
						deleteCart({
							filter: { id: original.id },
						}),
				})

			const isCartOnlineAndEcom =
				original.cartStatus === "OPEN" && original.cartType === "ONLINE"

			let editLink = `/sales/make-a-sale/${original.id}`
			if (isCartOnlineAndEcom) {
				editLink = `/sales/pending-carts/edit-ecom/${original.uuid}`
			}

			let isEditButtonDisabled = true
			if (isCartOnlineAndEcom || original.cartStatus === "HOLD") {
				isEditButtonDisabled = false
			}

			return (
				<>
					<Flex>
						<Dropdown.Root>
							<Dropdown.Trigger>
								<ActionIcon>
									<MenuIcon />
								</ActionIcon>
							</Dropdown.Trigger>
							<Dropdown.Content>
								<Dropdown.Item asChild>
									<Link to={`/sales/pending-carts/pdf/${original.id}`}>
										Export PDF
									</Link>
								</Dropdown.Item>
								<Dropdown.Item
									onClick={() => {
										setMustFetchCart(true)
										setExportToXlsx(true)
									}}
								>
									Export XLSX
								</Dropdown.Item>
								<Dropdown.Item onClick={openDeleteCartConfirmModal}>
									Delete
								</Dropdown.Item>
								<Dropdown.Item
									onClick={() => {
										setMustFetchCart(true)
										setReprintEpson({ enabled: true })
									}}
								>
									Re-print (Epson)
								</Dropdown.Item>
								<Dropdown.Item
									onClick={() => {
										setMustFetchCart(true)
										setReprintEpson({ enabled: true, includeLocation: true })
									}}
								>
									Re-print with location (Epson)
								</Dropdown.Item>
								<Dropdown.Item
									onClick={() => {
										setMustFetchCart(true)
										setPreviewTicketOpened(true)
									}}
								>
									Preview receipt
								</Dropdown.Item>
								{original.cartType === "ONLINE" &&
									original.cartStatus === "OPEN" &&
									original.customerId && (
										<Dropdown.Item onClick={openMoveCartConfirmModal}>
											Transfer to In-Store Cart
										</Dropdown.Item>
									)}
							</Dropdown.Content>
						</Dropdown.Root>
						<ActionIcon
							component={Link}
							to={editLink}
							disabled={isEditButtonDisabled}
						>
							<EditIcon />
						</ActionIcon>
						<ActionIcon onClick={openCartContent}>
							<MdVisibility />
						</ActionIcon>
						{original.note && (
							<Tooltip content="Note on cart">
								<ActionIcon>
									<NoteBlackCustomIcon width={"18px"} height={"18px"} />
								</ActionIcon>
							</Tooltip>
						)}
					</Flex>
					{openedCartContent && (
						<Modal
							opened={openedCartContent}
							onClose={closeCartContent}
							withCloseButton={false}
							styles={{ content: { width: "auto", maxWidth: "initial" } }}
							zIndex={1200}
							size="auto"
						>
							<PendingCartContent id={original.id} />
						</Modal>
					)}
					{previewTicketOpened && (
						<Modal
							opened={previewTicketOpened}
							onClose={() => setPreviewTicketOpened(false)}
							withCloseButton={false}
						>
							{data && (
								<TicketPreview
									cart={{
										...data,
										cartItemSku: data.cartItemSku.map((cartItemSku) => {
											return {
												...cartItemSku,
												discount:
													data?.cartType !== "IN_STORE"
														? // @ts-ignore
															cartItemSku?.itemSku?.discount
														: cartItemSku?.discount,
											}
										}),
									}}
									filialId={auth?.user?.filialId ?? 0}
								/>
							)}
						</Modal>
					)}
				</>
			)
		},
	},
	{
		header: "Customer name",
		enableSorting: false,
		Cell: (table) => {
			const customerFullName = `${table.row.original.customer?.firstName ?? ""} ${table.row.original.customer?.lastName ?? ""}`
			return customerFullName
		},
	},
	{
		header: "Sales assistant name",
		enableSorting: false,
		Cell: (table) => {
			const associatedFullName = `${table.row.original.associated?.firstName ?? ""} ${table.row.original.associated?.lastName ?? ""}`
			return associatedFullName
		},
	},
	{
		header: "Status",
		accessorKey: "cartStatus",
		enableSorting: false,
		Cell: (table) => {
			const cartStatus = table.row.original.cartStatus
			const value = `${cartStatus[0]}${cartStatus.substring(1).toLowerCase()}`

			return (
				<Badge
					size="3"
					radius="full"
					color={
						match(cartStatus)
							.with("OPEN", () => "blue")
							.with("HOLD", () => "orange")
							.with("CLOSED", () => "red")
							.with("CANCELLED", () => "violet")
							.otherwise(() => "gray") as any
					}
				>
					{value}
				</Badge>
			)
		},
	},
	{
		header: "Total",
		accessorKey: "total",
		enableSorting: false,
		Cell: (table) => {
			const total = table.row.original.total
			return `$${total.toFixed(2)}`
		},
	},
	{
		id: "updatedAt",
		header: "Last updated",
		accessorKey: "updatedAt",
		Cell: (table) => {
			const updatedAt = table.row.original.updatedAt
			if (!updatedAt) return ""
			return dayjs(updatedAt).format("MM/DD/YYYY HH:mm").toString()
		},
	},
	{
		id: "createdAt",
		header: "Created at",
		accessorKey: "createdAt",
		Cell: (table) => {
			const createdAt = table.row.original.createdAt
			if (!createdAt) return ""
			return dayjs(createdAt).format("MM/DD/YYYY HH:mm").toString()
		},
	},
]
