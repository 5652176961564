import { atom } from "jotai";
import { ShowRoomType } from "server";

interface locationsAtom {
  filialId: number;
  filialName: string;
  storeLocation?: string | null;
  warehouseLocation?: string | null;
  reorder: number;
  isLine: boolean;
}

export const locationsAtom = atom<locationsAtom[]>([]);

export const showRoomTypes = atom<ShowRoomType | null>("PUBLIC");

export const itemSkuCustomAttributesAtom = atom<string[]>([]);
