import styled from "styled-components";

export const StyledLayout = styled.div`
  height: 100vh;
  width: 100%;

  @media (max-width: 768px) {
  }
`;

export const Content = styled.div`
  overflow: auto;
  height: calc(100vh - 60px);

  @media (min-width: 768px) {
  }
`;
