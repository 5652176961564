// @ts-nocheck
import { ChangelogArticleDialog } from "#/changelog/index.js"
import Header from "#/components/Header/index.js"
import Menu from "#/components/Menu/index.jsx"
import { ErrorBoundary } from "#/components/ng/index.js"
import { BottomBarMenu } from "#/components@v2_5/bottom-bard-menu.js"
import { routes } from "#/scenes/routes.js"
import { StyledLayout, Content } from "./Styles.js"
import {
	useDidUpdate,
	useMediaQuery,
	useNetwork,
	usePrevious,
} from "@mantine/hooks"
import { showNotification } from "@mantine/notifications"
import { useState, useEffect } from "react"
import { Helmet } from "react-helmet"
import { useLocation } from "react-router"
import { Outlet } from "react-router-dom"
import useBreadcrumbs from "use-react-router-breadcrumbs"
import Wifi from "~icons/ic/round-wifi"
import WifiOff from "~icons/ic/round-wifi-off"

const Layout = ({ children }) => {
	const location = useLocation()
	const isDesktop = useMediaQuery("(min-width: 768px)")

	const [menuOpen, setMenuOpen] = useState(isDesktop ? true : false)
	const onMenuToggle = () => {
		setMenuOpen(!menuOpen)
	}

	useEffect(() => {
		if (isDesktop) {
			setMenuOpen(true)
		} else {
			setMenuOpen(false)
		}
	}, [isDesktop])

	const breadcrumbs = useBreadcrumbs(routes)
	let title = "Unknown"
	let breadcrumbChildren =
		breadcrumbs[breadcrumbs.length - 1].breadcrumb?.props?.children
	if (typeof breadcrumbChildren === "string") {
		title = breadcrumbChildren
	}
	const networkStatus = useNetwork()
	const previousNetworkStatus = usePrevious(networkStatus)

	useDidUpdate(() => {
		if (!networkStatus.online && previousNetworkStatus.online) {
			showNotification({
				title: "You're offline now",
				message: "Verify your internet connection",
				icon: <WifiOff />,
				color: "red",
				autoClose: 15000,
			})
		} else if (networkStatus.online && !previousNetworkStatus.online) {
			showNotification({
				title: "You're online now",
				message: "Internet has been connected",
				icon: <Wifi />,
				color: "green",
				autoClose: 15000,
			})
		}
	})

	return (
		<StyledLayout>
			<ChangelogArticleDialog />
			<Helmet>
				<title>{`GT | ${title}`}</title>
			</Helmet>
			<Header onMenuToggle={onMenuToggle} />
			<div className={"flex"}>
				<Menu location={location} open={menuOpen} isDesktop={isDesktop} />
				<Content className={"flex-1"}>
					<ErrorBoundary>
						{children}
						<Outlet />
					</ErrorBoundary>
				</Content>
			</div>
			<BottomBarMenu />
		</StyledLayout>
	)
}

export default Layout
