import { useAuth } from "#/context/AuthContext.js";
import { css } from "#/css/css";
import { HStack } from "#/css/jsx";
import { trpc } from "#/trpc.js";
import { reportUserError, reportUserSuccess } from "#/util/index.js";
import { Button, Dropdown } from "@gt/ui";
import { DatePickerInput } from "@mantine/dates";
import { DropdownMenu } from "@radix-ui/themes";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone.js";
import utc from "dayjs/plugin/utc.js";
import { useState } from "react";
import CalendarIcon from "~icons/ion/calendar-clear-outline";
import { ItemListTable } from "../ItemList/ItemListTable";
import { DetailsDrawer } from "../ItemList/DetailsDrawer";
import ZebraBrowserPrintWrapper from "zebra-browser-print-wrapper";
import {
  openDetailsDrawerAtom,
  openModalAtom,
  selectedItemSkuIdAtom,
  selectedItemSkuToPrintAtom,
} from "../ItemList/ItemList";
import { Provider } from "jotai";
import { PrintLabelsModal } from "../ItemList/PrintLabelsModal";

dayjs.extend(utc);
dayjs.extend(timezone);

const NewArrivals = () => {
  const [{ auth }] = useAuth();
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    dayjs().subtract(1, "M").toDate(),
    new Date(),
  ]);

  const [browserPrint] = useState(() => new ZebraBrowserPrintWrapper());
  const [isLoadingPrinters, setIsLoadingPrinters] = useState<boolean>(false);

  const [selectedItemSkuId, setSelectedItemSkuId] = useAtom(
    selectedItemSkuIdAtom,
  );
  const [selectedItemSkuToPrint, setSelectedItemSkuToPrint] = useAtom(
    selectedItemSkuToPrintAtom,
  );
  const [openModal, setOpenModal] = useAtom(openModalAtom);
  const [openDetailsDrawer, setOpenDetailsDrawer] = useAtom(
    openDetailsDrawerAtom,
  );

  useEffect(() => {
    async function setDefaultPrinter() {
      setIsLoadingPrinters(true);
      const availablePrinters = await browserPrint.getAvailablePrinters();
      browserPrint.setPrinter(availablePrinters[0]);
      setIsLoadingPrinters(false);
    }

    setDefaultPrinter();
  }, [browserPrint]);

  return (
    <>
      <Provider key={selectedItemSkuToPrint?.id}>
        <PrintLabelsModal
          printer={browserPrint}
          isLoadingPrinters={isLoadingPrinters}
          defaultProduct={selectedItemSkuToPrint as any}
          setSelectedItemSkuToPrint={setSelectedItemSkuToPrint}
          opened={openModal}
          setOpened={setOpenModal}
        />
      </Provider>
      <HStack justify="space-between" gap={3} flexWrap="nowrap">
        <HStack width="100%" gap={3}>
          <DrowndownButton
            startDate={dateRange[0]}
            endDate={dateRange[1]}
            filialId={auth?.user?.filialId}
          />
          <DatePickerInput
            type="range"
            value={dateRange}
            clearable={false}
            popoverProps={{
              withinPortal: false,
              zIndex: 9999,
            }}
            numberOfColumns={2}
            onChange={setDateRange}
            allowSingleDateInRange
            required
            sx={{ width: "100%", maxWidth: "280px" }}
            icon={<CalendarIcon color="#424242" />}
          />
        </HStack>
      </HStack>
      <div className={css({ marginTop: 15 })}>
        <ItemListTable
          dateRange={{
            startDate: dateRange[0],
            endDate: dateRange[1],
          }}
        />
      </div>
      <DetailsDrawer
        opened={openDetailsDrawer}
        onClose={() => {
          setOpenDetailsDrawer(false);
          setSelectedItemSkuId(null);
        }}
        itemSkuId={selectedItemSkuId?.id ?? 0}
        sku={selectedItemSkuId?.sku?.toString() ?? ""}
        title={selectedItemSkuId?.title ?? ""}
      />
    </>
  );
};

export default NewArrivals;

interface DrowndownButtonProps {
  startDate?: Date | null;
  endDate?: Date | null;
  filialId?: number | null;
}

const DrowndownButton = (props: DrowndownButtonProps) => {
  const { mutate: generatePdf, isLoading } =
    trpc.itemSku.createNewArrivalsPdf.useMutation({
      cacheTime: 0,
      onError: (err) => {
        reportUserError({
          title: "Failed to load products",
          message: err.message,
        });
      },
      onSuccess: () => {
        reportUserSuccess({
          title: "You pdf is being generated",
          message:
            "Please wait a few minutes, the link will be sent to your email",
        });
      },
    });

  const handleGeneratePdf = (language: "en" | "es") => {
    generatePdf({
      language: language,
      startDate: dayjs(props.startDate).tz("America/New_York").toDate(),
      endDate: dayjs(props.endDate).tz("America/New_York").toDate(),
      filialId: props.filialId,
    });
  };

  return (
    <Dropdown.Root>
      <Dropdown.Trigger>
        <Button
          className={css({
            gap: 4,
          })}
        >
          Customer Pdf
          <DropdownMenu.TriggerIcon />
        </Button>
      </Dropdown.Trigger>
      <Dropdown.Content>
        <Dropdown.Item
          disabled={!!isLoading}
          onClick={() => handleGeneratePdf("en")}
        >
          English
        </Dropdown.Item>
        <Dropdown.Item
          disabled={!!isLoading}
          onClick={() => handleGeneratePdf("es")}
        >
          Spanish
        </Dropdown.Item>
      </Dropdown.Content>
    </Dropdown.Root>
  );
};
