import { FilterOutlineIcon } from "#/components-ng/icons.js"
import { SearchField } from "#/components/index.js"
import { useAuth } from "#/context/AuthContext.js"
import logoSrc from "#/images/monochromatic-logo-ticket.png"
import {
	printTicketFromRefundV2,
	refundOrderItemSkuToTicket,
} from "#/modules/ticket/print.js"
import { RouterOutputs, trpc } from "#/trpc.js"
import { reportUserError, reportUserSuccess } from "#/util/index.js"
import { IconButton, Tooltip } from "@radix-ui/themes"
import { exportReturnToXLSX } from "./export-return.js"
import * as M from "@mantine/core"
import { DatePickerInput } from "@mantine/dates"
import { openConfirmModal } from "@mantine/modals"
import dayjs from "dayjs"
import {
	MantineReactTable,
	MRT_ColumnDef,
	MRT_SortingState,
	useMantineReactTable,
} from "mantine-react-table"
import { MdMoreHoriz, MdOutlineDownload } from "react-icons/md/index.js"
import CalendarIcon from "~icons/ion/calendar-clear-outline"
import { css } from "#/css/css"

const TicketPreview = ({ refundOrder, filialId }) => {
	const ticket = refundOrderItemSkuToTicket(refundOrder, filialId)

	let changeString = `$${ticket.change.toFixed(2)}`
	let shippingString = ticket.shipping ? `$${ticket.shipping.toFixed(2)}` : ""
	let subtotalString = `${ticket.subtotal.toFixed(2)}`
	let taxString = `+$${ticket.taxAmount.toFixed(2)}`
	let totalString = `${ticket.total.toFixed(2)}`
	const maxLength = Math.max(
		subtotalString.length,
		taxString.length,
		totalString.length,
		changeString.length,
		shippingString.length,
	)
	changeString = changeString.padStart(maxLength, " ")
	shippingString = shippingString.padStart(maxLength, " ")
	subtotalString = subtotalString.padStart(maxLength, " ")
	taxString = taxString.padStart(maxLength, " ")
	totalString = totalString.padStart(maxLength, " ")

	return (
		<M.Stack spacing={2} sx={{ "& *": { fontFamily: "monospace !important" } }}>
			<M.Group position="apart">
				<M.Text>Date: {dayjs().format("MM/DD/YYYY")}</M.Text>
				<M.Text>Order: {ticket.order}</M.Text>
			</M.Group>
			<M.Group position="apart">
				<M.Text>Store: {ticket.store}</M.Text>
				<M.Text>Workstation: {ticket.workstation}</M.Text>
			</M.Group>
			<M.Stack align="center" spacing={2}>
				<M.Text>REPRINTED</M.Text>
				<M.Image src={logoSrc} width={240} />
				<M.Text>GOLD TREE</M.Text>
				<M.Text>755 NW 72nd AVE PLAZA 33</M.Text>
				<M.Text>MIAMI, FL, 33126</M.Text>
				<M.Text>TEL +1 (786) 7177131</M.Text>
			</M.Stack>
			<M.Text weight="bold" mt="md">
				Bill to: {ticket.customer}
			</M.Text>
			<M.Text weight="bold">Cashier: {ticket.cashier}</M.Text>
			<M.Text underline component="pre" mt="md">
				{"Item #".padEnd(16)}
				{"".padEnd(1)}
				{"Qty".padEnd(3)}
				{"".padEnd(3)}
				{"D%".padEnd(3)}
				{"".padEnd(3)}
				{"Price".padEnd(8)}
				{"".padEnd(3)}
				{"Total".padEnd(8)}
			</M.Text>
			{ticket.products.map((p, i) => {
				const itemCell = `${p.sku} ${p.title}`
				return (
					<M.Stack spacing={0} key={i}>
						<M.Text component="pre">
							{itemCell.substring(0, 17).padEnd(16)}
							{"".padEnd(1)}
							{p.qty.toString().padStart(3)}
							{"".padEnd(3)}
							{(p.discount ? p.discount + "%" : "").padEnd(3)}
							{"".padEnd(3)}
							{`$${p.price.toFixed(2)}`.padEnd(8)}
							{"".padEnd(3)}
							{`$${p.total.toFixed(2)}`.padEnd(8)}
						</M.Text>
						<M.Text component="pre">
							{itemCell.substring(17, 34).padEnd(16)}
							{"".padEnd(1)}
							{"".padStart(3)}
							{"".padEnd(3)}
							{"".padEnd(3)}
							{"".padEnd(3)}
							{(p.discount
								? `$${(p.price * (p.discount / 100.0)).toFixed(2)}`
								: ""
							).padEnd(8)}
							{"".padEnd(3)}
							{"".padEnd(8)}
						</M.Text>
					</M.Stack>
				)
			})}
			<M.Stack spacing={2} align="end" mt="md">
				<M.Text>Change: {changeString}</M.Text>
				{!ticket.shipping?.eq(0) && `Shipping: ${shippingString}`}
				<M.Text>Subtotal: ${subtotalString}</M.Text>
				<M.Text>
					Local sales tax ${ticket.taxPercentage.toString()}% Tax: ${taxString}
				</M.Text>
				<M.Text weight="bold">CREDIT RECEIPT: ${totalString}</M.Text>
			</M.Stack>
			{ticket.payments.map((payment, i) => (
				<M.Text key={i}>
					{"  "}
					{payment.method}: ${payment.amount}
				</M.Text>
			))}
			<M.Stack spacing={2} align="end">
				{ticket.saleDiscount && (
					<M.Text>
						{"  "}Sale discount:
						{ticket.saleDiscount?.type === "AMOUNT" ? "$" : ""}
						{ticket.saleDiscount?.amount.toFixed(2)}
						{ticket.saleDiscount?.type === "PERCENTAGE" ? "%" : ""}
					</M.Text>
				)}
				<M.Text>
					Total sales discounts: ${ticket.totalDiscount.toFixed(2)}
				</M.Text>
			</M.Stack>
			<M.Stack spacing={2} align="center" mt="md">
				<M.Text>Thanks for shopping with us!</M.Text>
				<M.Text>All sales are final</M.Text>
				<M.Text>No exchanges. No returns</M.Text>
			</M.Stack>
		</M.Stack>
	)
}

export default function ReturnHistory() {
	const [{ auth }] = useAuth()
	const [query, setQuery] = useState<string | null>(null)
	const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
		dayjs().subtract(1, "M").toDate(),
		new Date(),
	])

	const [filters, setFilters] = useState<{
		[key: string]: boolean
	}>({})

	// pagination
	const [pagination, setPagination] = useState({
		pageIndex: 0,
		pageSize: 25,
	})

	// sorting
	const [sorting, setSorting] = useState<MRT_SortingState>([])
	const querySorting = useMemo(() => {
		if (sorting[0]) {
			return {
				desc: sorting[0].desc,
				key: sorting[0].id as any,
			}
		}
		return null
	}, [sorting])

	const { data: filials } = trpc.filial.getAll.useQuery(undefined, {
		cacheTime: 0,
		enabled: !auth?.user?.filialId,
		onSuccess(data) {
			setFilters(data.reduce((acc, fil) => ({ ...acc, [fil.name]: true }), {}))
		},
	})

	const { data, isLoading } = trpc.v2_5.orderReturn.getRefundsByPage.useQuery(
		{
			search: query,
			pageIndex: pagination.pageIndex,
			pageSize: pagination.pageSize,
			sorting: querySorting,
			filters: {
				rangeDate: {
					start: dateRange[0]!,
					end: dateRange[1]!,
				},
				filialIds: auth?.user?.filialId
					? [auth?.user?.filialId]
					: filials?.filter((f) => filters[f.name])?.map((f) => f.id) ?? [],
			},
		},
		{
			enabled: dateRange[0] && dateRange[1] ? true : false,
			cacheTime: 0,
			onError(err) {
				reportUserError({
					title: "Failed to get return history",
					message: err.message,
				})
			},
		},
	)

	const { mutate: exportCsv, isLoading: isLoadingExport } =
		trpc.v2_5.orderReturn.exportReturnHistoryTableToCsv.useMutation({
			onSuccess: () => {
				reportUserSuccess({
					title: "The csv file has been sent to email",
				})
			},
		})

	const refundsForTable = useMemo(() => data?.entries ?? [], [data])

	const handleFilterChange = (filterKey) => {
		setFilters({
			...filters,
			[filterKey]: !filters[filterKey],
		})
	}

	const handleExportCsv = () => {
		exportCsv({
			search: query,
			pageIndex: pagination.pageIndex,
			pageSize: pagination.pageSize,
			sorting: querySorting,
			filters: {
				rangeDate: {
					start: dateRange[0]!,
					end: dateRange[1]!,
				},
				filialIds: auth?.user?.filialId
					? [auth?.user?.filialId]
					: filials?.filter((f) => filters[f.name])?.map((f) => f.id) ?? [],
			},
		})
	}

	const table = useMantineReactTable({
		data: refundsForTable ?? [],
		columns: columns,
		manualPagination: true,
		enableTopToolbar: false,
		rowCount: data?.totalEntries ?? 0,
		enableFilters: false,
		state: {
			pagination,
			sorting,
			isLoading,
		},
		enableStickyHeader: true,
		manualSorting: true,
		onSortingChange: setSorting,
		onPaginationChange: setPagination,
		renderBottomToolbarCustomActions: () => (
			<div
				className={css({
					display: "flex",
					alignItems: "center",
					gap: 2,
				})}
			>
				<Tooltip content="Export to CSV">
					<IconButton
						color="gray"
						variant="soft"
						loading={isLoadingExport}
						onClick={handleExportCsv}
					>
						<MdOutlineDownload />
					</IconButton>
				</Tooltip>
			</div>
		),
	})

	return (
		<>
			<M.Group align="right" w="100%" spacing={4} position="apart">
				<M.Group noWrap sx={{ width: "100%", maxWidth: "500px" }}>
					<SearchField
						size="sm"
						onChange={(e: any) => setQuery(e.currentTarget.value)}
					/>
					{!auth?.user?.filialId && (
						<M.Popover
							width={200}
							position="bottom"
							withArrow
							shadow="md"
							zIndex={1600}
							radius="sm"
						>
							<M.Popover.Target>
								<M.Button
									leftIcon={<FilterOutlineIcon />}
									color="white"
									c="dark.6"
								>
									Filter by filial
								</M.Button>
							</M.Popover.Target>
							<M.Popover.Dropdown>
								<M.Stack>
									{Object.keys(filters).map((filterKey) => (
										<M.Checkbox
											key={filterKey}
											label={filterKey}
											checked={filters[filterKey]}
											onChange={() => handleFilterChange(filterKey)}
										/>
									))}
								</M.Stack>
							</M.Popover.Dropdown>
						</M.Popover>
					)}
				</M.Group>
				<DatePickerInput
					type="range"
					value={dateRange}
					clearable={false}
					popoverProps={{
						withinPortal: false,
						zIndex: 9999,
					}}
					numberOfColumns={2}
					onChange={(value) => setDateRange(value)}
					allowSingleDateInRange
					required
					sx={{ width: "100%", maxWidth: "250px" }}
					icon={<CalendarIcon color="#424242" />}
				/>
			</M.Group>
			<M.Box mt={16} sx={{ background: "white" }}>
				<MantineReactTable table={table} />
			</M.Box>
		</>
	)
}

type Refund =
	RouterOutputs["v2_5"]["orderReturn"]["getRefundsByPage"]["entries"][number]

const columns: MRT_ColumnDef<Refund>[] = [
	{
		id: "actions",
		enableSorting: false,
		enableColumnFilter: false,
		header: "Actions",
		Cell(props) {
			const [{ auth }] = useAuth()
			const ctx = trpc.useContext()
			const pin = useRef("adminPin")
			const [reprintEpson, setReprintEpson] = useState<{
				enabled: boolean
				includeLocation?: boolean
			}>({ enabled: false })
			const [exportToXlsx, setExportToXlsx] = useState<boolean>(false)
			const [previewOpened, setPreviewOpened] = useState<boolean>(false)
			const [fetchRefunds, setFetchRefunds] = useState<boolean>(false)

			const original = props.row.original

			const { data } = trpc.refundOrderItemSku.getByReference.useQuery(
				{
					reference: original.reference,
					filialId: auth?.user?.filialId,
				},
				{
					cacheTime: 0,
					enabled: fetchRefunds,
					onSuccess(data) {
						if (exportToXlsx) {
							exportReturnToXLSX(data as any)
							setExportToXlsx(false)
							setFetchRefunds(false)
						}
						if (reprintEpson.enabled) {
							printTicketFromRefundV2({
								refunds: data!,
								withLocation: !!reprintEpson.includeLocation,
								filialId: original.filial.id,
							})
							setReprintEpson({ enabled: false })
							setFetchRefunds(false)
						}
					},
					onError(err) {
						reportUserError({
							title: "Failed to get order",
							message: err.message,
						})
					},
				},
			)

			const { mutate: reverse, isLoading: isLoadingReverse } =
				trpc.v2_5.orderReturn.reverseReturn.useMutation({
					cacheTime: 0,
					onSuccess() {
						ctx.refundOrderItemSku.invalidate()
						reportUserSuccess({
							title: "Successfully reversed return",
							message: `Successfully reversed return R${original.reference}`,
						})
					},
				})

			const OpenConfirmModal = () => {
				if (!auth?.user?.filialId) {
					return reportUserError({
						title: "Failed to reverse return",
						message: "You don't have a filial assigned to your account",
					})
				}

				openConfirmModal({
					title: "Are you sure you want to reverse this return?",
					children: (
						<M.PasswordInput
							label="Pin"
							onChange={(e) => (pin.current = e.currentTarget.value)}
						/>
					),
					labels: { cancel: "Cancel", confirm: "Confirm" },
					confirmProps: { color: "red" },
					onConfirm: () =>
						reverse({
							reference: Number(original.reference),
							pin: pin.current,
						}),
				})
			}

			return (
				<M.Group>
					<M.Menu withinPortal>
						<M.Menu.Target>
							<M.ActionIcon>
								<MdMoreHoriz />
							</M.ActionIcon>
						</M.Menu.Target>
						<M.Menu.Dropdown>
							<M.Menu.Item
								onClick={async () => {
									setFetchRefunds(true)
									setExportToXlsx(true)
								}}
							>
								Re-print (XLSX)
							</M.Menu.Item>
							<M.Menu.Item
								component="a"
								href={`return-history/print/${original.reference}`}
								target="_blank"
							>
								Re-print (PDF)
							</M.Menu.Item>
							<M.Menu.Item
								onClick={async () => {
									setFetchRefunds(true)
									setReprintEpson({ enabled: true })
								}}
							>
								Re-print (Epson)
							</M.Menu.Item>
							<M.Menu.Item
								onClick={async () => {
									setFetchRefunds(true)
									setReprintEpson({ enabled: true, includeLocation: true })
								}}
							>
								Re-print with location (Epson)
							</M.Menu.Item>
							<M.Menu.Item
								onClick={() => {
									setFetchRefunds(true)
									setPreviewOpened(true)
								}}
							>
								Preview receipt
							</M.Menu.Item>
							{auth?.user?.filialId && !original.isReversed && (
								<M.Menu.Item
									disabled={original.isReversed || isLoadingReverse}
									onClick={OpenConfirmModal}
								>
									Reverse
								</M.Menu.Item>
							)}
						</M.Menu.Dropdown>
					</M.Menu>
					<M.Modal
						opened={previewOpened}
						onClose={() => {
							setPreviewOpened(false)
							setFetchRefunds(false)
						}}
						withCloseButton={false}
					>
						{data && (
							<TicketPreview refundOrder={data} filialId={original.filial.id} />
						)}
					</M.Modal>
				</M.Group>
			)
		},
	},
	{
		id: "createdAt",
		header: "Date",
		accessorKey: "createdAt",
		Cell: (table) => {
			const createdAt = table.row.original.createdAt
			if (!createdAt) return ""
			return dayjs(createdAt).format("MM/DD/YYYY").toString()
		},
	},
	{
		id: "customer",
		header: "Customer",
		accessorKey: "customer",
	},
	{
		id: "reference",
		header: "Reference",
		accessorKey: "reference",
		Cell(table) {
			const reference = table.row.original.reference
			return <p>R{reference}</p>
		},
	},
	{
		id: "items",
		header: "Items",
		accessorKey: "items",
	},
	{
		id: "total",
		header: "Total",
		accessorKey: "total",
		Cell(table) {
			const total = table.row.original.total
			return <p>${total.toFixed(2)}</p>
		},
	},
	{
		id: "isReversed",
		header: "Status",
		accessorKey: "isReversed",
		Cell(table) {
			const isReversed = table.row.original.isReversed
			return <p>{isReversed ? "REVERSED" : "COMPLETE"}</p>
		},
	},
	{
		id: "filial",
		header: "Filial",
		accessorKey: "filial",
		enableSorting: false,
		enableColumnFilter: false,
		Cell(table) {
			const filial = table.row.original.filial
			return <p>{filial.name}</p>
		},
	},
]
