import { LOGO_URL } from "#/constants/envt.js"
import logoUrl from "#/images/colored-logo-invoice.png"
import { trpc } from "#/trpc.js"
import { reportUserError } from "#/util/index.js"
import { applyDiscountV2 } from "../util/index.js"
import * as M from "@mantine/core"
import dayjs from "dayjs"
import Decimal from "decimal.js"
import React from "react"
import { useParams } from "react-router"

export const CartPdf = () => {
	const { id } = useParams() as { id: string }
	const [imageLoaded, setImageLoaded] = useState<boolean>(false)

	const { data, isLoading, isFetchedAfterMount } = trpc.cart.getById.useQuery(
		{
			id: Number(id),
		},
		{
			refetchOnWindowFocus: false,
			cacheTime: 0,
			onError: (err) => {
				reportUserError({
					title: "Failed to load cart",
					message: err.message,
				})
			},
		},
	)

	React.useEffect(() => {
		if (data && !isLoading && isFetchedAfterMount && imageLoaded) {
			window.print()
		}
	}, [data, imageLoaded, isFetchedAfterMount, isLoading])

	if (isLoading || !data || !isFetchedAfterMount) {
		return (
			<M.Center>
				<M.Loader size="lg" p="xl" />
			</M.Center>
		)
	}

	const cart = data

	const subtotal = cart.cartItemSku!.reduce((acc, entry) => {
		return new Decimal(
			applyDiscountV2(
				entry!.itemSku.price.times(entry.quantity),
				entry!.discount,
			),
		)
			.plus(acc)
			.toNumber()
	}, 0)

	const tax = subtotal * cart?.filial?.taxRate?.toNumber()
	const total = cart?.customer?.taxable ? subtotal + tax : subtotal

	return (
		<M.Box p="xl">
			<M.Group position="apart">
				<M.Group position="left" spacing="md" align="flex-start">
					<M.Image
						src={logoUrl}
						width={100}
						onLoad={() => setImageLoaded(true)}
					/>
					<M.Stack spacing={0} align="flex-start">
						<M.Text weight={600} size={20} color="#836415">
							Gold Tree
						</M.Text>
						<M.Text weight={500} size={16} color="#1A1C21">
							www.goldtreemiami.com
						</M.Text>
						<M.Text size={16} color="#5E6470">
							+1 (786) 7177131
						</M.Text>
						<M.Text size={16} color="#5E6470">
							755 NW 72nd Ave Plaza 33
						</M.Text>
						<M.Text size={16} color="#5E6470">
							Miami, FL, 33126
						</M.Text>
					</M.Stack>
				</M.Group>
				<M.Stack spacing={0} align="flex-end">
					<M.Text size={16} color="#5E6470">
						Order number
					</M.Text>
					<M.Text weight={500} size={16} color="#1A1C21" mb={16}>
						{/* #{order?.receiptNumber} */}
					</M.Text>
					<M.Text size={16} color="#5E6470">
						Sale date: {dayjs(cart?.createdAt).format("MM/DD/YYYY")}
					</M.Text>
					<M.Text size={16} color="#5E6470">
						Printed date: {dayjs().format("MM/DD/YYYY")}
					</M.Text>
				</M.Stack>
			</M.Group>

			<M.Divider my={40} color="#D7DAE0" />

			<M.Stack spacing={0} align="flex-start" mb={40}>
				<M.Text size={16} color="#5E6470">
					Billed to
				</M.Text>
				<M.Text weight={500} size={16} color="#1A1C21">
					{cart?.customer
						? cart?.customer?.company
							? cart?.customer?.company
							: `${cart?.customer?.firstName} ${cart?.customer?.lastName ?? ""}`
						: ""}
				</M.Text>
				<M.Text size={16} color="#5E6470">
					{cart?.customer ? cart?.customer?.email : ""}
				</M.Text>
				<M.Text size={16} color="#5E6470">
					{cart?.customer?.phoneNumber}
				</M.Text>
				<M.Text size={16} color="#5E6470"></M.Text>
			</M.Stack>

			<M.Table mb={40}>
				<thead className="border !border-x-0 !border-y-[#D7DAE0] p-5">
					<tr>
						<th className="!p-4 !text-center !text-[11.2px] !font-semibold !text-[#5E6470]">
							Image
						</th>
						<th className="!p-4 !text-center !text-[11.2px] !font-semibold !text-[#5E6470]">
							SKU
						</th>
						<th className="!p-4 !text-center !text-[11.2px] !font-semibold !text-[#5E6470]">
							ITEM DETAIL
						</th>
						<th className="!p-4 !text-center !text-[11.2px] !font-semibold !text-[#5E6470]">
							QTY
						</th>
						<th className="!p-4 !text-center !text-[11.2px] !font-semibold !text-[#5E6470]">
							REGULAR PRICE
						</th>
						<th className="!p-4 !text-center !text-[11.2px] !font-semibold !text-[#5E6470]">
							DISCOUNT
						</th>
						<th className="!p-4 !text-center !text-[11.2px] !font-semibold !text-[#5E6470]">
							PRICE WITH DISCOUNT
						</th>
						<th className="!p-4 !text-center !text-[11.2px] !font-semibold !text-[#5E6470]">
							AMOUNT
						</th>
					</tr>
				</thead>
				<tbody className="border !border-x-0 !border-y-[#D7DAE0]">
					{cart?.cartItemSku?.map((p, i) => {
						const unitPriceAfterDiscount = applyDiscountV2(
							p.itemSku.price,
							p.discount,
						)

						const subtotal = unitPriceAfterDiscount.times(p.quantity)

						return (
							<tr key={i}>
								<td className="!border-none !p-4 text-center !text-xs !text-[#1A1C21]">
									<img
										src={p?.itemSku.images[0] ?? LOGO_URL}
										alt={p?.itemSku?.title}
										width={50}
										height={50}
									/>
								</td>
								<td className="!border-none !p-4 text-center !text-xs !text-[#1A1C21]">
									{p?.itemSku.sku}
								</td>
								<td className="!border-none !p-4 text-center !text-xs !text-[#1A1C21]">
									{p?.itemSku.title}
								</td>
								<td className="!border-none !p-4 text-center !text-xs !text-[#1A1C21]">
									{p?.quantity}
								</td>
								<td className="!border-none !p-4 text-center !text-xs !text-[#1A1C21]">
									${p?.itemSku?.price?.toNumber()?.toFixed(2)}
								</td>
								<td className="!border-none !p-4 text-center !text-xs !text-[#1A1C21]">
									{`${
										p?.discount
											? p?.discount?.type === "PERCENTAGE"
												? `${p?.discount?.amount?.toNumber()?.toFixed(2)}%`
												: `$${p?.discount?.amount?.toNumber()?.toFixed(2)}`
											: ""
									}`}
								</td>
								<td className="!border-none !p-4 text-center !text-xs !text-[#1A1C21]">
									${unitPriceAfterDiscount.toFixed(2)}
								</td>
								<td className="!border-none !p-4 text-center !text-xs !text-[#1A1C21]">
									${subtotal.toFixed(2)}
								</td>
							</tr>
						)
					})}
				</tbody>
			</M.Table>

			<M.Box className="h-auto w-full rounded-md bg-[#F9F9F9]">
				<M.Group position="right" className="p-5">
					<M.Box className="w-1/4">
						<M.Text size={14} color="#5E6470">
							Subtotal
						</M.Text>
						<M.Text size={14} color="#5E6470">
							Discount
						</M.Text>
						<M.Text size={14} color="#5E6470">
							Tax
						</M.Text>
						<M.Text size={14} color="#5E6470">
							Shipping
						</M.Text>
						<M.Divider my={14} color="#D7DAE0" />
						<M.Text size={14} color="#5E6470" weight={600}>
							Total
						</M.Text>
					</M.Box>
					<M.Box className="text-right">
						<M.Text size={14} color="#5E6470">
							${subtotal.toFixed(2)}
						</M.Text>
						<M.Text size={14} color="#5E6470">
							$0.00
						</M.Text>
						<M.Text size={14} color="#5E6470">
							{cart?.customer?.taxable ? `$${tax.toFixed(2)}` : "$0.00"}{" "}
						</M.Text>
						<M.Text size={14} color="#5E6470">
							$0.00
						</M.Text>
						<M.Divider my={14} color="#D7DAE0" />
						<M.Text size={14} color="#5E6470" weight={600}>
							${total.toFixed(2)}
						</M.Text>
					</M.Box>
				</M.Group>
			</M.Box>

			{cart?.note && (
				<M.Stack className="mt-5" spacing="xs">
					<M.Text size={14} color="#1A1C21" weight={600}>
						Note:
					</M.Text>
					<M.Text size={14} color="#5E6470">
						{cart?.note}
					</M.Text>
				</M.Stack>
			)}
		</M.Box>
	)
}
