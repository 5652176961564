import { PersonOutlineIcon } from "#/components-ng/index.js";
import { userDiscountsAtom } from "#/components/Form/complex/User/DiscountsNewCustomers.js";
import CreateUserForm from "#/components/Form/complex/User/index.js";
import SceneLayout from "#/components/SceneLayout/index.jsx";
import { Form, RouterPrompt } from "#/components/index.js";
import { useAuth } from "#/context/AuthContext.js";
import { trpc } from "#/trpc.js";
import { reportUserError, reportUserSuccess } from "#/util/index.js";
import * as C from "@chakra-ui/react";
import * as M from "@mantine/core";
import dayjs from "dayjs";
import { useAtom } from "jotai";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

export default function CreateUser() {
  const [auth] = useAuth();
  const navigate = useNavigate();
  const ctx = trpc.useContext();
  const [isAddressRequired, setIsAddressRequired] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);
  const [userDiscounts, setUserDiscounts] = useAtom(userDiscountsAtom);

  const { data: rolesCustomer } = trpc.role.getByType.useQuery(
    {
      type: "CUSTOMER",
    },
    {
      onError: (err) => {
        reportUserError({
          title: "Failed to load roles",
          message: err.message,
        });
      },
    }
  );

  const rolesData = React.useMemo(() => {
    return (
      rolesCustomer?.map((r) => ({
        label: r.name,
        value: r.id as unknown as string,
      })) ?? []
    );
  }, [rolesCustomer]);

  const { mutate, isLoading } = trpc.user.signupPos.useMutation({
    onSuccess() {
      reportUserSuccess({
        title: "Customer created successfully",
        message: <span>Customer created successfully</span>,
      });
      setUserDiscounts([]);
      ctx.user.invalidate();
      navigate(`/customers/list`);
    },
    onError(err) {
      reportUserError({
        title: "Failed to create customer",
        message: err.message,
      });
    },
  });

  const handleSubmit = React.useCallback(
    (f) => {
      setSubmitted(true);
      mutate({
        firstName: f.firstName,
        lastName: f.lastName,
        email: f.email,
        phoneNumber: f.phoneNumber,
        areaCode: f.areaCode,
        countryCode: f.countryCode,
        roleId: rolesData[0].value as unknown as number,
        birthDate:
          f.birthDate && dayjs(f.birthDate).isValid()
            ? dayjs(f.birthDate).toDate()
            : undefined,
        street: isAddressRequired ? f.address?.address : undefined,
        city: isAddressRequired ? f.address?.city : undefined,
        state: isAddressRequired ? f.address?.state : undefined,
        zip: isAddressRequired ? f.address?.zip : undefined,
        country: isAddressRequired ? f.address?.country : undefined,
        apt: isAddressRequired ? f.address?.apt : undefined,
        poBox: isAddressRequired ? f.address?.poBox : undefined,
        filialId: auth.auth?.user?.filialId ?? null,
        verified: f.verified,
        taxable: f.taxable,
        note: f.note,
        company: f.company ?? null,
        nickName: f.nickName ?? null,
        avatar: f.avatar ?? null,
        taxDocument: f.taxDocument ?? null,
        ein: f.ein ?? null,
        userDiscounts: userDiscounts.map((d) => ({
          discountId: d.discountId,
          updatedById: auth.auth?.user?.id ?? 1,
        })),
        bio: f.bio ?? null,
        preferredSalesAssociatedId: f.preferredSalesAssociatedId,
      });
    },
    [
      auth.auth?.user?.filialId,
      auth.auth?.user?.id,
      isAddressRequired,
      mutate,
      rolesData,
      userDiscounts,
    ]
  );

  return (
    <SceneLayout icon={<PersonOutlineIcon />}>
      <RouterPrompt when={!submitted} />
      <C.Container maxW="container.xl">
        <C.Heading as="h2" my={5}>
          Create customer
        </C.Heading>
        <C.Box bg="white" borderRadius="md" p={5}>
          {/* @ts-ignore */}
          <Form onSubmit={handleSubmit} w="100%">
            {/* @ts-ignore */}
            <CreateUserForm
              onAddressRequiredChange={setIsAddressRequired}
              isDiscountsTabDisabledNewCustomers={false}
              customerButtons={
                <>
                  <M.Button
                    component={Link}
                    to="/customers/list"
                    color="darkGray.0"
                    sx={(t) => ({ color: t.colors.gray[8] })}
                  >
                    Cancel
                  </M.Button>
                  <M.Button
                    type="submit"
                    sx={{ width: "min(15ch, 70%)" }}
                    loading={isLoading}
                  >
                    Save
                  </M.Button>
                </>
              }
              isCustomer={true}
            />
          </Form>
        </C.Box>
      </C.Container>
    </SceneLayout>
  );
}
