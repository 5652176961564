import { cartV2ToTicket } from "#/modules/ticket/print"
import * as M from "@mantine/core"
import dayjs from "dayjs"
import logoSrc from "#/images/monochromatic-logo-ticket.png"
import { match } from "ts-pattern"

export const TicketPreview = ({ cart, filialId }) => {
	const ticket = cartV2ToTicket(cart, filialId ?? 1)

	let changeString = `$${ticket.change.toFixed(2)}`
	let shippingString = ticket.shipping ? `$${ticket.shipping.toFixed(2)}` : ""
	let subtotalString = `$${ticket.subtotal.toFixed(2)}`
	let taxString = `+$${ticket.taxAmount.toFixed(2)}`
	let totalString = `$${ticket.total.toFixed(2)}`
	const maxLength = Math.max(
		subtotalString.length,
		taxString.length,
		totalString.length,
		changeString.length,
		shippingString.length,
	)
	changeString = changeString.padStart(maxLength, " ")
	shippingString = shippingString.padStart(maxLength, " ")
	subtotalString = subtotalString.padStart(maxLength, " ")
	taxString = taxString.padStart(maxLength, " ")
	totalString = totalString.padStart(maxLength, " ")

	const orderType = match(ticket.orderType)
		.with("IN_STORE", () => "Store")
		.with("ONLINE", () => "Delivery")
		.with("PICKUP", () => "Pickup")
		.otherwise(() => "Store")

	return (
		<M.Stack spacing={2} sx={{ "& *": { fontFamily: "monospace !important" } }}>
			<M.Group position="apart">
				<M.Text>Date: {dayjs().format("MM/DD/YYYY")}</M.Text>
				<M.Text>Order: {ticket.order}</M.Text>
			</M.Group>
			<M.Group position="apart">
				<M.Text>Store: {ticket.store}</M.Text>
				<M.Text>Workstation: {ticket.workstation}</M.Text>
			</M.Group>
			<M.Stack align="center" spacing={2}>
				<M.Text>REPRINTED</M.Text>
				<M.Image src={logoSrc} width={240} />
				<M.Text>GOLD TREE</M.Text>
				<M.Text>755 NW 72nd AVE PLAZA 33</M.Text>
				<M.Text>MIAMI, FL, 33126</M.Text>
				<M.Text>TEL +1 (786) 7177131</M.Text>
			</M.Stack>
			<M.Text weight="bold" mt="md">
				Bill to: {ticket.customer}
			</M.Text>
			{ticket.cashier && (
				<M.Text weight="bold">Cashier: {ticket.cashier}</M.Text>
			)}
			<M.Text weight="bold">Type: {orderType}</M.Text>
			<M.Text underline component="pre" mt="md">
				{"Item #".padEnd(16)}
				{"".padEnd(1)}
				{"Qty".padEnd(3)}
				{"".padEnd(3)}
				{"D%".padEnd(3)}
				{"".padEnd(3)}
				{"Price".padEnd(8)}
				{"".padEnd(3)}
				{"Total".padEnd(8)}
			</M.Text>
			{ticket.products.map((p, i) => {
				const itemCell = `${p.sku} ${p.title}`
				return (
					<M.Stack spacing={0} key={i}>
						<M.Text component="pre">
							{itemCell.substring(0, 17).padEnd(16)}
							{"".padEnd(1)}
							{p.qty.toString().padStart(3)}
							{"".padEnd(3)}
							{(p.discount ? p.discount + "%" : "").padEnd(3)}
							{"".padEnd(3)}
							{`$${p.price.toFixed(2)}`.padEnd(8)}
							{"".padEnd(3)}
							{`$${p.total.toFixed(2)}`.padEnd(8)}
						</M.Text>
						<M.Text component="pre">
							{itemCell.substring(17, 34).padEnd(16)}
							{"".padEnd(1)}
							{"".padStart(3)}
							{"".padEnd(3)}
							{"".padEnd(3)}
							{"".padEnd(3)}
							{(p.discount
								? `-$${(p.price * (p.discount / 100.0)).toFixed(2)}`
								: ""
							).padEnd(8)}
							{"".padEnd(3)}
							{"".padEnd(8)}
						</M.Text>
					</M.Stack>
				)
			})}
			<M.Stack spacing={2} align="end" mt="md">
				<M.Text>Change: {changeString}</M.Text>
				{ticket.shipping && `Shipping: ${shippingString}`}
				<M.Text>Subtotal: {subtotalString}</M.Text>
				<M.Text>
					Local sales tax ${ticket.taxPercentage.toString()}% Tax: ${taxString}
				</M.Text>
				<M.Text weight="bold">RECEIPT TOTAL: {totalString}</M.Text>
			</M.Stack>
			{ticket.payments.map((payment, i) => (
				<M.Text key={i}>
					{"  "}
					{payment.method}: ${payment.amount}
				</M.Text>
			))}
			<M.Stack spacing={2} align="end">
				{ticket.saleDiscount && (
					<M.Text>
						{"  "}Sale discount:
						{ticket.saleDiscount?.type === "AMOUNT" ? "$" : ""}
						{ticket.saleDiscount?.amount.toFixed(2)}
						{ticket.saleDiscount?.type === "PERCENTAGE" ? "%" : ""}
					</M.Text>
				)}
				<M.Text>
					Total sales discounts: ${ticket.totalDiscount.toFixed(2)}
				</M.Text>
			</M.Stack>
			{ticket?.note && (
				<M.Stack spacing={2} align="left" mt="md">
					<M.Text>Note: {ticket.note}</M.Text>
				</M.Stack>
			)}
			<M.Stack spacing={2} align="center" mt="md">
				<M.Text>Thanks for shopping with us!</M.Text>
				<M.Text>All sales are final</M.Text>
				<M.Text>No exchanges. No returns</M.Text>
			</M.Stack>
		</M.Stack>
	)
}
