import { styled } from "#/css/jsx";
import { RouterOutputs } from "#/trpc";
import { groupBy } from "lodash";
import * as M from "@mantine/core";
import { getDiscountLabel } from "#/util/discounts";
import { subtotalAtom } from "./state/cost-breakdown";
import { token } from "#/css/tokens";

export interface SeasonalDiscountsGoalsProps {
  seasonalDiscounts: RouterOutputs["v2_5"]["discounts"]["getSeasonalDiscounts"];
}

export function SeasonalDiscountsGoals(props: SeasonalDiscountsGoalsProps) {
  const { seasonalDiscounts } = props;

  const seasonalDiscountsByRef = groupBy(seasonalDiscounts, "reference");

  const colors = [
    {
      primary: token.var("colors.brand.500"),
      secondary: token.var("colors.brand.200"),
      ternary: token.var("colors.brand.50"),
    },
    {
      primary: token.var("colors.red.500"),
      secondary: token.var("colors.red.200"),
      ternary: token.var("colors.red.50"),
    },
    {
      primary: token.var("colors.green.500"),
      secondary: token.var("colors.green.200"),
      ternary: token.var("colors.green.50"),
    },
  ];

  const seasonalDiscountsByRefEntries = Object.entries(seasonalDiscountsByRef);

  return (
    <styled.div display="flex" flexDir="column" rowGap="4">
      {seasonalDiscountsByRefEntries.flatMap(([ref, discountRates], i) => (
        <>
          <SeasonalDiscountGoal
            key={ref}
            seasonalDiscountRates={discountRates}
            colors={colors[i % colors.length]}
          />
          {i < seasonalDiscountsByRefEntries.length - 1 && (
            <styled.hr borderColor="slate.100" my="1" />
          )}
        </>
      ))}
    </styled.div>
  );
}

export interface SeasonalDiscountGoalProps {
  seasonalDiscountRates: RouterOutputs["v2_5"]["discounts"]["getSeasonalDiscounts"];
  colors: {
    primary: string;
    secondary: string;
    ternary: string;
  };
}

export function SeasonalDiscountGoal(props: SeasonalDiscountGoalProps) {
  const { seasonalDiscountRates, colors } = props;

  // Sorted in descending order. We later render them in ascending order by
  // reversing the flex container.
  const sortedRates = seasonalDiscountRates.sort(
    (a, b) =>
      (b.minPurchaseForEligibility?.toNumber() ?? 0) -
      (a.minPurchaseForEligibility?.toNumber() ?? 0),
  );

  const subtotal = useAtomValue(subtotalAtom);

  const lastEligibleDiscountIndex = sortedRates.findIndex((d) =>
    subtotal.gte(d.minPurchaseForEligibility ?? 0),
  );

  // Reverse the index because we render the discounts in ascending order
  const nextEligibleDiscountReversedIndex =
    sortedRates.length - 1 - lastEligibleDiscountIndex;

  // Make sure we don't divide by zero
  const progressBarDivisor = sortedRates.length - 1 || 1;

  let progressBarWidth =
    (nextEligibleDiscountReversedIndex / progressBarDivisor) * 100;

  if (lastEligibleDiscountIndex === 0 && sortedRates.length === 1) {
    progressBarWidth = 100;
  }

  if (lastEligibleDiscountIndex === -1) {
    progressBarWidth = 0;
  }

  // Up to this point, we've calculated the progress bar width to be on the
  // current met goal. Now we need to calculate the progress between the current
  // goal and the next goal.
  //
  // Lets say that we have three goals: 20, 40 and 100 and the subtotal is 75.
  // Up to this point, the bar width is on the 50 goal. Now we need to calculate
  // the width to be around where the 75 would be.
  //
  // To calculate the width between goals, we need to calculate:
  // 1. The distance between the current goal and the next goal. In our example,
  //    the distance between 40 and 100 is 60.
  // 2. The distance between the subtotal and the current goal. In our example,
  //    the distance between 40 and 75 is 35.
  // 3. The progress between the current goal and the next goal as a fraction.
  //    In our example, the progress is given by 35 / 60 = 0.5833. This
  //    tells us that we are 58.33% of the way between the current goal and the
  //    next goal.
  // 4. The number of sections in the progress bar. In our example, we have 2
  //    sections because one section is between the first goal and the second
  //    goal, and the other section is between the second goal and the last
  //    goal.
  // 5. Get the progress between the current goal and the next goal as a
  //    fraction of the whole progress bar. In our example, the progress is
  //    given by 0.5833 / 2 = 0.2917. This tells us that 58.33% of the way
  //    between the current goal and the next goal is 29.17% of the whole
  //    progress bar.
  // 6. Add the progress to the current progress bar width.

  let distanceBetweenSubtotalAndCurrentGoal = 0;
  let distanceBetweenGoals = 0;
  if (lastEligibleDiscountIndex !== -1) {
    const currentGoal =
      sortedRates[
        lastEligibleDiscountIndex
      ].minPurchaseForEligibility?.toNumber() ?? 0;

    distanceBetweenSubtotalAndCurrentGoal = subtotal.toNumber() - currentGoal;

    const nextGoal =
      sortedRates[
        lastEligibleDiscountIndex - 1
      ]?.minPurchaseForEligibility?.toNumber();

    if (nextGoal != null) {
      distanceBetweenGoals = nextGoal - currentGoal;
    }

    const barSections = sortedRates.length - 1;
    if (distanceBetweenGoals > 0) {
      const nextGoalProgress =
        distanceBetweenSubtotalAndCurrentGoal / distanceBetweenGoals;

      progressBarWidth += (nextGoalProgress / barSections) * 100;
    }
  } else if (sortedRates.length === 1) {
    distanceBetweenSubtotalAndCurrentGoal = subtotal.toNumber();
    distanceBetweenGoals = sortedRates[0].minPurchaseForEligibility!.toNumber();

    progressBarWidth =
      (distanceBetweenSubtotalAndCurrentGoal / distanceBetweenGoals) * 100;
  }

  return (
    <div>
      <styled.p mb="2" color="slate.500">
        {sortedRates[0].reference}
      </styled.p>
      <styled.div pos="relative" display="flex" alignItems="center" w="full">
        {/* Progress bar background */}
        <styled.div
          w="full"
          h="2"
          pos="absolute"
          rounded="md"
          style={{
            background: colors.ternary,
          }}
        />

        {/* Progress bar */}
        <styled.div
          w={`calc(var(--progress-bar-width) * 1%)`}
          h="2"
          pos="absolute"
          rounded="md"
          transition="width 200ms ease-out"
          style={{
            background: colors.primary,
            "--progress-bar-width": progressBarWidth,
          }}
        />

        {/* Goals container */}
        <styled.div
          w="full"
          display="flex"
          justifyContent="space-between"
          flexDir="row-reverse"
          pos="relative"
          px="2"
        >
          {sortedRates.map((rate) => (
            <M.HoverCard key={rate.id}>
              <M.HoverCard.Target>
                <styled.div
                  w="3.5"
                  h="3.5"
                  rounded="full"
                  display="inline-block"
                  style={{
                    outline: `solid 3px ${colors.secondary}`,
                    background: colors.primary,
                  }}
                />
              </M.HoverCard.Target>
              <M.HoverCard.Dropdown>
                {getDiscountLabel(rate)}
              </M.HoverCard.Dropdown>
            </M.HoverCard>
          ))}
        </styled.div>
      </styled.div>
      <styled.div
        w="full"
        display="flex"
        justifyContent="space-between"
        flexDir="row-reverse"
        px="2"
        mt="2"
      >
        {sortedRates.map((rate) => (
          <styled.span key={rate.id} fontSize="sm" color="slate.400">
            ${rate.minPurchaseForEligibility?.toFixed(2)}
          </styled.span>
        ))}
      </styled.div>
    </div>
  );
}
