import { Table } from "@mantine/core";
import { nanoid } from "nanoid";

interface CardTableProps {
  headers: string[];
  rows: string[][] | number[][];
}

export const CardTable = ({ headers, rows }: CardTableProps) => {
  return (
    <Table align="center">
      <thead>
        <tr>
          {headers.map((header) => (
            <th key={`header-${header}`}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row, index) => (
          <tr key={`row-${index}-${nanoid()}`}>
            {row.map((cell) => (
              <td key={`cell-${cell}-${nanoid()}`}>{cell}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
