// @ts-nocheck
import { ImageUpload, UseS3UploadResult } from "#/components-ng/ui";
import { Textarea } from "#/components-ng/ui/index.js";
import DateRange from "#/components/DateRange/index.jsx";
import { FormControl, Field, Input } from "#/components/Form/v2/index.js";
import { RouterPrompt } from "#/components/index.js";
import { epochToDate } from "#/util/date.js";
import * as C from "@chakra-ui/react";
import { Carousel } from "@mantine/carousel";
import * as M from "@mantine/core";
import React from "react";
import {
  useForm,
  FormProvider,
  useFormContext,
  useWatch,
} from "react-hook-form";
import {
  MdOutlineCheckCircle,
  MdMoreVert,
  MdChevronRight,
  MdChevronLeft,
} from "react-icons/md/index.js";

export default function SeasonForm({ defaultValues, onSubmit, isLoading }) {
  const formDefaultValues = defaultValues;

  const formMethods = useForm({
    defaultValues: formDefaultValues,
  });

  const [date, setDate] = React.useState([
    {
      startDate:
        (defaultValues?.initialDate &&
          epochToDate(defaultValues?.initialDate)) ||
        new Date(),
      endDate:
        (defaultValues?.endDate && epochToDate(defaultValues?.endDate)) ||
        new Date(),
      key: "selection",
    },
  ]);

  const handleDateRangeOnChange = (item) => {
    formMethods.setValue("initialDate", item.selection.startDate);
    formMethods.setValue("endDate", item.selection.endDate);
    setDate([item.selection]);
  };

  const [submitted, setSubmitted] = React.useState(false);
  const handlePreSubmit = React.useCallback(
    (f) => {
      setSubmitted(true);
      onSubmit({
        ...f,
        image: "https://picsum.photos/200/300",
      });
    },
    [onSubmit]
  );

  React.useEffect(() => {
    formMethods.register("initialDate", {
      required: true,
      value: defaultValues?.initialDate || new Date().valueOf().toString(),
    });
    formMethods.register("endDate", {
      required: true,
      value: defaultValues?.initialDate || new Date().valueOf().toString(),
    });
    formMethods.register("current", {
      required: false,
      value: defaultValues?.current || false,
    });
    // eslint-disable-next-line
  }, []);

  return (
    <form onSubmit={formMethods.handleSubmit(handlePreSubmit)}>
      <RouterPrompt when={!submitted} />
      <C.Center>
        <C.VStack spacing={6} align="start" maxW="container.lg">
          <C.Heading as="h1" size="md">
            Season
          </C.Heading>
          <FormProvider {...formMethods}>
            <M.Tabs
              defaultValue="information"
              styles={{
                panel: { marginTop: 24 },
              }}
              sx={(t) => ({
                "& button[data-active] > *": { color: t.colors.brand[5] },
              })}
            >
              <M.Tabs.List>
                <M.Tabs.Tab value="information">Information</M.Tabs.Tab>
                <M.Tabs.Tab value="banners">Banners</M.Tabs.Tab>
              </M.Tabs.List>
              <M.Tabs.Panel value="information">
                <C.VStack spacing={8} w="100%">
                  <C.HStack spacing={6} w="100%" align="start">
                    <div className="grid gap-y-4">
                      <FormControl label="Season*" name="season" flex={1}>
                        <Field as={Input} required />
                      </FormControl>
                      <M.Checkbox
                        label="Current"
                        name="current"
                        defaultChecked={defaultValues?.current}
                        onChange={(e) =>
                          formMethods.setValue(
                            "current",
                            e.currentTarget.checked
                          )
                        }
                      />
                    </div>
                    <FormControl
                      label="Description*"
                      name="description"
                      flex={1}
                    >
                      <Field as={Textarea} className="bg-white" required />
                    </FormControl>
                  </C.HStack>
                  <C.Flex w="100%" justify="center">
                    <DateRange
                      onChange={handleDateRangeOnChange}
                      ranges={date}
                      months={2}
                      direction="horizontal"
                    />
                  </C.Flex>
                  <FormControl
                    label="Meta description"
                    name="metaDescription"
                    flex={1}
                  >
                    <Field as={Input} className="bg-white" />
                  </FormControl>
                </C.VStack>
              </M.Tabs.Panel>
              <M.Tabs.Panel value="banners">
                <Banners />
              </M.Tabs.Panel>
            </M.Tabs>
            <C.Divider />
            <C.Button type="submit" w="100%" isLoading={isLoading}>
              Submit
            </C.Button>
          </FormProvider>
        </C.VStack>
      </C.Center>
    </form>
  );
}

const Banners = (props) => {
  const { control, setValue } = useFormContext();

  const banners = useWatch({
    name: "banner",
    control,
  });

  const [slidesOpened, setSlidesOpened] = React.useState(false);
  const [initialSlide, setInitialSlide] = React.useState(null);

  return (
    <M.Stack {...props}>
      {slidesOpened && (
        <BannerSlides
          key={initialSlide}
          initialSlide={initialSlide}
          opened={slidesOpened}
          onClose={() => setSlidesOpened(false)}
        />
      )}
      <BannerField />
      <M.SimpleGrid mt="lg" cols={3} spacing={32}>
        {banners?.map((banner, index) => (
          <M.Image
            key={index}
            src={banner.url}
            fit="cover"
            styles={{
              image: { aspectRatio: "7/5", borderRadius: 3, cursor: "zoom-in" },
              caption: { textAlign: "left" },
            }}
            onClick={() => {
              setInitialSlide(index);
              setSlidesOpened(true);
            }}
            caption={
              <ImageActions
                image={banner}
                onSetDefault={() => {
                  setValue(
                    `banner`,
                    banners.map((image, i) => ({
                      ...image,
                      default: i === index,
                    }))
                  );
                }}
                onDelete={() => {
                  setValue(
                    "banner",
                    banners.filter((_, i) => i !== index)
                  );
                }}
              />
            }
          />
        ))}
      </M.SimpleGrid>
    </M.Stack>
  );
};

const BannerField = () => {
  const { getValues, setValue } = useFormContext();

  const handleS3UploadImageSuccess = (result: UseS3UploadResult) => {
    const banners = getValues("banner");

    setValue(
      `banner`,
      banners
        ? [...banners, { url: result.url, default: false }]
        : [{ url: result.url, default: false }]
    );
  };

  return (
    <ImageUpload
      directory="seasons/optimized"
      supports={{
        image: true,
      }}
      onS3UploadSuccess={handleS3UploadImageSuccess}
      hiddenVisually={true}
    />
  );
};

const ImageActions = ({ image, onSetDefault, onDelete }) => {
  return (
    <M.Group position="right" noWrap onClick={(e) => e.stopPropagation()}>
      <M.Group spacing="xs">
        {image.default && <MdOutlineCheckCircle />}
        <M.Menu withinPortal zIndex={9999}>
          <M.Menu.Target>
            <M.ActionIcon
              sx={{
                "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.25)" },
              }}
            >
              <MdMoreVert size={18} />
            </M.ActionIcon>
          </M.Menu.Target>
          <M.Menu.Dropdown>
            <M.Menu.Item onClick={onSetDefault}>Set as default</M.Menu.Item>
            <M.Menu.Item onClick={onDelete}>Delete</M.Menu.Item>
          </M.Menu.Dropdown>
        </M.Menu>
      </M.Group>
    </M.Group>
  );
};

const BannerSlides = ({ initialSlide, ...props }) => {
  const { control, setValue } = useFormContext();

  const banners = useWatch({
    name: "banner",
    control,
  });

  return (
    <M.Modal
      {...props}
      centered
      withCloseButton={false}
      size="xl"
      styles={{
        modal: { background: "transparent", boxShadow: "none" },
      }}
    >
      <Carousel
        withControls
        nextControlIcon={<MdChevronRight size={64} />}
        previousControlIcon={<MdChevronLeft size={64} />}
        initialSlide={initialSlide}
        slideSize="100%"
        styles={{
          control: {
            background: "transparent",
            border: "none",
            color: "white",
            boxShadow: "none",
          },
          controls: {
            width: "140%",
            transform: "translateX(-50%)",
            left: "50%",
          },
        }}
      >
        {banners?.map((banner, index) => (
          <Carousel.Slide key={index}>
            <M.Image
              src={banner.url}
              width={800}
              height={600}
              fit="contain"
              caption={
                <ImageActions
                  image={banner}
                  onSetDefault={() => {
                    setValue(
                      `banner`,
                      banners.map((image, j) => {
                        return { ...banner, default: index === j };
                      })
                    );
                  }}
                  onDelete={() => {
                    setValue(
                      "banner",
                      banners.filter((banner, j) => index !== j)
                    );
                  }}
                />
              }
              styles={{
                figure: { position: "relative" },
                caption: {
                  width: "100%",
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  background: "rgba(0, 0, 0, 0.25)",
                  color: "white",
                  padding: "0.5rem 1.5rem",
                  textAlign: "left",
                },
              }}
            />
          </Carousel.Slide>
        ))}
      </Carousel>
    </M.Modal>
  );
};
