import {
  Controller as OriginalController,
  ControllerProps as OriginalControllerProps,
  ControllerRenderProps as OriginalControllerRenderProps,
  ControllerFieldState as OriginalControllerFieldState,
  FieldValues,
  useFormContext,
  UseFormStateReturn,
  FieldPath,
  FieldPathValue,
  UnpackNestedValue,
} from "react-hook-form";

// All of these annotations were copied from `react-hook-form` type definitions.
// They were modified to allow minor improvements such as allow render children and
// passing `onChange` directly to the render prop.

// Special attention was given to the typing definitions of form components as the
// app consists mostly of forms.

// If they look complex to you and:
// a) You don't have to change them, leave them be.
// b) You have to change them:
//   1) Try to understand the current code and learn the features it uses such as
//      type indexing, default type parameters, the `Omit` type, union types, etc.
//   2) Simplify them. The current type definitions try to help you catch bugs in
//      100% of cases. Sometimes, just 90% is enough. We will never use an API in
//      all its permutations.
//      You can simplify them with simple types such as
//      `onChange: (value: any) => void`.

type RenderFunction<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = (props: {
  onChange: OriginalControllerRenderProps<TFieldValues, TName>["onChange"];
  value: UnpackNestedValue<FieldPathValue<TFieldValues, TName>>;
  field: OriginalControllerRenderProps<TFieldValues, TName>;
  fieldState: OriginalControllerFieldState;
  formState: UseFormStateReturn<TFieldValues>;
}) => React.ReactElement;

interface ControllerPropsWithChildren<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends Omit<OriginalControllerProps<TFieldValues, TName>, "render"> {
  children: RenderFunction<TFieldValues, TName>;
}

interface ControllerPropsWithRender<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends Omit<OriginalControllerProps<TFieldValues, TName>, "render"> {
  render: RenderFunction<TFieldValues, TName>;
}

type ControllerProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> =
  | ControllerPropsWithChildren<TFieldValues, TName>
  | ControllerPropsWithRender<TFieldValues, TName>;

/**
 *
 * Equivalent to react-hook-form's `Controller` but gets its `control` from the context.
 * @returns
 */
const Controller = <TFieldValues extends FieldValues>(
  props: ControllerProps<TFieldValues>
) => {
  const formCtx = useFormContext<TFieldValues>();
  const control = props?.control || formCtx?.control;

  if (!control) {
    return <span>Internal error</span>;
  }

  return (
    <OriginalController
      {...props}
      control={control}
      render={(fieldState) =>
        // @ts-ignore
        "children" in props
          ? props.children({
              onChange: fieldState.field.onChange,
              value: fieldState.field.value as any,
              ...fieldState,
            })
          : props.render({
              onChange: fieldState.field.onChange,
              value: fieldState.field.value as any,
              ...fieldState,
            })
      }
    />
  );
};

export default Controller;
