import { RouterOutputs, trpc } from "#/trpc";
import { reportUserError, reportUserSuccess } from "#/util";
import { VariantForm } from ".";
import {
  itemSkuCustomAttributesAtom,
  locationsAtom,
  showRoomTypes,
} from "../state";
import { FormValues } from "./types";
import { useAtom } from "jotai";
import { useNavigate, useParams } from "react-router";

export type VariantOuput = RouterOutputs["itemSku"]["pos"]["create"];
export type UpdateVariantFormProps = {
  onSuccess?: (data: VariantOuput) => void;
  redirectOnSuccess?: boolean;
  showTitle?: boolean;
  itemId?: number | null;
};
export const CreateVariant = (props: UpdateVariantFormProps) => {
  const [locationsToCreate, setLocationsToCreate] = useAtom(locationsAtom);
  const [showRoomType, setShowRoomType] = useAtom(showRoomTypes);
  const [customAttributesToCreate, setItemSkuCustomAttributesToCreate] =
    useAtom(itemSkuCustomAttributesAtom);
  const params = useParams();
  const navigate = useNavigate();
  const ctx = trpc.useContext();

  useEffect(() => {
    setLocationsToCreate([]);
    setItemSkuCustomAttributesToCreate([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { mutate: createItemSkuStocks } = trpc.itemSkuStock.create.useMutation({
    onError(err) {
      reportUserError({
        title: "Failed to create location",
        message: err.message,
      });
    },
  });

  const { mutate: createItemSkuCustomAttribute } =
    trpc.itemSkuCustomAttribute.private.create.useMutation({
      onError(err) {
        reportUserError({
          title: "Failed to create custom attribute",
          message: err.message,
        });
      },
    });

  const { mutate, isLoading } = trpc.itemSku.pos.create.useMutation({
    onError(err) {
      reportUserError({
        title: "Failed to create variant",
        message: err.message,
      });
    },
    onSuccess(data) {
      reportUserSuccess({
        title: "Variant created successfully",
        message: (
          <>
            Variant <span className="font-medium">{data.title}</span> has been
            created
          </>
        ),
      });

      for (const loc of locationsToCreate) {
        createItemSkuStocks({
          filialId: loc.filialId,
          itemSkuId: data.id,
          storeLocation: loc.storeLocation,
          warehouseLocation: loc.warehouseLocation,
          quantity: 0,
          reorder: loc.reorder,
          holdStock: 0,
          isLine: loc.isLine
        });
      }

      if (showRoomType === "CUSTOM") {
        for (const attr of customAttributesToCreate) {
          createItemSkuCustomAttribute({
            itemSkuId: data.id,
            customAttributeId: Number(attr),
          });
        }
      }

      setLocationsToCreate([]);
      setItemSkuCustomAttributesToCreate([]);
      setShowRoomType(null);
      ctx.itemSku.invalidate();
      props?.onSuccess?.(data);
      if (props.redirectOnSuccess ?? true) {
        navigate(`/inventory/item-list/edit/${data.itemId}`);
      }
    },
  });

  const handleSubmit = (values: FormValues) => {
    if (!values.sku || !showRoomType) {
      return reportUserError({
        title: "Failed to create variant",
        message: "Please fill all required fields",
      });
    }

    if (values.price === 0) {
      return reportUserError({
        title: "Failed to create variant",
        message: "Price cannot be 0",
      });
    }

    mutate({
      sku: values.sku!,
      title: values.title,
      presentationType: values.presentationType,
      presentationValue: values.presentationValue,
      price: values.price,
      unitPrice: values.unitPrice,
      cost: values.cost,
      showRoomType: showRoomType,
      weight: values.weight,
      width: values.width,
      height: values.height,
      length: values.length,
      size: values.size,
      available: values.available ?? false,
      images: values.images ?? undefined,
      defaultImage: values.defaultImage ?? "",
      defaultVideo: values.defaultVideo ?? "",
      vendor: values.vendor?.id ?? null,
      vendorCostType: values.vendorCostType ?? null,
      vendorAlu: values.vendorAlu ?? null,
      vendorCost: values.vendorCost ?? null,
      rateCostId: values.rateCostId ? Number(values.rateCostId) : null,
      vendorImage: values.vendorImage ?? null,
      itemId: props?.itemId ?? Number(params.id),
      minPurchase: values?.minPurchase ?? 1,
      note: values.note ?? "",
    });
  };

  return <VariantForm onSubmit={handleSubmit} isLoading={isLoading} />;
};
