import GTLoader from "#/components-ng/loader";
import { RouterOutputs, trpc } from "#/trpc";
import { reportUserError, reportUserSuccess } from "#/util";
import { VariantForm } from ".";
import { showRoomTypes } from "../state";
import { FormValues } from "./types";
import { LoadingOverlay } from "@mantine/core";
import { useAtom } from "jotai";
import { useNavigate, useParams } from "react-router";

export type VariantOuput = RouterOutputs["itemSku"]["pos"]["update"];

export type UpdateVariantFormProps = {
  itemSkuId?: number;
  onSuccess?: (data: VariantOuput) => void;
  redirectOnSuccess?: boolean;
  showTitle?: boolean;
};
export const UpdateVariant = (props: UpdateVariantFormProps) => {
  const params = useParams() as any;
  const navigate = useNavigate();
  const ctx = trpc.useContext();
  const [itemSkuId, setItemSkuId] = useState<number | null>(() =>
    params.itemSkuId ? Number(params.itemSkuId) : props?.itemSkuId ?? null
  );
  const [showRoomType, setShowRoomType] = useAtom(showRoomTypes);

  const { data, isLoading, isFetchedAfterMount } =
    trpc.itemSku.getById.useQuery(
      {
        id: itemSkuId!,
      },
      {
        enabled: !!itemSkuId,
        cacheTime: 0,
        onSuccess(data) {
          setShowRoomType(data.showRoomType);
        },
      }
    );

  const { mutate, isLoading: isLoadingUpdate } =
    trpc.itemSku.pos.update.useMutation({
      onError(err) {
        reportUserError({
          title: "Failed to update variant",
          message: err.message,
        });
      },
      onSuccess(data) {
        reportUserSuccess({
          title: "Variant updated successfully",
          message: (
            <>
              Variant <span className="font-medium">{data?.title}</span> has
              been updated
            </>
          ),
        });
        setShowRoomType(null);
        ctx.itemSku.invalidate();

        if (props.itemSkuId) {
          props.onSuccess?.(data);
        }

        if (props.redirectOnSuccess ?? true) {
          navigate(`/inventory/item-list/edit/${params.id}`);
        }
      },
    });

  const handleSubmit = (values: FormValues) => {
    if (!values.sku || !itemSkuId || !showRoomType) {
      return reportUserError({
        title: "Failed to update variant",
        message: "Please fill all required fields",
      });
    }

    if (values.price === 0) {
      return reportUserError({
        title: "Failed to create variant",
        message: "Price cannot be 0",
      });
    }

    mutate({
      id: itemSkuId!,
      input: {
        sku: values.sku!,
        title: values.title,
        presentationType: values.presentationType,
        presentationValue: values.presentationValue,
        price: values.price,
        unitPrice: values.unitPrice,
        cost: values.cost,
        weight: values.weight,
        width: values.width,
        height: values.height,
        length: values.length,
        size: values.size,
        available: values.available ?? false,
        images: values.images ?? undefined,
        defaultImage: values.defaultImage ?? "",
        defaultVideo: values.defaultVideo ?? "",
        vendor: values.vendor?.id ?? null,
        vendorCostType: values.vendorCostType ?? null,
        vendorAlu: values.vendorAlu ?? null,
        vendorCost: values.vendorCost ?? null,
        rateCostId: values.rateCostId ? Number(values.rateCostId) : null,
        vendorImage: values.vendorImage ?? null,
        minPurchase: values?.minPurchase ?? 1,
        note: values.note ?? "",
        showRoomType: showRoomType,
      },
    });
  };

  return isLoading || !isFetchedAfterMount || !data ? (
    <LoadingOverlay
      visible={isLoading}
      loader={<GTLoader width={100} height={100} />}
    />
  ) : (
    <VariantForm
      onSubmit={handleSubmit}
      isLoading={isLoadingUpdate}
      defaultValues={{
        sku: data.sku,
        vendor: {
          id: data.vendorId,
          company: data?.vendor?.company,
        },
        vendorAlu: data?.vendorAlu ?? null,
        vendorCost: data.vendorCost?.toNumber(),
        vendorCostType: data.vendorCostType,
        rateCostId: data.rateCostId?.toString(),
        presentationType: data.presentationType,
        presentationValue: data.presentationValue,
        title: data.title,
        defaultVideo: data.defaultVideo,
        defaultImage: data.defaultImage,
        images: data.images,
        note: data?.note ?? "",
        available: data.available,
        price: data.price.toNumber(),
        unitPrice: data.unitPrice.toNumber(),
        cost: data.cost.toNumber(),
        weight: data.weight ?? 0,
        width: data.width ?? 0,
        height: data.height ?? 0,
        length: data.length ?? 0,
        vendorImage: data.vendorImage ?? null,
        minPurchase: data.minPurchase.toNumber(),
        size: data.size
      }}
      itemSkuId={props?.itemSkuId}
    />
  );
};
