import { Button, makeController } from "#/components-ng/index.js";
import { zodResolver } from "@hookform/resolvers/zod";
import { DefaultValues, FormProvider, SubmitHandler } from "react-hook-form";
import { z } from "zod";

export const shippingInformationFormSchema = z.object({
  entries: z.array(
    z.object({
      trackingNumber: z.string(),
      company: z.string(),
      cost: z.coerce.number(),
      date: z.date(),
    })
  ),
});

export type ShippingInformationFormSchema = z.TypeOf<
  typeof shippingInformationFormSchema
>;

const C = makeController<ShippingInformationFormSchema>();

export type ShippingInformationDialogContentProps = {
  onSubmit: SubmitHandler<ShippingInformationFormSchema>;
  defaultValues?: DefaultValues<ShippingInformationFormSchema>;
};
export function ShippingInformationDialogContent(
  props: ShippingInformationDialogContentProps
) {
  const form = C.useForm({
    resolver: zodResolver(shippingInformationFormSchema),
    defaultValues: props.defaultValues ?? {
      entries: [
        {
          trackingNumber: "",
          company: "",
          cost: 0,
          date: new Date(),
        },
      ],
    },
  });
  const entries = C.useFieldArray({
    control: form.control,
    name: "entries",
  });

  return (
    <div>
      <FormProvider {...form}>
        <form>
          <div>
            <table className="w-full">
              <thead>
                <tr className="text-left [&>th:first-child]:pl-0 [&>th:last-child]:pr-0 [&>th]:border-b [&>th]:p-2 [&>th]:font-medium">
                  <th>Tracking #</th>
                  <th>Company</th>
                  <th>Cost</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {entries.fields.map((field, fieldIndex) => (
                  <tr
                    key={field.key}
                    className="[&>td:first-child]:pl-0 [&>td:last-child]:pr-0 [&>td]:p-2"
                  >
                    <td>
                      <C.InputField
                        type="text"
                        name={`entries.${fieldIndex}.trackingNumber`}
                      />
                    </td>
                    <td>
                      <C.InputField
                        type="text"
                        name={`entries.${fieldIndex}.company`}
                      />
                    </td>
                    <td>
                      <C.InputField
                        type="number"
                        name={`entries.${fieldIndex}.cost`}
                      />
                    </td>
                    <td>
                      <C.InputField
                        type="date"
                        name={`entries.${fieldIndex}.date`}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="mt-4 flex justify-end gap-x-4">
              <Button
                onClick={() =>
                  entries.append({
                    trackingNumber: "",
                    company: "",
                    cost: 0,
                    date: new Date(),
                  })
                }
              >
                Add shipping
              </Button>
              <Button
                variant="primary"
                type="submit"
                className="px-8"
                onClick={form.handleSubmit(props.onSubmit)}
              >
                Save
              </Button>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}
