import { MantineController as Mc } from "#/components/Form/v3/index.js";
import { useAuth } from "#/context/AuthContext.js";
import { trpc } from "#/trpc.js";
import { reportUserError } from "#/util/index.js";
import * as M from "@mantine/core";
import React from "react";

const typeAdjustmentOptions = [
  { label: "Stock", value: "STOCK" },
  { label: "Damaged", value: "DAMAGED", disabled: true },
  { label: "Cost", value: "COST", disabled: true },
];

const reasonOptions = [
  { label: "Quantity correction", value: "Quantity correction" },
  { label: "Damaged", value: "Damaged" },
  { label: "Gifts", value: "Gifts" },
  { label: "Disincorporate", value: "Disincorporate" },
  { label: "Samples for photos", value: "Samples for photos" },
];

export const GeneralInformation = () => {
  const [auth] = useAuth();

  const { data } = trpc.filial.getAll.useQuery(undefined, {
    onError: (err) => {
      reportUserError({
        title: "Failed to load filials",
        message: err.message,
      });
    },
  });

  const filialOptions = React.useMemo(
    () =>
      data?.map((filial) => ({
        label: filial.name,
        value: filial.id.toString(),
      })) ?? [],
    [data]
  );

  return (
    <M.Stack className="bg-white p-10">
      <M.Title order={1}>Inventory adjustment</M.Title>
      <M.Divider color="gray" className="mb-8 mt-4" />
      <M.Group noWrap grow align="end">
        <Mc
          wrap={M.Select}
          name="tagName"
          label="Reason"
          required
          placeholder="Enter tag name"
          classNames={{
            input: "rounded-lg",
          }}
          data={reasonOptions}
          zIndex={9998}
        />
        <Mc
          wrap={M.Select}
          name="filial"
          label="Filial"
          data={filialOptions}
          required
          zIndex={9998}
          disabled={auth.auth?.user?.filialId !== null}
          defaultValue={auth.auth?.user?.filialId?.toString() ?? ""}
          classNames={{
            input: "rounded-lg",
          }}
        />
        <Mc
          wrap={M.Select}
          name="reason"
          label="Type"
          data={typeAdjustmentOptions}
          required
          zIndex={9998}
          defaultValue="QtyAdjustment"
          placeholder="Select reason"
          classNames={{
            input: "rounded-lg",
          }}
        />
      </M.Group>
    </M.Stack>
  );
};
