import GTLoader from "#/components-ng/loader";
import * as M from "@mantine/core";

interface PickupDetailsDrawerProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  data?: {
    pickupFirstName: string | null;
    pickupLastName: string | null;
    pickupNote: string | null;
  } | null;
  isLoading?: boolean;
}

export const PickupDetailsDrawer = ({
  open,
  setOpen,
  data,
  isLoading,
}: PickupDetailsDrawerProps) => {
  return (
    <M.Drawer
      opened={open}
      onClose={() => setOpen(false)}
      size="lg"
      position="right"
      padding="md"
      zIndex={44}
    >
      {isLoading ? (
        <M.LoadingOverlay
          visible={isLoading}
          loader={<GTLoader width={100} height={100} />}
        />
      ) : (
        <div>
          <h3 className="font-medium">Pick up info</h3>
          <div className="grid gap-y-1">
            <div className="grid grid-cols-[auto_auto]">
              <p>First name</p>
              <p className="text-right">{data?.pickupFirstName}</p>
            </div>
            <div className="grid grid-cols-[auto_auto]">
              <p>Last name</p>
              <p className="text-right">{data?.pickupLastName}</p>
            </div>
            <div className="grid grid-cols-[auto_auto]">
              <p>Note</p>
              <p className="text-right">{data?.pickupNote}</p>
            </div>
          </div>
        </div>
      )}
    </M.Drawer>
  );
};
