import styled from "styled-components";

const StyledSceneLayout = styled.div`
  height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  overflow: auto;

  & > .title {
    display: flex;
    gap: 0.5em;
    padding: 1em 60px;
    color: ${({ activeIcon, theme }) =>
      activeIcon ? theme.primaryFont : theme.secondaryFont};
  }
`;

const BreadcrumbsWrapper = styled.div`
  display: flex;
  gap: 20px;
`;

const LinkWrapper = styled.div`
  font-size: 14px;
  position: relative;

  a {
    text-decoration: none;
  }

  &:not(:first-child):before {
    content: "ᐳ";
    font-size: 8px;
    position: absolute;
    top: 4px;
    left: -12px;
  }

  &:last-child {
    &:before,
    p {
      color: ${({ theme }) => theme.primaryFont};
      font-weight: bold;
    }
  }
`;

export { BreadcrumbsWrapper, LinkWrapper };
export default StyledSceneLayout;
