import DisplayTable from "#/components/Table/DisplayTable/index.jsx";
import { useAuth } from "#/context/AuthContext.js";
import { trpc, RouterOutputs } from "#/trpc.js";
import { reportUserError } from "#/util/index.js";
import * as M from "@mantine/core";
import { atom, useAtom, useAtomValue, useSetAtom } from "jotai";
import React from "react";
import DownIcon from "~icons/ion/chevron-down-outline";
import UpIcon from "~icons/ion/chevron-up-outline";
import MenuIcon from "~icons/ion/ellipsis-horizontal-outline";
import DeleteIcon from "~icons/ion/trash-outline";

const discountsAtom = atom<
  RouterOutputs["discount"]["getDiscountsGroupedByReference"]
>([]);

export const userDiscountsAtom = atom<
  {
    id: number;
    discountId: number;
    updatedBy: number;
    discount: RouterOutputs["discount"]["getById"];
  }[]
>([]);

export const DiscountsNewCustomers = () => {
  const setDiscounts = useSetAtom(discountsAtom);
  const [userDiscounts, setUserDiscounts] = useAtom(userDiscountsAtom);
  const [{ auth }] = useAuth()!;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => setUserDiscounts([]), []);

  const { data: discountsData } =
    trpc.discount.getDiscountsGroupedByReference.useQuery(undefined, {
      onError(error) {
        reportUserError({
          title: "Failed to get discounts",
          message: error.message,
        });
      },
      onSuccess(data) {
        setDiscounts(data);
      },
      staleTime: 0,
    });

  const selectDiscountsData = React.useMemo(
    () =>
      discountsData?.map((e) => ({
        label: e.reference,
        value: e.reference,
        discounts: e.discounts,
      })) ?? [],
    [discountsData]
  );

  const tableData = React.useMemo(
    () =>
      userDiscounts?.map((e) => ({
        ...e,
        updatedByFullName: `${auth?.user.firstName} ${
          auth?.user?.lastName ?? ""
        }`,
      })) ?? [],
    [auth?.user.firstName, auth?.user?.lastName, userDiscounts]
  );

  const handleDiscountSelect = (v: string) => {
    if (!v) return;
    const discount = selectDiscountsData.find((n) => n.value === v)!
      .discounts[0];

    if (userDiscounts.find((ud) => ud.discount?.id === discount.id)) {
      reportUserError({
        title: "User already has this discount",
        message: "User cannot have the same discount more than once",
      });
      return;
    }

    setUserDiscounts((prev) => [
      ...prev,
      {
        id: Math.floor(Math.random() * 1000000000),
        discountId: discount.id,
        updatedBy: auth!.user.id,
        discount,
      },
    ]);
  };

  return (
    <M.Stack>
      <M.Title order={3}>Discounts</M.Title>
      <M.Select
        data={selectDiscountsData}
        placeholder="Search discount..."
        searchable
        clearable
        zIndex={1500}
        w={400}
        sx={(t) => ({
          border: `solid 1px ${t.colors.cyan[3]}`,
          borderRadius: 10,
          overflow: "hidden",
        })}
        styles={(t) => ({
          input: {
            background: `${t.colors.cyan[0]}77`,
          },
        })}
        onChange={handleDiscountSelect}
      />
      <DisplayTable columns={columns} data={tableData} />{" "}
    </M.Stack>
  );
};

const columns = [
  {
    Header: "Actions",
    id: "actions",
    Cell: ({ row: { original } }) => {
      const setUserDiscounts = useSetAtom(userDiscountsAtom);
      return (
        <M.Menu>
          <M.Menu.Target>
            <M.ActionIcon>
              <MenuIcon />
            </M.ActionIcon>
          </M.Menu.Target>
          <M.Menu.Dropdown>
            <M.Menu.Item
              icon={<DeleteIcon />}
              onClick={() =>
                setUserDiscounts((prev) =>
                  prev.filter((e) => e.id !== original.id)
                )
              }
            >
              Delete
            </M.Menu.Item>
          </M.Menu.Dropdown>
        </M.Menu>
      );
    },
  },
  {
    Header: "Ref",
    accessor: "discount.reference",
    Cell: ({ value, row: { original } }) => {
      const [opened, setOpened] = React.useState(false);

      return (
        <M.Stack>
          <M.Group noWrap>
            <M.Text>{value}</M.Text>
            <M.ActionIcon onClick={() => setOpened((prev) => !prev)}>
              {opened ? <UpIcon /> : <DownIcon />}
            </M.ActionIcon>
          </M.Group>
          <M.Text
            size="sm"
            color="gray.7"
            maw={400}
            sx={{ display: opened ? "block" : "none" }}
          >
            {original.discount.departmentDiscounts
              .map((d: any) => d.department.name)
              .join(", ")}
          </M.Text>
        </M.Stack>
      );
    },
  },
  {
    Header: "Type",
    accessor: "discount.type",
    Cell: ({ value }) => {
      return value === "PERCENTAGE" ? "%" : value === "AMOUNT" ? "$" : value;
    },
  },
  {
    Header: "Amount",
    accessor: "discount.amount",
    isNumeric: true,
    Cell: ({ row: { original } }) => {
      // We store the id of the current discount so that when we change the discount,
      // we can show immediately even if the backend has not responded yet!
      const [localId, setLocalId] = React.useState(
        original.discount.id.toString()
      );
      React.useEffect(() => {
        // Sync with the data
        setLocalId(original.discount.id.toString());
      }, [original.discount.id]);

      const setUserDiscounts = useSetAtom(userDiscountsAtom);
      const discounts = useAtomValue(discountsAtom);

      const selectData = React.useMemo(
        () =>
          discounts
            .find((d) => d.reference === original.discount.reference)
            ?.discounts?.map((d) => ({
              label: d.type === "PERCENTAGE" ? `${d.amount}%` : `$${d.amount}`,
              value: d.id.toString(),
              discount: d,
            })) ?? [],
        [discounts, original.discount.reference]
      );

      return (
        <M.Select
          data={selectData}
          value={localId}
          onChange={(newId) => {
            // Optimistically update the local discount id
            setLocalId(newId);

            const newDiscount = selectData.find(
              (sd) => sd.value === newId
            )!.discount;

            setUserDiscounts((prev) =>
              prev.map((e) =>
                e.id === original.id
                  ? {
                      ...e,
                      discountId: newDiscount.id,
                      discount: newDiscount,
                    }
                  : e
              )
            );
          }}
        />
      );
    },
  },
  {
    Header: "Updated by",
    accessor: "updatedByFullName",
  },
];
