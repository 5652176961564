import GTLoader from "#/components-ng/loader";
import { trpc } from "#/trpc";
import { reportUserSuccess } from "#/util";
import { CustomAttributesForm } from "./Form";
import { FormValues } from "./Form/types";
import { LoadingOverlay } from "@mantine/core";
import { useNavigate, useParams } from "react-router";

export const UpdateCustomAttribute = () => {
  const params = useParams();
  const id = params.customAttributeId ? Number(params.customAttributeId) : null;
  const navigate = useNavigate();
  const ctx = trpc.useContext();

  const { data, isLoading, isFetchedAfterMount } =
    trpc.v2_5.customAttributes.getById.useQuery(
      { id: id! },
      { enabled: !!id, cacheTime: 0 },
    );

  const { mutate, isLoading: isLoadingUpdate } =
    trpc.v2_5.customAttributes.update.useMutation({
      onSuccess() {
        ctx.v2_5.customAttributes.invalidate();
        reportUserSuccess({
          title: "Custom attribute updated",
        });
        navigate("/inventory/custom-attributes");
      },
    });

  const handleSubmit = (values: FormValues) => {
    mutate({
      id: id!,
      name: values.name,
      type: values.type,
      description: values.description,
      textLimit: values.textLimit,
      options: values.type === "SELECT" ? values.options : [],
      multiSelectItems: values.multiSelectItems.map((value) => value.id),
    });
  };

  return isLoading || !isFetchedAfterMount ? (
    <LoadingOverlay
      visible={isLoading || !isFetchedAfterMount}
      loader={<GTLoader width={100} height={100} />}
    />
  ) : (
    <CustomAttributesForm
      isLoading={isLoadingUpdate}
      onSubmit={handleSubmit}
      defaultValues={{
        name: data?.name,
        description: data?.description,
        type: data?.type,
        options: data?.options,
        textLimit: data?.textLimit ?? 9,
        multiSelectItems:
          data?.customAttributeMultiSelectItems.map((item) => ({
            id: item.itemSku.id,
            title: item.itemSku.title,
            sku: item.itemSku.sku,
            defaultImage: item.itemSku.defaultImage,
          })) ?? [],
      }}
    />
  );
};
