import { trpc } from "#/trpc";
import { useParams } from "react-router";
import * as M from "@mantine/core";
import { reportUserSuccess } from "#/util";
import GTLoader from "#/components-ng/loader";
import { QuizForm } from "./Form";
import { FormValues } from "./Form/types";

export default function UpdateQuiz() {
  const params = useParams() as any;
  const id = params?.id ? Number(params.id) : null;

  const { data, isLoading, isFetchedAfterMount } =
    trpc.v2_5.quiz.getQuizById.useQuery(
      {
        id: id!,
      },
      {
        enabled: !!id,
        cacheTime: 0,
      },
    );

  const { mutate, isLoading: updateQuizIsLoading } =
    trpc.v2_5.quiz.updateQuiz.useMutation({
      onSuccess() {
        reportUserSuccess({
          title: "Quiz updated",
        });
      },
    });

  const handleSubmit = (values: FormValues) => {
    mutate({
      id: id!,
      title: values.title,
      description: values.description,
      finishedAt: values.finishedAt,
      needAuth: values.needAuth,
      publishedAt: values.publishedAt,
      defaultImage: values.defaultImage,
      titleEsp: values.titleEsp,
      descriptionEsp: values.descriptionEsp,
    });
  };

  return data && isFetchedAfterMount ? (
    <QuizForm
      isLoading={updateQuizIsLoading}
      onSubmit={handleSubmit}
      title="Update"
      defaultValues={{
        title: data?.title ?? "",
        description: data?.description ?? "",
        needAuth: data?.needsAuth ?? false,
        defaultImage: data?.defaultImage ?? null,
        publishedAt: data.publishedAt,
        finishedAt: data.finishedAt,
        titleEsp: data?.titleEsp ?? null,
        descriptionEsp: data?.descriptionEsp ?? null,
      }}
      quizId={id!}
    />
  ) : (
    <M.LoadingOverlay
      visible={isLoading || !isFetchedAfterMount}
      loader={<GTLoader width={100} height={100} />}
    />
  );
}
