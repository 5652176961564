import { THEME } from "./enums.js";
import { extendTheme, VStack, HStack, Input, Textarea } from "@chakra-ui/react";
import * as M from "@mantine/core";

VStack.defaultProps = {
  align: "start",
};

HStack.defaultProps = {
  justify: "start",
};

Input.defaultProps = {
  placeholder: " ",
};

Textarea.defaultProps = {
  placeholder: " ",
};

export const lightTheme = {
  mode: "Light",
  primary: "#49B9FF",
  primaryBackground: "#FAFAFA",
  secondaryBackground: "#F4F4F4",
  primaryFont: "#16223E",
  secondaryFont: "#919191",
  secondaryButton: "#EBEBEB",
  primaryBorder: "#EBEBEB",
  secondaryButtonBorder: "#EBEBEB",
  backgroundDisabled: "#DFDFDF",
  fontDisabled: "#B3B3B3",
  warning: "#FA5555",
  alert: "#F771B8",
  accent: "#4FBFBE",
  white: "#FFF",
  black: "#16223E",
  transparent: "#FAFAFA77",
  gold: "#b4a177",
  purple: "#9a7bfe",
  green: "#52a35f",
  red: "#d04b4b",
  separator: "#DDD",
  sizes: {
    font: {
      xs: "0.7rem",
      sm: "0.85rem",
      md: "0.95rem",
      lg: "1.15rem",
    },
    container: {
      xs: "1.5rem",
      sm: "2rem",
      md: "2.25rem",
      lg: "2.75rem",
    },
  },
};

export const darkTheme = {
  mode: "Dark",
  primaryBackground: "#16223E",
  secondaryBackground: "#FCFCFC",
  primaryFont: "#FAFAFA",
  secondaryFont: "#89B4F8",
  primary: "#16223E",
  secondaryButton: "#EBEBEB",
  primaryBorder: "#EBEBEB",
  secondaryButtonBorder: "#EBEBEB",
  backgroundDisabled: "#DFDFDF",
  fontDisabled: "#B3B3B3",
  warning: "#FA5555",
  alert: "#F771B8",
  accent: "#4FBFBE",
  white: "#FFF",
  black: "#16223E",
  transparent: "#FAFAFA77",
  gold: "#b4a177",
};

export const themes = {
  [THEME.Light]: lightTheme,
  [THEME.Dark]: darkTheme,
};

const activeLabelStyles = {
  transform: "scale(0.83) translateY(-20px) translateX(-4px)",
  borderTopLeftRadius: "md",
  borderTopRightRadius: "md",
};

const formFieldBaseStyle = {
  bg: "white",
  _hover: {
    borderColor: "brand.100",
  },
  _focus: {
    border: "solid 2px",
    borderColor: "brand.200",
    boxShadow: "none",
  },
};

const formFieldStyles = {
  baseStyle: {
    ...formFieldBaseStyle,
    field: formFieldBaseStyle,
  },
  variants: {
    // default variant
    outline: {
      ...formFieldBaseStyle,
      field: formFieldBaseStyle,
    },
  },
};

export const chakraTheme = extendTheme({
  fonts: {
    heading: "'Montserrat'",
    body: "'Montserrat'",
  },
  colors: {
    brand: {
      50: "#dbf7ff",
      100: "#aee3ff",
      200: "#7eceff",
      300: "#6ebaf0",
      400: "#63ade6",
      500: "#4dbaff",
      600: "#0e8de5",
      700: "#006eb3",
      800: "#002f50",
      900: "#001120",
      lightAccent: "#aee3ff",
      strongAccent: "#6ebaf0",
    },
  },
  components: {
    Button: {
      defaultProps: {
        colorScheme: "brand",
      },
      variants: {
        secondary: {
          color: "gray.500",
          bg: "gray.100",
          _hover: {
            bg: "gray.300",
          },
          _active: {
            bg: "gray.400",
          },
        },
      },
    },
    Form: {
      baseStyle: {
        requiredIndicator: {
          color: "brand.700",
        },
      },
      variants: {
        floating: {
          container: {
            _focusWithin: {
              label: {
                ...activeLabelStyles,
              },
            },
            "input:not(:placeholder-shown) + label, textarea:not(:placeholder-shown) + label, .chakra-select__wrapper + label, .react-select-active + label":
              {
                ...activeLabelStyles,
              },
            label: {
              top: 0,
              left: 0,
              zIndex: 2,
              position: "absolute",
              pointerEvents: "none",
              background: "white",
              mx: 3,
              px: 1,
              my: "0.45rem",
              transformOrigin: "left top",
              fontWeight: 600,
            },
          },
        },
      },
    },
    FormLabel: {
      baseStyle: {
        fontWeight: "bold",
        fontSize: "0.75rem",
        color: "gray.500",
      },
    },
    Input: {
      baseStyle: {
        ...formFieldStyles.baseStyle,
      },
      defaultProps: {
        size: "md",
      },
      sizes: {
        sm: {
          field: {
            borderRadius: "md",
          },
          addon: {
            borderRadius: "md",
          },
        },
      },
      variants: {
        ...formFieldStyles.variants,
      },
    },
    Textarea: {
      baseStyle: {
        ...formFieldStyles.baseStyle,
      },
      variants: {
        ...formFieldStyles.variants,
      },
    },
    NumberInput: {
      defaultProps: {
        size: "sm",
      },
      variants: {
        outline: {
          field: {
            bg: "white",
            borderRadius: "md",
            _hover: { borderColor: "brand.100" },
            _focus: {
              bg: "brand.50",
              borderStyle: "solid",
              borderWidth: "2px",
              borderColor: "brand.200",
              boxShadow: "none",
            },
          },
        },
      },
    },
    Select: {
      baseStyle: {
        ...formFieldStyles.baseStyle,
      },
      variants: {
        ...formFieldStyles.variants,
      },
      defaultProps: {
        size: "sm",
      },
      sizes: {
        sm: {
          field: {
            borderRadius: "md",
          },
        },
      },
    },
  },
});

const inputStyles = (theme) => ({
  input: {
    border: `solid 1px ${theme.colors.gray[3]}`,
    "&:hover": {
      borderColor: theme.colors.brand[1],
    },
    "&:focus": {
      background: theme.colors.brand[0],
      borderColor: theme.colors.brand[3],
    },
  },
  label: {
    color: theme.colors.gray[7],
  },
  required: {
    color: theme.colors.gray[7],
  },
});

/**
  @type {import("@mantine/core").MantineTheme["components"]}
*/
const mantineStyles = {
  Input: { styles: inputStyles },
  TextInput: { styles: inputStyles },
  Textarea: { styles: inputStyles },
  Select: { styles: inputStyles },
  NativeSelect: { styles: inputStyles },
  MultiSelect: { styles: inputStyles },
  DatePickerInput: { styles: inputStyles },
  DateInput: { styles: inputStyles },
  NumberInput: { styles: inputStyles },
  Divider: {
    classNames: {
      root: "border-slate-100",
    },
  },
  Drawer: {
    classNames: {
      drawer: "max-w-[90vw] sm:max-w-[calc(100vw/3)] w-auto p-8",
    },
  },
};

export const mantineTheme = {
  colors: {
    darkGray: [
      "#edf2f7",
      "#D7DBDF",
      "#BEC4CB",
      "#A5ADB6",
      "#8B95A2",
      "#727E8D",
      "#5B6571",
      "#444C55",
      "#2D3239",
      "#17191C",
    ],
    brand: [
      "#dbf7ff",
      "#aee3ff",
      "#7eceff",
      "#6ebaf0",
      "#63ade6",
      "#4dbaff",
      "#0e8de5",
      "#006eb3",
      "#002f50",
      "#001120",
    ],
    white: Array(10).fill("#FFFFFF"),
    slate: [
      "#f8fafc",
      "#f1f5f9",
      "#e2e8f0",
      "#cbd5e1",
      "#94a3b8",
      "#64748b",
      "#475569",
      "#334155",
      "#1e293b",
      "#0f172a",
      "#020617",
    ],
    red: [
      "rgb(254, 226, 226)",
      "rgb(254, 202, 202)",
      "rgb(252, 165, 165)",
      "rgb(248, 113, 113)",
      "rgb(239, 68, 68)",
      "rgb(220, 38, 38)",
      "rgb(185, 28, 28)",
      "rgb(153, 27, 27)",
      "rgb(127, 29, 29)",
    ],
  },
  fontFamily: "Montserrat",
  headings: {
    fontFamily: "Montserrat",
  },
  primaryColor: "brand",
  primaryShade: 5,
  components: mantineStyles,
  cursorType: "pointer",
};

M.Modal.defaultProps = {
  zIndex: 9999,
};

M.Menu.defaultProps = {
  zIndex: 9998,
};
