import GTLoader from '#/components-ng/loader.js'
import { CelebrateIcon, MedalIcon } from '#/components/icons/index.jsx'
import { useAuth } from '#/context/AuthContext.js'
import { CardContainer, PieChartWithText } from '#/scenes/Reports/common.js'
import { columns } from '#/scenes/Sales/SalesHistory/table.js'
import { trpc } from '#/trpc.js'
import { reportUserError, reportUserSuccess } from '#/util/index.js'
import { dashboardBaseDataAtom } from '../state/index.js'
import * as M from '@mantine/core'
import { DatePickerInput } from '@mantine/dates'
import { useAtomValue } from 'jotai'
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table'
import React from 'react'
import Confetti from 'react-confetti/index.js'
import { AiOutlineCheckCircle } from 'react-icons/ai/index.js'
import { useWindowSize } from 'react-use'

export const DashboardInner = () => {
  const [auth] = useAuth()
  const baseData = useAtomValue(dashboardBaseDataAtom)
  const [opened, setOpened] = React.useState(false)
  const [showOrders, setShowOrders] = React.useState(false)
  const { width, height } = useWindowSize()

  const [dateRange, setDateRange] = React.useState<[Date | null, Date | null]>([
    baseData.fromDate,
    baseData.toDate,
  ])

  const { mutate, data, isLoading } =
    trpc.report.summaryByFilialIdUserId.useMutation({
      onError: (err) => {
        reportUserError({
          title: 'Failed to get summary',
          message: err.message,
        })
      },
    })

  React.useEffect(() => {
    if (!dateRange[0] || !dateRange[1]) return
    mutate({
      filialId: baseData.filialId as number,
      userId: baseData.userId as number,
      start: dateRange[0],
      end: dateRange[1],
    })
  }, [baseData.filialId, baseData.userId, dateRange])

  const { mutate: completeUserMilestone } =
    trpc.milestone.completeUserMilestone.useMutation({
      onSuccess: () => {
        reportUserSuccess({
          title: 'Milestone completed',
        })
        setOpened(true)
      },
      onError: (err) => {
        reportUserError({
          title: 'Failed to complete milestone',
          message: err.message,
        })
      },
    })

  const handleCompleteMilestone = () => {
    if (
      data?.totalOrderWithoutFees &&
      data?.currentMilestone?.milestoneValue &&
      data?.totalOrderWithoutFees?.toNumber() >=
        data?.currentMilestone?.milestoneValue?.toNumber()
    ) {
      completeUserMilestone({
        userId: baseData.userId as number,
        milestoneId: data?.currentMilestone?.id as number,
      })
    } else {
      reportUserError({
        title: 'You have not completed the milestone yet',
      })
    }
  }

  const {
    data: ordersAssociated,
    isLoading: isLoadingOrders,
    mutate: getOrdersAssociated,
  } = trpc.order.private.getByDateRangeAndAssociated.useMutation({
    onSuccess: () => {
      setShowOrders(true)
    },
    onError(err) {
      reportUserError({
        title: 'Failed to get sales history',
        message: err.message,
      })
    },
  })

  const handleShowOrders = () => {
    if (!dateRange[0] || !dateRange[1]) return
    getOrdersAssociated({
      start: dateRange[0]!,
      end: dateRange[1]!,
      filialId: auth.auth?.user?.filialId || null,
      userId: baseData.userId as number,
    })
  }

  const table = useMantineReactTable({
    data: ordersAssociated ?? [],
    columns: columns,
    enableTopToolbar: false,
    state: {
      isLoading: isLoadingOrders,
    },
  })

  return (
    <>
      <Confetti
        width={width}
        height={height}
        run={opened}
        recycle={false}
        style={{
          zIndex: 10000,
        }}
        tweenDuration={5000}
        numberOfPieces={1000}
      />
      <M.Modal
        opened={opened}
        onClose={() => setOpened(false)}
        size={'sm'}
        centered
      >
        <M.Stack align="center">
          {/* TODO: change icon by badge */}
          <AiOutlineCheckCircle size={100} color="green" />
          <M.Title order={3} align="center">
            Congratulations
          </M.Title>
          <M.Text align="center">You did a great job this month</M.Text>
          <M.Button onClick={() => setOpened(false)}>Continue</M.Button>
        </M.Stack>
      </M.Modal>
      <M.Container size="xl">
        <M.Group position="apart">
          <M.Group position="apart">
            <M.Avatar
              src={auth.auth?.user.avatar}
              radius="xl"
              size="lg"
              color="dark"
            >{`${baseData.user?.firstName?.split('')[0] ?? ''}${
              baseData?.user?.lastName?.split('')[0] ?? ''
            }`}</M.Avatar>
            <M.Stack spacing={0} ml={10}>
              <M.Text weight={600}>
                Welcome back,{' '}
                {`${baseData.user?.firstName} ${
                  baseData?.user?.lastName ?? ''
                }`}
              </M.Text>
              <M.Text size="xs" color="gray">
                {baseData?.user?.roleName ?? ''}
              </M.Text>
            </M.Stack>
          </M.Group>
          <M.Group>
            <DatePickerInput
              type="range"
              value={dateRange}
              onChange={(e) => {
                setShowOrders(false)
                setDateRange(e as [Date | null, Date | null])
              }}
              numberOfColumns={2}
              popoverProps={{
                zIndex: 9000,
              }}
              w={400}
              allowSingleDateInRange
            />
            <M.Button
              onClick={() => {
                if (!dateRange[0] || !dateRange[1]) return
                showOrders ? setShowOrders(false) : handleShowOrders()
              }}
              loading={isLoadingOrders}
            >
              View details
            </M.Button>
          </M.Group>
        </M.Group>
        <M.Divider color="gray.2" my="lg" />
        {isLoading ? (
          <M.LoadingOverlay
            visible={isLoading}
            loader={<GTLoader width={100} height={100} />}
          />
        ) : (
          <>
            <M.Group align="stretch" mb="lg">
              <M.Stack spacing={'md'} sx={{ flex: '1 !important' }}>
                <CardContainer>
                  <M.Text weight={600} size={'lg'}>
                    Milestone
                  </M.Text>
                  <M.Divider color="gray.2" mb={'xl'} />
                  <M.Stack spacing={'xs'}>
                    {data?.totalOrderWithoutFees &&
                    data?.totalOrderWithoutFees?.toNumber() > 0 ? (
                      <>
                        <CelebrateIcon size="md" />
                        <M.Text color="#212121" size="md" weight={600}>
                          You&apos;re close to the goal
                        </M.Text>
                        <M.Text
                          color="#666666"
                          size="xs"
                          weight={400}
                          mb={'lg'}
                        >
                          Complete your milestone to earn a badge
                        </M.Text>
                      </>
                    ) : (
                      <>
                        <MedalIcon size="md" />
                        <M.Text color="#212121" size="md" weight={600}>
                          Let the badge journey begin!
                        </M.Text>
                        <M.Text
                          color="#666666"
                          size="xs"
                          weight={400}
                          mb={'lg'}
                        >
                          Start fulfilling your orders and goals
                        </M.Text>
                      </>
                    )}

                    <M.Progress
                      color="teal"
                      value={
                        data?.totalOrderWithoutFees &&
                        data?.totalOrderWithoutFees?.toNumber() > 0
                          ? ((data?.totalOrderWithoutFees?.toNumber() ?? 0) /
                              (data?.currentMilestone?.milestoneValue?.toNumber() ??
                                1)) *
                            100
                          : 0
                      }
                      mb="sm"
                    />
                    <M.Group position="apart" mb={'lg'}>
                      <M.Text size="md" weight={500}>
                        Your Milestone
                      </M.Text>
                      <M.Text size="md" weight={500}>
                        ${`${data?.currentMilestone?.milestoneValue ?? 0}`}
                      </M.Text>
                    </M.Group>

                    <M.Group position="right" sx={{ width: 'auto' }}>
                      <M.Tooltip label="To finish your milestone you need to reach the milestone value">
                        <M.Button
                          sx={{ width: '15ch' }}
                          onClick={handleCompleteMilestone}
                          disabled={
                            !data?.currentMilestone?.milestoneValue
                              ? true
                              : data?.totalOrderWithoutFees &&
                                  data?.currentMilestone?.milestoneValue &&
                                  data?.totalOrderWithoutFees?.toNumber() <
                                    data?.currentMilestone?.milestoneValue?.toNumber()
                                ? true
                                : false
                          }
                        >
                          Let&apos;s finish!
                        </M.Button>
                      </M.Tooltip>
                    </M.Group>
                  </M.Stack>
                </CardContainer>
              </M.Stack>

              <M.Stack spacing={'xs'} sx={{ flex: '1 !important' }}>
                <M.Group align="stretch" mb="lg">
                  <CardContainer sx={{ flex: '1 !important' }}>
                    <M.Stack spacing={0}>
                      <M.Text weight={600} size={'lg'}>
                        $
                        {data?.totalOrderWithoutFees?.toNumber().toFixed(2) ||
                          0}
                      </M.Text>
                      <M.Text color="#666666" size="md" weight={500}>
                        Total sales orders
                      </M.Text>
                      <M.Text color="#666666" size="xs" weight={400}>
                        without fees
                      </M.Text>
                      <M.Group position="apart">
                        <M.Text color="#666666" size="xs" weight={500}></M.Text>
                        <M.Box sx={{ maxWidth: 78 }}>
                          <PieChartWithText
                            data={[
                              {
                                title: 'Sales',
                                value:
                                  data?.totalOrderWithoutFees?.toNumber() ?? 0,
                                color: '#49b9ff',
                              },
                            ]}
                            text={`${
                              data?.totalOrderWithoutFees &&
                              data?.totalOrderWithoutFees?.toNumber() > 0
                                ? (
                                    ((data?.totalOrderWithoutFees?.toNumber() ??
                                      0) /
                                      (data?.currentMilestone?.milestoneValue?.toNumber() ??
                                        1)) *
                                    100
                                  ).toFixed(0)
                                : 0
                            }%`}
                            lineWidth={10}
                            totalValue={data?.currentMilestone?.milestoneValue?.toNumber()}
                          />
                        </M.Box>
                      </M.Group>
                    </M.Stack>
                  </CardContainer>
                  <CardContainer sx={{ flex: '1 !important' }}>
                    <M.Stack spacing={0}>
                      <M.Text weight={600} size={'lg'}>
                        ${data?.totalOrders?.toNumber().toFixed(2) || 0}
                      </M.Text>
                      <M.Text color="#666666" size="md" weight={500}>
                        Total sales orders
                      </M.Text>
                      <M.Text color="#666666" size="xs" weight={400}>
                        with fees
                      </M.Text>
                      <M.Group position="apart">
                        <M.Text color="#666666" size="xs" weight={500}></M.Text>
                        <M.Box sx={{ maxWidth: 78 }}>
                          <PieChartWithText
                            data={[
                              {
                                title: 'Sales',
                                value: data?.totalOrders?.toNumber(),
                                color: '#49b9ff',
                              },
                            ]}
                            text={`${
                              data?.totalOrders &&
                              data?.totalOrders?.toNumber() > 0
                                ? (
                                    ((data?.totalOrders?.toNumber() ?? 0) /
                                      (data?.currentMilestone?.milestoneValue?.toNumber() ??
                                        1)) *
                                    100
                                  ).toFixed(0)
                                : 0
                            }%`}
                            lineWidth={10}
                            totalValue={
                              data?.currentMilestone?.milestoneValue?.toNumber() ??
                              0
                            }
                          />
                        </M.Box>
                      </M.Group>
                    </M.Stack>
                  </CardContainer>
                </M.Group>

                <M.Group align="stretch" mb="lg">
                  <CardContainer sx={{ flex: '1 !important' }}>
                    <M.Stack spacing={0}>
                      <M.Text weight={600} size={'lg'}>
                        {data?.totalCustomerAttends || 0}
                      </M.Text>
                      <M.Text color="#666666" size="md" weight={500}>
                        Total Customer Attends
                      </M.Text>
                      <M.Group position="apart">
                        <M.Text color="#666666" size="xs" weight={500}></M.Text>
                        <M.Box sx={{ maxWidth: 78 }}>
                          <PieChartWithText
                            data={[
                              {
                                title: 'Total',
                                value: data?.totalCustomerAttends,
                                color: '#957CF7',
                              },
                            ]}
                            text={`${
                              data?.totalCustomerAttends
                                ? (
                                    ((data?.totalCustomerAttends ?? 0) /
                                      (data?.totalCustomerAttendsTotal ?? 1)) *
                                    100
                                  ).toFixed(0)
                                : 0
                            }%`}
                            lineWidth={10}
                            totalValue={data?.totalCustomerAttendsTotal}
                          />
                        </M.Box>
                      </M.Group>
                    </M.Stack>
                  </CardContainer>
                  <CardContainer sx={{ flex: '1 !important' }}>
                    <M.Stack spacing={0}>
                      <M.Text weight={600} size={'lg'}>
                        {data?.totalOrderAttends || 0}
                      </M.Text>
                      <M.Text color="#666666" size="md" weight={500}>
                        Total Order Attends
                      </M.Text>
                      <M.Group position="apart">
                        <M.Text color="#666666" size="xs" weight={500}></M.Text>
                        <M.Box sx={{ maxWidth: 78 }}>
                          <PieChartWithText
                            data={[
                              {
                                title: 'Total',
                                value: data?.totalOrderAttends,
                                color: '#957CF7',
                              },
                            ]}
                            text={`${
                              data?.totalOrderAttends
                                ? (
                                    ((data?.totalOrderAttends ?? 0) /
                                      (data?.totalOrdersAttendsFilial ?? 1)) *
                                    100
                                  ).toFixed(0)
                                : 0
                            }%`}
                            lineWidth={10}
                            totalValue={data?.totalOrdersAttendsFilial}
                          />
                        </M.Box>
                      </M.Group>
                    </M.Stack>
                  </CardContainer>
                </M.Group>
              </M.Stack>
            </M.Group>
            {showOrders && <MantineReactTable table={table} />}
          </>
        )}
      </M.Container>
    </>
  )
}
