import { makeController } from "#/components-ng";
import { useAuth } from "#/context/AuthContext";
import { css } from "#/css/css";
import { trpc } from "#/trpc";
import { reportUserError, reportUserSuccess } from "#/util";
import { Button } from "@gt/ui";
import { modals } from "@mantine/modals";
import { FormProvider } from "react-hook-form";

export const openInactivateUserModal = (userId: number) => {
  modals.open({
    modalId: "INACTIVATE_USER_MODAL",
    children: <InactivateUserModal userId={userId} />,
  });
};

interface InactivateUserModalProps {
  userId: number;
}

const mc = makeController<{
  pin: string;
}>();

const InactivateUserModal = (props: InactivateUserModalProps) => {
  const [{ auth }] = useAuth();
  const ctx = trpc.useContext();
  const form = mc.useForm();

  const { mutate: suspendUser } = trpc.user.closeAccount.useMutation({
    cacheTime: 0,
    onError(error) {
      reportUserError({
        title: "Failed to inactivate account",
        message: error.shape?.message,
      });
      modals.close("INACTIVATE_USER_MODAL");
      form.reset();
    },
    onSuccess() {
      ctx.user.invalidate();
      ctx.v2_5.user.invalidate();
      reportUserSuccess({
        title: "User inactivated",
      });
      modals.close("INACTIVATE_USER_MODAL");
      form.reset();
    },
  });

  const handleInactivated = async (values: { pin: string }) => {
    if (!auth?.user?.filialId) {
      reportUserError({
        title: "Failed to inactivate account",
        message:
          "you need to have a filial assigned to be able to inactivate a user",
      });
      return;
    }

    suspendUser({
      id: Number(props.userId),
      filialId: auth!.user!.filialId as number,
      pin: values.pin,
    });
  };

  return (
    <div
      className={css({
        display: "grid",
        placeItems: "center",
        maxHeight: "100vh",
      })}
    >
      <form onSubmit={form.handleSubmit(handleInactivated)}>
        <FormProvider {...form}>
          <div
            className={css({
              display: "grid",
              minWidth: "32ch",
              justifyItems: "center",
              gridGap: 2,
              padding: 4,
            })}
          >
            <h4>Please enter the pin to inactivate the user</h4>
            <mc.InputField
              name="pin"
              placeholder="Admin Pin"
              required
              classNames={{ field: "w-full" }}
              type="password"
            />
            <Button variant="destructive" className="w-full" type="submit">
              Inactivate
            </Button>
          </div>
        </FormProvider>
      </form>
    </div>
  );
};
