import { DefaultValues, FormProvider, SubmitHandler } from "react-hook-form";
import { C, createSysBlockSchema, FormValues } from "./types";
import { styled } from "#/css/jsx";
import { css } from "#/css/css";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@gt/ui";

export interface SysBlockFormProps {
  onSubmit: SubmitHandler<FormValues>;
  isLoading: boolean;
  defaultValues?: DefaultValues<FormValues>;
}

export const SysBlockForm = (props: SysBlockFormProps) => {
  const form = C.useForm({
    resolver: zodResolver(createSysBlockSchema),
    shouldUnregister: false,
    defaultValues: {
      name: "",
      ...props.defaultValues,
    },
  });

  return (
    <styled.div
      className={css({
        backgroundColor: "white",
        padding: "40px",
        borderRadius: "lg",
      })}
    >
      <h2
        className={css({
          color: "black",
          fontSize: "26px",
          fontWeight: "bold",
          marginBottom: "30px",
        })}
      >
        Sys-Block
      </h2>
      <FormProvider {...form}>
        <form onSubmit={(e) => form.handleSubmit(props.onSubmit)(e)}>
          <styled.div
            className={css({
              display: "flex",
              flexDirection: "column",
              gap: 35,
              maxWidth: "500px",
            })}
          >
            <C.InputField name="name" label="Name *" required />
            <Button type="submit" isLoading={props.isLoading}>
              Save
            </Button>
          </styled.div>
        </form>
      </FormProvider>
    </styled.div>
  );
};
