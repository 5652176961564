import { DisplayTable } from "#/components";
import { Button } from "#/components-ng";
import { showVariantFormPOAtom } from "#/scenes/Inventory/UpcomingItems/CreatePO/NewItems/NewItemsForm";
import { trpc } from "#/trpc";
import { reportUserError, reportUserSuccess } from "#/util";
import { ItemSkuToConvertItemAtom } from "../../ConvertVariantToItem";
import { showRoomTypes } from "../state";
import { Combobox } from "@gt/ui";
import { ActionIcon, Group, Menu, Modal, Stack, Text } from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import { useAtom, useSetAtom } from "jotai";
import { MdMoreHoriz } from "react-icons/md";
import { Link, useNavigate, useParams } from "react-router-dom";

interface VariantsTable {
  itemId?: number | null;
}

export function VariantsTable(props: VariantsTable) {
  const params = useParams() as any;
  const setShowRoomType = useSetAtom(showRoomTypes);
  const [showVariantFormPO, setShowVariantForm] = useAtom(
    showVariantFormPOAtom
  );

  const { data, isLoading } = trpc.item.getItemSkus.useQuery(
    {
      id: props.itemId!,
    },
    {
      cacheTime: 0,
      enabled: !!props.itemId,
    }
  );

  const variants = useMemo(() => {
    if (!data) return [];
    return data.map((itemSku) => ({
      id: itemSku.id,
      title: itemSku.title,
      sku: itemSku.sku,
      pkg: `${itemSku.presentationValue} ${itemSku.presentationType}`,
      defaultImage: itemSku.defaultImage,
      showRoomType: itemSku.showRoomType,
    }));
  }, [data]);

  return (
    <div className="rounded-md border border-[#E0E0E0] bg-white p-10">
      <h2 className="mb-1">Variants</h2>
      <p className="mb-6 text-sm text-[#757575]">
        Add variants to your product
      </p>

      {data && data?.length > 0 ? (
        <div className="overflow-x-auto">
          <DisplayTable
            columns={columns}
            data={variants}
            pagination={false}
            isLoading={isLoading}
          />
        </div>
      ) : null}

      <Menu shadow="md" width="auto">
        <Menu.Target>
          <Button variant="primary" className="mt-6">
            Add Variant
          </Button>
        </Menu.Target>
        <Menu.Dropdown>
          {props?.itemId && !params?.id ? (
            <>
              <Menu.Item
                onClick={() => {
                  setShowRoomType("PUBLIC");
                  setShowVariantForm(true);
                }}
              >
                Public
              </Menu.Item>
              <Menu.Item
                onClick={() => {
                  setShowRoomType("CUSTOM");
                  setShowVariantForm(true);
                }}
              >
                Custom
              </Menu.Item>
              <Menu.Item
                onClick={() => {
                  setShowRoomType("EXCLUSIVE");
                  setShowVariantForm(true);
                }}
              >
                Exclusive
              </Menu.Item>
            </>
          ) : (
            <>
              <Menu.Item
                component={Link}
                to={`/inventory/item-list/edit/${props.itemId}/variant/create`}
                onClick={() => setShowRoomType("PUBLIC")}
              >
                Public
              </Menu.Item>
              <Menu.Item
                onClick={() => setShowRoomType("CUSTOM")}
                component={Link}
                to={`/inventory/item-list/edit/${props.itemId}/variant/create`}
              >
                Custom
              </Menu.Item>
              <Menu.Item
                onClick={() => setShowRoomType("EXCLUSIVE")}
                component={Link}
                to={`/inventory/item-list/edit/${props.itemId}/variant/create`}
              >
                Exclusive
              </Menu.Item>
            </>
          )}
        </Menu.Dropdown>
      </Menu>
    </div>
  );
}

const columns = [
  {
    Header: "Actions",
    id: "actions",
    Cell: ({ row: { original } }) => {
      const params = useParams();
      const itemId = params["id"];
      const navigate = useNavigate();
      const ctx = trpc.useContext();
      const [query, setQuery] = useState<string>("");
      const [opened, setOpened] = useState<boolean>(false);
      const [itemToMoveId, setItemToMoveId] = useState<number | null>(null);
      const setItemSkuToConvertItem = useSetAtom(ItemSkuToConvertItemAtom);

      const { data: searchData } = trpc.itemSku.search.useQuery(
        { query: `\\"${query}\\"` },
        {
          enabled: query.length > 0,
          onError: (err) => {
            reportUserError({
              title: "Failed to get products",
              message: err.message,
            });
          },
        }
      );

      const options = useMemo(() => {
        if (!query) return [];
        return (
          searchData?.map((itemSku) => ({
            label: `${itemSku.sku} - ${itemSku.title}`,
            value: itemSku,
            id: itemSku.id.toString(),
          })) ?? []
        );
      }, [searchData, query]);

      const { mutate: deleteItemSku } = trpc.itemSku.delete.useMutation({
        onError(error) {
          reportUserError({
            title: "Failed to delete variant",
            message: error.message,
          });
        },
        onSuccess() {
          ctx.itemSku.invalidate();
          ctx.item.invalidate();
          reportUserSuccess({
            title: "Variant deleted",
          });
        },
      });

      const { mutate: moveItemSku } = trpc.v2_5.itemSku.move.useMutation({
        onError(error) {
          reportUserError({
            title: "Failed to move variant",
            message: error.message,
          });
        },
        onSuccess() {
          setOpened(false);
          setItemToMoveId(null);
          ctx.itemSku.invalidate();
          ctx.item.invalidate();
          reportUserSuccess({
            title: "Variant moved",
          });
        },
      });

      const openConfirmCloseModal = () =>
        openConfirmModal({
          title: "Are you sure you want to delete this variant?",
          labels: { confirm: "Confirm", cancel: "Cancel" },
          confirmProps: { color: "red" },
          onConfirm: () => {
            deleteItemSku({ id: original.id });
          },
        });

      const openConfirmConvertItemModal = () =>
        openConfirmModal({
          title: "Are you sure about turning this variant int a item?",
          labels: { confirm: "Confirm", cancel: "Cancel" },
          confirmProps: { color: "red" },
          onConfirm: () => {
            setItemSkuToConvertItem(original.id);
            navigate("/inventory/item-list/create/convert");
          },
        });

      return (
        <>
          <Modal
            opened={opened}
            onClose={() => setOpened(false)}
            zIndex={40}
            centered
            withCloseButton={false}
          >
            <Stack spacing="lg">
              <Text className="text-lg font-semibold">Move variant</Text>
              <Text className="text-base">
                To move this variant search for an item
              </Text>
              <Combobox
                data={options}
                placeholders={{
                  selectValue: "Select SKU",
                  searchValue: "Search SKU",
                  valueNotFound: "No SKU found",
                }}
                onSearchChange={setQuery}
                onChange={(value) =>
                  setItemToMoveId(value?.value?.itemId ?? null)
                }
              />
              <Group position="right">
                <Button
                  variant="primary"
                  onClick={() => {
                    if (!itemToMoveId) return;
                    moveItemSku({
                      itemId: itemToMoveId,
                      itemSkuId: Number(original.id),
                    });
                  }}
                  className="mr-2"
                  disabled={!itemToMoveId}
                >
                  Move
                </Button>
                <Button
                  color="red"
                  onClick={() => {
                    setOpened(false);
                    setItemToMoveId(null);
                  }}
                >
                  Cancel
                </Button>
              </Group>
            </Stack>
          </Modal>
          <Group spacing={8} noWrap>
            <Menu>
              <Menu.Target>
                <ActionIcon>
                  <MdMoreHoriz />
                </ActionIcon>
              </Menu.Target>
              <Menu.Dropdown>
                {itemId && (
                  <Menu.Item
                    component={Link}
                    to={`/inventory/item-list/edit/${itemId}/variant/edit/${original.id}`}
                  >
                    Edit
                  </Menu.Item>
                )}
                <Menu.Item
                  component={Link}
                  to={`/inventory/item-list/preview/${original.id}`}
                >
                  Preview
                </Menu.Item>
                <Menu.Item onClick={() => setOpened(true)}>
                  Move variant
                </Menu.Item>
                <Menu.Item onClick={openConfirmConvertItemModal}>
                  Convert to item
                </Menu.Item>
                <Menu.Item onClick={openConfirmCloseModal}>Delete</Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Group>
        </>
      );
    },
  },
  {
    Header: "Image",
    accessor: "defaultImage",
    disableSortBy: true,
    type: "image",
  },
  {
    Header: "Title",
    accessor: "title",
  },
  {
    Header: "SKU",
    accessor: "sku",
    isNumeric: true,
  },
  {
    Header: "PKG",
    accessor: "pkg",
  },
  {
    Header: "Type",
    accessor: "showRoomType",
  },
];
