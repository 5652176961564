import { css } from "#/css/css";
import { useChangelogDialog } from "./dialog.state";
import { ChangelogArticle } from "./latest";
import { Dialog } from "@radix-ui/themes";

interface ChangelogArticleDialogProps {
  children?: React.ReactNode;
}

const articleStyles = css({
  color: "slate.800",

  "& :is(h2, h3, h4, h5, h6)": {
    mb: 2,
    fontWeight: "medium",
  },

  "& h2": {
    mb: 5,
    color: "slate.900",
    pb: 2,
    borderBottom: "1px solid token(colors.slate.300)",
  },

  "& p": {
    mb: 4,
  },

  "& :is(*:has(+ h3))": {
    mb: 6,
  },

  "& :is(*:has(+ h2))": {
    mb: 8,
  },
});

export function ChangelogArticleDialogWrapper(
  props: ChangelogArticleDialogProps,
) {
  const [changelogDialog, changelogDialogOnOpen] = useChangelogDialog();

  return (
    <Dialog.Root
      open={changelogDialog.open}
      onOpenChange={changelogDialogOnOpen}
    >
      <Dialog.Content size="4" maxWidth="850px">
        <div className={articleStyles}>{props.children}</div>
      </Dialog.Content>
    </Dialog.Root>
  );
}

export function ChangelogArticleDialog() {
  return (
    <ChangelogArticleDialogWrapper>
      <ChangelogArticle />
    </ChangelogArticleDialogWrapper>
  );
}
