import { trpc } from "#/trpc";
import { reportUserSuccess } from "#/util";
import { useNavigate } from "react-router";
import { SysBlockForm } from "./Form";
import { FormValues } from "./Form/types";

export const CreateSysBlockSection = () => {
  const ctx = trpc.useContext();
  const navigate = useNavigate();

  const { mutate, isLoading } =
    trpc.v2_5.changelog.sysBlocks.create.useMutation({
      onSuccess() {
        ctx.v2_5.changelog.invalidate();
        reportUserSuccess({
          title: "Created SysBlock",
          message: "SysBlock has been created",
        });
        navigate("/settings/admin/changelog/sys-blocks");
      },
    });

  const handleSubmit = (values: FormValues) => {
    mutate({
      name: values.name,
    });
  };

  return <SysBlockForm onSubmit={handleSubmit} isLoading={isLoading} />;
};
