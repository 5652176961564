import { AlertCircleOutline } from "./icons.js";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./tooltip.js";

type ErrorTooltipProps = {
  error?: string;
};

export function ErrorTooltip(props: ErrorTooltipProps) {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger className="absolute right-3 top-1/2 -translate-y-1/2 rounded-full">
          <AlertCircleOutline className="rounded-full bg-red-50 text-red-600" />
        </TooltipTrigger>
        <TooltipContent>
          <p className="text-sm text-red-500">{props.error}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}
