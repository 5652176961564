import GTLoader from "#/components-ng/loader.js";
import { trpc } from "#/trpc.js";
import { reportUserError, reportUserSuccess } from "#/util/ux/index.js";
import VendorsForm, { VendorFormValues } from "./Form.js";
import * as M from "@mantine/core";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

export default function EditVendor() {
  const { id } = useParams();
  const navigate = useNavigate();
  const ctx = trpc.useContext();

  const { data, isLoading } = trpc.vendor.getById.useQuery(
    {
      id: Number(id!),
    },
    {
      onError: (error) => {
        reportUserError({
          title: "Failed to get vendor",
          message: error.message,
        });
      },
    }
  );

  const defaultValues = React.useMemo(() => {
    if (data == null) return null;

    return {
      email: data.email,
      seoName: data.seoName,
      contactPersonName: data.contactPersonName,
      description: data?.description ?? "",
      phoneNumber: data?.phoneNumber ?? "",
      areaCode: data?.areaCode ?? "",
      countryCode: data?.countryCode ?? "",
      company: data?.company ?? "",
      defaultImage: data?.defaultImage ?? "",
      note: data?.note ?? "",
      address: {
        street: data?.street ?? "",
        city: data?.city ?? "",
        state: data?.state ?? "",
        country: data?.country ?? "",
        zip: data?.zip ?? "",
      },
      rateCosts:
        data?.vendorRateCost?.map((rateCost) => ({
          id: rateCost.rateCostId,
          name: rateCost.rateCost.name,
        })) ?? [],
    };
  }, [data]);

  const { mutate, isLoading: isLoadingUpdate } = trpc.vendor.update.useMutation(
    {
      onSuccess: () => {
        ctx.v2_5.vendor.invalidate();
        reportUserSuccess({
          title: "Vendor updated",
          message: "Vendor updated successfully",
        });
        navigate("/inventory/vendors");
      },
      onError: (error) => {
        reportUserError({
          title: "Failed to update vendor",
          message: error.message,
        });
      },
    }
  );

  const handleSubmit = (f: VendorFormValues) => {
    mutate({
      id: Number(id!),
      input: {
        email: f.email,
        contactPersonName: f.contactPersonName,
        seoName: f.seoName,
        phoneNumber: f?.phoneNumber ?? "",
        description: f?.description ?? "",
        areaCode: f?.areaCode ?? undefined,
        countryCode: f?.countryCode ?? undefined,
        company: f?.company ?? "",
        defaultImage: f?.defaultImage ?? "",
        note: f?.note ?? "",
        street: f?.address?.street ?? "",
        city: f?.address?.city ?? "",
        state: f?.address?.state ?? "",
        country: f?.address?.country ?? "",
        zip: f?.address?.zip ?? "",
        ratecosts:
          f?.rateCosts?.map((rateCost) => ({
            id: Number(rateCost.id),
          })) ?? [],
      },
    });
  };

  return isLoading ? (
    <M.LoadingOverlay
      visible={isLoading}
      loader={<GTLoader width={100} height={100} />}
    />
  ) : (
    <VendorsForm
      defaultValues={defaultValues as VendorFormValues}
      onSubmit={handleSubmit}
      loading={isLoadingUpdate}
    />
  );
}
