import { cn } from "#/lib/utils.js";
import { UseS3UploadError } from "#/scenes/Inventory/ItemList/Form/Item/components/types.js";
import { trpc } from "#/trpc.js";
import { resizeFile } from "#/util/image.js";
import { CloudUploadOutline } from "../icons.js";
import * as VisuallyHidden from "@radix-ui/react-visually-hidden";
import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { v4 as uuidv4 } from "uuid";

export type S3File = {
  fileName: string;
  directory: string;
  body: Blob;
  useBlobUrl?: boolean;
};

export type UseS3UploadResult = {
  fileName: string;
  url: string;
  uuid: string;
};

export type UseS3UploadObject = {
  fileName: string;
  isLoading: boolean;
  uuid: string;
  url?: string;
  blobUrl?: string;
  s3Url?: string;
};

export type UseS3Props = {
  onError?: (data: UseS3UploadError) => void;
  onSuccess?: (data: UseS3UploadResult) => void;
  onUploadingStart?: (data: UseS3UploadObject) => void;
};

function useS3(props?: UseS3Props) {
  const trpcClient = trpc.useContext().client;

  const [uploads, setUploads] = useState<UseS3UploadObject[]>([]);
  const upload = useCallback(
    async (s3Files: S3File[]) => {
      // get s3 presigned urls
      const uuid = uuidv4();
      const presignedUrls = await trpcClient.s3.getManyPresignedUrls.query(
        s3Files.map((s3File, i) => {
          // create unique name for file on S3

          const blobUrl = s3File.useBlobUrl
            ? URL.createObjectURL(s3File.body)
            : undefined;

          // update uploads state
          const uploadObject = {
            fileName: s3File.fileName,
            isLoading: true,
            uuid,
            blobUrl,
            url: blobUrl,
          };
          setUploads((prev) => [...prev, uploadObject]);

          // notify that we started uploading
          if (i === 0) {
            props?.onUploadingStart?.(uploadObject);
          }

          return {
            fileName: s3File.fileName,
            directory: s3File.directory,
            uuid,
          };
        }),
      );

      s3Files.forEach(async (s3File, i) => {
        try {
          // upload file to S3
          await fetch(presignedUrls[i].presignedUrl, {
            method: "PUT",
            body: s3File.body,
          });

          // update upload state
          setUploads((prev) =>
            prev.map((upload) => {
              if (upload.uuid === presignedUrls[i].uuid) {
                return {
                  ...upload,
                  isLoading: false,
                  s3Url: presignedUrls[i].s3Url,
                  url: presignedUrls[i].s3Url,
                };
              }
              return upload;
            }),
          );

          if (i === 0) {
            props?.onSuccess?.({
              fileName: s3File.fileName,
              uuid: presignedUrls[i].uuid,
              url: presignedUrls[i].s3Url,
            });
          }
        } catch (e) {
          props?.onError?.({
            fileName: s3File.fileName,
            uuid: presignedUrls[i].uuid,
            error: e,
          });
        }
      });
    },
    [props, trpcClient.s3.getManyPresignedUrls],
  );

  return { upload, uploads };
}

export type ImageUploadProps = {
  onS3UploadSuccess?: (data: UseS3UploadResult) => void;
  onS3UploadError?: (data: UseS3UploadError) => void;
  onS3UploadingStart?: (data: UseS3UploadObject) => void;
  directory: string;
  supports?: {
    image?: boolean;
    video?: boolean;
  };
  hiddenVisually?: boolean;
};

export function ImageUpload(props: ImageUploadProps) {
  const supports = {
    image: props.supports?.image ?? true,
    video: props.supports?.video ?? false,
  };

  const { upload } = useS3({
    onError: props.onS3UploadError,
    onSuccess: props.onS3UploadSuccess,
    onUploadingStart: props.onS3UploadingStart,
  });
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    async onDrop(acceptedFiles) {
      const sizes = [
        { width: 160, height: 160, name: "thumbail" },
        { width: 1280, height: 720, name: "hd" },
        { width: 1920, height: 1080, name: "full_hd" },
        { width: 3840, height: 2160, name: "4k" },
      ];

      if (acceptedFiles[0].type.startsWith("video")) {
        upload([
          {
            fileName: acceptedFiles[0].name,
            body: acceptedFiles[0],
            directory: props.directory,
            useBlobUrl: true,
          },
        ]);
        return;
      }

      const imagesArray: File[] = [acceptedFiles[0]];

      for await (const size of sizes) {
        const imageFile = await resizeFile(
          acceptedFiles[0],
          size.width,
          size.height,
        );

        imagesArray.push(imageFile);
      }

      upload(
        imagesArray.map((file, i) => {
          const name =
            i === 0
              ? file.name
              : `${file.name.split(".")[0]}.${sizes[i - 1]?.name}.${
                  file.name.split(".")[1]
                }`;

          return {
            fileName: name,
            body: file,
            directory: props.directory,
            useBlobUrl: true,
          };
        }),
      );
    },
    accept: ["image/jpeg", "image/png", "video/mp4"],
  });

  return (
    <div
      className={cn(
        "flex gap-x-6 rounded border border-slate-100 bg-white p-4 transition-shadow duration-200",
        isDragActive && "shadow-inner",
      )}
      {...getRootProps()}
    >
      <CloudUploadOutline className="text-4xl text-brand-500" />
      <div>
        <p className="font-medium">
          Drop your image{supports.video && "or video"} here, or{" "}
          <span className="text-brand-500">click to browse</span>
        </p>
        <p className="text-sm text-slate-500">
          Supports JPG, PNG{supports.video && ", MP4"}
        </p>
      </div>
      {props.hiddenVisually ? (
        <input {...getInputProps()} />
      ) : (
        <VisuallyHidden.Root>
          <input {...getInputProps()} />
        </VisuallyHidden.Root>
      )}
    </div>
  );
}

export type FileUploadProps = {
  onS3UploadSuccess?: (data: UseS3UploadResult) => void;
  onS3UploadingStart?: (data: UseS3UploadObject) => void;
  directory: string;
  label?: string | null;
};

export function FileUpload(props: FileUploadProps) {
  const { upload } = useS3({
    onSuccess: props.onS3UploadSuccess,
    onUploadingStart: props.onS3UploadingStart,
  });
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: [
      "application/pdf",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ],
    onDrop(acceptedFiles) {
      upload(
        acceptedFiles.map((file) => ({
          fileName: file.name,
          body: file,
          directory: props.directory,
          useBlobUrl: true,
        })),
      );
    },
  });

  return (
    <div
      className={cn(
        "flex gap-x-6 rounded border border-slate-100 bg-white p-4 transition-shadow duration-200",
        isDragActive && "shadow-inner",
      )}
      {...getRootProps()}
    >
      <CloudUploadOutline className="text-4xl text-brand-500" />
      <div>
        <p className="font-medium">
          Drop your file here, or{" "}
          <span className="text-brand-500">click to browse</span>
        </p>
        {props.label && <p className="text-sm text-slate-500">{props.label}</p>}
        <p className="text-sm text-slate-500">Supports PDF and DOCX</p>
      </div>
      <VisuallyHidden.Root>
        <input {...getInputProps()} />
      </VisuallyHidden.Root>
    </div>
  );
}
