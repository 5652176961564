import Decimal from "decimal.js";
import { atom } from "jotai";

export interface ItemSkuTableProps {
  itemSkuId: number;
  sku: number;
  title: string;
  qty: number;
  location: string;
  price: Decimal;
}

export const ItemSkusSelectedAtom = atom<ItemSkuTableProps[]>([]);
export const QtyToDepartmentCategoryAtom = atom<number>(1);
export const categorySelectedAtom = atom<number | null>(null);
export const departmentSelectedAtom = atom<number | null>(null);

export const ItemSkusSelectedAddAtom = atom(
  null,
  (get, set, sku: ItemSkuTableProps) => {
    const itemSkusSelected = get(ItemSkusSelectedAtom);
    const itemSkuIndex = itemSkusSelected.findIndex(
      (itemSku) => itemSku.sku === sku.sku
    );
    if (itemSkuIndex > -1) {
      itemSkusSelected[itemSkuIndex].qty += 1;
      set(ItemSkusSelectedAtom, itemSkusSelected);
    } else {
      set(ItemSkusSelectedAtom, [...itemSkusSelected, sku]);
    }
  }
);

export const ItemSkusSelectedAddQtyAtom = atom(
  null,
  (get, set, { index, qty }: { index: number; qty: number }) => {
    const itemSkusSelected = get(ItemSkusSelectedAtom).map((itemSku, i) => {
      if (i === index) {
        itemSku.qty = qty;
      }
      return itemSku;
    });
    set(ItemSkusSelectedAtom, itemSkusSelected);
  }
);
