import { showNotification } from "@mantine/notifications";
import React from "react";

export interface ReportUserSuccessProps {
  title?: string;
  message?: React.ReactNode;
}
export function reportUserSuccess(props: ReportUserSuccessProps) {
  showNotification({
    title: props.title,
    message: props.message,
    color: "green",
  });
}
