import GTLoader from "#/components-ng/loader";
import { useAuth } from "#/context/AuthContext";
import { trpc } from "#/trpc";
import { reportUserError } from "#/util";
import { PieChartCard } from "../common";
import { ExportEcommerceSalesButton } from "./export-button";
import * as M from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import dayjs from "dayjs";
import Decimal from "decimal.js";
import { useNavigate } from "react-router";

export const EcommerceSalesReport = () => {
  const navigate = useNavigate();
  const [{ auth }] = useAuth();
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    dayjs().startOf("month").toDate(),
    dayjs().endOf("month").toDate(),
  ]);

  if (auth && !["ROOT", "ENTITY MANAGER"].includes(auth.role.name)) {
    navigate("/reports");
    reportUserError({
      message: "You are not authorized to view this page",
    });
  }

  const {
    data: report,
    isFetching,
    fetchStatus,
  } = trpc.v2_5.report.getEcommerceSummary.useQuery(
    {
      startDate: dateRange[0]!,
      endDate: dateRange[1]!,
    },
    {
      enabled: dateRange[0] !== null && dateRange[1] !== null,
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );

  const data = useMemo(() => {
    if (report) {
      return report;
    }
    return null;
  }, [report]);

  const totalSalesByCashier = useMemo(() => {
    return (
      data?.usersReports?.users
        ?.map((userReport) => {
          return {
            cashier: `${userReport?.user?.firstName} ${
              userReport?.user?.lastName ?? ""
            }`,
            totalSales: userReport?.totalSales,
            color: generateRandomHexColor(),
          };
        })
        .concat({
          cashier: "Unassisted",
          totalSales: data?.usersReports?.unassisted?.totalSales,
          color: generateRandomHexColor(),
        }) ?? []
    );
  }, [data?.usersReports]);

  const totalSalesByOrderType = useMemo(() => {
    return (
      data?.salesActiviyByOrderType?.map((order) => {
        return {
          orderType: order?.orderType,
          totalOrderAmount: order?.totalOrderAmount,
          color: generateRandomHexColor(),
        };
      }) ?? []
    );
  }, [data?.salesActiviyByOrderType]);

  const totalCountOrdersByOrderType = useMemo(() => {
    return (
      data?.salesActiviyByOrderType?.map((order) => {
        return {
          orderType: order?.orderType,
          totalCountOrders: order?.orderCount,
          color: generateRandomHexColor(),
        };
      }) ?? []
    );
  }, [data?.salesActiviyByOrderType]);

  const totalCustomerAttendsByOrderType = useMemo(() => {
    return (
      data?.summaryCustomersByOrdersType?.map((order) => {
        return {
          orderType: order?.orderType,
          totalCustomers: order?.totalCustomerAttends + order?.guessCustomers,
          summary: [
            {
              title: "Guest customers",
              value: order?.guessCustomers,
              color: generateRandomHexColor(),
            },
            {
              title: "New customers",
              value: order?.newCustomers,
              color: generateRandomHexColor(),
            },
            {
              title: "Registered customers",
              value: order?.registeredCustomers,
              color: generateRandomHexColor(),
            },
          ],
        };
      }) ?? []
    );
  }, [data?.summaryCustomersByOrdersType]);

  return (
    <M.Container size="lg">
      <M.Group position="apart">
        <M.Title order={2}>E-commerce - Summary</M.Title>

        <M.Group position="apart">
          <DatePickerInput
            type="range"
            value={dateRange}
            onChange={setDateRange}
            numberOfColumns={2}
            popoverProps={{
              zIndex: 9000,
            }}
            w={400}
          />
          <ExportEcommerceSalesButton
            startDate={dateRange[0]}
            endDate={dateRange[1]}
          />
        </M.Group>
      </M.Group>
      <M.Divider my="lg" />
      {isFetching && fetchStatus === "fetching" ? (
        <M.LoadingOverlay
          visible={isFetching}
          loader={<GTLoader width={100} height={100} />}
        />
      ) : (
        <>
          <M.Group align="stretch" mb="lg">
            <PieChartCard
              title="Total Sales"
              text={`$${data?.totalOrderAmount.toNumber().toFixed(2)}`}
              total={data?.totalOrderAmount.toNumber() ?? 0}
              data={
                totalSalesByCashier?.map((cashier) => ({
                  title: cashier.cashier,
                  value: cashier.totalSales.toNumber(),
                  color: cashier.color,
                })) ?? []
              }
              isMoney={true}
            />
          </M.Group>

          <M.Group align="stretch" mb="lg">
            <PieChartCard
              title="Total Sales by order type"
              text={`$${
                totalSalesByOrderType
                  .reduce(
                    (acc, order) => acc.plus(order.totalOrderAmount),
                    new Decimal(0),
                  )
                  ?.toNumber()
                  ?.toFixed(2) ?? 0
              }`}
              total={
                totalSalesByOrderType
                  .reduce(
                    (acc, order) => acc.plus(order.totalOrderAmount),
                    new Decimal(0),
                  )
                  .toNumber() ?? 0
              }
              data={
                totalSalesByOrderType.map((order) => ({
                  title: order.orderType,
                  value: order.totalOrderAmount.toNumber(),
                  color: order.color,
                })) ?? []
              }
              isMoney={true}
            />
            <PieChartCard
              title="Total orders by order type"
              text={`
                ${totalCountOrdersByOrderType.reduce(
                  (acc, order) => acc + order.totalCountOrders,
                  0,
                )}
              `}
              total={
                totalCountOrdersByOrderType.reduce(
                  (acc, order) => acc + order.totalCountOrders,
                  0,
                ) ?? 0
              }
              data={
                totalCountOrdersByOrderType.map((order) => ({
                  title: order.orderType,
                  value: order.totalCountOrders,
                  color: order.color,
                })) ?? []
              }
              isMoney={false}
            />
          </M.Group>

          <M.Group align="stretch" mb="lg">
            <PieChartCard
              title="Total Customers - ONLINE"
              text={`${
                totalCustomerAttendsByOrderType.find(
                  (order) => order.orderType === "ONLINE",
                )?.totalCustomers
              }`}
              total={
                totalCustomerAttendsByOrderType.find(
                  (order) => order.orderType === "ONLINE",
                )?.totalCustomers ?? 0
              }
              data={
                totalCustomerAttendsByOrderType
                  .find((order) => order.orderType === "ONLINE")
                  ?.summary.map((order) => ({
                    title: order.title,
                    value: order.value,
                    color: order.color,
                  })) ?? []
              }
              isMoney={false}
            />
            <PieChartCard
              title="Total Customers - PICKUP"
              text={`${
                totalCustomerAttendsByOrderType.find(
                  (order) => order.orderType === "PICKUP",
                )?.totalCustomers
              }`}
              total={
                totalCustomerAttendsByOrderType.find(
                  (order) => order.orderType === "PICKUP",
                )?.totalCustomers ?? 0
              }
              data={
                totalCustomerAttendsByOrderType
                  .find((order) => order.orderType === "PICKUP")
                  ?.summary.map((order) => ({
                    title: order.title,
                    value: order.value,
                    color: order.color,
                  })) ?? []
              }
              isMoney={false}
            />
          </M.Group>

          <M.Group align="stretch" mb="lg">
            <PieChartCard
              title="Total sales by customer"
              text={`$${data?.totalOrderAmount.toNumber().toFixed(2)}`}
              total={data?.totalOrderAmount.toNumber() ?? 0}
              data={[
                {
                  title: "New Customers",
                  value:
                    data?.salesByCustomersType?.newCustomersSales?.toNumber() ??
                    0,
                  color: generateRandomHexColor(),
                },
                {
                  title: "Old Customers",
                  value:
                    data?.salesByCustomersType?.registeredCustomersSales?.toNumber() ??
                    0,
                  color: generateRandomHexColor(),
                },
                {
                  title: "Guest Customers",
                  value:
                    data?.salesByCustomersType?.guessCustomersSales?.toNumber() ??
                    0,
                  color: generateRandomHexColor(),
                },
              ]}
              isMoney={true}
            />
          </M.Group>
        </>
      )}
    </M.Container>
  );
};

const generateRandomHexColor = () => {
  return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
};
