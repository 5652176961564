import { GroupBase, Props } from "chakra-react-select";
import { Select } from "#/components/Form/v3/index.js";

export function SearchSelect<Option, Group extends GroupBase<Option>>({
  chakraStyles: {
    control = (provided, _state) => provided,
    container = (provided, _state) => provided,
    menu = (provided, _state) => provided,
    dropdownIndicator = (provided, _state) => provided,
    ...chakraStyles
  } = {},
  ...props
}: Props<Option, false, Group>) {
  return (
    <Select
      filterOption={() => true}
      {...props}
      size="sm"
      chakraStyles={{
        control: (provided, state) =>
          control(
            {
              ...provided,
              color: "gray.500",
              borderRadius: "full",
            },
            state
          ),
        container: (provided, state) =>
          container(
            {
              ...provided,
              borderRadius: "full",
              w: "100%",
              maxW: "400px",
            },
            state
          ),
        menu: (provided, state) =>
          menu({ ...provided, width: "auto", zIndex: "popover" }, state),

        dropdownIndicator: (provided, state) =>
          dropdownIndicator(
            { ...provided, display: "none", ...dropdownIndicator },
            state
          ),
        ...chakraStyles,
      }}
    />
  );
}
