import { trpc } from "#/trpc";
import { reportUserError } from "#/util";
import { useMemo } from "react";
import * as M from "@mantine/core";
import { DisplayTable } from "#/components";

type PurchaseOrderItemSkusModalProps = {
  purchaseOrderId: number | null;
  opened: boolean;
  setOpened: (boolean) => void;
};

export const PurchaseOrderItemSkusModal = ({
  purchaseOrderId,
  opened,
  setOpened,
}: PurchaseOrderItemSkusModalProps) => {
  const { data: filials } = trpc.filial.getAll.useQuery();

  const { data, isLoading } = trpc.v2_5.purchaseOrder.getById.useQuery(
    {
      id: purchaseOrderId!,
    },
    {
      enabled: !!purchaseOrderId && opened,
      onError(err) {
        reportUserError({
          title: "Failed to load purchase order",
          message: err.message,
        });
      },
    },
  );

  const columns = useMemo(() => {
    const initialColumns: { Header: string; accessor: string; Cell?: any }[] = [
      {
        Header: "Item name",
        accessor: "itemSku.title",
      },
      {
        Header: "SKU",
        accessor: "itemSku.sku",
      },
      {
        Header: "Vendor name",
        accessor: "itemSku.vendor.company",
      },
      {
        Header: "QTY",
        accessor: "expectedQuantity",
      },
    ];

    for (const [i, filial] of filials?.entries() ?? []) {
      initialColumns.push({
        accessor: `receivedPurchaseOrderItemSku[${i}].receivedQuantity`,
        Header: `QTY received ${filial.name}`,
        Cell: ({ row: { original } }) => {
          const value = original.receivedPurchaseOrderItemSku
            .filter((v) => v.filialId === filial.id)
            .reduce((acc, v) => acc + v.receivedQuantity, 0);

          return <M.Text align="center">{value ?? 0}</M.Text>;
        },
      });
    }

    return initialColumns;
  }, [filials]);

  return (
    <M.Modal
      title={<M.Title order={3}>Item list</M.Title>}
      opened={opened}
      onClose={() => setOpened(false)}
      withCloseButton={false}
      size="1080px"
      zIndex={1400}
    >
      {isLoading && <M.Loader mt={12} />}
      {!data?.purchaseOrderItemSku?.length ? (
        <M.Text align="center" color="dimmed">
          No products in this purchase order
        </M.Text>
      ) : (
        <DisplayTable
          columns={columns}
          data={data?.purchaseOrderItemSku ?? []}
        />
      )}
    </M.Modal>
  );
};
