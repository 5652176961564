import { RouterOutputs, trpc } from "#/trpc.js";
import { FormControl, Select } from "#/components/Form/v2/index.js";
import { useState } from "react";

type ItemSku = RouterOutputs["itemSku"]["search"][0];
export function ProductField() {
  const [query, setQuery] = useState("");
  const itemSkusQuery = trpc.itemSku.search.useQuery({
    query: `\\"${query}\\"`,
  });

  return (
    <FormControl label="Product" name="product" w="100%" required>
      <Select
        options={itemSkusQuery.data ?? []}
        onInputChange={setQuery}
        placeholder="Search by SKU or name"
        isLoading={itemSkusQuery.isLoading}
        getOptionLabel={(item: ItemSku) => `${item.sku} ${item.title}`}
        getOptionValue={(item: ItemSku) => item.id.toString()}
        required
      />
    </FormControl>
  );
}
