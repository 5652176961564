import SceneLayout from "#/components/SceneLayout/index.jsx";
import { MdOutlineMonetizationOn } from "react-icons/md/index.esm.js";
import { Outlet } from "react-router";

export const SalesLayout = () => {
  return (
    <SceneLayout icon={<MdOutlineMonetizationOn />}>
      <Outlet />
    </SceneLayout>
  );
};
