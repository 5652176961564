// @ts-nocheck
import GTLoader from "#/components-ng/loader.js";
import Form from "#/components/Form/index.jsx";
import {
  Field,
  FormControl,
  NativeSelect,
  NumberInput,
} from "#/components/Form/v2/index.js";
import Image from "#/components/Image/index.js";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  TriangleDownIcon,
  TriangleUpIcon,
} from "@chakra-ui/icons";
import * as C from "@chakra-ui/react";
import { useScrollIntoView, useToggle } from "@mantine/hooks";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone.js";
import utc from "dayjs/plugin/utc.js";
import ExcelJS from "exceljs";
import FileSaver from "file-saver";
import _, { gt } from "lodash";
import React, { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { MdOutlineDownload, MdPictureAsPdf } from "react-icons/md/index.js";
import { SiMicrosoftexcel } from "react-icons/si/index.esm.js";
import {
  useTable,
  useSortBy,
  usePagination,
  useRowSelect,
  Cell,
} from "react-table";

dayjs.extend(utc);
dayjs.extend(timezone);

const NoHighlightedRow = { index: -1 };
// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};
const DisplayTable = ({
  columns,
  data,
  actions = null,
  isLoading = false,
  pagination = true,
  onRowSelect = noop,
  autoResetPage = false,
  style = {},
  highlightedRow = NoHighlightedRow,
  display = "table",
  enableMemoization = false,
}) => {
  const mappedColumns = useMemo(
    () =>
      columns.map((column) => ({
        sortType: chooseSortType(column),
        ...column,
        // eslint-disable-next-line no-dupe-keys
        Cell: (() => {
          if (column.Cell in cells) {
            return cells[column.Cell];
          } else if (column.Cell) {
            return column.Cell;
          } else {
            return (table) => {
              if (
                table.column.id === "image" ||
                table.column.type === "image" ||
                table.column.Header === "Image"
              ) {
                return (
                  <Image
                    src={table.row.values[table.column.id]}
                    width="80px"
                    height="80px"
                  />
                );
              } else {
                return <>{table.row.values[table.column.id]}</>;
              }
            };
          }
        })(),
      })),
    [columns]
  );

  const {
    scrollIntoView,
    scrollableRef,
    targetRef: scrollIntoViewRowRef,
  } = useScrollIntoView({ duration: 350 });
  const [currentHighlightedRow, setCurrentHighlightedRow] =
    React.useState(highlightedRow);

  React.useEffect(() => {
    setCurrentHighlightedRow(highlightedRow);
  }, [highlightedRow, scrollIntoView]);

  const [shouldRetriggerScroll, retriggerScroll] = useToggle([false, true]);
  React.useEffect(() => {
    if (currentHighlightedRow?.index >= 0 && scrollIntoViewRowRef.current) {
      scrollIntoView({ alignment: "center" });
    } else if (
      currentHighlightedRow?.index === data.length - 1 &&
      data.length > 0 &&
      !scrollIntoViewRowRef.current
    ) {
      retriggerScroll();
    }
  }, [
    currentHighlightedRow,
    data,
    scrollIntoView,
    scrollIntoViewRowRef,
    shouldRetriggerScroll,
    retriggerScroll,
  ]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    nextPage,
    previousPage,
    setPageSize,
    gotoPage,
    toggleAllRowsSelected,
    toggleRowSelected,
    selectedFlatRows,
    state: { pageIndex },
  } = useTable(
    {
      columns: mappedColumns,
      data,
      initialState: {
        pageIndex: 0,
        // we need to add `+ 1`, otherwise it crashes when `data.length == 1`
        pageSize: pagination ? 25 : data.length + 1,
      },
      autoResetPage,
    },
    useSortBy,
    usePagination,
    useRowSelect
  );

  React.useEffect(() => {
    if (!pagination) {
      setPageSize(Math.max(data?.length, 1));
    }
  }, [data?.length, setPageSize, pagination]);

  const [lastRowSelected, setLastRowSelected] = React.useState(0);
  const [isShiftDown, setIsShiftDown] = React.useState(false);
  React.useEffect(() => {
    function handleKeyDown(e) {
      if (e.key === "Shift") {
        setIsShiftDown(true);
      }
    }
    function handleKeyUp(e) {
      if (e.key === "Shift") {
        setIsShiftDown(false);
      }
    }
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  React.useEffect(() => {
    gotoPage(0);
  }, [data, gotoPage]);

  const downloadModalDisclosure = C.useDisclosure();
  const downloadXlsxModalDisclosure = C.useDisclosure();
  const printPdfModalDisclosure = C.useDisclosure();

  const handleDownloadOpen = React.useCallback(() => {
    downloadModalDisclosure.onOpen();
  }, [downloadModalDisclosure]);

  const handlePrintPdfOpen = React.useCallback(() => {
    printPdfModalDisclosure.onOpen();
  }, [printPdfModalDisclosure]);

  const handleDownloadXlsxOpen = React.useCallback(() => {
    downloadXlsxModalDisclosure.onOpen();
  }, [downloadXlsxModalDisclosure]);

  const handlePrintPdf = React.useCallback(
    (f) => {
      let rows = data;
      if (f.qty === "current") {
        rows = page.map((row) => row.original);
      } else if (f.qty === "selected") {
        rows = selectedFlatRows.map((row) => row.original);
      }
      const tableToPrint = {
        columns: getColumnsForExport(columns),
        data: rows,
      };
      window.localStorage.setItem("tableToPrint", JSON.stringify(tableToPrint));
      window.open("/print/table", "_blank");
    },
    [columns, data, page, selectedFlatRows]
  );

  const handleDownload = React.useCallback(
    (f) => {
      const skippedColumns = ["Actions", "Image"];
      let contents = "";
      contents += columns
        .filter((c) => !c.skipDownload)
        .map((c) => c.Header)
        .filter((c) => !skippedColumns.includes(c))
        .join(",");

      contents += "\n";

      let i = 0;
      let rows = data;
      if (f.qty === "current") {
        rows = page.map((row) => row.original);
      } else if (f.qty === "selected") {
        rows = selectedFlatRows.map((row) => row.original);
      }

      for (const row of rows) {
        if (f.qty === "custom" && i === f.customQty) {
          break;
        }
        const rowCells = [];
        for (const col of columns) {
          if (
            skippedColumns.includes(col.Header) ||
            col.skipDownload ||
            !col.accessor
          ) {
            continue;
          }
          const cell = _.get(row, col.accessor);

          const isDate =
            col.isEpochDate || col.isEpochDateTime || col.isDate ? true : false;

          let finalCell = "";
          if (cell) {
            if (cell instanceof Date || isDate) {
              finalCell = dayjs(cell)
                .tz("America/New_York")
                .format("MM/DD/YYYY");
            } else if (typeof cell === "string") {
              finalCell = cell;
            } else {
              finalCell = cell.toString();
            }
            // Escape double-quotes by adding another one
            finalCell = finalCell.replace(/"/g, '""');
            // Wrap string in double-quotes if it contains special characters
            if (finalCell.search(/("|,|\n)/g) >= 0) {
              finalCell = `"${finalCell}"`;
            }
          } else {
            finalCell = "";
          }
          rowCells.push(finalCell);
        }
        contents += rowCells.join(",");
        contents += "\n";
        i += 1;
      }
      FileSaver.saveAs(
        new Blob([contents], { type: "text/plain;charset=utf-8" }),
        `export-${dayjs().format("MM/DD/YYYY")}.csv`
      );
    },
    [columns, data, page, selectedFlatRows]
  );

  const handleDownloadXlsx = React.useCallback(
    (f) => {
      const skippedColumns = ["Actions", "Image"];
      const headers = columns
        .filter((c) => !c.skipDownload)
        .filter((c) => !skippedColumns.includes(c))
        .filter((c) => c.Header !== "Actions");

      const summary = [];

      let i = 0;
      let rows = data;
      if (f.qty === "current") {
        rows = page.map((row) => row.original);
      } else if (f.qty === "selected") {
        rows = selectedFlatRows.map((row) => row.original);
      }

      for (const row of rows) {
        if (f.qty === "custom" && i === f.customQty) {
          break;
        }
        const rowCells = {};
        for (const col of columns) {
          if (
            skippedColumns.includes(col.Header) ||
            col.skipDownload ||
            !col.accessor
          ) {
            continue;
          }
          const cell = _.get(row, col.accessor);

          const isDate =
            col.isEpochDate || col.isEpochDateTime || col.isDate ? true : false;

          let finalCell = "";
          if (cell) {
            if (cell instanceof Date || isDate) {
              finalCell = dayjs(cell)
                .tz("America/New_York")
                .format("MM/DD/YYYY");
            } else if (typeof cell === "string") {
              finalCell = cell;
            } else {
              finalCell = cell.toString();
            }
            // Escape double-quotes by adding another one
            finalCell = finalCell.replace(/"/g, '""');
            // Wrap string in double-quotes if it contains special characters
            if (finalCell.search(/("|,|\n)/g) >= 0) {
              finalCell = `"${finalCell}"`;
            }
          } else {
            finalCell = "";
          }
          rowCells[col.Header] = finalCell;
        }
        summary.push(rowCells);
        i += 1;
      }

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Sheet 1", {
        pageSetup: { paperSize: 9 },
      });

      worksheet.columns = headers.map((header) => ({
        header: header.Header,
        key: header.accessor,
        width: 20,
      }));

      summary.forEach((row) => {
        const rowValues = headers.map((header) => row[header.Header]);
        worksheet.addRow(rowValues);
      });

      workbook.xlsx
        .writeBuffer({
          // @ts-ignore
          base64: true,
        })
        .then((data) => {
          const blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          FileSaver.saveAs(blob, `export-${dayjs().format("MM/DD/YYYY")}.xlsx`);
        });
    },
    [columns, data, page, selectedFlatRows]
  );

  return (
    <C.Box w="100%" {...style}>
      <DownloadModal
        length={selectedFlatRows.length}
        disclosure={downloadModalDisclosure}
        handleDownload={handleDownload}
        format="CSV"
      />

      <DownloadModal
        length={selectedFlatRows.length}
        disclosure={printPdfModalDisclosure}
        handleDownload={handlePrintPdf}
        format="PDF"
      />

      <DownloadModal
        length={selectedFlatRows.length}
        disclosure={downloadXlsxModalDisclosure}
        handleDownload={handleDownloadXlsx}
        format="XLSX"
      />

      <table
        ref={scrollableRef}
        className={cx(
          "border-collapse w-full max-h-[calc(100vh-350px)] overflow-auto",
          display === "block" && "block",
          display === "grid" && "grid",
          display === "table" && "table"
        )}
        {...getTableProps()}
        onDoubleClick={() => toggleAllRowsSelected(false)}
      >
        <thead className="w-full">
          {headerGroups.map((headerGroup, tri) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              key={`tr${tri}`}
              className="sticky top-0 z-10 cursor-pointer bg-white shadow-sm"
            >
              {actions && (
                <th className="border-b border-slate-200 px-6 py-3 text-sm font-bold text-slate-600">
                  <span>Actions</span>
                </th>
              )}
              {headerGroup.headers.map((column, thi) => (
                <th
                  key={`th${thi}`}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className="border-b border-slate-200 px-6 py-3 text-xs font-bold text-slate-600"
                  style={{
                    padding: column.styles?.padding,
                    width: column.styles?.width,
                    minWidth: column.styles?.minWidth,
                  }}
                >
                  <div className="items-center gap-x-2">
                    {column.render("Header")}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <TriangleDownIcon aria-label="sorted descending" />
                        ) : (
                          <TriangleUpIcon aria-label="sorted ascending" />
                        )
                      ) : null}
                    </span>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className="w-full">
          {page.map((row) => {
            prepareRow(row);
            const rowProps = row.getRowProps();
            return (
              <tr
                {...rowProps}
                key={rowProps.key}
                className={cx(
                  "cursor-pointer focus:bg-gray-200",
                  row.index === currentHighlightedRow?.index &&
                    "animate-[table-row-highlight_0.3s_ease-in]",
                  row.isSelected ? "bg-sky-100" : "bg-inherit",
                  row.index === highlightedRow?.index && !currentHighlightedRow?.index && "!bg-[#EBFFF6]"
                )}
                style={{
                  backgroundColor: row.original.__idleBg ?? undefined,
                  color: row.original.__idleFg ?? undefined,
                }}
                ref={
                  row.index === currentHighlightedRow?.index
                    ? scrollIntoViewRowRef
                    : undefined
                }
                onAnimationEnd={() => setCurrentHighlightedRow(-1)}
                onMouseDown={(e) => {
                  if (e.target.tagName !== "TD" && e.target.tagName !== "TR") {
                    return;
                  }
                  row.toggleRowSelected();
                  if (isShiftDown) {
                    let from = lastRowSelected + 1;
                    let to = row.index;
                    if (from > to) {
                      [from, to] = [to + 1, from - 1];
                    }
                    for (let i = from; i < to; i++) {
                      toggleRowSelected(i);
                    }
                  }
                  onRowSelect && onRowSelect(row.index);
                  setLastRowSelected(row.index);
                  e.preventDefault();
                }}
              >
                {actions && (
                  <td
                    className="border-b border-b-gray-200 px-6 py-4"
                    style={{
                      color: row.original.__idleFg ?? undefined,
                    }}
                  >
                    {actions(row.original.id)}
                  </td>
                )}
                {row.cells.map((cell, tdi) =>
                  enableMemoization ? (
                    <MemoizedByValueTd key={tdi} cell={cell} />
                  ) : (
                    <Td key={tdi} cell={cell} />
                  )
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
      {isLoading && (
        <C.Flex justify="center" align="center" mt={3}>
          <GTLoader width={100} height={100} />
        </C.Flex>
      )}
      {pagination && (
        <C.Wrap
          justify="space-between"
          px={4}
          mt={2}
          py={1}
          align="center"
          borderRadius="md"
          bg="white"
          position="sticky"
          left="0"
          bottom="0"
          boxShadow="base"
          sx={{
            "@media print": {
              display: "none",
            },
          }}
        >
          <C.Flex align="center" gap={3}>
            <span>
              Page {pageIndex + 1} of {pageCount} | {data.length} rows
            </span>
            <C.IconButton
              onClick={previousPage}
              icon={<ChevronLeftIcon />}
              colorScheme="gray"
              isDisabled={!canPreviousPage}
            />
            <C.IconButton
              onClick={nextPage}
              icon={<ChevronRightIcon />}
              colorScheme="gray"
              isDisabled={!canNextPage}
            />
            <div>
              <span>Rows per page: </span>
              <C.Select
                defaultValue={25}
                onChange={(e) => setPageSize(Number.parseInt(e.target.value))}
              >
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={75}>75</option>
                <option value={100}>100</option>
                <option value={150}>150</option>
              </C.Select>
            </div>
          </C.Flex>
          <C.Text
            fontSize="sm"
            color="gray.700"
            minW="0"
            wordBreak="break-word"
          >
            Click: Select/Unselect | Double click: Unselect all | Shift+Click:
            Select/Unselect range
          </C.Text>
          <C.HStack>
            <C.IconButton
              icon={<MdPictureAsPdf />}
              colorScheme="gray"
              color="gray.500"
              onClick={handlePrintPdfOpen}
            />
            <C.IconButton
              icon={<MdOutlineDownload />}
              colorScheme="gray"
              color="gray.500"
              onClick={handleDownloadOpen}
            />
            <C.IconButton
              icon={<SiMicrosoftexcel />}
              colorScheme="gray"
              color="gray.500"
              onClick={handleDownloadXlsxOpen}
            />
          </C.HStack>
        </C.Wrap>
      )}
    </C.Box>
  );
};

export default React.memo(
  DisplayTable,
  (prev, next) =>
    prev.columns === next.columns &&
    prev.data === next.data &&
    prev.highlightedRow === next.highlightedRow &&
    prev.isLoading === next.isLoading &&
    prev.onRowSelect === next.onRowSelect &&
    prev.pagination === next.pagination &&
    prev.actions === next.actions &&
    prev.autoResetPage === next.autoResetPage &&
    prev.style === next.style
);

const getColumnsForExport = (columns) => {
  const skippedColumns = ["Actions", "Image", "Photo"];
  return columns
    .filter((c) => !c.skipDownload || !c.skipPrint)
    .filter((c) => !skippedColumns.includes(c.Header))
    .map((c) => {
      return c.PrintCell ? { ...c, Cell: c.PrintCell } : c;
    });
};

const DownloadModal = (props) => (
  <C.Modal {...props.disclosure}>
    <C.ModalOverlay />
    <C.ModalContent>
      <Form onSubmit={props.handleDownload}>
        <C.ModalHeader>Download as {props.format}</C.ModalHeader>
        <C.ModalCloseButton />
        <C.ModalBody>
          <DownloadForm defaultQty={props.length > 0 ? "selected" : "all"} />
        </C.ModalBody>
        <C.ModalFooter>
          <C.Button type="submit">Download</C.Button>
        </C.ModalFooter>
      </Form>
    </C.ModalContent>
  </C.Modal>
);

const DownloadForm = ({ defaultQty }) => {
  const { watch, setValue } = useFormContext();
  const [customDisabled, setCustomDisabled] = React.useState(true);

  React.useEffect(() => {
    const sub = watch((formValues) => {
      if (formValues.qty !== "custom") {
        setCustomDisabled(true);
      } else {
        setCustomDisabled(false);
      }
    });
    return () => sub.unsubscribe();
  }, [watch]);

  React.useEffect(() => {
    setValue("qty", defaultQty);
  }, [defaultQty, setValue]);

  return (
    <C.VStack spacing={4}>
      <FormControl name="qty" label="Qty" required>
        <Field as={NativeSelect}>
          <option value="all">All</option>
          <option value="current">Current page</option>
          <option value="selected">Only selected</option>
          <option value="custom">Custom</option>
        </Field>
      </FormControl>
      <FormControl name="customQty" label="Custom Qty">
        <Field>
          {(props) => (
            <NumberInput
              defaultValue={20}
              isDisabled={customDisabled}
              {...props}
            />
          )}
        </Field>
      </FormControl>
    </C.VStack>
  );
};

function sortLodash(valueA, valueB) {
  if (gt(valueA, valueB)) {
    return 1;
  } else {
    return -1;
  }
}

function chooseSortType(column) {
  return function (rowA, rowB, columnId) {
    let valueA = rowA.values[columnId];
    let valueB = rowB.values[columnId];
    if (column.isNumeric) {
      valueA = Number.parseFloat(valueA);
      valueB = Number.parseFloat(valueB);
    } else if (column.isEpochDate) {
      if (typeof valueA === "string") {
        valueA = Number.parseInt(valueA);
      }
      if (typeof valueA === "string") {
        valueB = Number.parseInt(valueB);
      }
      valueA = new Date(valueA).getTime();
      valueB = new Date(valueB).getTime();
    } else if (column.isEpochDateTime) {
      if (typeof valueA === "string") {
        valueA = Number.parseInt(valueA);
      }
      if (typeof valueB === "string") {
        valueB = Number.parseInt(valueA);
      }
    } else if (column.isDate) {
      valueA = new Date(valueA).getTime();
      valueB = new Date(valueB).getTime();
    } else {
      return _.sortBy(
        [
          { id: -1, value: valueA },
          { id: 1, value: valueB },
        ],
        (v) => v.value?.toString().toLowerCase()
      )[0].id;
    }
    return sortLodash(valueA, valueB);
  };
}

const cells = {
  date: ({ value }) => (value ? dayjs(value).format("MM/DD/YYYY") : undefined),
  money: ({ value }) => `$${Number.parseInt(value ?? -1).toFixed(2)}`,
  dateFromEpoch: ({ value }) =>
    dayjs(typeof value === "string" ? Number.parseInt(value) : value).format(
      "MM/DD/YYYY"
    ),
  epochDateTime: ({ value }) =>
    dayjs(typeof value === "string" ? Number.parseInt(value) : value).format(
      "MM/DD/YYYY HH:mm"
    ),
  epochDate: ({ value }) => {
    return value
      ? dayjs(typeof value === "string" ? Number.parseInt(value) : value)
          .utc()
          .format("MM/DD/YYYY")
      : "";
  },
};

function Td(props: { cell: Cell<any> }) {
  return (
    <td
      {...props.cell.getCellProps()}
      className="border-b border-b-gray-200 px-6 py-4"
      style={{
        color: props.cell.row.original.__idleFg ?? undefined,
      }}
    >
      {props.cell.render("Cell")}
    </td>
  );
}

const MemoizedByValueTd = React.memo(Td, (prev, next) => {
  if (typeof next.cell.column.$memoizeBy === "function") {
    return next.cell.column.$memoizeBy(prev.cell, next.cell);
  }
  return prev.cell.value === next.cell.value;
});
