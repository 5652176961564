import { DisplayTable } from "#/components";
import { printTicketFromOrderV2 } from "#/modules/ticket/print";
import { paymentMethodsMap } from "#/scenes/Sales/MakeASale/payment-methods-map";
import { applyDiscountV2 } from "#/scenes/Sales/util";
import { epochToMMDDYYY } from "#/util/date";
import { selectedOrderAtom } from "./state";
import { ChevronLeftIcon } from "@chakra-ui/icons";
import * as C from "@chakra-ui/react";
import * as M from "@mantine/core";
import { Flipped } from "react-flip-toolkit";

export function OrderModalContent() {
  const [selectedOrder, setSelectedOrder] = useAtom(selectedOrderAtom);
  const cartProductList = selectedOrder?.orderItemSku?.map((entry) => ({
    sku: entry.itemSku.sku,
    title: entry.itemSku.title,
    size: `${entry.itemSku.width ?? "-"}x${entry.itemSku.height ?? "-"}x${
      entry.itemSku.length ?? "-"
    }`,
    quantity: entry.quantity,
    discountAmount: entry.discountAmount,
    discountType: entry.discountType,
    price: entry.price,
    total: entry.total,
  }));

  const handleReprintEpson = async (withLocation: boolean) => {
    await printTicketFromOrderV2({
      withLocation: withLocation,
      order: selectedOrder!,
    });
  };

  return (
    <C.Modal isOpen={!!selectedOrder} onClose={() => setSelectedOrder(null)}>
      <C.ModalOverlay />
      <C.ModalContent
        maxW="initial"
        w="auto"
        overflowX="hidden"
        overflowY="auto"
        maxH="min(800px, 95vh)"
      >
        <Flipped inverseFlipId="order-history-modal">
          <C.Box>
            <C.ModalHeader as={C.HStack} spacing={4}>
              <C.IconButton
                aria-label="go back"
                icon={<ChevronLeftIcon w={8} h={8} />}
                variant="ghost"
                colorScheme="gray"
                size="sm"
                onClick={() => setSelectedOrder(null)}
              />

              <M.Text>Order {selectedOrder?.receiptNumber}</M.Text>
            </C.ModalHeader>
            <C.Box>
              <C.ModalCloseButton />
            </C.Box>
            <C.ModalBody>
              <C.HStack w="100%" justify="space-between">
                <C.VStack>
                  <M.Text className="font-bold">
                    {selectedOrder?.customer?.firstName ?? ""}{" "}
                    {selectedOrder?.customer?.lastName ?? ""}
                  </M.Text>
                  <M.Text color="gray.500">
                    {selectedOrder?.customer?.areaCode}{" "}
                    {selectedOrder?.customer?.phoneNumber}
                  </M.Text>
                </C.VStack>
                <C.VStack>
                  <M.Text color="gray.500">
                    Date: {epochToMMDDYYY(selectedOrder?.createdAt)}
                  </M.Text>
                </C.VStack>
              </C.HStack>
              <C.Divider my={3} />
              <DisplayTable
                data={cartProductList}
                columns={cartProductListColumns}
              />
              <C.Divider my={6} />
              <C.HStack w="100%" spacing={8} mt={6} align="start">
                <C.VStack flex={1} align="start" spacing={6}>
                  <C.VStack>
                    <M.Text>Payment method</M.Text>
                    {selectedOrder?.orderPayment?.map((pm, index) => (
                      <C.HStack key={index} spacing={2}>
                        <M.Box w="2.25rem">
                          <C.Icon
                            as={
                              paymentMethodsMap[pm?.paymentType || "OTHER"]
                                ?.icon
                            }
                            color={
                              pm?.paymentType === "CASH"
                                ? "cyan.300"
                                : undefined
                            }
                            w="1.5rem"
                            h="1.5rem"
                          />
                        </M.Box>
                        <M.Text className="font-normal">
                          {pm?.paymentType}
                        </M.Text>
                      </C.HStack>
                    ))}
                  </C.VStack>
                  <M.Menu width="12ch" zIndex={44}>
                    <M.Menu.Target>
                      <M.Button variant="light" size="md">
                        Reprint
                      </M.Button>
                    </M.Menu.Target>
                    <M.Popover.Dropdown>
                      <M.Menu.Item
                        onClick={async () => {
                          await handleReprintEpson(false);
                        }}
                      >
                        Re-print (Epson)
                      </M.Menu.Item>
                      <M.Menu.Item
                        component="a"
                        href={`/sales/sales-history/invoice/${
                          selectedOrder!.id
                        }`}
                        target="_blank"
                      >
                        Re-print (PDF)
                      </M.Menu.Item>
                      <M.Menu.Item
                        onClick={async () => {
                          await handleReprintEpson(true);
                        }}
                      >
                        Re-print with location (Epson)
                      </M.Menu.Item>
                    </M.Popover.Dropdown>
                  </M.Menu>
                </C.VStack>
                <CostBreakdown flex={1} />
              </C.HStack>
            </C.ModalBody>
          </C.Box>
        </Flipped>
      </C.ModalContent>
    </C.Modal>
  );
}

function CostBreakdown(props: C.AccordionProps) {
  const selectedOrder = useAtomValue(selectedOrderAtom);

  return (
    <C.Accordion allowToggle {...props}>
      <C.AccordionItem borderTop="none">
        <C.AccordionButton
          bg="green.400"
          color="white"
          borderRadius="md"
          _hover={{ bg: "green.500" }}
        >
          <C.HStack w="100%" justify="space-between">
            <M.Text className="font-semibold">Total</M.Text>
            <M.Text className="font-semibold">
              ${(selectedOrder?.total ?? 0).toFixed(2)}
            </M.Text>
          </C.HStack>
          <C.AccordionIcon ml={4} />
        </C.AccordionButton>
        <C.AccordionPanel>
          <C.SimpleGrid
            columns={2}
            rowGap={2}
            w="100%"
            px={3}
            py={1}
            color="gray.500"
          >
            <M.Text>Subtotal</M.Text>
            <M.Text className="text-end font-bold">
              ${(selectedOrder?.subTotal ?? 0).toFixed(2)}
            </M.Text>
            <M.Text>Tax</M.Text>
            <M.Text className="text-end font-bold">
              {selectedOrder?.taxable
                ? `$${selectedOrder?.taxTotal?.toFixed(2)}`
                : `N/A`}
            </M.Text>
            <M.Text>Shipping</M.Text>
            <M.Text className="text-end font-bold">
              {selectedOrder?.orderShipping
                ? selectedOrder?.orderShipping?.shippingCost
                    ?.toNumber()
                    ?.toFixed(2)
                : "N/A"}
            </M.Text>
          </C.SimpleGrid>
        </C.AccordionPanel>
      </C.AccordionItem>
    </C.Accordion>
  );
}

const cartProductListColumns = [
  {
    Header: "SKU",
    accessor: "sku",
    isNumeric: true,
  },
  {
    Header: "Product",
    accessor: "title",
  },
  {
    Header: "Size (WxHxL)",
    accesor: "size",
  },
  {
    Header: "Qty",
    accessor: "quantity",
    isNumeric: true,
  },
  {
    Header: "Discount",
    accesor: "discountAmount",
    Cell: ({ row: { original } }) => (
      <M.Text>
        {original.discountType === "AMOUNT" && "$"}
        {original.discountAmount?.toNumber()?.toFixed(2) ?? ""}
        {original.discountType === "PERCENTAGE" && "%"}
      </M.Text>
    ),
  },
  {
    Header: "Price",
    accessor: "price",
    isNumeric: true,
    PrintCell: "money",
    Cell: ({ value, row: { original } }) => (
      <M.Stack>
        <M.Text>
          $
          {original?.discountAmount
            ? applyDiscountV2(original?.price, {
                amount: original?.discountAmount,
                type: original?.discountType,
              })
                ?.toNumber()
                ?.toFixed(2)
            : value?.toNumber().toFixed(2)}
        </M.Text>
        <M.Text className="text-xs">
          Original price: ${original?.price?.toNumber().toFixed(2)}
        </M.Text>
      </M.Stack>
    ),
  },
  {
    Header: "Ext. price",
    accessor: "total",
    isNumeric: true,
    PrintCell: "money",
    Cell: ({ value }) => `$${value?.toFixed(2)}`,
  },
];
