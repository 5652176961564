import { openChangelogDialog } from "#/changelog/dialog";
import { changelogDialogAtom } from "#/changelog/dialog.state.js";
import { styled } from "#/css/jsx";
import { JsxStyleProps } from "#/css/types";
import { useAtomValue } from "jotai";

export interface ChangelogNewBadgeProps {
  module: string;
  openDialog?: boolean;
  css?: JsxStyleProps["css"];
}

export function ChangelogNewBadge(props: ChangelogNewBadgeProps) {
  const changelogDialog = useAtomValue(changelogDialogAtom);

  const newChanges =
    changelogDialog?.changelog?.getNewChanges(props.module) ?? [];

  if (newChanges.length === 0) {
    return null;
  }

  let changeType = newChanges[0].type;

  for (const change of newChanges) {
    // We prefer to show `New` rather than `Fix`
    if (changeType === "fix" && change.type === "feature") {
      changeType = change.type;
    }
  }

  const isFeature = changeType === "feature";

  return (
    <styled.div
      fs="xs"
      bg={isFeature ? "brand.50" : "green.100"}
      c={isFeature ? "brand.600" : "green.600"}
      h="1.5em"
      px="0.3em"
      rounded="full"
      display="grid"
      placeItems="center"
      cursor="default"
      css={props.css}
      onClick={props.openDialog ? openChangelogDialog : undefined}
    >
      <span>{changeType === "feature" ? "New" : "Fix"}</span>
    </styled.div>
  );
}
