import React from "react";
import { Switch as CSwitch } from "@chakra-ui/react";
import { Controller } from "react-hook-form";

export default function Switch({ name, control, children, ...props }) {
  if (control) {
    return (
      <Controller
        control={control}
        name={name}
        defaultValue={props.defaultChecked}
        render={({ field: { value, ...field } }) => (
          <InnerSwitch
            children={children}
            {...props}
            {...field}
            isChecked={value}
          />
        )}
      />
    );
  } else {
    return <CSwitch children={children} {...props} />;
  }
}

const InnerSwitch = React.forwardRef((/** @type {any} */ props, ref) => (
  <CSwitch {...props} ref={ref} />
));
