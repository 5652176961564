import { makeController } from "#/components-ng/index.js";
import { z } from "zod";

export const createRouletteSchema = z.object({
  title: z.string().min(1),
  description: z.string().nullish(),
  active: z.boolean().default(true),
  startDate: z.date().nullish(),
  endDate: z.date().nullish(),
});

export type FormValues = z.infer<typeof createRouletteSchema>;
export const C = makeController<FormValues>();

export const rouletteItemTypes = [
  "TEXT",
  "FREE_ITEM",
  "COUPON",
  "FREE_SHIPPING_CODE",
  "PERCENTAGE_DISCOUNT",
  "FIXED_DISCOUNT",
  "UPGRADE_MEMBERSHIP"
] as const;

export const createRouletteItemSchema = z.object({
  title: z.string().min(1),
  description: z.string().nullish(),
  active: z.boolean().default(true),
  type: z.enum(rouletteItemTypes),
  link: z.string().nullish(),
  itemSku: z
    .object({
      id: z.number(),
      title: z.string(),
      sku: z.number(),
      defaultImage: z.string().nullish(),
    })
    .nullish(),
  couponId: z.string().nullish(),
  discountId: z.string().nullish(),
});

export type FormRouletteItemValues = z.infer<typeof createRouletteItemSchema>;
export const CCreateRouletteItem = makeController<FormRouletteItemValues>();
