import React from "react";
import PropTypes from "prop-types";
import { Input as ChakraInput } from "@chakra-ui/react";

const Input = React.forwardRef(({ name, ...props }, ref) => {
  return (
    <ChakraInput
      ref={ref}
      name={name}
      bg={props?.isReadOnly ? "gray.100" : "white"}
      _hover={{ borderColor: "brand.100" }}
      _focus={{ bg: "brand.50", border: "solid 2px", borderColor: "brand.200" }}
      {...props}
    />
  );
});

Input.defaultProps = {
  name: "",
  type: "text",
};

Input.propTypes = {
  rules: PropTypes.objectOf(PropTypes.any),
  type: PropTypes.string,
};

Input.id = "Input";
Input.displayName = "Input";

export default Input;
