import { AlertCircleOutline } from "#/components-ng/index.js";
import DisplayTableContainer from "#/components/DisplayTableContainer/index.jsx";
import { DisplayTable } from "#/components/index.js";
import { RouterOutputs, trpc } from "#/trpc.js";
import { reportUserSuccess } from "#/util/index.js";
import * as M from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { openConfirmModal } from "@mantine/modals";
// import {
//   createColumnHelper,
//   flexRender,
//   getCoreRowModel,
//   useReactTable,
// } from "@tanstack/react-table";
import dayjs from "dayjs";
import { useMemo, useRef, useState } from "react";

export type Cart = RouterOutputs["cart"]["root"]["getBy"][number];

export function DeletePendingCartsOnHold() {
  const [dateRangeFilter, setDateRangeFilter] = useState({
    from: dayjs().subtract(1, "M").toDate(),
    to: new Date(),
  });
  const cartsQuery = trpc.cart.root.getBy.useQuery({
    status: "HOLD",
    dateRange: dateRangeFilter,
  });
  const carts = useMemo(() => cartsQuery.data ?? [], [cartsQuery.data]);
  const tableData = useMemo(
    () =>
      carts.map((cart) => ({
        lastUpdated: cart.updatedAt,
        customerName: cart.customer
          ? `${cart.customer?.firstName} ${cart.customer?.lastName ?? ""}`
          : "Anonymous",
        salesAssistantName: cart.associated
          ? `${cart.associated?.firstName} ${cart.associated?.lastName ?? ""}`
          : "N/A",
        total: cart.total.toNumber(),
        status: cart.cartStatus,
      })) ?? [],
    [carts]
  );
  const ctx = trpc.useContext();
  const deleteCartsMutation = trpc.v2_5.cart.delete.useMutation({
    onSuccess: () => {
      ctx.cart.invalidate();
      reportUserSuccess({
        title: "Carts deleted successfully",
      });
    },
  });

  const pinRef = useRef("");
  const confirmDeletion = () =>
    openConfirmModal({
      onConfirm: () =>
        deleteCartsMutation.mutate({
          filter: {
            status: "HOLD",
            updatedAt: [dateRangeFilter.from, dateRangeFilter.to],
          },
          pin: pinRef.current,
        }),
      title: (
        <p className="flex items-center gap-x-3 text-xl">
          <AlertCircleOutline className="text-red-500" />
          <span>Delete hold carts</span>
        </p>
      ),
      children: (
        <M.PasswordInput
          label="Enter the administrator PIN"
          placeholder="1234"
          onChange={(e) => (pinRef.current = e.currentTarget.value)}
        />
      ),
      labels: {
        cancel: "Cancel",
        confirm: "Delete",
      },
      confirmProps: {
        color: "red",
      },
    });

  return (
    <div className="grid grid-cols-[1fr_auto] gap-4">
      <div className="row-span-2 rounded-md bg-white p-6">
        <div className="space-y-2">
          <M.Title order={3}>Delete carts on hold</M.Title>
          <p className="text-slate-500">
            Choose the date range to delete the carts on hold in that range
          </p>
        </div>
        <M.Divider className="-mx-6 my-4" />
        {/* <CartsTable carts={carts} /> */}
        <DisplayTableContainer>
          <DisplayTable data={tableData} columns={columns} />
        </DisplayTableContainer>
      </div>
      <div className="grid gap-4">
        <div className="rounded-md bg-white p-6">
          <DatePicker
            type="range"
            defaultValue={[dateRangeFilter.from, dateRangeFilter.to]}
            onChange={(v) => {
              if (v[0] == null || v[1] == null) return;
              setDateRangeFilter({
                from: v[0],
                to: v[1],
              });
            }}
          />
        </div>
        <div className="flex items-center gap-x-4 rounded-md bg-white p-6">
          <p className="text-red-500">
            Carts to be deleted:{" "}
            <span className="font-medium">{carts.length}</span>
          </p>
          <M.Button color="red" onClick={confirmDeletion}>
            Delete carts
          </M.Button>
        </div>
      </div>
    </div>
  );
}

const columns = [
  {
    accessor: "lastUpdated",
    Header: "Last updated",
    Cell: (table: any) => {
      return dayjs(table.value).format("MM/DD/YYYY");
    },
  },
  {
    accessor: "customerName",
    Header: "Customer name",
  },
  {
    accessor: "salesAssistantName",
    Header: "Sales assistant name",
  },
  {
    accessor: "total",
    Header: "Total",
    isNumeric: true,
    Cell: "money",
  },
  {
    accessor: "status",
    Header: "Status",
    Cell: ({ value }) => {
      return <M.Badge color="orange">{value}</M.Badge>;
    },
  },
];

//
// type CartsTableProps = {
//   carts: Cart[];
// };
// function CartsTable(props: CartsTableProps) {
//
//   const table = useReactTable({
//     data: tableData,
//     columns,
//     getCoreRowModel: getCoreRowModel(),
//   });
//
//   return (
//     <div>
//       <M.Table
//         verticalSpacing="md"
//         className="[&>tbody>tr>td]:border-slate-100 [&>thead>tr>th]:border-slate-100"
//       >
//         <thead>
//           {table.getHeaderGroups().map((headerGroup) => (
//             <tr key={headerGroup.id}>
//               {headerGroup.headers.map((header) => (
//                 <th key={header.id}>
//                   {header.isPlaceholder
//                     ? null
//                     : flexRender(
//                         header.column.columnDef.header,
//                         header.getContext()
//                       )}
//                 </th>
//               ))}
//             </tr>
//           ))}
//         </thead>
//         <tbody>
//           {table.getRowModel().rows.map((row) => (
//             <tr key={row.id}>
//               {row.getVisibleCells().map((cell) => (
//                 <td key={cell.id}>
//                   {flexRender(cell.column.columnDef.cell, cell.getContext())}
//                 </td>
//               ))}
//             </tr>
//           ))}
//         </tbody>
//       </M.Table>
//     </div>
//   );
// }
//
// type CartOnHoldRow = {
//   lastUpdated: Date;
//   customerName: string;
//   salesAssistantName: string;
//   total: string;
//   status: string;
// };
//
// const columnHelper = createColumnHelper<CartOnHoldRow>();
//
// const columns = [
//   columnHelper.accessor("lastUpdated", {
//     header: "Last updated",
//     cell: (table) => {
//       return dayjs(table.getValue()).format("MM/DD/YYYY");
//     },
//   }),
//   columnHelper.accessor("customerName", {
//     header: "Customer name",
//   }),
//   columnHelper.accessor("salesAssistantName", {
//     header: "Sales assistant name",
//   }),
//   columnHelper.accessor("total", {
//     header: "Total",
//   }),
//   columnHelper.accessor("status", {
//     header: "Status",
//   }),
// ];
