import logo from "#/images/colored-logo-invoice.png";
import * as M from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { FormProvider, useForm } from "react-hook-form";

export const QRForm = () => {
  const form = useForm();
  const isDesktop = useMediaQuery("(min-width: 800px)");

  return (
    <M.Group
      noWrap
      position="center"
      align="start"
      spacing={0}
      sx={{
        borderTop: "solid 8px #A67744",
      }}
    >
      <M.Stack
        py="xl"
        px={isDesktop ? 80 : 48}
        w="100%"
        maw={isDesktop ? 600 : "auto"}
        sx={{ flex: 1 }}
      >
        <M.Center>
          <M.Image src={logo} maw={200} mt={isDesktop ? 100 : 0} />
        </M.Center>
        <M.Text color="gray.8" weight="bold" mt={48}>
          Hey!
        </M.Text>
        <M.Text>
          Sign up to receive many benefits and discounts for our store
        </M.Text>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit((v) => console.log(v))}>
            <M.Stack>
              <M.TextInput placeholder="Name" />
              <M.TextInput placeholder="Last name" />
              <M.Group noWrap spacing="xs">
                <M.NumberInput
                  placeholder="+1"
                  parser={(v) => v?.replace(/^\+/g, "")}
                  formatter={(v) =>
                    v && !Number.isNaN(Number.parseFloat(v)) ? `+${v}` : ""
                  }
                  hideControls
                  w="8ch"
                />
                <M.TextInput placeholder="Phone number" sx={{ flexGrow: 1 }} />
              </M.Group>
              <M.Button
                color="gray.8"
                py="0.25em"
                sx={{ boxSizing: "content-box" }}
              >
                Submit
              </M.Button>
            </M.Stack>
          </form>
        </FormProvider>
      </M.Stack>
    </M.Group>
  );
};
