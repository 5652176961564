import { trpc } from "#/trpc.js";
import { reportUserError, reportUserSuccess } from "#/util/index.js";
import DiscountForm, { DiscountFormValues } from "./Form.js";
import Decimal from "decimal.js";
import React from "react";
import { useNavigate } from "react-router";

export default function CreateDiscount() {
  const navigate = useNavigate();
  const ctx = trpc.useContext();
  const { mutate, isLoading } = trpc.discount.pos.create.useMutation({
    onSuccess(data) {
      reportUserSuccess({
        title: "Discount created successfully",
      });
      ctx.discount.invalidate();
      ctx.v2_5.discounts.invalidate();
      if (data.discountMode === "COUPON") {
        navigate(
          `/inventory/coupons/create?discount=${encodeURIComponent(
            data.reference,
          )}`,
        );
      } else {
        navigate("/inventory/discounts");
      }
    },
    onError(error) {
      reportUserError({
        title: "Failed to create discount",
        message: error.message,
      });
    },
  });

  const handleSubmit = React.useCallback(
    (discount: DiscountFormValues) => {
      for (const rate of discount.rates) {
        mutate({
          reference: discount.ref,
          amount: new Decimal(rate.amount),
          type: rate.type,
          redeemable: rate.redeemable,
          minPurchase: rate.minPurchase != null
            ? new Decimal(rate.minPurchase)
            : undefined,
          minPurchaseForEligibility: rate.minPurchaseForEligibility != null
            ? new Decimal(rate.minPurchaseForEligibility)
            : undefined,
          discountMode: discount.discountMode,
          itemSkus: discount.itemSkus.map((isku) => isku.id),
          filterMode: discount.filterMode,
          departments: discount.departments.map((dept) => dept.id),
          activeDateRange:
            discount.discountMode === "SEASONAL"
              ? [discount.activeDateRange[0]!, discount.activeDateRange[1]!]
              : null,
          validOn: discount.validOn,
        });
      }
    },
    [mutate],
  );
  return (
    <DiscountForm onSubmit={handleSubmit} isLoading={isLoading} mode="create" />
  );
}
