import { DefaultValues, FormProvider, SubmitHandler } from "react-hook-form";
import {
  CCreateQuestion,
  createQuestionSchema,
  FormQuestionCreate,
} from "./types";
import { zodResolver } from "@hookform/resolvers/zod";
import * as M from "@mantine/core";
import { css } from "#/css/css";

interface QuestionFormDrawerProps {
  open: boolean;
  close: () => void;
  defaultValues?: DefaultValues<FormQuestionCreate>;
  isLoading: boolean;
  onSubmit: SubmitHandler<FormQuestionCreate>;
  title: string;
}

export const QuestionFormDrawer = ({
  open,
  close,
  defaultValues,
  isLoading,
  onSubmit,
  title,
}: QuestionFormDrawerProps) => {
  const form = CCreateQuestion.useForm({
    resolver: zodResolver(createQuestionSchema),
    shouldUnregister: false,
    defaultValues: {
      question: "",
      questionEsp: "",
      ...defaultValues,
    },
  });

  return (
    <M.Drawer
      position="right"
      opened={open}
      onClose={close}
      title={<M.Title order={3}>{title}</M.Title>}
    >
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit((values) => {
            form.reset();
            onSubmit(values);
          })}
        >
          <M.Stack spacing={30}>
            <CCreateQuestion.InputField
              name="question"
              label="Question *"
              placeholder="Enter Question"
            />
            <CCreateQuestion.InputField
              name="questionEsp"
              label="Pregunta"
              placeholder="Introduzca la pregunta"
            />
            <M.Button
              type="submit"
              loading={isLoading}
              className={css({ width: "fit" })}
            >
              Save question
            </M.Button>
          </M.Stack>
        </form>
      </FormProvider>
    </M.Drawer>
  );
};
