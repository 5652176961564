import { Input as ChakraInput } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import React from "react";

const Input = React.forwardRef(({ name, ...props }, ref) => {
  const formContext = useFormContext();
  const registerProps =
    props.type === "number"
      ? {
          valueAsNumber: true,
        }
      : {};
  const formProps = formContext
    ? formContext.register(name, registerProps)
    : {};

  return <ChakraInput ref={ref} {...formProps} {...props} />;
});

Input.id = "Input";

export default Input;
