import { trpc } from "#/trpc";
import { reportUserSuccess } from "#/util";
import { useNavigate, useParams } from "react-router";
import SupplierForm from "./Form/supplierForm";
import { FormValues } from "./Form/types";
import * as M from "@mantine/core";
import GTLoader from "#/components-ng/loader";

export const UpdateSupplier = () => {
  const navigate = useNavigate();
  const params = useParams() as any;
  const ctx = trpc.useContext();
  const id = params.id ? Number(params.id) : null;

  const {
    data: supplier,
    isLoading,
    isFetchedAfterMount,
  } = trpc.v2_5.supplier.getSupplierById.useQuery(
    {
      id: id!,
    },
    {
      enabled: !!id,
      cacheTime: 0,
    },
  );

  const { mutate, isLoading: isLoadingUpdate } =
    trpc.v2_5.supplier.updateById.useMutation({
      onSuccess() {
        ctx.v2_5.supplier.invalidate();
        reportUserSuccess({
          title: "Supplier updated",
        });
        navigate(`/inventory/suppliers`);
      },
    });

  const handleSubmit = (values: FormValues) => {
    mutate({
      id: id!,
      name: values.name,
      description: values.description,
      phoneNumber: values.phoneNumber,
      address: values.address,
    });
  };

  return isLoading && !isFetchedAfterMount ? (
    <M.LoadingOverlay
      visible={isLoading || !isFetchedAfterMount}
      loader={<GTLoader width={100} height={100} />}
    />
  ) : (
    <SupplierForm
      isLoading={isLoadingUpdate}
      defaultValues={{
        name: supplier?.name,
        description: supplier?.description,
        phoneNumber: supplier?.phoneNumber,
        address: supplier?.address,
      }}
      onSubmit={handleSubmit}
    />
  );
};
