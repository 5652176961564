import {
  BankTransferCustomIcon,
  CashPaymentMethodCustomIcon,
  PaypalCustomIcon,
  UserCreditsPaymentMethodCustomIcon,
  ZelleCustomIcon,
} from "#/components-ng/index.js";
import {
  AmericanExpressIcon,
  DiscoverIcon,
  MastercardIcon,
  VisaIcon,
} from "#/components/icons/index.jsx";
import React from "react";
import { PaymentType } from "server";

interface RefundMethodInterface {
  label: string;
  icon: React.ReactNode;
  value: PaymentType;
}

export const refundMethodsMap: RefundMethodInterface[] = [
  {
    label: "User credits",
    icon: <UserCreditsPaymentMethodCustomIcon />,
    value: "USER_CREDITS",
  },
  {
    label: "Cash",
    icon: <CashPaymentMethodCustomIcon />,
    value: "CASH",
  },
  {
    label: "Visa",
    icon: <VisaIcon />,
    value: "VISA",
  },
  {
    label: "Mastercard",
    icon: <MastercardIcon />,
    value: "MASTERCARD",
  },
  {
    label: "American Express",
    icon: <AmericanExpressIcon />,
    value: "AMERICAN_EXPRESS",
  },
  {
    label: "Discover",
    icon: <DiscoverIcon />,
    value: "DISCOVER",
  },
  {
    label: "Zelle",
    icon: <ZelleCustomIcon />,
    value: "ZELLE",
  },
  {
    label: "PayPal",
    icon: <PaypalCustomIcon />,
    value: "PAYPAL",
  },
  {
    label: "Bank transfer",
    icon: <BankTransferCustomIcon />,
    value: "BANK_TRANSFER",
  },
];
