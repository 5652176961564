import GTLoader from "#/components-ng/loader.js";
import { useAuth } from "#/context/AuthContext";
import { trpc } from "#/trpc.js";
import { reportUserError, reportUserSuccess } from "#/util/ux/index.js";
import BlogForm from "./Form/Form";
import * as M from "@mantine/core";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";

export default function UpdatePost() {
  const { id: _id } = useParams<{ id: string }>();
  const id = Number.parseInt(_id!);
  const navigate = useNavigate();
  const ctx = trpc.useContext();
  const [{ auth }] = useAuth();
  const { data, isLoading, isFetchedAfterMount } =
    trpc.v2_5.blog.getById.useQuery(
      {
        id,
      },
      {
        cacheTime: 0,
        refetchOnWindowFocus: false,
        refetchInterval: false,
        onError(error) {
          reportUserError({
            title: "Failed to load post",
            message: error.message,
          });
        },
      }
    );
  const { mutate } = trpc.v2_5.blog.update.useMutation({
    onSuccess() {
      reportUserSuccess({ title: "Post updated successfully" });
      ctx.v2_5.blog.invalidate();
      navigate("/ecommerce-crm/blog");
    },
    onError(error) {
      reportUserError({
        title: "Failed to update post",
        message: error.message,
      });
    },
  });

  const handleSubmit = (post) => {
    mutate({
      id: id,
      title: post.title,
      titleEsp: post.titleEsp ?? null,
      image: post.image ?? null,
      author: auth.user.id,
      text: post.text,
      textEsp: post.textEsp ?? null,
      blogExternalUrl: post.blogExternalUrl ?? null,
      seasonsIds: post.seasons?.map((season) => season.id) ?? [],
      tagsIds: post.tags?.map((tag) => tag.id) ?? [],
      catalogIds: post.catalogs?.map((catalog) => catalog.id) ?? [],
    });
  };

  return !isLoading || isFetchedAfterMount ? (
    <BlogForm
      title="Update post"
      titleSubmit="Update post"
      defaultValues={{
        title: data!.post!.title,
        titleEsp: data?.post?.titleEsp ?? undefined,
        image: data?.post?.image ?? undefined,
        text: data!.post!.text,
        textEsp: data?.post?.textEsp ?? undefined,
        blogExternalUrl: data?.post?.blogExternalUrl ?? undefined,
        seasons:
          data?.post?.blogSeasons?.map((s) => ({
            id: s.seasonId,
            name: s.season.name,
          })) ?? [],
        tags:
          data?.post?.blogTags?.map((t) => ({
            id: t.tagId,
            name: t.tag.name,
          })) ?? [],
        catalogs:
          data?.post?.blogCatalogs?.map((c) => ({
            id: c.catalogId,
            name: c.catalog.title,
          })) ?? [],
      }}
      onSubmit={handleSubmit}
      isLoading={isLoading}
    />
  ) : (
    <M.LoadingOverlay
      visible={isLoading}
      loader={<GTLoader width={100} height={100} />}
    />
  );
}
