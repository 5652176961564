import SceneLayout from "#/components/SceneLayout/index.jsx";
import { AiOutlineLineChart } from "react-icons/ai/index.js";
import { Outlet } from "react-router";

export const ReportsLayout = () => {
  return (
    <SceneLayout icon={<AiOutlineLineChart />}>
      <Outlet />
    </SceneLayout>
  );
};
