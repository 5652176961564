import styled from "styled-components";
import GenericDateRange from "react-date-range/dist/components/DateRange";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import React from "react";

const DateRange = React.memo(
  /** @param {import("react-date-range").DateRangeProps} props */ function (
    props
  ) {
    return (
      <DateRangeWrapper>
        <GenericDateRange
          editableDateInputs={true}
          moveRangeOnFirstSelection={false}
          {...props}
        />
      </DateRangeWrapper>
    );
  }
);

export default DateRange;

const DateRangeWrapper = styled.div`
  & > * {
    .rdrSelected,
    .rdrInRange,
    .rdrStartEdge,
    .rdrEndEdge {
      background-color: ${({ theme }) => theme.primary};
    }
  }
`;
