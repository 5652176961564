import * as M from "@mantine/core";

export default function FilialCard({ filial }) {
  return (
    <M.Stack
      sx={{
        borderRadius: "10px",
        overflow: "hidden",
        transition: "all 0.07s ease-in-out",
        "&:hover": { transform: "scale(1.07)" },
      }}
      spacing={2}
      align="center"
      justify="start"
      bg="rgba(0, 0, 0, 0.02)"
      h="190px"
      w="170px"
    >
      <M.AspectRatio ratio={1 / 2} w="184px" h="140px">
        <M.Image
          classNames={{
            figure: "h-full w-full",
            imageWrapper: "h-full",
          }}
          height="100%"
          src={filial.avatar}
          fit="cover"
        />
      </M.AspectRatio>
      <M.Flex h="40px" align="center" justify="center">
        <M.Text lineClamp={2} align="center" p="0 5px">
          {filial.name}
        </M.Text>
      </M.Flex>
    </M.Stack>
  );
}
