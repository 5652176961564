import { useAuth } from "#/context/AuthContext";
import { css } from "#/css/css";
import placeholderImage from "#/placeholder-image.jpg";
import { RouterOutputs, trpc } from "#/trpc";
import { reportUserSuccess } from "#/util";
import * as M from "@mantine/core";
import { IconButton, Tooltip } from "@radix-ui/themes";
import {
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_SortingState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import { MdOutlineDownload } from "react-icons/md";
import CheckIcon from "~icons/ic/baseline-check";
import CloseIcon from "~icons/ic/baseline-close";

type ItemSkuStock = NonNullable<
  RouterOutputs["v2_5"]["itemSku"]["getItemSkuReorders"]
>["entries"][number];

export const ItemListReorders = () => {
  const [{ auth }] = useAuth();

  // pagination
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 25,
  });

  //  filial selected
  const [filialSelected, setFilialSelected] = useState<number | null>(
    auth?.user?.filialId ?? 1,
  );

  // sorting
  const [sorting, setSorting] = useState<MRT_SortingState>([]);

  const querySorting = useMemo(() => {
    if (sorting[0]) {
      return {
        desc: sorting[0].desc,
        key: sorting[0].id as any,
      };
    }
    return null;
  }, [sorting]);

  // Filtering
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    [],
  );

  const queryFilters = useMemo(() => {
    const filters: {
      sku?: string | null;
    } = {};

    for (const columnFilter of columnFilters) {
      if (columnFilter.id === "sku") {
        filters.sku = `"${columnFilter.value}"`;
      }
    }

    return filters;
  }, [columnFilters]);

  const { data: filials } = trpc.filial.getAll.useQuery();

  const { data, isLoading } = trpc.v2_5.itemSku.getItemSkuReorders.useQuery(
    {
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize,
      sorting: querySorting,
      filters: {
        filialId: filialSelected ?? 0,
        sku: queryFilters?.sku ?? null,
      },
    },
    {
      keepPreviousData: true,
    },
  );

  const { mutate: exportCsv, isLoading: isLoadingExport } =
    trpc.v2_5.itemSku.exportItemReordersTableToCsv.useMutation({
      onSuccess: () => {
        reportUserSuccess({
          title: "The csv file has been sent to email",
        });
      },
    });

  const handleExportCsv = () => {
    exportCsv({
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize,
      sorting: querySorting,
      filters: {
        filialId: filialSelected ?? 0,
        sku: queryFilters?.sku ?? null,
      },
    });
  };

  const filialOptions = useMemo(
    () =>
      filials?.map((filial) => ({
        value: filial.id.toString(),
        label: filial.name,
      })) ?? [],
    [filials],
  );

  const table = useMantineReactTable({
    data: data?.entries ?? [],
    columns,
    manualPagination: true,
    enableTopToolbar: false,
    rowCount: data?.totalEntries ?? 0,
    enableFilters: true,
    state: {
      pagination,
      isLoading,
      sorting,
      columnFilters,
    },
    enableStickyHeader: true,
    manualSorting: true,
    manualFiltering: true,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    enableFilterMatchHighlighting: false,
    initialState: {
      showColumnFilters: true,
    },
    mantineFilterTextInputProps() {
      return {
        classNames: {
          input: "px-2 font-normal text-sm",
        },
      };
    },
    renderBottomToolbarCustomActions: () => (
      <div
        className={css({
          display: "flex",
          alignItems: "center",
          gap: 2,
        })}
      >
        <Tooltip content="Export to CSV">
          <IconButton
            color="gray"
            variant="soft"
            loading={isLoadingExport}
            onClick={handleExportCsv}
          >
            <MdOutlineDownload />
          </IconButton>
        </Tooltip>
      </div>
    ),
  });

  return (
    <M.Box>
      <M.Group>
        {!auth?.user?.filialId && (
          <M.Select
            data={filialOptions}
            value={filialSelected?.toString() ?? ""}
            onChange={(e) => setFilialSelected(Number(e))}
          />
        )}
      </M.Group>
      <M.Box mt={16} sx={{ background: "white" }}>
        <MantineReactTable table={table} />
      </M.Box>
    </M.Box>
  );
};

const columns: Array<MRT_ColumnDef<ItemSkuStock>> = [
  {
    accessorKey: "itemSku.defaultImage",
    header: "Image",
    enableSorting: false,
    enableColumnFilter: false,
    Cell(props) {
      const original = props.row.original;
      const image = props.row.original?.itemSku?.defaultImage
        ? props.row.original?.itemSku?.defaultImage
        : original?.itemSku?.images?.length > 0
          ? original.itemSku.images[0]
          : null;

      return (
        <img src={image ?? placeholderImage} className="w-11 cursor-pointer" />
      );
    },
  },
  {
    id: "sku",
    accessorKey: "itemSku.sku",
    header: "Sku",
  },
  {
    id: "name",
    accessorKey: "itemSku.title",
    header: "Name",
    enableColumnFilter: false,
  },
  {
    accessorKey: "quantity",
    header: "Quantity",
    enableColumnFilter: false,
  },
  {
    accessorKey: "reorder",
    header: "Reorder",
    enableColumnFilter: false,
  },
  {
    id: "price",
    accessorKey: "itemSku.price",
    header: "Price",
    accessorFn: (row) => "$" + row.itemSku?.price?.toNumber().toFixed(2),
    enableColumnFilter: false,
  },
  {
    accessorKey: "isLine",
    header: "Is Line?",
    enableColumnFilter: false,
    Cell(props) {
      const original = props.row.original;
      const isLine = original.isLine;

      return (
        <M.Box c={isLine ? "lime.7" : "red.4"}>
          {isLine ? <CheckIcon /> : <CloseIcon />}
        </M.Box>
      );
    },
  },
];
