export const getTimezones = () => {
  const timezones = Intl.supportedValuesOf("timeZone");
  const timezoneList: { label: string; value: string }[] = [];

  for (const tz of timezones) {
    const parts = Intl.DateTimeFormat([], {
      timeZone: tz,
      timeZoneName: "short",
    }).formatToParts();
    const offsetPart = parts.find((part) => part.type === "timeZoneName");
    const offset = offsetPart ? offsetPart.value : "";
    const label = `(UTC ${offset}) - ${tz}`;
    const value = tz;
    timezoneList.push({ label, value });
  }

  return timezoneList;
};
