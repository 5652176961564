import { FormValueCreate } from "./types";
import * as M from "@mantine/core";
import { DefaultValues } from "react-hook-form";
import { ActionsMenuIcon } from "#/components-ng";
import { RouterOutputs, trpc } from "#/trpc";
import { useDisclosure } from "@mantine/hooks";
import {
  MantineReactTable,
  MRT_ColumnDef,
  useMantineReactTable,
} from "mantine-react-table";
import { reportUserError, reportUserSuccess } from "#/util";
import { ValueFormDrawer } from "./quiz-value-form-drawer";
import { openConfirmModal } from "@mantine/modals";
import { match } from "ts-pattern";
import { css } from "#/css/css";

export default function QuizValuesSection({ quizId }: { quizId: number }) {
  const [opened, { open, close }] = useDisclosure(false);

  const { data, isLoading, isFetchedAfterMount } =
    trpc.v2_5.quiz.getValuesByQuizId.useQuery(
      {
        id: quizId,
      },
      {
        cacheTime: 0,
      },
    );

  return (
    <M.Stack w="100%" bg={"white"} spacing={10} my={32}>
      <M.Title bg={"white"} fw={600} px={40} py={24} w={"100%"} fz="1.2rem">
        Create values
      </M.Title>
      <M.Divider />
      <M.Box w="100%" px={40} py={24}>
        <ValuesTable
          values={data ?? []}
          isLoading={isLoading}
          isFetchedAfterMount={isFetchedAfterMount}
        />
      </M.Box>
      {opened && <CreateValue open={opened} close={close} quizId={quizId} />}
      <M.Button
        onClick={open}
        mb={30}
        ml={40}
        className={css({ width: "fit" })}
      >
        Add new value
      </M.Button>
    </M.Stack>
  );
}

function CreateValue({
  open,
  close,
  quizId,
}: {
  open: boolean;
  close: () => void;
  quizId: number;
}) {
  const ctx = trpc.useContext();

  const { mutate, isLoading } = trpc.v2_5.quiz.createValue.useMutation({
    onSuccess: () => {
      reportUserSuccess({ title: "Value created successfully" });
      ctx.v2_5.quiz.getValuesByQuizId.invalidate();
      close();
    },
  });

  const handleSubmit = (values: FormValueCreate) => {
    const validate = validateType(values);
    if (validate) {
      reportUserError({ title: validate });
      return;
    }

    mutate({
      quizId: quizId,
      title: values.title,
      description: values.description,
      type: values.type,
      blogId: values.blogId ? Number(values.blogId) : null,
      catalogId: values.catalogId ? Number(values.catalogId) : null,
      promotionId: values.promotionId ? Number(values.promotionId) : null,
      departmentId: values.departmentId ? Number(values.departmentId) : null,
      categoryId: values.categoryId ? Number(values.categoryId) : null,
      seasonId: values.seasonId ? Number(values.seasonId) : null,
      titleEsp: values.titleEsp,
      descriptionEsp: values.descriptionEsp,
    });
    close();
  };

  return (
    <ValueFormDrawer
      open={open}
      close={close}
      isLoading={isLoading}
      onSubmit={handleSubmit}
      title="Add value"
    />
  );
}

function UpdateValue({
  open,
  close,
  valueId,
  defaultValues,
}: {
  open: boolean;
  close: () => void;
  valueId: number;
  defaultValues: DefaultValues<FormValueCreate>;
}) {
  const ctx = trpc.useContext();

  const { mutate, isLoading } = trpc.v2_5.quiz.updateValue.useMutation({
    onSuccess: () => {
      reportUserSuccess({ title: "Value updated successfully" });
      ctx.v2_5.quiz.getValuesByQuizId.invalidate();
      close();
    },
  });

  const handleSubmit = (values: FormValueCreate) => {
    const validate = validateType(values);
    if (validate) {
      reportUserError({ title: validate });
      return;
    }

    mutate({
      id: valueId,
      title: values.title,
      description: values.description,
      type: values.type,
      blogId: values.blogId ? Number(values.blogId) : null,
      catalogId: values.catalogId ? Number(values.catalogId) : null,
      promotionId: values.promotionId ? Number(values.promotionId) : null,
      departmentId: values.departmentId ? Number(values.departmentId) : null,
      categoryId: values.categoryId ? Number(values.categoryId) : null,
      seasonId: values.seasonId ? Number(values.seasonId) : null,
      titleEsp: values.titleEsp,
      descriptionEsp: values.descriptionEsp,
    });
    close();
  };

  return (
    <ValueFormDrawer
      open={open}
      close={close}
      isLoading={isLoading}
      onSubmit={handleSubmit}
      title="Update value"
      defaultValues={defaultValues}
    />
  );
}

type QuizValues = RouterOutputs["v2_5"]["quiz"]["getValuesByQuizId"];

interface QuizValuesProps {
  values: QuizValues;
  isLoading: boolean;
  isFetchedAfterMount: boolean;
}

const ValuesTable = ({
  values,
  isFetchedAfterMount,
  isLoading,
}: QuizValuesProps) => {
  const table = useMantineReactTable({
    data: values ?? [],
    columns: columns,
    enableTopToolbar: false,
    enableSorting: false,
    enableFilters: false,
    state: {
      isLoading: isLoading || !isFetchedAfterMount ? true : false,
    },
    enableColumnActions: false,
    enableColumnFilters: false,
    enablePagination: false,
  });

  return <MantineReactTable table={table} />;
};

type QuizValue = QuizValues[number];

const columns: MRT_ColumnDef<QuizValue>[] = [
  {
    id: "actions",
    header: "Actions",
    Cell(table) {
      const [opened, { open, close }] = useDisclosure(false);
      const ctx = trpc.useContext();
      const original = table.row.original;

      const { mutate: deleteValue } = trpc.v2_5.quiz.deleteValue.useMutation({
        onSuccess: () => {
          ctx.v2_5.quiz.getValuesByQuizId.invalidate();
        },
      });

      const openConfirmDeleteModal = () =>
        openConfirmModal({
          title: "Are you sure you want to delete this value?",
          labels: { cancel: "Cancel", confirm: "Delete" },
          confirmProps: { color: "red" },
          onConfirm: () => deleteValue({ id: original.id }),
        });

      return (
        <>
          <M.Menu withinPortal>
            <M.Menu.Target>
              <M.ActionIcon>
                <ActionsMenuIcon />
              </M.ActionIcon>
            </M.Menu.Target>
            <M.Menu.Dropdown>
              <M.Menu.Item onClick={open}>Edit</M.Menu.Item>
              <M.Menu.Item onClick={openConfirmDeleteModal}>Delete</M.Menu.Item>
            </M.Menu.Dropdown>
          </M.Menu>
          {opened && (
            <UpdateValue
              open={opened}
              close={close}
              valueId={original.id}
              defaultValues={{
                title: original.title,
                description: original.description,
                type: original.type as any,
                blogId: original.blogId?.toString() ?? null,
                catalogId: original.catalogId?.toString() ?? null,
                promotionId: original.promotionId?.toString() ?? null,
                departmentId: original.departmentId?.toString() ?? null,
                categoryId: original.categoryId?.toString() ?? null,
                seasonId: original.seasonId?.toString() ?? null,
                titleEsp: original.titleEsp ?? null,
                descriptionEsp: original.descriptionEsp ?? null,
              }}
            />
          )}
        </>
      );
    },
  },
  {
    accessorKey: "title",
    header: "Title",
  },
  {
    accessorKey: "description",
    header: "Description",
  },
  {
    id: "type",
    header: "Type",
    Cell(table) {
      const original = table.row.original;
      const type = original.type;

      return match(type)
        .with("BLOG", () => `Blog - ${original.blog?.title}`)
        .with("CATALOG", () => `Catalog - ${original.catalog?.title}`)
        .with("PROMOTION", () => `Promotion - ${original.promotion?.name}`)
        .with("DEPARTMENT", () => `Department - ${original.department?.name}`)
        .with("CATEGORY", () => `Category - ${original.category?.name}`)
        .with("SEASON", () => `Season - ${original.season?.name}`)
        .otherwise(() => "");
    },
  },
];

const validateType = (values: FormValueCreate) => {
  if (values.type === "BLOG" && !values.blogId) {
    return "Blog is required";
  }
  if (values.type === "CATALOG" && !values.catalogId) {
    return "Catalog is required";
  }
  if (values.type === "PROMOTION" && !values.promotionId) {
    return "Promotion is required";
  }
  if (values.type === "DEPARTMENT" && !values.departmentId) {
    return "Department is required";
  }
  if (values.type === "CATEGORY" && !values.categoryId) {
    return "Category is required";
  }
  if (values.type === "SEASON" && !values.seasonId) {
    return "Season is required";
  }
  return null;
};
