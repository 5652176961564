import { FilterOutlineIcon } from "#/components-ng/index.js";
import { useState } from "react";
import { HStack, styled } from "#/css/jsx";
import { css } from "#/css/css";
import { Button, Checkbox, Dropdown } from "@gt/ui";
import { Link } from "react-router-dom";
import { DropdownMenu } from "@radix-ui/themes";
import { SearchButton } from "#/components@v2_5/search-button.js";
import { CatalogsTable } from "./table.js";

interface RelationalFilter {
  RELATIONAL: boolean;
  NON_RELATIONAL: boolean;
}

export function Catalog() {
  const [query, setQuery] = useState<string | null>(null);
  const [filters, setFilters] = useState<RelationalFilter>({
    RELATIONAL: true,
    NON_RELATIONAL: true,
  });

  return (
    <>
      <styled.div mb={6} display="flex" justifyContent="space-between">
        <HStack justifyContent="space-between">
          <PrincipalActionsButton />
          <RelationalFilter filters={filters} setFilters={setFilters} />
        </HStack>
        <SearchButton setQuery={setQuery} />
      </styled.div>
      <CatalogsTable filter={filters} search={query} />
    </>
  );
}

function PrincipalActionsButton() {
  return (
    <Dropdown.Root>
      <Dropdown.Trigger asChild>
        <Button
          className={css({
            gap: 4,
          })}
        >
          I Want to
          <DropdownMenu.TriggerIcon />
        </Button>
      </Dropdown.Trigger>
      <Dropdown.Content>
        <Dropdown.Item asChild>
          <Link to="create">Create new catalog item</Link>
        </Dropdown.Item>
      </Dropdown.Content>
    </Dropdown.Root>
  );
}

interface RelationalFilterProps {
  filters: RelationalFilter;
  setFilters: React.Dispatch<React.SetStateAction<RelationalFilter>>;
}

function RelationalFilter({ filters, setFilters }: RelationalFilterProps) {
  return (
    <Dropdown.Root>
      <Dropdown.Trigger>
        <Button variant="secondary">
          <FilterOutlineIcon className="mr-2" />
          Filter
        </Button>
      </Dropdown.Trigger>
      <Dropdown.Content>
        {Object.keys(filters).map((filter) => (
          <Dropdown.Item
            key={filter}
            className={css({
              gap: 2,
              display: "flex",
              alignItems: "center",
            })}
            onSelect={(e) => {
              e.preventDefault();
              setFilters({
                ...filters,
                [filter]: !filters[filter],
              });
            }}
          >
            <Checkbox checked={filters[filter]} />
            <span>{filter}</span>
          </Dropdown.Item>
        ))}
      </Dropdown.Content>
    </Dropdown.Root>
  );
}
