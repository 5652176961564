import { InventoryAdjustmentModal } from "./components/InventoryAdjustmentModal";
import * as M from "@mantine/core";
import { useMantineTheme } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import dayjs from "dayjs";
import { MdMoreHoriz, MdVisibility } from "react-icons/md/index.js";
import { Link } from "react-router-dom";
import ZebraBrowserPrintWrapper from "zebra-browser-print-wrapper";
import EditIcon from "~icons/ion/create-outline";
import PrintIcon from "~icons/ion/print-outline";

export const createLabelTemplate = ({ tag, guide, date, reason }) => `
^XA

^CFA,10
^FB233,,,L^FO20,15^FDDate: ${guide}\\&^FS

^CFA,10
^FB233,,,R^FO0,15^FDGuide: ${guide}    \\&^FS

^CF0,16
^FB233,,,C^FO0,60^FDTag:\\&^FS
^CFB, 16
^FB233,,,C^FO0,80^FD${tag}\\&^FS

^CF0, 16
^FB233,,,C^FO0,120^FDReason:\\&^FS
^CFB, 16
^FB233,,,C^FO0,140^FD${reason}\\&^FS


^CFA,10
^FB233,,,L^FO253,15^FDDate: ${date}\\&^FS

^CFA,10
^FB233,,,R^FO233,15^FDGuide: ${guide}    \\&^FS

^CF0,16
^FB233,,,C^FO233,60^FDTag:\\&^FS
^CFB, 16
^FB233,,,C^FO233,80^FD${tag}\\&^FS

^CF0, 16
^FB233,,,C^FO233,120^FDReason:\\&^FS
^CFB, 16
^FB233,,,C^FO233,140^FD${reason}\\&^FS

^XZ
`;

const createColumns = ({
  printer,
  loadingPrinter,
}: {
  printer: ZebraBrowserPrintWrapper;
  loadingPrinter: boolean;
}) => [
  {
    Header: "Actions",
    id: "actions",
    Cell: ({ row: { original } }) => {
      const [opened, setOpened] = useState(false);

      const handlePrintLabel = () => {
        if (printer) {
          printer.print(
            createLabelTemplate({
              tag: original.tagName,
              guide: original.id,
              date: dayjs(original.createdAt).format("MM/DD/YYYY"),
              reason: original.adjustmentType,
            })
          );
        } else {
          showNotification({
            title: "Zebra printer not connected.",
            message: "Make sure Zebra Browser Print is running and try again.",
            color: "red",
          });
        }
      };

      return (
        <M.Group noWrap>
          {original.adjustmentStatus === "DRAFT" ? (
            <M.Menu>
              <M.Menu.Target>
                <M.ActionIcon>
                  <MdMoreHoriz />
                </M.ActionIcon>
              </M.Menu.Target>
              <M.Menu.Dropdown>
                <M.Menu.Item
                  component={Link}
                  to={`/inventory/adjust/edit/${original.id}`}
                  icon={<EditIcon />}
                >
                  Edit
                </M.Menu.Item>
                <M.Menu.Item
                  disabled={loadingPrinter}
                  onClick={handlePrintLabel}
                  icon={<PrintIcon />}
                >
                  <M.Group>
                    <M.Text>Print label</M.Text>
                    {loadingPrinter && <M.Loader size="xs" />}
                  </M.Group>
                </M.Menu.Item>
              </M.Menu.Dropdown>
            </M.Menu>
          ) : (
            <></>
          )}
          <InventoryAdjustmentModal
            opened={opened}
            onClose={() => setOpened(false)}
            adjustment={original}
          />
          <M.ActionIcon onClick={() => setOpened(true)}>
            <MdVisibility />
          </M.ActionIcon>
        </M.Group>
      );
    },
  },
  {
    Header: "Date",
    accessor: "createdAt",
    isDate: true,
    Cell: "date",
  },
  {
    Header: "Reason",
    accessor: "tagName",
  },
  {
    Header: "Guide",
    accessor: "id",
    isNumeric: true,
    Cell: ({ row: { original } }) => {
      return original.adjustmentStatus === "DRAFT" ? (
        <M.Text>DRAFT</M.Text>
      ) : (
        <M.Text>{original.id}</M.Text>
      );
    },
  },
  {
    Header: "Products qty",
    accessor: "adjustmentItemSku.length",
    isNumeric: true,
  },
  {
    Header: "New total qty",
    accessor: "newQtyTotal",
    isNumeric: true,
  },
  {
    Header: "Old total qty",
    accessor: "oldQtyTotal",
    isNumeric: true,
  },
  {
    Header: "Diff total qty",
    accessor: "diffQtyTotal",
    isNumeric: true,
    Cell: ({ value }) => (
      <M.Text color={value < 0 ? "red.5" : "green.5"}>{value}</M.Text>
    ),
  },
  {
    Header: "Diff total cost",
    accessor: "diffCostTotal",
    isNumeric: true,
    PrintCell: "money",
    Cell: ({ value }) => (
      <M.Text color={value < 0 ? "red.5" : "green.5"}>
        ${value?.toNumber()?.toFixed(2) ?? 0}
      </M.Text>
    ),
  },
  {
    Header: "Status",
    accessor: "adjustmentStatus",
    Cell: ({ value }) => {
      const theme = useMantineTheme();
      const color =
        value === "DRAFT" ? theme.colors.orange : theme.colors.green;
      return (
        <M.Text
          sx={{
            color: color[6],
            background: color[0],
            borderRadius: "1rem",
            padding: "0.15rem 0.75rem",
            textAlign: "center",
          }}
        >
          {value}
        </M.Text>
      );
    },
  },
  {
    Header: "Type",
    accessor: "adjustmentType",
  },
  {
    Header: "Perfomed By",
    accessor: "user.firstName",
    Cell: ({ row: { original } }) => {
      return (
        <M.Text>
          {original?.user?.firstName} {original?.user?.lastName ?? ""}
        </M.Text>
      );
    },
  },
  {
    Header: "Note",
    accessor: "note",
  },
];

export const createInventoryAdjustmentColumns = createColumns;
