import { ShoppingCartIcon } from "#/components-ng";
import SceneLayout from "#/components/SceneLayout/index.jsx";
import { Outlet } from "react-router";

export const CrmLayout = () => {
  return (
    <SceneLayout icon={<ShoppingCartIcon className="h-5 w-5" />}>
      <Outlet />
    </SceneLayout>
  );
};
