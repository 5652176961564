import { UserCreditsPaymentMethodCustomIcon } from "#/components-ng"
import { css } from "#/css/css"
import { HStack } from "#/css/jsx"
import { printTicketFromRefundV2 } from "#/modules/ticket/print"
import { paymentMethodsMap } from "#/scenes/Sales/MakeASale/payment-methods-map"
import { type RouterOutputs, trpc } from "#/trpc"
import { Icon } from "@chakra-ui/react"
import { Button } from "@gt/ui"
import { modals } from "@mantine/modals"
import { Separator, Spinner } from "@radix-ui/themes"
import dayjs from "dayjs"
import Decimal from "decimal.js"
import { uniqBy } from "lodash"
import {
	MantineReactTable,
	type MRT_ColumnDef,
	useMantineReactTable,
} from "mantine-react-table"
import { useMemo } from "react"

type Refunds = NonNullable<
	RouterOutputs["refundOrderItemSku"]["getByReference"]
>

export const openReturnDetailsModal = (reference: number) => {
	modals.open({
		modalId: "REFUND-DETAILS-MODAL",
		children: <ReturnDetailsModal reference={reference} />,
		size: "auto",
		centered: true,
		title: <h2>Refund R{reference}</h2>,
	})
}

interface RefundDetailsModalProps {
	reference: number
}

const ReturnDetailsModal = (props: RefundDetailsModalProps) => {
	const { data, isLoading, isFetchedAfterMount } =
		trpc.refundOrderItemSku.getByReference.useQuery(
			{
				reference: props.reference,
				filialId: null,
			},
			{
				enabled: !!props.reference,
				refetchOnWindowFocus: false,
				cacheTime: 0,
			},
		)

	const refund = data?.[0]

	return isLoading || !isFetchedAfterMount ? (
		<Spinner loading={isLoading} size="2" />
	) : (
		<div
			className={css({
				padding: 2,
				maxHeight: "min(800px, 95vh)",
				width: "100%",
				maxWidth: "1100px",
			})}
		>
			<HStack width="100%" justifyContent="space-between">
				<div
					className={css({
						display: "flex",
						flexDirection: "column",
						gap: "0.5rem",
					})}
				>
					<p className={css({ fontWeight: 700, color: "#0F1729" })}>
						{refund?.customer?.firstName ?? ""}{" "}
						{refund?.customer?.lastName ?? ""}
					</p>
					<p className={css({ color: "#4DBAFF" })}>
						+{refund?.customer?.areaCode} {refund?.customer?.phoneNumber}
					</p>
				</div>
				<div
					className={css({
						display: "flex",
						flexDirection: "column",
						gap: "0.5rem",
					})}
				>
					<p className={css({ color: "#0F1729", fontWeight: 500 })}>
						Date: {dayjs(refund?.createdAt).format("MM/DD/YYYY")}
					</p>
					<p className={css({ color: "#4DBAFF" })}>
						Status: {refund?.isReversed ? "Reversed" : "Complete"}
					</p>
				</div>
			</HStack>

			<Separator className={css({ marginY: "12px" })} />
			<ReturnDetailsTable refunds={data ?? []} />
			<Separator className={css({ marginY: "24px" })} />
			<HStack
				width="100%"
				justifyContent="space-between"
				alignItems="flex-start"
			>
				<div
					className={css({
						display: "flex",
						flexDirection: "column",
						gap: "0.5rem",
						alignContent: "flex-start",
					})}
				>
					<PaymentsSection refunds={data ?? []} />
					<ReprintButton refunds={data ?? []} />
				</div>
				<CostBreakdown refunds={data ?? []} />
			</HStack>
		</div>
	)
}

interface ReturnDetailsTableProps {
	refunds: Refunds
}

const ReturnDetailsTable = (props: ReturnDetailsTableProps) => {
	const refunds = useMemo(() => props.refunds ?? [], [props.refunds])

	const table = useMantineReactTable({
		columns,
		data: refunds ?? [],
		enableTopToolbar: false,
	})

	return <MantineReactTable table={table} />
}

const columns: MRT_ColumnDef<Refunds[number]>[] = [
	{
		id: "id",
		header: "SKU",
		accessorKey: "orderItemSku.itemSku.sku",
		size: 100,
	},
	{
		id: "title",
		header: "Product",
		accessorKey: "orderItemSku.itemSku.title",
	},
	{
		id: "quantity",
		header: "Qty",
		accessorKey: "quantity",
		Cell: (table) => `-${table.row.original.quantity}`,
	},
	{
		id: "price",
		header: "Price",
		accessorKey: "orderItemSku.price",
		Cell: (table) => `-$${table.row.original.orderItemSku.price.toFixed(2)}`,
	},
	{
		id: "total",
		header: "Total",
		accessorKey: "total",
		Cell: (table) => `-$${table.row.original.total.toFixed(2)}`,
	},
]

interface PaymentsSectionProps {
	refunds: Refunds
}

const PaymentsSection = (props: PaymentsSectionProps) => {
	const payments = uniqBy(props.refunds, "refundMethod")?.map((r) => ({
		method: r.refundMethod,
	}))

	return (
		<div
			className={css({
				display: "flex",
				flexDirection: "column",
				gap: "0.5rem",
			})}
		>
			<p className={css({ color: "#0F1729" })}>Payment method</p>
			{payments?.map((pm) => {
				const payment =
					pm.method !== "USER_CREDITS"
						? paymentMethodsMap[pm.method || "OTHER"]
						: { icon: UserCreditsPaymentMethodCustomIcon, text: "User credits" }

				return (
					<div
						key={pm.method}
						className={css({
							display: "flex",
							flexDirection: "row",
							gap: "0.5rem",
						})}
					>
						<div
							className={css({
								width: "2.25rem",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							})}
						>
							<Icon as={payment.icon} w="1.5rem" h="1.5rem" />
						</div>
						<p className={css({ fontWeight: 400 })}>{payment.text}</p>
					</div>
				)
			})}
		</div>
	)
}

interface CostBreakdownProps {
	refunds: Refunds
}

const CostBreakdown = (props: CostBreakdownProps) => {
	const total = useMemo(() => {
		return props.refunds?.reduce((acc, refund) => {
			return acc.plus(refund.total)
		}, new Decimal(0))
	}, [props.refunds])

	return (
		<div
			className={css({
				backgroundColor: "#48BB78",
				color: "white",
				width: "400px",
				borderRadius: "5px",
				paddingX: "16px",
				paddingY: "10px",
			})}
		>
			<div
				className={css({
					display: "flex",
					justifyContent: "space-between",
					gap: "2rem",
					fontWeight: 500,
					alignItems: "center",
				})}
			>
				<p className={css({ fontWeight: 500 })}>Total</p>
				<p className={css({ fontWeight: 500 })}>-${total?.toFixed(2) ?? 0}</p>
			</div>
		</div>
	)
}

interface ReprintButtonProps {
	refunds: Refunds
}

const ReprintButton = (props: ReprintButtonProps) => {
	const handleReprint = async () => {
		if (!props.refunds) {
			return
		}

		await printTicketFromRefundV2({
			refunds: props.refunds,
			withLocation: false,
			filialId: props.refunds[0].filialId,
		})
	}

	return (
		<Button
			onClick={handleReprint}
			className={css({
				marginY: "10px",
			})}
		>
			Reprint
		</Button>
	)
}
