import { ChangelogNewBadge } from "#/components@v2_5/changelog-new-badge";
import { css } from "#/css/css";
import { Box, Flex, Text } from "@chakra-ui/react";
import * as M from "@mantine/core";
import { Link } from "react-router-dom";

const Card = ({
  icon,
  text,
  path,
  width = "120px",
  height = "120px",
  iconProps = undefined,
  textProps = undefined,
  module,
  ...props
}: {
  icon: React.ReactNode;
  text: string;
  path: string;
  width?: string;
  height?: string;
  iconProps?: any;
  textProps?: any;
  module?: string;
}) => {
  return (
    <M.Box
      className={css({ pos: "relative" })}
      sx={{
        transition: "all 0.07s ease-in-out",
        "&:hover": { transform: "scale(1.07)" },
      }}
    >
      <Link to={path}>
        <Flex
          w={width}
          h={height}
          p={2}
          borderRadius="xl"
          bg="white"
          direction="column"
          alignItems="center"
          justifyContent="center"
          gap={2}
          {...props}
        >
          <Box {...iconProps}>{icon}</Box>
          <Text fontSize="sm" align="center" {...textProps}>
            {text}
          </Text>
        </Flex>
      </Link>
      {module != null && (
        <ChangelogNewBadge
          module={module}
          css={{
            pos: "absolute",
            right: "-2",
            top: "-2",
          }}
        />
      )}
    </M.Box>
  );
};

export default Card;
