import { trpc } from "#/trpc";
import { reportUserSuccess } from "#/util";
import { useNavigate } from "react-router";
import SupplierForm from "./Form/supplierForm";
import { FormValues } from "./Form/types";

export const CreateSupplier = () => {
  const navigate = useNavigate();
  const ctx = trpc.useContext();

  const { mutate, isLoading } = trpc.v2_5.supplier.createSupplier.useMutation({
    onSuccess() {
      ctx.v2_5.supplier.invalidate();
      reportUserSuccess({
        title: "Supplier added",
      });
      navigate(`/inventory/suppliers`);
    },
  });

  const handleSubmit = (values: FormValues) => {
    mutate({
      name: values.name,
      description: values.description,
      phoneNumber: values.phoneNumber,
      address: values.address,
    });
  };

  return <SupplierForm isLoading={isLoading} onSubmit={handleSubmit} />;
};
