import { ActionsMenuIcon } from "#/components-ng";
import GTLoader from "#/components-ng/loader";
import { css } from "#/css/css";
import { Box } from "#/css/jsx";
import { RouterOutputs, trpc } from "#/trpc";
import { Button, Dropdown } from "@gt/ui";
import { LoadingOverlay } from "@mantine/core";
import { Badge, IconButton, Separator } from "@radix-ui/themes";
import dayjs from "dayjs";
import {
  MantineReactTable,
  MRT_ColumnDef,
  useMantineReactTable,
} from "mantine-react-table";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import EditIcon from "~icons/ion/create-outline";
import CheckIcon from "~icons/ic/baseline-check";
import CloseIcon from "~icons/ic/baseline-close";
import { reportUserSuccess } from "#/util";
import { openConfirmModal } from "@mantine/modals";

export const SysChangeSetSection = () => {
  const params = useParams() as any;
  const sysVersionId = params?.sysVersionId
    ? Number(params.sysVersionId)
    : null;
  const navigate = useNavigate();

  const { data, isLoading, isFetchedAfterMount } =
    trpc.v2_5.changelog.sysVersions.getById.useQuery(
      {
        sysVersionId: sysVersionId!,
      },
      {
        enabled: !!sysVersionId,
        cacheTime: 0,
      },
    );

  const handleAddChange = () => {
    navigate(`changeset`);
  };

  return isLoading || !isFetchedAfterMount ? (
    <LoadingOverlay
      loader={<GTLoader width="100px" height="100px" />}
      visible={isFetchedAfterMount || isLoading}
    />
  ) : (
    <div
      className={css({
        backgroundColor: "white",
        padding: "40px",
        borderRadius: "lg",
      })}
    >
      <h2
        className={css({
          color: "black",
          fontSize: "26px",
          fontWeight: "bold",
          marginBottom: "18px",
        })}
      >
        Version {data?.version}
      </h2>
      <Badge size="3">
        Released at {dayjs(data?.createdAt).format("MM/DD/YYYY")}
      </Badge>
      <Separator
        size="4"
        className={css({
          marginY: "18px",
        })}
      />

      <div
        className={css({
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        })}
      >
        <div
          className={css({
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          })}
        >
          <h3
            className={css({
              color: "black",
              fontSize: "20px",
            })}
          >
            Change sets
          </h3>
          <Button
            className={css({
              width: "fit-content",
            })}
            onClick={handleAddChange}
          >
            Add change
          </Button>
        </div>
        <SysChangeSetSectionTable sysChangeSets={data?.sysChangeSets ?? []} />
      </div>
    </div>
  );
};

type SysChangeSet =
  RouterOutputs["v2_5"]["changelog"]["sysVersions"]["getById"]["sysChangeSets"][number];

interface SysChangeSetSectionTableProps {
  sysChangeSets: SysChangeSet[];
}

const SysChangeSetSectionTable = (props: SysChangeSetSectionTableProps) => {
  const data = useMemo(() => props.sysChangeSets ?? [], [props.sysChangeSets]);

  const table = useMantineReactTable({
    columns,
    data: data ?? [],
  });

  return <MantineReactTable table={table} />;
};

const columns: MRT_ColumnDef<SysChangeSet>[] = [
  {
    id: "actions",
    header: "Actions",
    size: 100,
    enableSorting: false,
    Cell: (table) => {
      const ctx = trpc.useContext();
      const { mutate, isLoading } =
        trpc.v2_5.changelog.sysChangeSet.delete.useMutation({
          onSuccess: () => {
            ctx.v2_5.changelog.invalidate();
            reportUserSuccess({
              title: "Change set deleted",
            });
          },
        });

      const handleDelete = () =>
        openConfirmModal({
          title: "Are you sure you want to delete this change set?",
          labels: { cancel: "Cancel", confirm: "Delete" },
          confirmProps: { color: "red" },
          onConfirm: () =>
            mutate({
              id: table.row.original.id,
            }),
        });

      return (
        <Dropdown.Root>
          <Dropdown.Trigger>
            <div
              className={css({
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              })}
            >
              <IconButton variant="ghost" color="gray" size="1">
                <ActionsMenuIcon />
              </IconButton>
            </div>
          </Dropdown.Trigger>
          <Dropdown.Content>
            <Dropdown.Item asChild>
              <Link to={`changeset/${table.row.original.id}`}>
                <div
                  className={css({
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 2,
                  })}
                >
                  <EditIcon />
                  Edit
                </div>
              </Link>
            </Dropdown.Item>
            <Dropdown.Item onClick={handleDelete} disabled={isLoading}>
              Delete
            </Dropdown.Item>
          </Dropdown.Content>
        </Dropdown.Root>
      );
    },
  },
  {
    id: "title",
    header: "Title",
    accessorKey: "title",
  },
  {
    id: "sysSectionId",
    header: "Section",
    accessorKey: "sysSection.name",
    Cell: (table) => {
      return table.row.original.sysSection.name;
    },
  },
  {
    id: "type",
    header: "Type",
    accessorKey: "type",
  },
  {
    id: "requiredReload",
    header: "Required reload",
    accessorKey: "requiredReload",
    Cell: (table) => {
      const value = table.row.original.requiredReload;
      return (
        <Box color={value ? "green" : "red"}>
          {value ? <CheckIcon /> : <CloseIcon />}
        </Box>
      );
    },
  },
];
