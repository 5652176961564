import { toNullIfEmpty } from "./transformers.js";
import { forwardRef, Input, InputProps } from "@chakra-ui/react";
import { ChangeEvent, Ref } from "react";

export interface NullableInputProps extends Omit<InputProps, "onChange"> {
  onChange: (value: string | null) => void;
}

const NullableInput = forwardRef<NullableInputProps, "input">(
  (props: NullableInputProps, ref: Ref<HTMLInputElement>) => {
    return (
      <Input
        {...props}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          if (props.onChange) {
            props.onChange(toNullIfEmpty(e.currentTarget.value));
          }
        }}
      />
    );
  }
);

export default NullableInput;
