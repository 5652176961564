import { DisplayTable } from "#/components/index.js";
import {
  filteredProductsAtom,
  removeProductAtom,
  setProductNewQtyAtom,
} from "../state.js";
import { AddItemsSection } from "./SearchHeader.js";
import * as M from "@mantine/core";
import { useAtomValue, useSetAtom } from "jotai";

export const Table = () => {
  const products = useAtomValue(filteredProductsAtom);

  return (
    <M.Stack className="bg-white">
      <AddItemsSection />
      <M.Divider color="gray" className="mb-8 mt-4" />
      <M.Box sx={{ maxWidth: "100%", overflow: "auto" }}>
        <DisplayTable
          columns={columns}
          data={products}
          pagination={false}
          display="block"
        />
      </M.Box>
    </M.Stack>
  );
};

const columns = [
  {
    Header: "SKU",
    accessor: "sku",
  },
  {
    Header: "Product name",
    accessor: "title",
  },
  {
    Header: "Cost",
    accessor: "cost",
    isNumeric: true,
    Cell: "money",
  },
  {
    Header: "Department",
    accessor: "department",
  },
  {
    Header: "Category",
    accessor: "category",
  },
  {
    Header: "Store Location",
    accessor: "storeLocation",
  },
  {
    Header: "Warehouse Location",
    accessor: "warehouseLocation",
  },
  {
    Header: "Avail. quantity",
    accessor: "availQty",

    isNumeric: true,
  },
  {
    Header: "New quantity",
    accessor: "newQty",

    isNumeric: true,
    Cell: ({ value, row: { index } }) => {
      const setProductNewQty = useSetAtom(setProductNewQtyAtom);

      return (
        <M.NumberInput
          min={0}
          value={value}
          onChange={(v) =>
            setProductNewQty({
              index,
              qty: typeof v === "number" ? v : 0,
            })
          }
        />
      );
    },
  },
  {
    Header: "Diff. quantity",
    accessor: "diffQty",

    isNumeric: true,
    Cell: ({ value }) => (
      <M.Text
        sx={(t) => ({
          color:
            value < 0
              ? t.colors.red[7]
              : value === 0
              ? t.colors.gray[6]
              : t.colors.green[7],
        })}
      >
        {value}
      </M.Text>
    ),
  },
  {
    Header: "",
    id: "close",
    Cell: ({ row: { index } }) => {
      const removeProduct = useSetAtom(removeProductAtom);

      return <M.CloseButton onClick={() => removeProduct(index)} />;
    },
  },
];
