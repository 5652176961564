import GTLoader from "#/components-ng/loader.js";
import { useAuth } from "#/context/AuthContext.js";
import { trpc } from "#/trpc.js";
import { CardLayout, CardTable } from "../components/index.js";
import { Text } from "@mantine/core";
import dayjs from "dayjs";
import { useMemo } from "react";

interface HistoryCardProps {
  itemSkuId: number;
}

export const HistoryCard = ({ itemSkuId }: HistoryCardProps) => {
  const [{ auth }] = useAuth();

  const { data, isLoading } = trpc.itemLog.getByItemSkuByInventory.useQuery(
    {
      itemSkuId: itemSkuId,
      filialId: auth?.user?.filialId ?? null,
      limit: 8,
    },
    {
      enabled: !!itemSkuId,
      cacheTime: 0,
    }
  );

  const itemLogs = useMemo(() => {
    if (!data) return [];
    return data.map((itemLog) => {
      const filial = `${itemLog.filial?.brevis ?? itemLog.filial.name}`;
      const user = `${itemLog.userFullName} ${
        !itemLog?.user && itemLog.logType === "ORDER" ? "(Guest)" : ""
      }`;
      const date = dayjs(itemLog.createdAt).format("DD/MM/YYYY");
      const logTitle = `${itemLog.logType} - ${
        itemLog.logType === "ORDER" ? itemLog?.receiptNumber : itemLog?.title
      }`;
      const stock = `${itemLog.stock}`;
      const trx = `${itemLog.trx}`;
      const result = `${itemLog.result}`;
      return [filial, user, date, logTitle, stock, trx, result];
    });
  }, [data]);

  return (
    <CardLayout title="History" id={itemSkuId} route="movement-history">
      {isLoading ? (
        <GTLoader width={100} height={100} key="loader" />
      ) : itemLogs.length > 0 ? (
        <CardTable
          headers={["Filial", "User", "Date", "Log", "Stock", "Trx", "result"]}
          rows={itemLogs}
        />
      ) : (
        <Text key="no-data" size="sm">
          No history found
        </Text>
      )}
    </CardLayout>
  );
};
