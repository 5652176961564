import { ImageUpload, UseS3UploadResult } from "#/components-ng/ui";
import { FormControl, Field } from "#/components/Form/v2/index.js";
import { RouterPrompt } from "#/components/index.js";
import placeholderImage from "#/placeholder-image.jpg";
import * as M from "@mantine/core";
import React from "react";
import {
  useForm,
  FormProvider,
  useFormContext,
  useWatch,
} from "react-hook-form";

export interface DepartmentFormValues {
  name: string;
  description: string;
  images?: string[];
  active: boolean;
  defaultImage?: string;
  top: boolean;
  metaDescription?: string | null;
}

export default function DepartmentForm({
  defaultValues,
  onSubmit,
  isLoading,
}: {
  defaultValues?: DepartmentFormValues;
  onSubmit: (values: DepartmentFormValues) => void;
  isLoading: boolean;
}) {
  const formDefaultValues = defaultValues;

  const [submitted, setSubmitted] = React.useState(false);
  const handlePreSubmit = React.useCallback(
    (f) => {
      setSubmitted(true);
      onSubmit({
        ...f,
      });
    },
    [onSubmit]
  );

  const formMethods = useForm({
    defaultValues: formDefaultValues,
  });

  return (
    <form onSubmit={formMethods.handleSubmit(handlePreSubmit)}>
      <RouterPrompt when={!submitted} />
      <M.Stack spacing={4} align="start">
        <M.Title bg={"white"} fw={600} px={40} py={24} w={"100%"} fz="1.6rem">
          Department
        </M.Title>
        <FormProvider {...formMethods}>
          <M.Box w="100%" bg={"white"} px={40} pt={43}>
            <M.Stack spacing={40} w="40%" fz={"14px"}>
              <M.Stack spacing={30}>
                <FormControl name="active" required>
                  <Field
                    colorScheme="twitter"
                    classNames={{
                      label: " font-[400] text-[#3D3D3D]",
                    }}
                    label="Active"
                    as={SwitchField}
                  />
                </FormControl>
                <FormControl name="top">
                  <Field
                    colorScheme="twitter"
                    label="Top department"
                    classNames={{
                      label: "font-[400] text-[#3D3D3D]",
                    }}
                    as={SwitchField}
                  />
                </FormControl>
              </M.Stack>
              <FormControl name="name" required>
                {/* @ts-ignore */}
                <Field
                  as={TextareaField}
                  label="Name"
                  placeholder="Enter name"
                  required
                  classNames={{
                    label: "py-1 font-[400] text-[#3D3D3D]",
                  }}
                  autosize={true}
                />
              </FormControl>
              <FormControl name="description" required>
                {/* @ts-ignore */}
                <Field
                  as={TextareaField}
                  label="Description"
                  placeholder="Enter description"
                  required
                  classNames={{
                    label: "py-1 font-[400] text-[#3D3D3D]",
                  }}
                  autosize={true}
                />
              </FormControl>
              <M.Stack fz={16} fw={400} c="#3D3D3D" spacing={12}>
                <M.Text>Upload department image</M.Text>
                <DefaultImageUpload />
              </M.Stack>
              <FormControl name="metaDescription">
                <Field
                  as={TextareaField}
                  label="Meta description"
                  placeholder="Enter meta description"
                  classNames={{
                    label: "py-1 font-[400] text-[#3D3D3D]",
                  }}
                  autosize={true}
                />
              </FormControl>
              <M.Button type="submit" w="30%" loading={isLoading}>
                save department
              </M.Button>
            </M.Stack>
          </M.Box>
        </FormProvider>
      </M.Stack>
    </form>
  );
}

function DefaultImageUpload() {
  const form = useFormContext();
  const [isLoading, setIsLoading] = useState(false);

  function handleUpload(data: UseS3UploadResult) {
    form.setValue("defaultImage", data.url);
    setIsLoading(false);
  }

  function handleUploadStart() {
    setIsLoading(true);
  }

  const image = useWatch({
    control: form.control,
    name: "defaultImage",
  });

  return (
    <div className="flex gap-x-12">
      <div className="relative">
        <img
          src={image ?? placeholderImage}
          className="aspect-square max-w-[150px] rounded-lg object-cover"
        />
        <M.LoadingOverlay visible={isLoading} />
      </div>
      <ImageUpload
        directory="departments"
        onS3UploadSuccess={handleUpload}
        onS3UploadingStart={handleUploadStart}
      />
    </div>
  );
}

function TextareaField({ name, ...props }) {
  const formContext = useFormContext();
  const formProps = formContext ? formContext.register(name) : {};

  return <M.Textarea classNames={props.classNames} {...formProps} {...props} />;
}

function SwitchField({ name, ...props }) {
  const formContext = useFormContext();
  const formProps = formContext ? formContext.register(name) : {};

  return (
    <M.Switch
      {...formProps}
      classNames={props.classNames}
      checked={formContext.watch(name)}
      {...props}
    />
  );
}
