import SceneSectionLayout from "#/components/SceneSectionLayout/index.jsx";
import { useAuth } from "#/context/AuthContext.js";
import { DashboardForm } from "./form/index.js";
import { dashboardBaseDataAtom } from "./state/index.js";
import { DashboardInner } from "./stats/index.js";
import * as M from "@mantine/core";
import dayjs from "dayjs";
import { useAtom } from "jotai";
import React from "react";

export const Dashboard = () => {
  const [auth] = useAuth();
  const [baseData, setBaseData] = useAtom(dashboardBaseDataAtom);

  React.useEffect(() => {
    if (auth?.auth?.user?.filialId) {
      setBaseData({
        ...baseData,
        filialId: auth.auth.user.filialId,
        userId: auth.auth.user.id,
        send: true,
        user: {
          avatar: auth.auth.user.avatar ?? null,
          firstName: auth.auth.user.firstName,
          lastName: auth.auth?.user?.lastName ?? null,
          roleName: auth.auth.role.name ?? null,
        },
      });
    } else {
      setBaseData({
        filialId: null,
        userId: null,
        send: false,
        fromDate: dayjs().startOf("month").toDate(),
        toDate: dayjs().endOf("month").toDate(),
        user: null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <M.Stack>
      <SceneSectionLayout title="">
        {baseData?.filialId && baseData?.userId && baseData.send ? (
          <DashboardInner />
        ) : (
          <DashboardForm />
        )}
      </SceneSectionLayout>
    </M.Stack>
  );
};
