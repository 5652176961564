import { ChevronDownOutline, PrintAndEmailIcon } from "#/components-ng/icons.js"
import { useAuth } from "#/context/AuthContext.js"
import { printTicketFromOrderV2 } from "#/modules/ticket/print.js"
import { RouterOutputs, trpc } from "#/trpc.js"
import { reportUserError, reportUserSuccess } from "#/util/index.js"
import { openConfirmModal } from "@mantine/modals"
import { applyDiscountV2 } from "../util/index.js"
import { cartAtom, saveCartAtom } from "./state/cart.js"
import {
	amountDueAtom,
	cashPaidOutAtom,
	taxAtom,
	orderTotalAtom,
	subtotalAtom,
	subtotalTaxAtom,
	shippingCostAtom,
} from "./state/cost-breakdown.js"
import { idempotencyKeyAtom } from "./state/idempotency-key.js"
import {
	cashierAtom,
	customerAtom,
	helperAtom,
	salesAssociateAtom,
	submittedAtom,
	receiptNameAtom,
	seasonalDiscountAtom,
	peopleValidationAtom,
} from "./state/index.js"
import {
	cardPaymentMethodsAtom,
	cashPaymentMethodAtom,
	giftPaymentMethodAtom,
	userCreditsPaymentMethodAtom,
} from "./state/payment-methods.js"
import { reportAtom } from "./state/reporting.js"
import * as M from "@mantine/core"
import { showNotification } from "@mantine/notifications"
import Decimal from "decimal.js"
import { useAtomValue, useSetAtom } from "jotai"
import React from "react"
import { useNavigate } from "react-router"
import MailIconOutline from "~icons/ion/ios-email-outline"
import PrintIconOutline from "~icons/ion/print-outline"
import { getDiscountLabel } from "#/util/discounts.js"
import { token } from "#/css/tokens"
import { uniqBy } from "lodash"
import { P, match } from "ts-pattern"
import { filialAtom } from "./state/filial.js"

export function CheckoutAndPrint() {
	const [printReceipt, setPrintReceipt] = useState<boolean>(false)
	const report = useAtomValue(reportAtom)
	const saveCart = useSetAtom(saveCartAtom)
	const customer = useAtomValue(customerAtom)
	const cart = useAtomValue(cartAtom)
	const cashPaymentMethod = useAtomValue(cashPaymentMethodAtom)
	const cardPaymentMethods = useAtomValue(cardPaymentMethodsAtom)
	const userCreditsPaymentMethod = useAtomValue(userCreditsPaymentMethodAtom)
	const tax = useAtomValue(taxAtom)
	const amountDue = useAtomValue(amountDueAtom)
	const cashPaidOut = useAtomValue(cashPaidOutAtom)
	const taxTotal = useAtomValue(subtotalTaxAtom)
	const subTotal = useAtomValue(subtotalAtom)
	const total = useAtomValue(orderTotalAtom)
	const setSubmitted = useSetAtom(submittedAtom)
	const [loading, setLoading] = React.useState(false)
	const navigate = useNavigate()
	const salesAssociate = useAtomValue(salesAssociateAtom)
	const cashier = useAtomValue(cashierAtom)
	const helper = useAtomValue(helperAtom)
	const receiptName = useAtomValue(receiptNameAtom)
	const [{ auth }] = useAuth()
	const globalDiscountApplied = useAtomValue(giftPaymentMethodAtom)
	const ctx = trpc.useContext()
	const idempotencyKey = useAtomValue(idempotencyKeyAtom)
	const shippingCost = useAtomValue(shippingCostAtom)
	const seasonalDiscount = useAtomValue(seasonalDiscountAtom)
	const filial = useAtomValue(filialAtom)

	const createOrderMutation = trpc.order.create.useMutation({
		onSuccess(order) {
			setLoading(false)
			reportUserSuccess({
				title: "Order created successfully",
				message: `Order #${order?.receiptNumber ?? 1} created successfully`,
			})
			if (printReceipt) {
				printTicketFromOrderV2({
					order: order,
					withLocation: false,
				})
			}
			ctx.order.invalidate()
			ctx.report.invalidate()
			ctx.v2_5.order.invalidate()
			navigate("/sales")
		},
		onError(error) {
			setLoading(false)
			reportUserError({
				title: "Failed to create order",
				message: error.message,
			})
		},
	})

	const { mutateAsync: verifyCartEntriesStock } =
		trpc.cart.verifyStock.useMutation({
			onError(error) {
				setLoading(false)
				reportUserError({
					title: "Failed to create order",
					message: error.message,
				})
			},
		})

	const { mutateAsync: autoUpdateSeasonalDiscounts } =
		trpc.v2_5.discounts.autoUpdateSeasonalDiscounts.useMutation()

	const { mutateAsync: invalidateUserSeasonalDiscount } =
		trpc.v2_5.discounts.invalidateUserSeasonalDiscount.useMutation()

	const peopleValidation = useAtomValue(peopleValidationAtom)

	const handleCheckoutAndPrint = React.useCallback(
		(sendEmail: boolean) => {
			setSubmitted(true)
			setLoading(true)
			const doCheckout = async function () {
				try {
					let stopCheckout = false

					for (const validation of peopleValidation) {
						match(validation)
							.with({ code: "SAME_CUSTOMER_AND_ASSOCIATE" }, () => {
								report.error({
									id: "make-a-sale__same_customer_and_associate",
									title: "Failed to create order",
									description: "Customer and associated cannot be the samee",
								})
								stopCheckout = true
							})
							.exhaustive()
					}

					if (stopCheckout) {
						setLoading(false)
						return
					}

					if (!cart.customerId || !customer) {
						report.error({
							id: "make-a-sale__create-order__no-customer",
							title: "Failed to create order",
							description: "You must add a customer",
						})
						setLoading(false)
						return
					}

					if (cart.products.length === 0) {
						report.error({
							id: "make-a-sale__create-order__no-items",
							title: "Failed to create order",
							description: "You must add at least one item",
						})
						setLoading(false)
						return
					}

					if (
						cashPaymentMethod.eq(0) &&
						cardPaymentMethods.length === 0 &&
						userCreditsPaymentMethod.eq(0)
					) {
						report.error({
							id: "make-a-sale__create-order__no-payment-methods",
							title: "Failed to create order",
							description: "You must add at least a payment method",
						})
						setLoading(false)

						return
					}

					if (amountDue.gt(0)) {
						report.error({
							id: "make-a-sale__create-order__amount-due",
							title: "Failed to create order",
							description: "Customer still has an amount due for payment",
						})
						setLoading(false)

						return
					}

					if (!auth?.user?.filialId) {
						report.error({
							id: "make-a-sale__create-order__no-filial",
							title: "Failed to create order",
							description:
								"You must be a filial id associated with your account",
						})
						setLoading(false)

						return
					}

					const verifyStock = await verifyCartEntriesStock({
						filialId: auth?.user?.filialId,
						cartItemsSkus: cart.products.map((entry) => ({
							itemSkuId: entry.itemWithVariant.itemSku.id,
							quantity: entry.quantity,
						})),
					})

					if (!verifyStock) {
						setLoading(false)
						return
					}

					// await saveCart("OPEN")
					await saveCart("CLOSED")

					createOrderMutation.mutate({
						sendEmail: sendEmail,
						input: {
							idempotencyKey: idempotencyKey,
							orderStatus: "COMPLETE",
							orderType:
								filial?.type === "WAREHOUSE" ? "WAREHOUSE" : "IN_STORE",
							subTotal,
							taxRate: tax.value,
							taxable: customer.taxable,
							taxTotal: customer.taxable ? taxTotal : new Decimal(0),
							//TODO(v2): Backend should calculate this
							total,
							filial: {
								connect: {
									// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
									id: auth!.user!.filialId!,
								},
							},
							customer: {
								connect: {
									id: customer.id,
								},
							},
							associated: {
								connect: {
									id: salesAssociate?.id,
								},
							},
							cashier: {
								connect: {
									id: cashier?.id,
								},
							},
							helper: {
								connect: {
									id: helper?.id,
								},
							},
							orderItemSku: {
								createMany: {
									data: cart.products.map((entry) => ({
										itemSkuId: entry.itemWithVariant.itemSku.id,
										quantity: entry.quantity,
										price:
											filial?.type === "WAREHOUSE"
												? entry.itemWithVariant.itemSku.cost
												: entry.itemWithVariant.itemSku.price,
										discountReference: entry.discount?.reference,
										discountAmount: entry.discount?.amount,
										discountType: entry.discount?.type,
										//TODO(v2): Backend should calculate this
										total: applyDiscountV2(
											filial?.type === "WAREHOUSE"
												? entry.itemWithVariant.itemSku.cost.times(
														entry.quantity,
													)
												: entry.itemWithVariant.itemSku.price.times(
														entry.quantity,
													),
											entry.discount,
										),
									})),
								},
							},
							orderPayment: {
								createMany: {
									data: cardPaymentMethods
										.concat({
											type: "CASH",
											paidIn: cashPaymentMethod,
											paidOut: cashPaidOut,
										})
										.concat(
											userCreditsPaymentMethod.gt(0)
												? {
														type: "USER_CREDITS",
														paidIn: userCreditsPaymentMethod,
														paidOut: new Decimal(0),
													}
												: [],
										)
										.map((pm) => ({
											filialId: auth!.user!.filialId!,
											paidIn: pm.paidIn,
											paidOut: pm.paidOut,
											paymentAmount: pm.paidIn.sub(pm.paidOut),
											paymentType: pm.type,
										})),
								},
							},
							receiptName:
								receiptName === "companyName"
									? customer?.company ??
										`${customer?.firstName} ${customer?.lastName ?? ""}`
									: `${customer?.firstName} ${customer?.lastName ?? ""}`,
							discountAmount: globalDiscountApplied.amount.gt(0)
								? globalDiscountApplied.amount
								: null,
							discountType: globalDiscountApplied.amount.gt(0)
								? globalDiscountApplied.type === "Fixed"
									? "AMOUNT"
									: "PERCENTAGE"
								: null,
							orderShipping: shippingCost.gt(0)
								? {
										create: {
											customer: {
												connect: {
													id: customer.id,
												},
											},
											addressLine1: "",
											city: "",
											state: "",
											country: "",
											zipCode: "",
											shippingCompany: "",
											shippingNumber: "",
											shippingCost: shippingCost,
										},
									}
								: undefined,
						},
					})

					await autoUpdateSeasonalDiscounts({
						customerId: customer.id,
						orderTotal: total,
					})

					if (seasonalDiscount != null) {
						invalidateUserSeasonalDiscount({
							userDiscountId: seasonalDiscount.id,
						})
					}
				} catch (e) {
					return showNotification({
						title: "Failed to create order",
						message: e?.message,
						color: "red",
					})
				} finally {
					// setLoading(false);
				}
			}

			const preprocessCheckout = async () => {
				if (customer?.id == null) {
					await doCheckout()
					return
				}

				const eligibility = await checkSeasonalDiscountsEligibility({
					tctx: ctx,
					orderTotal: total,
					customerId: customer.id,
				})

				match(eligibility.status)
					.with(P.union("ELIGIBLE", "NO_DISCOUNTS"), () => doCheckout())
					.with("NOT_ELIGIBLE", () => {
						openConfirmModal({
							onConfirm: () => {
								doCheckout()
							},
							onCancel: () => {
								setLoading(false)
							},
							onClose: () => {
								setLoading(false)
							},
							title: (
								<M.Text
									c={token("colors.slate.800")}
									fw={token("fontWeights.medium")}
								>
									Seasonal discounts eligibility
								</M.Text>
							),
							children: (
								<M.Text c={token("colors.slate.800")}>
									Customer has not met the minimum order amount for the
									following seasonal discounts:
									<M.Table>
										<thead>
											<tr>
												<th>Discount</th>
												<th>Min. purchase</th>
												<th>Goal</th>
											</tr>
										</thead>
										<tbody>
											{eligibility.nonEligibleDiscounts.map((discount) => (
												<tr key={discount.id}>
													<td>
														{discount.reference} {getDiscountLabel(discount)}
													</td>
													<td>
														${discount.minPurchaseForEligibility?.toFixed(2)}
													</td>
													<td>
														+$
														{discount
															.minPurchaseForEligibility!.minus(total)
															.toFixed(2)}
													</td>
												</tr>
											))}
										</tbody>
									</M.Table>
								</M.Text>
							),
							labels: {
								confirm: "Checkout anyways",
								cancel: "Continue shopping",
							},
						})
					})
					.exhaustive()
			}

			preprocessCheckout()
		},
		[
			peopleValidation,
			seasonalDiscount,
			invalidateUserSeasonalDiscount,
			autoUpdateSeasonalDiscounts,
			ctx,
			setSubmitted,
			cart.customerId,
			cart.products,
			customer,
			cashPaymentMethod,
			cardPaymentMethods,
			userCreditsPaymentMethod,
			amountDue,
			auth,
			verifyCartEntriesStock,
			saveCart,
			createOrderMutation,
			idempotencyKey,
			subTotal,
			tax.value,
			taxTotal,
			total,
			salesAssociate?.id,
			cashier?.id,
			helper?.id,
			cashPaidOut,
			receiptName,
			globalDiscountApplied.amount,
			globalDiscountApplied.type,
			shippingCost,
			report,
			filial?.type,
		],
	)

	return (
		<M.Menu disabled={loading}>
			<M.Menu.Target>
				<M.Button
					loading={loading}
					sx={{ gridColumn: "span 2" }}
					className="col-span-3"
					rightIcon={<ChevronDownOutline />}
					loaderPosition="right"
				>
					Checkout
				</M.Button>
			</M.Menu.Target>
			<M.Menu.Dropdown>
				<M.Menu.Item
					onClick={() => {
						setPrintReceipt(true)
						handleCheckoutAndPrint(false)
					}}
					icon={<PrintIconOutline fontSize={20} />}
					disabled={loading}
				>
					Checkout and print receipt
				</M.Menu.Item>
				<M.Menu.Item
					onClick={() => {
						setPrintReceipt(false)
						handleCheckoutAndPrint(true)
					}}
					icon={<MailIconOutline fontSize={20} />}
					disabled={loading}
				>
					Checkout and send receipt by email
				</M.Menu.Item>
				<M.Menu.Item
					onClick={() => {
						setPrintReceipt(true)
						handleCheckoutAndPrint(true)
					}}
					icon={<PrintAndEmailIcon width={25} height={25} />}
					disabled={loading}
				>
					Checkout, print & send receipt by email
				</M.Menu.Item>
			</M.Menu.Dropdown>
		</M.Menu>
	)
}

interface CheckSeasonalDiscountsEligibilityOpts {
	tctx: ReturnType<typeof trpc.useContext>
	orderTotal: Decimal
	customerId: number
}

interface DiscountEligibility {
	status: "ELIGIBLE" | "NOT_ELIGIBLE" | "NO_DISCOUNTS"
	nonEligibleDiscounts: Array<
		RouterOutputs["v2_5"]["discounts"]["getSeasonalDiscounts"][0]
	>
}

async function checkSeasonalDiscountsEligibility({
	tctx,
	orderTotal,
	customerId,
}: CheckSeasonalDiscountsEligibilityOpts): Promise<DiscountEligibility> {
	let seasonalDiscounts = await tctx.v2_5.discounts.getSeasonalDiscounts.fetch({
		filter: {
			onlyEligible: {
				customerId,
			},
		},
	})

	seasonalDiscounts = seasonalDiscounts.sort(
		(a, b) =>
			a.minPurchaseForEligibility
				?.minus(b.minPurchaseForEligibility ?? 0)
				.toNumber() ?? 0,
	)

	seasonalDiscounts = uniqBy(seasonalDiscounts, (disc) => disc.reference)

	if (seasonalDiscounts.length === 0) {
		return {
			status: "NO_DISCOUNTS",
			nonEligibleDiscounts: [],
		}
	}

	const nonEligibleDiscounts = seasonalDiscounts.filter(
		(discount) => discount.minPurchaseForEligibility?.gt(orderTotal) ?? false,
	)

	if (nonEligibleDiscounts.length > 0) {
		return {
			status: "NOT_ELIGIBLE",
			nonEligibleDiscounts: nonEligibleDiscounts,
		}
	}

	return {
		status: "ELIGIBLE",
		nonEligibleDiscounts: [],
	}
}
