// AUTOGENERATED FILE. DO NOT MODIFY!!!
// See `scripts/generate_icons.js`

import styled from 'styled-components';
import AdjustInventoryRawIcon from './Adjust-inventory.svg?react'
import AmericanExpressRawIcon from './american-express.svg?react'
import BarGraphRawIcon from './bar-graph.svg?react'
import BellRawIcon from './bell.svg?react'
import CalendarCheckRawIcon from './calendar-check.svg?react'
import CampaignRawIcon from './campaign.svg?react'
import CartCancelRawIcon from './cart-cancel.svg?react'
import CartTimeRawIcon from './cart-time.svg?react'
import CategoryRawIcon from './Category.svg?react'
import CelebrateRawIcon from './celebrate.svg?react'
import ChatBalloonRawIcon from './chat-balloon.svg?react'
import ClipboardTimeRawIcon from './clipboard-time.svg?react'
import CustomersRawIcon from './Customers.svg?react'
import DepartmentRawIcon from './Department.svg?react'
import DinersCcRawIcon from './diners-cc.svg?react'
import DinersClubRawIcon from './diners-club.svg?react'
import DiscountTagRawIcon from './discount-tag.svg?react'
import DiscoverRawIcon from './discover.svg?react'
import DolarCircleRawIcon from './dolar-circle.svg?react'
import EntitiyRawIcon from './Entitiy.svg?react'
import FilialRawIcon from './filial.svg?react'
import FireRawIcon from './fire.svg?react'
import FourStackRawIcon from './four-stack.svg?react'
import GraphUpRawIcon from './graph-up.svg?react'
import HierarchyGraphRawIcon from './hierarchy-graph.svg?react'
import HomeRawIcon from './home.svg?react'
import ItemListRawIcon from './Item-list.svg?react'
import LeafRawIcon from './leaf.svg?react'
import LetterDownloadRawIcon from './letter-download.svg?react'
import LogoutRawIcon from './logout.svg?react'
import MastercardRawIcon from './mastercard.svg?react'
import MedalRawIcon from './medal.svg?react'
import MilestonesRawIcon from './Milestones.svg?react'
import MoneyBackRawIcon from './money-back.svg?react'
import MoonStarsRawIcon from './moon-stars.svg?react'
import NetworkRawIcon from './network.svg?react'
import NewArrivalsRawIcon from './new-arrivals.svg?react'
import NewStampRawIcon from './new-stamp.svg?react'
import OpenBoxRawIcon from './open-box.svg?react'
import OpenFolderRawIcon from './open-folder.svg?react'
import OrderBowlRawIcon from './order-bowl.svg?react'
import OutOfStockRawIcon from './Out-of-stock.svg?react'
import PaperCancelRawIcon from './paper-cancel.svg?react'
import PaperCheckRawIcon from './paper-check.svg?react'
import PaperChecklistRawIcon from './paper-checklist.svg?react'
import PaperMoneyRawIcon from './paper-money.svg?react'
import PaperTimeRawIcon from './paper-time.svg?react'
import PendingCartsRawIcon from './pendingCarts.svg?react'
import PercentageCircleRawIcon from './percentage-circle.svg?react'
import PlusRawIcon from './plus.svg?react'
import PrinterRawIcon from './printer.svg?react'
import ReturnRawIcon from './return.svg?react'
import Sales_1RawIcon from './Sales_1.svg?react'
import SettingsRawIcon from './settings.svg?react'
import ShippingRawIcon from './shipping.svg?react'
import SpeakerRawIcon from './speaker.svg?react'
import TimeBackRawIcon from './time-back.svg?react'
import TimeRawIcon from './time.svg?react'
import UserMoneyRawIcon from './user-money.svg?react'
import UserRawIcon from './user.svg?react'
import UsersRawIcon from './Users.svg?react'
import VisaRawIcon from './visa.svg?react'

export const AdjustInventoryIcon = styled(AdjustInventoryRawIcon).attrs(({
  size = 'medium'
}) => ({
  size: size === 'small' ? '16px'
    : size === 'medium' ? '24px'
    : size === 'big' ? '36px'
    : size === 'giant' ? '48px'
    : size,
}))`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  stroke: currentColor;

  & path {
    stroke: inherit !important;
  }
`;

export const AmericanExpressIcon = styled(AmericanExpressRawIcon).attrs(({
  size = 'medium'
}) => ({
  size: size === 'small' ? '16px'
    : size === 'medium' ? '24px'
    : size === 'big' ? '36px'
    : size === 'giant' ? '48px'
    : size,
}))`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  stroke: currentColor;

  & path {
    stroke: inherit !important;
  }
`;

export const BarGraphIcon = styled(BarGraphRawIcon).attrs(({
  size = 'medium'
}) => ({
  size: size === 'small' ? '16px'
    : size === 'medium' ? '24px'
    : size === 'big' ? '36px'
    : size === 'giant' ? '48px'
    : size,
}))`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  stroke: currentColor;

  & path {
    stroke: inherit !important;
  }
`;

export const BellIcon = styled(BellRawIcon).attrs(({
  size = 'medium'
}) => ({
  size: size === 'small' ? '16px'
    : size === 'medium' ? '24px'
    : size === 'big' ? '36px'
    : size === 'giant' ? '48px'
    : size,
}))`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  stroke: currentColor;

  & path {
    stroke: inherit !important;
  }
`;

export const CalendarCheckIcon = styled(CalendarCheckRawIcon).attrs(({
  size = 'medium'
}) => ({
  size: size === 'small' ? '16px'
    : size === 'medium' ? '24px'
    : size === 'big' ? '36px'
    : size === 'giant' ? '48px'
    : size,
}))`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  stroke: currentColor;

  & path {
    stroke: inherit !important;
  }
`;

export const CampaignIcon = styled(CampaignRawIcon).attrs(({
  size = 'medium'
}) => ({
  size: size === 'small' ? '16px'
    : size === 'medium' ? '24px'
    : size === 'big' ? '36px'
    : size === 'giant' ? '48px'
    : size,
}))`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  stroke: currentColor;

  & path {
    stroke: inherit !important;
  }
`;

export const CartCancelIcon = styled(CartCancelRawIcon).attrs(({
  size = 'medium'
}) => ({
  size: size === 'small' ? '16px'
    : size === 'medium' ? '24px'
    : size === 'big' ? '36px'
    : size === 'giant' ? '48px'
    : size,
}))`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  stroke: currentColor;

  & path {
    stroke: inherit !important;
  }
`;

export const CartTimeIcon = styled(CartTimeRawIcon).attrs(({
  size = 'medium'
}) => ({
  size: size === 'small' ? '16px'
    : size === 'medium' ? '24px'
    : size === 'big' ? '36px'
    : size === 'giant' ? '48px'
    : size,
}))`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  stroke: currentColor;

  & path {
    stroke: inherit !important;
  }
`;

export const CategoryIcon = styled(CategoryRawIcon).attrs(({
  size = 'medium'
}) => ({
  size: size === 'small' ? '16px'
    : size === 'medium' ? '24px'
    : size === 'big' ? '36px'
    : size === 'giant' ? '48px'
    : size,
}))`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  stroke: currentColor;

  & path {
    stroke: inherit !important;
  }
`;

export const CelebrateIcon = styled(CelebrateRawIcon).attrs(({
  size = 'medium'
}) => ({
  size: size === 'small' ? '16px'
    : size === 'medium' ? '24px'
    : size === 'big' ? '36px'
    : size === 'giant' ? '48px'
    : size,
}))`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  stroke: currentColor;

  & path {
    stroke: inherit !important;
  }
`;

export const ChatBalloonIcon = styled(ChatBalloonRawIcon).attrs(({
  size = 'medium'
}) => ({
  size: size === 'small' ? '16px'
    : size === 'medium' ? '24px'
    : size === 'big' ? '36px'
    : size === 'giant' ? '48px'
    : size,
}))`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  stroke: currentColor;

  & path {
    stroke: inherit !important;
  }
`;

export const ClipboardTimeIcon = styled(ClipboardTimeRawIcon).attrs(({
  size = 'medium'
}) => ({
  size: size === 'small' ? '16px'
    : size === 'medium' ? '24px'
    : size === 'big' ? '36px'
    : size === 'giant' ? '48px'
    : size,
}))`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  stroke: currentColor;

  & path {
    stroke: inherit !important;
  }
`;

export const CustomersIcon = styled(CustomersRawIcon).attrs(({
  size = 'medium'
}) => ({
  size: size === 'small' ? '16px'
    : size === 'medium' ? '24px'
    : size === 'big' ? '36px'
    : size === 'giant' ? '48px'
    : size,
}))`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  stroke: currentColor;

  & path {
    stroke: inherit !important;
  }
`;

export const DepartmentIcon = styled(DepartmentRawIcon).attrs(({
  size = 'medium'
}) => ({
  size: size === 'small' ? '16px'
    : size === 'medium' ? '24px'
    : size === 'big' ? '36px'
    : size === 'giant' ? '48px'
    : size,
}))`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  stroke: currentColor;

  & path {
    stroke: inherit !important;
  }
`;

export const DinersCcIcon = styled(DinersCcRawIcon).attrs(({
  size = 'medium'
}) => ({
  size: size === 'small' ? '16px'
    : size === 'medium' ? '24px'
    : size === 'big' ? '36px'
    : size === 'giant' ? '48px'
    : size,
}))`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  stroke: currentColor;

  & path {
    stroke: inherit !important;
  }
`;

export const DinersClubIcon = styled(DinersClubRawIcon).attrs(({
  size = 'medium'
}) => ({
  size: size === 'small' ? '16px'
    : size === 'medium' ? '24px'
    : size === 'big' ? '36px'
    : size === 'giant' ? '48px'
    : size,
}))`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  stroke: currentColor;

  & path {
    stroke: inherit !important;
  }
`;

export const DiscountTagIcon = styled(DiscountTagRawIcon).attrs(({
  size = 'medium'
}) => ({
  size: size === 'small' ? '16px'
    : size === 'medium' ? '24px'
    : size === 'big' ? '36px'
    : size === 'giant' ? '48px'
    : size,
}))`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  stroke: currentColor;

  & path {
    stroke: inherit !important;
  }
`;

export const DiscoverIcon = styled(DiscoverRawIcon).attrs(({
  size = 'medium'
}) => ({
  size: size === 'small' ? '16px'
    : size === 'medium' ? '24px'
    : size === 'big' ? '36px'
    : size === 'giant' ? '48px'
    : size,
}))`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  stroke: currentColor;

  & path {
    stroke: inherit !important;
  }
`;

export const DolarCircleIcon = styled(DolarCircleRawIcon).attrs(({
  size = 'medium'
}) => ({
  size: size === 'small' ? '16px'
    : size === 'medium' ? '24px'
    : size === 'big' ? '36px'
    : size === 'giant' ? '48px'
    : size,
}))`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  stroke: currentColor;

  & path {
    stroke: inherit !important;
  }
`;

export const EntitiyIcon = styled(EntitiyRawIcon).attrs(({
  size = 'medium'
}) => ({
  size: size === 'small' ? '16px'
    : size === 'medium' ? '24px'
    : size === 'big' ? '36px'
    : size === 'giant' ? '48px'
    : size,
}))`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  stroke: currentColor;

  & path {
    stroke: inherit !important;
  }
`;

export const FilialIcon = styled(FilialRawIcon).attrs(({
  size = 'medium'
}) => ({
  size: size === 'small' ? '16px'
    : size === 'medium' ? '24px'
    : size === 'big' ? '36px'
    : size === 'giant' ? '48px'
    : size,
}))`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  stroke: currentColor;

  & path {
    stroke: inherit !important;
  }
`;

export const FireIcon = styled(FireRawIcon).attrs(({
  size = 'medium'
}) => ({
  size: size === 'small' ? '16px'
    : size === 'medium' ? '24px'
    : size === 'big' ? '36px'
    : size === 'giant' ? '48px'
    : size,
}))`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  stroke: currentColor;

  & path {
    stroke: inherit !important;
  }
`;

export const FourStackIcon = styled(FourStackRawIcon).attrs(({
  size = 'medium'
}) => ({
  size: size === 'small' ? '16px'
    : size === 'medium' ? '24px'
    : size === 'big' ? '36px'
    : size === 'giant' ? '48px'
    : size,
}))`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  stroke: currentColor;

  & path {
    stroke: inherit !important;
  }
`;

export const GraphUpIcon = styled(GraphUpRawIcon).attrs(({
  size = 'medium'
}) => ({
  size: size === 'small' ? '16px'
    : size === 'medium' ? '24px'
    : size === 'big' ? '36px'
    : size === 'giant' ? '48px'
    : size,
}))`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  stroke: currentColor;

  & path {
    stroke: inherit !important;
  }
`;

export const HierarchyGraphIcon = styled(HierarchyGraphRawIcon).attrs(({
  size = 'medium'
}) => ({
  size: size === 'small' ? '16px'
    : size === 'medium' ? '24px'
    : size === 'big' ? '36px'
    : size === 'giant' ? '48px'
    : size,
}))`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  stroke: currentColor;

  & path {
    stroke: inherit !important;
  }
`;

export const HomeIcon = styled(HomeRawIcon).attrs(({
  size = 'medium'
}) => ({
  size: size === 'small' ? '16px'
    : size === 'medium' ? '24px'
    : size === 'big' ? '36px'
    : size === 'giant' ? '48px'
    : size,
}))`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  stroke: currentColor;

  & path {
    stroke: inherit !important;
  }
`;

export const ItemListIcon = styled(ItemListRawIcon).attrs(({
  size = 'medium'
}) => ({
  size: size === 'small' ? '16px'
    : size === 'medium' ? '24px'
    : size === 'big' ? '36px'
    : size === 'giant' ? '48px'
    : size,
}))`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  stroke: currentColor;

  & path {
    stroke: inherit !important;
  }
`;

export const LeafIcon = styled(LeafRawIcon).attrs(({
  size = 'medium'
}) => ({
  size: size === 'small' ? '16px'
    : size === 'medium' ? '24px'
    : size === 'big' ? '36px'
    : size === 'giant' ? '48px'
    : size,
}))`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  stroke: currentColor;

  & path {
    stroke: inherit !important;
  }
`;

export const LetterDownloadIcon = styled(LetterDownloadRawIcon).attrs(({
  size = 'medium'
}) => ({
  size: size === 'small' ? '16px'
    : size === 'medium' ? '24px'
    : size === 'big' ? '36px'
    : size === 'giant' ? '48px'
    : size,
}))`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  stroke: currentColor;

  & path {
    stroke: inherit !important;
  }
`;

export const LogoutIcon = styled(LogoutRawIcon).attrs(({
  size = 'medium'
}) => ({
  size: size === 'small' ? '16px'
    : size === 'medium' ? '24px'
    : size === 'big' ? '36px'
    : size === 'giant' ? '48px'
    : size,
}))`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  stroke: currentColor;

  & path {
    stroke: inherit !important;
  }
`;

export const MastercardIcon = styled(MastercardRawIcon).attrs(({
  size = 'medium'
}) => ({
  size: size === 'small' ? '16px'
    : size === 'medium' ? '24px'
    : size === 'big' ? '36px'
    : size === 'giant' ? '48px'
    : size,
}))`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  stroke: currentColor;

  & path {
    stroke: inherit !important;
  }
`;

export const MedalIcon = styled(MedalRawIcon).attrs(({
  size = 'medium'
}) => ({
  size: size === 'small' ? '16px'
    : size === 'medium' ? '24px'
    : size === 'big' ? '36px'
    : size === 'giant' ? '48px'
    : size,
}))`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  stroke: currentColor;

  & path {
    stroke: inherit !important;
  }
`;

export const MilestonesIcon = styled(MilestonesRawIcon).attrs(({
  size = 'medium'
}) => ({
  size: size === 'small' ? '16px'
    : size === 'medium' ? '24px'
    : size === 'big' ? '36px'
    : size === 'giant' ? '48px'
    : size,
}))`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  stroke: currentColor;

  & path {
    stroke: inherit !important;
  }
`;

export const MoneyBackIcon = styled(MoneyBackRawIcon).attrs(({
  size = 'medium'
}) => ({
  size: size === 'small' ? '16px'
    : size === 'medium' ? '24px'
    : size === 'big' ? '36px'
    : size === 'giant' ? '48px'
    : size,
}))`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  stroke: currentColor;

  & path {
    stroke: inherit !important;
  }
`;

export const MoonStarsIcon = styled(MoonStarsRawIcon).attrs(({
  size = 'medium'
}) => ({
  size: size === 'small' ? '16px'
    : size === 'medium' ? '24px'
    : size === 'big' ? '36px'
    : size === 'giant' ? '48px'
    : size,
}))`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  stroke: currentColor;

  & path {
    stroke: inherit !important;
  }
`;

export const NetworkIcon = styled(NetworkRawIcon).attrs(({
  size = 'medium'
}) => ({
  size: size === 'small' ? '16px'
    : size === 'medium' ? '24px'
    : size === 'big' ? '36px'
    : size === 'giant' ? '48px'
    : size,
}))`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  stroke: currentColor;

  & path {
    stroke: inherit !important;
  }
`;

export const NewArrivalsIcon = styled(NewArrivalsRawIcon).attrs(({
  size = 'medium'
}) => ({
  size: size === 'small' ? '16px'
    : size === 'medium' ? '24px'
    : size === 'big' ? '36px'
    : size === 'giant' ? '48px'
    : size,
}))`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  stroke: currentColor;

  & path {
    stroke: inherit !important;
  }
`;

export const NewStampIcon = styled(NewStampRawIcon).attrs(({
  size = 'medium'
}) => ({
  size: size === 'small' ? '16px'
    : size === 'medium' ? '24px'
    : size === 'big' ? '36px'
    : size === 'giant' ? '48px'
    : size,
}))`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  stroke: currentColor;

  & path {
    stroke: inherit !important;
  }
`;

export const OpenBoxIcon = styled(OpenBoxRawIcon).attrs(({
  size = 'medium'
}) => ({
  size: size === 'small' ? '16px'
    : size === 'medium' ? '24px'
    : size === 'big' ? '36px'
    : size === 'giant' ? '48px'
    : size,
}))`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  stroke: currentColor;

  & path {
    stroke: inherit !important;
  }
`;

export const OpenFolderIcon = styled(OpenFolderRawIcon).attrs(({
  size = 'medium'
}) => ({
  size: size === 'small' ? '16px'
    : size === 'medium' ? '24px'
    : size === 'big' ? '36px'
    : size === 'giant' ? '48px'
    : size,
}))`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  stroke: currentColor;

  & path {
    stroke: inherit !important;
  }
`;

export const OrderBowlIcon = styled(OrderBowlRawIcon).attrs(({
  size = 'medium'
}) => ({
  size: size === 'small' ? '16px'
    : size === 'medium' ? '24px'
    : size === 'big' ? '36px'
    : size === 'giant' ? '48px'
    : size,
}))`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  stroke: currentColor;

  & path {
    stroke: inherit !important;
  }
`;

export const OutOfStockIcon = styled(OutOfStockRawIcon).attrs(({
  size = 'medium'
}) => ({
  size: size === 'small' ? '16px'
    : size === 'medium' ? '24px'
    : size === 'big' ? '36px'
    : size === 'giant' ? '48px'
    : size,
}))`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  stroke: currentColor;

  & path {
    stroke: inherit !important;
  }
`;

export const PaperCancelIcon = styled(PaperCancelRawIcon).attrs(({
  size = 'medium'
}) => ({
  size: size === 'small' ? '16px'
    : size === 'medium' ? '24px'
    : size === 'big' ? '36px'
    : size === 'giant' ? '48px'
    : size,
}))`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  stroke: currentColor;

  & path {
    stroke: inherit !important;
  }
`;

export const PaperCheckIcon = styled(PaperCheckRawIcon).attrs(({
  size = 'medium'
}) => ({
  size: size === 'small' ? '16px'
    : size === 'medium' ? '24px'
    : size === 'big' ? '36px'
    : size === 'giant' ? '48px'
    : size,
}))`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  stroke: currentColor;

  & path {
    stroke: inherit !important;
  }
`;

export const PaperChecklistIcon = styled(PaperChecklistRawIcon).attrs(({
  size = 'medium'
}) => ({
  size: size === 'small' ? '16px'
    : size === 'medium' ? '24px'
    : size === 'big' ? '36px'
    : size === 'giant' ? '48px'
    : size,
}))`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  stroke: currentColor;

  & path {
    stroke: inherit !important;
  }
`;

export const PaperMoneyIcon = styled(PaperMoneyRawIcon).attrs(({
  size = 'medium'
}) => ({
  size: size === 'small' ? '16px'
    : size === 'medium' ? '24px'
    : size === 'big' ? '36px'
    : size === 'giant' ? '48px'
    : size,
}))`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  stroke: currentColor;

  & path {
    stroke: inherit !important;
  }
`;

export const PaperTimeIcon = styled(PaperTimeRawIcon).attrs(({
  size = 'medium'
}) => ({
  size: size === 'small' ? '16px'
    : size === 'medium' ? '24px'
    : size === 'big' ? '36px'
    : size === 'giant' ? '48px'
    : size,
}))`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  stroke: currentColor;

  & path {
    stroke: inherit !important;
  }
`;

export const PendingCartsIcon = styled(PendingCartsRawIcon).attrs(({
  size = 'medium'
}) => ({
  size: size === 'small' ? '16px'
    : size === 'medium' ? '24px'
    : size === 'big' ? '36px'
    : size === 'giant' ? '48px'
    : size,
}))`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  stroke: currentColor;

  & path {
    stroke: inherit !important;
  }
`;

export const PercentageCircleIcon = styled(PercentageCircleRawIcon).attrs(({
  size = 'medium'
}) => ({
  size: size === 'small' ? '16px'
    : size === 'medium' ? '24px'
    : size === 'big' ? '36px'
    : size === 'giant' ? '48px'
    : size,
}))`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  stroke: currentColor;

  & path {
    stroke: inherit !important;
  }
`;

export const PlusIcon = styled(PlusRawIcon).attrs(({
  size = 'medium'
}) => ({
  size: size === 'small' ? '16px'
    : size === 'medium' ? '24px'
    : size === 'big' ? '36px'
    : size === 'giant' ? '48px'
    : size,
}))`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  stroke: currentColor;

  & path {
    stroke: inherit !important;
  }
`;

export const PrinterIcon = styled(PrinterRawIcon).attrs(({
  size = 'medium'
}) => ({
  size: size === 'small' ? '16px'
    : size === 'medium' ? '24px'
    : size === 'big' ? '36px'
    : size === 'giant' ? '48px'
    : size,
}))`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  stroke: currentColor;

  & path {
    stroke: inherit !important;
  }
`;

export const ReturnIcon = styled(ReturnRawIcon).attrs(({
  size = 'medium'
}) => ({
  size: size === 'small' ? '16px'
    : size === 'medium' ? '24px'
    : size === 'big' ? '36px'
    : size === 'giant' ? '48px'
    : size,
}))`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  stroke: currentColor;

  & path {
    stroke: inherit !important;
  }
`;

export const Sales_1Icon = styled(Sales_1RawIcon).attrs(({
  size = 'medium'
}) => ({
  size: size === 'small' ? '16px'
    : size === 'medium' ? '24px'
    : size === 'big' ? '36px'
    : size === 'giant' ? '48px'
    : size,
}))`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  stroke: currentColor;

  & path {
    stroke: inherit !important;
  }
`;

export const SettingsIcon = styled(SettingsRawIcon).attrs(({
  size = 'medium'
}) => ({
  size: size === 'small' ? '16px'
    : size === 'medium' ? '24px'
    : size === 'big' ? '36px'
    : size === 'giant' ? '48px'
    : size,
}))`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  stroke: currentColor;

  & path {
    stroke: inherit !important;
  }
`;

export const ShippingIcon = styled(ShippingRawIcon).attrs(({
  size = 'medium'
}) => ({
  size: size === 'small' ? '16px'
    : size === 'medium' ? '24px'
    : size === 'big' ? '36px'
    : size === 'giant' ? '48px'
    : size,
}))`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  stroke: currentColor;

  & path {
    stroke: inherit !important;
  }
`;

export const SpeakerIcon = styled(SpeakerRawIcon).attrs(({
  size = 'medium'
}) => ({
  size: size === 'small' ? '16px'
    : size === 'medium' ? '24px'
    : size === 'big' ? '36px'
    : size === 'giant' ? '48px'
    : size,
}))`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  stroke: currentColor;

  & path {
    stroke: inherit !important;
  }
`;

export const TimeBackIcon = styled(TimeBackRawIcon).attrs(({
  size = 'medium'
}) => ({
  size: size === 'small' ? '16px'
    : size === 'medium' ? '24px'
    : size === 'big' ? '36px'
    : size === 'giant' ? '48px'
    : size,
}))`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  stroke: currentColor;

  & path {
    stroke: inherit !important;
  }
`;

export const TimeIcon = styled(TimeRawIcon).attrs(({
  size = 'medium'
}) => ({
  size: size === 'small' ? '16px'
    : size === 'medium' ? '24px'
    : size === 'big' ? '36px'
    : size === 'giant' ? '48px'
    : size,
}))`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  stroke: currentColor;

  & path {
    stroke: inherit !important;
  }
`;

export const UserMoneyIcon = styled(UserMoneyRawIcon).attrs(({
  size = 'medium'
}) => ({
  size: size === 'small' ? '16px'
    : size === 'medium' ? '24px'
    : size === 'big' ? '36px'
    : size === 'giant' ? '48px'
    : size,
}))`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  stroke: currentColor;

  & path {
    stroke: inherit !important;
  }
`;

export const UserIcon = styled(UserRawIcon).attrs(({
  size = 'medium'
}) => ({
  size: size === 'small' ? '16px'
    : size === 'medium' ? '24px'
    : size === 'big' ? '36px'
    : size === 'giant' ? '48px'
    : size,
}))`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  stroke: currentColor;

  & path {
    stroke: inherit !important;
  }
`;

export const UsersIcon = styled(UsersRawIcon).attrs(({
  size = 'medium'
}) => ({
  size: size === 'small' ? '16px'
    : size === 'medium' ? '24px'
    : size === 'big' ? '36px'
    : size === 'giant' ? '48px'
    : size,
}))`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  stroke: currentColor;

  & path {
    stroke: inherit !important;
  }
`;

export const VisaIcon = styled(VisaRawIcon).attrs(({
  size = 'medium'
}) => ({
  size: size === 'small' ? '16px'
    : size === 'medium' ? '24px'
    : size === 'big' ? '36px'
    : size === 'giant' ? '48px'
    : size,
}))`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  stroke: currentColor;

  & path {
    stroke: inherit !important;
  }
`;

