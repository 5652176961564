import { Separator, SpinnerIcon } from "#/components-ng";
import { CustomAttributesForm } from "#/scenes/Inventory/CustomAttributes/Form";
import { trpc } from "#/trpc";
import { reportUserError, reportUserSuccess } from "#/util";
import { itemSkuCustomAttributesAtom } from "../../state";
import {
  ActionIcon,
  Badge,
  Button,
  CloseButton,
  Modal,
  Select,
  Tooltip,
} from "@mantine/core";
import { useSetAtom } from "jotai";
import { useMemo, useState } from "react";
import { useParams } from "react-router";

type ItemSkuCustomAttributesSectionProps = {
  itemSkuId?: number | null;
};

export const ItemSkuCustomAttributeSection = (
  props: ItemSkuCustomAttributesSectionProps,
) => {
  const params = useParams() as any;
  const [itemSkuId, setItemSkuId] = useState<number | null>(() =>
    params.itemSkuId ? Number(params.itemSkuId) : props?.itemSkuId ?? null,
  );
  const setItemSkuCustomAttributesToCreate = useSetAtom(
    itemSkuCustomAttributesAtom,
  );
  const [verifyHandleChange, setVerifyHandleChange] = useState(false);
  const [customAttributesSelected, setCustomAttributesSelected] = useState<
    {
      customAttributeId: string;
      new: boolean;
      toDelete: boolean;
      name: string;
    }[]
  >([]);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const { data, isLoading } = trpc.customAttribute.getAll.useQuery();

  trpc.itemSkuCustomAttribute.private.getByItemSkuId.useQuery(
    {
      itemSkuId: itemSkuId!,
    },
    {
      cacheTime: 0,
      enabled: !!itemSkuId,
      onSuccess(data) {
        setCustomAttributesSelected(
          data.map((itemSkuCA) => ({
            customAttributeId: itemSkuCA.customAttributeId.toString(),
            new: false,
            toDelete: false,
            name: itemSkuCA.customAttribute.name,
          })),
        );
      },
    },
  );

  const options = useMemo(() => {
    if (!data) return [];
    return data.map((customAttribute) => ({
      value: customAttribute.id.toString(),
      label: customAttribute.name,
      customAttribute: customAttribute,
    }));
  }, [data]);

  const { mutate: createItemSkuCustomAttribute } =
    trpc.itemSkuCustomAttribute.private.create.useMutation({
      onSuccess(data) {
        setCustomAttributesSelected(
          customAttributesSelected.map((item) => {
            if (item.customAttributeId === data.customAttributeId.toString()) {
              return {
                ...item,
                new: false,
              };
            }
            return item;
          }),
        );
      },
    });

  const { mutate: deleteCustomAttribute } =
    trpc.itemSkuCustomAttribute.private.deleteByItemSkuIdAndCustomId.useMutation(
      {
        onSuccess() {
          setCustomAttributesSelected(
            customAttributesSelected.filter((item) => !item.toDelete),
          );
        },
      },
    );

  const handleChange = () => {
    if (!itemSkuId) return;
    const newCustomAttributes = customAttributesSelected.find(
      (item) => item.new,
    );
    const toDeleteCustomAttributes = customAttributesSelected.find(
      (item) => item.toDelete,
    );

    if (newCustomAttributes) {
      createItemSkuCustomAttribute({
        itemSkuId: itemSkuId,
        customAttributeId: Number(newCustomAttributes.customAttributeId),
      });
    }

    if (toDeleteCustomAttributes) {
      deleteCustomAttribute({
        itemSkuId: itemSkuId,
        customAttributeId: Number(toDeleteCustomAttributes.customAttributeId),
      });
    }
  };

  useEffect(() => {
    if (!itemSkuId) return;
    if (verifyHandleChange) {
      handleChange();
      setVerifyHandleChange(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verifyHandleChange]);

  const ctx = trpc.useContext();
  const { mutate } = trpc.v2_5.customAttributes.create.useMutation({
    onError(error) {
      reportUserError({
        title: "Error creating custom attribute",
        message: error.message,
      });
    },
    onSuccess(data) {
      ctx.customAttribute.invalidate();
      ctx.v2_5.customAttributes.invalidate();
      reportUserSuccess({
        title: "Custom attribute created",
      });

      if (itemSkuId) {
        createItemSkuCustomAttribute({
          itemSkuId: itemSkuId,
          customAttributeId: data.id,
        });
      } else {
        setItemSkuCustomAttributesToCreate((prev) => [
          ...prev,
          data.id.toString(),
        ]);
      }

      setCustomAttributesSelected([
        ...customAttributesSelected,
        {
          customAttributeId: data.id.toString(),
          new: false,
          toDelete: false,
          name: data.name,
        },
      ]);

      setOpenModal(false);
    },
  });

  return (
    <div>
      <Separator className="my-12" />
      <Select
        label="Select an attribute"
        placeholder="Select an attribute"
        data={options}
        rightSection={isLoading && <SpinnerIcon />}
        value={null}
        className="w-full lg:w-1/2"
        onChange={(id) => {
          if (!id) return;
          const customAttribute =
            options.find((option) => option.value === id)?.customAttribute ??
            null;

          if (customAttribute) {
            setCustomAttributesSelected([
              ...customAttributesSelected.filter(
                (attribute) =>
                  attribute.customAttributeId !== customAttribute.id.toString(),
              ),
              {
                customAttributeId: customAttribute.id.toString(),
                new: true,
                toDelete: false,
                name: customAttribute.name,
              },
            ]);

            if (!itemSkuId) {
              setItemSkuCustomAttributesToCreate((prev) => [...prev, id]);
            } else {
              setVerifyHandleChange(true);
            }
          }
        }}
      />
      <div className="mt-5 flex flex-wrap gap-2">
        {customAttributesSelected.map((attribute, i) => {
          return (
            <Badge
              key={i}
              className="bg-[#4DBAFF]/10 font-semibold text-[#4DBAFF]"
              rightSection={RemoveButton(() => {
                setCustomAttributesSelected(
                  customAttributesSelected.map((item) => {
                    if (
                      item.customAttributeId === attribute.customAttributeId
                    ) {
                      return {
                        ...item,
                        toDelete: true,
                      };
                    }
                    return item;
                  }),
                );
                if (!itemSkuId) {
                  setItemSkuCustomAttributesToCreate((prev) =>
                    prev.filter((id) => id !== attribute.customAttributeId),
                  );
                  setCustomAttributesSelected(
                    customAttributesSelected.filter(
                      (item) =>
                        item.customAttributeId !==
                        attribute.customAttributeId.toString(),
                    ),
                  );
                } else {
                  setVerifyHandleChange(true);
                }
              })}
            >
              {attribute.name}
            </Badge>
          );
        })}
      </div>
      <div className="mt-5 flex flex-wrap gap-2">
        <Button
          className="bg-[#F4F4F4] font-semibold text-black hover:bg-slate-500 hover:text-white"
          onClick={() => setOpenModal(true)}
        >
          Create new attribute
        </Button>
      </div>
      <Modal
        opened={openModal}
        onClose={() => setOpenModal(false)}
        size="auto"
        centered
        closeOnEscape={false}
        closeOnClickOutside={false}
        zIndex={40}
      >
        <CustomAttributesForm
          isLoading={false}
          onSubmit={(customAttribute) => {
            mutate({
              name: customAttribute.name,
              description: customAttribute.description,
              type: customAttribute.type,
              textLimit: customAttribute.textLimit,
              options:
                customAttribute.type === "SELECT"
                  ? customAttribute.options
                  : [],
              multiSelectItems: 
                customAttribute.type === "MULTISELECT_ITEM"
                  ? customAttribute.multiSelectItems.map((value) => value.id)
                  : [],
            });
          }}
        />
      </Modal>
    </div>
  );
};

const RemoveButton = (onDelete: () => void) => (
  <Tooltip label="Remove attribute">
    <ActionIcon size="sm" radius="xl" variant="transparent" onClick={onDelete}>
      <CloseButton size={"sm"} color="red" />
    </ActionIcon>
  </Tooltip>
);
