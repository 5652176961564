import { DefaultValues, FormProvider, SubmitHandler } from "react-hook-form";
import { C, createSysSectionSchema, FormValues } from "./types";
import { styled } from "#/css/jsx";
import { css } from "#/css/css";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@gt/ui";
import { trpc } from "#/trpc";

export interface SysSectionFormProps {
  onSubmit: SubmitHandler<FormValues>;
  isLoading: boolean;
  defaultValues?: DefaultValues<FormValues>;
}

export const SysSectionForm = (props: SysSectionFormProps) => {
  const { data: sysBlocks } = trpc.v2_5.changelog.sysBlocks.getAll.useQuery();

  const form = C.useForm({
    resolver: zodResolver(createSysSectionSchema),
    shouldUnregister: false,
    defaultValues: {
      name: "",
      sysBlockId: null,
      ...props.defaultValues,
    },
  });

  return (
    <styled.div
      className={css({
        backgroundColor: "white",
        padding: "40px",
        borderRadius: "lg",
      })}
    >
      <h2
        className={css({
          color: "black",
          fontSize: "26px",
          fontWeight: "bold",
          marginBottom: "30px",
        })}
      >
        Sys-Section
      </h2>
      <FormProvider {...form}>
        <form onSubmit={(e) => form.handleSubmit(props.onSubmit)(e)}>
          <styled.div
            className={css({
              display: "flex",
              flexDirection: "column",
              gap: 35,
              maxWidth: "500px",
            })}
          >
            <C.InputField name="name" label="Name *" required />
            <C.SelectField
              name="sysBlockId"
              required
              label="SysBlock *"
              data={
                sysBlocks?.map((sysBlock) => ({
                  id: sysBlock.id.toString(),
                  label: sysBlock.name,
                })) || []
              }
            />
            <Button type="submit" isLoading={props.isLoading}>
              Save
            </Button>
          </styled.div>
        </form>
      </FormProvider>
    </styled.div>
  );
};
