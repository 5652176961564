import { FilterOutlineIcon, SearchOutline } from "#/components-ng";
import { styled } from "#/css/jsx";
import { Filter, FilterKey } from "./$common";
import { EcommerceOrdersTable } from "./table";
import { Button, Checkbox, Dropdown, Input } from "@gt/ui";
import { DatePickerInput } from "@mantine/dates";
import dayjs from "dayjs";

export function EcommerceOrders() {
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    dayjs().startOf("month").toDate(),
    dayjs().endOf("month").toDate(),
  ]);
  const [search, setSearch] = useState<string | null>(null);
  const [filters, setFilters] = useState<Record<FilterKey, boolean>>(
    () =>
      Object.fromEntries(
        Object.keys(Filter).map((key) => [
          key,
          key === "PROCESSING" ? true : false,
        ])
      ) as any
    // ^ Create an object in the form of `{ complete: false, processing:
    // false, ... }` by using the keys from `Filter` to create a new object
  );

  function selectFilter(filter: FilterKey) {
    setFilters((old) => ({
      ...old,
      [filter]: !old[filter],
    }));
  }

  return (
    <div>
      <div className="flex justify-between">
        <styled.div
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap="2"
        >
          <OrderStatusDropdown filters={filters} selectFilter={selectFilter} />
          <DatePickerInput
            type="range"
            value={dateRange}
            onChange={setDateRange}
            numberOfColumns={2}
            popoverProps={{
              zIndex: 9000,
            }}
            w={250}
          />
        </styled.div>
        <div className="relative flex items-center gap-x-2">
          <SearchOutline className="absolute left-[10px] text-slate-400" />
          <Input
            className="pl-[35px]"
            onInput={(e) => setSearch(e.currentTarget.value)}
          />
        </div>
      </div>
      <div>
        <div className="mt-[24px]">
          <EcommerceOrdersTable
            filter={
              Object.entries(filters)
                .filter(([, active]) => active)
                .map(([filter]) => filter) as any
            }
            search={search}
            dateRange={dateRange}
          />
        </div>
      </div>
    </div>
  );
}

interface OrderStatusDropdownProps {
  filters: Record<FilterKey, boolean>;
  selectFilter: (filter: FilterKey) => void;
}

const OrderStatusDropdown = (props: OrderStatusDropdownProps) => {
  return (
    <Dropdown.Root>
      <Dropdown.Trigger>
        <Button variant="secondary">
          <FilterOutlineIcon className="mr-2" />
          Filter by
        </Button>
      </Dropdown.Trigger>
      <Dropdown.Content className="grid gap-y-2" loop>
        {Object.entries(props.filters)
          .filter((filter) =>
            ["PROCESSING", "COMPLETE", "CANCELLED", "READY_TO_PICKUP"].includes(
              filter[0]
            )
          )
          .map(([filterKey, isFilterActive], i) => (
            <Dropdown.Item
              key={i}
              className="flex items-center gap-x-2"
              onSelect={(e) => {
                e.preventDefault();
                props.selectFilter(filterKey as FilterKey);
              }}
            >
              <Checkbox checked={isFilterActive} />
              <span>{Filter[filterKey]}</span>
            </Dropdown.Item>
          ))}
      </Dropdown.Content>
    </Dropdown.Root>
  );
};
