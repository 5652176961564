"use client";

import { Button } from "./button.js";
import { Command } from "./command.js";
import { CloseIcon } from "./icons.js";
import { INPUT_CLASS_NAME } from "./input.js";
import { cn, tw } from "./lib/utils.js";
import { Popover } from "./popover.js";
import { useUncontrolled } from "./use-uncontrolled.js";
import { Check, ChevronsUpDown } from "lucide-react";
import * as React from "react";

export interface ComboboxDataEntry<Value> {
  id: string;
  label: string;
  value: Value;
}

export interface ComboboxProps<Value> {
  value?: ComboboxDataEntry<Value> | null;
  defaultValue?: ComboboxDataEntry<Value> | null;
  onChange?: (data: ComboboxDataEntry<Value>) => void;
  onSearchChange?: (query: string) => void;
  filterManually?: boolean;
  data: Array<ComboboxDataEntry<Value>>;
  canBeNull?: boolean;
  disabled?: boolean;
  placeholders?: {
    selectValue?: string;
    searchValue?: string;
    valueNotFound?: string;
  };
  classNames?: {
    button?: string;
  };
}

export function Combobox<Value>(props: ComboboxProps<Value>) {
  const [open, setOpen] = React.useState(false);
  const [value, handleChange] = useUncontrolled({
    value: props.value,
    defaultValue: props.defaultValue,
    fallbackValue: null,
    onChange: props.onChange,
  });
  const filterManually = props.filterManually ?? true;

  return (
    <Popover.Root open={open} onOpenChange={setOpen}>
      <Popover.Trigger asChild disabled={props.disabled}>
        <div className="relative flex items-center">
          <button
            disabled={props.disabled}
            className={tw(
              INPUT_CLASS_NAME,
              "relative flex cursor-pointer items-center focus-visible:ring-0"
            )}
          >
            {value
              ? value.label
              : props.placeholders?.selectValue ?? "Select value..."}
            <ChevronsUpDown
              className={tw(
                "absolute right-2 hidden h-4 w-4 text-slate-400",
                !value && "block"
              )}
            />
          </button>
          <Button
            disabled={props.disabled}
            variant="ghost"
            className={tw(
              "absolute right-2 hidden h-5 w-5 p-0 text-slate-400",
              value && props.canBeNull && "block"
            )}
            onClick={(e) => {
              e.stopPropagation();
              handleChange(null);
            }}
          >
            <CloseIcon />
          </Button>
        </div>
      </Popover.Trigger>
      <Popover.Content className="p-0">
        <Command.Root shouldFilter={!filterManually}>
          <Command.Input
            placeholder={props.placeholders?.searchValue ?? "Search value..."}
            onValueChange={props.onSearchChange}
          />
          <Command.Empty>
            {props.placeholders?.valueNotFound ?? "Value not found."}
          </Command.Empty>
          <Command.Group>
            {props.data.map((dataEntry) => (
              <Command.Item
                key={dataEntry.id}
                onSelect={() => {
                  handleChange(dataEntry);
                  setOpen(false);
                }}
              >
                <Check
                  className={cn(
                    "mr-2 h-4 w-4",
                    value?.id === dataEntry.id ? "opacity-100" : "opacity-0"
                  )}
                />
                {dataEntry.label}
              </Command.Item>
            ))}
          </Command.Group>
        </Command.Root>
      </Popover.Content>
    </Popover.Root>
  );
}
